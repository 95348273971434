import type Episode from '@audacy-clients/client-services/src/dataServices/Episode';
import type EpisodeList from '@audacy-clients/client-services/src/dataServices/EpisodeList';
import { getNullSafeDate } from '@audacy-clients/core/utils/date';

import { type IClientServicesPagedList, type IContentSummary, type IEpisode } from './types';
import { wrapDataObject } from '.';

let atlV2Enabled = true;
export const setAtlV2Enabled = (newValue: boolean) => (atlV2Enabled = newValue);
export const isAtlV2Enabled = () => atlV2Enabled;

export const wrapEpisodeList = (
  list: EpisodeList,
): IClientServicesPagedList<IEpisode | undefined> => {
  return {
    get: (i) => {
      const episode = list.getObjectSync(i);

      if (episode) {
        return wrapEpisode(episode as Episode);
      }

      return undefined;
    },
    getAll: () => (list.getAllObjects() as Array<Episode>).map(wrapEpisode),
    getLength: () => list.getNum(),
    prefetch: (startIndex, endIndex) => list.prefetch(startIndex, endIndex),
  };
};

export const wrapEpisode = (episode: Episode): IEpisode => {
  const now = new Date().getTime();
  const replayableUntilDate = getNullSafeDate(episode.getReplayableUntil());
  const publishDate =
    getNullSafeDate(episode.getPublishDate()) ?? getNullSafeDate(episode.getStartTime());

  return {
    ...wrapDataObject<Episode>(episode),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chatChannels: (episode.data as any)?.chatChannels, // Remove cast to any when its added to Client Services
    duration: episode.getDuration(),
    endDateTime: getNullSafeDate(episode.getEndTime()),
    endTime: episode.getEndTimeMillis(),
    isDoneAndRewind: episode.getEndTimeMillis() < now && episode.isRewindable(),
    isLive:
      episode.getStartTimeMillis() < now &&
      episode.getEndTimeMillis() > now &&
      !episode.getIsOnAirOnly(),
    isOnAirOnly: episode.getIsOnAirOnly(),
    isReplayable: episode.isRewindable(),
    isRewind: episode.isRewindable(),
    parentImage: episode.getParentImageSquare(),
    parentImageAlt: episode.getParentImageAlt(),
    parentShowUrl: (episode.getParentShow() as IContentSummary)?.url,
    parentStationIds: episode.getParentStationIds(),
    parentTitle: episode.getParentTitle(),
    publishDate,
    replayableUntilDateTime: replayableUntilDate?.toISOString(),
    showContentId: episode.getParentId(),
    startDateTime: getNullSafeDate(episode.getStartTime()),
    startTime: episode.getStartTimeMillis(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    episodeId: (episode.data as any)?.id,
    transcript: episode.getTranscript(),
  };
};
