import {
  EpisodeSubType,
  ShowSubtype,
  StandaloneChapterSubType,
  StationSubType,
  TagSubtype,
  LocalStorageKey,
  CollectionSubType,
  ClickFeature,
} from '@audacy-clients/client-services/core';
import { isConsideredPlayed } from '@audacy-clients/client-services/src/utils/playbackResumePoints';
import { useResumePoint } from '@audacy-clients/core/atoms/playbackResumePoints';
import playerState, {
  checkPlayingById,
  nowPlaying,
  playerTimeState,
} from '@audacy-clients/core/atoms/player';
import { PlayerState } from '@audacy-clients/core/types/player';
import { useClientServices } from '@audacy-clients/core/utils/clientServices';
import { getPlayingUntilFromStartAndDuration } from '@audacy-clients/core/utils/date';
import { useRecoilValue } from 'recoil';

import { INowPlaying } from '~/components/Entity/types';
import { useAudioHelpersWeb } from '~/hooks/use-audio-helpers-web';
import useWebQueue from '~/hooks/use-web-queue';

import { IUsePlayableCardProps, IPlayableHorizontalEntityCard } from './types';

// For playable entities, play audio for the given contentId on click
export const usePlayableCardProps = ({
  isLive = false,
  isPlayable = false,
  isPlayerQueue = false,
  item,
  parentId,
  playContext,
  collectionId,
  playOrPause,
  // only relevant for live items
  onPlayLiveItem,
}: IUsePlayableCardProps): IPlayableHorizontalEntityCard => {
  const audioHelpers = useAudioHelpersWeb();
  const { clientServices } = useClientServices();
  const nowPlayingItem = useRecoilValue(nowPlaying);
  const nowPlayingItemId = nowPlayingItem?.dataObject?.id;
  const playerStateObj = useRecoilValue(playerState);
  const isPlaying = useRecoilValue(checkPlayingById({ contentId: item?.id }));

  const isScrubbing =
    // can't use isPlaying above because it checks if player state is playing in addition to item id
    isPlaying && playerStateObj.playState === PlayerState.Loading;

  const { playbackResumePointStartOffset, fixedPlaybackResumePointSeconds } = useResumePoint(item);
  const { contains } = useWebQueue();

  const playerTimeStateValue = useRecoilValue(playerTimeState);
  const nowPlayingDuration = playerTimeStateValue.duration;

  const defaultPlayableCardData = {
    resumePoint: fixedPlaybackResumePointSeconds,
    isPlayed: isConsideredPlayed(item?.duration, fixedPlaybackResumePointSeconds),
    isPlaying,
    href: item?.url,
    isScrubbing,
    nowPlayingDuration,
  };

  if (!item || !item?.entitySubtype) return defaultPlayableCardData;

  const onPlayStateClick =
    isPlayable && !onPlayLiveItem
      ? () => {
          playOrPause?.(playContext, {
            startOffset: playbackResumePointStartOffset,
          });
        }
      : undefined;

  const onQueueClick =
    isPlayable && !onPlayLiveItem
      ? () => {
          if (!item.id) return;
          if (isPlaying) return audioHelpers.pause(playContext);
          if (nowPlayingItemId === item.id) {
            return audioHelpers.resume(playContext);
          }
          return audioHelpers.playQueue(item.id, playContext);
        }
      : undefined;

  const onPlaylistClick =
    isPlayable && !onPlayLiveItem
      ? () => {
          if (!item.id) return;
          if (isPlaying) return audioHelpers.pause(playContext);
          if (nowPlayingItemId === item.id) {
            return audioHelpers.resume(playContext);
          } else if (collectionId) {
            clientServices
              .getPersonalizationServices()
              .dataStore.setDataSync(LocalStorageKey.COLLECTION_ID, collectionId);

            return audioHelpers.playPlaylist({
              collectionId,
              itemId: item.id,
              playContext,
            });
          } else {
            clientServices
              .getPersonalizationServices()
              .dataStore.setDataSync(LocalStorageKey.COLLECTION_ID, collectionId);

            return audioHelpers.playPlaylist({
              collectionId: item.id,
              playContext,
            });
          }
        }
      : undefined;

  // The most straightforward and reliable way of knowing whether a HEC is in
  // the player queue is to pass a boolean through the component hierarchy.
  // Using viewId isn't always accurate for this edge case.
  if (isPlayerQueue) {
    return {
      ...defaultPlayableCardData,
      href: undefined,
      onClick: onQueueClick,
    };
  }

  if (contains(item.id)) {
    return {
      ...defaultPlayableCardData,
      onClick: onQueueClick,
      onPlayStateClick: onQueueClick,
    };
  }

  switch (item?.entitySubtype) {
    case CollectionSubType.PLAYLIST:
      return {
        ...defaultPlayableCardData,
        isPlayList: true,
        onClick: onPlaylistClick,
        onPlayStateClick: () => playOrPause?.(playContext),
      };
    case EpisodeSubType.BROADCAST_SHOW_EPISODE:
    case StandaloneChapterSubType.BROADCAST_EPISODE_CHAPTER:
    case ShowSubtype.BROADCAST:
      if (isPlayable || isLive) {
        const liveUntil: INowPlaying | undefined =
          item?.startDateTime && item?.duration
            ? {
                playingUntil: getPlayingUntilFromStartAndDuration(
                  item.startDateTime,
                  item.duration,
                ),
              }
            : undefined;

        return {
          ...defaultPlayableCardData,
          liveUntil,
          onPlayStateClick: () => {
            if (!item.id) return;
            if (isPlaying) return audioHelpers.pause(playContext);

            // play live episode
            if (isLive) {
              audioHelpers.pause(playContext);
              audioHelpers.seekToLivePosition();
              // We already call seekToLivePosition, so no resumePoint is needed.
              return onPlayLiveItem?.();
            }

            // resume a rewind episode
            if (
              nowPlayingItemId &&
              (nowPlayingItemId === item.id || nowPlayingItemId === parentId)
            ) {
              return audioHelpers.resume(playContext);
            }

            if (playContext.feature === ClickFeature.PLAYLIST) {
              return onPlayStateClick?.();
            }

            // play a rewind episode
            return onPlayLiveItem
              ? onPlayLiveItem()
              : audioHelpers.playFromId(item.id, playContext, {
                  startOffset: playbackResumePointStartOffset,
                });
          },
        };
      } else {
        return {
          href: defaultPlayableCardData.href,
        };
      }
    case EpisodeSubType.PODCAST_EPISODE:
    case EpisodeSubType.HOST_CREATED_EPISODE:
      return {
        ...defaultPlayableCardData,
        onPlayStateClick,
      };
    // Broadcast stations are a unique case dealing with live playback
    case StationSubType.BROADCAST:
      return {
        ...defaultPlayableCardData,
        isLive: true, // All stations are considered live
        onPlayStateClick: () => {
          if (!item?.id) return;
          if (isPlaying) return audioHelpers.stop(playContext);
          audioHelpers.pause(playContext);
          // Treat play state CTA just like station entity header CTA
          audioHelpers.playFromId(item?.id, playContext);
        },
      };
    case StationSubType.EXCLUSIVE:
      return {
        ...defaultPlayableCardData,
        isExclusiveStation: true,
        onPlayStateClick: () => {
          if (!item.id) return;
          if (isPlaying) return audioHelpers.pause(playContext);
          if (nowPlayingItemId === item.id) {
            return audioHelpers.resume(playContext);
          }
          audioHelpers.pause(playContext);
          return audioHelpers.playFromId(item.id, playContext);
        },
      };
    case TagSubtype.GENRE:
    case TagSubtype.LEAGUE:
    case TagSubtype.TEAM:
    case ShowSubtype.PODCAST:
      return {
        href: defaultPlayableCardData.href,
      };
    default:
      return defaultPlayableCardData;
  }
};
