import DataObject from './DataObject';
import { ObjectType } from '../Constants';
import StationSummary from './StationSummary';

/**
 * @module Show_Summary
 */
export default class ShowSummary extends DataObject {
  /**
   * Creates a new instance of a Show Summary.
   * @param {ShowSummary} data
   */
  constructor(data) {
    super(ObjectType.SHOW_SUMMARY, data);
  }

  /**
   * Return array of genres
   * @eeturns {Array<String>}
   */
  getGenres() {
    return this.data.genres;
  }

  /**
   * Returns isFav
   * @returns {String}
   */
  getIsFav() {
    return this.data.isFav;
  }

  /**
   * Returns parent genres array
   * @returns {Array<String>}
   */
  getParentGenres() {
    return this.data.parentGenres;
  }

  /**
   * Returns parent station
   * @returns {StationSummary | undefined}
   */
  getParentStation() {
    return this.data.parentStation && new StationSummary(this.data.parentStation);
  }

  /**
   * Returns parent title
   * @returns {String}
   */
  getParentTitle() {
    return this.data.parentTitle;
  }

  /**
   * Returns podcast type
   * @returns {String}
   */
  getPodcastType() {
    return this.data.podcastType;
  }

  /**
   * Returns title
   * @returns {String}
   */
  getTitle() {
    return this.data.title;
  }
}
