import { getEpisodesBySchedule } from '@audacy-clients/core/atoms/schedules';
import { type IModuleViewComponent } from '@audacy-clients/core/atoms/wrappers/modules';
import { useModuleContext } from '@audacy-clients/core/components/ModuleRenderer/context';
import { type IModuleProps } from '@audacy-clients/core/components/ModuleRenderer/types';
import { useEffect, useState } from 'react';
import { useRecoilValueLoadable } from 'recoil';

import ModuleInViewWrapper from '~/components/ModuleRenderer/ModuleInViewWrapper';

// TODO: CCS-3449: This is a mock module for the "Pick up where you left off" module UI
// This is built just for testing and will be updated while working on CCS-3449
const ModuleStationContinueListening: React.FC<IModuleProps> = ({
  module: { config },
  moduleMap,
  moduleViewComponent: ModuleViewComponent,
}): JSX.Element | null => {
  const { viewRootObjectId: contentId } = useModuleContext();
  const [previousScheduleGroups, setPreviousScheduleGroups] = useState<IModuleViewComponent | null>(
    null,
  );

  // NOTICE: useRecoilValueLoadable - was added here to avoid unnecessary screen re-renders
  // due to suspens which is at the screen level
  const scheduleGroupsLoadable = useRecoilValueLoadable(
    getEpisodesBySchedule({ stationId: contentId }),
  );

  const scheduleGroups = scheduleGroupsLoadable.valueMaybe();

  // NOTICE: this code is needed to save the previous list data while the new ones are being updated
  useEffect(() => {
    if (scheduleGroups?.module) {
      setPreviousScheduleGroups(scheduleGroups);
    }
  }, [scheduleGroups]);

  const currentScheduleGroups = scheduleGroups?.module ? scheduleGroups : previousScheduleGroups;

  if (!currentScheduleGroups?.module) {
    return null;
  }

  return (
    <ModuleViewComponent
      getData={currentScheduleGroups.getContent}
      module={{
        ...currentScheduleGroups.module,
        config,
      }}
      moduleMap={moduleMap}
      moduleWrapper={ModuleInViewWrapper}
    />
  );
};

export default ModuleStationContinueListening;
