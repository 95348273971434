import EntityImage, { IEntityImageProps } from '~/components/Entity/components/EntityImage';
import Icon from '~/components/Icon';
import { Icons, isIcon } from '~/components/Icon/constants';
import useLinkOrButton from '~/hooks/use-link-or-button';
import { TStyles } from '~/types/emotion-styles';
import { IGlobalAsAnchorProps, IGlobalAsButtonProps, LinkType } from '~/types/links';

import styles from './styles';

interface IMediaLinkProps {
  buttonCss?: TStyles;
  label: string;
  media: Icons | IEntityImageProps;
}

export interface IMediaLinkAnchorProps extends IGlobalAsAnchorProps, IMediaLinkProps {}
export interface IMediaLinkButtonProps extends IGlobalAsButtonProps, IMediaLinkProps {}
export type TMediaLinkProps = IMediaLinkAnchorProps | IMediaLinkButtonProps;

const MediaLink = ({
  as = LinkType.Anchor,
  buttonCss,
  label,
  media,
  ...rest
}: TMediaLinkProps): JSX.Element => {
  const children = (
    <>
      {isIcon(media) ? (
        <Icon name={media} ariaHidden />
      ) : (
        <EntityImage {...media} entityImageCss={styles.image} />
      )}
      <span>{label}</span>
    </>
  );

  const mediaLinkStyles = [styles.link, buttonCss];

  return useLinkOrButton({
    props: { as, ...rest },
    children,
    styles: mediaLinkStyles as TStyles,
  });
};

export default MediaLink;
