import { type IFeatureFlags } from '@audacy-clients/core/atoms/config/settings';
import { AmperwaveLocationOptions, Platform } from '../../../../Constants';
import {
  type IMarketingDataProvider,
  type ICredentialsProvider,
  type ILocationProvider,
} from '../../../../Container';
import { type TPlayableObject } from '../../../types';

type IAmperwaveOptions = {
  platform: Platform;
  marketingDataProvider?: IMarketingDataProvider;
  credentialsProvider?: ICredentialsProvider;
  locationProvider?: ILocationProvider;
  item: TPlayableObject;
  sessionId?: string;
  featureFlags?: IFeatureFlags;
};

export async function appendAmperwaveLocationParams(uri: URI, options: IAmperwaveOptions) {
  const { platform } = options;

  if (platform === Platform.ANDROID || platform === Platform.IOS) {
    return await appendAmperwaveLocationParamsForMobile(uri, options);
  }

  return appendAmperwaveLocationParamsForWeb(uri, options);
}

export async function appendAmperwaveLocationParamsForWeb(uri: URI, options: IAmperwaveOptions) {
  const { state } = await navigator.permissions.query({ name: 'geolocation' });
  const { locationProvider, featureFlags } = options;

  // if location is already granted, skip deriving location params and return URI with location params
  if (state === 'granted') {
    uri.removeQuery('sc');

    return uri.addQuery({
      ...(locationProvider?.lat ? { lat: locationProvider?.lat } : {}),
      ...(locationProvider?.long ? { long: locationProvider?.long } : {}),
    });
  }

  return featureFlags?.enableSimulcastOverride
    ? deriveLocationParamsForWebWithOverride(uri)
    : deriveLocationParamsForWeb(uri, state);
}

export async function appendAmperwaveLocationParamsForMobile(uri: URI, options: IAmperwaveOptions) {
  const { locationProvider } = options;

  if (locationProvider?.lat) {
    uri.addQuery('lat', locationProvider.lat);
  }

  if (locationProvider?.long) {
    uri.addQuery('long', locationProvider.long);
  }

  if (!locationProvider?.lat && !locationProvider?.long) {
    uri.addQuery('sc', AmperwaveLocationOptions.FORCE_SIMULCAST_OFF);
  }

  // Only wrapped to match the Promise return type signature of the web function
  return Promise.resolve(uri);
}

export function deriveLocationParamsForWeb(uri: URI, state: PermissionState) {
  switch (state) {
    case 'denied':
      return uri.addQuery('sc', AmperwaveLocationOptions.FORCE_SIMULCAST_OFF);
    default:
      return uri.addQuery('sc', AmperwaveLocationOptions.APPLY_AMPERWAVE_GEOLOCATION);
  }
}

export function deriveLocationParamsForWebWithOverride(uri: URI) {
  return uri.addQuery('sc', AmperwaveLocationOptions.FORCE_SIMULCAST_OFF);
}
