import { useCollectionItems } from '@audacy-clients/core/atoms/collections';
import { type IContentSummary } from '@audacy-clients/core/atoms/wrappers/types';
import { getPlaylistCountAndDuration } from '@audacy-clients/core/utils/playlist';
import i18n from 'i18next';
import React, { type FC } from 'react';

import List from '~/components/List';
import PlaylistItem from '~/components/Playlist/PlaylistItem';

import styles from './styles';

interface IPlaylistProps {
  durationSeconds: number;
  itemIds: string[];
  playlistId: string;
}

const Playlist: FC<IPlaylistProps> = ({ itemIds, durationSeconds, playlistId }) => {
  const ariaLabel = 'Playlist';
  const playlistItems = useCollectionItems(itemIds);

  if (!playlistItems?.length) {
    return null;
  }

  const count = playlistItems?.length ?? 0;
  const countAndDuration = getPlaylistCountAndDuration(count, durationSeconds, 'comma', {
    i18n,
    playlistCountKey: 'playlistScreen.playlistCount',
    playlistDurationKey: 'playlistScreen.playlistDuration',
  });

  const renderItem = ({ item }: { item: IContentSummary }): JSX.Element => (
    <PlaylistItem item={item} playlistId={playlistId} />
  );

  return (
    <div>
      {countAndDuration && <p css={styles.title}>{countAndDuration}</p>}

      <div css={styles.separator} />

      <List ariaLabel={ariaLabel} items={playlistItems} renderItem={renderItem} />
    </div>
  );
};

export default Playlist;
