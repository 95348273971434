export const pick = <T>(array: T[]): T => {
  return array[Math.floor(Math.random() * array.length)];
};

export const chunkArray = <T>(array: T[], size: number): T[][] => {
  const result = [];

  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size);
    result.push(chunk);
  }

  return result;
};
