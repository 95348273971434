import { useEffect } from 'react';

import { IModuleWrapperProps } from '@audacy-clients/core/components/ModuleRenderer/types';
import clientServices from '@audacy-clients/core/utils/clientServices';
import { useTrackVisibility } from 'react-intersection-observer-hook';
import { useRecoilValue } from 'recoil';

import usePrevious from '~/hooks/use-previous';
import { viewContext } from '~/state/dataEvents';

const ModuleInViewWrapper = ({
  children,
  isTopLevel,
  module,
}: IModuleWrapperProps): JSX.Element => {
  const { viewId, contentId } = useRecoilValue(viewContext);
  const [moduleRef, { isVisible: isOnScreen }] = useTrackVisibility();
  const prevIsOnScreen = usePrevious(isOnScreen, false);

  useEffect(() => {
    if (!prevIsOnScreen && isOnScreen) {
      clientServices.getAnalyticServices().sendModuleView({
        viewContext: { viewId, contentId },
        moduleId: module.id,
        contentId: module.config.contentId,
      });
    }
  }, [isOnScreen, module, prevIsOnScreen, viewId, contentId]);

  if (!isTopLevel) {
    return <>{children}</>;
  }

  // Don't fade, it causes heavy jank
  return <div ref={moduleRef}>{children}</div>;
};

export default ModuleInViewWrapper;
