import {
  VideoMemberEventParams,
  VideoRoomAudienceCountEventParams,
  VideoMemberEntity,
} from '@signalwire/core';
import { AnyStateMachine, StateValueMap } from 'xstate';

import { type IFeatureFlags } from '@audacy-clients/core/atoms/config/settings';
import AnalyticServices from '../analyticServices';
import { IDeviceInfoProvider } from '../Config';
import { Platform, Preference } from '../Constants';
import { IMarketingDataProvider, ILocationProvider, ICredentialsProvider } from '../Container';
import DataServices, { PlaybacksHashMap } from '../dataServices/DataServices';
import Episode from '../dataServices/Episode';
import StandaloneChapter from '../dataServices/StandaloneChapter';
import Station from '../dataServices/Station';
import { IddError, IddLogger, ILogger } from '../logger';
import PersonalizationServices, { UnauthenticatedServices } from '../personalizationServices';
import { IPlayerSettingsProvider } from '../personalizationServices/types';
import { IPlayer, TPlaybackRate, IPlayerMetadata } from './players/types';
import { IStreamerState, ITime } from './streamers/types';
import { findStreamer, initialize, startStreamer } from './utils';

export interface IAudioServicesStateListenerParams {
  audioServicesState: IAudioServicesState;
  streamerState?: IStreamerState<TPlayableObject>;
}

export type TAudioServicesListener = (params: IAudioServicesStateListenerParams) => void;

export interface IAudioServicesSignalWireListenerParams {
  type: string;
  data?: TSignalWireEventData;
}

export type TAudioServicesSignalWireListener = (
  params: IAudioServicesSignalWireListenerParams,
) => void;

export enum EContentType {
  ExclusiveStation = 'EXCLUSIVE_STATION',
  LiveStation = 'LIVE_STATION',
  Podcast = 'PODCAST',
  RewindNoReplay = 'LIVE_REWIND_NO_REPLAY',
  Rewind = 'LIVE_REWIND',
}

export type TPlayableObject = Episode | Station | StandaloneChapter;

// TODO: [CCS-1380] Rename IPersonalizationProvider
export type IPersonalizationProvider = Pick<
  PersonalizationServices,
  'getPlaybacks' | 'setPlayback' | 'setPlaybacks' | 'getQueue' | 'isInTopOfHistoryStack' | 'addToHistory' | 'callBrazeEvent'
>;

export type IUnauthenticatedProvider = Pick<
  UnauthenticatedServices,
  | 'setUnauthenticatedPlaybackTime'
  | 'getUnauthenticatedPlaybackTime'
  | 'getMaxFreeListeningTime'
  | 'dataStore'
  | 'unauthenticatedPlaybackTime'
  | 'isNewDay'
  | 'resetPlayedContentDaily'
>;

export type IAnalyticsProvider = Pick<
  AnalyticServices,
  | 'sendPlayerEvent'
  | 'platform'
  | 'sendEventToListener'
  | 'sendSleepTimerSetEvent'
  | 'sendPlaybackErrorEvent'
  | 'sendSongListenedEvent'
>;

// TODO: [CCS-487] Remove once DataServices is ported to TS
export type IDataProvider = Pick<
  DataServices,
  'getSegments' | 'getContentObject' | 'updateInteractionTime' | 'sessionId' | 'env' | 'stats'
>;

export enum ESleepTimerState {
  Idle = 'IDLE',
  EndOfEpisode = 'END_OF_EPISODE',
}

export enum ECollectionPlaybackMode {
  SingleItem = 'SINGLE_ITEM',
  Queue = 'QUEUE',
  Playlist = 'PLAYLIST',
}
export interface IMachineContext {
  platform: Platform;
  player: IPlayer;
  personalizationProvider: IPersonalizationProvider;
  unauthenticatedProvider?: IUnauthenticatedProvider;
  playerSettingsProvider: IPlayerSettingsProvider;
  dataProvider: IDataProvider;
  marketingDataProvider?: IMarketingDataProvider;
  locationProvider?: ILocationProvider;
  credentialsProvider?: ICredentialsProvider;
  signalWireEventListener?: TAudioServicesSignalWireListener;
  logger: ILogger;
  ddError: IddError;
  ddLogger?: IddLogger;
  autoplay: boolean;
  playLive: boolean;
  analyticsProvider: IAnalyticsProvider;
  skipsCount: number;
  deviceInfoProvider?: IDeviceInfoProvider;
}
export interface IAudioServicesMachineContext extends IMachineContext {
  isMuted: boolean; // Separate from volume so that we can retain pre-muted volume when unmuting
  volume: number; // 0 to 1 inclusive
  rate: TPlaybackRate;
  sleepTimerState: number | ESleepTimerState; // Epoch time in milliseconds, 'Idle', or 'EndOfEpisode'
  activePlayableObject?: TPlayableObject; // undefined when machine is idle
  activeCollection: TPlayableObject[]; // Empty array for single-item playback mode
  analyticsProvider: IAnalyticsProvider;
  deviceInfoProvider?: IDeviceInfoProvider;
  playbacks: PlaybacksHashMap;
  startOffset?: number; // undefined when machine is idle
  collectionPlaybackMode: ECollectionPlaybackMode;
  currentStreamerMachine?: AnyStateMachine;
  playbackTimer: {
    elapsed: ITime;
    accumulator: ITime;
    lastStartedTime?: ITime;
  };
  featureFlags?: IFeatureFlags;
}

export interface IAudioEventListenerContext {
  isMuted: boolean; // Separate from volume so that we can retain pre-muted volume when unmuting
  volume: number; // 0 to 1 inclusive
  rate: TPlaybackRate;
  sleepTimerState: number | ESleepTimerState; // Epoch time in milliseconds, 'Idle', or 'EndOfEpisode'
  activePlayableObject?: TPlayableObject; // undefined when machine is idle
  playbacks: PlaybacksHashMap;
  activeCollection: TPlayableObject[];
  collectionPlaybackMode: ECollectionPlaybackMode;
}

export interface IAudioServicesState {
  value: StateValueMap;
  context: IAudioEventListenerContext;
}

export interface IFailure {
  type: 'REPORT_FAILURE';
  errorMessage: string;
  error?: {
    type: string;
    raw?: unknown;
  };
}

export type TPlayerReportEvent =
  | { type: 'REPORT_TIME_UPDATE'; time: number; duration?: number }
  | { type: 'REPORT_METADATA'; metadata: IPlayerMetadata }
  | { type: 'REPORT_ENDED'; shouldDestroy?: boolean }
  | { type: 'REPORT_LOADING' }
  | { type: 'REPORT_LOADED' }
  | { type: 'DESTROY' }
  | IFailure
  | {
      type: 'REPORT_PLAYBACK_FAILED';
      errorMessage: string;
      error?: {
        type: string;
        raw?: unknown;
      };
    }
  | { type: 'REPORT_PLAYING' }
  | { type: 'REPORT_PAUSED' }
  | { type: 'REPORT_AUTOPLAY_POLICY' }
  | {
      type: 'REPORT_CONTINUE_PLAYING';
      playlistNextStartTime: number;
      playLive?: boolean;
    };

export type TSignalWireEventData =
  | VideoMemberEventParams
  | VideoRoomAudienceCountEventParams
  | { members: VideoMemberEntity[] };

export type TSignalWireReportEvent =
  | { type: 'REPORT_ROOM_JOINED'; data: TSignalWireEventData }
  | { type: 'REPORT_ROOM_LEFT' }
  | { type: 'REPORT_ROOM_UPDATED'; data: TSignalWireEventData }
  | { type: 'REPORT_MEMBER_TALKING'; data: TSignalWireEventData }
  | { type: 'REPORT_MEMBER_UPDATED'; data: TSignalWireEventData }
  | { type: 'REPORT_MEMBER_LIST_UPDATED'; data: TSignalWireEventData };

export type TStreamerMachineEvent =
  | { type: 'PAUSE'; shouldDestroy?: boolean }
  | { type: 'STOP' }
  | { type: 'PLAY'; autoplay: boolean }
  | { type: 'STOP' }
  | { type: 'SKIP' }
  | { type: 'GO_TO_LIVE' }
  | { type: 'SEEK_TIME'; time: number }
  | { type: 'SEND_PREFERENCE'; preference: Preference }
  | { type: 'DESTROY' }
  | { type: 'SET_MIC_MUTED'; isMuted: boolean }
  | {
      type: 'SET_SIGNAL_WIRE_EVENT_LISTENER';
      signalWireEventListener: TAudioServicesSignalWireListener;
    }
  | TPlayerReportEvent
  | TSignalWireReportEvent;

export type TAudioServicesMachineEvent =
  | {
      type: 'LOAD_OBJECT';
      data: TPlayableObject;
      autoplay: boolean;
      offset?: number;
      playLive: boolean;
    }
  | { type: 'UPDATE_COLLECTION'; data: TPlayableObject[] }
  | { type: 'UPDATE_PLAYBACKS'; data: PlaybacksHashMap }
  | { type: 'SET_SLEEP_TIMER'; sleepTimer: number | ESleepTimerState }
  | { type: 'CANCEL_SLEEP_TIMER' }
  | { type: 'SET_MUTED'; isMuted: boolean }
  | { type: 'SET_VOLUME'; volume: number }
  | { type: 'SET_PLAYBACK_RATE'; rate: TPlaybackRate }
  | { type: 'SET_COLLECTION_PLAYBACK_MODE'; mode: ECollectionPlaybackMode }
  | { type: 'INCREMENT_PLAYBACK_RATE' }
  | { type: 'NEXT'; shouldSkipCompletedItems?: boolean }
  | { type: 'PREVIOUS'; shouldSkipCompletedItems?: boolean }
  | { type: 'SCRUB' }
  | TStreamerMachineEvent;

export type TAudioServicesMachineServices = {
  initialize: {
    data: Awaited<ReturnType<typeof initialize>>;
  };
  findStreamer: {
    data: Awaited<ReturnType<typeof findStreamer>>;
  };
  startStreamer: {
    data: ReturnType<typeof startStreamer>;
  };
};
