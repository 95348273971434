import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router';

import Brand, { BrandVariants } from '~/components/Brand';
import { Path } from '~/routes';
import { Colors } from '~/styles';
import { TStyles } from '~/types/emotion-styles';

export interface IAudacyLogoButtonProps {
  onClose: () => void;
  navigate: NavigateFunction;
  isHome?: boolean;
  logoCss?: TStyles;
  containerCss?: TStyles;
}

const AudacyLogoButton = ({
  containerCss,
  logoCss,
  onClose,
  navigate,
  isHome,
}: IAudacyLogoButtonProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <button
      css={containerCss}
      aria-label={t('navigation.ariaLabels.logo')}
      onClick={() => {
        onClose();
        if (isHome) {
          window.scrollTo(0, 0);
        } else {
          navigate(Path.Home);
        }
      }}
    >
      <Brand containerCss={logoCss} color={Colors.white} variant={BrandVariants.Horizontal} />
    </button>
  );
};

export default AudacyLogoButton;
