import { useCallback } from 'react';

import { IToast, toastAtom } from '@audacy-clients/core/atoms/toast';
import { IToastHook } from '@audacy-clients/core/types/toast';
import first from 'lodash/first';
import { useRecoilState } from 'recoil';

export default function useToast(): IToastHook {
  const [toastState, setToastState] = useRecoilState(toastAtom);

  const currentToast = first(toastState.queue);

  const showToast = useCallback(
    (toast: IToast): void => {
      const newToast = {
        ...toast,
        id: new Date().getUTCMilliseconds().toString(),
      };
      setToastState((oldstate) => ({
        ...oldstate,
        queue: [...oldstate.queue, newToast],
      }));
    },
    [setToastState],
  );

  const popToast = (): void => {
    setToastState((oldState) => {
      if (oldState.queue.length) {
        const queue = [...oldState.queue];

        queue.shift();

        return {
          ...oldState,
          queue,
        };
      }

      return oldState;
    });
  };

  return {
    amountQueued: toastState.queue.length,
    currentToast,
    popToast,
    showToast,
  };
}
