export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Video</title>
    <path fill="currentColor" d="M4 7h16.5v18H4zM28 10l-7.5 3v6l7.5 3V10Z" />
  </svg>
);
