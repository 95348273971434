import { useCachedEarlierToday } from '@audacy-clients/core/atoms/earlierToday';
import { useModuleContext } from '@audacy-clients/core/components/ModuleRenderer/context';
import { IModuleProps } from '@audacy-clients/core/components/ModuleRenderer/types';

import ModuleInViewWrapper from '~/components/ModuleRenderer/ModuleInViewWrapper';

const ModuleEarlierToday: React.FC<IModuleProps> = ({
  module: { config },
  moduleMap,
  moduleViewComponent,
}): JSX.Element | null => {
  const { viewRootObjectId: contentId } = useModuleContext();

  const earlierToday = useCachedEarlierToday({
    stationId: contentId,
  });

  if (!contentId || !earlierToday || !earlierToday.module) {
    return null;
  }
  const { module: earlierTodayModule, getContent } = earlierToday;

  const ModuleViewComponent = moduleViewComponent;

  return (
    <ModuleViewComponent
      getData={getContent}
      module={{
        ...earlierTodayModule,
        config,
      }}
      moduleMap={moduleMap}
      moduleWrapper={ModuleInViewWrapper}
    />
  );
};

export default ModuleEarlierToday;
