import { atom } from 'recoil';

interface IWindowSize {
  height?: number;
  width?: number;
}

export const windowSizeAtom = atom<IWindowSize>({
  key: 'windowSize',
  default: {
    height: 0,
    width: 0,
  },
});
