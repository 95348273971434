import { keyframes } from '@emotion/react';

export enum LineWidth {
  Thin = 'Thin',
  Medium = 'Medium',
  Thick = 'Thick',
}

export const lineBounce = keyframes`
  0% {
    transform: scaleY(1);
  }
  20% {
    transform: scaleY(0.6);
  }
  40% {
    transform: scaleY(1.4);
  }
  60% {
    transform: scaleY(0.9);
  }
  80% {
    transform: scaleY(1.2);
  }
  100% {
    transform: scaleY(1);
  }
`;
