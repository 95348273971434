export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Google logo</title>

    <path
      d="M16 14.425h10.384c.115.64.172 1.25.172 1.833 0 2.072-.434 3.922-1.303 5.55a9.41 9.41 0 0 1-3.717 3.817C19.926 26.542 18.082 27 16 27c-1.5 0-2.927-.289-4.283-.866-1.356-.578-2.525-1.359-3.509-2.342-.983-.984-1.764-2.154-2.341-3.51A10.814 10.814 0 0 1 5 16c0-1.5.289-2.927.867-4.283.577-1.356 1.358-2.525 2.341-3.509.984-.983 2.153-1.764 3.51-2.341A10.814 10.814 0 0 1 16 5c2.865 0 5.323.96 7.376 2.879l-2.993 2.879C19.208 9.622 17.747 9.053 16 9.053c-1.232 0-2.37.31-3.416.931a6.86 6.86 0 0 0-2.485 2.528A6.884 6.884 0 0 0 9.182 16c0 1.26.306 2.423.917 3.488a6.86 6.86 0 0 0 2.485 2.528 6.572 6.572 0 0 0 3.416.93c.83 0 1.595-.114 2.292-.343.697-.23 1.27-.516 1.718-.86a6.22 6.22 0 0 0 1.175-1.174c.334-.44.58-.855.738-1.246a5.26 5.26 0 0 0 .322-1.117H16v-3.781Z"
      fill="currentColor"
    />
  </svg>
);
