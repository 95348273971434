export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Music</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 7L29 2L29 8L29 9L29 20H28.9725C28.9907 20.1642 29 20.331 29 20.5C29 22.9853 26.9853 25 24.5 25C22.0147 25 20 22.9853 20 20.5C20 18.0147 22.0147 16 24.5 16C25.2184 16 25.8975 16.1684 26.5 16.4678V9.6579L12.5 13.3421V23H12.478C12.4926 23.1471 12.5 23.2964 12.5 23.4473C12.5 25.9326 10.4853 27.9473 8 27.9473C5.51472 27.9473 3.5 25.9326 3.5 23.4473C3.5 20.962 5.51472 18.9473 8 18.9473C8.71842 18.9473 9.39752 19.1157 10 19.4151V12H10V7Z"
      fill="currentColor"
    />
  </svg>
);
