import QS from 'qs';
import UrlParser from 'url-parse';

const FASTLY_DEFAULTS = {
  auto: 'webp',
};

const FASTLY_HOSTS = new Set([
  'images.radio.com',
  'images.audacy2-stg.int.audacy.com',
  'images.audacy2-prod.ext.audacy.com',
  'stg-images.radio.com',
]);

export enum ImageType {
  Thumb,
  Full,
}

export const generateImageUrl = (
  url: string,
  size: number,
  type: ImageType,
): string | undefined => {
  const isBase64Image = url.startsWith('data:image/');
  if (isBase64Image) {
    // Return the original Base64 image if it's Base64 encoded
    return url;
  }

  const parsedUrl = new UrlParser(url, true);
  const isFastlyUrl = FASTLY_HOSTS.has(parsedUrl?.host);

  if (isFastlyUrl) {
    // if it's a fastly URL, force the protocol to HTTPS to avoid load issues with legacy images
    parsedUrl.set('protocol', 'https');

    return parsedUrl.toString((q: { height?: number }): string => {
      // Some imageURIs from the CMS can contain width and height parameters among others.
      // Since we will override the width param ourselves, we need to clear existing height
      // to avoid distorted / skewed images.
      q.height && delete q.height;

      return QS.stringify({
        ...FASTLY_DEFAULTS,
        ...q,
        width: size,
      });
    });
  }

  if (type === ImageType.Thumb) {
    return undefined;
  }

  const widthQuery = `?auto=webp&width=${size}`;

  return url + widthQuery;
};
