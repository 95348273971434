import { useEffect, useRef, useState } from 'react';

import {
  noCacheDefaultSelector,
  noCacheDefaultSelectorFamily,
} from '@audacy-clients/core/atoms/helpers/noCacheDefaultSelector';
import { setRecoil } from '@audacy-clients/core/utils/recoil';
import isArray from 'lodash/isArray';
import {
  atom,
  atomFamily,
  CachePolicyWithoutEquality,
  GetCallback,
  GetRecoilValue,
  Loadable,
  ReadOnlySelectorFamilyOptions,
  ReadOnlySelectorOptions,
  RecoilValue,
  RecoilValueReadOnly,
  SerializableParam,
  useRecoilCallback,
  useRecoilTransaction_UNSTABLE,
  WrappedValue,
} from 'recoil';

import { CacheTimes } from './constants';
import useListenWhileMounted from './useListenWhileMounted';

interface IRefreshableSelectorConfig {
  cacheLimit?: number;
  refreshEverySeconds?: number;
}

const defaultConfig = {
  cacheLimit: CacheTimes.limit60s,
};

type TInterval = {
  interval: NodeJS.Timeout | undefined;
  listenersCount: number;
  shouldBlockRefreshes: boolean;
};

interface IRefreshableSelectorFamily<T, P> {
  selector: (param: P) => RecoilValueReadOnly<T>;
  useListener: (param: P | P[]) => void;
  useRefreshCache: (param: P | P[]) => (force?: boolean) => void;
  useWrappedCachedValue: (param: P) => T | undefined;
}

interface IRefreshableSelector<T> {
  selector: RecoilValueReadOnly<T>;
  useListener: () => void;
  useRefreshCache: () => (force?: boolean) => void;
  useWrappedCachedValue: () => T | undefined;
  forceRefresh: () => void;
}

interface ICustomReadOnlySelectorOptions<T>
  extends ReadOnlySelectorOptions<T>,
    IRefreshableSelectorConfig {}

const refreshableSelector = <T>(
  options: ICustomReadOnlySelectorOptions<T>,
): IRefreshableSelector<T> => {
  const { cacheLimit, refreshEverySeconds } = {
    ...defaultConfig,
    ...options,
  };

  const requestTime = atom({
    default: Date.now(),
    key: `${options.key}_RequestTime`,
  });

  const listener: TInterval = {
    interval: undefined,
    listenersCount: 0,
    shouldBlockRefreshes: false,
  };

  const sel = noCacheDefaultSelector<T>({
    ...options,
    get: (o) => {
      o.get(requestTime);
      return options.get(o);
    },
  });

  const forceRefresh = () => {
    setRecoil(requestTime, Date.now());
  };

  const useRefreshCache = (): ((force?: boolean) => void) => {
    return useRecoilTransaction_UNSTABLE(
      ({ get, set }) =>
        (forceUpdate) => {
          const lastUpdate = get(requestTime);

          const now = Date.now();
          if (forceUpdate || !lastUpdate || now - lastUpdate > cacheLimit) {
            set(requestTime, now);
          }
        },
      [],
    );
  };

  const useListener = (): void => {
    const setIsListening = useRecoilCallback(
      ({ set }) =>
        async (isSetting?: boolean) => {
          listener.listenersCount += isSetting ? 1 : -1;

          if (listener.listenersCount <= 0 && listener.interval) {
            clearInterval(listener.interval);
            listener.interval = undefined;
          } else if (!listener.interval && refreshEverySeconds !== undefined) {
            listener.interval = setInterval(() => {
              set(requestTime, Date.now());
            }, refreshEverySeconds);
          }
        },
      [],
    );

    useListenWhileMounted(setIsListening);
  };

  /**
   * Hook return cache values using loadables and state in a way that components
   * will use the most-recent value while loading a new one.
   */
  const useWrappedCachedValue = (): T | undefined => {
    const [, setNeedsRender] = useState(0);

    // Used to prevent state update if not mounted
    const mounted = useRef(false);
    useEffect(() => {
      mounted.current = true;
      return () => {
        mounted.current = false;
      };
    }, []);

    const callback = useRecoilCallback(
      ({ snapshot }) =>
        () => {
          const loadable = snapshot.getLoadable(sel);
          const maybe = loadable.valueMaybe();

          // Snapshots are only retained for the duration of the callback that obtained them.
          // To use them after that they should be explicitly retained using retain().
          // It must be retained in order to guarantee we can observe the resolved value.
          // Source: https://recoiljs.org/docs/api-reference/core/Snapshot/#asynchronous-use-of-snapshots
          // Without this, loadable obj will be cancelled (triggers catch method).
          const releaseSnapshot = snapshot.retain();

          if (maybe === undefined && loadable.state === 'loading') {
            loadable
              // returns a Promise that will resolve when the selector has resolved.
              // If the selector is synchronous or has already resolved it returns a Promise that resolves immediately.
              .toPromise()
              .then(() => mounted.current && setNeedsRender((n) => n + 1))
              .catch(() => {
                /* This promise can get cancelled and spam the console, so catch. */
              })
              .finally(() => releaseSnapshot());
          }
          return maybe;
        },
      [setNeedsRender],
    );

    useListener();

    return callback();
  };

  return {
    selector: sel,
    useListener,
    useRefreshCache,
    useWrappedCachedValue,
    forceRefresh,
  };
};

interface ICustomReadOnlySelectorFamilyOptions<T, P extends SerializableParam>
  extends Omit<ReadOnlySelectorFamilyOptions<T, P>, 'get'>,
    IRefreshableSelectorConfig {
  key: string;
  get: (
    param: P,
  ) => (opts: {
    get: GetRecoilValue;
    getCallback: GetCallback;
    blockRefreshes: () => void;
  }) => Promise<T> | RecoilValue<T> | Loadable<T> | WrappedValue<T> | T;
  cachePolicy_UNSTABLE?: CachePolicyWithoutEquality;
  dangerouslyAllowMutability?: boolean;
  /**
   * This prop creates a gate to automatically render some additional hooks
   * when `useWrappedCachedValue` is called.
   *
   * The most common use case it's to render other dependents refreshable
   * selector's useListeners. So if you're creating a refreshable selector
   * that relies on another one, you must add their `useListener` here.
   */
  useAdditionalListenerHook?: (param: P) => void;
}

export const refreshableSelectorFamily = <T, P extends SerializableParam>(
  options: ICustomReadOnlySelectorFamilyOptions<T, P>,
): IRefreshableSelectorFamily<T, P> => {
  const { cacheLimit, refreshEverySeconds } = {
    ...defaultConfig,
    ...options,
  };

  const requestTimes = atomFamily<number, P>({
    default: Date.now(),
    key: `${options.key}_RequestTimes`,
  });

  const listeners = new Map<P, TInterval>();

  const sel = noCacheDefaultSelectorFamily<T, P>({
    ...options,
    get: (param) => (o) => {
      o.get(requestTimes(param));
      return options.get(param)({
        ...o,
        blockRefreshes: () => {
          const listener = listeners.get(param);

          if (listener) {
            listener.shouldBlockRefreshes = true;
          } else {
            listeners.set(param, {
              interval: undefined,
              listenersCount: 0,
              shouldBlockRefreshes: true,
            });
          }
        },
      });
    },
  });

  const useRefreshCache = (param: P | P[]): ((force?: boolean) => void) => {
    const params = isArray(param) ? param : [param];

    return useRecoilTransaction_UNSTABLE(
      ({ get, set }) =>
        (force?: boolean) => {
          for (const p of params) {
            const state = requestTimes(p);
            const lastUpdate = get(state);
            const now = Date.now();

            if (force || !lastUpdate || now - lastUpdate > cacheLimit) {
              set(state, now);
            }
          }
        },
      [],
    );
  };

  const useListener = (param: P | P[]): void => {
    const params = isArray(param) ? param : [param];

    const setIsListening = useRecoilCallback(
      ({ set }) =>
        async (isSetting?: boolean) => {
          for (const p of params) {
            if (!listeners.has(p)) {
              listeners.set(p, {
                interval: undefined,
                listenersCount: 0,
                shouldBlockRefreshes: false,
              });
            }

            const listener = listeners.get(p) as TInterval;
            const requestTimesState = requestTimes(p);

            listener.listenersCount += isSetting ? 1 : -1;

            if (
              (listener.listenersCount <= 0 && listener.interval) ||
              listener.shouldBlockRefreshes
            ) {
              listener.interval && clearInterval(listener.interval);
              listener.interval = undefined;
            } else if (!listener.interval && refreshEverySeconds !== undefined) {
              listener.interval = setInterval(() => {
                set(requestTimesState, Date.now());
              }, refreshEverySeconds);
            }
          }
        },
      [],
    );

    useListenWhileMounted(setIsListening);
  };

  /**
   * Hook return cache values using loadables and state in a way that components
   * will use the most-recent value while loading a new one.
   */
  const useWrappedCachedValue = (param: P): T | undefined => {
    const [, setNeedsRender] = useState(0);

    const callback = useRecoilCallback(
      ({ snapshot }) =>
        () => {
          const loadable = snapshot.getLoadable(sel(param));
          const maybe = loadable.valueMaybe();

          // Snapshots are only retained for the duration of the callback that obtained them.
          // To use them after that they should be explicitly retained using retain().
          // It must be retained in order to guarantee we can observe the resolved value.
          // Source: https://recoiljs.org/docs/api-reference/core/Snapshot/#asynchronous-use-of-snapshots
          // Without this, loadable obj will be cancelled (triggers catch method).
          const releaseSnapshot = snapshot.retain();

          if (maybe === undefined && loadable.state === 'loading') {
            loadable
              // returns a Promise that will resolve when the selector has resolved.
              // If the selector is synchronous or has already resolved it returns a Promise that resolves immediately.
              .toPromise()
              .then(() => setNeedsRender((n) => n + 1))
              .catch(() => {
                /* This promise can get cancelled and spam the console, so catch. */
              })
              .finally(() => releaseSnapshot());
          }
          return maybe;
        },
      [setNeedsRender, param],
    );

    useListener(param);
    options.useAdditionalListenerHook?.(param);

    return callback();
  };

  return {
    selector: sel,
    useListener,
    useRefreshCache,
    useWrappedCachedValue,
  };
};

export default refreshableSelector;
