import { Colors, Mixins, mqHover, Outlines, Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderRadius: 100,
    color: Colors.audacyDarkNavy,
    display: 'inline-flex',
    justifyContent: 'center',
    padding: 20,
    pointerEvents: 'initial',
  },
  title: {
    ...Typography.metadataSmall,
    marginRight: 30,
  },
  cta: {
    ...Mixins.accessibleMinButtonSize(),
    ...Mixins.focusOutline({ outline: `${Outlines.focusDark}` }),
  },
  ctaDivider: {
    display: 'flex',
    alignItems: 'center',
    '&::after': {
      backgroundColor: Colors.greyC5,
      content: '""',
      height: 9,
      margin: '0 20px',
      width: 1,
    },
  },
  ctaLabel: {
    ...Mixins.accessibleMinButtonSize(),
    ...Mixins.transition('color'),
    color: Colors.audacyDarkNavy,
    fontWeight: 700,
    ...mqHover({
      color: Colors.audacyDarkNavy60,
    }),
  },
  closeButton: {
    ...Mixins.focusOutline({ outline: `${Outlines.focusDark}` }),
  },
};

export default styles;
