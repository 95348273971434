import { Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 20,
  },
  copy: {
    ...Typography.buttonLarge,
  },
};

export default styles;
