import {
  type ECollectionPlaybackMode,
  type TPlayableObject,
} from '@audacy-clients/client-services/src/audioServices/types';
import clientServices from '@audacy-clients/core/utils/clientServices';
import { reorderContentSummariesById } from '@audacy-clients/core/utils/objects';
import { atom } from 'recoil';

import { contentQuery } from './content';
import { RefreshTimes } from './helpers/constants';
import { noCacheDefaultSelectorFamily } from './helpers/noCacheDefaultSelector';
import { refreshableSelectorFamily } from './helpers/refreshable';
import { wrapCollection } from './wrappers/collection';
import { wrapContentSummary } from './wrappers/content';
import { type ICollection, type IContentSummary } from './wrappers/types';

export const collectionQuery = noCacheDefaultSelectorFamily({
  get:
    (collectionId?: string) =>
    ({ get }) => {
      if (!collectionId) {
        return;
      }
      try {
        const collection = get(contentQuery<ICollection>(collectionId))?.getDataObject();
        if (!collection) {
          return;
        }
        return wrapCollection(collection);
      } catch (_e) {
        // empty
      }
    },
  key: 'CollectionQuery',
});

export const { useWrappedCachedValue: useCollectionItems } = refreshableSelectorFamily<
  Array<IContentSummary>,
  Array<string>
>({
  get: (ids) => async () => {
    if (!ids.length) {
      return [];
    }
    const { content } = await clientServices.getDataServices().getContentSummaries(ids);

    const playlistItems = Object.values(content);

    const orderedPlaylistItems = reorderContentSummariesById(ids, playlistItems);

    return orderedPlaylistItems.map(wrapContentSummary);
  },
  key: 'CollectionItems',
  refreshEverySeconds: RefreshTimes.every30s,
});

export const collectionPlaybackModeState = atom<ECollectionPlaybackMode | undefined>({
  default: undefined,
  key: 'CollectionPlaybackModeState',
});

export const activeCollectionState = atom<Array<TPlayableObject>>({
  default: [],
  key: 'ActiveCollectionState',
});

export const selectedPlaylistIdState = atom<string | undefined>({
  default: undefined,
  key: 'SelectedPlaylistIdState',
});
