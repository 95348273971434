import { isSameDay, isBefore } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import URI from 'urijs';
import { type IBrazeInstance } from '../@types/braze';
import AudacyAuthError from '../AudacyAuthError';
import { type TPlaybackRate } from '../audioServices/players/types';
import { type AdvertisingIdFunction } from '../Config';
import {
  AuthState,
  type Environment,
  EnvironmentHosts,
  EpisodeSubType,
  FOLLOWS,
  ModuleType,
  Platform,
  StationSubType,
  TWELVE_HOURS,
} from '../Constants';
import { type ICredentialsProvider, type IMarketingDataProvider } from '../Container';
import { type ContentSummary, type PlaybacksHashMap } from '../dataServices/DataServices';
import { type IHistoryEntry, type IServerQueue } from '../dataServices/types';
import { enableObjectTracing, type ILogger } from '../logger';
import { validateZipCode } from '../Utilities';
import { cache } from '../utils';
import { EMPTY_QUEUE, LocalStorageKey, VIEW_TYPE_VALUES } from './constants';
import type IDataStore from './IDataStore';
import { addToQueueAndDedupe, indexInQueue, setCurrentIdIfFound } from './queueUtils';
import {
  Gender,
  type IAutoHomeWithSummary,
  type IFollowsProvider,
  type IHistoryEntryWithSummary,
  type IPlayerSettingsProvider,
  type IProfile,
  type IProfileProvider,
  type IQueue,
  type IQueueProvider,
  type TAuthState,
  type TClientProvidedAuthState,
  type TExperienceAPI,
  type TPlaybacksListener,
  type TProfileAPI,
  type TProfilePatch,
  type TQueueListener,
  type IAdTargetingEligibility,
  type IAdTargeting,
  type AdTargetingStatusParams,
  type IPreferences,
  type TPreferencesPatch,
  type TPlayback,
  type TQueueItem,
  isQueueItemIsEpisode,
  isQueueItemStandaloneChapter,
} from './types';
import { reconcilePlaybackResumePoints, reconcileHistory } from './utils';

/**
 * @typedef CampaignData
 * @property {String} name
 * @property {String} medium
 * @property {String} source
 */

interface IPersonalizationServicesConfig {
  appName: string;
  appVersion: string;
  dataStore: IDataStore;
  env: Environment;
  eventListener?: (error?: Error) => void;
  experienceApi: TExperienceAPI;
  getAdvertisingId?: AdvertisingIdFunction;
  language: string;
  logger?: ILogger;
  platform: Platform;
  playerVolume?: number;
  profileApi: TProfileAPI;
  profileEventListener?: (profile: IProfile) => void;
  historyEventListener?: () => void;
  callBrazeEvent?: IBrazeInstance['logCustomEvent'];
  removeFromBrazeAttributeArray?: IBrazeInstance['removeFromCustomUserAttributeArray'];
  addToBrazeAttributeArray?: IBrazeInstance['addToCustomUserAttributeArray'];
}

interface IUnauthenticatedServicesConfig {
  dataStore: IDataStore;
}

interface IAuthConfig
  extends Pick<IPersonalizationServicesConfig, 'appName' | 'appVersion' | 'language'> {
  plat: string;
  baseUrl: string;
}

export class UnauthenticatedServices {
  dataStore: IDataStore;
  unauthenticatedPlaybackTime: number;
  private readonly maxFreeListeningTime: number | undefined;

  constructor(config: IUnauthenticatedServicesConfig) {
    this.dataStore = config.dataStore;
    this.unauthenticatedPlaybackTime =
      this.dataStore.getDataSync(LocalStorageKey.UNAUTHENTICATED_PLAYBACK_TIME) ?? 0;
    this.maxFreeListeningTime = this.dataStore.getDataSync(
      LocalStorageKey.UNAUTHENTICATED_PLAYBACK_MAX_DURATION,
    );
    this.isNewDay() && this.resetPlayedContentDaily();
  }

  getUnauthenticatedPlaybackTime() {
    return this.unauthenticatedPlaybackTime;
  }

  getMaxFreeListeningTime() {
    return this.maxFreeListeningTime;
  }

  setUnauthenticatedPlaybackTime(value: number) {
    this.unauthenticatedPlaybackTime = value;
    this.dataStore.setDataSync(LocalStorageKey.UNAUTHENTICATED_PLAYBACK_TIME, value);
  }

  resetPlayedContentDaily() {
    this.setUnauthenticatedPlaybackTime(0);
    this.dataStore.setDataSync(
      LocalStorageKey.UNAUTHENTICATED_PLAYBACK_LAST_RESET_DATE,
      new Date().getTime(),
    );
  }

  isNewDay() {
    const today = new Date().getTime(); // Get the current timestamp in milliseconds
    const lastResetTimestamp = this.dataStore.getDataSync(
      LocalStorageKey.UNAUTHENTICATED_PLAYBACK_LAST_RESET_DATE,
    ); // Retrieve the last reset timestamp from localStorage
    const lastReset = lastResetTimestamp ? +lastResetTimestamp : null; // Convert the stored string back to a number

    // Check if a new day has started (more than 86400000 milliseconds since last reset, where 86400000 is the number of milliseconds in one day)
    return !lastReset || !isSameDay(today, lastReset);
  }
}

class MarketingDataProvider implements IMarketingDataProvider {
  /* These members private to this file */
  profile?: IProfile;
  campaign?: IMarketingDataProvider['campaign'];

  constructor(private advertisingIdFunction?: AdvertisingIdFunction) {}

  /*
   * -- IMarketingDataProvider implementation --
   */

  get age(): number | undefined {
    const dateOfBirth = this.profile?.dateOfBirth;
    return dateOfBirth
      ? ((Date.now() - new Date(dateOfBirth).getTime()) / 3.15576e10) | 0
      : undefined;
  }

  get zip(): string | undefined {
    const zip = this.profile?.zipCode;
    if (zip && validateZipCode(zip)) {
      return zip;
    }
  }

  get yearofBirth(): string | undefined {
    const birthYear = this.profile?.birthYear;
    if (birthYear) {
      return `${birthYear}`;
    }
    const dateOfBirth = this.profile?.dateOfBirth;
    if (dateOfBirth) {
      return dateOfBirth.split('-')[0];
    }
  }

  get gender(): IMarketingDataProvider['gender'] {
    const gender = this.profile?.gender;
    switch (gender) {
      case Gender.MALE:
        return 'M';
      case Gender.FEMALE:
        return 'F';
    }
  }

  get getAdvertisingId(): AdvertisingIdFunction | undefined {
    return this.advertisingIdFunction;
  }
}

export default class PersonalizationServices
  implements
    IFollowsProvider,
    IQueueProvider,
    ICredentialsProvider,
    IProfileProvider,
    IPlayerSettingsProvider {
  dataStore: IDataStore;
  logger?: ILogger;
  playerVolume?: number;
  authData: TAuthState = { state: AuthState.NONE };
  private profileApi: TProfileAPI;
  private experienceApi: TExperienceAPI;
  private eventListener?: (error?: Error) => void;
  private queueListeners: TQueueListener[] = [];
  private playbacksListeners: TPlaybacksListener[] = [];
  private authConfig: IAuthConfig;
  private profileEventListener: IPersonalizationServicesConfig['profileEventListener'];
  private historyEventListener: IPersonalizationServicesConfig['historyEventListener'];
  private historyQueue: IHistoryEntry[] = [];
  private writingHistory = false;
  private _marketingDataProvider: MarketingDataProvider;
  public callBrazeEvent?: IBrazeInstance['logCustomEvent'];
  public removeFromBrazeAttributeArray?: IBrazeInstance['removeFromCustomUserAttributeArray'];
  public addToBrazeAttributeArray?: IBrazeInstance['addToCustomUserAttributeArray'];
  private currentHistorySessionId: string | undefined;
  private currentSessionId: string | undefined;

  constructor(config: IPersonalizationServicesConfig) {
    this.logger = config.logger;
    this.profileApi = config.profileApi;
    this.experienceApi = config.experienceApi;
    this.eventListener = config.eventListener;
    this.dataStore = config.dataStore;
    this.playerVolume = config.playerVolume;
    this.authConfig = {
      appName: config.appName,
      appVersion: config.appVersion,
      language: config.language,
      baseUrl: EnvironmentHosts[config.env],
      plat: (() => {
        switch (config.platform) {
          case Platform.ANDROID:
            return 'android';
          case Platform.ANDROID_CREATOR:
            return 'androidCreator';
          case Platform.IOS:
            return 'ios';
          case Platform.IOS_CREATOR:
            return 'iosCreator';
          case Platform.WEB:
          default:
            return 'ott';
        }
      })(),
    };
    this.profileEventListener = config.profileEventListener;
    this.historyEventListener = config.historyEventListener;
    this._marketingDataProvider = new MarketingDataProvider(config.getAdvertisingId);
    this.callBrazeEvent = config.callBrazeEvent;
    this.removeFromBrazeAttributeArray = config.removeFromBrazeAttributeArray;
    this.addToBrazeAttributeArray = config.addToBrazeAttributeArray;
    this.cleanPlaybacks();

    // Can't use serviceBus while constructing the serviceBus, so use setTimeout
    setTimeout(() => {
      this.setAdParameters();
    }, 0);
  }

  setEventListener(listener?: (error?: Error) => void) {
    this.eventListener = listener;
  }

  /*
   * -- Marketing Data Provider config--
   */

  get marketingDataProvider(): IMarketingDataProvider {
    return this._marketingDataProvider;
  }

  async setAdParameters() {
    const profile = await this.getProfileData();
    this._marketingDataProvider.profile = profile;
  }

  setCampaignData(campaignData: IMarketingDataProvider['campaign']) {
    this._marketingDataProvider.campaign = campaignData;
  }

  /*
   * -- IProfileProvider implementation --
   */
  async getIncompleteProfileData(): Promise<string[]> {
    return this.getAuthState() === AuthState.AUTH
      ? await this.checkForAuthError(this.profileApi.getIncompleteProfileData())
      : [];
  }

  async getProfileData(): Promise<IProfile> {
    return this.getAuthState() === AuthState.AUTH
      ? (await this.profileApi.getProfileData())?.profile
      : {};
  }

  setProfileData = async (profile: TProfilePatch): Promise<IProfile | undefined> => {
    if (this.getAuthState() === AuthState.AUTH) {
      await this.checkForAuthError(this.profileApi.patchProfileData(profile));
      const updatedProfile = await this.getProfileData();
      this.profileEventListener?.(updatedProfile);
      return updatedProfile;
    }
  };

  /*
   * -- IPreferences implementation --
   */

  async getPreferences(): Promise<IPreferences> {
    return this.getAuthState() === AuthState.AUTH
      ? await this.checkForAuthError(this.profileApi.getPreferences())
      : {};
  }

  setPreferences = async (preferences: TPreferencesPatch): Promise<void> => {
    const authState = await this.getAuthState();
    const isAuthenticated = authState === AuthState.AUTH;

    if (isAuthenticated) {
      await this.checkForAuthError(this.profileApi.setPreferences(preferences));
    }
  };

  /*
   * -- AdTargetingStatus implementation --
   */

  async getAdTargetingStatus(): Promise<IAdTargeting | undefined> {
    let data;
    const authState = await this.getAuthState();
    const isAuthenticated = authState === AuthState.AUTH;

    if (isAuthenticated) {
      data = await this.checkForAuthError(this.profileApi.getAdTargetingStatus());
      const ineligibleExp = /^INELIGIBLE.*$/;
      if (ineligibleExp.test(data.adTargeting)) {
        data.adTargeting = 'INELIGIBLE';
      }
    }
    return data;
  }

  async setAdTargetingStatus(adTargeting: AdTargetingStatusParams): Promise<void> {
    const authState = await this.getAuthState();
    const isAuthenticated = authState === AuthState.AUTH;

    if (isAuthenticated) {
      await this.checkForAuthError(this.profileApi.setAdTargetingStatus(adTargeting));
    }
  }

  async getAdTargetingEligibility(): Promise<IAdTargetingEligibility | undefined> {
    let data;
    const authState = await this.getAuthState();
    const isAuthenticated = authState === AuthState.AUTH;

    if (isAuthenticated) {
      data = await this.checkForAuthError(this.profileApi.getAdTargetingEligibility());
    }

    return data;
  }

  /*
   * -- IPlaybacksProvider implementation --
   */
  addPlaybacksListener(listener: TPlaybacksListener) {
    this.playbacksListeners.indexOf(listener) < 0 && this.playbacksListeners.push(listener);
  }

  removePlaybacksListener(listener: TPlaybacksListener) {
    const index = this.playbacksListeners.indexOf(listener);
    if (index >= 0) {
      this.playbacksListeners.splice(index, 1);
    }
  }

  cleanPlaybacks() {
    const localPlaybacks = {
      ...this.dataStore.getDataSync('playbacks'),
    } as PlaybacksHashMap;

    const oneWeekAgo = (Date.now() / 1000) - 604800;

    for (const key in localPlaybacks) {
      if (Object.prototype.hasOwnProperty.call(localPlaybacks, key)) {
        const playback = localPlaybacks[key]
          // delete playbacks in local playbacks
          if (oneWeekAgo > playback && (key.startsWith("101") || key.startsWith('202') || key.startsWith('801'))) 
              delete localPlaybacks[key];
      }
  }

  this.dataStore.setDataSync('playbacks', localPlaybacks);
  }

  /**
   * Get the resume points for all stations and podcasts
   */
  async getPlaybacks(): Promise<PlaybacksHashMap> {
    const { getPlaybacks } = this.profileApi;

    const localPlaybacks = {
      ...this.dataStore.getDataSync('playbacks'),
    } as PlaybacksHashMap;

    const isAuthenticated = this.getAuthState() === AuthState.AUTH;

    if (isAuthenticated && this.currentSessionId !== this.profileApi.sessionId) {
      this.currentSessionId = this.profileApi.sessionId;

      const playbacks = await this.checkForAuthError(getPlaybacks());

      const combinedPlaybacks = reconcilePlaybackResumePoints(localPlaybacks, playbacks);

      this.dataStore.setDataSync('playbacks', combinedPlaybacks);
      return combinedPlaybacks;
    }

    return localPlaybacks;
  }

  /**
   * Set the resume point for a station or podcast
   */
  async setPlayback(id: string, offset: number, shouldUpdateServer = true): Promise<void> {
    // don't set playback if contentId or offset is missing
    if (!id || !offset) {
      this.logger?.warn('Called setPlayback without contentId or offset');
      return;
    }
    // Update local dataStore with resume point
    const playbacks: PlaybacksHashMap = this.dataStore.getDataSync('playbacks') ?? {};
    playbacks[id] = offset;
    this.dataStore.setDataSync('playbacks', playbacks);

    // Notify listeners of new resume point
    this.playbacksListeners.forEach((listener) => listener(playbacks));

    // If authenticated, send resume point to server
    const authenticated = this.getAuthState() === AuthState.AUTH;
    if (authenticated && shouldUpdateServer) {
      await this.checkForAuthError(this.profileApi.setPlayback(id, offset));
    }
  }

  /**
   * Sets the resume points for an episode and its parent station. Used only for Rewind streams
   */
  async setPlaybacks(playbacks: TPlayback[], shouldUpdateServer = true): Promise<void> {
    // Update local dataStore with resume points
    const localPlaybacks: PlaybacksHashMap = this.dataStore.getDataSync('playbacks') ?? {};
    playbacks.forEach((playback) => {
      localPlaybacks[playback.contentId] = playback.playbackOffset;
    });
    this.dataStore.setDataSync('playbacks', localPlaybacks);

    // Notify listeners of new resume point
    this.playbacksListeners.forEach((listener) => listener(localPlaybacks));

    // If authenticated, send resume point to server
    const authenticated = this.getAuthState() === AuthState.AUTH;
    if (authenticated && shouldUpdateServer) {
      await this.checkForAuthError(this.profileApi.setPlaybacks(playbacks)); // prettier-ignore
    }
  }

  /*
   * -- IPlayerSettingsProvider implementation --
   */

  setVolume = (volume: number): void => {
    this.playerVolume = volume;
    this.dataStore.setData('volume', volume);
  };

  getVolume = async (): Promise<number> =>
    this.playerVolume || (await this.dataStore.getData('volume')) || 1;

  setIsMuted = (isMuted: boolean): void => {
    this.dataStore.setData('isMuted', isMuted);
  };

  getIsMuted = async (): Promise<boolean> => (await this.dataStore.getData('isMuted')) ?? false;

  setPlaybackRate = (rate: TPlaybackRate): void => {
    this.dataStore.setData('rate', rate);
  };

  getPlaybackRate = async (): Promise<TPlaybackRate> => (await this.dataStore.getData('rate')) ?? 1;

  /*
   * -- IFollowsProvider implementation --
   */
  async addFollows(contentIds: string[]) {
    // add follows data to the cache
    let followedStations = cache.get(FOLLOWS) || [];
    followedStations.push(contentIds);
    followedStations = followedStations.flat();
    const dedupeFollowedStations = [...new Set(followedStations)] as string[];
    cache.set(FOLLOWS, dedupeFollowedStations, TWELVE_HOURS);

    return await this.checkForAuthError(this.profileApi.addFollows(contentIds));
  }

  async deleteFollow(id: string) {
    await this.bulkUnfollow([id]);
  }

  async bulkUnfollow(contentIds: string[]) {
    // removes follow from local cache
    let cachedFollows = (cache.get(FOLLOWS) as string[]) || [];
    cachedFollows = cachedFollows.flat();
    const updatedFollows = cachedFollows.filter((x) => !contentIds.includes(x));
    cache.set(FOLLOWS, updatedFollows, TWELVE_HOURS);

    await this.checkForAuthError(this.profileApi.bulkUnfollow(contentIds));
  }

  async getFollows() {
    const { getFollows } = this.profileApi;

    // If authenticated, update local dataStore with latest follows from server
    const isAuthenticated = this.getAuthState() === AuthState.AUTH;

    if (isAuthenticated) {
      const follows = await this.checkForAuthError(getFollows());
      return follows;
    }
    return [];
  }

  async getFollowsWithSummaries(): Promise<ContentSummary[]> {
    const followIds = await this.getFollows();
    const summaries = await this.experienceApi.getContentSummaries(followIds);
    const follows = Object.values(summaries.content);

    const startIgnoreRegex = /^(the|an|a) /gi; // What does this do? -NPL
    const cleanTitle = (title: string) => title.replace(startIgnoreRegex, '');
    follows.sort((a, b) => cleanTitle(a.title).localeCompare(cleanTitle(b.title)));
    return follows;
  }

  /*
   * -- IQueueProvider implementation --
   */
  addQueueListener(listener: TQueueListener): unknown {
    return this.queueListeners.indexOf(listener) < 0 && this.queueListeners.push(listener);
  }

  removeQueueListener(listener: TQueueListener): void {
    const index = this.queueListeners.indexOf(listener);
    if (index >= 0) {
      this.queueListeners.splice(index, 1);
    }
  }

  clearQueue() {
    return this.profileApi.setQueue({ currentId: '', queue: [] });
  }

  async addToQueue(object: string | TQueueItem) {
    const existingQueuePromise = this.getQueue();
    const item = isString(object)
      ? ((await this.experienceApi.getContentObject(
          object,
        )) as TQueueItem) /* TODO: [DTC-146] Remove this cast once we refactor DataServices */
      : object;
    return await this.setQueue(addToQueueAndDedupe(await existingQueuePromise, item));
  }

  // TODO: [CCS-2794] revisit this method in the future, to simplify and promote understanding
  async getQueue(): Promise<IQueue> {
    if (this.getAuthState() !== AuthState.AUTH) {
      return EMPTY_QUEUE;
    }

    const currentQueue = await this.checkForAuthError(this.profileApi.getQueue());

    const currentQueueItems = await this.experienceApi.getContentObjects(currentQueue.queue);

    let retQueue: IQueue = {
      itemsRemoved: false,
      items: [],
    };

    for (const item of currentQueue.queue) {
      const queueItem = currentQueueItems.content[item];
      if (isQueueItemIsEpisode(queueItem) || isQueueItemStandaloneChapter(queueItem)) {
        retQueue.items.push(queueItem);
      } else {
        retQueue.itemsRemoved = true;
      }
    }

    const item = currentQueueItems.content[currentQueue.currentId ?? ''];
    if (
      !isEmpty(currentQueue.currentId) &&
      !(isQueueItemIsEpisode(item) || isQueueItemStandaloneChapter(item))
    ) {
      retQueue.currentId = '';
      retQueue.itemsRemoved = true;
    }

    if (retQueue.itemsRemoved) {
      retQueue = {
        ...(await this.setQueue(retQueue)),
        itemsRemoved: true,
      };
    }

    return retQueue;
  }

  // TODO: [CCS-2794] revisit this method in the future, to simplify and promote understanding
  async setQueue(queue: IQueue, silent = false): Promise<IQueue> {
    if (this.getAuthState() !== AuthState.AUTH) {
      return EMPTY_QUEUE;
    }

    const newQueue: IServerQueue = {
      queue: queue.items.map((item) => item.getId()),
    };

    if (newQueue.queue.length === 0) {
      // if the queue is empty, clear the currentId
      newQueue.currentId = EMPTY_QUEUE.currentId;
    } else if (newQueue.queue.length === 1) {
      // if the queue has one item, set the currentId to that item
      newQueue.currentId = newQueue.queue[0];
    } else if (indexInQueue(newQueue.currentId, newQueue) < 0) {
      // if the currentId is not in the queue, find an appropriate item to set it to
      const oldQueue = await this.profileApi.getQueue();
      const id = newQueue.currentId;
      const index = indexInQueue(id, oldQueue);
      newQueue.currentId = undefined;

      /*
       * Starting at the index directly after the index of the old currentId in the old queue,
       * find the first item in the new queue that is also in the old queue. Set the currentId to that item.
       */
      for (let i = index + 1; i < oldQueue.queue.length && newQueue.currentId === undefined; i++) {
        setCurrentIdIfFound(oldQueue.queue[i], newQueue);
      }

      /*
       * If the currentId is still undefined, start at the index directly before the index of the old currentId
       * in the old queue, and find the first item in the new queue that is also in the old queue. Set the currentId to that item.
       */
      for (let i = index - 1; i >= 0 && newQueue.currentId === undefined; i--) {
        setCurrentIdIfFound(oldQueue.queue[i], newQueue);
      }

      // If the currentId is still undefined, set it to the first item in the new queue.
      if (newQueue.currentId === undefined) {
        newQueue.currentId = newQueue.queue[0];
      }
    }

    await this.profileApi.setQueue(newQueue);

    const updatedQueue = await this.getQueue();

    if (!silent) {
      this.queueListeners.forEach((listener) => listener(updatedQueue));
    }

    return updatedQueue;
  }

  async removeFromQueue(idsToRemove: string[]): Promise<IQueue> {
    const idsToRemoveSet = new Set(idsToRemove);
    const oldQueue = await this.getQueue();
    const newQueue: IQueue = {
      ...oldQueue,
      items: oldQueue.items.filter((item) => !idsToRemoveSet.has(item.getId())),
    };
    return await this.setQueue(newQueue);
  }

  /*
   * -- History --
   */

  isInTopOfHistoryStack(contentIds: string|string[]) {
    const localHistory =
      this.dataStore.getDataSync<IHistoryEntry[]>(LocalStorageKey.HISTORY) ?? [];
    
    const itemsToCompare = [contentIds].flat();
    const historyItemsToCheck = localHistory
      ?.slice(0, contentIds.length)
      ?.map(({ contentId }) => contentId) || [];
    
    return isEqual(historyItemsToCheck, itemsToCompare);
  }

  async addToHistory(contentIds: string[]) {
    contentIds.forEach((item) => {
      this.historyQueue.push({
        contentId: item,
        timestamp: new Date().toISOString(),
      });
    });

    if (this.writingHistory) {
      return;
    }

    this.writingHistory = true;

    // TODO: improve current algorithm to use forEach
    // to better understanding of each iteration process
    while (this.historyQueue.length > 0) {
      const localHistory =
        this.dataStore.getDataSync<IHistoryEntry[]>(LocalStorageKey.HISTORY) ?? [];
      const itemsToAdd = [...this.historyQueue];
      this.historyQueue = [];
      try {
        if (this.getAuthState() === AuthState.AUTH) {
          const reconciledHistory = reconcileHistory(localHistory, itemsToAdd);
          this.dataStore.setDataSync(LocalStorageKey.HISTORY, reconciledHistory);
          await this.profileApi.addToHistory(itemsToAdd);
        }
      } catch {
        this.historyQueue = [...itemsToAdd, ...this.historyQueue];
        break;
      }
    }
    this.writingHistory = false;

    this.historyEventListener?.();
  }

  clearHistory(): Promise<unknown> {
    return this.profileApi.clearHistory();
  }

  clearLocalFollows() {
    cache.delete(FOLLOWS);
  }

  async getHistoryWithSummaries(): Promise<IHistoryEntryWithSummary[]> {
    if (this.getAuthState() !== AuthState.AUTH) {
      return [];
    }
    const isNewSession = this.currentHistorySessionId !== this.profileApi.sessionId;
    if (isNewSession) {
      this.currentHistorySessionId = this.profileApi.sessionId;
      const remoteHistory = await this.checkForAuthError(this.profileApi.getHistory());
      this.dataStore.setDataSync<IHistoryEntry[]>(LocalStorageKey.HISTORY, remoteHistory);
    }
    const history = this.dataStore.getDataSync<IHistoryEntry[]>(LocalStorageKey.HISTORY) ?? [];
    const ids = history.map((item) => item.contentId);
    const { content: entities } = await this.experienceApi.getContentSummaries(ids);
    return history
      .filter((item) => !!entities[item.contentId])
      .map(
        (item): IHistoryEntryWithSummary => ({
          ...item,
          content: entities[item.contentId],
        }),
      )
      .filter(({ content: { replayableUntilDateTime, entitySubtype } }) => {
        if (entitySubtype === EpisodeSubType.BROADCAST_SHOW_EPISODE) {
          return (
            Boolean(replayableUntilDateTime) &&
            isBefore(new Date(), new Date(replayableUntilDateTime))
          );
        }
        return true;
      });
  }

  async getAutoHome(marketIds?: string): Promise<Array<Awaited<IAutoHomeWithSummary>>> {
    if ((await this.getAuthState()) !== AuthState.AUTH) {
      return [];
    }
    const homeTab = await this.checkForAuthError(this.profileApi.getAutoView(marketIds));

    const history = await this.getHistoryWithSummaries();

    const liveRecents = history.filter(
      ({ content }) =>
        content.entitySubtype === StationSubType.BROADCAST ||
        content.entitySubtype === StationSubType.EXCLUSIVE,
    );

    const episodeRecents = history.filter(
      ({ content }) =>
        content.entitySubtype === EpisodeSubType.BROADCAST_SHOW_EPISODE ||
        content.entitySubtype === EpisodeSubType.PODCAST_EPISODE,
    );

    return await Promise.all(
      homeTab.modules?.map(async (module): Promise<IAutoHomeWithSummary> => {
        const { moduleType } = module;

        if (moduleType === ModuleType.RECENTLY_PLAYED_STATIONS_LIVE) {
          return {
            header: module.config.title.label ?? '',
            items: liveRecents,
            viewId: module.moduleId,
            viewType: VIEW_TYPE_VALUES.imageRow,
            moduleId: module.moduleId,
            isLive: true,
          };
        }
        if (moduleType === ModuleType.CONTINUE_LISTENING) {
          return {
            header: module.config.title.label ?? '',
            items: episodeRecents,
            viewId: module.moduleId,
            viewType: VIEW_TYPE_VALUES.list,
            moduleId: module.moduleId,
          };
        }
        if (moduleType === ModuleType.SECTION_WRAPPER) {
          const ids: string[] = module.modules?.[0].modules
            ? module.modules[0].modules.map((item) => item.config.contentId)
            : [];
          const { content: entities } = await this.experienceApi.getContentSummaries(ids);
          return {
            header: module.config.title.label ?? '',
            items: module.modules?.[0].modules
              ? module.modules?.[0].modules
                  .filter((item) => !!entities[item.config.contentId])
                  .map(
                    (item): IHistoryEntryWithSummary => ({
                      ...(item as unknown as IHistoryEntry),
                      content: entities[item.config.contentId],
                    }),
                  )
              : [],
            showLimit: module.modules?.[0].config?.itemsPerColumn,
            viewId: module.moduleId,
            viewType:
              module.modules?.[0].modules?.[0].moduleType === ModuleType.ENTITY_CARD_VERTICAL
                ? VIEW_TYPE_VALUES.imageRow
                : VIEW_TYPE_VALUES.list,
            moduleId: module.moduleId,
          };
        }
        if (module.moduleType === ModuleType.VIEW_TITLE) {
          return {
            header: module.config.titleFragments[0] ?? '',

            items: [],
            viewId: module.moduleId,
            viewType: VIEW_TYPE_VALUES.list,
            moduleId: module.moduleId,
          };
        }
        return {} as IAutoHomeWithSummary;
      }) ?? [],
    );
  }

  /*
   * -- Playlist --
   */
  get localCollectionId(): string | undefined {
    return this.dataStore.getDataSync(LocalStorageKey.COLLECTION_ID);
  }

  get localActivePlaylistItems(): Array<string> | undefined {
    return this.dataStore.getDataSync(LocalStorageKey.ACTIVE_PLAYLIST_ITEMS);
  }

  /*
   * -- ICredentialsProvider implementation --
   */
  public get token(): string | undefined {
    return this.authData.state === AuthState.AUTH ? this.authData.refreshToken : undefined;
  }

  public get userToken(): string | undefined {
    return this.authData.state !== AuthState.NONE ? this.authData.userToken : undefined;
  }

  public get bearerHeader(): string | undefined {
    return this.authData.state === AuthState.AUTH ? this.authData.bearerHeader : undefined;
  }

  /*
   * -- Auth --
   */
  public get authUrl(): string {
    const uri = new URI('identity')
      .absoluteTo(this.authConfig.baseUrl)
      .addQuery('appName', this.authConfig.appName)
      .addQuery('appVersion', this.authConfig.appVersion)
      .addQuery('language', this.authConfig.language)
      .addQuery('plat', this.authConfig.plat)
      .addQuery('version', '2');

    this.marketingDataProvider.campaign?.name &&
      uri.addQuery('campaign', this.marketingDataProvider.campaign.name);
    this.marketingDataProvider.campaign?.medium &&
      uri.addQuery('entry', this.marketingDataProvider.campaign.medium);
    this.marketingDataProvider.campaign?.source &&
      uri.addQuery('source', this.marketingDataProvider.campaign.source);
    this.profileApi.sessionId && uri.addQuery('sessionId', this.profileApi.sessionId);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (this.authConfig.plat === 'ott' && typeof origin !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      uri.addQuery('parent', origin);
    }

    return uri.valueOf();
  }

  async setAuthState(data: TClientProvidedAuthState): Promise<void> {
    const { state } = data;
    const deviceId = this.dataStore.getDataSync<string>(LocalStorageKey.BRAZE_DEVICE_ID);
    switch (state) {
      case AuthState.ANON: {
        this.authData = {
          state,
          userToken: deviceId ? deviceId : await this.profileApi.createAnonymousUser(),
        };
        break;
      }
      case AuthState.AUTH: {
        if (
          'data' in data &&
          (!data.isTrusted ||
            !Object.values(EnvironmentHosts)
              .map((host) => host.toString())
              .includes(data.origin))
        ) {
          return await this.setAuthState({ state: AuthState.NONE });
        }
        const { refreshToken, userId } = 'data' in data ? data.data : data;
        const bearerHeader =
          'data' in data
            ? data.data.authorizationHeader
            : data.accessToken
            ? `Bearer ${data.accessToken}`
            : undefined;
        this.authData = {
          state,
          refreshToken,
          userToken: userId,
          bearerHeader,
        };
        break;
      }
      default:
        this.authData = { state };
        break;
    }

    await this.persistAuthState(this.authData);
    await this.setAdParameters();
    await this.getPlaybacks();
  }

  getAuthState(): AuthState {
    const bearerHeader = this.dataStore.getDataSync<string>(LocalStorageKey.BEARER_HEADER);
    const refreshToken = this.dataStore.getDataSync<string>(LocalStorageKey.REFRESH_TOKEN);
    const userToken = this.dataStore.getDataSync<string>(LocalStorageKey.USER_TOKEN);

    if (refreshToken && userToken) {
      this.authData = {
        state: AuthState.AUTH,
        bearerHeader,
        refreshToken,
        userToken,
      };
    } else if (userToken) {
      this.authData = { state: AuthState.ANON, userToken };
    } else {
      this.authData = { state: AuthState.NONE };
    }

    return this.authData.state;
  }

  setIsSoftDeleted = (isSoftDeleted: boolean): void => {
    this.dataStore.setData(LocalStorageKey.IS_SOFT_DELETED, isSoftDeleted);
  };

  getIsSoftDeleted = async (): Promise<boolean> =>
    (await this.dataStore.getData(LocalStorageKey.IS_SOFT_DELETED)) ?? false;

  private async persistAuthState(state: TAuthState): Promise<unknown> {
    const promises = Promise.all([
      'userToken' in state && state.userToken
        ? this.dataStore.setData(LocalStorageKey.USER_TOKEN, state.userToken)
        : this.dataStore.clearData(LocalStorageKey.USER_TOKEN),
      'bearerHeader' in state && state.bearerHeader
        ? this.dataStore.setData(LocalStorageKey.BEARER_HEADER, state.bearerHeader)
        : this.dataStore.clearData(LocalStorageKey.BEARER_HEADER),
      'refreshToken' in state && state.refreshToken
        ? this.dataStore.setData(LocalStorageKey.REFRESH_TOKEN, state.refreshToken)
        : this.dataStore.clearData(LocalStorageKey.REFRESH_TOKEN),
    ]);

    return await promises;
  }

  private async checkForAuthError<T>(promise: Promise<T>): Promise<T> {
    try {
      return await promise;
    } catch (error) {
      if (error instanceof AudacyAuthError) {
        await this.setAuthState({ state: AuthState.NONE });
        this.eventListener?.(error);
        this.logger?.error('Triggered Login: ' + error.message);
      }
      throw error;
    }
  }

  triggerPasswordResetEmail() {
    return this.checkForAuthError(this.profileApi.triggerPasswordResetEmail());
  }

  getLogoutUrl() {
    return this.profileApi.getLogoutUrl();
  }

  async executeLogout(): Promise<string | undefined> {
    let logoutUrl: string | undefined;

    if (this.getAuthState() === AuthState.AUTH) {
      try {
        logoutUrl = await this.profileApi.getLogoutUrl();
      } catch {
        // oh well.
      }
    }

    await this.setAuthState({ state: AuthState.NONE });
    this.clearLocalFollows();

    return logoutUrl;
  }
}

enableObjectTracing(PersonalizationServices);
