import { BP, Colors, Mixins, mq, mqHover, Outlines } from '~/styles';
import { type TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  wrapper: {
    color: Colors.audacyDarkNavy,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15,
    gap: 15,
    paddingBottom: 30,
    position: 'relative',
  },
  seperator: {
    content: '""',
    display: 'block',
    height: 1,
    width: 'calc(100% + 60px)',
    backgroundColor: Colors.greyEB,
    position: 'absolute',
    left: -30,
    bottom: 0,
    [mq(BP.Medium)]: {
      left: -42,
      width: 'calc(100% + 84px)',
    },
  },
  entityImage: {
    flexShrink: 0,
    height: 32,
    width: 32,
  },
  textContainer: { display: 'flex', flexDirection: 'column' },
  title: {
    ...Mixins.truncateText(2),
    color: Colors.audacyDarkNavy,
    fontSize: 15,
  },
  subtitle: {
    ...Mixins.truncateText(2),
    color: Colors.audacyDarkNavy,
    fontSize: 12,
  },
  closeButton: {
    ...Mixins.focusOutline({ outlineOffset: 0, outline: `${Outlines.focusDark}` }),
    color: Colors.greyA6,
    marginLeft: 'auto',
    ...mqHover({
      color: Colors.greyC5,
    }),
    span: {
      margin: `0 auto`,
    },
  },
  closeIcon: {
    height: 28,
    width: 28,
  },
};

export default styles;
