import { useMemo } from 'react';

import { ClickFeature } from '@audacy-clients/client-services/core';
import { useModuleContext } from '@audacy-clients/core/components/ModuleRenderer/context';
import { IModuleProps } from '@audacy-clients/core/components/ModuleRenderer/types';

import Card, { CardType } from '~/components/Card';

import { getCardType, formatCard, formatFeaturedCard } from './utils';

const ModuleCard: React.FC<IModuleProps> = ({ module, data }) => {
  const moduleContext = useModuleContext();
  const contentId = module.config?.contentId;
  const moduleId = useMemo(() => {
    // If there is a contentId, prefer ID of outermost parent module because this has an enrichable title (eg "Featured Stations")
    // If there is no content ID, prefer innermost module to get title of card clicked (eg "All Podcasts")
    return contentId ? moduleContext.sectionId || module.id : module.id;
  }, [contentId, module.id, moduleContext.sectionId]);
  const playContext = {
    feature: ClickFeature.MODULE,
    itemsList: moduleContext.carousel?.cardContentIds,
    moduleId,
  };

  const cardType = getCardType(module.type, moduleContext);

  if (!cardType) return null;

  return (
    <Card
      data={
        cardType === CardType.Featured
          ? formatFeaturedCard(cardType, module, playContext, data, moduleContext)
          : formatCard(cardType, module, playContext, data, moduleContext)
      }
    />
  );
};

export default ModuleCard;
