import { SearchFilterOptions } from '@audacy-clients/core/atoms/search';
import { atom } from 'recoil';

export const searchTabState = atom<SearchFilterOptions>({
  default: SearchFilterOptions.STATIONS,
  key: 'searchTabState',
});

export const searchQueryState = atom<string>({
  default: '',
  key: 'searchQueryState',
});
