import { Children, type ReactElement, useMemo } from 'react';
import { SwiperSlide } from 'swiper/react';
import { type SwiperOptions } from 'swiper/types';
import { type TStyles } from '~/types/emotion-styles';
import { chunkArray } from '~/utils/array';
import CarouselView from './components/CarouselView';
import StackedCardHolder from './components/StackedCardHolder';
import { SwiperParams } from './constants';
import styles from './styles';

type IProps = {
  ariaLabel?: string;
  children: Array<ReactElement>;
  carouselViewCss?: TStyles;
  stackSize?: number;
  swiperParams?: SwiperOptions;
};

const StackedCarousel = ({
  ariaLabel,
  children,
  carouselViewCss,
  stackSize = 3,
  swiperParams,
}: IProps): JSX.Element => {
  const stackedCards = useMemo(() => chunkArray(children, stackSize), [children, stackSize]);
  let slideIndex = 1;
  return (
    <CarouselView
      ariaLabel={ariaLabel}
      carouselViewCss={carouselViewCss}
      swiperParams={swiperParams ?? SwiperParams.stacked}
    >
      {stackedCards.map((cardsChunk, stackIndex) => (
        <SwiperSlide key={stackIndex} virtualIndex={stackIndex}>
          <StackedCardHolder>
            {Children.map(cardsChunk, (child: ReactElement): ReactElement => {
              return (
                <div
                  css={styles.stackedItem}
                  role="group"
                  aria-label={`Slide ${slideIndex++} of ${children.length}`}
                >
                  {child}
                </div>
              );
            })}
          </StackedCardHolder>
        </SwiperSlide>
      ))}
    </CarouselView>
  );
};

export default StackedCarousel;
