import { useSongHistory } from '@audacy-clients/core/atoms/songHistory';
import { useModuleContext } from '@audacy-clients/core/components/ModuleRenderer/context';

import SongHistoryListView from '~/components/SongHistory/SongHistoryListView';
import { Mixins } from '~/styles';

import styles from './styles';

const ModuleSongHistory: React.FC = () => {
  const { viewRootObjectId } = useModuleContext();
  const stationId = viewRootObjectId || '';
  const songHistory = useSongHistory({ stationId, limit: 40 });

  return songHistory.length > 0 ? (
    <section css={[Mixins.horizontalModulePadding(), styles.container]}>
      <SongHistoryListView items={songHistory} />
    </section>
  ) : (
    <></>
  );
};

export default ModuleSongHistory;
