import { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import usePlayerProps from '~/components/Player/hooks/usePlayerProps';
import styles from '~/components/Player/MiniPlayer/styles';
import { PlayerQueueContext } from '~/components/Player/PlayerContextProvider';
import Scrubber from '~/components/Player/Scrubber';
import SkeletonText from '~/components/SkeletonText';
import { useBreakpoints } from '~/hooks/use-breakpoints';

export const BroadcastMetadata = (): JSX.Element => {
  const {
    onOpen: onExpandPlayer,
    hasMetaData,
    miniPlayerTitle,
    miniPlayerSubtitle,
  } = usePlayerProps();
  const { t } = useTranslation();
  const { greaterThan } = useBreakpoints();

  const { isQueueHead } = useContext(PlayerQueueContext);

  return (
    <div css={[styles.contentContainer, isQueueHead && styles.contentContainerQueue]}>
      <button
        css={styles.textContainer}
        onClick={onExpandPlayer}
        aria-label={t('playerControls.accessibilityLabels.openFullPlayer')}
      >
        {!hasMetaData ? (
          <div css={styles.textInner}>
            <SkeletonText length={30} />
            <SkeletonText length={20} />
          </div>
        ) : (
          <div css={styles.textInner}>
            {!!miniPlayerTitle && <span css={styles.title}>{miniPlayerTitle}</span>}
            {!!miniPlayerSubtitle && <span css={styles.subtitle}>{miniPlayerSubtitle}</span>}
          </div>
        )}
      </button>
      {greaterThan.MD && (
        <div css={styles.scrubberContainer}>
          <Scrubber inputCss={styles.scrubber} />
        </div>
      )}
    </div>
  );
};
