import { Colors, ZIndex } from "~/styles";
export const Constants = {
  noIndexFounded: -1,
};
export const styles = {
  button: {
    display: 'flex',
    alignItems: 'center',
    zIndex: ZIndex.transcriptContainer,
  },
  buttonDisabled: {
    opacity: 0.3,
  },
  buttonTitle: {
    color: Colors.white,
    fontFamily: 'Maison Neue',
    fontSize: '12px',
    fontWeight: '700',
    letterSpacing: '0.02px',
  },
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
};
export default styles;
