export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Bell</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.97 6.57A8.003 8.003 0 0 1 24 14v4.6l2 1.4v3H6v-3l2-1.4V14a8.003 8.003 0 0 1 5.03-7.43 3 3 0 0 1 5.94 0Z"
      fill="currentColor"
    />
    <path d="M20 25c0 1.657-1.79 3-4 3s-4-1.343-4-3h8Z" fill="currentColor" />
    <circle cx="23" cy="9" r="4" fill="#FE5000" />
  </svg>
);
