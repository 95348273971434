import { webClientNavigation } from '@audacy-clients/core/atoms/config/navigation';
import { type IView } from '@audacy-clients/core/atoms/wrappers/modules';
import { type IViewContext } from '@audacy-clients/core/utils/viewContext';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

// TODO: [A2-5062] refactor viewContext
export const viewContext = atom<IViewContext>({
  default: {
    viewId: '',
    contentId: '',
  },
  key: 'ViewContext',
});

export const viewState = atom<IView | undefined>({
  default: undefined,
  key: 'View',
});

export const useGetViewContext = (): ((view: IView) => IViewContext | undefined) => {
  const { viewMap } = useRecoilValue(webClientNavigation);

  return (view: IView) => {
    if (view) {
      let viewId = '';
      let contentId = '';

      if (view.rootObject) {
        const { id, type, subtype } = view.rootObject;
        contentId = id;
        viewId = viewMap[type][subtype]?.viewId || view.id;
      } else {
        viewId = view.id;
      }

      return {
        viewId,
        contentId,
      };
    }
  };
};

export const useSetViewContext = (): ((view: IView) => void) => {
  const setViewContext = useSetRecoilState(viewContext);
  const setViewState = useSetRecoilState(viewState);
  const getViewContext = useGetViewContext();

  return (view: IView) => {
    if (view) {
      setViewState(view);
      const context = getViewContext(view);
      if (context) {
        setViewContext(context);
      }
    }
  };
};

// used for core package hooks throughout the app
export const useGetViewContextFn = (): (() => IViewContext) => {
  const viewContextObj = useRecoilValue(viewContext);

  return () => viewContextObj;
};

export const useViewState = (): IView | undefined => useRecoilValue(viewState);
