import type Show from '@audacy-clients/client-services/src/dataServices/Show';
import { type IShow } from './types';
import { wrapDataObject } from '.';

export const wrapShow = (show: Show): IShow => ({
  ...wrapDataObject(show),
  podcastType: show.getPodcastType(),
  author: show.getAuthor(),
  allowShowImageOverride: show.getAllowShowImageOverride(),
});
