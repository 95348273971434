import { useState } from 'react';

import ResponsiveImage from '~/components/Image/ResponsiveImage';
import { TStyles } from '~/types/emotion-styles';

import { LoadingOptions, TLoading } from './constants';
import styles from './styles';
import { IImageSize } from './types';

interface IImageProps {
  alt: string;
  src: string;
  imgCss?: TStyles;
  loading?: TLoading;
  onError?: () => void;
  onLoad?: () => void;
  sizes?: IImageSize[];
  isLoading?: boolean;
}

const Image = ({
  alt,
  src,
  imgCss,
  loading = LoadingOptions.Lazy,
  onError,
  onLoad,
  sizes,
  isLoading,
}: IImageProps): JSX.Element => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleOnLoad = (): void => {
    setIsLoaded(true);
    onLoad && onLoad();
  };

  return (
    <ResponsiveImage sizes={sizes} src={src}>
      <img
        alt={alt}
        css={[styles.img, isLoaded && !isLoading && styles.loaded, imgCss]}
        loading={loading}
        onLoad={handleOnLoad}
        onError={onError}
        src={src}
      />
    </ResponsiveImage>
  );
};

export default Image;
