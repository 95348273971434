import {
  ClickFeature,
  type EntitySubtype,
  type EntityType,
} from '@audacy-clients/client-services/core';
import { EntityImageShape } from '@audacy-clients/core/atoms/wrappers/modules';
import { useModuleContext } from '@audacy-clients/core/components/ModuleRenderer/context';
import { type IPlayContext } from '@audacy-clients/core/hooks/useAudioHelpers';
import React, { useMemo } from 'react';

import HorizontalEntityCard from '~/components/Entity/HorizontalEntityCard';
import { type INowPlaying } from '~/components/Entity/types';
import VerticalEntityCard from '~/components/Entity/VerticalEntityCard';
import FeaturedCard, { type IFeaturedCardProps } from '~/components/FeaturedCard';
import useOptionsMenu from '~/hooks/use-options-menu';
import { useNavClickEvent } from '~/utils/analytics';

// 'Small' might not be needed and could be removed during integration
export enum CardType {
  Small,
  Featured,
  Vertical,
  Horizontal,
}

export type ICard = {
  contentId?: string;
  duration?: number;
  liveUntil?: INowPlaying;
  publishDate?: Date;
  entityType?: EntityType;
  entitySubtype?: EntitySubtype;
  childEntity?: ICard;
  href?: string;
  episodeHref?: string;
  showHref?: string;
  image?: string | Array<string>;
  imageAlt?: string;
  imageShape?: EntityImageShape;
  isExternal?: boolean;
  showContentId?: string;
  subtitle?: string;
  title?: string;
  type: CardType;
  isPlayable?: boolean;
  isFixed?: boolean;
  startDateTime?: Date;
  playContext: IPlayContext;
  parentTitle?: string;
  genres?: Array<string> | undefined;
  marketTitle?: string | undefined;
  author?: string | undefined;
  itemsCount?: number;
  replayableUntilDateTime?: string;
};

export type TModuleFeaturedCardProps = IFeaturedCardProps &
  Pick<ICard, 'contentId' | 'entitySubtype' | 'entityType' | 'type' | 'playContext'>;

export type ICardProps = {
  data: ICard | TModuleFeaturedCardProps;
};

const Card = ({ data }: ICardProps): JSX.Element => {
  const { replayableUntilDateTime, contentId, entitySubtype, entityType, type, ...rest } =
    data as ICard;
  const { carousel, sectionId, moduleId: originalModuleId, moduleSubType } = useModuleContext();

  // This was taken over from mobile's ModuleCard component
  const moduleId = useMemo(() => {
    // If there is a contentId, prefer ID of outermost parent module because this has an enrichable title (eg "Featured Stations")
    // If there is no content ID, prefer innermost module to get title of card clicked (eg "All Podcasts")
    return contentId ?? sectionId ?? originalModuleId;
  }, [contentId, originalModuleId, sectionId]);

  const trackAnalytics = useNavClickEvent({
    contentId,
    entitySubtype,
    entityType,
    feature: ClickFeature.MODULE,
    itemsList: carousel?.cardContentIds,
    moduleId,
  });

  const optionsMenu = useOptionsMenu({
    contentId,
    entityType,
    entitySubtype,
    showContentId: rest.showContentId,
    url: rest.href,
    moduleId: originalModuleId,
    parentShowUrl: rest.showHref,
    parentEpisodeUrl: rest.episodeHref,
  });
  let card = null;

  switch (type) {
    case CardType.Featured:
      card = (
        <FeaturedCard
          contentId={contentId}
          entitySubtype={entitySubtype}
          entityType={entityType}
          {...(data as TModuleFeaturedCardProps)}
        />
      );
      break;

    case CardType.Horizontal:
      card = (
        <HorizontalEntityCard
          {...rest}
          imageURIBase={rest.image}
          entityImageShape={rest.imageShape}
          contentId={contentId}
          onHrefClick={trackAnalytics}
          optionsMenu={optionsMenu}
          entitySubtype={entitySubtype}
          entityType={entityType}
          subType={moduleSubType}
          replayableUntilDateTime={replayableUntilDateTime}
        />
      );
      break;

    default:
      card = (
        <VerticalEntityCard
          {...rest}
          entityImageShape={
            rest.imageShape ??
            (type === CardType.Small ? EntityImageShape.Circular : EntityImageShape.Square)
          }
          entityType={entityType}
          entitySubtype={entitySubtype}
          contentId={contentId}
          imageURIBase={rest.image}
          onClick={trackAnalytics}
        />
      );
  }
  return card;
};

export default Card;
