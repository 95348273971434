export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Mic</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2C12.4735 2 9.55612 4.60771 9.07089 8H13V10H9V13H13V15H9.07089C9.55612 18.3923 12.4735 21 16 21C19.866 21 23 17.866 23 14V9C23 5.13401 19.866 2 16 2ZM26.9941 18.2579C26.9938 18.2583 26.9936 18.2587 28 19C29.0065 19.7413 29.0061 19.7418 29.0058 19.7422L29.0051 19.7432L29.0033 19.7457L28.9984 19.7522L28.9838 19.7716C28.9718 19.7874 28.9555 19.8088 28.9348 19.8354C28.8934 19.8885 28.8346 19.9625 28.7587 20.0543C28.6069 20.2379 28.386 20.4936 28.0986 20.7971C27.5246 21.403 26.6791 22.2061 25.5803 23.0092C23.6122 24.4476 20.7923 25.9128 17.25 26.1996V30.2156H14.75V26.1996C11.2077 25.9128 8.38783 24.4476 6.41975 23.0092C5.32093 22.2061 4.47541 21.403 3.90146 20.7971C3.61399 20.4936 3.39318 20.2379 3.24137 20.0543C3.16542 19.9625 3.10662 19.8885 3.06525 19.8354C3.04456 19.8088 3.02822 19.7874 3.01626 19.7716L3.00162 19.7522L2.99677 19.7457L2.99496 19.7432L2.99421 19.7422C2.99388 19.7418 2.99357 19.7413 4.00002 19C5.00646 18.2587 5.00618 18.2583 5.00592 18.2579L5.00959 18.2628C5.01482 18.2697 5.02422 18.282 5.03775 18.2994C5.06482 18.3342 5.10837 18.3891 5.16807 18.4613C5.28756 18.6058 5.47125 18.8189 5.71654 19.0779C6.20814 19.597 6.94127 20.2939 7.89488 20.9908C9.80544 22.3871 12.5522 23.75 16 23.75C19.4478 23.75 22.1946 22.3871 24.1051 20.9908C25.0588 20.2939 25.7919 19.597 26.2835 19.0779C26.5288 18.8189 26.7125 18.6058 26.832 18.4613C26.8917 18.3891 26.9352 18.3342 26.9623 18.2994C26.9692 18.2906 26.975 18.283 26.9797 18.2768C26.9843 18.2709 26.9879 18.2662 26.9904 18.2628L26.9941 18.2579Z"
      fill="currentColor"
    />
  </svg>
);
