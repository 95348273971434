import { useRef } from 'react';

import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';

const diffContexts = <T>(a: Partial<T>, b: Partial<T>): Partial<Record<keyof T, boolean>> => {
  const keys = new Set([
    ...(Object.keys(a) as Array<keyof T>),
    ...(Object.keys(b) as Array<keyof T>),
  ]);
  const retVal: Partial<Record<keyof T, boolean>> = {};
  for (const key of keys) {
    if (!isEqual(b[key], a[key])) {
      retVal[key] = true;
    }
  }
  return retVal;
};

export const useGetContextDiff = <T>() => {
  const prevContext = useRef<T>({} as T);
  return (context?: T) => {
    const newContext = context ?? ({} as T);
    const diff = diffContexts<T>(prevContext.current, newContext);
    prevContext.current = Object.assign({}, newContext);
    return diff;
  };
};

export const isDefined = <T>(value: T | null | undefined): value is T => !isNil(value);
