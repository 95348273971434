import { useEffect } from 'react';

import { ModuleType } from '@audacy-clients/client-services';
import { useRecoilState } from 'recoil';

import { shouldDisplaySquareAd } from '~/atoms/googleAds';
import { defineAdSlot, getAdSlot } from '~/utils/googleAds';

const ModuleSquareAd: React.FC = () => {
  const [isDisplayable, setIsDisplayable] = useRecoilState(shouldDisplaySquareAd);
  const squareAd = getAdSlot(ModuleType.SQUARE_AD);

  // if there is not already a squareAd slot on page, define it
  if (!squareAd) defineAdSlot(ModuleType.SQUARE_AD);

  useEffect(() => {
    if (!isDisplayable) {
      setIsDisplayable(true);
    }
    if (window.googletag) {
      window.googletag.cmd.push(function () {
        window.googletag.display('google-ad-manager___right-rail-1');
      });
    }
  });

  return <></>;
};

export default ModuleSquareAd;
