import { useContext } from 'react';

import { shouldShowPlayerState } from '@audacy-clients/core/atoms/player';
import { MotionProps, useReducedMotion } from 'framer-motion';
import { useRecoilValue } from 'recoil';

import usePlayerProps from '~/components/Player/hooks/usePlayerProps';
import { PlayerQueueContext } from '~/components/Player/PlayerContextProvider';
import { useBreakpoints } from '~/hooks/use-breakpoints';

export const useGetAnimationProps = (): MotionProps => {
  const hasPlayer = useRecoilValue(shouldShowPlayerState);
  const { isOpen } = usePlayerProps();

  const isVisible = hasPlayer && !isOpen;

  const { greaterThan } = useBreakpoints();
  const shouldReduceMotion = useReducedMotion();
  const { isQueueHead } = useContext(PlayerQueueContext);

  const miniPlayerTransition = {
    initial: {
      opacity: 0,
      y: 0,
    },
    animate: {
      opacity: isVisible ? 1 : 0,
      y: isVisible || shouldReduceMotion ? 0 : greaterThan.MD ? -20 : '100%',
    },
    exit: {
      opacity: 0,
    },
    transition: {
      bounce: 0.1,
      duration: greaterThan.MD ? 0.2 : 0.3,
    },
  };
  return isQueueHead ? {} : miniPlayerTransition;
};
