import { Colors, Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  text: {
    ...Typography.bodyLarge,
    color: Colors.white,
  },
};

export default styles;
