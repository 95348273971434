export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Search</title>
    <circle cx="15.5" cy="15.5" r="11.25" stroke="currentColor" strokeWidth="2.5" />
    <path d="m24 24 4 4" stroke="currentColor" strokeWidth="2.5" />
  </svg>
);
