export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>YouTube logo</title>
    <path
      d="M25.39 6H6.64a5.656 5.656 0 0 0-3.986 1.607A5.481 5.481 0 0 0 1 11.524v8.123a5.48 5.48 0 0 0 1.633 3.925A5.654 5.654 0 0 0 6.61 25.2h18.75a5.656 5.656 0 0 0 3.986-1.607A5.481 5.481 0 0 0 31 19.675v-8.123a5.48 5.48 0 0 0-1.633-3.925A5.655 5.655 0 0 0 25.39 6ZM22 15.94l-9.12 4.563a.418.418 0 0 1-.419.01.405.405 0 0 1-.211-.357v-9.112c0-.148.08-.285.211-.357a.418.418 0 0 1 .419.01L22 15.238a.412.412 0 0 1 0 .702Z"
      fill="currentColor"
    />
  </svg>
);
