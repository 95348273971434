import { ClickFeature } from '@audacy-clients/client-services/core';
import { useCachedLiveEpisodeFromSchedule } from '@audacy-clients/core/atoms/liveNow';
import { useResumePoint } from '@audacy-clients/core/atoms/playbackResumePoints';
import { useCachedPreviousEpisode } from '@audacy-clients/core/atoms/previousEpisode';
import { useModuleContext } from '@audacy-clients/core/components/ModuleRenderer/context';
import { IModuleProps } from '@audacy-clients/core/components/ModuleRenderer/types';
import { formatPublishDateWithDayOfWeek } from '@audacy-clients/core/utils/date';

import { useAudioHelpersWeb } from '~/hooks/use-audio-helpers-web';

import PreviousEpisode from './PreviousEpisode';

const PreviousEpisodeWithState = (props: IModuleProps): JSX.Element | null => {
  const audioHelpers = useAudioHelpersWeb();
  const { viewRootObjectId: contentId } = useModuleContext();

  const episode = useCachedPreviousEpisode(contentId);
  const liveEpisode = useCachedLiveEpisodeFromSchedule({ contentId });
  const { playbackResumePointStartOffset } = useResumePoint(episode);

  if (!episode) {
    return null;
  }

  const handlePlayClick = (): void => {
    const playContext = {
      feature: ClickFeature.MODULE,
      moduleId: props.module.id,
    };

    audioHelpers.playFromId(episode.id, playContext, {
      startOffset: playbackResumePointStartOffset,
    });
  };

  const episodeTitle = episode.title || formatPublishDateWithDayOfWeek(episode.publishDate) || '';

  return (
    <PreviousEpisode
      episode={{
        ...episode,
        title: episodeTitle,
      }}
      isShowLive={!!liveEpisode}
      onPlayClick={handlePlayClick}
    />
  );
};

export default PreviousEpisodeWithState;
