import { BP, mq, Mixins } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  wrapper: {
    position: 'relative',
  },

  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    ...Mixins.focusOutline({ outlineOffset: -1 }),
  },

  checkmark: {
    height: 16,
    flexShrink: 0,
    marginRight: 6, // Apply positive margin to offset grid gap (16px - 10px)
    width: 16,
    [mq(BP.Medium)]: {
      gap: -4, // Apply negative margin to offset grid gap (16px - 20px)
    },
  },

  editableContainer: {
    paddingRight: 60,
    position: 'unset',
  },
};

export default styles;
