import {
  addDays,
  differenceInHours,
  differenceInMinutes,
  Duration,
  format,
  formatDuration,
  getMinutes,
  intervalToDuration,
  isToday,
  isYesterday,
  parseISO,
  subDays,
} from 'date-fns';
import i18n from 'i18next';

// currently there is no way to customize duration format with date-fns
// https://github.com/date-fns/date-fns/issues/2134
interface ILocale {
  xHours: string;
  xMinutes: string;
  xSeconds: string;
}

export const formatDistanceLocale: ILocale = {
  xHours: '{{count}}h',
  xMinutes: '{{count}}m',
  xSeconds: '{{count}}s',
};

const shortEnLocale = {
  formatDistance: (token: keyof ILocale, count: string) =>
    formatDistanceLocale[token].replace('{{count}}', count),
};

export const customFormatDuration = (seconds: number): { duration: Duration; label: string } => {
  const duration = intervalToDuration({ end: seconds * 1000, start: 0 });

  // Only display seconds for durations under one minute
  const timeFormat = seconds < 60 ? ['seconds'] : ['hours', 'minutes'];
  return {
    duration,
    label: formatDuration(duration, {
      format: timeFormat,
      locale: shortEnLocale,
    }),
  };
};

export const getPlayingUntilFromStartAndDuration = (
  startDateTime?: Date,
  durationSeconds?: number,
): string | undefined => {
  if (!startDateTime || !durationSeconds) {
    return undefined;
  }

  return new Date(+startDateTime + durationSeconds * 1000).toISOString();
};

export const getNullSafeDate = (date: string | number | undefined): Date | undefined =>
  date ? new Date(date) : undefined;

export const formatPublishDate = (date: Date): string => {
  const now = new Date();
  const timeDifferenceInMins = differenceInMinutes(now, date);
  const timeDifferenceInHours = differenceInHours(now, date);

  if (timeDifferenceInMins < 5) {
    return i18n.t('global.justNow');
  }

  if (timeDifferenceInMins < 60) {
    return i18n.t('global.timeAgo', {
      time: formatDistanceLocale.xMinutes.replace('{{count}}', timeDifferenceInMins.toString()),
    });
  }

  if (timeDifferenceInHours <= 6) {
    return i18n.t('global.timeAgo', {
      time: formatDistanceLocale.xHours.replace('{{count}}', timeDifferenceInHours.toString()),
    });
  }

  if (isToday(date)) {
    return i18n.t('global.today');
  }

  if (isYesterday(date)) {
    return i18n.t('global.yesterday');
  }

  return format(date, 'MMM d, yyyy');
};

export const formatMessageDate = (date: Date): string => {
  const now = new Date();
  const timeDifferenceInMins = differenceInMinutes(now, date);
  const timeDifferenceInHours = differenceInHours(now, date);

  if (timeDifferenceInMins < 2) {
    return i18n.t('global.justNow');
  }

  if (timeDifferenceInMins < 60) {
    return i18n.t('global.timeAgo', {
      time: i18n.t('global.minutes', { count: timeDifferenceInMins }),
    });
  }

  return i18n.t('global.timeAgo', {
    time: i18n.t('global.hour', { count: timeDifferenceInHours }),
  });
};

export const formatPublishDateWithDayOfWeek = (
  date: Date | undefined,
  separator = ' - ',
): string | undefined => {
  if (!date) {
    return undefined;
  }

  return format(date, `eeee${separator}MMM d, yyyy`);
};

/**
 * Returns localized time based on ISO 8601 string, omits minutes if on the hour.
 * @param {string} date - The ISO 8601 string to take the time of
 * @returns {string} - A formatted time string i.e. "8am" or "8:30am".
 */
export const formatPlayingUntil = (date: string): string => {
  // const locale = i18n.language;
  // TODO: [A2-2288] Format time based on language (am/pm vs. 24 hour)
  // Ticket: https://entercomdigitalservices.atlassian.net/browse/A2-2288

  // convert the passed ISO 8601 string into a localized date object
  const parsedDate = parseISO(date.toLocaleString());

  return formatTime(parsedDate);
};

/**
 * Returns formatted from-to string based on two epoch timestamps,
 * omits the first 'am/pm' if from and to are equally 'am/pm'.
 * @param {number} from - The "from" in epoch time in seconds.
 * @param {number} to - The "to" in epoch time in seconds.
 * @param separator
 * @param omitMinutesIfOnHour
 * @returns {string} - A formatted string i.e. "8-9:30am" or "11:30am-12:30pm".
 */
export const formatFromTo = (
  from: number,
  to: number,
  separator = '-',
  omitMinutesIfOnHour = true,
): string => {
  let formattedFrom = formatTime(new Date(from), omitMinutesIfOnHour);
  const formattedTo = formatTime(new Date(to), omitMinutesIfOnHour);

  if (
    (formattedFrom.endsWith('am') && formattedTo.endsWith('am')) ||
    (formattedFrom.endsWith('pm') && formattedTo.endsWith('pm'))
  ) {
    formattedFrom = formattedFrom.substring(0, formattedFrom.length - 2);
  }

  return `${formattedFrom}${separator}${formattedTo}`;
};

export const formatTime = (date: Date, omitMinutesIfOnHour = true): string => {
  // return time without minutes if on the hour i.e. 2pm instead of 2:00pm
  const timeFormat = getMinutes(date) === 0 && omitMinutesIfOnHour ? 'haaa' : 'h:mmaaa';

  return format(date, timeFormat);
};

export const getRelativeDateFromIndex = ({
  selectedIndex,
  todayIndex,
  today,
}: {
  maxDaysBack?: number;
  selectedIndex: number;
  today: Date;
  todayIndex: number;
}): Date => {
  if (selectedIndex > todayIndex) {
    return addDays(today, selectedIndex - todayIndex);
  }

  if (selectedIndex < todayIndex) {
    return subDays(today, todayIndex - selectedIndex);
  }

  return today;
};

interface IDuration {
  hours: number;
  minutes: number;
  seconds: number;
}

export const getDurationFromSeconds = (seconds: number): IDuration => {
  return {
    hours: Math.floor(seconds / (60 * 60)) % 24,
    minutes: Math.floor(seconds / 60) % 60,
    seconds: seconds % 60,
  };
};

export const formatCountdownDuration = (duration: IDuration) => {
  if (duration.hours) {
    const hours = duration.hours;
    const minutes = duration.minutes.toString().padStart(2, '0');
    const seconds = duration.seconds.toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  }

  const minutes = duration.minutes;
  const seconds = duration.seconds.toString().padStart(2, '0');

  return `${minutes}:${seconds}`;
};
