import { getCurrentEpisodes } from '@audacy-clients/core/atoms/episodes';
import { IEpisode } from '@audacy-clients/core/atoms/wrappers/types';

import { RefreshTimes } from './helpers/constants';
import { refreshableSelectorFamily } from './helpers/refreshable';

export const { useWrappedCachedValue: useCachedPreviousEpisode } = refreshableSelectorFamily<
  IEpisode | undefined,
  string | undefined
>({
  get:
    (id) =>
    ({ get }) => {
      if (!id) {
        return undefined;
      }

      const previousEpisode = get(getCurrentEpisodes(id))
        .getAll()
        .find((e) => e.isReplayable && e.endTime < Date.now());

      return previousEpisode;
    },
  key: 'PreviousEpisode',
  refreshEverySeconds: RefreshTimes.every30s,
});
