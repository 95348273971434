import { useCallback } from 'react';

import { useClientServices } from '@audacy-clients/core/utils/clientServices';
import { TGetViewContext } from '@audacy-clients/core/utils/viewContext';

export const useQueueDataEvents = (getViewContext?: TGetViewContext) => {
  const { clientServices } = useClientServices();
  const viewContext = getViewContext?.();

  const onAdd = useCallback(
    (id: string): void => {
      viewContext &&
        clientServices?.getAnalyticServices().sendQueueAddRemove(viewContext, id, true);
    },
    [clientServices, viewContext],
  );

  const onRemove = useCallback(
    (ids: string[]): void => {
      viewContext &&
        ids.forEach((id) =>
          clientServices?.getAnalyticServices().sendQueueAddRemove(viewContext, id, false),
        );
    },
    [clientServices, viewContext],
  );

  return {
    onAdd,
    onRemove,
  };
};
