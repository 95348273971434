export default (
  <svg width="214" height="131" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden>
    <rect y="39.861" width="26.805" height="26.956" rx="2" fill="#fff" fillOpacity=".15" />
    <path
      fill="#fff"
      fillOpacity=".15"
      d="M35.741 46.151h107.218v4.493H35.741zM35.74 55.136h58.97v4.493H35.74z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M172.442 48.847c0-.497-.4-.899-.893-.899a.896.896 0 0 0-.893.899c0 .496.4.898.893.898a.896.896 0 0 0 .893-.898Zm4.647 0c0-.497-.4-.899-.894-.899a.896.896 0 0 0-.893.899c0 .496.4.898.893.898a.896.896 0 0 0 .894-.898Zm3.752-.899c.494 0 .894.402.894.899 0 .496-.4.898-.894.898a.896.896 0 0 1-.893-.898c0-.497.4-.899.893-.899Z"
      fill="#fff"
      fillOpacity=".15"
    />
    <rect width="26.805" height="26.956" rx="2" fill="#fff" fillOpacity=".15" />
    <path
      fill="#fff"
      fillOpacity=".15"
      d="M35.741 6.29h107.218v4.493H35.741zM35.74 15.275h58.97v4.493H35.74z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M172.442 8.986c0-.496-.4-.899-.893-.899a.896.896 0 0 0-.893.899c0 .496.4.898.893.898a.896.896 0 0 0 .893-.898Zm4.647 0c0-.496-.4-.899-.894-.899a.896.896 0 0 0-.893.899c0 .496.4.898.893.898a.896.896 0 0 0 .894-.898Zm3.752-.899c.494 0 .894.403.894.899s-.4.898-.894.898a.896.896 0 0 1-.893-.898c0-.496.4-.899.893-.899Z"
      fill="#fff"
      fillOpacity=".15"
    />
    <rect y="80.295" width="26.805" height="26.956" rx="2" fill="#fff" fillOpacity=".15" />
    <path
      fill="#fff"
      fillOpacity=".15"
      d="M35.741 86.585h107.218v4.493H35.741zM35.74 95.571h58.97v4.493H35.74z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M172.442 88.383c0-.496-.4-.899-.893-.899a.896.896 0 0 0-.893.899c0 .496.4.898.893.898a.896.896 0 0 0 .893-.898Zm4.647 0c0-.496-.4-.899-.894-.899a.896.896 0 0 0-.893.899c0 .496.4.898.893.898a.896.896 0 0 0 .894-.898Zm3.752-.899c.494 0 .894.403.894.899s-.4.898-.894.898a.896.896 0 0 1-.893-.898c0-.496.4-.899.893-.899Z"
      fill="#fff"
      fillOpacity=".15"
    />
    <ellipse cx="176.494" cy="88.5" rx="28" ry="28.5" fill="#090527" />
    <path
      d="M176.494 117.5c15.749 0 28.5-12.992 28.5-29s-12.751-29-28.5-29c-15.748 0-28.5 12.992-28.5 29s12.752 29 28.5 29Z"
      stroke="#fff"
      strokeOpacity=".3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M170.494 88.5c0-.863-.671-1.563-1.5-1.563-.828 0-1.5.7-1.5 1.563s.672 1.563 1.5 1.563c.829 0 1.5-.7 1.5-1.563Zm7.8 0c0-.863-.671-1.563-1.5-1.563-.828 0-1.5.7-1.5 1.563s.672 1.563 1.5 1.563c.829 0 1.5-.7 1.5-1.563Zm6.3-1.563c.829 0 1.5.7 1.5 1.563s-.671 1.563-1.5 1.563c-.828 0-1.5-.7-1.5-1.563s.672-1.563 1.5-1.563Z"
      fill="#fff"
    />
  </svg>
);
