// Essentially a fork or recoil-nexus, a small library for using Recoil state
// outside of React context. See https://github.com/luisanton-io/recoil-nexus

// See comments below for stuff that was changed

import { RecoilValue, RecoilState, useRecoilCallback } from 'recoil';

type Nexus = {
  get: <T>(atom: RecoilValue<T>) => T;
  getPromise: <T>(atom: RecoilValue<T>) => Promise<T>;
  set: <T>(atom: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) => void;
  reset: <T>(atom: RecoilState<T>) => void;
  refresh: <T>(atom: RecoilValue<T>) => void;
};

const nexus = {} as Nexus;

export default function RecoilNexus() {
  nexus.get = useRecoilCallback(
    ({ snapshot }) =>
      function <T>(atom: RecoilValue<T>) {
        return snapshot.getLoadable(atom).contents;
      },
    [],
  );

  // recoil-nexus has broken types here but the Nexus type is correct so disabling type checker
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  nexus.getPromise = useRecoilCallback<[atom: RecoilValue<unknown>], Promise<unknown>>(
    ({ snapshot }) =>
      function <T>(atom: RecoilValue<T>) {
        return snapshot.getPromise(atom);
      },
    [],
  );

  // Updated this function to remove the unnecessary/broken transaction
  nexus.set = useRecoilCallback(({ set }) => {
    return function <T>(atom: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) {
      set(atom, valOrUpdater);
    };
  }, []);

  nexus.reset = useRecoilCallback(({ reset }) => reset, []);

  // Added refresh feature not in recoil-nexus
  nexus.refresh = useRecoilCallback(({ refresh }) => refresh, []);

  return null;
}

export function getRecoil<T>(atom: RecoilValue<T>): T {
  return nexus.get(atom);
}

export function getRecoilPromise<T>(atom: RecoilValue<T>): Promise<T> {
  return nexus.getPromise(atom);
}

export function setRecoil<T>(atom: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) {
  nexus.set(atom, valOrUpdater);
}

export function resetRecoil<T>(atom: RecoilState<T>) {
  nexus.reset(atom);
}

export function refreshRecoil<T>(atom: RecoilValue<T>) {
  nexus.refresh(atom);
}
