import { playerSpeedState } from '@audacy-clients/core/atoms/player';
import { useRecoilState } from 'recoil';

export const usePlaybackSpeed = () => {
  const [{ currentRate, savedRate, availableRates }] = useRecoilState(playerSpeedState);

  return {
    playbackSpeed: currentRate,
    savedPlaybackSpeed: savedRate,
    availableRates: availableRates,
  };
};
