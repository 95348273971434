import { type ViewIDKey } from '@audacy-clients/core/atoms/config/constants';
import { type TTranscript } from '@audacy-clients/core/atoms/transcripts';
import { type IPlayContext } from '@audacy-clients/core/hooks/useAudioHelpers';
import { type PlayAssistantProps } from '@audacy-clients/core/types/voiceEventsData';
import { useClientServices } from '@audacy-clients/core/utils/clientServices';
import { formatSecondsToTime } from '@audacy-clients/core/utils/time';
import { type IViewContext, type TGetViewContext } from '@audacy-clients/core/utils/viewContext';
import { useCallback } from 'react';

type UseTranscriptAnalyticReturnType = {
  getAnalyticPlayContext: (playContext: IPlayContext, item?: TTranscript) => IPlayContext;
  sendTranscriptPlayClickEvent: (
    playContext: IPlayContext,
    playAssistantProps?: PlayAssistantProps,
    viewId?: ViewIDKey,
  ) => void;
  sendTranscriptScreenViewEvent: (viewId?: ViewIDKey) => void;
};

const useTranscriptDataEvents = (
  getViewContext: TGetViewContext | (() => IViewContext),
): UseTranscriptAnalyticReturnType => {
  const { clientServices, loading } = useClientServices();

  const viewContext = getViewContext();

  const getAnalyticPlayContext = useCallback((playContext: IPlayContext, item?: TTranscript) => {
    if (!item) {
      return playContext;
    }
    const featureText = getFormattedFeatureText(item);
    // TODO: [CCS-3436] There is an issue on BE that doesn't allow us to send this property
    // Uncomment when this issue is settled
    // const secondsOffset = item.start_time.toString();
    return { ...playContext, featureText };
  }, []);

  const sendTranscriptPlayClickEvent = useCallback(
    (playContext: IPlayContext, playAssistantProps?: PlayAssistantProps, viewId?: ViewIDKey) => {
      const {
        contentId: contextContentId,
        viewId: contextViewId,
        ...restViewContext
      } = viewContext;
      const auxViewContext = {
        ...restViewContext,
        contentId: contextContentId ?? playContext.contentId,
        viewId: viewId ?? contextViewId,
      };

      const analyticServices = clientServices?.getAnalyticServices();
      if (analyticServices) {
        analyticServices.sendPlayClickEvent(auxViewContext, playContext, playAssistantProps);
      }
    },
    [clientServices, viewContext],
  );

  const sendTranscriptScreenViewEvent = useCallback(
    (viewId?: ViewIDKey) => {
      if (loading) {
        return;
      }

      const { viewId: contextViewId, ...restViewContext } = viewContext;
      const auxViewContext = {
        ...restViewContext,
        viewId: viewId ?? contextViewId,
      };

      const analyticServices = clientServices?.getAnalyticServices();
      if (analyticServices) {
        analyticServices.sendScreenView(auxViewContext);
      }
    },
    [clientServices, loading, viewContext],
  );

  return { getAnalyticPlayContext, sendTranscriptPlayClickEvent, sendTranscriptScreenViewEvent };
};

const getFormattedFeatureText = (item: TTranscript) => {
  const { speaker, text, start_time } = item;
  return `${speaker} | ${formatSecondsToTime(start_time / 1000)} | ${text}`;
};

export default useTranscriptDataEvents;
