export enum PlaybackFractionPositionType {
  End = 'End',
  Middle = 'Middle',
  Start = 'Start',
}

export enum CurrentChapterPositionType {
  End = 'End',
  Middle = 'Middle',
  Start = 'Start',
  StartAndEnd = 'StartAndEnd',
}

export enum PlayerState {
  Ended = 'Ended',
  Error = 'Error',
  Idle = 'Idle', // TODO: [CCS-1391] Remove
  Loading = 'Loading',
  Paused = 'Paused',
  Playing = 'Playing',
}
