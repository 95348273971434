import { EntitySubtype, EntityType } from '@audacy-clients/client-services';
import Collection from '@audacy-clients/client-services/src/dataServices/Collection';
import Episode from '@audacy-clients/client-services/src/dataServices/Episode';
import SearchResultList from '@audacy-clients/client-services/src/dataServices/SearchResultList';
import Show from '@audacy-clients/client-services/src/dataServices/Show';
import Song from '@audacy-clients/client-services/src/dataServices/Song';
import Station from '@audacy-clients/client-services/src/dataServices/Station';
import Tag from '@audacy-clients/client-services/src/dataServices/Tag';
import { getNullSafeDate } from '@audacy-clients/core/utils/date';

import { IClientServicesPaginatedList } from './types';

export interface ISearchResult {
  entitySubtype: EntitySubtype;
  entityType: EntityType;
  genres?: string[];
  id: string;
  image?: string;
  imageAlt?: string;
  isFeatured?: boolean;
  marketTitle?: string;
  author?: string;
  title: string;
  url?: string;
  duration?: number;
  publishDate?: Date;
  parentTitle?: string;
  parentImage?: string;
  parentGenres?: string[];
  updateDate?: Date;
  itemsIdList?: string[];
  showContentId?: string;
}

export const wrapSearchResultsList = (
  list: SearchResultList,
): IClientServicesPaginatedList<ISearchResult | null> => {
  return {
    get: (i: number) => {
      const result = list.getObjectSync(i);

      if (result) {
        return wrapSearchResult(result);
      }

      return null;
    },
    pageLength: list.getNumInCore(),
    total: list.getNum(),
  };
};

const wrapSearchResult = (
  result: Episode | Show | Station | Song | Tag | Collection,
): ISearchResult => {
  return {
    entitySubtype: result.getEntitySubtype(),
    entityType: result.getEntityType(),
    genres: result.getGenres(),
    id: result?.getId(),
    image: result.getImages()?.square,
    imageAlt: result.getImages()?.alt,
    marketTitle: result.getMarketTitle(),
    author: 'getAuthor' in result ? result.getAuthor() : undefined,
    title: result.getTitle(),
    url: result.getUrl() ?? undefined,
    duration: 'getDuration' in result ? result.getDuration() : undefined,
    itemsIdList: 'getItemsIdList' in result ? result.getItemsIdList() : undefined,
    showContentId: 'getParentShowId' in result ? result.getParentShowId() : undefined,
    publishDate: 'getPublishDate' in result ? getNullSafeDate(result.getPublishDate()) : undefined,
    parentTitle:
      'getParentTitle' in result
        ? result.getParentTitle()
        : 'getParentStation' in result
        ? result.getParentStation()?.getTitle()
        : undefined,
    parentImage: 'getParentImageSquare' in result ? result.getParentImageSquare() : undefined,
    parentGenres: 'getParentStation' in result ? result.getParentStation()?.getGenres() : undefined,
    updateDate: 'getUpdatedDate' in result ? getNullSafeDate(result.getUpdatedDate()) : undefined,
  };
};
