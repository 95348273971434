export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Information</title>
    <path d="M16 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" fill="currentColor" />
    <path
      d="M14.2 13.421h2v12h2"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
