export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Live</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 28V4h-1.5v24H13Zm-8 0V17H3.5v11H5Zm16-17v17h-1.5V11H21Zm8 17v-9h-1.5v9H29Z"
      fill="currentColor"
    />
  </svg>
);
