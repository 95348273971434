const MINUTE = 60 * 1000;

export const CacheTimes = {
  limit10m: 10 * MINUTE,
  limit10s: 10 * 1000,
  limit60s: MINUTE,
};

export const RefreshTimes = {
  every15s: 15 * 1000,
  every30s: 30 * 1000,
  every5min: 5 * 60 * 1000,
};

export const PageSizes = {
  episodesList: 20,
};

export const nullResponse = {
  getContent: () => undefined,
  module: undefined,
};

export const staleTime = {
  VERY_SLOW: 15 * 60 * 1000, // 15 minutes in milliseconds
  SLOW: 5 * 60 * 1000, // 5 minutes in milliseconds
  FAST: 1 * 60 * 1000, // 1 minute in milliseconds
  VERY_FAST: 15 * 1000, // 15 seconds in milliseconds
  ONE_DAY: 24 * 60 * 60 * 1000, // 1 day in milliseconds
  FOREVER: Number.MAX_SAFE_INTEGER, // A very large number representing "forever"
};
