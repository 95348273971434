import { Borders, Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  showAll: {
    ...Typography.buttonSmall,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  showAllIcon: {
    marginLeft: 10,
  },
  container: {
    marginTop: 20,
  },
  footer: {
    borderTop: Borders.separator,
    marginTop: 20,
    paddingTop: 20,
  },
};

export default styles;
