import { ISongHistoryItem } from '@audacy-clients/client-services/src/Constants';

import List from '~/components/List';
import SongHistoryListItem from '~/components/SongHistory/SongHistoryListItem';

interface ISongHistoryListViewProps {
  ariaLabel?: string;
  items: ISongHistoryItem[];
}

const SongHistoryListView = ({
  ariaLabel = 'SongHistoryList',
  items,
}: ISongHistoryListViewProps): JSX.Element => {
  const renderItem = ({
    item,
    isFirst,
  }: {
    item: ISongHistoryItem;
    isFirst: boolean;
  }): JSX.Element => <SongHistoryListItem isFirst={isFirst} {...item} />;

  return <List ariaLabel={ariaLabel} items={items} renderItem={renderItem} />;
};

export default SongHistoryListView;
