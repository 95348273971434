export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Stop</title>
    <path fill="currentColor" d="M5 5h22v22H5z" />
  </svg>
);
