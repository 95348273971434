import styles from '~/components/Carousel/styles';

export interface IStackedHolderProps {
  children?: React.ReactNode;
}

const StackedCardHolder = ({ children }: IStackedHolderProps): JSX.Element => (
  <div css={styles.stackedCardHolder}>{children}</div>
);

export default StackedCardHolder;
