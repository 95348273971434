import { ModuleSubType, ModuleType } from '@audacy-clients/client-services';
import { isConsideredPlayed } from '@audacy-clients/client-services/src/utils/playbackResumePoints';
import { noCacheDefaultSelectorFamily } from '@audacy-clients/core/atoms/helpers/noCacheDefaultSelector';
import { wrapEpisode, wrapEpisodeList } from '@audacy-clients/core/atoms/wrappers/episode';
import clientServices from '@audacy-clients/core/utils/clientServices';

import { addDays, startOfDay } from 'date-fns';
import { type SerializableParam } from 'recoil';
import { nullResponse } from '../helpers/constants';
import { fixedPlaybackResumePointState } from '../playbackResumePoints';
import { recentsSelector } from '../recents';
import { type IModuleViewComponent, ModuleSize, wrapModule } from '../wrappers/modules';
import { type IEpisode } from '../wrappers/types';
import { getIsEpisodeToday, groupScheduleEpisodesByStartTime, type IScheduleGroup } from './utils';

export const getSchedules = noCacheDefaultSelectorFamily({
  // The schedule endpoint technically supports both shows and stations,
  // but Client Services's schedule utility is only setup for stations.
  // For show requests, use episodesQuery.
  get: (stationId: string) => async () =>
    wrapEpisodeList(await clientServices.getDataServices().getSchedules(stationId)),
  key: 'Schedules',
});

type IScheduleGroupsParams = {
  id: string;
  selectedDate: Date;
};

export const getScheduleGroups = noCacheDefaultSelectorFamily<
  Array<IScheduleGroup>,
  IScheduleGroupsParams & SerializableParam
>({
  get:
    ({ id, selectedDate }) =>
    ({ get }) => {
      const episodesList = get(getSchedules(id));

      const filteredEpisodes = episodesList
        .getAll()
        // Filter out all undefined and not in within date
        .filter(
          (episode) =>
            episode && getIsEpisodeToday(selectedDate, episode.startTime, episode.endTime),
        );

      return groupScheduleEpisodesByStartTime(filteredEpisodes, selectedDate);
    },
  key: 'ScheduleGroups',
});

type IEpisodesByScheduleParams = {
  stationId?: string;
  daysOffset?: number;
};

export const getEpisodesBySchedule = noCacheDefaultSelectorFamily<
  IModuleViewComponent,
  IEpisodesByScheduleParams & SerializableParam
>({
  get:
    ({ stationId, daysOffset = -7 }) =>
    ({ get }) => {
      if (!stationId) {
        return nullResponse;
      }

      const { recents } = get(recentsSelector);
      const episodesList = get(getSchedules(stationId));

      if (!recents.length) {
        return nullResponse;
      }

      const lastListenTimestamp: Record<string, string> = recents.reduce(
        (acc, recent) => ({ ...acc, [recent.id]: recent.lastListenDateTime }),
        {},
      );

      if (!episodesList) {
        return nullResponse;
      }

      const endDate = startOfDay(new Date()).getTime();
      const startDate = startOfDay(addDays(endDate, daysOffset)).getTime();

      const filteredEpisodes = episodesList
        .getAll()
        .filter(({ id, duration, isReplayable, startTime, startDateTime }) => {
          const fixedPlaybackResumePoint = get(
            fixedPlaybackResumePointState({ id, startDateTime }),
          );

          return (
            isReplayable &&
            startTime >= startDate &&
            fixedPlaybackResumePoint &&
            !isConsideredPlayed(duration, fixedPlaybackResumePoint)
          );
        })
        .slice(0, 10) // TODO: the limit needs to be clarified in [CCS-3449 CCS-3516]
        .sort(
          (a, b) =>
            new Date(lastListenTimestamp[b.id]).getTime() -
            new Date(lastListenTimestamp[a.id]).getTime(),
        );

      if (filteredEpisodes.length === 0) {
        return nullResponse;
      }

      const content: Record<string, IEpisode> = {};

      const carouselModules = filteredEpisodes.map((episode) => {
        content[episode.id] = wrapEpisode(episode.getDataObject());

        return {
          config: {
            contentId: episode.id,
            moduleSize: ModuleSize.Large,
            subType: ModuleSubType.UNFINISHED_EPISODES,
          },
          moduleId: `CLIENT--ENTITY_CARD_HORIZONTAL--UNFINISHED_EPISODES--${episode.id}`,
          moduleType: ModuleType.ENTITY_CARD_HORIZONTAL,
        };
      });

      const module = wrapModule({
        moduleId: 'CLIENT--SECTION_WRAPPER--UNFINISHED_EPISODES',
        moduleType: ModuleType.SECTION_WRAPPER,
        modules: [
          {
            moduleId: 'CLIENT--CAROUSEL--UNFINISHED_EPISODES',
            moduleType: ModuleType.CAROUSEL,
            modules: carouselModules,
            // NOTICE: this is a hack to use the logic and behavior of the Stacked slider in one row
            config: { isStacked: true },
          },
        ],
      });

      return { getContent: (id) => (id ? content[id] : undefined), module };
    },
  key: 'EpisodesBySchedule',
});
