export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Live</title>
    <rect x="2" y="8" width="28" height="16" rx="4" fill="currentColor" />
    <path
      d="M11.629 19v-1.224H8.965V12.7H7.633V19h3.996Zm2.08 0v-6.3h-1.332V19h1.332Zm4.105 0 2.277-6.3h-1.413l-1.521 4.437h-.018l-1.53-4.437h-1.404l2.268 6.3h1.34Zm6.818 0v-1.224h-2.736v-1.404h2.484v-1.17h-2.484v-1.278h2.727V12.7h-4.059V19h4.068Z"
      fill="#090527"
    />
  </svg>
);
