import { BP } from '~/styles';

export const FullPrimaryIconSize: { [key in BP]: number } = {
  [BP.Small]: 26,
  [BP.SmallMax]: 26,
  [BP.Medium]: 32,
  [BP.MediumMax]: 32,
  [BP.Large]: 35,
  [BP.LargeMax]: 35,
  [BP.ExtraLarge]: 35,
  [BP.XXLarge]: 40,
  [BP.XXXLarge]: 50,
};

export const FullSecondaryIconSize: { [key in BP]: number } = {
  [BP.Small]: 28,
  [BP.SmallMax]: 28,
  [BP.Medium]: 32,
  [BP.MediumMax]: 32,
  [BP.Large]: 32,
  [BP.LargeMax]: 32,
  [BP.ExtraLarge]: 32,
  [BP.XXLarge]: 40,
  [BP.XXXLarge]: 50,
};
