import { keyframes } from '@emotion/react';

import { Colors } from '~/styles';
import { mqReducedMotion } from '~/styles/breakpoints';
import { TStyles, TStyleSheet } from '~/types/emotion-styles';

export const Constants = {
  barCount: 4,
  barDelay: 0.12,
  barWidth: 7,
  duration: 2.8,
  durationReducedMotion: 8.4, // normal duration * 3
};

const upperBarAnimation = keyframes`
  0% {
    opacity: 1;
    transform: scaleY(0);
  }
  12.5% {
    transform: scaleY(20);
  }
  25%, 50% {
    transform: scaleY(7);
  }
  62.5% {
    transform: scaleY(25);
  }
  75%, 87.5% {
    opacity: 1;
    transform: scaleY(7);
  }
  87.51%, 100% {
    opacity: 0;
    transform: scaleY(0);
  }
`;

const lowerBarAnimation = keyframes`
  0%, 25% {
    opacity: 1;
    transform: scaleY(0);
  }
  25.1% {
    transform: scaleY(7);
  }
  37.5% {
    transform: scaleY(25);
  }
  50%, 75% {
    transform: scaleY(7);
  }
  81% {
    transform: scaleY(15);
  }
  87.5% {
    transform: scaleY(7);
  }
  99.9% {
    opacity: 1;
    transform: scaleY(0);
  }
  100% {
    opacity: 0;
  }
`;

export const getAnimationDelay = (index: number): TStyles => {
  return {
    animationDelay: `${-Constants.duration - index * Constants.barDelay}s`,
  };
};

const styles: TStyleSheet = {
  center: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 'inherit',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    height: 50,
    width: Constants.barWidth * (Constants.barCount * 2 - 1),
  },
  barContainer: {
    position: 'relative',
    width: Constants.barWidth,
  },
  barBase: {
    backgroundColor: Colors.white,
    position: 'absolute',
    left: 0,
    width: '100%',
    height: 1,
    opacity: 0,
  },
  barUpper: {
    bottom: 25,
    transformOrigin: 'bottom',
    animationName: upperBarAnimation,
    animationDuration: `${Constants.duration}s`,
    animationIterationCount: 'infinite',
    ...mqReducedMotion({
      animationDuration: `${Constants.durationReducedMotion}s`,
    }),
  },
  barLower: {
    top: 18,
    transformOrigin: 'top',
    animationName: lowerBarAnimation,
    animationDuration: `${Constants.duration}s`,
    animationIterationCount: 'infinite',
    ...mqReducedMotion({
      animationDuration: `${Constants.durationReducedMotion}s`,
    }),
  },
};

export default styles;
