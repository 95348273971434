import React, { useCallback, useMemo } from 'react';

import { useGetViewInfoByTypeAndSubtype } from '@audacy-clients/core/atoms/config/navigation';
// example of importing subnav hook
// import { useClientSubNavigation } from '@audacy-clients/core/atoms/config/navigation';
import { useModules, useRefreshModules } from '@audacy-clients/core/atoms/modules';
import { TModuleRequest } from '@audacy-clients/core/atoms/modules/requests';
import { IModule } from '@audacy-clients/core/atoms/wrappers/modules';

import ModuleView from './ModuleView';
import { TModuleMapping, TModuleWrapper } from './types';
import { useFeatureFlag } from '@audacy-clients/core/atoms/config/settings';
import { ModuleType } from '@audacy-clients/client-services';

export interface IModuleFactoryOptions<T> {
  request: TModuleRequest;
  moduleMap: TModuleMapping;
  moduleWrapper: TModuleWrapper;
  navigation?: T;
  onNavigate?: () => void;
}

export interface IModuleFactory {
  modules: ILazyModule[];
  view: ReturnType<typeof useModules>;
  renderer: typeof RENDERER;
  refresh: () => void;
}

export interface ILazyModule {
  render(): JSX.Element;
  module: IModule;
}

const RENDERER = ({ item }: { item: ILazyModule }) => item.render();

export const useModuleFactory = <T,>({
  request,
  moduleMap,
  moduleWrapper,
  navigation,
  onNavigate,
}: IModuleFactoryOptions<T>): IModuleFactory => {
  const view = useModules(request);
  const pickupLeftoffEnabled = useFeatureFlag('usePickupWhereYouLeftOff');

  const getViewInfo = useGetViewInfoByTypeAndSubtype();
  // example of subnav hook usage
  // const subnav = useClientSubNavigation();

  const viewInfoFromMap = useMemo(
    () => getViewInfo(view?.rootObject?.type, view?.rootObject?.subtype),
    [getViewInfo, view],
  );

  // viewId can come from different sources:
  // 1) from request, if request includes viewId
  // 2) from viewMap based on rootObject's type and subtype (i.e. any entity-driven page like station, podcast, etc.)
  // 3) the 'id' of cms data for current view (as fallback)
  // TODO: [A2-5897] Update the below once viewId is available on view (see XP ticket A2-5896)
  const viewId =
    ('viewId' in request && (request.viewId as string)) || viewInfoFromMap?.viewId || view.id;

  // const refreshModules = useRefreshModules(request);

  const modules = useMemo(
    (): ILazyModule[] =>
      view.modules.flatMap((module, idx) => {
        const isPickupLeftOff = module.type === ModuleType.PICKUP_LEFT_OFF;
        const isStationLiveNow = module.type === ModuleType.STATION_LIVE_NOW;
        const isRewindable = view.rootObject?.rewindable;

        if (
          (isPickupLeftOff && isRewindable && !pickupLeftoffEnabled) ||
          (isStationLiveNow && isRewindable && pickupLeftoffEnabled)
        ) {
          return [];
        }

        return {
          module,
          render: () => (
            <ModuleView
              getData={view.getContent}
              isFirst={idx === 0}
              isLast={idx === view.modules.length - 1}
              isTopLevel={true}
              key={module.uniqueId}
              module={module}
              moduleMap={moduleMap}
              moduleWrapper={moduleWrapper}
              navigation={navigation}
              rootObject={view.rootObject}
              viewId={viewId}
              viewRootObjectId={view.rootObject?.id}
              viewRootObjectRewindable={view.rootObject?.rewindable ?? false}
              viewRootObjectUrl={view.rootObject?.url ?? undefined}
              viewType={view.type}
              onNavigate={onNavigate}
            />
          ),
        };
      }),
    [moduleMap, moduleWrapper, navigation, onNavigate, view, viewId],
  );

  const refreshModules = useRefreshModules(request);
  const refresh = useCallback(() => refreshModules(true), [refreshModules]);

  return useMemo(
    (): IModuleFactory => ({
      modules,
      view,
      renderer: RENDERER,
      refresh,
    }),
    [modules, view, refresh],
  );
};
