import { Colors, Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  item: {
    marginBottom: 20,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  button: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'start',
  },
  label: {
    ...Typography.metadataLarge,
    textAlign: 'left',
    marginTop: 5,
  },
  labelPlaying: {
    color: Colors.white60,
  },
  duration: {
    color: Colors.white60,
    position: 'relative',
    paddingLeft: 16,

    '&::before': {
      content: '""',
      display: 'inline',
      position: 'absolute',
      top: '50%',
      left: 8,
      background: Colors.white60,
      borderRadius: 3,
      height: 2,
      width: 2,
    },
  },
  pill: {
    ...Typography.metadataSmall,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white15,
    borderRadius: 100,
    minWidth: 65,
    height: 28,
    marginRight: 12,
  },
  pillPlaying: {
    backgroundColor: 'transparent',
    border: `1px solid ${Colors.white15}`,
  },
  liveIcon: {
    position: 'relative',
    marginLeft: 12,
    top: 3,
    display: 'inline-block',
  },
};

export default styles;
