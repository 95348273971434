import { AudacyColors } from '@audacy-clients/core/styles/colors';

import { Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  highlightText: {
    ...Typography.buttonSmall,
    backgroundColor: AudacyColors.secondaryError,
    borderRadius: 3,
    padding: '1px 5px',
    textAlign: 'center',
    fontSize: 9,
    letterSpacing: '-0.03em',
    textTransform: 'uppercase',
  },
};

export default styles;
