export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Close</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m26.563 6.593.53-.531L26.033 5l-.53.53-9.512 9.534-9.494-9.533L5.967 5 4.91 6.063l.53.531 9.492 9.532-9.33 9.35-.53.531 1.06 1.062.53-.53 9.329-9.35 9.332 9.37.529.531 1.058-1.063-.529-.531-9.33-9.369 9.513-9.534Z"
      fill="currentColor"
    />
  </svg>
);
