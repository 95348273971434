import Loading from '~/components/Loading';
import { Mixins } from '~/styles';

const FullScreenLoading = (): JSX.Element => {
  return (
    <div aria-label="Full Screen Loading" css={Mixins.fullScreenContainer}>
      <Loading />
    </div>
  );
};

export default FullScreenLoading;
