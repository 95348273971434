import { QueryClient } from 'react-query';

export function createQueryClient(): QueryClient {
  const client = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: Infinity,
      },
    },
  });

  return client;
}
