// General utility functions
import { type IFeatureFlags } from '@audacy-clients/core/atoms/config/settings';
import _ from 'lodash';
import { type AnyEventObject, type DoneInvokeEvent } from 'xstate/lib/types';
import { type IMachineContext } from '../audioServices/types';
import { LIVE_CHAPTER_CACHE_EXPIRATION, MAX_CACHE_EXPIRATION } from '../Constants';
import type Chapter from '../dataServices/Chapter';
import type Episode from '../dataServices/Episode';
import audacyPlatformSettings, { PlatformSettingsKey } from '../platformSettings/PlatformSettings';

export function webSafe64(base64: string) {
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export const memoizer = {
  resolvedFunctions: new Map(),
  /**
   * Clear cache when switching users to remove previous user data
   */
  clearCache: () => {
    memoizer.resolvedFunctions = new Map();
  },
  /**
   * Only ever run a function once, MUST be a pre-defined function
   * May need to .bind the function or define as an arrow function
   * e.g. memoizer.call(this.profileApi.getFollows) , getFollows = () => { ... }
   */
  call: _.memoize(async (func) => {
    const ret = await func();
    memoizer.resolvedFunctions.set(func, true);
    return ret;
  }),
  /**
   * Used with memoizer.call to tell if the function ran
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  didResolve: (func: Function) => {
    return Boolean(memoizer.resolvedFunctions.get(func));
  },
};

export const cache = {
  store: new Map(),

  set: (key: string, value: Array<Episode | Chapter> | string[], expiration = 0) => {
    const now = Date.now();
    cache.store.set(key, {
      data: value,
      useExpiration: expiration > 0,
      expiration: expiration > 0 ? now + expiration : now + MAX_CACHE_EXPIRATION,
    });
  },
  get: (key: string) => {
    const cachedData = cache.store.get(key);
    const useExpiration = cachedData?.useExpiration;
    const isValid = cachedData?.expiration > Date.now();

    if (!useExpiration) {
      return cachedData?.data;
    } else if (useExpiration && isValid) {
      return cachedData?.data;
    } else {
      return '';
    }
  },
  // checks the store for any expired values and deletes them
  clean: () => {
    for (const [key, value] of cache.store.entries()) {
      const now = Date.now();
      if (value.expiration < now) {
        cache.store.delete(key);
      }
    }
  },
  delete: (key: string) => {
    cache.store.delete(key);
  },
};

export async function setQuickPauseChapterCache(id: string): Promise<void> {
  const chapterCache = cache.get(id);
  if (chapterCache) {
    cache.set(`${id}-quickPause`, chapterCache, LIVE_CHAPTER_CACHE_EXPIRATION);
  }
}

export function shouldUseCache(
  playback: number,
  data: Chapter[],
  start: number,
  liveBroadcastEnded: boolean,
): boolean {
  if (!data) {
    return false;
  }

  if (liveBroadcastEnded) {
    return true;
  }

  const lastChapter = data.pop();
  const offset = lastChapter?.data.startOffset || 0;
  const duration = lastChapter?.data.duration || 0;
  const max = offset + duration;

  return playback > start && playback < max;
}

export const handleErrorAction = (stateName: string, machineName: string) => {
  return (context: IMachineContext, event: DoneInvokeEvent<AnyEventObject>) => {
    context.ddError(
      `Error invoking service for ${stateName} in ${machineName}: ${event?.data?.message}`,
    );
  };
};

export const handleTimeoutAction = (stateName: string, machineName: string) => {
  return (context: IMachineContext) => {
    context.ddError(`Timeout invoking service for ${stateName} in ${machineName}`);
  };
};

export const serializeDOMException = (error: DOMException) =>
  JSON.stringify({
    name: error.name,
    message: error.message,
    code: error.code,
  });

export const featureFlagMap = {
  defaultFlags: 'defaultFlags',
  remoteSettings: 'remoteSettings',
  brazeFlags: 'brazeFlags',
  overrideFlags: 'overrideFlags',
  store: new Map(),
  set: (key: string, data: IFeatureFlags) => {
    featureFlagMap.store.set(key, data);
  },
  get: (key: string) => {
    return featureFlagMap.store.get(key);
  },
  getAll: (): IFeatureFlags => {
    return {
      ...featureFlagMap.store.get(featureFlagMap.defaultFlags),
      ...featureFlagMap.store.get(featureFlagMap.remoteSettings),
      ...featureFlagMap.store.get(featureFlagMap.brazeFlags),
      ...featureFlagMap.store.get(featureFlagMap.overrideFlags),
    };
  },
};

export const isEnableTraceSessionEnabled = () => {
  return (
    featureFlagMap.getAll().enableTraceSession ||
    (audacyPlatformSettings.getPlatformSettings(PlatformSettingsKey.VERBOSE_LOGGING) &&
      audacyPlatformSettings.getPlatformSettings(PlatformSettingsKey.AMPERWAVE_END_TO_END_LOGGING))
  );
};
