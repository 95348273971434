export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Key</title>
    <path
      d="m19.207 22.397.65-.375-.65.375Zm-2.179-3.773.65-.375-.375-.65-.65.375.375.65Zm-1.856 1.071-.375-.65-.324.188-.045.372.744.09Zm-.32 2.66-.295.69.92.392.12-.993-.745-.09Zm-2.463-1.053.294-.69-.345-.147-.324.188.375.65Zm-.928.536-.375-.65-.325.188-.045.372.745.09Zm-.32 2.66-.295.689.92.393.12-.993-.745-.09Zm-2.464-1.053.295-.69-.011-.004-.01-.005-.274.7Zm-3.195-1.248-.741-.113-.09.593.558.218.273-.698ZM6 18.806l-.375-.65-.313.18-.054.357.742.113Zm8.35-4.822.376.65.65-.375-.376-.65-.65.375Zm-1.651-4.86a1.75 1.75 0 0 0-.64 2.39l1.298-.75a.25.25 0 0 1 .092-.342l-.75-1.299Zm9.526-5.5-9.526 5.5.75 1.298 9.526-5.5-.75-1.299Zm2.39.64a1.75 1.75 0 0 0-2.39-.64l.75 1.298a.25.25 0 0 1 .341.092l1.3-.75Zm6.5 11.258-6.5-11.258-1.299.75 6.5 11.258 1.3-.75Zm-.64 2.39a1.75 1.75 0 0 0 .64-2.39l-1.299.75a.25.25 0 0 1-.091.341l.75 1.3Zm-9.526 5.5 9.526-5.5-.75-1.299-9.526 5.5.75 1.3Zm-2.39-.64a1.75 1.75 0 0 0 2.39.64l-.75-1.299a.25.25 0 0 1-.342-.091l-1.299.75Zm-2.18-3.773 2.18 3.773 1.298-.75-2.178-3.773-1.3.75Zm-.83 1.346 1.855-1.072-.75-1.299-1.856 1.072.75 1.299Zm-1.12-.74-.32 2.66 1.489.18.32-2.66-1.49-.18Zm.719 2.06-2.464-1.052-.589 1.379 2.463 1.052.59-1.38Zm-3.311.822.928-.535-.75-1.3-.928.536.75 1.3Zm.05 2.1.32-2.66-1.49-.179-.32 2.66 1.49.179Zm-3.503-.452 2.463 1.052.59-1.38-2.464-1.052-.59 1.38Zm-3.174-1.24 3.195 1.249.546-1.397-3.195-1.249-.546 1.397Zm.048-4.202-.516 3.39 1.483.227.516-3.391-1.483-.226Zm8.718-5.358-8.351 4.821.75 1.3 8.35-4.822-.75-1.3Zm-1.918-1.821 1.643 2.845 1.3-.75-1.644-2.845-1.299.75Z"
      fill="currentColor"
    />
    <circle
      cx="21.524"
      cy="13.554"
      r="2.143"
      transform="rotate(-30 21.524 13.554)"
      fill="currentColor"
    />
  </svg>
);
