/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { TPlayableObject } from '@audacy-clients/client-services/src/audioServices/types';
import { ContentSummary } from '@audacy-clients/client-services/src/dataServices/DataServices';
import { isNumber, isPlainObject, camelCase, mapKeys } from 'lodash';

function retrievePaths(
  object: Record<string, any>,
  path: string | null,
  result: Record<string, any>,
  untilDepth?: number,
): Record<string, any> {
  if (!isPlainObject(object) || !Object.keys(object).length) {
    if (!path) {
      return object;
    }
    result[path] = object;
    return result;
  }
  if (isNumber(untilDepth)) {
    untilDepth--;
  }
  return Object.keys(object).reduce((carry, key) => {
    const pathUntilNow = path ? path + '.' : '';
    const newPath = pathUntilNow + key;
    // last iteration or not
    const extra =
      untilDepth === -1
        ? { [newPath]: object[key] }
        : retrievePaths(object[key], newPath, result, untilDepth);
    return Object.assign(carry, extra);
  }, {});
}

export function flattenObject(
  object: Record<string, any>,
  untilDepth?: number,
): Record<string, any> {
  const result = {};
  return retrievePaths(object, null, result, untilDepth);
}

export function camelCaseKeys(object: Record<string, any>): Record<string, any> {
  return mapKeys(object, (_v, k) => camelCase(k));
}

export const reorderPlayableObjectsById = (
  ids: string[],
  objects: TPlayableObject[],
): TPlayableObject[] => {
  const idMap = new Map<string, TPlayableObject>();
  objects.forEach((obj) => idMap.set(obj.getId(), obj));
  return ids.map((id) => idMap.get(id)!).filter((i) => !!i);
};

export const reorderContentSummariesById = (
  ids: string[],
  objects: ContentSummary[],
): ContentSummary[] => {
  const idMap = new Map<string, ContentSummary>();
  objects.forEach((obj) => idMap.set(obj.id, obj));
  return ids.map((id) => idMap.get(id)!).filter((i) => !!i);
};
