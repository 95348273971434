import { BP, Colors, mqMax } from '~/styles';
import { type TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: { display: 'flex', justifyContent: 'center' },
  drawerContainer: {
    backgroundColor: Colors.secondaryDeepBlue,
    borderRadius: '15px 15px 0 0',
    width: '100vw',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  headerContainerStyle: {
    marginBottom: 0,
    padding: '28px 30px',
  },
  headerSeperator: {
    height: 1,
    backgroundColor: Colors.white07,
    position: 'absolute',
    bottom: 0,
    left: '50%',
    right: '50%',
    marginLeft: '-50vw',
    marginRight: '-50vw',
    maxWidth: '100vw',
    width: '100vw',
  },
  contentContainer: {
    overflow: 'hidden',
    height: '100%',
  },

  transcriptPlayer: {
    backgroundColor: Colors.secondaryDeepBlue,
    padding: '28px 42px 42px',
    [mqMax(BP.Medium)]: {
      padding: '40px 20px 20px',
    },
  },
  transcriptChapterControlsContainer: {
    marginBottom: '6px',
  },
};

export default styles;
