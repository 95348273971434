import { Borders, BP, Colors, mq, Typography, ZIndex, Mixins } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'inherit',
  },
  editCta: {
    [mq(BP.Small)]: {
      position: 'fixed',
      bottom: 20,
      left: 20,
      right: 20,
      zIndex: ZIndex.editCta,
    },
    [mq(BP.Medium)]: {
      bottom: 0,
      position: 'relative',
      left: 0,
      right: 0,
      zIndex: ZIndex.editCta,
    },
  },
  editCtaPlayerVisible: {
    [mq(BP.Small)]: {
      position: 'fixed',
      bottom: 80,
      left: 20,
      right: 20,
      zIndex: ZIndex.editCta,
    },
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [mq(BP.Large)]: {
      paddingBottom: 20,
      borderBottom: Borders.separator,
    },
  },
  headerCtas: {
    display: 'flex',
    '> button': {
      marginLeft: 10,
    },
  },
  title: {
    position: 'relative',
    ...Typography.titleSection,
  },
  titleGradient: {
    position: 'relative',
    whiteSpace: 'nowrap',
    ...Typography.titleSection,
    ...Mixins.overflowGradient('to right'),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 28,
  },
  metadata: {
    ...Typography.metadataSmall,
    color: Colors.white60,
  },
  editSelectors: {
    display: 'flex',
  },
  editSelector: {
    marginRight: 20,
    marginLeft: 3, // add 3px buffer to show full focus outline
  },
  editSelectorsElement: {
    height: 16, // maintain constant height during animation transition
  },
  contentWithMediumPadding: {
    height: '100%',
    padding: '20px 0',
    paddingRight: 10, // 10px buffer to scrollbar to prevent cutoff
    overflowX: 'hidden',
  },
  emptyMessage: {
    ...Typography.metadataLarge,
    minHeight: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listWithToolbar: {
    marginTop: 15,
  },
  listWithoutToolbar: {
    marginTop: 20,
  },
};

export default styles;
