import { RefObject, useEffect, useRef, useCallback } from 'react';

export const usePreventFocusOnVisibilityChange = (
  initialValue = false,
): [RefObject<boolean>, (value: boolean) => void] => {
  const shouldPreventFocusRef = useRef<boolean>(initialValue);

  const handleVisibilityChange = useCallback(() => {
    shouldPreventFocusRef.current = true;
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  const setShouldPreventFocus = (value: boolean): void => {
    shouldPreventFocusRef.current = value;
  };

  return [shouldPreventFocusRef, setShouldPreventFocus];
};
