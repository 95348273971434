import React from 'react';

import Icon from '~/components/Icon';
import { Icons } from '~/components/Icon/constants';
import { Visibility } from '~/styles';
import { TStyles } from '~/types/emotion-styles';

import styles from './styles';

interface ICheckboxProps {
  isChecked: boolean;
  isLabelVisHidden?: boolean;
  label: string;
  name: string;
  onChange(isChecked: boolean): void;
}

interface ICheckmarkProps extends Pick<ICheckboxProps, 'isChecked'> {
  checkmarkCss?: TStyles;
  iconSizeInPx?: number;
}

export const Checkmark = ({
  iconSizeInPx = 10,
  isChecked,
  checkmarkCss = {},
}: ICheckmarkProps): JSX.Element => {
  return (
    <span css={[styles.checkmark, isChecked && styles.checked, checkmarkCss]}>
      <Icon
        ariaHidden
        iconCss={[styles.icon, isChecked ? styles.iconChecked : []]}
        name={Icons.Check}
        sizeInPx={iconSizeInPx}
      />
    </span>
  );
};

const Checkbox = ({
  isChecked,
  isLabelVisHidden = true,
  label,
  name,
  onChange,
}: ICheckboxProps): JSX.Element => {
  return (
    <label>
      <input
        css={styles.input}
        type="checkbox"
        name={name}
        checked={isChecked}
        onChange={() => {
          onChange(!isChecked);
        }}
      />
      <span css={styles.inputContainer}>
        <Checkmark isChecked={isChecked} />
        <span css={[styles.label, isLabelVisHidden && Visibility.visHidden]}>{label}</span>
      </span>
    </label>
  );
};

export default Checkbox;
