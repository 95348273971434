import { useMemo } from 'react';

import { IEpisode } from '@audacy-clients/core/atoms/wrappers/types';
import { useModuleContext } from '@audacy-clients/core/components/ModuleRenderer/context';
import { IPlayContext, IPlayOptions } from '@audacy-clients/core/hooks/useAudioHelpers';
import { formatFromTo } from '@audacy-clients/core/utils/date';
import { getEpisodeTitleAndImage } from '@audacy-clients/core/utils/episode';
import { useTranslation } from 'react-i18next';

import Chapters from '~/components/Chapters';
import HorizontalEntityCard from '~/components/Entity/HorizontalEntityCard';
import { IHorizontalEntityProps, INowPlaying } from '~/components/Entity/types';
import FooterLink from '~/components/Link/FooterLink';
import SectionWrapper from '~/components/SectionWrapper';
import useOptionsMenu from '~/hooks/use-options-menu';
import { Colors } from '~/styles';
import { isPlayable } from '~/utils/card';

import styles from './styles';

interface ILiveNowProps
  extends IHorizontalEntityProps,
    Pick<IHorizontalEntityProps, 'onClick' | 'onOptionsMenuClick'> {
  episode: IEpisode;
  isStation?: boolean;
  liveUntil?: INowPlaying;
  onFooterClick?: () => void;
  scheduleHref?: string;
  isPlaying?: boolean;
  onPlayLiveItem: (options?: IPlayOptions) => void;
  playContext: IPlayContext;
  rewindable?: boolean;
}

const LiveNow = ({
  contentId,
  episode,
  isStation,
  liveUntil,
  scheduleHref,
  onClick,
  onFooterClick,
  onOptionsMenuClick,
  isPlaying,
  onPlayLiveItem,
  playContext,
  isRewindable,
}: ILiveNowProps): JSX.Element => {
  const { t } = useTranslation();
  const { isSideColumn } = useModuleContext();

  const optionsMenu = useOptionsMenu({
    contentId: episode.id,
    entityType: episode.type,
    entitySubtype: episode.subtype,
    image: episode.image,
    isLive: episode.isLive,
    title: episode.title,
    url: episode.url,
  });

  const isPlaceholder = !episode?.showContentId;

  const [title, image] = useMemo(() => {
    if (!episode) {
      return [undefined, undefined]; // we need to return an array with length of 2, so pass undefined here.
    }

    if (isPlaceholder) {
      return [formatFromTo(episode.startTime, episode.endTime, ' - ', false), episode.parentImage];
    }

    return getEpisodeTitleAndImage(episode, isStation);
  }, [episode, isPlaceholder, isStation]);

  return (
    <SectionWrapper
      containerCss={styles.container}
      title={t('entity.liveNow.header')}
      footerContent={
        scheduleHref &&
        isStation && (
          <FooterLink
            href={scheduleHref}
            label={t('entity.liveNow.schedule')}
            linkCss={styles.footerLink}
            onClick={onFooterClick}
          />
        )
      }
    >
      <HorizontalEntityCard
        ctaColor={Colors.audacyOrange}
        imageURIBase={image}
        isLive
        liveUntil={liveUntil}
        onOptionsMenuClick={onOptionsMenuClick}
        titleCss={!isSideColumn ? styles.title : undefined}
        href={episode.url}
        title={title}
        entitySubtype={episode.entitySubtype}
        entityType={episode.entityType}
        contentId={contentId}
        isPlayable={isPlayable(episode.entityType)}
        isPlaying={isPlaying}
        onClick={onClick}
        onPlayLiveItem={onPlayLiveItem}
        parentId={episode.parentStationIds?.[0]}
        playContext={playContext}
        optionsMenu={optionsMenu}
        isRewindable={isRewindable}
      />
      {!isStation && <Chapters episode={episode} live={true} hasSectionWrapper={false} />}
    </SectionWrapper>
  );
};

export default LiveNow;
