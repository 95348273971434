export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Caret</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12Zm0-9.91-4.688-4.687-.909.91L16 19.909l5.597-5.596-.91-.91L16 18.091Z"
      fill="currentColor"
    />
  </svg>
);
