import { BP, mq } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    [mq(BP.Small)]: {
      width: 300,
      height: 250,
    },
    [mq(BP.Large)]: {
      width: 728,
      height: 90,
    },
    margin: '0 auto',
    marginBottom: 60,
  },
};

export default styles;
