export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Calendar</title>
    <path
      stroke="currentColor"
      strokeWidth="1.5"
      d="M6 7h20v20H6zM10.75 4v6M6 13.25h20M21.25 4v6"
    />
  </svg>
);
