// TODO: [A2-4801] consider moving this to core if web and mobile menu types are aligned.
export enum OptionsMenuTypes {
  AudioItem,
  Location,
  MyAudio,
  MyAudioExclusiveStation,
  MyAudioPodcast,
  MyAudioQueue,
  MyAudioRadioShow,
  MyAudioRadioStation,
  MyAudioTopic,
  PodcastEntity,
  PodcastEpisode,
  RadioShowClip,
  RadioShowEntity,
  RadioShowEpisode,
  RadioShowSegment,
  RadioStationEntity,
  RecentExclusiveStation,
  RecentPodcast,
  RecentRadioShow,
  RecentRadioStation,
  SearchPodcastEntity,
  FullPlayerExclusiveStation,
  FullPlayerStation,
  FullPlayerBroadcastEpisode,
  FullPlayerPodcastEpisode,
  TopicEntity,
  ContinueListeningPodcastEpisode,
  ContinueListeningBroadcastEpisode,
  SearchPodcastEpisode,
  SearchStation,
  BroadcastEpisode,
  Playlist,
  StandaloneChapter,
}
