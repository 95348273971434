import { type CSSProperties, type ReactNode, useEffect, useRef } from 'react';
import { type TStyles } from '~/types/emotion-styles';

type ScrollContainerProps = {
  /** Callback for user-initiated scroll events */
  onUserScroll: () => void;
  /** React children elements to render inside the container */
  children: ReactNode;
  /** Optional custom styling for the container */
  style?: TStyles | CSSProperties;
  /** Optional ref callback for the container */
  handleContainerRef?: (ref: HTMLDivElement | null) => void;
};

/**
 * Container that detects user-initiated scrolls and calls a user-defined callback when they occur.
 * This is useful for implementing features like pausing auto-scrolling when the user manually scrolls the container
 */
export const ScrollableContainer: React.FC<ScrollContainerProps> = ({
  onUserScroll,
  children,
  style,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null); // Ref to the scroll container
  const timeoutRef = useRef<number | null>(null); // Ref for the debounce timeout
  const isUserScrollingRef = useRef<boolean>(false); // Tracks if the scroll is user-initiated
  const isScrollBarTouchedRef = useRef(false);

  // Detect user-initiated scroll events (e.g., mouse wheel or touch)
  const handleUserScroll = () => {
    isUserScrollingRef.current = true; // Mark the scroll as user-initiated
  };

  const handleMouseDown = (event: MouseEvent) => {
    if (event.target !== scrollContainerRef.current) {
      return;
    }
    isScrollBarTouchedRef.current = true;
  };

  const handleMouseUp = (event: MouseEvent) => {
    if (event.target !== scrollContainerRef.current) {
      return;
    }
    isScrollBarTouchedRef.current = false;
  };

  // Handle the onScroll event
  const handleScroll = () => {
    if (isUserScrollingRef.current || isScrollBarTouchedRef.current) {
      onUserScroll(); // Call the user-defined callback
    }

    // Reset the scrolling state after debounce
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => {
      isUserScrollingRef.current = false; // Reset the user scroll flag
    }, 100); // Adjust debounce as needed
  };

  useEffect(() => {
    const container = scrollContainerRef.current;

    // Add event listeners to detect user scrolls
    if (container) {
      container.addEventListener('wheel', handleUserScroll);
      container.addEventListener('touchmove', handleUserScroll);
      container.addEventListener('mousedown', handleMouseDown);
      container.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      // Clean up listeners and timeout on unmount
      if (container) {
        container.removeEventListener('wheel', handleUserScroll);
        container.removeEventListener('touchmove', handleUserScroll);
        container.removeEventListener('mousedown', handleMouseDown);
        container.removeEventListener('mouseup', handleMouseUp);
      }
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={scrollContainerRef}
      onScroll={handleScroll}
      css={{
        overflowY: 'auto',
        height: '100%',
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default ScrollableContainer;
