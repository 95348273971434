import { ReactNode } from 'react';

import {
  EpisodeSubType,
  ShowSubtype,
  StationSubType,
  TagSubtype,
} from '@audacy-clients/client-services';
import { useTranslation } from 'react-i18next';

import { Icons } from '~/components/Icon/constants';
import OptionsMenuItem from '~/components/OptionsMenu/components/OptionsMenuItem';
import { IOptionsMenu, IOptionsMenuItemAnchorProps } from '~/components/OptionsMenu/types';
import useToast from '~/state/toast';
import { LinkType } from '~/types/links';

export type TShareMenuItemType = 'episode' | 'podcast' | 'station' | 'topic' | 'show' | 'genre';

const ShareMenuItem = (
  props: { linkType: TShareMenuItemType } & Partial<IOptionsMenuItemAnchorProps>,
): JSX.Element => {
  const { t } = useTranslation();
  const { href, isExternal, linkType } = props;

  const LinkText = {
    station: t('optionsMenu.shareStation'),
    show: t('optionsMenu.shareShow'),
    episode: t('optionsMenu.shareEpisode'),
    podcast: t('optionsMenu.sharePodcast'),
    topic: t('optionsMenu.shareTopic'),
    genre: t('optionsMenu.shareGenre'),
  };

  return (
    <OptionsMenuItem
      as={LinkType.Anchor}
      isDisabled={!href}
      isExternal={isExternal}
      href={href}
      icon={Icons.CopyLink}
      onClick={props.onClick}
      text={LinkText[linkType]}
    />
  );
};

export const useShareSubtype = (
  optionsMenu: IOptionsMenu,
  onClickCallback?: () => void,
): ReactNode => {
  const { t } = useTranslation();
  const { showToast } = useToast();

  async function copyTextToClipboard(text?: string) {
    if ('clipboard' in navigator && text) {
      return await navigator.clipboard.writeText(text);
    }
  }
  const shareMenuItem = (linkType: TShareMenuItemType) => (
    <ShareMenuItem
      href={optionsMenu.data.url}
      isExternal={!!optionsMenu.data.url}
      linkType={linkType}
      onClick={onClick}
    />
  );

  const onClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClickCallback?.();
    copyTextToClipboard(`${window.location.origin}${optionsMenu.data?.url}`);
    showToast({
      title: t('toast.linkCopied'),
    });
  };

  switch (optionsMenu.subtype) {
    case StationSubType.BROADCAST:
    case StationSubType.INTERNET:
    case StationSubType.PERSONALIZED:
    case StationSubType.EXCLUSIVE:
      return shareMenuItem('station');
    case ShowSubtype.DIGITAL:
    case ShowSubtype.BROADCAST:
      return shareMenuItem('show');
    case StationSubType.PODCAST:
    case EpisodeSubType.BROADCAST_SHOW_EPISODE:
    case EpisodeSubType.PODCAST_EPISODE:
      return shareMenuItem('episode');
    case ShowSubtype.PODCAST:
      return shareMenuItem('podcast');
    case TagSubtype.GENRE:
      return shareMenuItem('genre');
    case TagSubtype.TEAM:
    case StationSubType.TOPIC:
      return shareMenuItem('topic');
    default:
      break;
  }
};
