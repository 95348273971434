import { ClickFeature, type EntitySubtype, EpisodeSubType } from '@audacy-clients/client-services';
import { checkPlayingById, isAdState, nowPlaying } from '@audacy-clients/core/atoms/player';
import { type TTranscript } from '@audacy-clients/core/atoms/transcripts';
import { isDefined } from '@audacy-clients/core/components/StateConnectors/utils';
import useTranscript, {
  getChapterTranscriptItemOffset,
} from '@audacy-clients/core/hooks/useTranscript';
import useTranscriptDataEvents from '@audacy-clients/core/hooks/useTranscriptDataEvents';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { scrollableClassState } from '~/atoms/scrollClass';
import IconButton from '~/components/Button/IconButton';
import Error from '~/components/Error';
import { Icons } from '~/components/Icon/constants';
import Loading from '~/components/Loading';
import ScrollableContainer from '~/components/ScrollableContainer';
import { fadeInOut } from '~/constants/animation';
import { useAudioHelpersWeb } from '~/hooks/use-audio-helpers-web';
import useBodyScrollLock, { allowTouchMoveBasedOnClass } from '~/hooks/use-body-scroll-lock';
import { useGetViewContextFn } from '~/state/dataEvents';
import { Colors, Timing } from '~/styles';
import ItemCell from './ItemCell';
import styles, { Constants } from './styles';
import TranscriptAdBlock from './TranscriptAdBlock';
import TranscriptHeader from './TranscriptHeader';

type TranscriptContentProps = {
  transcript: Array<TTranscript> | undefined;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  duration: number;
  entitySubtype?: EntitySubtype;
  startOffset?: number;
  startTime?: number;
  isNextTranscriptAvailable: boolean;
  title?: string;
};

const Transcript: React.FC<TranscriptContentProps> = ({
  transcript,
  currentIndex,
  setCurrentIndex,
  duration,
  entitySubtype,
  startOffset = 0,
  startTime = 0,
  isNextTranscriptAvailable,
  title,
}) => {
  const audioHelpers = useAudioHelpersWeb();
  const playerItem = useRecoilValue(nowPlaying);
  const { id: contentId } = playerItem ?? {};
  const isPlaying = useRecoilValue(checkPlayingById({ contentId }));
  const isAd = useRecoilValue(isAdState);
  const getViewContext = useGetViewContextFn();
  const { sendTranscriptPlayClickEvent, getAnalyticPlayContext } =
    useTranscriptDataEvents(getViewContext);
  const { t } = useTranslation();

  const [isAutoScrolling, setIsAutoScrolling] = useState(true);

  const activeRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const rotation = useMotionValue(0);
  const rotateStyle = useTransform(rotation, [0, 180], [0, 180]);

  // Enables draggable container
  const scrollClass = 'transcriptScrollableContent';
  const allowTouchMove = allowTouchMoveBasedOnClass(scrollClass);
  useBodyScrollLock(true, null, allowTouchMove);
  const setScrollableClass = useSetRecoilState(scrollableClassState);
  useEffect(() => {
    setScrollableClass(scrollClass);
  }, [setScrollableClass]);

  const programmaticScrollToTop = useCallback(() => {
    if (!activeRef.current) {
      return;
    }

    const parentNode = activeRef.current?.parentNode as HTMLDivElement | null;
    if (parentNode) {
      parentNode.scrollTo({
        top: activeRef.current.offsetTop - parentNode.offsetTop,
        behavior: 'smooth',
      });
    }
  }, []);

  const playContext = useMemo(
    () => ({
      contentId,
      feature: ClickFeature.TRANSCRIPTION,
    }),
    [contentId],
  );

  const handleTranscriptPress = useCallback(
    (startTimeInMs: number, index: number) => () => {
      setIsAutoScrolling(true);
      setCurrentIndex(index);
      const offset = startTimeInMs / 1000;
      let progress = isDefined(duration) && isDefined(offset) ? offset / duration : undefined;
      if (progress && progress > 1) {
        progress = 1;
      }
      sendTranscriptPlayClickEvent(getAnalyticPlayContext(playContext, transcript?.[index]));
      if (entitySubtype === EpisodeSubType.BROADCAST_SHOW_EPISODE) {
        return audioHelpers.seekToTime(
          getChapterTranscriptItemOffset(startOffset, startTimeInMs / 1000, startTime / 1000),
        );
      }
      if (isPlaying) {
        return audioHelpers.seekToProgress(progress ?? 0);
      }

      audioHelpers.seekToProgress(progress ?? 0);
      audioHelpers.play(undefined, playContext);
    },
    [
      duration,
      audioHelpers,
      isPlaying,
      setCurrentIndex,
      getAnalyticPlayContext,
      sendTranscriptPlayClickEvent,
      transcript,
      playContext,
      entitySubtype,
      startOffset,
      startTime,
    ],
  );

  const handleResumeButtonDirection = useCallback(() => {
    setIsAutoScrolling(false);

    if (!activeRef?.current || !buttonRef.current) {
      return;
    }

    const activeRect = activeRef.current.getBoundingClientRect();
    const buttonRect = buttonRef.current.getBoundingClientRect();
    const isAbove = activeRect.bottom >= buttonRect.bottom;

    // Update rotation based on isAbove
    if (isAbove) {
      rotation.set(180);
    } else {
      rotation.set(0);
    }
  }, [rotation]);

  // Resume auto-scrolling when the user clicks the resume button
  const handleResumeAutoScroll = useCallback(() => {
    programmaticScrollToTop();
    setIsAutoScrolling(true);
  }, [programmaticScrollToTop]);

  const handleTranscriptRef = useCallback(
    (index: number) => (ref: HTMLDivElement | null) => {
      if (currentIndex === index) {
        activeRef.current = ref;
      }
    },
    [currentIndex],
  );

  const renderHeader = useCallback(
    () => (
      <>
        {entitySubtype === EpisodeSubType.BROADCAST_SHOW_EPISODE && title && (
          <TranscriptHeader title={title} startOffset={startOffset} />
        )}
      </>
    ),
    [title, startOffset, entitySubtype],
  );

  const renderItem = useCallback(
    (transcript: TTranscript, index: number) => {
      const { start_time, speaker, text } = transcript;
      const isActiveItem = index <= currentIndex && currentIndex >= Constants.noIndexFounded;
      return (
        <ItemCell
          index={index}
          startTime={start_time}
          isActive={isActiveItem}
          speaker={speaker}
          text={text}
          handleTranscriptPress={handleTranscriptPress}
          handleTranscriptRef={handleTranscriptRef}
          startOffset={startOffset}
          entitySubtype={entitySubtype}
          renderHeader={index === 0 ? renderHeader : undefined}
        />
      );
    },
    [
      handleTranscriptPress,
      handleTranscriptRef,
      currentIndex,
      entitySubtype,
      startOffset,
      renderHeader,
    ],
  );

  // Automatically scroll to the current index when necessary
  useEffect(() => {
    if (currentIndex < 0 || !isAutoScrolling) {
      return;
    }
    programmaticScrollToTop();
  }, [currentIndex, isAutoScrolling, programmaticScrollToTop]);

  if (isAd) {
    return <TranscriptAdBlock />;
  }

  return (
    <ScrollableContainer
      onUserScroll={handleResumeButtonDirection}
      style={styles.transcriptContentContainer}
    >
      {transcript?.map((item, index) => (
        <Fragment key={`${item.start_time}-${index}`}>{renderItem(item, index)}</Fragment>
      ))}
      {!isNextTranscriptAvailable && (
        <div css={styles.listFooterComponent}>
          <p css={styles.listFooterText}>{t('transcript.processing')}</p>
        </div>
      )}
      {!isAutoScrolling && (
        <motion.div style={{ rotate: rotateStyle }} css={styles.resumeButtonContainer}>
          <IconButton
            ref={buttonRef}
            icon={Icons.CaretUp}
            onClick={handleResumeAutoScroll}
            buttonCss={styles.resumeButton}
            color={Colors.black}
            buttonSizeInPx={26}
            iconSizeInPx={12}
          />
        </motion.div>
      )}
    </ScrollableContainer>
  );
};

const MemoizedTranscriptContent = memo(Transcript);

const TranscriptContent = () => {
  const playerItem = useRecoilValue(nowPlaying);
  const { episodeDataObject, standaloneChapterDataObject } = playerItem ?? {};
  const entityDataObject = episodeDataObject ?? standaloneChapterDataObject;
  const entitySubtype = entityDataObject?.subtype;
  const {
    transcript,
    currentIndex,
    setCurrentIndex,
    duration,
    loading,
    hasError,
    startOffset,
    isNextTranscriptAvailable,
    title,
  } = useTranscript(entityDataObject);
  const getViewContext = useGetViewContextFn();
  const { sendTranscriptScreenViewEvent } = useTranscriptDataEvents(getViewContext);

  useEffect(() => {
    sendTranscriptScreenViewEvent();
  }, [sendTranscriptScreenViewEvent]);

  if (loading) {
    return (
      <motion.div
        {...fadeInOut({
          durationIn: Timing.pageTransitionIn,
          durationOut: 1000,
        })}
        css={styles.loadContainer}
      >
        {loading && <Loading />}
      </motion.div>
    );
  }
  if (hasError) {
    return <div css={styles.loadContainer}>{hasError && <Error />}</div>;
  }

  return (
    <MemoizedTranscriptContent
      transcript={transcript}
      currentIndex={currentIndex}
      setCurrentIndex={setCurrentIndex}
      duration={duration}
      entitySubtype={entitySubtype}
      startOffset={startOffset}
      startTime={episodeDataObject?.startTime}
      isNextTranscriptAvailable={isNextTranscriptAvailable}
      title={title}
    />
  );
};

export default TranscriptContent;
