import { type TProfilePatch } from '@audacy-clients/client-services/src/personalizationServices/types';
import { noCacheDefaultSelector } from '@audacy-clients/core/atoms/helpers/noCacheDefaultSelector';
import clientServices, { useClientServices } from '@audacy-clients/core/utils/clientServices';
import { ellipsizeText } from '@audacy-clients/core/utils/strings';
import { useRecoilRefresher_UNSTABLE } from 'recoil';

import { isLoggedIn } from './auth';

export const getIncompleteProfileData = noCacheDefaultSelector({
  get: async ({ get }) => {
    const loggedIn = get(isLoggedIn);
    if (!loggedIn) {
      return undefined;
    }

    try {
      const incompleteProfileData = await clientServices
        .getPersonalizationServices()
        .getIncompleteProfileData();

      return incompleteProfileData;
    } catch (_e) {
      // empty
    }
  },
  key: 'IncompleteProfileData',
});

export const getProfileData = noCacheDefaultSelector({
  get: async () => await clientServices.getPersonalizationServices().getProfileData(),
  key: 'ProfileData',
});

export const getProfileNameOrEmail = noCacheDefaultSelector({
  get: ({ get }) => {
    const profile = get(getProfileData);
    const { firstName, emailAddress } = profile;

    if (firstName) {
      return { text: `${firstName.trim()}`, isEmail: false };
    }

    return { text: ellipsizeText(emailAddress, 16), isEmail: true };
  },
  key: 'ProfileNameOrEmail',
});

export const getProfileFullNameOrEmail = noCacheDefaultSelector({
  get: ({ get }) => {
    const profile = get(getProfileData);
    const { firstName, lastName, emailAddress } = profile;

    if (firstName && lastName) {
      return { text: `${firstName.trim()} ${lastName.trim()}`, isEmail: false };
    }

    return { text: ellipsizeText(emailAddress, 16), isEmail: true };
  },
  key: 'ProfileFullName',
});

interface TSetProfileProps {
  profile: TProfilePatch;
  shouldRefreshProfileData?: boolean;
  shouldRefreshIncompleteProfileData?: boolean;
  callbacks?: {
    onSuccess?: () => void;
    onError?: () => void;
  };
}

type TSetProfileReturn = ({
  profile,
  shouldRefreshProfileData,
  shouldRefreshIncompleteProfileData,
  callbacks,
}: TSetProfileProps) => Promise<void>;

export const useSetProfileData = (): TSetProfileReturn => {
  const { clientServices: cs } = useClientServices();
  const refreshProfileData = useRecoilRefresher_UNSTABLE(getProfileData);
  const refreshIncompleteProfileData = useRecoilRefresher_UNSTABLE(getIncompleteProfileData);

  return async ({
    profile,
    shouldRefreshProfileData,
    shouldRefreshIncompleteProfileData,
    callbacks,
  }): Promise<void> => {
    await cs
      .getPersonalizationServices()
      .setProfileData(profile)
      .then(() => {
        shouldRefreshProfileData && refreshProfileData();
        shouldRefreshIncompleteProfileData && refreshIncompleteProfileData();
        callbacks?.onSuccess?.();
      })
      .catch(() => {
        callbacks?.onError?.();
      });
  };
};
