import { IModuleContextListData } from '@audacy-clients/core/components/ModuleRenderer/types';

import List from '.';

export interface IListViewProps extends Partial<IModuleContextListData> {
  ariaLabel?: string;
  isListModule?: boolean;
  children: JSX.Element[];
}

const ListView = ({ ariaLabel, children }: IListViewProps): JSX.Element => {
  const renderItem = ({ item }: { item: JSX.Element }): JSX.Element => item;

  return <List ariaLabel={ariaLabel} items={children} renderItem={renderItem} />;
};

export default ListView;
