export default (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Pause</title>
    <rect width="20" height="20" rx="10" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.25 5.875H6.625V14.125H9.25V5.875ZM13.375 5.875H10.75V14.125H13.375V5.875Z"
      fill="white"
    />
  </svg>
);
