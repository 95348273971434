import playerState, { playerTimeState } from '@audacy-clients/core/atoms/player';
import PlayerStateConnector from '@audacy-clients/core/components/StateConnectors/PlayerStateConnector';
import { useRecentlyPlayedPlaylist } from '@audacy-clients/core/hooks/useRecentlyPlayedPlaylist';
import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useResetRecoilState } from 'recoil';

import useToast from '~/state/toast';

import useMusicControl from './musicControl';

const WebPlayerStateConnector = (): JSX.Element => {
  const { t } = useTranslation();
  useMusicControl();
  useRecentlyPlayedPlaylist();
  const { showToast } = useToast();

  const resetPlayerState = useResetRecoilState(playerState);
  const resetPlayerTimeState = useResetRecoilState(playerTimeState);

  const resetPlayerStateAndShowToast = useCallback(() => {
    resetPlayerState();
    resetPlayerTimeState();
    showToast({
      title: t('playerControls.toast.errorPlaying'),
    });
  }, [resetPlayerState, resetPlayerTimeState, showToast, t]);

  const onError = useCallback(
    (): void => resetPlayerStateAndShowToast(),
    [resetPlayerStateAndShowToast],
  );

  // TODO: [CCS-2783] combine with onError and have Audio Services send the proper error message
  const onExpiredContentError = useCallback(() => {
    showToast({
      title: t('global.contentUnavailable'),
    });
  }, [t, showToast]);

  return (
    <PlayerStateConnector onPlaybackError={onError} onExpiredContentError={onExpiredContentError} />
  );
};

export default WebPlayerStateConnector;
