import { Path } from '~/routes';
import useAuth from '~/state/auth';

import Navigation, { INavigationProps } from './Navigation';

type TNavigationWithStateProps = Omit<INavigationProps, 'logIn' | 'isLoggedIn'>;

const NavigationWithState = (props: TNavigationWithStateProps): JSX.Element => {
  const { isLoggedIn, logIn } = useAuth();

  return (
    <Navigation
      isLoggedIn={isLoggedIn}
      isHome={location.pathname === Path.Home}
      logIn={logIn}
      {...props}
    />
  );
};

export default NavigationWithState;
