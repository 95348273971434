export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>More</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16a2 2 0 1 0-4 0 2 2 0 0 0 4 0Zm10.4 0a2 2 0 1 0-4 0 2 2 0 0 0 4 0Zm8.4-2a2 2 0 1 1 0 4 2 2 0 0 1 0-4Z"
      fill="currentColor"
    />
  </svg>
);
