import { Mixins } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  img: {
    ...Mixins.transition('opacity'),
    opacity: '0',
  },
  loaded: {
    opacity: '1',
  },
};

export default styles;
