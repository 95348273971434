import { isAdState, nowPlaying } from '@audacy-clients/core/atoms/player';
import { transcriptHasOpenedState } from '@audacy-clients/core/atoms/transcripts';
import React, { Suspense, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import DrawerContainer from '~/components/Drawer';
import DrawerHeader from '~/components/Drawer/components/DrawerHeader';
import BottomOptions from '~/components/Player/FullscreenPlayer/Components/FullPlayer/BottomOptions';
import PlayerControls from '~/components/Player/PlayerControls';
import Scrubber from '~/components/Player/Scrubber';
import Portal from '~/components/Portal';
import TranscriptButton from '~/components/Transcript/TranscriptButton';
import TranscriptContent from '~/components/Transcript/TranscriptContent';

import { useBreakpoints } from '~/hooks/use-breakpoints';
import { Colors } from '~/styles';
import TranscriptChapterControls from '../TranscriptChapterControls';
import TranscriptOverlay from '../TranscriptOverlay';
import styles from './styles';

export type TranscriptBottomSheetProps = {
  isQueueVisible: boolean;
  onQueueClick: () => void;
  hasChapter?: boolean;
};

/**
 * Container for Transcript inside the full player in mobile-web view (< Breakpoint.MD)
 * This initially displays a button to open the Transcript
 * On open, a drawer opens and displays the TranscriptContent and player controls
 */
const TranscriptBottomSheet: React.FC<TranscriptBottomSheetProps> = ({
  isQueueVisible,
  onQueueClick,
  hasChapter = false,
}) => {
  const [transcriptVisible, setTranscriptVisible] = useState(false);
  const { lessThan } = useBreakpoints();
  const item = useRecoilValue(nowPlaying);
  const { t } = useTranslation();
  const isAd = useRecoilValue(isAdState);
  const setHasOpened = useSetRecoilState(transcriptHasOpenedState);

  const openTranscript = () => {
    setTranscriptVisible(true);
    setHasOpened(true);
  };

  const closeTranscript = () => {
    setTranscriptVisible(false);
    setHasOpened(false);
  };

  return (
    <div css={styles.container}>
      {lessThan.LG && (
        <TranscriptButton text={t('transcript.openTranscript')} onClick={openTranscript} />
      )}
      <Portal>
        <DrawerContainer
          fullHeight
          contentContainerStyle={styles.drawerContainer}
          onClose={closeTranscript}
          isOpen={transcriptVisible}
          renderDrawerHeader={(onClose) => (
            <DrawerHeader
              headerContainerStyle={styles.headerContainerStyle}
              image={{
                imageURIBase: item?.image?.src,
                alt: item?.image?.srcFallback,
              }}
              onClose={onClose}
              id={item?.id ?? ''}
              title={item?.title}
              titleStyle={{ color: Colors.white }}
              subtitle={item?.dataObject?.parentTitle}
              subtitleStyle={{ color: Colors.white60 }}
              seperator={<div css={styles.headerSeperator} />}
            />
          )}
        >
          <ErrorBoundary FallbackComponent={() => <></>}>
            <Suspense fallback={<></>}>
              <TranscriptOverlay
                displayBottomOverlay={!isAd}
                containerStyle={styles.contentContainer}
              >
                <TranscriptContent />
              </TranscriptOverlay>
              <div css={styles.transcriptPlayer}>
                {hasChapter && (
                  <div css={styles.transcriptChapterControlsContainer}>
                    <TranscriptChapterControls />
                  </div>
                )}
                <Scrubber shouldDisplayTime isFullPlayer hideBackToLiveButton={hasChapter} />
                <PlayerControls />
                <BottomOptions isQueueVisible={isQueueVisible} onQueueClick={onQueueClick} />
              </div>
            </Suspense>
          </ErrorBoundary>
        </DrawerContainer>
      </Portal>
    </div>
  );
};

export default TranscriptBottomSheet;
