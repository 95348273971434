import { noCacheDefaultSelector } from '@audacy-clients/core/atoms/helpers/noCacheDefaultSelector';
import { atom, readOnlySelector } from 'recoil';

import { deepEqualAtomWrapper } from './helpers/deepEqualSelector';

export interface IMarket {
  marketTitle?: string;
  marketId?: string;
}
export interface IMarketsState {
  markets: IMarket[];
  isLoading?: boolean;
}

export const locationMarketsState = deepEqualAtomWrapper(
  atom<IMarketsState>({
    default: {
      markets: [],
    },
    key: 'LocationMarketsState',
  }),
);

export const locationMarketsSelector = readOnlySelector(locationMarketsState);

export const locationPrimaryMarketSelector = noCacheDefaultSelector({
  get: ({ get }) => {
    const { markets } = get(locationMarketsSelector);
    // Written this way so Typescript knows return value can be undefined
    return markets.length ? markets[0] : undefined;
  },
  key: 'LocationPrimaryMarket',
});

export const locationPermissionStatus = {
  denied: 'DENIED',
  grantedBackground: 'GRANTED_BACKGROUND',
  grantedForeground: 'GRANTED_FOREGROUND',
  notDetermined: 'NOT_DETERMINED',
} as const;
export type TLocationPermissionStatus =
  typeof locationPermissionStatus[keyof typeof locationPermissionStatus];

export const locationStatusState = atom<TLocationPermissionStatus>({
  default: locationPermissionStatus.notDetermined,
  key: 'LocationStatusState',
});
