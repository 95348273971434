import { IEditModeHook } from '@audacy-clients/core/atoms/editMode';
import { EntityImageShape } from '@audacy-clients/core/atoms/wrappers/modules';
import { AnimatePresence } from 'framer-motion';

import CardListElement, { ICardListItem } from '~/components/CardListView/CardListElement';
import List from '~/components/List';
import SortableList from '~/components/SortableList';

interface ICardListProps<T> extends Pick<IEditModeHook, 'isEditMode' | 'selectedItems'> {
  entityImageShape?: EntityImageShape;
  isPlayerQueue?: boolean;
  isSortable?: boolean;
  items: T[];
  itemType: string;
  nowPlayingItemId: string | undefined;
  shouldShowEntityType?: boolean;
  onItemClick?(episodeId: string): void;
  onItemHrefClick?: (id: string) => void;
  onSortEnd?: (items: T[]) => void;
  onToggleSelected(episodeId: string): void;
  renderEntityRightColumn?: (contentId: string) => JSX.Element;
}

const CardList = <T extends ICardListItem>({
  entityImageShape,
  isEditMode,
  isPlayerQueue,
  isSortable,
  items,
  itemType,
  selectedItems,
  shouldShowEntityType,
  onItemClick,
  onItemHrefClick,
  onSortEnd,
  onToggleSelected,
  renderEntityRightColumn,
}: ICardListProps<T>): JSX.Element => {
  const renderItem = ({ item }: { item: typeof items[0] }) => (
    <CardListElement
      entityImageShape={entityImageShape}
      contentId={item.id}
      entityType={item.entityType}
      entitySubtype={item.entitySubtype}
      isEditMode={isEditMode}
      isPlayerQueue={isPlayerQueue}
      isSelected={selectedItems.includes(item.id)}
      item={item}
      shouldShowEntityType={shouldShowEntityType}
      onItemClick={onItemClick ? () => onItemClick(item.id) : undefined}
      onToggleSelected={() => onToggleSelected(item.id)}
      onItemHrefClick={onItemHrefClick}
      renderEntityRightColumn={renderEntityRightColumn}
      itemType={itemType}
    />
  );

  return (
    <AnimatePresence initial={false}>
      {isSortable ? (
        <SortableList
          onSortEnd={onSortEnd}
          items={items}
          listId="queue"
          renderItem={renderItem}
          isEditMode={isEditMode}
        />
      ) : (
        <List items={items} renderItem={renderItem} />
      )}
    </AnimatePresence>
  );
};

export default CardList;
