import { useEffect } from 'react';

import { featureFlagOverridesState } from '@audacy-clients/core/atoms/config/settings';
import { useSetRecoilState } from 'recoil';

const DevFeatureFlagsStateConnector = (): JSX.Element => {
  const setOverride = useSetRecoilState(featureFlagOverridesState);
  useEffect(() => {
    const featureFlagOverrides = localStorage.getItem('featureFlags');
    featureFlagOverrides && setOverride(JSON.parse(featureFlagOverrides));
  }, [setOverride]);
  
  return <></>;
};

export default DevFeatureFlagsStateConnector;
