import { useCallback } from 'react';

import { IToastHook } from '@audacy-clients/core/types/toast';
import clientServices from '@audacy-clients/core/utils/clientServices';
import { useSetRecoilState } from 'recoil';

import { contentQuery } from './content';
import { noCacheDefaultSelectorFamily } from './helpers/noCacheDefaultSelector';
import { playbackResumePointsState } from './playbackResumePoints';
import { wrapStandaloneChapter } from './wrappers/standaloneChapter';
import { IStandaloneChapter } from './wrappers/types';

type MarkAsPlayedCallback = (chapter: IStandaloneChapter) => void;

export const standaloneChapterQuery = noCacheDefaultSelectorFamily({
  get:
    (standaloneChapterId: string) =>
    ({ get }) => {
      const standaloneChapter = get(
        contentQuery<IStandaloneChapter>(standaloneChapterId),
      )?.getDataObject();
      if (!standaloneChapter) {
        return;
      }
      return wrapStandaloneChapter(standaloneChapter);
    },
  key: 'StandaloneChapter',
});

export const useMarkStandaloneChapterAsPlayed = (
  useToast: () => IToastHook,
): MarkAsPlayedCallback => {
  const setPlaybackResumePoints = useSetRecoilState(playbackResumePointsState);
  const { showToast } = useToast();

  return useCallback<MarkAsPlayedCallback>(
    (chapter) => {
      // TODO: [A2-6219] update this once is done, as we'll have a new setPlayed function
      const durationToUse = chapter.duration;

      if (chapter.id && durationToUse) {
        setPlaybackResumePoints((prevResumePoints) => {
          return {
            ...prevResumePoints,
            [chapter.id]: durationToUse,
          };
        });

        clientServices
          .getPersonalizationServices()
          .setPlayback(chapter.id, durationToUse)
          .catch(() => {
            setPlaybackResumePoints((prevResumePoints) => {
              delete prevResumePoints[chapter.id];
              return { ...prevResumePoints };
            });
            showToast({ title: 'Error marking chapter as played' });
          });
      }
    },
    [setPlaybackResumePoints, showToast],
  );
};
