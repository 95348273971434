import type AnalyticServices from './analyticServices';
import type AudioServices from './audioServices';
import type ChatServices from './chatServices/ChatServices';
import type DataServices from './dataServices/DataServices';
import { type IddLogger, type ILogger } from './logger';
// eslint-disable-next-line import/no-duplicates
import type PersonalizationServices from './personalizationServices';
// eslint-disable-next-line import/no-duplicates
import { type UnauthenticatedServices } from './personalizationServices';
import {
  type IPlayerSettingsProvider,
  type IProfile,
  type TAuthState,
} from './personalizationServices/types';

export interface ILocationProvider {
  lat?: number;
  long?: number;
}

export interface IOnlineStatusProvider {
  online: boolean;
}

// Mobile: see https://github.com/react-native-netinfo/react-native-netinfo
// Web: see https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/effectiveType
export type TConnectivityType = 'none' | 'unknown' | 'cellular'| 'wifi' | 'bluetooth' | 'ethernet' | 'wimax' | 'vpn' | 'other' | null | 'slow-2g' | '2g'| '3g'| '4g' | '5g'

export interface INetworkStateProvider {
  connectivityType: TConnectivityType;
}

export interface ICredentialsProvider {
  authData?: TAuthState;
  bearerHeader?: string;
  token?: string;
  userToken?: string;
}

export interface IServiceBus {
  analyticServices: AnalyticServices;
  audioServices: AudioServices;
  chatServices: ChatServices;
  dataServices: DataServices;
  personalizationServices: PersonalizationServices;
  unauthenticatedServices?: UnauthenticatedServices;
}
export interface IMarketingDataProvider {
  age?: number;
  gender?: 'M' | 'F';
  zip?: string;
  yearofBirth?: string;
  getAdvertisingId?: () => Promise<string>;
  getFullTritonCookie?: () => Promise<unknown>;
  campaign?: {
    content?: string;
    name?: string;
    medium?: string;
    source?: string;
    term?: string;
  };
  profile?: IProfile;
}

type TContainer = IServiceBus &
  IOnlineStatusProvider &
  ILocationProvider &
  INetworkStateProvider &
  IPlayerSettingsProvider &
  IMarketingDataProvider &
  ICredentialsProvider &
  ILogger & { ddLogger?: IddLogger };

const Container = {} as TContainer;
export default Container;
