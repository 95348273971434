import { IAnalyticsEvent } from '@audacy-clients/client-services/src/analyticServices';
import { PlayerMetric } from '@audacy-clients/client-services/src/Constants';
import { IEvent } from '@audacy-clients/core/utils/branch';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

import AudacyLogger, {
  AudacyLoggerTransport,
  LoggerTopic,
} from '@audacy-clients/client-services/src/AudacyLogger';
import { isEnableTraceSessionEnabled } from '@audacy-clients/client-services/src/utils';

const allowedTypes = [...Object.values(PlayerMetric)];
const isEventAllowed = (event: IEvent): boolean => {
  return allowedTypes.includes(event.type as PlayerMetric);
};

export const sendEventsToDataDog = async (events: IEvent[] | IAnalyticsEvent[]): Promise<void> => {
  if (!events || events.length === 0) {
    return;
  }

  events.filter(isEventAllowed).forEach((event) => {
    AudacyLogger.info(
      `[${LoggerTopic.Analytics}] AnalyticsService: sending ${event.type} event to RUM`,
    );

    datadogRum.addAction(event.type, event);
  });
};

export const dataDogLoggerTransport: AudacyLoggerTransport = {
  error: (message: string) => {
    // adding the errors to RUM allows us to see the error stack trace all in one spot
    // this can be updated in the future depending on needs
    datadogRum.addError(message);
  },
  warn: (message: string) => {
    datadogLogs.logger.warn(message);
  },
  info: (message: string) => {
    if (isEnableTraceSessionEnabled()) {
      datadogLogs.logger.info(message);
    }
  },
  debug: (_message: string) => {
    // NOOP
    // datadogLogs.logger.debug(_message)
  },
  log: (_message: string) => {
    // NOOP
    // datadogLogs.debug(_message)
  },
};
