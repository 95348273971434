import { AuthState } from '@audacy-clients/client-services/core';
import { noCacheDefaultSelector } from '@audacy-clients/core/atoms/helpers/noCacheDefaultSelector';
import clientServices from '@audacy-clients/core/utils/clientServices';
import { atom } from 'recoil';

type TAuthCallback = (state: AuthState) => void;

let authCallback: TAuthCallback | undefined;

const getAndNotify = async () => {
  await clientServices.onReady();
  const newState = await clientServices.getPersonalizationServices().getAuthState();
  authCallback?.(newState);
  return newState;
};

export const auth = atom<AuthState>({
  default: getAndNotify(),
  effects: [
    ({ onSet }) =>
      onSet((newState) => {
        authCallback?.(newState);
      }),
  ],
  key: 'Auth',
});

const getIsSoftDeletedValue = async () => {
  await clientServices.onReady();
  return await clientServices.getPersonalizationServices().getIsSoftDeleted();
};

export const isSoftDeletedState = atom<boolean>({
  default: getIsSoftDeletedValue(),
  key: 'AuthSoftDeleted',
});

export const isLoggedIn = noCacheDefaultSelector({
  get: ({ get }) => get(auth) === AuthState.AUTH,
  key: 'LoggedIn',
});

export const setAuthCallback = (callback: TAuthCallback) => {
  authCallback = callback;
  getAndNotify();
};
