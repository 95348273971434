import { ReactElement } from 'react';

import { useModuleContext } from '@audacy-clients/core/components/ModuleRenderer/context';
import { IModuleProps } from '@audacy-clients/core/components/ModuleRenderer/types';

import Carousel from '~/components/Carousel';
import { SwiperParams } from '~/components/Carousel/constants';
import StackedCarousel from '~/components/Carousel/StackedCarousel';

const ModuleCarousel: React.FC<IModuleProps> = ({ children }) => {
  const { carousel, isSideColumn } = useModuleContext();

  if (!carousel) {
    return null;
  }

  if (carousel && carousel.isStacked) {
    return (
      <StackedCarousel
        stackSize={carousel.itemsPerColumn}
        swiperParams={isSideColumn ? SwiperParams.sideColumnStacked : undefined}
      >
        {children as ReactElement[]}
      </StackedCarousel>
    );
  }

  const carouselItems = children as ReactElement[];
  const sideColumnParams = isSideColumn ? SwiperParams.sideColumn : undefined;
  const featuredParams =
    carouselItems.length > 2
      ? SwiperParams.featured
      : SwiperParams.featuredSingleAndDouble[carouselItems.length];

  return (
    <Carousel swiperParams={carousel.isFeatured ? featuredParams : sideColumnParams}>
      {carouselItems}
    </Carousel>
  );
};

export default ModuleCarousel;
