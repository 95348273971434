import { BP, Colors, Gradients, mq, ZIndex } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    display: 'grid',
    gridTemplateRows: '[top] 3fr [content] 1fr [bottom]',
    alignItems: 'center',
    height: '100vh',
    [mq(BP.Large)]: {
      gridTemplateRows: '[top] 3fr [content] 2fr [bottom]',
    },
  },
  controlsContainer: {
    display: 'grid',
    gridTemplateRows: '[content-overview] 50px [scrubber] auto [controls] 60px [bottom]',
    alignSelf: 'stretch',
    backgroundColor: Colors.audacyDarkNavy,
    bottom: 0,
    padding: '0 20px',
    width: '100%',
    position: 'fixed',
    zIndex: ZIndex.fullPlayerPanel,
    '&::before': {
      background: Gradients.playerContainer,
      content: '""',
      height: 50,
      position: 'absolute',
      top: -50,
      left: 0,
      pointerEvents: 'none',
      width: '100%',
      zIndex: ZIndex.fullPlayerPanel,
    },
    [mq(BP.Medium)]: {
      position: 'relative',
      padding: '0 42px 5px',
    },
    [mq(BP.Large)]: {
      backgroundColor: 'transparent',
      position: 'relative',
      padding: '0 60px 15px',
      '&::before': {
        display: 'none',
      },
    },
  },
};

export default styles;
