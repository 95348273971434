export default (
  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.96945 10.1142L3.49978 10.6445L4.03011 10.1142L6.44676 7.69754L6.97709 7.16721L5.91643 6.10655L5.3861 6.63688L4.24978 7.77321L4.24978 2.00053L4.24978 1.25053L2.74978 1.25053L2.74978 2.00053L2.74978 7.77321L1.61345 6.63688L1.08312 6.10655L0.0224608 7.16721L0.552791 7.69754L2.96945 10.1142Z"
      fill="white"
      fillOpacity="0.6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.96945 1.46978L10.4998 0.939453L11.0301 1.46978L13.4468 3.88644L13.9771 4.41677L12.9164 5.47743L12.3861 4.9471L11.2498 3.81077L11.2498 9.58381L11.2498 10.3338L9.74978 10.3338L9.74978 9.58381L9.74978 3.81077L8.61345 4.9471L8.08312 5.47743L7.02246 4.41677L7.55279 3.88644L9.96945 1.46978Z"
      fill="white"
      fillOpacity="0.6"
    />
  </svg>
);
