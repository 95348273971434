import { type ClickFeature } from '@audacy-clients/client-services/core';
import { type IViewContext } from '@audacy-clients/client-services/src/analyticServices';
import { webClientNavigation } from '@audacy-clients/core/atoms/config/navigation';
import clientServices from '@audacy-clients/core/utils/clientServices';
import { isNil, omitBy } from 'lodash';
import { useRecoilValue } from 'recoil';

import { useGetViewContextFn } from '~/state/dataEvents';

export type INavigationContext = {
  contentId?: string;
  feature: ClickFeature;
  featureText?: string;
  itemsList?: Array<string>;
  moduleId?: string;
  viewContext: IViewContext;
  viewId?: string;
};

export const sendNavigationClickEvent = (context: INavigationContext): void => {
  const { viewContext, ...navigationClickContext } = context;
  const filteredNavigationClickContext = {
    feature: context.feature,
    ...omitBy(navigationClickContext, isNil),
  };

  clientServices
    .getAnalyticServices()
    .sendNavigationClickEvent(viewContext, filteredNavigationClickContext);
};

type IUseNavClickEvent = {
  entitySubtype?: string;
  entityType?: string;
} & Omit<INavigationContext, 'viewContext'>;

export const useNavClickEvent = (context: IUseNavClickEvent): (() => void) => {
  const { entityType, entitySubtype, ...rest } = context;

  const viewContext = useGetViewContextFn()();
  const { viewMap } = useRecoilValue(webClientNavigation)
  const targetViewId = entityType && entitySubtype && viewMap[entityType]?.[entitySubtype]?.viewId;

  return () => {
    sendNavigationClickEvent({
      viewContext,
      viewId: targetViewId,
      ...rest,
    });
  };
};
