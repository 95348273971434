export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Time</title>
    <path
      d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M16.75 10v-.75h-1.5V10h1.5ZM16 16h-.75v.75H16V16Zm6 .75h.75v-1.5H22v1.5ZM15.25 10v6h1.5v-6h-1.5Zm.75 6.75h6v-1.5h-6v1.5Z"
      fill="currentColor"
    />
  </svg>
);
