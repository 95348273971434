import { BP, Colors, mq, ZIndex } from '~/styles';
import { mqReducedMotion } from '~/styles/breakpoints';
import { TStyles, TStyleSheet } from '~/types/emotion-styles';

import { lineBounce, LineWidth } from './constants';

export const randomLineStyles = (val: number): TStyles => ({
  height: val * 60,
  top: val * 60,
  animationDelay: `${val - 1}s`,
  [mq(BP.Large)]: {
    height: val * 80,
    top: `${val * 15}%`,
  },
});

export const lineWidthStyles = (lineWidth: LineWidth): TStyles => {
  let width;

  switch (lineWidth) {
    case LineWidth.Thin:
      width = 3;
      break;
    case LineWidth.Medium:
      width = 8;
      break;
    case LineWidth.Thick:
      width = 12;
      break;
  }

  return {
    width,
  };
};

const styles: TStyleSheet = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: ZIndex.hidden,
  },
  gradient: {
    height: '100%',
    left: '50%',
    maxWidth: 400,
    position: 'fixed',
    transform: 'translateX(-50%)',
    width: '100%',
    [mq(BP.Large)]: {
      maxWidth: 450,
    },
  },
  gradientColor: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    '&:nth-of-type(1)': {
      left: 0,
      top: '-10%',
      [mq(BP.Medium)]: {
        left: '30%',
        top: '-10%',
      },
      [mq(BP.Large)]: {
        top: '-5%',
      },
    },
    '&:nth-of-type(2)': {
      right: '-10%',
      top: '5%',
      [mq(BP.Medium)]: {
        right: '-20%',
      },
      [mq(BP.Large)]: {
        right: '15%',
        top: '15%',
      },
    },
  },
  lines: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-around',
    left: '50%',
    maxWidth: '90%',
    position: 'absolute',
    transform: 'translateX(-50%)',
    width: '100%',
    ...mqReducedMotion({
      display: 'none',
    }),
  },
  line: {
    backgroundColor: Colors.white15,
    position: 'relative',
    height: 70,
    animation: `${lineBounce} 10s ease-in-out infinite`,
  },
};

export default styles;
