import React from 'react';

import { Path, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ISearchBarFormValues } from '~/components/SearchBar';

import styles from './styles';

interface ISearchBarProps {
  name: Path<ISearchBarFormValues>;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  register: UseFormRegister<ISearchBarFormValues>;
  tabIndex?: number;
}

const SearchBarInput = ({ name, onChange, register, tabIndex }: ISearchBarProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <input
      {...register(name)}
      css={[styles.input]}
      placeholder={t('searchNavLandingScreen.overview.searchInput.placeholder')}
      tabIndex={tabIndex}
      type="text"
      autoComplete="off"
      onChange={onChange}
    />
  );
};

export default SearchBarInput;
