import { type IQueue } from './types';

export const EMPTY_QUEUE: IQueue = { currentId: '', items: [] };

export enum ReactQueryStorageKey {
  REMOTE_NAVIGATION = 'remoteNavigation',
  REMOTE_SUB_NAVIGATION = 'remoteSubNavigation',
}

export enum LocalStorageKey {
  BEARER_HEADER = 'bearerHeader',
  COLLECTION_ID = 'collectionId',
  IS_SOFT_DELETED = 'isSoftDeleted',
  REFRESH_TOKEN = 'refreshToken',
  UNAUTHENTICATED_PLAYBACK_TIME = 'unauthenticatedPlaybackTime',
  UNAUTHENTICATED_PLAYBACK_MAX_DURATION = 'unauthenticatedPlaybackMaxDuration',
  UNAUTHENTICATED_PLAYBACK_LAST_RESET_DATE = 'unauthenticatedPlaybackLastResetDate',
  USER_TOKEN = 'userToken',
  HISTORY = 'history',
  PLAYBACKS = 'playbacks',
  ACTIVE_PLAYLIST_ITEMS = 'activePlaylistItems',
  BRAZE_DEVICE_ID = 'brazeDeviceId',
}

export enum SessionStorageKey {
  ADSWIZZ_LISTENER_ID = 'adsWizzListenerId',
  FULL_TRITON_COOKIE = 'fullTritonCookie',
  LOCATION_MARKETS_STATE = 'locationMarketsState',
}

export const VIEW_TYPE_VALUES = {
  imageRow: 'imageRow',
  list: 'list',
};
