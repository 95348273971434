export enum BrazeUserEvents {
  METERED_CONTENT_LIMIT_REACHED = 'METERED_CONTENT_LIMIT_REACHED',
}

export interface IBrazeInstance {
  refreshFeatureFlags(): void;
  getAllFeatureFlags(): IBrazeFeatureFlag[] | Promise<IBrazeFeatureFlag[] | undefined> | undefined;
  getFeatureFlag(
    featureId: string,
  ): IBrazeFeatureFlag | Promise<IBrazeFeatureFlag | undefined> | undefined;
  logCustomEvent(eventName: BrazeUserEvents, eventProperties?: object | undefined): void;
  removeFromCustomUserAttributeArray?(arrayName: string, idToRemove: string): void;
  addToCustomUserAttributeArray?(arrayName: string, idToRemove: string): void;
}

export interface IBrazeFeatureFlag {
  id: string;
  enabled: boolean;
  properties?: {
    active?: { type: string; value: boolean };
    duration?: { type: string; value: number };
  };
}
