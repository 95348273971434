import { forwardRef, MutableRefObject } from 'react';

import { TStyles } from '~/types/emotion-styles';

import styles, { menuPositionStyles } from './styles';

export enum OptionsMenuPosition {
  BottomLeft = 'BottomLeft',
  BottomRight = 'BottomRight',
  TopLeft = 'TopLeft',
  TopRight = 'TopRight',
}

type TDivRef =
  | ((instance: HTMLDivElement | null) => void)
  | MutableRefObject<HTMLDivElement | null>
  | null;

interface IOptionsMenuContainerProps {
  buttonSizeInPx?: number;
  children?: React.ReactNode;
  containerCss?: TStyles;
  id: string;
  isOpen?: boolean;
  menuPosition?: OptionsMenuPosition;
}

const OptionsMenuContainer = (
  {
    buttonSizeInPx = 24,
    children,
    containerCss,
    id,
    isOpen,
    menuPosition = OptionsMenuPosition.BottomRight,
  }: IOptionsMenuContainerProps,
  ref: TDivRef,
): JSX.Element => {
  return (
    <div
      css={[
        styles.menuContainer,
        menuPositionStyles(menuPosition, buttonSizeInPx),
        isOpen ? styles.menuOpen : styles.menuClosed,
        containerCss,
      ]}
      id={id}
      ref={ref}
      role="menu"
    >
      {children}
    </div>
  );
};

export default forwardRef(OptionsMenuContainer);
