import { Borders, BP, Colors, mq, Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  separator: {
    borderBottom: Borders.separator,
    paddingTop: 40,
    marginTop: 13,
    marginBottom: 15,
    [mq(BP.Medium)]: {
      paddingTop: 0,
    },
  },
  title: {
    ...Typography.bodyLarge,
    color: Colors.white,
  },
};

export default styles;
