import { Colors, mqHover, Typography } from '~/styles';
import { Mixins } from '~/styles/mixins';
import { focusOffset, Outlines } from '~/styles/outlines';
import { type TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  containerWrapper: {
    ...mqHover({
      cursor: 'initial',
    }),
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  linkContainer: {
    position: 'relative',
    ...mqHover({
      '.imageOpacityLayout': {
        opacity: 0.85,
      },
      '.title': {
        color: Colors.white60,
      },
    }),
  },
  linkInImage: {
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 700,
    opacity: 0.6,
    ...mqHover({
      opacity: 1,
    }),
  },
  link: {
    display: 'block',

    ...Mixins.focusReset(),
    [`&:focus::after`]: {
      outline: Outlines.focus,
      outlineOffset: focusOffset,
    },
    [`&:focus:not(:focus-visible)::after`]: {
      outline: 'none',
    },
    [`&:focus-visible::after`]: {
      outline: Outlines.focus,
      outlineOffset: focusOffset,
    },
  },
  imageWrapper: {
    ...Typography.metadataLarge,
    ...Mixins.transition('opacity'),
    position: 'relative',
    ...mqHover({
      '.hoverCardLayout': {
        display: 'block',
      },
    }),
    ['&:focus']: {
      '.hoverCardLayout': {
        display: 'block',
      },
    },
    ['&:focus-within']: {
      '.hoverCardLayout': {
        display: 'block',
      },
    },
  },
  title: {
    ...Typography.metadataLarge,
    color: Colors.white,
    ...Mixins.transition('color'),
    ...Mixins.truncateText(2),
  },
  contentType: {
    ...Typography.metadataSmall,
    color: Colors.white60,
  },
  subtitle: {
    ...Typography.metadataSmall,
    color: Colors.white60,
    ...Mixins.truncateText(2),
  },
  hoverCardLayout: {
    display: 'none',
    zIndex: 2,
    pointerEvents: 'fill',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    flex: 1,
  },
  hoverCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    alignItems: 'center',
    padding: '8px',
  },
  hoverCardWrapperExtraPadding: { paddingBottom: '45%' },
  playIconContainer: {
    transform: 'scale(0.75)',
    width: '55%',
    height: '55%',
    borderRadius: '50%',
    backgroundColor: Colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...mqHover({
      ['& > span']: {
        color: `${Colors.audacyDarkNavy} !important`,
      },
    }),
  },
  likeButtonLayout: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  likeButtonWrapper: {
    position: 'relative',
    fontSize: '12px',
    ...mqHover({
      '&::after': {
        content: '"Add to favorites"',
        padding: '6px 14px',
        backgroundColor: Colors.white15,
        position: 'absolute',
        top: '-30px',
        left: '-60px',
        borderRadius: '20px',
        width: '136px',

        zIndex: 1,
      },
    }),
  },
  hoverCardCentralContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
};

export default styles;
