import DataObject from './DataObject';
import { ObjectType } from '../Constants';

/**
 * @module Tag
 */
export default class Tag extends DataObject {
  /**
   * Creates a new instance of a Tag.
   * @param {Object} data
   */
  constructor(data) {
    super(ObjectType.TAG, data);
  }
}
