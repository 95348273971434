export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Call</title>
    <path
      d="m4.954 7.747-.53-.53-.16.16-.045.223.735.147ZM8.49 4.211l.53-.53-.53-.53-.53.53.53.53Zm16.263 23.335.147.735.223-.044.16-.16-.53-.531Zm3.536-3.536.53.53.53-.53-.53-.53-.53.53Zm-6.364-6.364.53-.53-.53-.53-.53.53.53.53Zm-3.536 3.536-.53.53.53.53.53-.53-.53-.53Zm-7.071-7.071-.53-.53-.53.53.53.53.53-.53Zm3.536-3.536.53.53.53-.53-.53-.53-.53.53Zm-9.37-2.298L9.02 4.742l-1.06-1.06-3.536 3.535 1.06 1.06Zm5.657 13.082c-3.422-3.422-4.771-6.82-5.275-9.34a13.603 13.603 0 0 1-.273-3.035 9.243 9.243 0 0 1 .087-1.036l.008-.047.002-.008v-.001.001l-.736-.146a58.337 58.337 0 0 0-.736-.145v.003l-.002.007a2.685 2.685 0 0 0-.017.097c-.01.063-.023.152-.037.266-.027.228-.056.555-.068.966a15.1 15.1 0 0 0 .301 3.372c.557 2.785 2.037 6.458 5.686 10.106l1.06-1.06Zm13.612 6.187c-.147-.735-.147-.735-.146-.736h.001l-.009.002-.047.008a9.284 9.284 0 0 1-1.036.087c-.724.02-1.772-.02-3.035-.273-2.52-.504-5.918-1.853-9.34-5.275l-1.06 1.06c3.648 3.65 7.321 5.129 10.106 5.686 1.388.277 2.55.325 3.372.301.411-.012.738-.041.966-.068a6.886 6.886 0 0 0 .363-.054l.007-.002h.004l-.146-.736Zm3.005-4.066-3.535 3.536 1.06 1.06 3.536-3.535-1.06-1.06Zm-6.364-5.303 6.364 6.364 1.06-1.06-6.363-6.365-1.06 1.06Zm0-1.06-3.535 3.535 1.06 1.06 3.536-3.535-1.06-1.06Zm-2.475 3.535-7.07-7.071-1.061 1.06 7.07 7.071 1.061-1.06Zm-7.07-6.01 3.535-3.536-1.06-1.06-3.536 3.535 1.06 1.06Zm-3.89-9.9 6.364 6.364 1.06-1.06L9.02 3.68l-1.06 1.06Z"
      fill="currentColor"
    />
    <path
      d="M21 6h5v5M20 12l5.5-5.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);
