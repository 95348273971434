export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Shield</title>
    <path
      d="M4.515 8.515S10.675 7.03 16 3.03c5.326 4 11.485 5.485 11.485 5.485S29.711 25 16 28C2.289 25 4.515 8.515 4.515 8.515Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="m11.452 15.72 3.639 3.143 5.79-6.82"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);
