import Image from '~/components/Image';

import { IFeaturedCardProps } from '.';
import HighlightText from './components/HighlightText/HightlightText';
import TextBlock from './components/TextBlock';
import styles from './styles';

const FeaturedCard = ({
  imageURIBase,
  thumbnailURI,
  forceVertical,
  highlightText = '',
  thumbnailAltText = '',
  imageAltText = '',
  hideThumbnail,
  ...rest
}: IFeaturedCardProps): JSX.Element => {
  const getTitleStyle = () => {
    const isTitleOverMaxChars = rest.title.length > 50;
    if (forceVertical) {
      if (imageURIBase) {
        return styles.titleVerticalWithImage;
      }
      return isTitleOverMaxChars ? styles.titleMaxChars : styles.titleVerticalWithoutImage;
    }

    if (imageURIBase) {
      return styles.title;
    }
    return isTitleOverMaxChars ? styles.titleMaxChars : [styles.title, styles.titleWithoutImageSm];
  };

  //Thumbnail will always default to small
  const thumbnail = thumbnailURI && (
    <Image
      sizes={[
        { maxWidth: 599, size: 60 },
        { maxWidth: 1119, size: 220 },
        { maxWidth: 1439, size: 240 },
        { size: 240 },
      ]}
      src={thumbnailURI}
      alt={thumbnailAltText}
      imgCss={[styles.thumbnailSmall, !imageURIBase && !forceVertical ? styles.thumbnailLarge : {}]}
    />
  );

  //Large cover image when there is no image on large BP
  const coverImage = !forceVertical && <div css={styles.thumbnailCover}>{thumbnail}</div>;

  return (
    <div css={[styles.container, forceVertical && styles.verticalContainer]}>
      {/* Text Content Area */}
      <div
        css={[
          styles.textContentArea,
          (imageURIBase || (highlightText && !forceVertical)) && styles.textContentEnd,
          forceVertical && styles.fullWidthText,
        ]}
      >
        {/* Thumbnail (Small) Top Right Corner */}
        {!hideThumbnail && (
          <div
            css={[
              styles.thumbnailTopRight,
              imageURIBase && forceVertical && styles.hideThumbnail,
              !forceVertical && !imageURIBase && styles.hideThumbnail,
            ]}
          >
            {thumbnail}
          </div>
        )}

        {/* TextBlock contains Eyebrow, Title, Subtitle, PlayStateArea/"Go-To" Link */}
        <TextBlock titleCss={getTitleStyle()} {...rest} />
      </div>

      {/* Image Content Area */}
      {imageURIBase ? (
        <div css={[styles.imageContentArea, !forceVertical && styles.horizontalImgContainer]}>
          <Image
            sizes={[
              { maxWidth: 599, size: 579 },
              { maxWidth: 1119, size: 550 },
              { maxWidth: 1439, size: 710 },
              { size: 950 },
            ]}
            src={imageURIBase}
            alt={imageAltText || ''}
            imgCss={[styles.backgroundImage, forceVertical ? styles.backgroundImageVertical : {}]}
          />

          {/* Thumbnail/Cover (small thumbnail that overhangs on image in small BP)*/}
          {!hideThumbnail && (
            <div css={[styles.thumbnailOverlay, forceVertical && styles.thumbnailOverlayVertical]}>
              {thumbnail}
            </div>
          )}
        </div>
      ) : (
        coverImage
      )}
      {highlightText.length > 0 && highlightText.trim().length != 0 && (
        <HighlightText
          containerCss={
            forceVertical ? styles.highlightTextSmallBP : styles.highlightTextMedLargeBP
          }
          text={highlightText}
        />
      )}
    </div>
  );
};

export default FeaturedCard;
