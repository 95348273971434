import { KeyboardEvent, MouseEventHandler, MutableRefObject, PointerEventHandler } from 'react';

import { ButtonType } from '~/components/Button/types';

export enum LinkType {
  Anchor = 'Anchor',
  Button = 'Button',
}

export type TButtonRef =
  | ((instance: HTMLButtonElement | null) => void)
  | MutableRefObject<HTMLButtonElement | null>
  | null;

export type TAnchorRef =
  | ((instance: HTMLAnchorElement | null) => void)
  | MutableRefObject<HTMLAnchorElement | null>
  | null;

export type TButtonOrAnchorRef = TButtonRef | TAnchorRef;

export interface IGlobalButtonAnchorSharedProps {
  as?: LinkType;
  ariaControls?: string;
  ariaDescribedBy?: string;
  ariaExpanded?: boolean;
  ariaHasPopup?: boolean | 'dialog' | 'grid' | 'listbox' | 'menu' | 'tree';
  ariaHidden?: boolean;
  ariaLabel?: string;
  ariaPosInSet?: number;
  ariaPressed?: boolean;
  ariaSelected?: boolean;
  ariaSetSize?: number;
  children?: React.ReactNode;
  className?: string;
  id?: string;
  onBlur?: () => void;
  onClick?: MouseEventHandler;
  onFocus?: () => void;
  role?: string;
  onKeyDown?(e: KeyboardEvent): void;
  onKeyUp?(e: KeyboardEvent): void;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  onPointerDown?: PointerEventHandler;
  tabIndex?: number;
  title?: string;
}

export interface IGlobalAsButtonProps extends IGlobalButtonAnchorSharedProps {
  isDisabled?: boolean;
  type?: ButtonType;
}

export interface IGlobalAsAnchorProps extends IGlobalButtonAnchorSharedProps {
  href?: string; // Temp fix to make SectionTitleWork
  isExternal?: boolean;
}

export type TGlobalAnchorOrButton = IGlobalAsButtonProps | IGlobalAsAnchorProps;
