import { noCacheDefaultSelectorFamily } from '@audacy-clients/core/atoms/helpers/noCacheDefaultSelector';
import { wrapContentObject } from '@audacy-clients/core/atoms/wrappers/content';
import { type TWrappedContentObject } from '@audacy-clients/core/atoms/wrappers/types';
import clientServices from '@audacy-clients/core/utils/clientServices';

export const contentQuery = noCacheDefaultSelectorFamily({
  get:
    <T extends TWrappedContentObject | undefined>(contentId: string | undefined) =>
    async () => {
      try {
        if (!contentId) {
          return undefined;
        }
        const content = await clientServices.getDataServices().getContentObject(contentId);
        return wrapContentObject(content) as T;
      } catch (_e) {
        // empty
      }
    },
  key: 'Content',
});
