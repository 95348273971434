export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Live</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 25V7H12v18h1.5Zm-6 0v-8.25H6V25h1.5Zm12-12.75V25H18V12.25h1.5Zm6 12.75v-6.75H24V25h1.5Z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);
