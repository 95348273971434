export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Downloaded</title>
    <path
      d="M23.891 24.23H8.424C5.044 24.23 3 22.027 3 19.274c0-2.477 1.457-4.414 3.783-4.8.076-2.928 3-4.778 5.38-4.14C13.413 8.487 15.423 7 18.196 7c4.239 0 7.554 3.369 7.532 8.037C27.75 15.862 29 17.602 29 19.749c0 2.554-1.924 4.48-5.109 4.48Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="m11.717 16.97 3.398 2.992 5.046-6.069"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);
