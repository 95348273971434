import { Mixins } from '~/styles';
import { TStyles } from '~/types/emotion-styles';

import { TitleVariant } from './constants';
import styles from './styles';

// NB: we make the tags below readonly with "as const".
export const titleTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span'] as const;
export type TTitleTag = typeof titleTags[number];

interface ITitleProps {
  children?: React.ReactNode;
  as?: TTitleTag;
  containerCss?: TStyles;
  hasHorizontalPadding?: boolean;
  title?: string;
  titleCss?: TStyles;
  variant?: TitleVariant;
}

const TitleVariantCss: Record<TitleVariant, TStyles> = {
  [TitleVariant.Small]: styles.title,
  [TitleVariant.Large]: styles.titleLarge,
};

export const TitleTag = ({
  as = 'h1',
  children,
  title,
  titleCss = {},
  variant = TitleVariant.Small,
}: ITitleProps): JSX.Element => {
  const titleVariantCss = TitleVariantCss[variant];
  const Tag = as;

  return <Tag css={[titleVariantCss, titleCss]}>{title || children}</Tag>;
};

const Title = ({
  containerCss = {},
  hasHorizontalPadding = true,
  ...rest
}: ITitleProps): JSX.Element => (
  <div
    css={[hasHorizontalPadding && Mixins.horizontalModulePadding(), styles.container, containerCss]}
  >
    <TitleTag {...rest} />
  </div>
);

export default Title;
