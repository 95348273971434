import { ReactNode } from 'react';

import { EntityType, LinkMenuItemType } from '@audacy-clients/client-services';
import { useLocation } from 'react-router-dom';

import { Icons } from '~/components/Icon/constants';
import { OptionsMenuTypes } from '~/components/OptionsMenu/constants';
import { IOptionsMenu } from '~/components/OptionsMenu/types';
import { getPodcastUrl } from '~/routes';

import LinkMenuItem from './LinkMenuItem';
import { MarkEpisodeAsPlayed, MarkStandaloneChapterAsPlayed } from './MarkAsPlayedMenuItem';
import QueueMenuItem from './QueueMenuItem';
import { useShareSubtype } from './ShareMenuItem';
import ToggleFollowMenuItem from './ToggleFollowMenuItem';

export const useOptionsMenuContent = (
  optionsMenu: IOptionsMenu,
  isQueueEnabled: boolean,
  onClickCallback?: () => void,
  isLoggedIn?: boolean,
): ReactNode[] => {
  const menuContent: ReactNode[] = [];
  const shareSubtype = useShareSubtype(optionsMenu, onClickCallback);
  const { pathname: encodedPathname } = useLocation();
  const pathname = decodeURIComponent(encodedPathname);

  const isCurrentPathname = pathname === optionsMenu.data.url;
  const contentId = optionsMenu.contentId;
  if (!contentId) return [];

  if (shareSubtype) {
    // NOTICE: remove share options from 3 dots menu acc. to CCS-3386
    //menuContent.push(shareSubtype);
  }

  let stationHref: string | undefined;
  if (
    optionsMenu.type === OptionsMenuTypes.FullPlayerStation ||
    optionsMenu.type === OptionsMenuTypes.FullPlayerExclusiveStation ||
    optionsMenu.type === OptionsMenuTypes.MyAudioRadioStation ||
    optionsMenu.type === OptionsMenuTypes.MyAudioExclusiveStation ||
    optionsMenu.type === OptionsMenuTypes.RecentRadioStation ||
    optionsMenu.type === OptionsMenuTypes.RecentExclusiveStation ||
    optionsMenu.type === OptionsMenuTypes.SearchStation
  ) {
    stationHref = optionsMenu.data.url || '';
  } else if (optionsMenu.type === OptionsMenuTypes.FullPlayerBroadcastEpisode) {
    stationHref = optionsMenu?.parentShowUrl;
  }

  const episodeDetailsMenuItem = !isCurrentPathname ? (
    <LinkMenuItem
      href={optionsMenu.data.url}
      icon={Icons.Detail}
      linkType={LinkMenuItemType.EPISODE_DETAILS}
    />
  ) : (
    <></>
  );

  const goToEpisodePageMenuItem = (
    <LinkMenuItem
      href={optionsMenu.parentEpisodeUrl}
      icon={Icons.GotoCircle}
      linkType={LinkMenuItemType.EPISODE_GO_TO}
    />
  );

  const goToShowPageMenuItem = (
    <LinkMenuItem
      href={optionsMenu.parentShowUrl}
      icon={Icons.GotoCircle}
      linkType={LinkMenuItemType.SHOW_GO_TO}
    />
  );

  const podcastLinkMenuItem = optionsMenu.data.url ? (
    <LinkMenuItem href={getPodcastUrl(optionsMenu.data.url)} linkType={LinkMenuItemType.PODCAST} />
  ) : (
    <></>
  );

  const stationLinkMenuItem = (
    <LinkMenuItem
      href={optionsMenu.data.website || stationHref}
      isExternal={!!optionsMenu.data.website}
      linkType={LinkMenuItemType.STATION}
      text={optionsMenu.data.title}
    />
  );

  const topicMenuItem = (
    <LinkMenuItem
      icon={Icons.GotoCircle}
      href={optionsMenu.data.url}
      linkType={LinkMenuItemType.TOPIC}
      text={optionsMenu.data.title}
    />
  );
  // Disable “Add to Queue”, “Follow”, "Mark as Played" for users who are not authenticated
  const queueMenuItem = isLoggedIn && isQueueEnabled ? (
    <QueueMenuItem id={contentId} isDisabled={!isQueueEnabled} onClickCallback={onClickCallback} />
  ) : (
    <></>
  );

  const toggleFollowMenuItem = isLoggedIn ? (
    <ToggleFollowMenuItem
      isFollowing={optionsMenu.isFollowing}
      toggleFollow={optionsMenu.toggleFollow}
      onClickCallback={onClickCallback}
    />
  ) : (
    <></>
  );

  const markAsPlayed = isLoggedIn ? (
    optionsMenu.entityType === EntityType.STANDALONE_CHAPTER ? (
      <MarkStandaloneChapterAsPlayed id={contentId} onClickCallback={onClickCallback} />
    ) : (
      <MarkEpisodeAsPlayed id={contentId} onClickCallback={onClickCallback} />
    )
  ) : (
    <></>
  );

  const broadcastStationMenuItems = [stationLinkMenuItem, toggleFollowMenuItem];
  const podcastEpisodeMenuItems = [
    queueMenuItem,
    markAsPlayed,
    episodeDetailsMenuItem,
    podcastLinkMenuItem,
  ];
  const broadcastEpisodeMenuItems = optionsMenu.isLive
    ? [queueMenuItem, episodeDetailsMenuItem]
    : [queueMenuItem, markAsPlayed, episodeDetailsMenuItem];

  const topicItems = [toggleFollowMenuItem, topicMenuItem];
  const continueListeningPodcastEpisodeItems = [markAsPlayed, ...podcastEpisodeMenuItems];
  const continueListeningBroadcastEpisodeeItems = [markAsPlayed, ...broadcastStationMenuItems];
  const standaloneChapter = [queueMenuItem, goToEpisodePageMenuItem, goToShowPageMenuItem];
  const fullPlayerPodcastEpisodeMenuItems = [...podcastEpisodeMenuItems, toggleFollowMenuItem];

  switch (optionsMenu.type) {
    case OptionsMenuTypes.FullPlayerExclusiveStation:
    case OptionsMenuTypes.FullPlayerStation:
    case OptionsMenuTypes.FullPlayerBroadcastEpisode:
    case OptionsMenuTypes.MyAudioExclusiveStation:
    case OptionsMenuTypes.MyAudioRadioStation:
    case OptionsMenuTypes.SearchStation:
      menuContent.unshift(...broadcastStationMenuItems);
      break;

    case OptionsMenuTypes.MyAudioPodcast:
    case OptionsMenuTypes.MyAudioRadioShow:
    case OptionsMenuTypes.Playlist:
    case OptionsMenuTypes.SearchPodcastEntity:
      menuContent.unshift(toggleFollowMenuItem);
      break;

    case OptionsMenuTypes.FullPlayerPodcastEpisode:
      menuContent.unshift(...fullPlayerPodcastEpisodeMenuItems);
      break;
    case OptionsMenuTypes.MyAudioQueue:
    case OptionsMenuTypes.PodcastEpisode:
      menuContent.unshift(...podcastEpisodeMenuItems);
      break;

    case OptionsMenuTypes.BroadcastEpisode:
      menuContent.unshift(...broadcastEpisodeMenuItems);
      break;

    case OptionsMenuTypes.MyAudioTopic:
      menuContent.unshift(...topicItems);
      break;

    case OptionsMenuTypes.SearchPodcastEpisode:
    case OptionsMenuTypes.RecentPodcast:
      menuContent.unshift(podcastLinkMenuItem);
      break;

    case OptionsMenuTypes.RecentExclusiveStation:
    case OptionsMenuTypes.RecentRadioStation:
      menuContent.unshift(stationLinkMenuItem);
      break;

    case OptionsMenuTypes.RadioStationEntity:
      optionsMenu.data.website && menuContent.unshift(stationLinkMenuItem);
      break;

    case OptionsMenuTypes.ContinueListeningPodcastEpisode:
      menuContent.unshift(...continueListeningPodcastEpisodeItems);
      break;

    case OptionsMenuTypes.ContinueListeningBroadcastEpisode:
      menuContent.unshift(...continueListeningBroadcastEpisodeeItems);
      break;
    case OptionsMenuTypes.StandaloneChapter:
      return standaloneChapter;
    default:
      break;
  }

  return menuContent;
};
