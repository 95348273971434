import { useState } from 'react';

import { useFollows } from '@audacy-clients/core/atoms/follows';
import { useTranslation } from 'react-i18next';

import { useGetViewContextFn } from '~/state/dataEvents';
import useToast from '~/state/toast';

interface IUseFollowEntityProps {
  contentId: string;
  isShowUnfollowToast?: boolean;
  title: string;
}

interface IUseFollowEntity {
  isFollowing?: boolean;
  toggleFollow?: () => void;
}

export const useFollowEntity = ({
  contentId,
  isShowUnfollowToast = false,
  title,
}: IUseFollowEntityProps): IUseFollowEntity => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { checkIsFollowing, toggleFollowing } = useFollows(useGetViewContextFn());

  const defaultIsFollowing = checkIsFollowing(contentId);
  const [isFollowing, setIsFollowing] = useState(defaultIsFollowing);
  const trimmedTitle = title.length > 50 ? `${title.substring(0, 50)}...` : title;

  const followCallbacks = {
    onSuccess: (undoCallback: () => void): void => {
      setIsFollowing(true);

      const onUndo = () => {
        setIsFollowing(false);
        undoCallback();
      };

      showToast({
        cta: { action: onUndo, title: t('global.undo') },
        title: t('entityHeader.followSuccess', {
          title: trimmedTitle,
        }),
      });
    },
    onError: (retryCallback: () => void): void => {
      setIsFollowing(false);
      showToast({
        cta: { action: retryCallback, title: t('global.retry') },
        title: t('entityHeader.followError', {
          title: trimmedTitle,
        }),
      });
    },
    onUndoError: (retryCallback: () => void): void => {
      setIsFollowing(true);
      showToast({
        cta: { action: retryCallback, title: t('global.retry') },
        title: t('entityHeader.undoError'),
      });
    },
  };

  const unfollowCallbacks = {
    onSuccess: (undoCallback: () => void): void => {
      setIsFollowing(false);

      const onUnfollow = () => {
        setIsFollowing(true);
        undoCallback();
      };

      // By default, unfollowing an entity from the header should not show a
      // toast. We do however need the option to show a toast for cases like
      // unfollowing an entity from a content card.
      if (isShowUnfollowToast) {
        showToast({
          cta: { action: onUnfollow, title: t('global.undo') },
          title: t('entityHeader.unfollowSuccess', {
            title: trimmedTitle,
          }),
        });
      }
    },
    onError: (retryCallback: () => void): void => {
      setIsFollowing(true);
      showToast({
        cta: { action: retryCallback, title: t('global.retry') },
        title: t('entityHeader.unFollowError', {
          title: trimmedTitle,
        }),
      });
    },
  };

  const toggleFollowingCallbacks = isFollowing ? unfollowCallbacks : followCallbacks;
  const toggleFollow = () => toggleFollowing(contentId, toggleFollowingCallbacks);

  return {
    isFollowing,
    toggleFollow,
  };
};
