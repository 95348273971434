import QueueStateConnector from '@audacy-clients/core/components/StateConnectors/QueueStateConnector';
import RecentsStateConnector from '@audacy-clients/core/components/StateConnectors/RecentsStateConnector';

import DeeplinkStateConnector from './DeeplinkStateConnector';
import DevFeatureFlagsStateConnector from './DevFeatureFlagsStateConnector';
import LocationMarketsStateConnector from './LocationMarketsStateConnector';
import WebPlayerStateConnector from './WebPlayerStateConnector';

const StateConnectors: React.FC = () => {
  return (
    <>
      <WebPlayerStateConnector />
      <DevFeatureFlagsStateConnector />
      <LocationMarketsStateConnector />
      <QueueStateConnector />
      <RecentsStateConnector />
      <DeeplinkStateConnector />
    </>
  );
};

export default StateConnectors;
