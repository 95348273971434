import { ModuleType } from '@audacy-clients/client-services';
import { IModuleProps } from '@audacy-clients/core/components/ModuleRenderer/types';

import BannerAd from '~/components/BannerAd';
import Grid from '~/components/Grid';
import GridItem from '~/components/Grid/GridItem';
import LiveNow from '~/components/LiveNow';
import PreviousEpisode from '~/components/PreviousEpisode';
import { defineAdSlot, getAdSlot, destroyAdSlots } from '~/utils/googleAds';

const ModuleShowLiveNowAndPreviousEpisode: React.FC<IModuleProps> = (props) => {
  const bannerAd = getAdSlot(ModuleType.BANNER_AD);
  if (bannerAd) destroyAdSlots([bannerAd]);
  defineAdSlot(ModuleType.BANNER_AD);

  return (
    <>
      <LiveNow isStation={false} {...props} />
      <BannerAd />
      <Grid>
        <GridItem lg="1/7">
          <PreviousEpisode {...props} />
        </GridItem>
      </Grid>
    </>
  );
};

export default ModuleShowLiveNowAndPreviousEpisode;
