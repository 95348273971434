import { BP, Colors, Mixins, mq, mqHover, Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const Constants = {
  hoverSizeHoriz: 15,
  hoverSizeVert: 5,
};

const styles: TStyleSheet = {
  listItem: {
    padding: '20px 0',
    position: 'relative',
    ...Mixins.transition('opacity'),
  },
  listItemHover: {
    [mq(BP.Large)]: {
      ...mqHover({
        '&::before': {
          opacity: 1,
        },
      }),
    },
    /* The pseudo-selector is used to show the hover state */
    '&::before': {
      backgroundColor: Colors.white07,
      borderRadius: 4,
      content: '""',
      height: `calc(100% - ${Constants.hoverSizeVert * 2}px)`,
      opacity: 0,
      position: 'absolute',
      top: Constants.hoverSizeVert,
      left: `-${Constants.hoverSizeHoriz}px`,
      width: `calc(100% + ${Constants.hoverSizeHoriz * 2}px)`,
      ...Mixins.transition('opacity'),
    },
  },
  played: {
    opacity: 0.6,
  },
  container: {
    display: 'block',
    width: '100%',
    textAlign: 'left',
    ...mqHover({
      color: Colors.white,
    }),
  },
  header: {
    display: 'flex',
    marginBottom: 20,
    alignItems: 'center',
  },
  image: {
    width: 76,
    height: 76,
    marginRight: 10,
    flexShrink: 0,
    [mq(BP.Medium)]: {
      width: 80,
      height: 80,
      marginRight: 20,
    },
    [mq(BP.Large)]: {
      width: 88,
      height: 88,
    },
  },
  eyebrow: {
    ...Typography.metadataSmall,
    marginBottom: 6,
    color: Colors.white60,
    flexWrap: 'wrap',
  },
  title: {
    ...Typography.metadataLarge,
    ...mqHover({
      color: Colors.white,
    }),
  },
  descriptionWrapper: {
    paddingBottom: 20,
  },
  description: {
    ...Typography.bodySmall,
    color: Colors.white60,
    // Prevent user interacting with links in description
    pointerEvents: 'none',
  },
  bottomOptions: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  playGroup: {
    alignItems: 'center',
    display: 'flex',
    gap: 5,
    // Absolutely positioned pseudoelement prevents button hit area from being
    // fully clickable unless a z-index is applied.
    zIndex: 0,
  },
  actionsGroup: {
    alignItems: 'center',
    display: 'flex',
    gap: 20,
  },
};

export default styles;
