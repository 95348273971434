import { ButtonHeights } from '~/components/Button/constants';
import { spacing } from '~/components/Carousel/constants';
import { BP, Colors, Mixins, mq, mqHover, ZIndex } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    position: 'relative',
    ...mqHover({
      '.swiper-button': {
        opacity: 1,
      },
    }),
    '&:is(:focus-visible, :focus-within)': {
      '.swiper-slide': {
        opacity: 1,
      },
    },
    ...Mixins.focusOutline({ outlineOffset: 3 }),
  },
  swiper: {
    overflow: 'visible',
    '.swiper-wrapper': {
      boxSizing: 'border-box',
    },
    // Avoid flash of incorrectly sized slides while widths are calculated
    // and therefore only show when inline width attribute is set on them
    '.swiper-slide': {
      opacity: 0,
      '&[style*="width:"]': {
        opacity: 1,

        [mq(BP.Large)]: {
          ...Mixins.transition('opacity'),
          '&:not(.swiper-slide-visible)': {
            opacity: 0.5,
          },
        },
      },
    },
  },
  swiperStacked: {
    zIndex: 50,
    '.swiper-wrapper': {
      zIndex: 50,
    },
  },
  swiperSideColumn: {
    overflow: 'hidden',
  },
  swiperButtonWrap: {
    position: 'absolute',
    right: 3,
    top: -45,
    zIndex: ZIndex.carouselNav,
    display: 'flex',
    gap: 8,
  },
  swiperButtonWrapHidden: {
    display: 'none',
  },
  swiperButton: {
    display: 'flex',
    background: Colors.white,
    boxShadow: `0px 3px 3px ${Colors.audacyDarkNavy20}`,
    color: Colors.audacyDarkNavy,
    borderRadius: '100%',
    height: ButtonHeights.carouselNav,
    width: ButtonHeights.carouselNav,
    '&:hover': {
      background: Colors.white80,
    },
    '&[disabled]': {
      border: `1px solid ${Colors.white30}`,
      background: 'none',
      color: Colors.white30,
      '&:hover': {
        color: Colors.white30,
      },
    },

    opacity: 0,
    ...Mixins.transition(['opacity', 'background']),
    ...mqHover({
      color: Colors.audacyDarkNavy,
    }),
  },
  stackedCardHolder: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.mediumAndLarge,
  },
  stackedItem: {
    marginBottom: 6,
    ':nth-of-type(3)': {
      marginBottom: 0,
    },
  },
};

export default styles;
