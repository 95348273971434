const getDataEvent = async (
  url: string,
  isSuccess: (event: MessageEvent) => boolean,
): Promise<MessageEvent | null> => {
  return new Promise((resolve, reject) => {
    let popup: Window | null | undefined;

    function receiveMessage(
      event: MessageEvent<{ source?: string; accessToken?: string; isLoggedOut?: boolean }>,
    ) {
      const isAuthEvent = event.data.accessToken ?? event.data.isLoggedOut;

      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      if (event.data?.source?.includes('react-devtools') || !isAuthEvent) {
        return;
      }

      // AccessToken is part of the message event data when it comes back
      // from the auth server. If it's present, login was successful and
      // we close the popup. When the user logs out, the isLoggedOut flag
      // is set to true, so we close the popup in that case as well.
      if (popup && isAuthEvent) {
        popup.close();
        popup = undefined;
      }

      if (isSuccess(event)) {
        window.removeEventListener('message', receiveMessage, false);
        resolve(event);
      } else {
        reject(new Error(`Login failed: ${JSON.stringify(event.data)}`)); // reject with an error
      }
    }

    popup = window.open(
      `${url}`,
      'RadioDotComAuthWindow',
      'width=375,height=685,toolbar=1,menubar=1,location=1,status=1,scrollbars=1,resizable=0',
    );

    if (popup) {
      window.addEventListener('message', receiveMessage, false);
    }
  });
};

const Auth = {
  async clearToken(url: string): Promise<MessageEvent | null> {
    return getDataEvent(url, (event) => event.data.isLoggedOut);
  },

  async getToken(url: string): Promise<MessageEvent | null> {
    return getDataEvent(url, (event) => event.data.userId);
  },
};

export default Auth;
