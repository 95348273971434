import { HTMLAttributes } from 'react';
import { BP, Grid, mq } from '~/styles';
import { TStyles } from '~/types/emotion-styles';

enum TypeConstants {
  separator = '/',
}

const Constants = {
  defaultColumnStart: '1',
};

type TSeparator = TypeConstants.separator;
type TColumn = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | 'auto';
export type TValidGridColumn = `${TColumn}${TSeparator}${TColumn}`;

interface IGridItemProps extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  sm?: TValidGridColumn;
  md?: TValidGridColumn;
  lg?: TValidGridColumn;
  xl?: TValidGridColumn;
  gridItemCss?: TStyles;
}

const parse = (col: TValidGridColumn): string[] => col.split(TypeConstants.separator);
const column = ([start, end]: string[]) => ({ gridColumn: `${start} / span ${end}` });
const defaultColumn = (span: number) => column([Constants.defaultColumnStart, String(span)]);

const GridItem = ({
  children,
  sm,
  md,
  lg,
  xl,
  gridItemCss,
  ...props
}: IGridItemProps): JSX.Element => {
  // For each breakpoint above Small, we only apply the default span if props
  // for smaller breakpoints are not passed. This is to allow previous spans
  // to cascade up. Otherwise, the GridItem spans all columns by defaut.
  const css: TStyles = {
    [mq(BP.Small)]: sm ? column(parse(sm)) : defaultColumn(Grid[BP.Small].columnCount),
    [mq(BP.Medium)]: md ? column(parse(md)) : !sm && defaultColumn(Grid[BP.Medium].columnCount),
    [mq(BP.Large)]: lg
      ? column(parse(lg))
      : !md && !sm && defaultColumn(Grid[BP.Large].columnCount),
    [mq(BP.ExtraLarge)]: xl
      ? column(parse(xl))
      : !lg && !md && !sm && defaultColumn(Grid[BP.ExtraLarge].columnCount),
  };

  return (
    <div css={[css, gridItemCss]} {...props}>
      {children}
    </div>
  );
};

export default GridItem;
