import { BP, Colors, Mixins, mq, mqHover, Typography, ZIndex } from '~/styles';
import { Outlines } from '~/styles/outlines';
import { TStyleSheet } from '~/types/emotion-styles';

export const Constants = {
  gridGapSm: 10,
  gridGapMd: 20,
};

const styles: TStyleSheet = {
  bottomActionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  bulletedContainer: {
    display: 'grid',
    // Eyebrow semantically appears after the title, visually appears before.
    order: -1,
    ...Mixins.overflowGradient('to right'),
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    flex: 1,
  },
  cardContentFixed: {
    flex: '0 0 45%',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: Constants.gridGapSm,
    flex: 1,
    borderRadius: 5,
    textAlign: 'left',
    position: 'relative',
    width: '100%',
    ...Mixins.transition('background-color'),

    [mq(BP.Medium)]: {
      gap: Constants.gridGapMd,
    },
  },
  containerFixed: {
    flex: '0 0 50%',
  },
  elementRight: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 0,
  },
  elementRightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: Constants.gridGapMd,
  },
  singleActionElement: {
    alignItems: 'flex-end',
  },
  multipleActionElements: {
    alignItems: 'center',
  },
  imageContainer: {
    width: 76,
    position: 'relative',

    [mq(BP.Medium)]: {
      width: 80,
    },
    [mq(BP.Large)]: {
      width: 88,
    },
  },
  imageContainerFixed: {
    flex: '0 0 45%',
  },
  entityImage: {
    ...Mixins.transition('opacity'),
  },
  imageOverlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.audacyDarkNavy20,
    opacity: 0,
  },
  played: {
    opacity: 0.6,
  },
  playIconContainer: {
    transform: 'scale(0.75)',
    width: '55%',
    height: '55%',
    borderRadius: '50%',
    backgroundColor: Colors.white30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...Mixins.transition(['transform']),
    ['@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))']: {
      backdropFilter: 'blur(8px)',
    },
  },
  linkOrButtonContainer: {
    textAlign: 'left',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      ...Mixins.transition(['background-color']),
    },
    ...Mixins.focusReset(),
    [`&:focus::after`]: {
      outline: Outlines.focus,
      outlineOffset: -1,
    },
    [`&:focus:not(:focus-visible)::after`]: {
      outline: 'none',
    },
    [`&:focus-visible::after`]: {
      outline: Outlines.focus,
      outlineOffset: -1,
    },
    ...mqHover({
      [`&::after`]: {
        backgroundColor: Colors.white07,
      },
    }),
  },
  playStateContainerNested: {
    // A z-index is applied to ensure clicking the playstate group CTA does
    // not trigger link navigation from the linkOrButtonContainer pseudoelement.
    zIndex: ZIndex.horizontalEntityCardPlayStateContainer,
    ...Mixins.focusOutline({ outlineOffset: -1 }),
  },
  playStateButton: {
    display: 'flex',
    ...Mixins.transition(['opacity']),
    ...mqHover({
      opacity: 0.6,
    }),
  },
  subtitle: {
    ...Typography.metadataSmall,
    color: Colors.white60,
    ...Mixins.truncateText(2),
  },
  title: {
    color: Colors.white,
    ...Typography.metadataLarge,
    ...Mixins.truncateText(2),
    ...Mixins.transition('color'),
  },
  titleSpacing: {
    marginBottom: 10,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainerFixed: {
    wordBreak: 'break-word',
  },
};

export default styles;
