import { type TStyles } from '~/types/emotion-styles';
import styles from './styles';

type TTranscriptOverlay = {
  displayBottomOverlay?: boolean;
  containerStyle?: TStyles;
  children: React.ReactNode;
};

/**
 * Overlay component for the TranscriptContent to create a gradient effect at the top and bottom of the content
 */
const TranscriptOverlay = ({
  displayBottomOverlay = true,
  containerStyle,
  children,
}: TTranscriptOverlay) => {
  return (
    <div css={containerStyle}>
      <div css={styles.topOverlayContainer}>
        <div css={styles.topOverlay} />
      </div>
      {children}
      {displayBottomOverlay && <div css={styles.bottomOverlay} />}
    </div>
  );
};

export default TranscriptOverlay;
