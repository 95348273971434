import { type Property } from 'csstype';
import { mix } from 'polished';
import { type CSSProperties } from 'react';
import { Breakpoint as BP, mq } from '~/styles/breakpoints';
import { Constants } from '~/styles/constants';
import { HorizontalSpacing } from '~/styles/spacing';
import { type TCSSObject } from '~/types/emotion-styles';
import { ratioToPercentage } from '~/utils/numbers';

import { mqHover, mqReducedMotion } from './breakpoints';
import { Colors } from './colors';
import { Easing } from './easing';
import { focusOffset, Outlines } from './outlines';
import { Timing } from './timing';
import { ZIndex } from './z-index';

const accessibleMinButtonSize = (): TCSSObject => ({
  position: 'relative',
  '&::before': {
    content: '""',
    display: 'block',
    height: '100%',
    left: '50%',
    minHeight: Constants.minButtonSize.coarsePointer,
    minWidth: Constants.minButtonSize.coarsePointer,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',

    '@media (pointer: fine)': {
      minHeight: Constants.minButtonSize.finePointer,
      minWidth: Constants.minButtonSize.finePointer,
    },
  },
});

const aspectRatio = (ratio: number): TCSSObject => ({
  position: 'relative',
  '&:before': {
    content: '""',
    display: 'block',
    paddingTop: `${ratioToPercentage(ratio)}%`,
    width: '100%',
  },
  '> *': {
    bottom: '0',
    left: '0',
    position: 'absolute',
    right: '0',
    top: '0',
  },
});

export const overflowGradient = (direction: string): TCSSObject => ({
  maskImage: `linear-gradient(${direction}, rgba(0, 0, 0, 1.0) 25%, transparent)`,
  overflow: 'hidden',
});

const transition = (
  props: string | Array<string>,
  timing: number = Timing.default,
  easing: Property.TransitionTimingFunction = Easing.default,
): TCSSObject => {
  const isArray = Array.isArray(props);
  return {
    transitionProperty: isArray ? props.join(', ') : props,
    transitionDuration: `${timing}s`,
    transitionTimingFunction: easing,
  };
};

// Per design, buttons with solid background colors should add 15%
// audacyDarkNavy on hover. This mixin helps with this per default
// but can be used to mix any color in any ratio to a color and
// then transition to it on hover
const transitionMixBackgroundColor = (
  background: Property.Color,
  hoverMixColor: Property.Color = Colors.audacyDarkNavy,
  hoverMixColorRatio = 0.15,
): TCSSObject => {
  return {
    backgroundColor: background,
    ...mqHover({
      backgroundColor: mix(hoverMixColorRatio, hoverMixColor, background),
    }),
  };
};

const transitionGradientBackground = (
  background: Property.Color | undefined,
  hoverBackground: Property.Color | undefined,
): TCSSObject => ({
  '&': {
    background: background || 'transparent',
    zIndex: ZIndex.transitionBackground,
  },
  '&::after': {
    ...transition('opacity'),
    background: hoverBackground || 'transparent',
    borderRadius: 'inherit',
    content: '""',
    height: '100%',
    left: '0',
    opacity: '0',
    position: 'absolute',
    top: '0',
    width: '100%',
    zIndex: ZIndex.transitionBackgroundAfter,
  },
  ...mqHover({ opacity: 1 }, '&:hover::after'),
});

type IFocusOutline = {
  selector?: string;
  outlineOffset?: number;
  outline?: Property.Outline;
};

// Using trick to fall back to focus where focus-visible is unsupported
// https://css-tricks.com/focus-visible-and-backwards-compatibility/
const focusOutline = ({ selector = '', outlineOffset, outline }: IFocusOutline): TCSSObject => ({
  [`&:focus ${selector}`]: {
    outline: outline || Outlines.focus,
    outlineOffset: outlineOffset || focusOffset,
  },
  [`&:focus:not(:focus-visible) ${selector}`]: {
    outline: 'none',
  },
  [`&:focus-visible ${selector}`]: {
    outline: outline || Outlines.focus,
    outlineOffset: outlineOffset || focusOffset,
  },
});

const focusReset = (): TCSSObject => ({
  '&:focus': {
    outline: 'none',
  },
  '&:focus-visible': {
    outline: 'none',
  },
});

const fullScreenContainer = (): TCSSObject => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

/**
 * Add standardized horizontal padding to an element either for the
 * main content area or in a side column (i.e. in the full player)
 *
 * @param {boolean} isSideColumn - Is the element in the side column (i.e. player info)?
 * @returns {TCSSObject} - The horizontal padding styles across breakpoints
 */
const horizontalModulePadding = (isSideColumn = false): TCSSObject => ({
  paddingLeft: HorizontalSpacing.sm,
  paddingRight: HorizontalSpacing.sm,
  [mq(BP.Medium)]: {
    paddingLeft: HorizontalSpacing.md,
    paddingRight: HorizontalSpacing.md,
  },
  [mq(BP.Large)]: {
    paddingLeft: !isSideColumn ? HorizontalSpacing.lgLeft : HorizontalSpacing.lgRight,
    paddingRight: HorizontalSpacing.lgRight,
  },
});

// Hack to add vertical & horizontal padding to scrollbar
const verticalScrollBar: TCSSObject = {
  ':hover': {
    '::-webkit-scrollbar-thumb': {
      border: 'solid 4px transparent',
      borderRadius: 14,
      boxShadow: `inset 0 0 14px 14px ${Colors.white15}`,
    },
  },
  /* 4px scroll thumb. 10px margin from right margin */
  '::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 14px 14px transparent',
    border: 'solid 4px transparent',
  },
  '::-webkit-scrollbar': {
    width: 14,
  },
};

const visibility = (isVisible: boolean): TCSSObject => ({
  visibility: isVisible ? 'visible' : 'hidden',
});

const truncateText = (numberOfLines: number): TCSSObject => ({
  display: '-webkit-box',
  WebkitLineClamp: numberOfLines,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
});

const backgroundGradientStyle = (color: string, opacity: number, size: number): CSSProperties => ({
  backgroundColor: color,
  borderRadius: '50%',
  boxShadow: `0 0 ${size}px ${size}px ${color}`,
  height: 1,
  opacity,
  width: 1,
});

const reduceMotion = (): TCSSObject => ({
  ...mqReducedMotion({
    animationDelay: '-1ms !important',
    animationDuration: '1ms !important',
    animationIterationCount: '1 !important',
    backgroundAttachment: 'initial !important',
    transitionDuration: '0s !important',
    transitionDelay: '0s !important',
  }),
});

export const Mixins = {
  accessibleMinButtonSize,
  aspectRatio,
  backgroundGradientStyle,
  focusOutline,
  focusReset,
  fullScreenContainer,
  horizontalModulePadding,
  overflowGradient,
  reduceMotion,
  transition,
  transitionMixBackgroundColor,
  transitionGradientBackground,
  truncateText,
  verticalScrollBar,
  visibility,
};
