import { Property } from 'csstype';

import { Colors } from './colors';

export interface IOutlineProps {
  focus: Property.Outline;
  focusDark: Property.Outline;
}

export const Outlines: IOutlineProps = {
  focus: `1px solid ${Colors.white}`,
  focusDark: `1px solid ${Colors.audacyDarkNavy}`,
};

export const focusOffset = 2;
