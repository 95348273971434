export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Add to queue</title>
    <path d="M24 16V26M19 21H29" stroke="currentColor" strokeWidth="2.5" strokeLinecap="square" />
    <line
      x1="4.75"
      y1="7"
      x2="27.25"
      y2="7"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <line
      x1="4.75"
      y1="16"
      x2="14.25"
      y2="16"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <line
      x1="4.75"
      y1="25"
      x2="13.75"
      y2="25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);
