import {
  CachePolicyWithoutEquality,
  ReadOnlySelectorFamilyOptions,
  ReadOnlySelectorOptions,
  ReadWriteSelectorFamilyOptions,
  ReadWriteSelectorOptions,
  RecoilState,
  RecoilValue,
  RecoilValueReadOnly,
  selector,
  selectorFamily,
  SerializableParam,
} from 'recoil';

const DEFAULT_CACHE_POLICY = {
  eviction: 'most-recent',
} as CachePolicyWithoutEquality;

export function noCacheDefaultSelector<T>(options: ReadWriteSelectorOptions<T>): RecoilState<T>;

export function noCacheDefaultSelector<T>(options: ReadOnlySelectorOptions<T>): RecoilValue<T>;

export function noCacheDefaultSelector<T>(
  options: ReadWriteSelectorOptions<T> | ReadOnlySelectorOptions<T>,
): RecoilState<T> | RecoilValue<T> {
  const composedOptions = options.cachePolicy_UNSTABLE
    ? options
    : { ...options, cachePolicy_UNSTABLE: DEFAULT_CACHE_POLICY };
  return selector<T>(composedOptions);
}

export function noCacheDefaultSelectorFamily<T, P extends SerializableParam>(
  options: ReadWriteSelectorFamilyOptions<T, P>,
): (param: P) => RecoilState<T>;

export function noCacheDefaultSelectorFamily<T, P extends SerializableParam>(
  options: ReadOnlySelectorFamilyOptions<T, P>,
): (param: P) => RecoilValueReadOnly<T>;

export function noCacheDefaultSelectorFamily<T, P extends SerializableParam>(
  options: ReadWriteSelectorFamilyOptions<T, P> | ReadOnlySelectorFamilyOptions<T, P>,
): (param: P) => RecoilValueReadOnly<T> | RecoilState<T> {
  const composedOptions = options.cachePolicy_UNSTABLE
    ? options
    : { ...options, cachePolicy_UNSTABLE: DEFAULT_CACHE_POLICY };
  return selectorFamily<T, P>(composedOptions);
}
