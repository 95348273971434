import { useContext } from 'react';

import { playButtonDisabledState } from '@audacy-clients/core/atoms/player';
import { useRecoilValue } from 'recoil';

import { ExpandPlayerButton } from '~/components/Player/MiniPlayer/Components/ExpandPlayerButton';
import styles from '~/components/Player/MiniPlayer/styles';
import { PlayerQueueContext } from '~/components/Player/PlayerContextProvider';
import PlayerControls from '~/components/Player/PlayerControls';
import VolumeSlider from '~/components/Player/VolumeSlider';
import { useBreakpoints } from '~/hooks/use-breakpoints';

export const MiniPlayerControls = (): JSX.Element => {
  const isControlsDisabled = useRecoilValue(playButtonDisabledState);
  const controlDisabledStyle = isControlsDisabled ? styles.disabled : {};

  const { greaterThan } = useBreakpoints();
  const { isQueueHead } = useContext(PlayerQueueContext);

  return (
    <>
      <div css={controlDisabledStyle}>
        <PlayerControls />
      </div>

      {greaterThan.MD && (
        <div css={[styles.volumeContainer, controlDisabledStyle]}>
          <VolumeSlider />
        </div>
      )}

      {!isQueueHead && (
        <div css={[styles.expandContainer, controlDisabledStyle]}>
          <ExpandPlayerButton />
        </div>
      )}
    </>
  );
};
