/* eslint-disable @typescript-eslint/no-unused-vars */
import { createMachine, assign, send } from 'xstate';
import { choose } from 'xstate/lib/actions';

import { BrazeUserEvents } from '../@types/braze';
import { AuthState, PlayerAction, PlayerActionTimings } from '../Constants';
import { handleErrorAction } from '../utils';
import { shouldEnablePlaybackRates } from '../utils/playbackRate';
import { Constants, isConsideredPlayed } from '../utils/playbackResumePoints';
import { PLAYBACK_RATES } from './players/constants';
import { PODCAST_MACHINE_ID } from './streamers/PodcastEpisodeMachine';
import { REWIND_MACHINE_ID } from './streamers/RewindStationMachine';
import {
  addTimes,
  getFirstAudioSourceUrl,
  isContentTypeAd,
  msFromTime,
  subtractTimes,
  timeFromMs,
  toIntegerOrUndefined,
} from './streamers/utils';
import {
  ECollectionPlaybackMode,
  ESleepTimerState,
  type IAudioServicesMachineContext,
  type TAudioServicesMachineEvent,
  type TAudioServicesMachineServices,
} from './types';
import {
  findNextPlayableObject,
  findPreviousPlayableObject,
  findStreamer,
  initialize,
  startStreamer,
  shouldIncludeCurrentPosition,
} from './utils';

export const ACTIVE_STREAMER_ID = 'activeStreamer';
const PLAYBACK_TIMER_TICK_INTERVAL_MSEC = 1000;

export const audioServicesMachine = createMachine(
  {
    predictableActionArguments: true,
    preserveActionOrder: true,
    id: 'audioServicesMachine',
    tsTypes: {} as import('./audioServicesMachine.typegen').Typegen0,
    schema: {
      context: {} as IAudioServicesMachineContext,
      events: {} as TAudioServicesMachineEvent,
      services: {} as TAudioServicesMachineServices,
    },
    type: 'parallel',
    states: {
      PLAYBACK_TIMER: {
        initial: 'STOPPED',
        states: {
          STOPPED: {
            id: 'playback_timer_stopped',
            on: {
              REPORT_PLAYING: {
                target: 'RUNNING',
                actions: 'startPlaybackTimer',
              },
            },
          },
          RUNNING: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            after: {
              [PLAYBACK_TIMER_TICK_INTERVAL_MSEC]: {
                target: 'RUNNING',
                actions: choose([
                  {
                    cond: 'shouldSendContinueListen',
                    actions: [
                      'sendContinueListenEvent',
                      'resetPlaybackTimer',
                      'updateInteractionTime',
                    ],
                  },
                  {
                    actions: ['updatePlaybackTimer', 'updateInteractionTime'],
                  },
                ]),
              },
            },
            on: {
              REPORT_PAUSED: {
                target: 'STOPPED',
                actions: 'stopPlaybackTimer',
              },
            },
          },
        },
      },
      MAIN: {
        initial: 'INITIALIZING',
        on: {
          SET_VOLUME: {
            actions: ['assignVolume', 'setVolume', 'persistVolume'],
          },
          SET_MUTED: {
            actions: ['assignMuted', 'setMuted', 'persistMuted'],
          },
          SET_PLAYBACK_RATE: {
            actions: ['assignPlaybackRate', 'setPlaybackRate', 'persistPlaybackRate'],
          },
          INCREMENT_PLAYBACK_RATE: {
            actions: [
              'incrementPlaybackRate',
              'setPlaybackRate',
              'persistPlaybackRate',
              'sendPlaybackRateEvent',
            ],
          },
          SET_SLEEP_TIMER: {
            actions: ['setSleepTimer', 'sendSetSleepTimerEvent'],
          },
          CANCEL_SLEEP_TIMER: {
            actions: ['cancelSleepTimer', 'sendCancelSleepTimerEvent'],
          },
          UPDATE_COLLECTION: {
            actions: 'assignActiveCollection',
          },
          UPDATE_PLAYBACKS: {
            actions: 'assignPlaybacks',
          },
          SET_COLLECTION_PLAYBACK_MODE: {
            actions: 'assignCollectionPlaybackMode',
          },
          SCRUB: {
            actions: 'sendScrubEvent',
          },
        },
        states: {
          INITIALIZING: {
            invoke: {
              id: 'initialize',
              src: 'initialize',
              onDone: [
                {
                  cond: ({ activePlayableObject }) => !!activePlayableObject,
                  actions: ['assignPersonalizationData', 'setVolume', 'setMuted'],
                  target: 'LOADING',
                },
                {
                  actions: ['assignPersonalizationData', 'setVolume', 'setMuted'],
                  target: 'IDLE',
                },
              ],
              onError: {
                target: 'IDLE',
                actions: 'logInitializeErrorToDataDog',
              },
            },
            on: {
              LOAD_OBJECT: {
                actions: ['assignActiveObject', 'assignAutoplay', 'assignOffset', 'setPlayLive'],
              },
            },
          },
          IDLE: {
            on: {
              LOAD_OBJECT: {
                actions: ['assignActiveObject', 'assignAutoplay', 'assignOffset', 'setPlayLive'],
                target: 'LOADING',
              },
            },
          },
          LOADING: {
            entry: ['clearStreamerMachine'],
            invoke: {
              id: 'findStreamer',
              src: 'findStreamer',
              onDone: [
                {
                  cond: 'foundValidStreamer',
                  actions: 'assignStreamerMachine',
                  target: 'STREAMING',
                },
                {
                  target: 'IDLE',
                },
              ],
              onError: {
                target: ['FAILURE', '#playback_timer_stopped'],
                actions: handleErrorAction('LOADING', 'audioServicesMachine'),
              },
            },
          },
          STREAMING: {
            invoke: {
              src: 'startStreamer',
              id: ACTIVE_STREAMER_ID,
              autoForward: true,
              onDone: [
                {
                  target: 'LOADING',
                },
              ],
            },
            on: {
              LOAD_OBJECT: {
                actions: [
                  'assignActiveObject',
                  'assignAutoplay',
                  'assignOffset',
                  'destroyStreamer',
                  'setPlayLive',
                ],
              },
              PLAY: [
                {
                  actions: ['assignAutoplay'],
                },
                {
                  cond: 'isNewDayForGuest',
                  actions: ['resetFreeListeningLimit'],
                },
                {
                  cond: 'isReachedUnauthenticatedListeningLimit',
                  actions: ['pause', 'callBrazeEvent'],
                },
                {
                  cond: 'shouldRespectPlaybackRate',
                  actions: ['assignPlaybackRate', 'setPlaybackRate'],
                },
              ],
              REPORT_ENDED: [
                {
                  cond: 'shouldSleepEndOfEpisode',
                  actions: 'cancelSleepTimer',
                },
                {
                  cond: 'shouldPlayNextObject',
                  actions: [
                    'assignNextPlayableObject', // TODO: [CCS-1382] Optimize so we don't have to do findNextPlayableObject twice?
                    'destroyStreamer',
                  ],
                },
              ],
              REPORT_CONTINUE_PLAYING: [
                {
                  cond: 'shouldPlayNextObject',
                  actions: [
                    'assignNextPlayableObject', // TODO: [CCS-1382] Optimize so we don't have to do findNextPlayableObject twice?
                    'destroyStreamer',
                  ],
                },
              ],
              REPORT_TIME_UPDATE: [
                {
                  cond: 'shouldSleep',
                  actions: ['pause', 'cancelSleepTimer'],
                },
                {
                  cond: 'isReachedUnauthenticatedListeningLimit',
                  actions: ['pause', 'callBrazeEvent'],
                },
                {
                  cond: 'shouldTrackUnauthenticated',
                  actions: ['handleUnauthenticatedData'],
                },
              ],
              NEXT: [
                {
                  cond: (ctx, event) =>
                    !!event.shouldSkipCompletedItems && !!findNextPlayableObject(ctx),
                  actions: ['assignNextPlayableObject', 'destroyStreamer'],
                },
                {
                  cond: ({ activeCollection, activePlayableObject }) =>
                    activeCollection.findIndex(
                      (item) => item.data.id === activePlayableObject?.data.id,
                    ) <
                    activeCollection.length - 1,
                  actions: ['assignNextObject', 'destroyStreamer'],
                },
              ],
              PREVIOUS: [
                {
                  cond: (ctx, event) =>
                    !!event.shouldSkipCompletedItems && !!findPreviousPlayableObject(ctx),
                  actions: ['assignPreviousPlayableObject', 'destroyStreamer'],
                },
                {
                  cond: ({ activeCollection, activePlayableObject }) =>
                    activeCollection.findIndex(
                      (item) => item.data.id === activePlayableObject?.data.id,
                    ) > 0,
                  actions: ['assignPreviousObject', 'destroyStreamer'],
                },
              ],
            },
          },
          FAILURE: {
            onEntry: 'logToDataDog',
            on: {
              LOAD_OBJECT: {
                actions: [
                  'assignActiveObject',
                  'assignAutoplay',
                  'assignOffset',
                  'addToHistory',
                  'setPlayLive',
                ],
                target: 'LOADING',
              },
            },
          },
        },
      },
    },
  },
  {
    guards: {
      shouldPlayNextObject: (ctx) => {
        const { collectionPlaybackMode, activeCollection, activePlayableObject } = ctx;

        if (collectionPlaybackMode === ECollectionPlaybackMode.Playlist) {
          return (
            activeCollection.findIndex((item) => item.data.id === activePlayableObject?.data.id) <
            activeCollection.length - 1
          );
        }
        if (collectionPlaybackMode !== ECollectionPlaybackMode.SingleItem) {
          return !!findNextPlayableObject(ctx);
        }
        return false;
      },
      shouldSleep: ({ sleepTimerState }) =>
        sleepTimerState !== ESleepTimerState.EndOfEpisode &&
        sleepTimerState !== ESleepTimerState.Idle &&
        Date.now() > sleepTimerState,
      shouldTrackUnauthenticated: (ctx) => {
        if (
          !ctx.unauthenticatedProvider ||
          !ctx.featureFlags?.meteredContent ||
          ctx.analyticsProvider?.platform !== 'WEB'
        ) {
          return false;
        }

        // NOTICE: if the user is authorized and unauthenticatedPlaybackTime is greater than 0 then we clear the counter
        if (
          ctx.credentialsProvider?.authData?.state === AuthState.AUTH &&
          !!ctx.unauthenticatedProvider.unauthenticatedPlaybackTime
        ) {
          return true;
        }

        return (
          ctx.credentialsProvider?.authData?.state === AuthState.ANON ||
          ctx.credentialsProvider?.authData?.state === AuthState.NONE
        );
      },
      shouldSleepEndOfEpisode: ({ sleepTimerState }) =>
        sleepTimerState === ESleepTimerState.EndOfEpisode,
      foundValidStreamer: (_, { data }) => !!data,
      shouldSendContinueListen: ({ playbackTimer }) =>
        msFromTime(addTimes(playbackTimer.accumulator, playbackTimer.elapsed)) >
        PlayerActionTimings.CONTINUE_LISTEN_DURATION,
      shouldRespectPlaybackRate: (ctx) => {
        const currentStreamer = ctx.currentStreamerMachine;
        const currentStreamerCtx =
          currentStreamer?.context.dataProvider.audioServices.getStreamerMachineContext();
        const currentStreamerMetadata = currentStreamerCtx.metadata;
        const isAd = isContentTypeAd(currentStreamerMetadata.contentType);
        const isRewind = currentStreamer?.id === REWIND_MACHINE_ID;

        return (
          !isAd &&
          !!ctx.activePlayableObject?.data &&
          shouldEnablePlaybackRates(
            ctx.activePlayableObject.data.entityType,
            ctx.activePlayableObject.data.entitySubtype,
            isRewind,
          )
        );
      },
      isReachedUnauthenticatedListeningLimit: (ctx) => {
        // Checks whether the unauthenticated user has reached the maximum free listening duration.
        if (
          ctx.unauthenticatedProvider &&
          ctx.credentialsProvider?.authData?.state !== AuthState.AUTH &&
          ctx.featureFlags?.meteredContent &&
          ctx.analyticsProvider?.platform === 'WEB'
        ) {
          const { unauthenticatedProvider } = ctx;
          const unauthenticatedPlaybackTime =
            unauthenticatedProvider.getUnauthenticatedPlaybackTime();
          const maxDuration = unauthenticatedProvider.getMaxFreeListeningTime();

          if (maxDuration !== undefined && maxDuration <= unauthenticatedPlaybackTime) {
            return true;
          }
        }
        return false;
      },
      isNewDayForGuest: (ctx) => {
        if (
          ctx.featureFlags?.meteredContent &&
          ctx.analyticsProvider?.platform === 'WEB' &&
          ctx.unauthenticatedProvider &&
          ctx.credentialsProvider?.authData?.state !== AuthState.AUTH
        ) {
          return ctx.unauthenticatedProvider.isNewDay();
        }
        return false;
      },
    },
    actions: {
      resetFreeListeningLimit: (ctx) => {
        if (ctx.unauthenticatedProvider) {
          ctx.unauthenticatedProvider.resetPlayedContentDaily();
        }
      },
      callBrazeEvent: (ctx) => {
        const { personalizationProvider } = ctx;
        // The callBrazeEvent is available only for web. If you want to use it on mobile, you can pass it in the
        // initializeClientServices function on the mobile side, similar to how it's done on the web.
        if (personalizationProvider.callBrazeEvent) {
          personalizationProvider.callBrazeEvent(BrazeUserEvents.METERED_CONTENT_LIMIT_REACHED);
        }
      },
      handleUnauthenticatedData: (ctx) => {
        if (!ctx.unauthenticatedProvider) {
          return;
        }
        const { unauthenticatedProvider } = ctx;
        const isAuthenticated = ctx.credentialsProvider?.authData?.state === AuthState.AUTH;
        const unauthenticatedPlaybackTime = isAuthenticated
          ? 0
          : unauthenticatedProvider.getUnauthenticatedPlaybackTime() + 1;

        unauthenticatedProvider.setUnauthenticatedPlaybackTime(unauthenticatedPlaybackTime);
      },
      addToHistory: (ctx, event) => {
        const id = event.data.getId();
        if (!ctx.personalizationProvider.isInTopOfHistoryStack(id)) {
          ctx.personalizationProvider.addToHistory([id]);
        }
      },
      assignPersonalizationData: assign(
        (_, { data: [activeCollection, playbacks, volume, isMuted, rate] }) => ({
          activeCollection,
          playbacks,
          volume,
          isMuted,
          rate,
        }),
      ),
      setPlayLive: assign({
        playLive: (_, event) => !!event.playLive,
      }),
      clearStreamerMachine: assign((_) => ({
        currentStreamerMachine: undefined,
      })),
      assignStreamerMachine: assign((_, event) => ({
        currentStreamerMachine: event.data,
      })),
      assignActiveObject: assign({
        activePlayableObject: (_, event) => event.data,
      }),
      assignAutoplay: assign({
        autoplay: (_, event) => event.autoplay,
      }),
      assignActiveCollection: assign({
        activeCollection: (_, event) => event.data,
      }),
      assignPlaybacks: assign({
        playbacks: (_, event) => event.data,
      }),
      assignNextObject: assign(({ activeCollection, activePlayableObject, playbacks }) => {
        const currentPlayingIndex = activeCollection.findIndex(
          (item) => item.data.id === activePlayableObject?.data.id,
        );

        if (currentPlayingIndex === -1) {
          return {
            activePlayableObject: undefined,
            startOffset: undefined,
          };
        }

        // Iterate to find the next track that has not been fully played
        let nextIndex = currentPlayingIndex;
        while (++nextIndex < activeCollection.length) {
          const nextObject = activeCollection[nextIndex];
          if (!isConsideredPlayed(nextObject.data.durationSeconds, playbacks[nextObject.getId()])) {
            const nextObjectResumePoint =
              nextObject.data.durationSeconds &&
              playbacks[nextObject.getId()] < nextObject.data.durationSeconds
                ? playbacks[nextObject.getId()]
                : 0;

            return {
              activePlayableObject: nextObject,
              startOffset: nextObjectResumePoint,
            };
          }
        }

        // If no unplayed track is found, return the current object with startOffset 0
        return {
          activePlayableObject: activePlayableObject,
          startOffset: 0,
        };
      }),
      assignPreviousObject: assign(({ activeCollection, activePlayableObject, playbacks }) => {
        const currentPlayingIndex = activeCollection.findIndex(
          (item) => item.data.id === activePlayableObject?.data.id,
        );

        if (currentPlayingIndex <= 0) {
          return {
            activePlayableObject: undefined,
            startOffset: undefined,
          };
        }

        // Iterate to find the previous track that has not been fully played
        let previousIndex = currentPlayingIndex;
        while (--previousIndex >= 0) {
          const previousObject = activeCollection[previousIndex];
          if (
            !isConsideredPlayed(
              previousObject.data.durationSeconds,
              playbacks[previousObject.getId()],
            )
          ) {
            const previousObjectResumePoint =
              previousObject.data.durationSeconds &&
              playbacks[previousObject.getId()] < previousObject.data.durationSeconds
                ? playbacks[previousObject.getId()]
                : 0;

            return {
              activePlayableObject: previousObject,
              startOffset: previousObjectResumePoint,
            };
          }
        }

        // If no unplayed track is found, return the current object with startOffset 0
        return {
          activePlayableObject: activePlayableObject,
          startOffset: 0,
        };
      }),
      assignNextPlayableObject: assign((ctx) => {
        const nextPlayableObject = findNextPlayableObject(ctx);

        if (nextPlayableObject == undefined) {
          return {
            activePlayableObject: undefined,
            startOffset: undefined,
          };
        }
        const nextPlayableObjectResumePoint =
          nextPlayableObject.data.durationSeconds &&
          ctx.playbacks[nextPlayableObject.getId()] < nextPlayableObject.data.durationSeconds
            ? ctx.playbacks[nextPlayableObject.getId()]
            : undefined;

        return {
          activePlayableObject: nextPlayableObject,
          startOffset: nextPlayableObjectResumePoint,
        };
      }),
      assignPreviousPlayableObject: assign((ctx) => {
        const previousPlayableObject = findPreviousPlayableObject(ctx);
        if (previousPlayableObject == undefined) {
          return {
            activePlayableObject: undefined,
            startOffset: undefined,
          };
        }
        const previousPlayableObjectResumePoint =
          previousPlayableObject.data.durationSeconds &&
          ctx.playbacks[previousPlayableObject.getId()] <
            previousPlayableObject.data.durationSeconds
            ? ctx.playbacks[previousPlayableObject.getId()]
            : undefined;

        return {
          activePlayableObject: previousPlayableObject,
          startOffset: previousPlayableObjectResumePoint,
        };
      }),
      assignCollectionPlaybackMode: assign((_, event) => ({
        collectionPlaybackMode: event.mode,
      })),
      destroyStreamer: send({ type: 'DESTROY' }, { to: ACTIVE_STREAMER_ID }),
      logToDataDog: (ctx: IAudioServicesMachineContext) => {
        const { activePlayableObject, ddError } = ctx;
        const data = JSON.stringify(activePlayableObject?.data);

        void ddError(`Audio Services Machine Failure No Streamer Found: ${data}`);
      },
      logInitializeErrorToDataDog: (ctx: IAudioServicesMachineContext, error) => {
        const { activePlayableObject, ddError, currentStreamerMachine } = ctx;
        const data = JSON.stringify(activePlayableObject?.data);

        void ddError(
          // TODO: Revisit `currentStreamerMachine?.toString()` with better serialization for logging
          `Error in initialize of audioServicesMachine, activePlayableObject: ${data}, currentStreamerMachine: ${
            currentStreamerMachine?.toString() ?? ''
          }, error: ${JSON.stringify(error.data)}`,
        );
      },
      setVolume: (context) => context?.player?.setVolume(context.volume),
      setMuted: (context) => context?.player?.setMuted(context.isMuted),
      setPlaybackRate: (context) => context?.player?.setRate(context.rate),
      assignVolume: assign({
        volume: (_, event) => event.volume,
      }),
      assignMuted: assign({
        isMuted: (_, event) => event.isMuted,
      }),
      assignPlaybackRate: assign({
        rate: (context, event) => {
          const currentStreamer = context.currentStreamerMachine;
          const isRewind = currentStreamer?.id === REWIND_MACHINE_ID;

          if (!context.player.vodFeatureEnabled && isRewind) {
            return 1;
          }

          return event.type === 'SET_PLAYBACK_RATE' ? event.rate : context.rate;
        },
      }),
      persistVolume: (context) => context.playerSettingsProvider.setVolume(context.volume),
      persistMuted: (context) => context.playerSettingsProvider.setIsMuted(context.isMuted),
      persistPlaybackRate: (context) =>
        context.playerSettingsProvider.setPlaybackRate(context.rate),
      incrementPlaybackRate: assign({
        rate: ({ rate: currentRate }) => {
          const currentIndex = PLAYBACK_RATES.indexOf(currentRate);
          const newIndex = (currentIndex + 1) % PLAYBACK_RATES.length;
          return PLAYBACK_RATES[newIndex];
        },
      }),

      sendSetSleepTimerEvent: ({ analyticsProvider, activePlayableObject, sleepTimerState }) => {
        let endOfEpisode = false;
        let duration = 0;

        const isIdle = sleepTimerState === ESleepTimerState.Idle;
        const isEnd = sleepTimerState === ESleepTimerState.EndOfEpisode;

        if (!isIdle) {
          if (isEnd) {
            endOfEpisode = true;
          } else {
            duration = Math.ceil((Number(sleepTimerState) - Date.now()) / 1000);
          }

          analyticsProvider?.sendSleepTimerSetEvent(
            PlayerAction.SLEEP_TIMER_SET,
            activePlayableObject?.data.id,
            endOfEpisode,
            duration,
          );
        }
      },
      setSleepTimer: assign({
        sleepTimerState: (_, event) => event.sleepTimer,
      }),
      cancelSleepTimer: assign({
        sleepTimerState: (_) => ESleepTimerState.Idle,
      }),
      pause: send('PAUSE'),
      assignOffset: assign({
        startOffset: (context, event) => {
          if (event.offset) {
            return event.offset;
          } else if (
            context.playbacks[event.data.getId()] <
            event.data.data.durationSeconds * Constants.isConsideredPlayedEpsilon
          ) {
            return context.playbacks[event.data.getId()];
          }
          // If there is a resume point but it's >= duration of that episode (times some epsilon), then start over at the beginning
          return undefined;
        },
      }),
      startPlaybackTimer: assign(({ playbackTimer }) => ({
        playbackTimer: {
          ...playbackTimer,
          lastStartedTime: timeFromMs(Date.now()),
        },
      })),
      stopPlaybackTimer: assign(({ playbackTimer }) => ({
        playbackTimer: {
          lastStartedTime: playbackTimer.lastStartedTime,
          elapsed: timeFromMs(0),
          accumulator:
            playbackTimer.lastStartedTime !== undefined
              ? subtractTimes(
                  addTimes(playbackTimer.accumulator, timeFromMs(Date.now())),
                  playbackTimer.lastStartedTime,
                )
              : playbackTimer.accumulator,
        },
      })),
      resetPlaybackTimer: assign({
        playbackTimer: () => ({
          accumulator: timeFromMs(0),
          elapsed: timeFromMs(0),
          lastStartedTime: timeFromMs(Date.now()),
        }),
      }),
      updatePlaybackTimer: assign(({ playbackTimer }) => ({
        playbackTimer: {
          ...playbackTimer,
          elapsed:
            playbackTimer.lastStartedTime !== undefined
              ? subtractTimes(timeFromMs(Date.now()), playbackTimer.lastStartedTime)
              : playbackTimer.elapsed,
        },
      })),
      sendContinueListenEvent: (context) => {
        const audioRoute = context.deviceInfoProvider?.getAudioRoute();

        let currentPosition;
        const includeCurrentPosition = shouldIncludeCurrentPosition(
          context.currentStreamerMachine?.id,
        );
        if (includeCurrentPosition) {
          currentPosition = toIntegerOrUndefined(
            context.playbacks[context.activePlayableObject?.data.id] || 0,
          );
        }

        context.analyticsProvider?.sendPlayerEvent({
          type: PlayerAction.CONTINUE_LISTEN,
          contentId: context.activePlayableObject?.data.id,
          currentPosition,
          streamUrl: getFirstAudioSourceUrl(context.activePlayableObject?.data.streamUrl),
          connectionType: audioRoute,
        });
      },
      sendScrubEvent: ({
        analyticsProvider,
        activePlayableObject,
        deviceInfoProvider,
        playbacks,
        currentStreamerMachine,
      }) => {
        const audioRoute = deviceInfoProvider?.getAudioRoute();

        let currentPosition;
        const includeCurrentPosition = shouldIncludeCurrentPosition(currentStreamerMachine?.id);
        if (includeCurrentPosition) {
          currentPosition = toIntegerOrUndefined(playbacks[activePlayableObject?.data.id] || 0);
        }

        analyticsProvider?.sendPlayerEvent({
          type: PlayerAction.SCRUB,
          contentId: activePlayableObject?.data.id,
          currentPosition,
          streamUrl: getFirstAudioSourceUrl(activePlayableObject?.data.streamUrl),
          connectionType: audioRoute,
        });
      },
      sendCancelSleepTimerEvent: ({
        analyticsProvider,
        activePlayableObject,
        deviceInfoProvider,
        playbacks,
        currentStreamerMachine,
      }) => {
        const audioRoute = deviceInfoProvider?.getAudioRoute();

        let currentPosition;
        const includeCurrentPosition = shouldIncludeCurrentPosition(currentStreamerMachine?.id);
        if (includeCurrentPosition) {
          currentPosition = toIntegerOrUndefined(playbacks[activePlayableObject?.data.id] || 0);
        }

        analyticsProvider?.sendPlayerEvent({
          type: PlayerAction.SLEEP_TIMER_CANCELED,
          contentId: activePlayableObject?.data.id,
          currentPosition,
          streamUrl: getFirstAudioSourceUrl(activePlayableObject?.data.streamUrl),
          connectionType: audioRoute,
        });
      },
      sendPlaybackRateEvent: (ctx) => {
        const { analyticsProvider, activePlayableObject, rate, featureFlags } = ctx;
        const isEnabled = featureFlags?.vodSpeedControls;
        const speedSelection = isEnabled ? rate.toString() : 'disabled';
        const audioRoute = ctx.deviceInfoProvider?.getAudioRoute();

        let currentPosition;
        const includeCurrentPosition = shouldIncludeCurrentPosition(ctx.currentStreamerMachine?.id);
        if (includeCurrentPosition) {
          currentPosition = toIntegerOrUndefined(ctx.playbacks[activePlayableObject?.data.id] || 0);
        }

        analyticsProvider?.sendPlayerEvent({
          type: PlayerAction.PLAYBACK_SPEED,
          contentId: activePlayableObject?.data.id,
          speedSelection,
          currentPosition,
          streamUrl: getFirstAudioSourceUrl(activePlayableObject?.data.streamUrl),
          connectionType: audioRoute,
        });
      },
      updateInteractionTime: ({ dataProvider }) => dataProvider.updateInteractionTime(),
    },
    services: {
      initialize,
      findStreamer,
      startStreamer,
    },
  },
);
