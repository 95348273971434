import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import Button from '~/components/Button';
import Keys from '~/constants/keys';
import LayoutConstants from '~/constants/layout';
import { LinkType } from '~/types/links';
import { getFocusableChildren } from '~/utils/dom';

import styles from './styles';

const SkipToMainContent = (): JSX.Element => {
  const { t } = useTranslation();

  const skipToMain = useCallback(() => {
    const mainContainer = document.querySelector(`#${LayoutConstants.mainContentId}`);
    if (mainContainer) {
      const focusable = getFocusableChildren(mainContainer);
      if (focusable.length > 0 && focusable[0] instanceof HTMLElement) {
        focusable[0].focus();
      }
    }
  }, []);

  const onKeyUp = useCallback(
    ({ key }: React.KeyboardEvent<HTMLButtonElement>) => {
      if (key === Keys.Enter) skipToMain();
    },
    [skipToMain],
  );

  return (
    <div css={styles.skipToMainContentContainer}>
      <Button
        as={LinkType.Anchor}
        buttonCss={styles.skipToMainContentButton}
        label={t('navigation.skipToMainContent')}
        onClick={skipToMain}
        onKeyUp={onKeyUp}
      />
    </div>
  );
};

export default SkipToMainContent;
