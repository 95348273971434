export const controlsVariants = {
  visible: {
    opacity: 1,
    height: 'auto',
    transition: { delay: 0.3 },
  },
  hidden: {
    opacity: 0,
    height: 0,
  },
};

export const itemVariants = {
  visible: {
    opacity: 1,
    width: 'auto',
    height: 'auto',
    marginBottom: 15,
  },
  hidden: { opacity: 0, height: 1, marginBottom: 0 },
};
