import { LinkMenuItemType } from '@audacy-clients/client-services';
import { useTranslation } from 'react-i18next';

import { Icons } from '~/components/Icon/constants';
import OptionsMenuItem from '~/components/OptionsMenu/components/OptionsMenuItem';
import { IOptionsMenuItemAnchorProps } from '~/components/OptionsMenu/types';
import { LinkType } from '~/types/links';

const LinkMenuItem = (
  props: { linkType: LinkMenuItemType } & Partial<IOptionsMenuItemAnchorProps>,
): JSX.Element => {
  const { t } = useTranslation();
  const { href, isExternal, linkType, text, icon } = props;

  let linkText;

  switch (linkType) {
    case LinkMenuItemType.EPISODE_DETAILS:
      linkText = t('optionsMenu.episodeDetails');
      break;
    case LinkMenuItemType.EPISODE_GO_TO:
      linkText = t('optionsMenu.goToEpisode');
      break;
    case LinkMenuItemType.SHOW_GO_TO:
      linkText = t('optionsMenu.goToShow');
      break;
    case LinkMenuItemType.PODCAST:
      linkText = t('optionsMenu.goToPodcast');
      break;
    case LinkMenuItemType.STATION:
      linkText = t('optionsMenu.goToStationWebsite');
      break;
    case LinkMenuItemType.TOPIC:
      linkText = t('optionsMenu.goToTopic', { text });
      break;
  }

  const defaultIcon = isExternal ? Icons.Website : Icons.GotoCircle;

  return (
    <OptionsMenuItem
      as={LinkType.Anchor}
      isDisabled={!href}
      isExternal={isExternal}
      href={href}
      icon={icon || defaultIcon}
      onClick={props.onClick}
      text={linkText || ''}
    />
  );
};

export default LinkMenuItem;
