import Episode from './Episode';
import PagedList from './PagedList';
import { ObjectType } from '../Constants';

/**
 * @module EpisodeList
 */
export default class EpisodeList extends PagedList {
  /**
   * Creates a new instance of a List.
   * @param {string}                 version
   * @param {object}                 data
   * @param {DataServices}           dataService
   * @param {PagedListQueryFunction} [queryFunction=undefined]
   * @param {Boolean}                [fillHoles=false]
   */
  constructor(version, data, dataService, queryFunction = undefined, fillHoles = false) {
    super(version, data, dataService, ObjectType.EPISODE_LIST, queryFunction);

    if (fillHoles === true) {
      const createDummyEpisode = (startTime, endTime, stationId) => {
        return {
          durationSeconds: endTime - startTime,
          endDateTime: new Date(endTime * 1000).toISOString(),
          endDateTimeSeconds: endTime,
          entitySubtype: 'BROADCAST_SHOW_EPISODE',
          entityType: 'EPISODE',
          id: '',
          isOnAirOnly: false,
          parentImage: {},
          parentStationIds: [stationId],
          parentTitle: 'Not Available',
          publishDate: '',
          publishDateSeconds: null,
          replayableUntilDateTime: new Date(startTime * 1000).toISOString(),
          replayableUntilDateTimeSeconds: startTime,
          showContentId: '',
          startDateTime: new Date(startTime * 1000).toISOString(),
          startDateTimeSeconds: startTime,
          title: '',
        };
      };

      // make sure there is at least an episode for now

      if (this.data.length === 0) {
        const start = new Date();

        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);

        const end = new Date(start.getTime() + 2 * 60 * 60 * 1000);

        this.data.push(createDummyEpisode(start.getTime() / 1000, end.getTime() / 1000, data.id));
      }

      // remove all the isOnAirOnly entries and save in a separate list

      this.onAirOnly = [];
      this.dupes = 0;
      this.holes = 0;
      this.overlaps = 0;

      for (let i = this.data.length - 1; i >= 0; i--)
        if (this.data[i].isOnAirOnly === true) this.onAirOnly.push(this.data.splice(i, 1)[0]);

      for (let i = 0; i < this.data.length; i++) {
        const endTime = this.getEndTime(i);
        const fract = endTime % 60;

        if (fract === 59) {
          this.data[i].durationSeconds += 1;
          this.data[i].endDateTimeSeconds += 1;
          this.data[i].endDateTime = new Date(this.data[i].endDateTimeSeconds * 1000).toISOString();
        }
      }

      for (let i = 0; i < this.onAirOnly.length; i++) {
        const endTime = this.onAirOnly[i].endDateTimeSeconds;
        const fract = endTime % 60;

        if (fract === 59) {
          this.onAirOnly[i].durationSeconds += 1;
          this.onAirOnly[i].endDateTimeSeconds += 1;
          this.onAirOnly[i].endDateTime = new Date(
            this.onAirOnly[i].endDateTimeSeconds * 1000,
          ).toISOString();
        }
      }

      for (let i = this.data.length - 2; i >= 0; i--) {
        const firstStart = this.data[i].startDateTimeSeconds;
        const firstEnd = this.data[i].endDateTimeSeconds;
        const secondStart = this.data[i + 1].startDateTimeSeconds;
        const secondEnd = this.data[i + 1].endDateTimeSeconds;

        if (firstStart === secondStart && firstEnd === secondEnd) {
          this.dupes++;
          this.data[i].dupId = this.data[i + 1].id;
          this.data.splice(i + 1, 1);
        }
      }

      for (let i = this.data.length - 2; i >= 0; i--) {
        const startTime = this.getStartTime(i);
        const endTime = this.getEndTime(i + 1);

        if (startTime !== endTime) {
          if (startTime > endTime) {
            this.holes++;
            this.data.splice(i + 1, 0, createDummyEpisode(endTime, startTime, data.id));
          } else this.overlaps++;
        }
      }

      let passed = true;

      for (let i = 0; i < this.data.length - 1; i++) {
        if (this.getStartTime(i) !== this.getEndTime(i + 1)) {
          passed = false;
        }
      }

      while (this.onAirOnly.length > 0) this.insert(this.onAirOnly.shift());
    }
  }

  /**
   * Get the end time of the episode in floating point seconds since the epoch.
   * @param {Number} index
   * @returns {Number}
   */
  getEndTime(index) {
    return this.data[index].endDateTimeSeconds;
  }

  /**
   * Get the episode associated with the specified time.
   * @param {Number} time epoch time in ms
   * @returns {Promise<Episode>}
   */
  getEpisode(time) {
    let retval;

    if (this.data.length > 0) {
      const onEpisode = (episode) => {
        return episode;
      };

      const secs = time / 1000;

      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].startDateTimeSeconds <= secs && secs < this.data[i].endDateTimeSeconds) {
          if (
            this.data[i].isOnAirOnly === true &&
            Date.now() / 1000 > this.data[i].endDateTimeSeconds
          ) {
            const next = this.data[i + 1];
            const prev = this.data[i - 1];

            if (next.startDateTimeSeconds <= secs && secs < next.endDateTimeSeconds)
              retval = this.getObject(i + 1).then(onEpisode);
            else if (prev.startDateTimeSeconds <= secs && secs < prev.endDateTimeSeconds)
              retval = this.getObject(i - 1).then(onEpisode);
            else retval = this.getObject(i).then(onEpisode);
          } else retval = this.getObject(i).then(onEpisode);
          break;
        }
      }
    }

    return retval ? retval : this.getObject(this.data.length - 1);
  }

  /**
   * Returns the number of floating point seconds since the epoch
   * @returns {Number}
   */
  getReplayableUntil(index) {
    return this.data[index].replayableUntilDateTimeSeconds;
  }

  /**
   * Returns the number of milliseconds since the epoch
   * @returns {Number}
   */
  getReplayableUntilMillis(index) {
    return this.data[index].replayableUntilDateTimeSeconds * 1000;
  }

  /**
   * Get the start time of the episode in floating point seconds since the epoch.
   * @param {Number} index
   * @returns {Number}
   */
  getStartTime(index) {
    return this.data[index].startDateTimeSeconds;
  }

  /**
   * Get the episode title for specified index.
   * @param {Number} index
   * @returns {String}
   */
  getTitle(index) {
    return this.data[index].title;
  }

  insert(ep) {
    for (let i = 0; i < this.data.length; i++) {
      if (ep.startDateTimeSeconds >= this.data[i].startDateTimeSeconds) {
        this.data.splice(i, 0, ep);
        break;
      }
    }
  }
}
