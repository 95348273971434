export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>People</title>
    <circle cx="16" cy="11" r="6" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M27 27v-6H5v6" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
  </svg>
);
