import { Variants } from 'framer-motion';

export const reduceVariantMotion = (
  variants: Variants,
  shouldReduceMotion: boolean | null,
): Variants => {
  if (shouldReduceMotion) {
    const newVariants = { ...variants };
    Object.entries(newVariants).forEach(([key, value]) => {
      newVariants[key] = {
        ...value,
        transition: {
          delay: 0,
          duration: 0,
        },
      };
    });

    return newVariants;
  }

  return variants;
};
