import { EpisodeSubType, ObjectType } from '../Constants';
import Container from '../Container';
import { cache, shouldUseCache } from '../utils/index';
import DataObject from './DataObject';
import StationSummary from './StationSummary';

const serviceBus = Container;

/** @typedef { import('../Constants').Image       } Image */
/** @typedef { import('./Station').default } Station */
/** @typedef { import('./types').Transcript } Transcript */

/**
 * @module Episode
 */
export default class Episode extends DataObject {
  /**
   * Creates a new instance of an Episode.
   * @param {Object}   data
   * @param {Object}  [data.chatChannels]
   * @param {String}  [data.chatChannels.public]
   * @param {String}  [data.chatChannels.questions]
   * @param {Number}  [data.description]
   * @param {Number}  [data.durationSeconds]
   * @param {Number}   data.end
   * @param {String}  [data.endDateTime]
   * @param {Number}  [data.endDateTimeSeconds]
   * @param {String}  [data.endTime]
   * @param {String}   data.entitySubtype
   * @param {String}   data.entityType
   * @param {String}   data.id
   * @param {String}  [data.image]
   * @param {Boolean} [data.isOnAirOnly]
   * @param {String}  [data.metaDescription]
   * @param {String}  [data.metaTitle]
   * @param {Array}   [data.parentStationIds]
   * @param {String}  [data.parentTitle]
   * @param {Image}   [data.parentImage]
   * @param {Object}  [data.parentShow]
   * @param {String}  [data.parentShow.url]
   * @param {Object}  [data.parentStation]
   * @param {Array}   [data.podcastName]
   * @param {String}  [data.publishDate]
   * @param {Number}  [data.publishDateSeconds]
   * @param {String}  [data.replayableUntilDateTime]
   * @param {Number}  [data.replayableUntilDateTimeSeconds]
   * @param {Number}  [data.start]
   * @param {String}  [data.showContentId]
   * @param {String}  [data.startDateTime]
   * @param {Number}  [data.startDateTimeSeconds]
   * @param {String}  [data.startTime]
   * @param {Object}  [data.streamUrls]
   * @param {Object}  [data.streamUrls.default]
   * @param {String}  [data.streamUrls.m3u8]
   * @param {String}  [data.streamUrls.mp3]
   * @param {String}  [data.title]
   * @param {String}  [data.url]
   * @param {Transcript} [data.transcript]
   */
  constructor(data) {
    super(ObjectType.EPISODE, data);

    if (data.entitySubtype === EpisodeSubType.BROADCAST_SHOW_EPISODE) {
      if (data.startDateTimeSeconds === undefined) {
        data.startDateTimeSeconds = new Date(data.startDateTime).getTime() / 1000;
      }

      if (data.endDateTimeSeconds === undefined) {
        if (data.endDateTime === undefined) {
          data.endDateTimeSeconds = data.startDateTimeSeconds + data.durationSeconds;
          data.endDateTime = new Date(data.endDateTimeSeconds * 1000).toISOString();
        } else {
          data.endDateTimeSeconds = new Date(data.endDateTime).getTime() / 1000;
        }
      }
    }

    DataObject.cleanJsonObject(this.data);
  }

  /**
   * Get the audio url associated with the episode.
   * @returns {String}
   */
  getAudioStream() {
    return this.data.streamUrl && (this.data.streamUrl.default || this.data.streamUrl.m3u8); // TODO: [CCS-2792] remove m3u8 when switched to default
  }

  /**
   * @param {Object}
   * @param {number}
   * @param {boolean}
   * @returns {Promise<Array<Chapter>>}
   */
  async getChapters(param = {}) {
    const id = this.getId();
    const start = this.getStartTimeSeconds();
    const isRewindable = this.isRewindable();

    const { playback = 0, broadcastChapters } = param;

    if (!isRewindable || !id) {
      return Promise.resolve([]);
    }

    let chapters = cache.get(id) || [];
    let quickPauseChapters = cache.get(`${id}-quickPause`);

    // if you pause and play within 60 seconds return existing chapters
    if (quickPauseChapters) {
      return quickPauseChapters;
    }

    const liveBroadcastEnded = this.getEndTimeMillis() < Date.now();

    // if your chapter is after the beginning of the show, but before the end of the
    // last known chapter then return the cache
    const isUseCache = shouldUseCache(playback, chapters, start, liveBroadcastEnded);
    if (isUseCache && chapters.length > 0) {
      return chapters;
    }

    chapters = await serviceBus.dataServices.getChapters(id, broadcastChapters);
    cache.set(id, chapters);
    cache.clean();
    return chapters || null;
  }

  /**
   * @returns {String}
   */
  getChatChannel() {
    return this.data.chatChannels?.public;
  }

  /**
   * Get the episode description.
   * @returns {String}
   */
  getDescription() {
    return this.data.description;
  }

  /**
   * Get the episode duration.
   * @returns {Number}
   */
  getDuration() {
    return this?.data?.durationSeconds || 0;
  }

  /**
   * Get the episode end time.
   * @returns {String}
   */
  getEndTime() {
    return this.data.endDateTime;
  }

  /**
   * @returns {Number}
   */
  getEndTimeMillis() {
    return this.data.endDateTimeSeconds * 1000;
  }

  /**
   * @returns {Number}
   */
  getEndTimeSeconds() {
    return this.data.endDateTimeSeconds;
  }

  /**
   * Get the text description of the image associated with the entity.
   * @returns {String}
   */
  getImageAlt() {
    return this.data.images && this.data.images.alt;
  }

  /**
   * Get the square show image.
   * @returns {String}
   */
  getImageSquare() {
    return this.data.images && this.data.images.square;
  }

  /**
   * @returns {Boolean}
   */
  getIsOnAirOnly() {
    return this.data.isOnAirOnly;
  }

  /**
   * Get the meta description.
   * @returns {String}
   */
  getMetaDescription() {
    return this.data.metaDescription;
  }

  /**
   * Get the meta title.
   * @returns {String}
   */
  getMetaTitle() {
    return this.data.metaTitle;
  }

  /**
   * Get contentId of parent show.
   * @returns {String}
   */
  getParentId() {
    return this.data.showContentId;
  }

  /**
   * Get the text description of the image associated with the entity parent.
   * @returns {String}
   */
  getParentImageAlt() {
    return this.data.parentImage && this.data.parentImage.alt;
  }

  /**
   * Get the parent square show image.
   * @returns {String}
   */
  getParentImageSquare() {
    return this.data.parentImage && this.data.parentImage.square;
  }

  /**
   * Get the parent station IDs.
   * @returns {Array<String>}
   */
  getParentStationIds() {
    return this.data.parentStationIds;
  }

  /**
   * Get the episode parent show.
   * @returns {Object|undefined}
   */
  getParentShow() {
    return this.data.parentShow;
  }

  /**
   * Get the episode parent show id.
   * @returns {string|undefined}
   */
  getParentShowId() {
    return this.data?.parentShow?.id;
  }

  /**
   * Get the episode parent station.
   * @returns {StationSummary | undefined}
   */
  getParentStation() {
    return this.data.parentStation && new StationSummary(this.data.parentStation);
  }

  /**
   * Get the episode parent name.
   * @returns {String}
   */
  getParentTitle() {
    return this.data.parentTitle;
  }

  /**
   * Get the episode publish date.
   * @returns {String}
   */
  getPublishDate() {
    return this.data.publishDate;
  }

  /**
   * Get the episode publish date.
   * @returns {Number}
   */
  getPublishDateMillis() {
    return this.data.publishDateSeconds * 1000;
  }

  /**
   * @returns {String}
   */
  getQuestionsChannel() {
    return this.data.chatChannels?.questions;
  }

  /**
   * @returns {String}
   */
  getReplayableUntil() {
    return this.data.replayableUntilDateTime;
  }

  /**
   * @returns {Number}
   */
  getReplayableUntilMillis() {
    return this.data.replayableUntilDateTimeSeconds * 1000;
  }

  /**
   * Get the episode start time.
   * @returns {String}
   */
  getStartTime() {
    return this.data.startDateTime;
  }

  /**
   * @returns {Number}
   */
  getStartTimeMillis() {
    return this.data.startDateTimeSeconds * 1000;
  }

  /**
   * @returns {Number}
   */
  getStartTimeSeconds() {
    return this.data.startDateTimeSeconds;
  }

  /**
   * @returns {Transcript}
   */
  getTranscript() {
    return this.data.transcript;
  }

  /**
   * @returns {Boolean}
   */
  isRewindable() {
    return Episode.isRewindable(this.data);
  }

  /**
   * Whether the episode is airing live and not pre-recorded
   * @returns {Boolean}
   */
  isLiveOnAir() {
    const now = new Date().getTime();
    return this.getEndTimeMillis() > now;
  }

  /**
   * @returns {Boolean}
   */
  static isRewindable(data) {
    if (!data.replayableUntilDateTimeSeconds && !data.startDateTimeSeconds) {
      return true;
    }
    const untilTime = data.replayableUntilDateTimeSeconds * 1000;
    const startTime = data.startDateTimeSeconds * 1000;
    const now = Date.now();

    return untilTime > startTime && now > startTime && now < untilTime;
  }
}
