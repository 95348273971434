export default (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Queue</title>
    <line
      x1="3.75"
      y1="5.0625"
      x2="20.25"
      y2="5.0625"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <line
      x1="3.75"
      y1="11.8125"
      x2="10.5"
      y2="11.8125"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <line
      x1="3.75"
      y1="18.5625"
      x2="10.125"
      y2="18.5625"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <path d="M22.5 15.375L15.75 20.25L15.75 10.5L22.5 15.375Z" fill="currentColor" />
  </svg>
);
