import { ClickFeature } from '@audacy-clients/client-services/core';
import { IEpisode } from '@audacy-clients/core/atoms/wrappers/types';

import { usePlayableCardProps } from '~/components/Entity/HorizontalEntityCard/hooks/use-playable-card-props';
import useWebQueue from '~/hooks/use-web-queue';
import useAuth from '~/state/auth';
import { useNavClickEvent } from '~/utils/analytics';
import { isPlayable } from '~/utils/card';
import { usePlayOrPause } from '~/utils/hooks/usePlayOrPause';

import EpisodeItem from './EpisodeItem';

interface IEpisodeItemWithStateProps {
  episode: IEpisode;
  onPlayClick?: (id?: string) => void;
  hasOptionsMenu?: boolean;
}

const EpisodeItemWithState = ({
  episode,
  onPlayClick,
  hasOptionsMenu,
}: IEpisodeItemWithStateProps): JSX.Element => {
  const { isLoggedIn } = useAuth();
  const { add, contains, remove } = useWebQueue();

  const playOrPause = usePlayOrPause(episode.id);

  const trackNavigation = useNavClickEvent({
    contentId: episode.id,
    entityType: episode.type,
    entitySubtype: episode.subtype,
    feature: ClickFeature.LIST_ITEM,
  });

  const playContext = {
    feature: ClickFeature.LIST_ITEM,
  };

  const { id } = episode;
  const isInQueue = contains(id);

  const handleQueueClick = () => {
    if (isInQueue) {
      remove([id]);
    } else {
      add(id);
    }
  };

  const {
    isPlayed,
    isPlaying,
    onPlayStateClick,
    resumePoint,
    isScrubbing,
    isNowPlaying,
    nowPlayingDuration,
  } = usePlayableCardProps({
    isPlayable: true,
    item: {
      id: episode.id,
      entityType: episode.entityType,
      entitySubtype: episode.entitySubtype,
      startDateTime: episode.startDateTime,
      duration: episode.duration,
    },
    playContext,
    playOrPause,
  });

  return (
    <EpisodeItem
      duration={isNowPlaying ? nowPlayingDuration : episode.duration}
      episode={episode}
      isInQueue={isInQueue}
      isLoggedIn={isLoggedIn}
      isPlayed={isPlayed}
      isPlaying={isPlaying}
      onClick={trackNavigation}
      onPlayStateClick={onPlayClick || onPlayStateClick}
      onQueueClick={handleQueueClick}
      resumePoint={resumePoint}
      isScrubbing={isScrubbing}
      hasOptionsMenu={hasOptionsMenu}
      isPlayable={isPlayable(episode.type)}
    />
  );
};

export default EpisodeItemWithState;
