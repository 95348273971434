export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Arrow down</title>
    <path
      d="M4.852 17.652 16 28.8l11.148-11.148M16 28.8V3.2"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);
