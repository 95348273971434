import { AuthState, LocalStorageKey } from '@audacy-clients/client-services/core';
import { isLoggedIn, isSoftDeletedState, setAuthCallback } from '@audacy-clients/core/atoms/auth';
import { clientSettings } from '@audacy-clients/core/atoms/config/settings';
import { getIncompleteProfileData } from '@audacy-clients/core/atoms/profile';
import { useClientServices } from '@audacy-clients/core/utils/clientServices';
import * as braze from '@braze/web-sdk';

import Page from 'components/Page';
import FullscreenPlayer from 'components/Player/FullscreenPlayer';
import { Suspense, lazy, useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { appInitializationState } from '~/atoms/app';
import { shouldDisplaySquareAd } from '~/atoms/googleAds';
import { initBraze, initClientServices, initOneTrust } from '~/backend';
import FacebookPixel from '~/components/FacebookPixel';
import SkipToMainContent from '~/components/Layout/SkipToMainContent';
import LiveRegions from '~/components/LiveRegions';
import NavigationContainer from '~/components/Navigation/NavigationContainer';
import MiniPlayer from '~/components/Player/MiniPlayer';
import PlayerContextProvider from '~/components/Player/PlayerContextProvider';
import MostRecentlyPlayedStateConnector from '~/components/StateConnectors/MostRecentlyPlayedStateConnector';
import ToastWithState from '~/components/Toast';
import { useBrazeLocationPrompt } from '~/hooks/use-braze-location-prompt';
import { useLocationPermission } from '~/hooks/use-location-permission';
import useWindowSize from '~/hooks/use-window-size';
import { Path, pathsWithoutNav } from '~/routes';
import { navigationPathState } from '~/state/navigation';
import { disableBraze } from '~/utils/braze';
import { env } from '~/utils/env';
import { destroyAdSlots, setAdRefreshRate } from '~/utils/googleAds';
import { gtmParams, GTMProvider } from '~/utils/gtm';
import { initDataDog, setUserToDataDog } from '~/utils/monitoring';

// Code split disparate view components
const AccountPage = lazy(() => import('~/pages/AccountPage'));
const DeleteAccountConfirmationPage = lazy(() => import('~/pages/DeleteAccountConfirmationPage'));
const DevUtiltiesPage = lazy(() => import('~/pages/DevUtilitiesPage'));
const MyAudioPage = lazy(() => import('~/pages/MyAudioPage'));
const ReactivateAccountPage = lazy(() => import('~/pages/ReactivateAccountPage'));
const RegistrationPage = lazy(() => import('~/pages/RegistrationPage'));

const AppWithState = (): JSX.Element => {
  const handleError = useErrorHandler();
  const location = useLocation();
  const [isAppInitialized, setIsAppInitialized] = useRecoilState(appInitializationState);
  const [{ currentPath, previousPath }, updatePreviousPath] = useRecoilState(navigationPathState);
  const { clientServices, loading } = useClientServices();

  useEffect(() => {
    initDataDog();
  }, []);

  useEffect(() => {
    if (!loading) {
      clientServices.getAnalyticServices().sendAppStart();
    }
  }, [clientServices, loading]);

  // Client services needs to be initialized before rendering the app.
  useEffect(() => {
    async function initApp() {
      try {
        //Order matters.
        initBraze();
        await initClientServices();
        initOneTrust();

        // setAuthCallback moved after initBraze() to utilize braze method
        setAuthCallback((state) => {
          const token = clientServices.getPersonalizationServices().userToken;
          const sessionId = clientServices.getDataServices().sessionId || '';

          if (state === AuthState.AUTH && token) {
            clientServices
              .getPersonalizationServices()
              .getProfileData()
              .then((profileData) => setUserToDataDog(profileData, token, sessionId));
            if (!disableBraze) {
              braze.changeUser(token);
              const deviceId = clientServices
                .getPersonalizationServices()
                .dataStore.getDataSync<string>(LocalStorageKey.BRAZE_DEVICE_ID);
              if (deviceId) {
                braze.getUser()?.addAlias('USER_ALIAS', deviceId);
              }
            }
          } else {
            setUserToDataDog({}, undefined, sessionId);
          }
        });

        setIsAppInitialized(true);
      } catch (error) {
        handleError(error);
      }
    }
    initApp();
  }, [handleError, setIsAppInitialized, clientServices]);

  // tracks location changes, state is used by back button
  useEffect(() => {
    if (currentPath !== location.pathname) {
      updatePreviousPath({
        previousPath: currentPath,
        currentPath: location.pathname + location.search || '',
      });
    }
  }, [location, previousPath, currentPath, updatePreviousPath]);

  if (isAppInitialized) {
    // once app is initlialized remove the static loader from index.html
    const initialLoading = document.querySelector('#initial-loading');
    if (initialLoading) {
      initialLoading.remove();
    }
  }

  return isAppInitialized ? <App /> : <></>;
};

const App = (): JSX.Element => {
  useWindowSize();
  const loggedIn = useRecoilValue(isLoggedIn);
  const navigate = useNavigate();
  const incompleteProfile = useRecoilValue(getIncompleteProfileData);
  const isSoftDeleted = useRecoilValue(isSoftDeletedState);
  const adRefreshInterval = useRecoilValue(clientSettings).configuration.adRefreshInterval;

  const location = useLocation();

  const setShouldDisplaySquareAd = useSetRecoilState(shouldDisplaySquareAd);

  // Reset scroll on location change.
  useEffect(() => {
    window.scrollTo(0, 0);
    // destroys all ad slots on location change, resets square ad's displayability
    // but only if the Google Publisher Tag API is ready
    if (window.googletag?.apiReady) {
      destroyAdSlots();
      setShouldDisplaySquareAd(false);
    }
  }, [location.pathname, setShouldDisplaySquareAd]);

  // location prompt initialization
  useBrazeLocationPrompt();
  useLocationPermission();

  const shouldShowNavigation = !pathsWithoutNav.some((path) => location.pathname.startsWith(path));

  // If user has incomplete profile, redirect them to registration
  useEffect(() => {
    if (isSoftDeleted) {
      navigate(Path.ReactivateAccount);
    }

    const isStepOne =
      incompleteProfile?.includes('firstName') || incompleteProfile?.includes('lastName');
    const registrationStep = isStepOne ? Path.RegistrationStepOne : Path.RegistrationStepTwo;

    if (incompleteProfile && incompleteProfile.length > 0 && shouldShowNavigation) {
      navigate(registrationStep);
    }
  }, [loggedIn, navigate, shouldShowNavigation, incompleteProfile, isSoftDeleted]);

  // sets google ads refresh interval rate
  useEffect(() => {
    setAdRefreshRate(adRefreshInterval);
  }, [adRefreshInterval]);

  return (
    <GTMProvider state={gtmParams}>
      <SkipToMainContent />
      {shouldShowNavigation && <NavigationContainer />}

      <Routes>
        {/* static routes */}
        <Route path={Path.Account} element={<AccountPage />} />
        <Route path={Path.DeleteAccountConfirmation} element={<DeleteAccountConfirmationPage />} />
        <Route path={Path.Registration} element={<RegistrationPage currentStepIndex={0} />} />
        <Route path={Path.ReactivateAccount} element={<ReactivateAccountPage />} />
        <Route
          path={Path.RegistrationStepOne}
          element={<RegistrationPage currentStepIndex={0} />}
        />
        <Route
          path={Path.RegistrationStepTwo}
          element={<RegistrationPage currentStepIndex={1} />}
        />
        <Route path={Path.MyAudio} element={<MyAudioPage />}>
          {/* TODO: [A2-7884] refactor this to use the react router Outlet component. */}
          <Route path={Path.MyAudioTab} element={<MyAudioPage />} />
        </Route>
        {env.APP_MODE !== 'production' && <Route path={Path.Dev} element={<DevUtiltiesPage />} />}

        {/* dynamic entity routes */}
        <Route path="*" element={<Page key={location.pathname} />} />
      </Routes>

      {shouldShowNavigation && (
        <Suspense fallback={<></>}>
          <PlayerContextProvider>
            <FullscreenPlayer />
            <MiniPlayer />
            <MostRecentlyPlayedStateConnector />
          </PlayerContextProvider>
        </Suspense>
      )}

      <LiveRegions />
      <ToastWithState />
      <FacebookPixel />
    </GTMProvider>
  );
};

export default AppWithState;
