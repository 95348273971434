export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Code</title>
    <path
      d="M13.724 6.373a1.091 1.091 0 0 0-.75-.371 1.073 1.073 0 0 0-.79.279L2.366 15.17A1.111 1.111 0 0 0 2 16a1.129 1.129 0 0 0 .366.83l9.818 8.89a1.079 1.079 0 0 0 1.54-.093 1.124 1.124 0 0 0-.09-1.569L4.733 16l8.9-8.058a1.123 1.123 0 0 0 .09-1.569ZM29.634 15.17l-9.818-8.89a1.079 1.079 0 0 0-1.54.093 1.124 1.124 0 0 0 .09 1.569L27.267 16l-8.9 8.058a1.109 1.109 0 0 0-.365.766 1.13 1.13 0 0 0 .274.803 1.095 1.095 0 0 0 .75.371 1.073 1.073 0 0 0 .79-.279l9.818-8.889A1.112 1.112 0 0 0 30 16a1.13 1.13 0 0 0-.366-.83Z"
      fill="currentColor"
    />
  </svg>
);
