export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Location</title>
    <path
      d="m7.09 18.222.665-.347-.665.347Zm17.82 0-.665-.347.665.347ZM6.75 13.778c0-4.97 4.125-9.028 9.25-9.028v-1.5c-5.921 0-10.75 4.698-10.75 10.528h1.5ZM16 4.75c5.125 0 9.25 4.058 9.25 9.028h1.5C26.75 7.948 21.921 3.25 16 3.25v1.5Zm0 22.5c.112 0 .155.037.081 0a2.088 2.088 0 0 1-.256-.157c-.234-.162-.542-.41-.908-.736-.729-.648-1.638-1.555-2.576-2.568-1.891-2.045-3.808-4.427-4.586-5.914l-1.33.695c.878 1.678 2.916 4.185 4.815 6.238.958 1.035 1.904 1.98 2.68 2.67.387.344.745.637 1.05.848.153.105.307.201.455.273.129.063.338.151.575.151v-1.5Zm-8.245-9.375a8.811 8.811 0 0 1-1.005-4.097h-1.5c0 1.725.424 3.354 1.176 4.792l1.329-.695Zm17.495-4.097a8.812 8.812 0 0 1-1.005 4.097l1.33.695a10.312 10.312 0 0 0 1.175-4.792h-1.5Zm-1.005 4.097c-.778 1.487-2.695 3.87-4.586 5.914-.938 1.013-1.847 1.92-2.576 2.568-.366.325-.673.574-.908.736a2.088 2.088 0 0 1-.256.157c-.074.037-.031 0 .081 0v1.5c.237 0 .446-.088.575-.15.148-.073.302-.169.454-.274a11.67 11.67 0 0 0 1.05-.848c.777-.69 1.723-1.635 2.68-2.67 1.9-2.053 3.938-4.56 4.815-6.238l-1.329-.695Z"
      fill="currentColor"
    />
    <circle cx="16" cy="13" r="3" fill="currentColor" />
  </svg>
);
