import audacyLogger, { LoggerTopic } from '@audacy-clients/client-services/src/AudacyLogger';
import { SILENT_AUDIO } from '../../Constants';
import { type ILogger } from '../../logger';
import { isAnySafari } from '../../utils/browser';
import { isContentTypeAd } from '../streamers/utils';
import {
  type IHtmlPlayer,
  type IPlayerLoad,
  type IPlayerMetadata,
  type IPlayerOptions,
  type TPlaybackRate,
  type TPlayerEventListener,
} from './types';
import {
  overrideContentType,
  parseMetadata,
  type TParseMetadata,
  isVodFeatureEnabled,
  isCloseToLive,
} from './utils';

interface ITextTrackCue extends TextTrackCue {
  value: {
    key: string;
    info: string;
    data?: Iterable<number>;
  };
}

// https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState

enum EReadyState {
  HAVE_NOTHING = 0,
  HAVE_METADATA,
  HAVE_CURRENT_DATA,
  HAVE_FUTURE_DATA,
  HAVE_ENOUGH_DATA,
}

interface IDeferredMetadata {
  data: IPlayerMetadata;
  time: number | undefined;
}

export default class NativeHlsPlayer implements IHtmlPlayer {
  logger?: ILogger;
  private deferredMetadata: IDeferredMetadata[] = [];
  playerElement: HTMLMediaElement;
  private silentAudioPlayed = false;
  private silentAudioInProgress = false;
  private defaultSpeed: TPlaybackRate = 1.0;
  private stalled = false;
  private ignoreNextLoadingEvent = false;
  private backOnline = false;
  private ignoreZeroUpdateEvent = false;
  private lastMetadata: IPlayerMetadata = {};
  private eventListeners = new Set<TPlayerEventListener>();
  private currentMetadata: TParseMetadata = {};
  private isExclusiveStation = false;
  private exclusiveStationOffset = 0;
  private currentTimeDelta?: number;
  private ctxRate: TPlaybackRate = 1.0;
  // NOTE: CCS-1104 (VOD speed controls): Remove after full feature flag rollout
  vodFeatureEnabled = false;
  private registration!: ServiceWorkerRegistration;
  private action = 'load';
  private isSuperHifi = false;
  private continuity: number;
  allowSkips = true;
  private url = '';

  constructor(options: IPlayerOptions = {}) {
    this.logger = options.logger;
    this.vodFeatureEnabled = isVodFeatureEnabled(options);

    this.continuity = 0;
    const interval = 1.0;
    let nextTick = 0;
    this.defaultSpeed = 1.0;
    this.playerElement = document.body.appendChild(document.createElement('audio'));

    this.playerElement.autoplay = false;
    this.playerElement.preload = 'auto';

    // this.player.onabort = onEvent; // do we need this one?

    this.playerElement.onended = (e) => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] Native Hls Player: Native Ended Event`);

      // Checks if stream is in VOD mode by checking if there is a playlistNextStartTime
      if (
        this.lastMetadata.playlistNextStartTime &&
        this.playerElement.currentTime >= this.playerElement.duration
      ) {
        this.notify({
          type: 'continuePlaying',
          playlistNextStartTime: this.lastMetadata.playlistNextStartTime,
        });
      } else {
        this.logger?.trace('onEnded');
        this.notify({ type: 'ended', raw: e });
      }
    };

    this.playerElement.onerror = () => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] Native Hls Player: On Error Event`);

      // Existing error handling has been commented out. Native errors are presenting differently than hls.js errors and
      // affecting working playback. This likely needs to be a follow on ticket.
      //*** Below is the Error Handling copied from HTML Plauer */
      // this.logger?.error('onError', e);
      // // silentAudioInProgress is set to false as soon as we start loading real audio
      // // so we can ignore errors than might come in until this is false
      // if (!this.silentAudioInProgress) {
      //     this.notify({ type: 'error', raw: e });
      // }
    };

    this.playerElement.onloadstart = (e) => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] Native Hls Player: On Load Start Event`);

      this.logger?.trace('onLoadStart');
      nextTick = 0;
      if (this.ignoreNextLoadingEvent === true) {
        this.ignoreNextLoadingEvent = false;
      } else {
        this.stalled = true;
        this.notify({ type: 'loading', raw: e });
      }
    };

    this.playerElement.onloadedmetadata = (e) => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] Native Hls Player: On Loaded Metadata Event`);

      this.logger?.trace('onLoadedMetadata');

      // silent audio causes this to prematurely think we're ready to play
      // we set it to false when we load the real audio then this check will work as expected.
      if (
        !this.silentAudioInProgress &&
        this.playerElement.readyState >= EReadyState.HAVE_METADATA
      ) {
        this.notify({ type: 'loaded', raw: e });
      }
    };

    this.playerElement.onpause = (e) => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] Native Hls Player: On Pause Event`);

      if (
        this.lastMetadata.playlistNextStartTime &&
        this.playerElement.currentTime >= this.playerElement.duration
      ) {
        this.notify({
          type: 'continuePlaying',
          playlistNextStartTime: this.lastMetadata.playlistNextStartTime,
        });
        return;
      } else {
        this.logger?.trace('onPause');
        this.notify({ type: 'paused', raw: e });
      }
    };

    // When we 'stop', we destroy the stream which fires 'emptied' but not 'pause' event
    this.playerElement.onemptied = (e) => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] Native Hls Player: On Emptied Event`);

      this.logger?.trace('onEmptied');
      this.notify({ type: 'paused', raw: e });
    };

    this.playerElement.onplaying = (e) => {
      this.logger?.trace('onPlaying');
      this.stalled = false;
      this.notify({ type: 'playing', raw: e });
    };

    this.playerElement.onseeked = () => {
      this.logger?.trace('onSeeked');
      nextTick = 0;
    };

    this.playerElement.onstalled = (e) => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] Native Hls Player: On Stalled Event`);

      this.logger?.trace('onStalled');
      this.stalled = true;
      this.notify({ type: 'loading', raw: e });
    };

    this.playerElement.onwaiting = (e) => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] Native Hls Player: On Waiting Event`);

      this.logger?.trace('onWaiting');
      this.stalled = true;
      if (this.backOnline === true) {
        this.backOnline = false;
      }
      this.notify({ type: 'loading', raw: e });
    };

    this.playerElement.ontimeupdate = (e) => {
    // at 4 times per second this would likely be too verbose for even verbose logs
    // audacyLogger.info(`[${LoggerTopic.Streaming}] Native Hls Player: On Time Update Event`);


      this.logger?.trace('onTimeUpdate');
      if (this.isSuperHifi) {
        this.continuity++;
        this.setSuperHifiParams();
        // notify SHF machine that SHF stream is playing
        // for Mobile Safari, time is not displayed, so send 0
        this.notify({
          type: 'update',
          time: 0,
          duration: this.playerElement.duration,
          raw: e,
        });
      }
      if (this.stalled === true) {
        this.stalled = false;
        this.notify({ type: 'playing', raw: e });
      }

      const current = this.playerElement.currentTime;

      if (!this.currentTimeDelta || this.currentTimeDelta > current) {
        this.currentTimeDelta = current;
      }

      const currentPlaybackTime = current + this.exclusiveStationOffset - this.currentTimeDelta;

      if (
        this.deferredMetadata &&
        this.deferredMetadata[0] &&
        this.deferredMetadata[0].time &&
        currentPlaybackTime >= this.deferredMetadata[0].time
      ) {
        // taking the first object in the metadata queing, shifting it off the array and sending it to the ui.
        const firstElement = this.deferredMetadata.shift();

        //send the frst element to the ui with notify function
        this.notify({
          type: 'metadata',
          ...firstElement?.data,
          raw: firstElement?.data,
        });
      }

      if (
        current >= nextTick &&
        (this.ignoreZeroUpdateEvent !== true || current !== 0) &&
        !this.isSuperHifi
      ) {
        this.ignoreZeroUpdateEvent = false;
        nextTick = current + interval;
        this.notify({
          type: 'update',
          time: current,
          duration: this.playerElement.duration,
          raw: e,
        });
      }

      this.ignoreZeroUpdateEvent = false;
    };

    // we can't use hlsJs in certain contexts (like iOS web)
    // so we need to extract metadtata from the TrackEventsCue
    // setup a listener for addTrackEvents - https://developer.mozilla.org/en-US/docs/Web/API/MediaStream/addtrack_event
    this.playerElement.textTracks.addEventListener('addtrack', (addTrackEvent: TrackEvent) => {
    audacyLogger.info(`[${LoggerTopic.Streaming}] Native Hls Player: Add Track Event`);

      const track = addTrackEvent.track;

      if (!track) {
        return;
      }

      track.mode = 'hidden';
      // CueChangeEvents are our opportunity to check for new metadata - https://developer.mozilla.org/en-US/docs/Web/API/TextTrack/cuechange_event
      track.addEventListener('cuechange', (cueChangeEvent: Event) => {
        audacyLogger.info(`[${LoggerTopic.Streaming}] Native Hls Player: Cue Change Event`);

        if (!cueChangeEvent || !cueChangeEvent.target) {
          return;
        }

        const entries = (<TextTrack>cueChangeEvent.target).activeCues;

        if (!entries || entries.length === 0) {
          return;
        }

        const metadataObj = {} as TParseMetadata;

        const currentTime = this.getCurrentTime();

        // we can't use a forEach here because entries isn't a true array
        for (let i = 0; i <= entries.length - 1; i++) {
          const entry = entries[i] as ITextTrackCue;
          const isAmperwaveMetadata =
            entry.value.key === 'PRIV' &&
            entry.value.info === 'amperwave.metadata' &&
            entry.startTime <= currentTime;
          // Used to build general metadata tags
          if (isAmperwaveMetadata) {
            const buffer = entry.value.data as Iterable<number>;

            if (!buffer) {
              continue;
            }

            // decode the data so we can parse it
            const dataArray = new Uint8Array(buffer) as unknown as number[];
            const str = String.fromCharCode.apply(null, dataArray);
            if (str) {
              this.parseHlsMetadata(str);
            }
          } else {
            // is exclusive station metadata
            const { key, data } = entry.value;
            if (!metadataObj[key]) {
              metadataObj[key] = data as string;
            }
            continue;
          }
        }

        // if metadataObj has key/values, then parse metadataObj
        if (Object.values(metadataObj).length) {
          // Takes care of an edgecase where contentType stays as ad when it should be track
          if (this.isExclusiveStation && !this.isSuperHifi) {
            const overrideContent = overrideContentType(this.currentMetadata, metadataObj);
            if (overrideContent) {
              metadataObj['X-SONG-TYPE'] = 'track';
            }
          }

          const fullMetadata = {
            ...this.currentMetadata,
            ...metadataObj,
          };
          this.currentMetadata = fullMetadata;
          this.parseHlsMetadata(JSON.stringify(fullMetadata));
        }
      });
    });

    this.registerServiceWorker();
  }

  registerServiceWorker = async () => {
    audacyLogger.info(`[${LoggerTopic.Streaming}] Native Hls Player: Register Service Worker`);

    if ('serviceWorker' in navigator) {
      try {
        this.registration = await navigator.serviceWorker.register('/sw.js');
        if (this.registration?.active) {
          this.setSuperHifiParams();
          this.handleServiceWorkerMessages();
        }
      } catch (error) {
        // should we play a non-interactive stream if the service worker installation fails?
        console.error(`Registration failed with ${JSON.stringify(error)}`);
      }
    }
  };

  handleServiceWorkerMessages() {
    navigator.serviceWorker.onmessage = (message) => {
      const allowSkips = message.data === 'Allow Skips';
      if (allowSkips) {
        this.allowSkips = true;
      }
    };
  }

  setSuperHifiParams() {
    if (!this.continuity) {
      this.action = 'l';
    } else {
      this.action = 'r';
    }

    const obj = JSON.stringify({
      continuity: this.continuity || 0,
      action: this.action || 'l',
    });

    this.registration?.active?.postMessage(obj);
  }

  parseHlsMetadata(data: string) {
    this.logger?.trace('parseHlsMetadata');
    try {
      const parsed = parseMetadata(JSON.parse(data));
      if (
        this.lastMetadata.songOrShow !== parsed.songOrShow ||
        this.lastMetadata.artist !== parsed.artist ||
        this.lastMetadata.image !== parsed.image ||
        this.lastMetadata.companionAdIframeUrl !== parsed.companionAdIframeUrl ||
        this.lastMetadata.trackStart !== parsed.trackStart ||
        this.lastMetadata.isLive !== parsed.isLive ||
        this.lastMetadata.isRewindable !== parsed.isRewindable ||
        this.lastMetadata.playlistNextStartTime !== parsed.playlistNextStartTime ||
        this.lastMetadata.playlistTailStartTime !== parsed.playlistTailStartTime ||
        this.lastMetadata.liveTime !== parsed.liveTime
      ) {
        this.lastMetadata = {
          contentType: this.lastMetadata.contentType,
          ...parsed,
        };
        // NOTE: CCS-1104 (VOD speed controls): Remove conditional after full feature flag rollout
        if (this.vodFeatureEnabled) {
          const { isLive, datumTime } = this.lastMetadata;

          // NOTE: [CCS-2636] - VOD: On Demand to Live transition
          if (!isLive) {
            const closeToLive = isCloseToLive(this.lastMetadata);

            // if the liveTime is within 60 seconds of the trackStart,
            // we should lod a new streamUrl that transitions to a live stream
            if (closeToLive && datumTime) {
              this.notify({
                type: 'continuePlaying',
                playlistNextStartTime: datumTime,
                playLive: true,
              });
              this.lastMetadata.isLive = true;
            }
          }

          // NOTE: [CCS-2636] - VOD: On Demand to Live transition
          // set playback rate to 1 when transitioning from VOD to Live
          const isAd = isContentTypeAd(this.lastMetadata.contentType);
          // NOTE: [CCS-2807]: not calling this.setRate to bypass setting this.ctxRate
          if (this.lastMetadata.isLive || isAd) {
            this.playerElement.playbackRate = this.defaultSpeed;
          } else {
            this.playerElement.playbackRate = this.ctxRate;
          }
        }
        // resets action to "refresh" after a skip event
        if (this.isSuperHifi) {
          const obj = JSON.stringify({
            continuity: 0,
            action: 'r',
          });
          this.registration?.active?.postMessage(obj);
        }

        if (this.isExclusiveStation) {
          this.deferredMetadata.push({
            data: parsed,
            time: parsed.segmentStartTime,
          });
        } else {
          this.notify({
            type: 'metadata',
            ...parsed,
            raw: data,
          });
        }
      }
    } catch {
      this.logger?.error('Error parsing hls metadata: ' + data);
    }
  }

  play() {
    audacyLogger.info(`[${LoggerTopic.Streaming}] Native Hls Player: Play Event`);

    this.logger?.trace('play');
    this.playerElement.play();
  }

  async playSuperHifiSkip(streamUrl: string) {
    await this.playerElement.play()?.catch(() => {
      // need to limit the number of retries here, in testing it only needed one, on the first skip.
      this.skipSuperHiFi(streamUrl);
    });
  }

  playWithOffset(offset?: number) {
    audacyLogger.info(`[${LoggerTopic.Streaming}] Native Hls Player: Play With Offset Event`);

    this.logger?.trace('playWithOffset');
    if (offset !== undefined) {
      this.setPosition(offset);
    }
    this.play();
  }

  playSuperHiFi(): void {
    audacyLogger.info(`[${LoggerTopic.Streaming}] Native Hls Player: Play Super Hifi`);

    this.play();
  }

  stop() {
    audacyLogger.info(`[${LoggerTopic.Streaming}] Native Hls Player: Stop`);

    this.logger?.trace('stop');
    this.pause();

    // eslint-disable-next-line no-self-assign
    this.playerElement.src = this.playerElement.src;
    this.ignoreNextLoadingEvent = true;
    this.ignoreZeroUpdateEvent = true;

    this.playerElement.src = '';
  }

  skipSuperHiFi(streamUrl: string) {
    audacyLogger.info(`[${LoggerTopic.Streaming}] Native Hls Player: Super Hifi Skip`);

    if (!this.allowSkips) {
      return;
    }
    this.allowSkips = false;
    const url = `${streamUrl}?action=s&continuity=0`;

    this.playerElement.src = url;

    const obj = JSON.stringify({
      continuity: 0,
      action: 's',
    });
    this.registration?.active?.postMessage(obj);

    try {
      this.playSuperHifiSkip(streamUrl);
    } catch {
      this.allowSkips = true;
    }
  }

  private resetSuperHifi(loadParams: IPlayerLoad) {
    const skip = loadParams.url.includes('skip');

    const obj = JSON.stringify({
      continuity: 0,
      action: skip ? 'skip' : 'load',
    });
    this.url = loadParams.url.split('?')[0];
    this.registration?.active?.postMessage(obj);
  }

  load(loadParams: IPlayerLoad) {
    audacyLogger.info(`[${LoggerTopic.Streaming}] Native Hls Player: Load`);

    this.isSuperHifi = loadParams.isSuperHifi;
    if (this.isSuperHifi) {
      const skip = loadParams.url.includes('skip');
      const shouldResume = this.url === loadParams.url.split('?')[0];
      if (shouldResume && !skip) {
        this.playerElement.src = loadParams.url;
        this.playerElement.play();
        return;
      } else {
        this.resetSuperHifi(loadParams);
      }
    }
    this.logger?.trace('load');
    this.lastMetadata = {};
    this.deferredMetadata = [];
    this.currentMetadata = {};
    this.stop();
    this.playerElement.defaultPlaybackRate = this.defaultSpeed;
    this.notify({ type: 'loading', raw: { url: loadParams.url } });

    this.playerElement.src = loadParams.url;
    // at the end of load, we want to make sure this is set to false
    if (this.silentAudioInProgress) {
      this.silentAudioInProgress = false;
    }

    // exclusive station specific logic
    this.isExclusiveStation = loadParams.url.includes('smartstreams.radio.com');
    if (this.isExclusiveStation) {
      const exclusiveUrl = new URL(loadParams.url);
      const playlistOffset = exclusiveUrl.searchParams.get('playlistOffset');
      this.exclusiveStationOffset = playlistOffset ? Math.floor(Number(playlistOffset)) : 0;
      this.currentTimeDelta = undefined;
    }
  }

  getMuted = () => this.playerElement.muted;
  setMuted = (muted: boolean) => (this.playerElement.muted = muted);
  getCurrentTime = () => this.playerElement.currentTime;
  getVolume = () => this.playerElement.volume;
  pause = () => {
    this.logger?.trace('pause');
    this.playerElement.pause();
    this.currentMetadata = {};
    this.deferredMetadata = [];
  };
  setDefaultRate = (speed: TPlaybackRate) => (this.defaultSpeed = speed);
  setPosition = (secs: number) => (this.playerElement.currentTime = secs);
  setRate = (speed: TPlaybackRate) => {
    this.ctxRate = speed;
    this.playerElement.playbackRate = speed;
  };
  getRate = () => this.playerElement.playbackRate as TPlaybackRate;
  skip = (secs: number) => (this.playerElement.currentTime += secs);
  playPrerollInProgress(): void {}

  setVolume(volume: number) {
    if (!isNaN(volume)) {
      this.playerElement.volume = volume;
    } else {
      this.logger?.error('Trying to set volume to something not a number = ' + volume);
    }
  }

  silentAudioHack() {
    audacyLogger.info(`[${LoggerTopic.Streaming}] Native Hls Player: Silent Audio Detection`);

    const shouldPlaySilentAudio =
      typeof document !== 'undefined' && isAnySafari() && !this.silentAudioPlayed;

    if (shouldPlaySilentAudio) {
      this.logger?.trace('silentAudioHack');
      // we want to ignore the load event that happens when we load the silent audio
      this.ignoreNextLoadingEvent = true;
      // this is used to track the silent audio from when it we initiate it until we load the actual audio
      this.silentAudioInProgress = true;
      this.playerElement.defaultPlaybackRate = this.defaultSpeed;
      this.playerElement.src = SILENT_AUDIO;
      this.playerElement.load();
      // long term we'll replace this with a value in the machines to control whether we want to call this at all
      this.silentAudioPlayed = true;
    }
  }

  private notify: (...args: Parameters<TPlayerEventListener>) => void = (event) => {
    this.eventListeners.forEach((listener) => listener(event));
  };

  addEventListener = (listener: TPlayerEventListener) => this.eventListeners.add(listener);
  removeEventListener = (listener: TPlayerEventListener) => this.eventListeners.delete(listener);
}
