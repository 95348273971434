import {
  ClickFeature,
  DataEventType,
  EntityType,
  LocalStorageKey,
  PlayerAction,
  type Preference,
} from '@audacy-clients/client-services/core';
import { playVideoAd } from '@audacy-clients/client-services/src/adServices/VideoAd';
import { type IEventDetails } from '@audacy-clients/client-services/src/analyticServices';
import {
  ECollectionPlaybackMode,
  type TPlayableObject,
} from '@audacy-clients/client-services/src/audioServices/types';
import type DataObject from '@audacy-clients/client-services/src/dataServices/DataObject';
import { shouldEnablePlaybackRates } from '@audacy-clients/client-services/src/utils/playbackRate';
import { selectedPlaylistIdState } from '@audacy-clients/core/atoms/collections';
import { ViewIDKey } from '@audacy-clients/core/atoms/config/constants';
import playerState, {
  playButtonDisabledState,
  playerMetadataState,
  playerSkipsState,
  useResetPlayerState,
} from '@audacy-clients/core/atoms/player';
import { queueState } from '@audacy-clients/core/atoms/queue';
import { wrapContentObject } from '@audacy-clients/core/atoms/wrappers/content';
import { TPlaybackRate } from '@audacy-clients/client-services/src/audioServices/players/types';
import { type ICollection, type IEpisode } from '@audacy-clients/core/atoms/wrappers/types';
import { PlayerState } from '@audacy-clients/core/types/player';
import { type PlayAssistantProps } from '@audacy-clients/core/types/voiceEventsData';
import { useClientServices } from '@audacy-clients/core/utils/clientServices';
import { getItemToPlay } from '@audacy-clients/core/utils/playlist';
import { getRecoil, setRecoil } from '@audacy-clients/core/utils/recoil';
import { useSetRecoilState, useRecoilCallback, useRecoilValue, useRecoilState } from 'recoil';
import { playerSpeedState } from '@audacy-clients/core/atoms/player';
import { type IViewContext } from '@audacy-clients/core/utils/viewContext';
import { useCallback, useMemo } from 'react';

export type IPlayContext = {
  chapter?: string;
  contentId?: string;
  feature: ClickFeature;
  featureText?: string;
  moduleId?: string;
  voiceEventData?: PlayAssistantProps;
  playlistId?: string;
  isAutomotiveFlag?: boolean;
  artist?: string;
  songTitle?: string;
  secondsOffset?: string;
};

export type IPlayOptions = {
  autoPlay?: boolean;
  startOffset?: number;
  episode?: IEpisode;
  collection?: ICollection;
  playLive?: boolean;
  viewId?: string;
};

export type PlayFromIdProps = (
  contentId: string,
  playContext: IPlayContext,
  options?: IPlayOptions,
  onCallback?: () => void,
) => void;

type SendPlayClickEventProps = {
  playContext: IPlayContext;
  playAssistantProps?: PlayAssistantProps;
  viewId?: string;
};

type SendStopClickEventProps = {
  playContext: IPlayContext;
};

type SendSkipByTimeClickEventProps = {
  playContext: IPlayContext;
};

export type IAudioHelpers = {
  getIsLive: () => boolean;
  getVolume: () => number;
  incrementRate: () => void;
  pause: (playContext: IPlayContext) => void;
  play: (data: DataObject | undefined, playContext: IPlayContext, options?: IPlayOptions) => void;
  playEpisodeLive: (episode: IEpisode, playContext: IPlayContext) => void;
  playFromId: PlayFromIdProps;
  playPlaylist: ({
    collectionId,
    playContext,
    itemId,
  }: {
    collectionId: string;
    playContext: IPlayContext;
    itemId?: string;
  }) => Promise<void>;
  playQueue: (itemId: string, playContext: IPlayContext) => Promise<void>;
  resume: (playContext: IPlayContext) => void;
  resumeCollection: (playContext: IPlayContext) => void;
  seekToProgress: (progress: number) => void;
  scrubbed: () => void;
  seekToLivePosition: (id?: string, eventDetails?: IEventDetails) => void;
  seekToTime: (timeInSecs: number) => void;
  next: (playContext: IPlayContext) => void;
  previous: (playContext: IPlayContext) => void;
  skip: (playContext: IPlayContext) => void;
  sendPreference: (preference: Preference) => void;
  setMuted: (isMuted: boolean) => void;
  setVolume: (volume: number) => void;
  skipByTime: (
    timeInSecs: number,
    playContext: IPlayContext,
    onCallback?: (elapsedTime: number) => void,
  ) => void;
  reset: (isLoading: boolean) => void;
  stop: (playContext: IPlayContext) => void;
  restartShow: (viewContext: IViewContext, playClickContext: IPlayContext) => void;
};

export const useAudioHelpers = (getViewContext?: () => IViewContext): IAudioHelpers => {
  const { clientServices, loading } = useClientServices();
  const resetPlayerState = useResetPlayerState();
  const skips = useRecoilValue(playerSkipsState);
  const setSelectedPlaylistId = useSetRecoilState(selectedPlaylistIdState);
  const [{ currentRate: playbackSpeed, savedRate: savedPlaybackSpeed, availableRates }] = useRecoilState(playerSpeedState);
  const setPlaybackSpeed = useSetRecoilState(playerSpeedState);

  const setSavedPlaybackSpeed =
    (savedRate: number, newRate: number) => {
      setPlaybackSpeed({ savedRate, currentRate: playbackSpeed, availableRates });
      clientServices.getAudioServices().setPlaybackRate(newRate as TPlaybackRate);
    };

  const reset = useCallback(
    (isLoading: boolean) => {
      // Stop the current audio and reset the player state
      // to show the loading state faster after user actions.
      clientServices?.getAudioServices().stop();
      resetPlayerState(isLoading);
    },
    [clientServices, resetPlayerState],
  );

  const sendPlayClickEvent = useCallback(
    ({ playContext, playAssistantProps, viewId }: SendPlayClickEventProps) => {
      const viewContext = getViewContext?.();
      if (!viewContext) {
        return;
      }
      if (viewId) {
        viewContext.viewId = viewId;
      }
      if (!viewContext.contentId && !!playContext.contentId) {
        viewContext.contentId = playContext.contentId;
      } else if (!playContext.contentId && !!viewContext.contentId) {
        playContext.contentId = viewContext.contentId;
      }

      clientServices
        ?.getAnalyticServices()
        .sendPlayClickEvent(viewContext, playContext, playAssistantProps);
    },
    [clientServices, getViewContext],
  );

  const sendStopClickEvent = useCallback(
    ({ playContext }: SendStopClickEventProps) => {
      const viewContext = getViewContext?.();
      if (!viewContext) {
        return;
      }

      if (playContext.contentId === undefined && !!viewContext.contentId) {
        playContext.contentId = viewContext.contentId;
      }

      clientServices?.getAnalyticServices().sendStopClickEvent(viewContext, playContext);
    },
    [clientServices, getViewContext],
  );

  const sendSkipByTimeClickEvent = useCallback(
    ({ playContext }: SendSkipByTimeClickEventProps) => {
      const viewContext = getViewContext?.();
      if (!viewContext) {
        return;
      }

      if (playContext.contentId === undefined && !!viewContext.contentId) {
        playContext.contentId = viewContext.contentId;
      }

      clientServices?.getAnalyticServices().sendSkipByTimeClickEvent(viewContext, playContext);
    },
    [clientServices, getViewContext],
  );

  const play = useCallback(
    async (
      data: DataObject | undefined,
      playContext: IPlayContext,
      options?: IPlayOptions,
    ): Promise<void> => {
      const isDisabled = getRecoil(playButtonDisabledState);
      if (isDisabled) {
        return;
      }
      if (!loading) {
        const filledPlayContext: IPlayContext = {
          contentId: data?.getId(), // It comes before context spread in case dev want to overwrite
          ...playContext,
        };
        const playAssistantProps: PlayAssistantProps = filledPlayContext.voiceEventData || {
          audacyAssistantFlag: 'No',
          audacyAssistantSessionId: '',
          audacyAssistantUserId: '',
        };
        filledPlayContext.voiceEventData = undefined;

        sendPlayClickEvent({
          playContext: filledPlayContext,
          playAssistantProps,
          viewId: options?.viewId,
        });

        // if there is no data, we are resuming the audio
        // TODO: validate that this should set the playmode to single item without an additional check
        if (data) {
          clientServices
            .getAudioServices()
            .setCollectionPlaybackMode(ECollectionPlaybackMode.SingleItem);
        }

        // Silent audio hack must be synchronous with click action
        clientServices.getAudioServices().getPlayer().silentAudioHack?.();

        // Video Preroll, this also must be synchronous with click action
        if (
          options?.autoPlay !== false &&
          data?.getEntityType() === EntityType.STATION &&
          options?.viewId !== ViewIDKey.Player
        ) {
          clientServices?.getAudioServices().stop();

          // text editor may say this is safe to remove, but it is not
          // it prevents video ad from playing with content at the same time.
          await playVideoAd({
            eventListener: (action) => {
              if (action === 'VIDEO_AD_LOAD') {
                setRecoil(playButtonDisabledState, true);
                setRecoil(playerState, {
                  ...getRecoil(playerState),
                  playState: PlayerState.Loading,
                });
              }

              if (action === 'VIDEO_AD_START') {
                setRecoil(playerMetadataState, {
                  songOrShow: 'Advertisement',
                  station: data?.data.title,
                  image: data?.data.images.square,
                  dataObject: wrapContentObject(data),
                });
                setRecoil(playerState, {
                  ...getRecoil(playerState),
                  playState: PlayerState.Idle,
                });
              }

              if (action === 'VIDEO_AD_END') {
                setRecoil(playButtonDisabledState, false);
              }
            },
            dataObj: data,
          });
        }

        // check playback speed for only new playable object
        // data will be empty if we press play multiple times on same audio
        if (data?.data) {
          const isLive = clientServices.getAudioServices().getIsLive();
          const playbackControllExist = shouldEnablePlaybackRates(
            data?.data.entityType,
            data?.data.entitySubtype,
            data?.data.isRewind || data?.data.rewindable,
          );

          if (isLive || !playbackControllExist) {
            // save latest playback speed
            setSavedPlaybackSpeed(savedPlaybackSpeed || playbackSpeed, 1);
          } else {
            // clear saved playback speed
            setSavedPlaybackSpeed(0, savedPlaybackSpeed || playbackSpeed);
          }
        }

        clientServices
          .getAudioServices()
          .play(
            data as TPlayableObject,
            options?.autoPlay,
            options?.startOffset,
            options?.playLive,
          );
      }
    },
    [
      setSavedPlaybackSpeed, playbackSpeed, savedPlaybackSpeed,
      loading, sendPlayClickEvent, clientServices
    ],
  );

  const playFromId = useCallback<PlayFromIdProps>(
    (contentId, playContext, options, onCallback): void => {
      const filledPlayContext: IPlayContext = {
        contentId, // It comes before context spread in case dev want to overwrite
        ...playContext,
      };

      clientServices
        ?.getDataServices()
        .getContentObject(contentId)
        .then((data) => play(data, filledPlayContext, options))
        .finally(() => onCallback && onCallback());
    },
    [clientServices, play],
  );

  const playEpisodeLive = useCallback(
    (episode: IEpisode, playContext: IPlayContext): void => {
      const episodeData = episode.getDataObject();
      const stationId = episode.parentStationIds?.[0];

      const filledPlayContext = {
        contentId: episode.id, // It comes before context spread in case dev want to overwrite
        ...playContext,
      };

      if (!stationId) {
        play(episodeData, filledPlayContext);
        return;
      }
      playFromId(stationId, filledPlayContext);
    },
    [playFromId, play],
  );

  const playPlaylist = useCallback(
    async ({
      collectionId,
      playContext,
      itemId,
    }: {
      collectionId: string;
      playContext: IPlayContext;
      itemId?: string;
    }) => {
      const audioServices = clientServices.getAudioServices();
      playContext.playlistId = collectionId;

      const { itemToPlay, orderedContent, noneToPlay, itemIds } = await getItemToPlay(
        collectionId,
        itemId,
      );

      if (noneToPlay) {
        audioServices.stop();
        return;
      }

      clientServices
        .getPersonalizationServices()
        .dataStore.setDataSync(LocalStorageKey.ACTIVE_PLAYLIST_ITEMS, itemIds);

      const localActivePlaylistId = clientServices.getPersonalizationServices().localCollectionId;
      const localActivePlaylistItems =
        clientServices.getPersonalizationServices().localActivePlaylistItems;

      if (localActivePlaylistId) {
        if (itemId && !localActivePlaylistItems?.includes(itemId)) {
          clientServices
            .getPersonalizationServices()
            .dataStore.setDataSync(LocalStorageKey.COLLECTION_ID, undefined);
          clientServices
            .getPersonalizationServices()
            .dataStore.setDataSync(LocalStorageKey.ACTIVE_PLAYLIST_ITEMS, undefined);
        }
      } else {
        if (playContext.feature === ClickFeature.PLAYLIST) {
          clientServices
            .getPersonalizationServices()
            .dataStore.setDataSync(LocalStorageKey.COLLECTION_ID, collectionId);
          clientServices
            .getPersonalizationServices()
            .dataStore.setDataSync(LocalStorageKey.ACTIVE_PLAYLIST_ITEMS, itemIds);
        }
      }

      if (itemToPlay) {
        playContext.contentId = itemToPlay?.data.id as string;
        audioServices.playCollection(orderedContent, itemToPlay);
        audioServices.setCollectionPlaybackMode(ECollectionPlaybackMode.Playlist);
      } else {
        playContext.contentId = orderedContent[0]?.data.id as string;
        audioServices.playCollection(orderedContent, orderedContent[0]);
        audioServices.setCollectionPlaybackMode(ECollectionPlaybackMode.Playlist);
      }
      setSelectedPlaylistId(collectionId);
      sendPlayClickEvent({ playContext });
    },
    [clientServices, sendPlayClickEvent, setSelectedPlaylistId],
  );

  // TODO: do we need playCollection? Can we consolidate?
  const playQueue = useRecoilCallback(
    ({ snapshot }) =>
      async (itemId: string, playContext: IPlayContext) => {
        const isDisabled = getRecoil(playButtonDisabledState);
        if (isDisabled) {
          return;
        }
        const queue = await snapshot.getPromise(queueState);
        reset(true);
        const itemToPlay = queue.items.find((item) => item.id === itemId);
        if (!itemToPlay) {
          return;
        }
        clientServices.getAudioServices().setCollectionPlaybackMode(ECollectionPlaybackMode.Queue);
        clientServices.getAudioServices().playCollection(
          queue.items.map((item) => item.getDataObject()),
          itemToPlay.getDataObject(),
        );
        sendPlayClickEvent({ playContext });
      },
    [],
  );

  const next = useCallback(
    (playContext: IPlayContext) => {
      const shouldSkipCompletedItems = playContext?.feature === ClickFeature.QUEUE_NEXT;
      clientServices.getAudioServices().next(shouldSkipCompletedItems);
      sendPlayClickEvent({ playContext });
    },
    [clientServices, sendPlayClickEvent],
  );

  const previous = useCallback(
    (playContext: IPlayContext) => {
      const shouldSkipCompletedItems = playContext?.feature === ClickFeature.QUEUE_PREVIOUS;
      clientServices.getAudioServices().previous(shouldSkipCompletedItems);
      sendPlayClickEvent({ playContext });
    },
    [clientServices, sendPlayClickEvent],
  );

  const seekToLivePosition = useCallback(
    (id?: string, eventDetails?: IEventDetails) => {
      clientServices.getAudioServices().goToLive();

      if (id) {
        clientServices?.getAnalyticServices().sendJumpToLive(id, eventDetails);
      }
    },
    [clientServices],
  );

  const restartShow = useCallback(
    (viewContext: IViewContext, playClickContext: IPlayContext) => {
      clientServices.getAudioServices().seekToProgress(0);

      clientServices
        .getAnalyticServices()
        .sendAutoPlayClickEvent(DataEventType.RESTART_EPISODE, viewContext, playClickContext);
    },
    [clientServices],
  );

  const skip = useCallback(
    (playContext: IPlayContext) => {
      if ((skips ?? 0) > 0) {
        clientServices.getAudioServices().skip();
        clientServices?.getAnalyticServices().sendPlayerEvent({
          type: PlayerAction.SKIP_SONG,
          contentId: playContext?.contentId ?? '',
          ...playContext,
        });
      }
    },
    [clientServices, skips],
  );

  const stop = useCallback(
    (playContext: IPlayContext) => {
      sendStopClickEvent({ playContext });
      clientServices.getAudioServices().stop();
    },
    [clientServices, sendStopClickEvent],
  );

  const pause = useCallback(
    (playContext: IPlayContext) => {
      sendStopClickEvent({ playContext });
      clientServices.getAudioServices().pause();
    },
    [clientServices, sendStopClickEvent],
  );

  const resumeCollection = useCallback(
    async (playContext: IPlayContext) => {
      const audioServices = clientServices.getAudioServices();
      if (!playContext.playlistId || !playContext.contentId) {
        return;
      }

      const { orderedContent } = await getItemToPlay(playContext.playlistId, playContext.contentId);

      play(undefined, playContext);
      audioServices.updateCollection(orderedContent);
      audioServices.setCollectionPlaybackMode(ECollectionPlaybackMode.Playlist);
    },
    [clientServices, play],
  );

  const skipByTime = useCallback(
    (secs: number, playContext: IPlayContext, onCallback?: (elapsedTime: number) => void) => {
      clientServices.getAudioServices().skipByTime(secs, onCallback);
      sendSkipByTimeClickEvent({ playContext });
    },
    [clientServices, sendSkipByTimeClickEvent],
  );

  return useMemo(
    () => ({
      getIsLive: (): boolean => clientServices.getAudioServices().getIsLive(),
      getVolume: (): number => clientServices.getAudioServices().getVolume(),
      incrementRate: () => clientServices.getAudioServices().incrementRate(),
      pause,
      play,
      playEpisodeLive,
      playFromId,
      playPlaylist,
      playQueue,
      // playCollection,
      resume: (playContext: IPlayContext) => play(undefined, playContext),
      resumeCollection,
      seekToTime: (secs: number) => clientServices.getAudioServices().seekToTime(secs),
      scrubbed: () => clientServices.getAudioServices().scrubbed(),
      seekToProgress: (fraction: number) =>
        clientServices.getAudioServices().seekToProgress(fraction),
      seekToLivePosition,
      next,
      previous,
      skip,
      sendPreference: (pref: Preference) => clientServices.getAudioServices().sendPreference(pref),
      setMuted: (isMuted: boolean) => clientServices.getAudioServices().setMuted(isMuted),
      setVolume: (volume: number) => clientServices.getAudioServices().setVolume(volume),
      skipByTime,
      stop,
      reset,
      restartShow,
    }),
    [
      play,
      playEpisodeLive,
      playFromId,
      playPlaylist,
      playQueue,
      resumeCollection,
      seekToLivePosition,
      next,
      previous,
      skip,
      reset,
      restartShow,
      skipByTime,
      stop,
      pause,
      clientServices,
    ],
  );
};
