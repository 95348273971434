import { type Property } from 'csstype';

import { type TStyles } from '~/types/emotion-styles';

import { IconMap, type Icons } from './constants';
import styles, { svgSizeStyles } from './styles';

type IIconProps = {
  ariaHidden?: boolean;
  iconColor?: Property.Color;
  iconCss?: TStyles;
  iconCssDimensions?: TStyles;
  name: Icons;
  sizeInPx?: number;
};

const getIconSvg = (name: Icons): React.ReactNode | null => {
  if (!name) {
    return null;
  }

  return IconMap[name];
};

const Icon = ({
  ariaHidden = false,
  iconColor,
  iconCss,
  iconCssDimensions,
  name,
  sizeInPx = 20,
}: IIconProps): JSX.Element | null => {
  const IconSvg = getIconSvg(name);
  return (
    <span
      css={[styles.iconContainer, svgSizeStyles(sizeInPx), iconCss, iconCssDimensions]}
      style={{
        color: iconColor && `${iconColor}`,
        width: iconCssDimensions ? undefined : `${sizeInPx}px`,
        height: iconCssDimensions ? undefined : `${sizeInPx}px`,
      }}
      aria-hidden={ariaHidden}
    >
      {IconSvg}
    </span>
  );
};

export default Icon;
