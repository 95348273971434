import Icon from '~/components/Icon';
import { Icons } from '~/components/Icon/constants';
import useLinkOrButton from '~/hooks/use-link-or-button';
import { IGlobalAsAnchorProps, IGlobalAsButtonProps, LinkType } from '~/types/links';
import { appendRightArrow } from '~/utils/strings';

import styles from './styles';

interface INavLinkProps {
  hasArrow?: boolean;
  icon?: Icons;
  isSecondary?: boolean;
  label: string;
}

export interface INavLinkAnchorProps extends IGlobalAsAnchorProps, INavLinkProps {}
export interface INavLinkButtonProps extends IGlobalAsButtonProps, INavLinkProps {}
export type TNavLinkProps = INavLinkAnchorProps | INavLinkButtonProps;

const NavLink = ({
  as = LinkType.Anchor,
  hasArrow,
  icon,
  isSecondary,
  label,
  ...rest
}: TNavLinkProps): JSX.Element => {
  const showIcon = !!icon && !isSecondary;
  const text = hasArrow ? appendRightArrow(label) : label;

  const children = (
    <>
      {showIcon && <Icon ariaHidden name={icon} />}
      <span>{text}</span>
    </>
  );

  const navLinkStyles = [styles.link, isSecondary ? styles.secondaryNav : styles.primaryNav];

  return useLinkOrButton({
    props: { as, ...rest },
    children,
    styles: navLinkStyles,
  });
};

export default NavLink;
