import { BP, Grid, mq } from '~/styles';
import { IGridProps } from '~/styles/grid';
import { TStyleSheet } from '~/types/emotion-styles';

const gridStyles = (grid: IGridProps) => ({
  columnGap: grid.gap,
  display: 'grid',
  gridTemplateColumns: `repeat(${grid.columnCount}, 1fr)`,
  height: '100%',
});

const styles: TStyleSheet = {
  grid: {
    ...gridStyles(Grid[BP.Small]),
    [mq(BP.Medium)]: gridStyles(Grid[BP.Medium]),
    [mq(BP.Large)]: gridStyles(Grid[BP.Large]),
    [mq(BP.ExtraLarge)]: gridStyles(Grid[BP.ExtraLarge]),
  },
};

export default styles;
