
import { EpisodeSubType } from '@audacy-clients/client-services/core';
import { featureFlag } from '@audacy-clients/core/atoms/config/settings';
import { episodeQuery } from '@audacy-clients/core/atoms/episodes';
import { nowPlaying } from '@audacy-clients/core/atoms/player';
import { type IChapter, wrapChapters } from '@audacy-clients/core/atoms/wrappers/chapter';
import { type IEpisode } from '@audacy-clients/core/atoms/wrappers/types';
import clientServices from '@audacy-clients/core/utils/clientServices';
import { CurrentChapterPositionType } from '@audacy-clients/core/utils/player';
import { fromUnixTime, getUnixTime, isAfter, isBefore } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { atom, atomFamily, selectorFamily, type SerializableParam, useRecoilValue } from 'recoil';

import { RefreshTimes } from './helpers/constants';
import { noCacheDefaultSelector } from './helpers/noCacheDefaultSelector';
import { refreshableSelectorFamily } from './helpers/refreshable';

export const chapterIndexState = atom<number>({
  default: -1,
  key: 'ChapterIndexState',
});

export const audioServicesProvidedChapters = atomFamily<Array<IChapter>, string>({
  key: 'AudioServicesProvidedChapters',
  default: [],
});

const { selector: clientLoadedChapters } = refreshableSelectorFamily<Array<IChapter>, string>({
  get:
    (episodeId) =>
    async ({ get, blockRefreshes }) => {
      const isChaptersEnabled = get(featureFlag('chapters'));

      if (!isChaptersEnabled) {
        return [];
      }

      const episode = get(episodeQuery(episodeId));

      // Only request chapters for episodes
      if (
        !episode ||
        (episode.subtype === EpisodeSubType.BROADCAST_SHOW_EPISODE && !episode.isReplayable)
      ) {
        blockRefreshes();

        return [];
      }

      const chapters = await clientServices
        .getDataServices()
        .getChapters(episodeId);

      if (!episode.isLive) {
        blockRefreshes();
      }

      if (chapters.length < 1) {
        return [];
      }

      return wrapChapters(chapters);
    },
  key: 'ClientLoadedChapters',
  refreshEverySeconds: RefreshTimes.every30s,
});

type TChaptersSelectorParams = {
  episodeId: string;
  live?: boolean;
} & SerializableParam;

export const chaptersSelector = selectorFamily<Array<IChapter>, TChaptersSelectorParams>({
  key: 'Chapters',
  get:
    ({ episodeId, live }) =>
    ({ get }) => {
      if (live) {
        return get(audioServicesProvidedChapters(episodeId));
      } else {
        return get(clientLoadedChapters(episodeId));
      }
    },
});

export const useChapters = (params: TChaptersSelectorParams): Array<IChapter> =>
  useRecoilValue(chaptersSelector(params));

export const useLiveChapterIndex = (episode: IEpisode, chapters: Array<IChapter> = []): number => {
  const { endTime, startTime } = episode;
  const [liveIndex, setLiveIndex] = useState(-1);
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const isShow = episode.subtype === EpisodeSubType.BROADCAST_SHOW_EPISODE;

  const getLiveChapterIndex = useCallback(() => {
    if (chapters.length && startTime && endTime) {
      const now = new Date();
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);

      if (isAfter(now, startDate) && isBefore(now, endDate)) {
        const newLiveChapterIndex = chapters.findIndex((chapter) => {
          const { duration, startOffset } = chapter;
          const chapterStartDate = fromUnixTime(startOffset);
          const chapterEndDate = fromUnixTime(startOffset + duration);

          return isAfter(now, chapterStartDate) && isBefore(now, chapterEndDate);
        });
        const hasLiveChapter = newLiveChapterIndex !== -1;
        const isNotLastChapter = newLiveChapterIndex < chapters.length - 1;

        setLiveIndex(newLiveChapterIndex);

        if (hasLiveChapter && isNotLastChapter) {
          const liveChapter = chapters[newLiveChapterIndex];
          const { duration, startOffset } = liveChapter;
          const chapterEndEpoch = startOffset + duration;
          const nowEpoch = getUnixTime(now);

          timeout.current && clearTimeout(timeout.current);
          timeout.current = setTimeout(getLiveChapterIndex, chapterEndEpoch - nowEpoch);
        }
      }
    }
  }, [chapters, endTime, startTime]);

  useEffect(() => {
    timeout.current && clearTimeout(timeout.current);

    getLiveChapterIndex();

    return () => {
      timeout.current && clearTimeout(timeout.current);
    };
  }, [getLiveChapterIndex]);

  // NOTICE: in 'SHOW EPISODE' we always have a placeholder chapter that is responsible for
  // playing in live and it is at the end of the list
  return useMemo(() => (isShow ? chapters.length - 1 : liveIndex), [isShow, chapters, liveIndex]);
};

export const usePlayingChapter = (contentId?: string): number => {
  const chapterIndex = useRecoilValue(chapterIndexState);
  const playerItem = useRecoilValue(nowPlaying);

  if (!playerItem) {
    return -1;
  }

  const { dataObject, episodeDataObject } = playerItem;
  const playingId = episodeDataObject?.id ?? dataObject?.id;

  if (playingId === contentId) {
    return chapterIndex;
  }

  return -1;
};

export const currentChapterPositionTypeState = noCacheDefaultSelector<CurrentChapterPositionType>({
  get: ({ get }) => {
    const { episodeDataObject } = get(nowPlaying) || {};
    const currentIndex = get(chapterIndexState);

    const chapters = get(audioServicesProvidedChapters(episodeDataObject?.id ?? ''));

    if (chapters.length === 1 || chapters.length === 0 || !chapters) {
      return CurrentChapterPositionType.StartAndEnd;
    }

    if (currentIndex === chapters.length - 1) {
      return CurrentChapterPositionType.End;
    }

    if (currentIndex === 0) {
      return CurrentChapterPositionType.Start;
    }

    return CurrentChapterPositionType.Middle;
  },
  key: 'CurrentChapterPositionType',
});

type TCurrentChapterSelector = {
  live?: boolean;
}

export const currentChapter = selectorFamily<IChapter | undefined, TCurrentChapterSelector>({
  key: 'CurrentChapter',
  get:
    ({ live }) =>
    ({ get }) => {
      const { episodeDataObject } = get(nowPlaying) ?? {};
      const currentIndex = get(chapterIndexState);

      const chapters = get(chaptersSelector({ episodeId: episodeDataObject?.id ?? '', live }));

      return chapters.find((el) => el.index === currentIndex);
    },
});

export const useCurrentChapter = ({ live }: TCurrentChapterSelector) =>
  useRecoilValue(currentChapter({ live }));