import { useCallback, useEffect, useRef, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import Portal from '~/components/Portal';
import Toast from '~/components/Toast/ToastItem';
import { IToastContainerProps } from '~/components/Toast/types';
import { useBreakpoints } from '~/hooks/use-breakpoints';

import styles from './styles';

const timing = {
  close: 4000,
  toastTransition: 0.4,
};

const ToastContainer = ({
  amountQueued,
  nextToast,
  toast,
}: IToastContainerProps): JSX.Element | null => {
  const { greaterThan } = useBreakpoints();
  const onCloseTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [isOpen, setIsOpen] = useState(true);
  const [isEnterAnimationComplete, setIsEnterAnimationComplete] = useState(false);

  const handleCloseToast = useCallback(() => {
    setIsOpen(false);
    resetOnCloseTimeout();
  }, []);

  // If next toast in queue, close old toast and
  // trigger next toast immediately
  useEffect(() => {
    if (nextToast && amountQueued && amountQueued > 1) {
      handleCloseToast();
    }
  }, [amountQueued, handleCloseToast, nextToast]);

  const handleSetTimerToCloseToast = () => {
    if (!isEnterAnimationComplete) {
      setIsEnterAnimationComplete(true);
      onCloseTimeoutRef.current = setTimeout(handleCloseToast, timing.close);
    } else {
      setIsEnterAnimationComplete(false);
    }
  };

  const resetOnCloseTimeout = (): void => {
    if (onCloseTimeoutRef.current) {
      clearTimeout(onCloseTimeoutRef.current);
      onCloseTimeoutRef.current = undefined;
    }
  };

  const handleOnExitComplete = () => {
    if (amountQueued && amountQueued > 0) {
      nextToast && nextToast();
    }
    setIsOpen(true);
  };

  const handleOnCTAClick = () => {
    handleCloseToast();
  };

  return (
    <Portal>
      <AnimatePresence onExitComplete={handleOnExitComplete}>
        {isOpen && toast && (
          <motion.div
            key={toast.id}
            initial={{ opacity: 0, y: greaterThan.MD ? 300 : -300 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 0 }}
            transition={{ bounce: 0.15, duration: timing.toastTransition, type: 'spring' }}
            css={styles.container}
            onAnimationComplete={handleSetTimerToCloseToast}
          >
            <Toast onClose={handleCloseToast} onClick={handleOnCTAClick} {...toast} />
          </motion.div>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default ToastContainer;
