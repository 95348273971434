import { Colors, Mixins } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    overflow: 'hidden',
    backgroundColor: Colors.white30,
    width: '100%',
    ...Mixins.transition('background'),
  },
  circle: {
    borderRadius: '50%',
  },
  gridImage: {
    borderRadius: 0,
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  loaded: {
    background: 'transparent',
  },
  withRadius: {
    borderRadius: '4px',
  },
};

export default styles;
