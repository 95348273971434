import './i18n';

import RecoilNexus from '@audacy-clients/core/utils/recoil';
import { Global } from '@emotion/react';
import { MotionConfig } from 'framer-motion';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import SwiperCore, { Virtual } from 'swiper';

import App from '~/components/App';
import FullScreenError from '~/components/Error/FullScreenError';
import StateConnectors from '~/components/StateConnectors';
import { global } from '~/styles/global';
import { createQueryClient } from './config/query';
import { init as envInit, env } from './utils/env';

const queryClient = createQueryClient();

envInit();

// Add `virtual` option to swiper
// eslint-disable-next-line react-hooks/rules-of-hooks
SwiperCore.use([Virtual]);

const getBaseName = () => {
  return env.BASE_NAME || '/';
};

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={getBaseName()}>
      <RecoilRoot>
        <RecoilNexus />
        {/* reducedMotion="user" only removes motion/transitions if user preference requires it */}
        <MotionConfig reducedMotion="user">
          <HelmetProvider>
            <Global styles={global} />
            <ErrorBoundary FallbackComponent={({ error }) => <FullScreenError error={error} />}>
              {/* Empty fallback here to not have two spinners (here and in <Layout />) */}
              <Suspense fallback={<></>}>
                <QueryClientProvider client={queryClient}>
                  <StateConnectors />
                  <App />
                </QueryClientProvider>
              </Suspense>
            </ErrorBoundary>
          </HelmetProvider>
        </MotionConfig>
      </RecoilRoot>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
