export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Share</title>
    <path d="M13 11H7v16h18V11h-6" stroke="currentColor" strokeWidth="1.5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m16.578 2.22-.53-.53-.53.53-3.65 3.649-.53.53 1.06 1.06.531-.53 2.368-2.367-.047 12.435-.003.75 1.5.006.003-.75.047-12.443 2.322 2.322.53.53 1.06-1.06-.53-.531-3.6-3.601Z"
      fill="currentColor"
    />
  </svg>
);
