import { useTranslation } from 'react-i18next';

import EntityImage from '~/components/Entity/components/EntityImage';
import usePlayerProps from '~/components/Player/hooks/usePlayerProps';
import styles from '~/components/Player/MiniPlayer/styles';

export const BroadcastImage = (): JSX.Element => {
  const { imageAlt, imageUrl, onOpen, isLoading, srcFallback } = usePlayerProps();
  const { t } = useTranslation();

  return (
    <button
      css={styles.imageContainer}
      onClick={onOpen}
      aria-label={t('playerControls.accessibilityLabels.openFullPlayer')}
    >
      <div css={styles.imageInner}>
        <EntityImage
          alt={imageAlt}
          imageURIBase={imageUrl}
          isLoading={isLoading}
          fallbackImage={srcFallback}
        />
      </div>
    </button>
  );
};
