export const toTitleCaseFromPascalCase = (str: string): string =>
  str
    .split(/(?=[A-Z])/)
    .join()
    .replaceAll(',', ' ');

export const toLowercaseFirstCharacter = (str: string): string =>
  str.charAt(0).toLowerCase() + str.slice(1);

export const appendRightArrow = (str: string): string => `${str} ›`;

export const appendLeftArrow = (str: string): string => `‹ ${str}`;

export const isString = <T>(str: string | T): str is string => {
  return typeof str === 'string';
};

export const arrayToCubicBezier = (arr: Array<number>): string => {
  const values = arr.join(', ');
  return `cubic-bezier(${values})`;
};

export const isRelativeURL = (url: string): boolean => {
  return !(url.startsWith('http://') || url.startsWith('https://'));
};
