import { useCallback } from 'react';

import { episodeQuery } from '@audacy-clients/core/atoms/episodes';
import { useMarkItemAsPlayed } from '@audacy-clients/core/atoms/helpers/markItemAsPlayed';
import {
  standaloneChapterQuery,
  useMarkStandaloneChapterAsPlayed,
} from '@audacy-clients/core/atoms/standaloneChapters';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { Icons } from '~/components/Icon/constants';
import OptionsMenuItem from '~/components/OptionsMenu/components/OptionsMenuItem';
import useToast from '~/state/toast';

interface IMarkItemAsPlayed {
  id: string;
  onClickCallback?: () => void;
}

export const MarkEpisodeAsPlayed = ({
  id,
  onClickCallback,
}: IMarkItemAsPlayed): JSX.Element | null => {
  const { t } = useTranslation();
  const episode = useRecoilValue(episodeQuery(id));

  const markEpisodeAsPlayed = useMarkItemAsPlayed(useToast);

  const markAsPlayedCallback = useCallback(() => {
    if (episode) {
      markEpisodeAsPlayed(episode);
    }
    onClickCallback && onClickCallback();
  }, [episode, onClickCallback, markEpisodeAsPlayed]);

  return (
    <OptionsMenuItem
      icon={Icons.Completed}
      onClick={markAsPlayedCallback}
      text={t('optionsMenu.markAsPlayed')}
    />
  );
};

export const MarkStandaloneChapterAsPlayed = ({
  id,
  onClickCallback,
}: IMarkItemAsPlayed): JSX.Element | null => {
  const { t } = useTranslation();
  const chapter = useRecoilValue(standaloneChapterQuery(id));

  const markChapterAsPlayed = useMarkStandaloneChapterAsPlayed(useToast);

  const markAsPlayedCallback = useCallback(() => {
    if (chapter) {
      markChapterAsPlayed(chapter);
    }
    onClickCallback && onClickCallback();
  }, [chapter, onClickCallback, markChapterAsPlayed]);

  return (
    <OptionsMenuItem
      icon={Icons.Completed}
      onClick={markAsPlayedCallback}
      text={t('optionsMenu.markAsPlayed')}
    />
  );
};
