import { bullet, container } from '~/components/PlayStateArea/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  bullet,
  container: {
    ...container,
    gap: 7,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  text: {
    whiteSpace: 'nowrap',
  },
};

export default styles;
