import { EntityType } from '@audacy-clients/client-services';
import { isQueueableEpisode } from "@audacy-clients/core/utils/episode";
import { EntityImageShape } from '@audacy-clients/core/atoms/wrappers/modules';
import { useTranslation } from 'react-i18next';

import EntityImage from '~/components/Entity/components/EntityImage';
import { IEntitySummaryProps, IHorizontalEntityProps } from '~/components/Entity/types';
import Icon from '~/components/Icon';
import { Icons } from '~/components/Icon/constants';
import OptionsMenuButton from '~/components/OptionsMenu/components/OptionsMenuButton';
import { OptionsMenuPosition } from '~/components/OptionsMenu/components/OptionsMenuContainer';
import PlayStateArea from '~/components/PlayStateArea';
import Bulleted from '~/components/PlayStateArea/components/Bulleted';
import useLinkOrButton from '~/hooks/use-link-or-button';
import { LinkType } from '~/types/links';

import styles from './styles';
import { formatEyebrow } from './utils';

type THorizontalEntityCardProps = IHorizontalEntityProps &
  IEntitySummaryProps & {
    onClick?: () => void;
    resumePoint?: number;
    isScrubbing?: boolean;
  };

const HorizontalEntityCard = ({
  aspectRatio = 1,
  containerCss,
  contentCss,
  ctaColor,
  duration,
  entityImageShape = EntityImageShape.Square,
  entityLeftColumn,
  entityLeftColumnCss,
  entityRightColumn,
  entityRightColumnCss,
  entityType,
  entitySubtype,
  eyebrow,
  href,
  imageAlt,
  imageContainerCss,
  imageURIBase,
  isEditMode,
  isEditModeSelected,
  isExclusiveStation,
  isFixed,
  isLive,
  isPlayable,
  isPlayed,
  isPlayerQueue,
  isPlaying,
  isPlayList,
  isReplayable,
  isRewindable,
  isScheduleItem,
  isScrubbing,
  itemsIdList,
  liveUntil,
  optionsMenu,
  publishDate,
  resumePoint,
  rightImageUrl,
  sizes = [{ maxWidth: 600, size: 60 }, { maxWidth: 1120, size: 120 }, { size: 180 }],
  subtitle,
  title,
  titleCss,
  updateDate,
  onClick,
  onHrefClick,
  onOptionsMenuClick,
  onPlayStateClick,
  renderCheckbox,
}: THorizontalEntityCardProps): JSX.Element => {
  const { t } = useTranslation();
  const hasPlayStateArea = duration || isLive || isExclusiveStation || isReplayable || isPlayList;

  // Right column actions, including 3-dots options menu
  const actionElements = (entityRightColumn || optionsMenu) && (
    <>
      {isRewindable && !isPlayerQueue && !!entityRightColumn && (
        <div css={[styles.elementRight, entityRightColumnCss]}>{entityRightColumn}</div>
      )}
      {isRewindable && !isPlayerQueue && optionsMenu ? (
        <div css={[styles.elementRight, entityRightColumnCss]}>
          <OptionsMenuButton
            ariaLabel={`${title} ${t(
              'horizontalEntityCard.entityImage.optionsCta.accessibilityLabel',
            )}`}
            menuPosition={OptionsMenuPosition.BottomLeft}
            menuTitle={title}
            isQueueEnabled={isQueueableEpisode(entityType, entitySubtype)}
            menuImage={
              imageURIBase
                ? {
                    imageURIBase,
                    alt: title,
                    aspectRatio,
                    entityImageShape,
                  }
                : undefined
            }
            onClickCallback={onOptionsMenuClick}
            optionsMenu={optionsMenu}
          />
        </div>
      ) : undefined}
    </>
  );

  // Play state and summary info to be conditionally wrapped by a button or div.
  const playStateChildren = (
    <PlayStateArea
      ctaColor={ctaColor}
      _isChapter={entityType === EntityType.STANDALONE_CHAPTER}
      duration={duration}
      isExclusiveStation={isExclusiveStation}
      _isEpisode={entityType === EntityType.EPISODE}
      isLive={isLive}
      isPlayed={isPlayed}
      isPlaying={isPlaying}
      liveUntil={liveUntil}
      resumePoint={resumePoint}
      isScrubbing={isScrubbing}
      isScheduleItem={isScheduleItem}
      isPlayable={isPlayable}
      isPlayList={isPlayList}
      itemsCount={itemsIdList?.length}
    />
  );

  // Button-wrapped playStateChildren
  const playStateButton = useLinkOrButton({
    children: playStateChildren,
    props: {
      as: LinkType.Button,
      ariaLabel: t(`horizontalEntityCard.ariaLabel.${resumePoint ? 'resume' : 'play'}`, { title }),
      tabIndex: isEditMode ? -1 : 0,
      onClick: (e) => {
        e.preventDefault();
        onPlayStateClick && onPlayStateClick();
      },
    },
    styles: styles.playStateButton,
  });

  const titleChildren = (
    <div
      css={[
        styles.titleContainer,
        isFixed && styles.titleContainerFixed,
        isPlayed && !isPlaying && styles.played,
      ]}
    >
      {!!title && (
        <span css={[styles.title, titleCss]} className="title">
          {title}
        </span>
      )}
      <div css={styles.bulletedContainer}>
        <Bulleted
          title={formatEyebrow({ publishDate, updateDate, subtitle: eyebrow, entityType })}
          hasOverflowGradient
        />
      </div>
    </div>
  );

  const linkOrButtonContainer = useLinkOrButton({
    children: titleChildren,
    props: {
      ariaLabel: href ? t('horizontalEntityCard.ariaLabel.link', { title }) : title,
      ariaPressed: isEditModeSelected,
      as: href ? LinkType.Anchor : LinkType.Button,
      href,
      onClick: onClick || onHrefClick,
    },
    styles: styles.linkOrButtonContainer,
  });

  return (
    <div css={[styles.container, isFixed && styles.containerFixed, containerCss]}>
      {/*
        Render prop for editable card lists -- not ideal but works. The full card button
        hitbox doesn't behave well when the checkbox is in the parent component.
      */}
      {renderCheckbox && renderCheckbox()}
      {!entityLeftColumn ? (
        <div
          css={[
            styles.imageContainer,
            isFixed && styles.imageContainerFixed,
            imageContainerCss,
            isPlayed && !isPlaying && styles.played,
          ]}
        >
          <EntityImage
            alt={imageAlt}
            aspectRatio={aspectRatio}
            entityImageCss={styles.entityImage}
            entityImageShape={entityImageShape}
            imageURIBase={imageURIBase}
            sizes={sizes}
          />
          {isPlayable && (
            <div css={styles.imageOverlay} className="imageOverlay">
              <div css={styles.playIconContainer} className="playIconContainer">
                {/* TODO: [A2-3809] for playable entities, toggle play/pause icon on playback state */}
                <Icon name={Icons.Play} ariaHidden={true} sizeInPx={18} />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div css={[styles.leftColumn, entityLeftColumnCss]}>{entityLeftColumn}</div>
      )}

      <div css={[styles.cardContent, isFixed && styles.cardContentFixed, contentCss]}>
        {/* Eyebrow and Title */}
        {href || onClick ? linkOrButtonContainer : titleChildren}

        {!!subtitle && subtitle}
        {/* Play state and options menu actions, hidden in edit mode */}
        <div css={styles.bottomActionsContainer}>
          {hasPlayStateArea && (
            <div css={[styles.playStateContainerNested, isPlayed && !isPlaying && styles.played]}>
              {onPlayStateClick ? playStateButton : playStateChildren}
            </div>
          )}
          {!isPlayerQueue && hasPlayStateArea && !isEditMode ? (
            <div
              css={[
                styles.elementRightContainer,
                entityRightColumn ? styles.multipleActionElements : styles.singleActionElement,
              ]}
            >
              {actionElements}
            </div>
          ) : undefined}
        </div>
      </div>

      {!!rightImageUrl && (
        <div css={[styles.imageContainer, styles.elementRight, entityRightColumnCss]}>
          <EntityImage
            alt={title || ''}
            entityImageShape={entityImageShape}
            imageURIBase={rightImageUrl}
            aspectRatio={aspectRatio}
            sizes={sizes}
          />
        </div>
      )}

      {!hasPlayStateArea && !isEditMode ? actionElements : undefined}
    </div>
  );
};

export default HorizontalEntityCard;
