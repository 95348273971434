import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';

import { type SubmitHandler, useForm } from 'react-hook-form';
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import SearchBarInput from '~/components/SearchBar/SearchBarInput';
import SearchBarLabel from '~/components/SearchBar/SearchBarLabel';
import SearchClearButton from '~/components/SearchBar/SearchClearButton';
import { Path } from '~/routes';
import { searchQueryState } from '~/state/search';

import styles from './styles';

export interface ISearchBarFormValues {
  term: string;
}

interface ISearchBarHookProps {
  tabIndex?: number;
  onSubmitCallback?: () => void;
}

const SEARCH_NAME_TERM = 'term';

const SearchBar = ({ tabIndex, onSubmitCallback }: ISearchBarHookProps): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const uriTerm = decodeURIComponent(searchParams.get(SEARCH_NAME_TERM) ?? '');
  const isSearchPage = !!useMatch({ path: Path.Search });
  const navigate = useNavigate();

  const setTermAtom = useSetRecoilState(searchQueryState);

  const { register, handleSubmit, setFocus, getValues, setValue } = useForm<ISearchBarFormValues>({
    defaultValues: {
      [SEARCH_NAME_TERM]: uriTerm,
    },
  });

  useEffect(() => {
    uriTerm && onChangeDebounced(uriTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showClearButton = getValues(SEARCH_NAME_TERM).length > 0;

  const onChangeDebounced = useMemo(
    () =>
      debounce((newTerm) => {
        setSearchParams({ [SEARCH_NAME_TERM]: newTerm }, { replace: true });
        setTermAtom(newTerm);
      }, 500),
    [setSearchParams, setTermAtom],
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setValue(SEARCH_NAME_TERM, e.target.value);
      isSearchPage && onChangeDebounced(e.target.value);
    },
    [isSearchPage, onChangeDebounced, setValue],
  );

  const onClear = useCallback(() => {
    setFocus(SEARCH_NAME_TERM);
    setValue(SEARCH_NAME_TERM, '');
  }, [setFocus, setValue]);

  const onSubmitDebounced = useMemo(
    () =>
      debounce((newTerm) => {
        if (isSearchPage) {
          setSearchParams({ [SEARCH_NAME_TERM]: newTerm }, { replace: true });
        } else {
          navigate({
            pathname: Path.Search,
            search: `?${SEARCH_NAME_TERM}=${encodeURIComponent(newTerm)}`,
          });
        }
        setTermAtom(newTerm);
      }, 500),
    [isSearchPage, navigate, setSearchParams, setTermAtom],
  );

  const onSubmit: SubmitHandler<ISearchBarFormValues> = (data) => {
    onSubmitDebounced(data[SEARCH_NAME_TERM]);
    onSubmitCallback && onSubmitCallback();
  };

  return (
    <form action={Path.Search} role="search" css={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div css={[styles.container]}>
        <SearchBarLabel />
        <SearchBarInput
          name={SEARCH_NAME_TERM}
          onChange={onChange}
          register={register}
          tabIndex={tabIndex}
        />
        <SearchClearButton showClearButton={showClearButton} onClear={onClear} />
      </div>
    </form>
  );
};

export default SearchBar;
