import React, { useEffect, useRef, useState } from 'react';

import { useModuleContext } from '@audacy-clients/core/components/ModuleRenderer/context';
import { useReducedMotion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Swiper } from 'swiper/react';
import { Swiper as TSwiper, SwiperOptions } from 'swiper/types';

import IconButton from '~/components/Button/IconButton';
import { SwiperParams } from '~/components/Carousel/constants';
import styles from '~/components/Carousel/styles';
import { Icons } from '~/components/Icon/constants';
import Keys from '~/constants/keys';
import { Timing } from '~/styles';
import { TStyles } from '~/types/emotion-styles';

export interface ICarouselViewProps {
  ariaLabel?: string;
  carouselViewCss?: TStyles;
  children?: React.ReactNode;
  contentContainerCss?: TStyles;
  swiperParams?: SwiperOptions;
}

const CarouselView = ({
  ariaLabel = 'Carousel',
  children,
  carouselViewCss,
  swiperParams,
  ...props
}: ICarouselViewProps): JSX.Element => {
  const shouldReduceMotion = useReducedMotion();
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [isSwipable, setIsSwipable] = useState(true);
  const ioRef = useRef<IntersectionObserver | null>(null);
  const swiperRef = useRef<TSwiper | null>(null);
  const { isSideColumn, carousel } = useModuleContext();

  const defaultSwiperParams = SwiperParams.getDefaults({
    prevEl,
    nextEl,
  });

  const mergedSwiperParams = {
    ...defaultSwiperParams,
    ...swiperParams,
    speed: shouldReduceMotion ? 0 : swiperParams?.speed || defaultSwiperParams?.speed,
  };

  const { t } = useTranslation();

  const checkIfSwipable = () => {
    const swiper = swiperRef.current;
    if (swiper) {
      setIsSwipable(!(swiper.isBeginning && swiper.isEnd));
    }
  };

  const onInit = (swiper: TSwiper): void => {
    swiperRef.current = swiper;
    checkIfSwipable();
  };

  // exclude all non-visible links and buttons inside carousel slides
  // from being focusable / accessible via tab/alt-tab
  function intersectionCallback(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (!entry.isIntersecting) {
        entry.target.setAttribute('tabindex', '-1');
        entry.target.setAttribute('aria-hidden', 'true');
      } else {
        entry.target.removeAttribute('tabindex');
        entry.target.removeAttribute('aria-hidden');
      }
    });
  }

  // since swiper's 'Keyboard' module triggers on every swiper in view
  // we need a custom keyUp handler for left and right arrow keys
  const onSwiperKeyUp = (e: React.KeyboardEvent) => {
    // exclusively allow event to fire when container itself is focused (and not any if its children)
    if (e.target === e.currentTarget) {
      const { key } = e;
      const swiper = swiperRef.current;
      if (swiper) {
        switch (key) {
          case Keys.ArrowRight:
            // TODO: [A2-4785] refactor 'Timing' to pass unit 'ms | s' to avoid multiplying by 1000
            swiper.slideNext(Timing.default * 1000);
            break;
          case Keys.ArrowLeft:
            swiper.slidePrev(Timing.default * 1000);
            break;
        }
      }
    }
  };

  // To catch any links/buttons that are rendered in carousel slides (some of them asynchronously
  // like optionsMenu) we run initIntersectionObserver either on swiper's onTransitionStart or
  // when the users focuses on the carousel's container (whichever happens first).
  // This is to make sure the user can only tab on visible slides/links/buttons.
  const initIntersectionObserver = () => {
    const swiper = swiperRef.current;
    if (swiper && !ioRef.current) {
      const io = new IntersectionObserver(intersectionCallback, {
        root: swiper.el,
        threshold: 0.95,
      });
      ioRef.current = io;
      const targetElements = swiper.el.querySelectorAll(
        '.swiper-slide, .swiper-slide a:not([role="menu"] a),.swiper-slide button:not([role="menu"] button)',
      );
      targetElements.forEach((el) => io.observe(el));
    }
  };

  const onFocus = () => {
    initIntersectionObserver();
  };

  const onResize = () => {
    checkIfSwipable();
  };

  const onTransitionStart = () => {
    initIntersectionObserver();
  };

  const onClickNext = () => {
    const swiper = swiperRef.current;
    if (
      swiper &&
      swiper.activeIndex &&
      swiper.params.slidesPerView &&
      typeof swiper.params.slidesPerView == 'number'
    ) {
      // advance as many full slides are currently visible
      swiper.slideTo(swiper.activeIndex + Math.floor(swiper.params.slidesPerView) - 1);
    }
  };

  const onClickPrevious = () => {
    const swiper = swiperRef.current;
    if (
      swiper &&
      swiper.activeIndex &&
      swiper.params.slidesPerView &&
      typeof swiper.params.slidesPerView == 'number'
    ) {
      // move back as many full slides are currently visible
      swiper.slideTo(swiper.activeIndex - Math.floor(swiper.params.slidesPerView) + 1);
    }
  };

  // disconnect IntersectionObserver when component unmounts
  useEffect(() => {
    if (!ioRef.current) return;
    return () => {
      ioRef.current?.disconnect();
    };
  }, [ioRef]);

  return (
    // disabling lint-rule below to allow swiper to handle arrow key interactions
    <div
      css={[styles.container, isSideColumn && styles.containerSideColumn, carouselViewCss]}
      // disabling lint-rule below to allow carousel container to be focusable
      tabIndex={0}
      aria-label={ariaLabel}
      role="region"
      onFocus={onFocus}
      onKeyUp={onSwiperKeyUp}
      {...props}
    >
      <Swiper
        {...mergedSwiperParams}
        css={[styles.swiper, carousel?.isStacked && styles.swiperStacked]}
        onInit={onInit}
        onResize={onResize}
        onTransitionStart={onTransitionStart}
        virtual={{
          // Need this to prevent blank content and pagination breaking randomly (swiper bug)
          addSlidesBefore: 5,
          addSlidesAfter: 5,
        }}
      >
        {children}
      </Swiper>
      <div css={[styles.swiperButtonWrap, !isSwipable ? styles.swiperButtonWrapHidden : {}]}>
        <IconButton
          buttonCss={styles.swiperButton}
          className="swiper-button"
          icon={Icons.CaretLeft}
          ariaLabel={t('carousel.ariaLabels.prevSlides')}
          ref={(node) => setPrevEl(node)}
          iconSizeInPx={16}
          onClick={onClickPrevious}
        />
        <IconButton
          buttonCss={styles.swiperButton}
          className="swiper-button"
          icon={Icons.CaretRight}
          ariaLabel={t('carousel.ariaLabels.nextSlides')}
          ref={(node) => setNextEl(node)}
          iconSizeInPx={16}
          onClick={onClickNext}
        />
      </div>
    </div>
  );
};

export default CarouselView;
