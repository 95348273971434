import { IServerQueue } from '../dataServices/types';
import { IQueue, TQueueItem } from './types';

export const addToQueueAndDedupe = (queue: IQueue, item: TQueueItem) => {
  const id = item.getId();
  const { items: episodes } = queue;
  /* Begin Tony code */
  let dup = false;
  if (id) {
    for (let i = 0; i < episodes.length; i++) {
      if (episodes[i].getId() === id) {
        dup = true;
        break;
      }
    }
  }

  if (dup === false && item) {
    episodes.push(item);
  }

  if (episodes.length === 1) {
    queue.currentId = episodes[0].getId();
  }
  /* End Tony code */
  return queue;
};

export const indexInQueue = (id: string | undefined, queue: IQueue | IServerQueue) => {
  if ('items' in queue) {
    for (let i = 0; i < queue.items.length; i++) {
      if (id === queue.items[i].getId()) {
        return i;
      }
    }
  } else if ('queue' in queue) {
    for (let i = 0; i < queue.queue.length; i++) {
      if (id === queue.queue[i]) {
        return i;
      }
    }
  }
  return -1;
};

export const setCurrentIdIfFound = (id: string, queue: IQueue | IServerQueue) => {
  if (indexInQueue(id, queue) >= 0) {
    queue.currentId = id;
  }
  return queue.currentId === id;
};
