import styles, { Constants, getAnimationDelay } from './styles';

const Loading = (): JSX.Element => {
  // creates array to map over and render n amount of bars
  // where n equals Constants.barCount of style constants
  const bars = Array.from(Array(Constants.barCount).keys());

  return (
    <div aria-label="Loading" css={styles.container}>
      {bars.map((index) => (
        <div css={styles.barContainer} key={index}>
          <div css={[styles.barBase, styles.barUpper, getAnimationDelay(index)]} />
          <div css={[styles.barBase, styles.barLower, getAnimationDelay(index)]} />
        </div>
      ))}
    </div>
  );
};

export default Loading;
