import { BP, Colors, Gradients, Mixins, mq, Typography } from '~/styles';
import { type TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  contentOverview: {
    overflowY: 'scroll',
    alignSelf: 'end',
    maxHeight: '100%',
  },
  contentOverviewHeader: {
    alignItems: 'center',
    borderBottom: '1px solid',
    borderColor: Colors.white15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 auto',
    padding: '48px 0 40px',
    width: 'calc(100% - 40px)',
    [mq(BP.Medium)]: {
      padding: '80px 0 60px',
      width: 'calc(100% - 84px)',
    },
    [mq(BP.Large)]: {
      borderBottom: 'none',
      padding: '0 0 60px',
      width: 'calc(100% - 120px)',
    },
  },
  coverImg: {
    marginBottom: 20,
    width: 100,
    [mq(BP.Medium)]: {
      width: 156,
    },
    [mq(BP.Large)]: {
      marginBottom: 40,
      width: 178,
    },
  },
  title: {
    ...Typography.titleSection,
    ...Mixins.truncateText(3),
    color: Colors.white,
    marginBottom: 5,
    textAlign: 'center',
    width: '100%',
  },
  subtitle: {
    textAlign: 'center',
    maskImage: Gradients.playerOverflowText,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  label: {
    ...Typography.metadataLarge,
    color: Colors.white60,
  },
  link: {
    textDecoration: 'underline',
  },
  adBanner: {
    marginBottom: 20,
    width: 120,
    height: 100,
    [mq(BP.Medium)]: {
      width: 300,
      height: 250,
    },
  },
  content: {
    flexGrow: 1,
    paddingBottom: 300,
    [mq(BP.Medium)]: {
      paddingBottom: 290,
    },
  },
};

export default styles;
