export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Newsletter</title>
    <path d="M11 12h13M11 16h13M11 20h13" stroke="currentColor" strokeWidth="1.5" />
    <path
      d="M4 26a2 2 0 0 0 2-2V6h23v18a2 2 0 0 1-2 2H4Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M4 26a2 2 0 0 1-2-2V10"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);
