import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  // Images in the overlay are duplicates of existing sortable item, so we need to override the opacity to prevent flicker
  overlay: {
    img: {
      opacity: 1,
    },
  },
};

export default styles;
