import { useTranslation } from 'react-i18next';

import BaseLink from '~/components/BaseLink';
import { IFeaturedCardProps } from '~/components/FeaturedCard';
import PlayStateArea from '~/components/PlayStateArea';
import Bulleted from '~/components/PlayStateArea/components/Bulleted';
import useLinkOrButton from '~/hooks/use-link-or-button';
import { TStyles } from '~/types/emotion-styles';
import { LinkType } from '~/types/links';
import { appendRightArrow } from '~/utils/strings';

import styles from './styles';

type TTextBlock = Pick<
  IFeaturedCardProps,
  | 'ctaHref'
  | 'duration'
  | 'entitySubtype'
  | 'entityURL'
  | 'eyebrow'
  | 'isExclusiveStation'
  | 'isExternalUrl'
  | 'isLive'
  | 'isPlayable'
  | 'isPlaying'
  | 'isScrubbing'
  | 'linkText'
  | 'liveUntil'
  | 'onPlayStateClick'
  | 'publishDate'
  | 'resumePoint'
  | 'subtitle'
  | 'title'
>;

const TextBlock = ({
  ctaHref,
  duration,
  entityURL,
  eyebrow,
  isExclusiveStation,
  isExternalUrl,
  isLive,
  isPlayable,
  isPlaying,
  isScrubbing,
  linkText = '',
  liveUntil,
  publishDate,
  resumePoint,
  subtitle,
  title,
  titleCss,
  onPlayStateClick,
}: TTextBlock & { titleCss?: TStyles }): JSX.Element => {
  const { t } = useTranslation();

  const titleAndSubtitle = (
    <div css={styles.titleSubtitleContainer}>
      <div css={[styles.title, titleCss]}>{title}</div>
      {!!subtitle && <div css={styles.subtitle}>{subtitle}</div>}
    </div>
  );

  const linkContainer = useLinkOrButton({
    children: titleAndSubtitle,
    props: {
      ariaLabel: t('horizontalEntityCard.ariaLabel.link', { title }),
      as: LinkType.Anchor,
      href: entityURL,
    },
    styles: styles.linkContainer,
  });

  const playStateChildren = (
    <PlayStateArea
      duration={duration}
      publishDate={publishDate}
      resumePoint={resumePoint}
      isLive={isLive}
      isPlayable={isPlayable}
      isPlaying={isPlaying}
      isScrubbing={isScrubbing}
      liveUntil={liveUntil}
      isExclusiveStation={isExclusiveStation}
    />
  );

  const playStateButton = useLinkOrButton({
    children: playStateChildren,
    props: {
      as: LinkType.Button,
      ariaLabel: t(`horizontalEntityCard.ariaLabel.${resumePoint ? 'resume' : 'play'}`, { title }),
      onClick: (e) => {
        e.preventDefault();
        onPlayStateClick && onPlayStateClick();
      },
    },
    styles: styles.playStateButton,
  });
  const isPlayStateButtonVisible = !isExternalUrl && (isPlayable || isExclusiveStation || isLive);
  const shouldDisplayEyebrow = !!eyebrow;

  return (
    <div css={styles.container}>
      {shouldDisplayEyebrow &&
        (Array.isArray(eyebrow) ? (
          <Bulleted hasOverflowGradient bulletedCss={styles.eyebrow} title={eyebrow} />
        ) : (
          <span css={styles.eyebrow}>{eyebrow}</span>
        ))}

      {/* NOTICE: Rendering Play State Area / "Go-To" Link */}
      {isPlayStateButtonVisible ? (
        /* For playable Featured cards, a primary click leads to the entity screen, secondary click plays */
        <>
          {entityURL ? linkContainer : titleAndSubtitle}
          {playStateButton}
        </>
      ) : (
        /* NOTICE: note that for show/podcast parent entity, the play button won't be displayed
           Instead, it will show a ‘go to show/podcast page’ link. Based on Content Team Analysis (CTA) */
        <>
          {titleAndSubtitle}
          <BaseLink href={ctaHref} css={[styles.linkText, styles.linkContainer]}>
            {appendRightArrow(linkText)}
          </BaseLink>
        </>
      )}
    </div>
  );
};

export default TextBlock;
