import { useTranslation } from 'react-i18next';

import { Icons } from '~/components/Icon/constants';
import OptionsMenuItem from '~/components/OptionsMenu/components/OptionsMenuItem';
import useWebQueue from '~/hooks/use-web-queue';

export interface IQueueMenuItemProps {
  id: string;
  isDisabled?: boolean;
  onClickCallback?: () => void;
}

const QueueMenuItem = ({
  id,
  isDisabled = false,
  onClickCallback,
}: IQueueMenuItemProps): JSX.Element => {
  const { t } = useTranslation();
  const queue = useWebQueue();

  const isQueued = queue.contains(id);
  const icon = isQueued ? Icons.QueueRemove : Icons.QueueAdd;
  const onClick = () => {
    isQueued ? queue.remove([id]) : queue.add(id);
    onClickCallback && onClickCallback();
  };
  const text = isQueued ? t('optionsMenu.removeFromQueue') : t('optionsMenu.addToQueue');

  return <OptionsMenuItem icon={icon} isDisabled={isDisabled} onClick={onClick} text={text} />;
};

export default QueueMenuItem;
