import { forwardRef } from 'react';

import { Link } from 'react-router-dom';

import { TStyles } from '~/types/emotion-styles';
import { IGlobalAsAnchorProps, TAnchorRef } from '~/types/links';
import { isRelativeURL } from '~/utils/strings';

interface IExternalLinkProps {
  rel?: string;
  target?: string;
}

export interface IBaseLinkProps extends Omit<IGlobalAsAnchorProps, 'as'> {
  linkCss?: TStyles;
}

const BaseLink = ({ children, href, linkCss, ...rest }: IBaseLinkProps, ref: TAnchorRef) => {
  if (!href) return null;

  const isExternal = !isRelativeURL(href);

  const externalLinkProps: IExternalLinkProps = isExternal
    ? { rel: 'noopener noreferrer', target: '_blank' }
    : {};

  const linkProps = {
    css: linkCss,
    ref,
    ...rest,
  };

  // react-router-dom only allows relative urls, recommends <a> for external urls
  // See https://github.com/remix-run/react-router/issues/1147
  return isExternal ? (
    <a href={href} {...externalLinkProps} {...linkProps}>
      {children}
    </a>
  ) : (
    <Link to={href} {...linkProps}>
      {children}
    </Link>
  );
};

export default forwardRef(BaseLink);
