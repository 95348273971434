
export enum PlatformSettingsKey {
  VERBOSE_LOGGING = 'verboseLogging',
  AMPERWAVE_END_TO_END_LOGGING = 'amperwaveEndToEndLogging'
}

class AudacyPlatformSettings {
  settings: Record<PlatformSettingsKey, boolean> = {
    [PlatformSettingsKey.VERBOSE_LOGGING]: false,
    [PlatformSettingsKey.AMPERWAVE_END_TO_END_LOGGING]: false
  };
  listeners: Record<string, Array<(setting: boolean) => void>> = {};
  
  constructor() {
    this.listeners = {};
    Object.keys(this.settings).forEach(key => {
      this.listeners[key] = [];
    });
  }

  setPlatformSettings(key: PlatformSettingsKey, setting: boolean) {
    this.settings[key] = setting;
    this.listeners[key].forEach(listener => listener(setting));
  }

  addListener(key: PlatformSettingsKey, listener: (setting: boolean) => void) {
    this.listeners[key].push(listener);
  }

  removeListener(key: PlatformSettingsKey, listener: (setting: boolean) => void) {
    this.listeners[key] = this.listeners[key].filter(l => l !== listener);
  }

  getPlatformSettings(key: PlatformSettingsKey): boolean {
    return this.settings[key];
  }

}
export default new AudacyPlatformSettings();
