import React from 'react';

import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon';
import { Icons } from '~/components/Icon/constants';
import styles from '~/components/SearchBar/styles';
import { Visibility } from '~/styles';

const SearchBarLabel = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <label htmlFor="term" css={Visibility.visHidden}>
        {t('searchNavLandingScreen.overview.searchInput.accessibilityLabel')}
      </label>
      <span css={[styles.iconContainer]}>
        <Icon name={Icons.Search} sizeInPx={16} />
      </span>
    </>
  );
};

export default SearchBarLabel;
