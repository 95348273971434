import { isIosSafari, isSafari } from '@braintree/browser-detection';
import { TConnectivityType } from '../Container';

export const isMobileBrowser = (): boolean =>
  Boolean(navigator?.userAgent?.match(/iPhone|iPod|Android/i));

export const isAnySafari = (userAgent?: string): boolean => {
  return isSafari(userAgent) || isIosSafari(userAgent);
};

type StringMap = {
  [key: string]: string;
};

export const expandUrlWithMacros = (url: string, data: StringMap): string => {
  const expandedUrl = url.replace(/\{([^}]+)}/g, (match, key) => {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      return data[key];
    } else {
      return match;
    }
  });

  return expandedUrl;
};


export const getWebConnectivityType = (): TConnectivityType => {
  // @ts-ignore next-line
  return navigator?.connection?.effectiveType ?? 'unknown';
}
