import clientServices from '@audacy-clients/core/utils/clientServices';
import { IViewContext } from '@audacy-clients/core/utils/viewContext';

export const sendBulkFollowOrUnfollowEvent = (
  viewContext: IViewContext,
  ids: string[],
  isFollow: boolean,
  isOnboarding = false,
): void => {
  ids.forEach((id) =>
    clientServices?.getAnalyticServices().sendFollow(viewContext, id, isOnboarding, isFollow),
  );
};

export const followOrUnfollowWithDataEvents = async (
  ids: string[],
  viewContext: IViewContext,
  isFollow: boolean,
  isOnboarding = false,
): Promise<void> => {
  const personalizationServices = clientServices.getPersonalizationServices();

  await (isFollow
    ? personalizationServices.addFollows(ids)
    : personalizationServices.bulkUnfollow(ids));

  sendBulkFollowOrUnfollowEvent(viewContext, ids, isFollow, isOnboarding);
};
