import { useState } from 'react';

import { IChapter } from '@audacy-clients/core/atoms/wrappers/chapter';
import { IEpisode } from '@audacy-clients/core/atoms/wrappers/types';
import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon';
import { Icons } from '~/components/Icon/constants';
import SectionWrapper from '~/components/SectionWrapper';
import { TStyles } from '~/types/emotion-styles';

import ChapterItem from './Components/ChapterItem';
import styles from './styles';
import { getChaptersToShowConfig } from './utils';

interface IChaptersProps {
  chapters: IChapter[];
  episode: IEpisode;
  onItemClick: (chapter: IChapter) => void;
  liveChapterIndex?: number;
  maxItems?: number;
  playingChapterIndex?: number;
  hasSectionWrapper?: boolean;
  containerCss?: TStyles;
}

const Chapters = ({
  chapters,
  episode,
  hasSectionWrapper = true,
  onItemClick,
  liveChapterIndex = -1,
  maxItems = 4,
  playingChapterIndex = -1,
  containerCss,
}: IChaptersProps): JSX.Element => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);

  const { chaptersToShow, relativeLiveIndex, relativePlayingIndex } = getChaptersToShowConfig(
    showAll,
    chapters,
    maxItems,
    playingChapterIndex,
    liveChapterIndex,
  );

  const chapterList = (
    <ol>
      {chaptersToShow.map((chapter, index) => (
        <ChapterItem
          accessibilityLabelIndex={`${t('chapters.accessibilityPrefix')} ${t(
            'accessibilityLabels.countLabel',
            {
              index: index + 1,
              total: chapters.length,
            },
          )}`}
          key={chapter.startOffset}
          chapter={chapter}
          episode={episode}
          onClick={onItemClick}
          isLiveChapter={index === relativeLiveIndex}
          isPlaying={index === relativePlayingIndex}
        />
      ))}
    </ol>
  );

  const footerContent = (
    <button onClick={() => setShowAll(!showAll)} css={styles.showAll}>
      {!showAll ? t('chapters.collapsible.seeAll') : t('chapters.collapsible.seeLess')}
      <Icon
        name={!showAll ? Icons.CaretDown : Icons.CaretUp}
        sizeInPx={12}
        iconCss={styles.showAllIcon}
        ariaHidden
      />
    </button>
  );

  return hasSectionWrapper ? (
    <SectionWrapper
      title={t('chapters.title')}
      footerContent={maxItems > 0 && chapters.length > maxItems ? footerContent : undefined}
      containerCss={containerCss}
    >
      {chapterList}
    </SectionWrapper>
  ) : (
    <div css={styles.container}>
      {chapterList}
      <div css={styles.footer}>{footerContent}</div>
    </div>
  );
};

export default Chapters;
