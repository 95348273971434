/* eslint-disable @typescript-eslint/no-explicit-any */
import { IBrazeInstance } from './@types/braze';
import { type IFeatureFlags } from '@audacy-clients/core/atoms/config/settings';
import AnalyticServices from './analyticServices';
import AudioServices from './audioServices';
import { ApiVersion, Environment } from './Constants';
import { TConnectivityType } from './Container';
import { ILogger, IddError, IddLogger } from './logger';
import PersonalizationServices from './personalizationServices';

/**
 * Easier to use global config object for client services
 */
const clientServicesConfig = {} as IClientServicesConfig;
export default clientServicesConfig;
export interface IClientServicesConfig {
  analyticServices: Omit<
    ConstructorParameters<typeof AnalyticServices>[0],
    'eventSender' | 'logger' | 'marketingDataProvider' | 'networkStateProvider'
  >;
  dataServices: {
    env: Environment;
    ver: ApiVersion;
  };
  personalizationServices: Omit<
    ConstructorParameters<typeof PersonalizationServices>[0],
    'logger' | 'profileApi' | 'experienceApi'
  >;
  audioServices: Omit<
    ConstructorParameters<typeof AudioServices>[0],
    | 'unauthenticatedProvider'
    | 'marketingDataProvider'
    | 'personalizationProvider'
    | 'analyticsProvider'
    | 'playerSettingsProvider'
    | 'credentialsProvider'
    | 'logger'
    | 'locationProvider'
    | 'dataProvider'
  >;
  /**
   * Used to pass a us_privacy string to ads to comply with privacy laws.
   */
  disableTargetedAds?: boolean;
  logger: ILogger;
  ddError: IddError;
  ddLogger?: IddLogger;
  featureFlags?: IFeatureFlags;
  deviceInfoProvider?: IDeviceInfoProvider;
  callBrazeEvent?: IBrazeInstance['logCustomEvent'];
  removeFromBrazeAttributeArray?: IBrazeInstance['removeFromCustomUserAttributeArray'];
  addToBrazeAttributeArray?: IBrazeInstance['addToCustomUserAttributeArray'];
}

export interface INetworkInfo {
  bandwidthEstimateBPS?: number;
  historicBandwidthEstimateBPS?: Array<{
    bandwidth: number;
    timestamp: number;
  }>;
  latencyEstimate?: number;
  type: TConnectivityType;
}

export interface IDeviceInfoProvider {
  getNetworkInfo: () => Promise<INetworkInfo>;
  getAudioRoute: () => string;
}

/**
 * Returns "idfa:{token}" | "gaid:{token}" | "cookie:{token}"
 */
export type AdvertisingIdFunction = () => Promise<string>;

export enum AdvertisingIdAttsType {
  NOT_DETERMINED = '0',
  RESTRICTED = '1',
  DENIED = '2',
  AUTHORIZED = '3',
}
