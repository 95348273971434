import { text } from '~/components/PlayStateArea/styles';
import { Colors, Mixins, Typography } from '~/styles';
import { TStyleSheet, TStyles } from '~/types/emotion-styles';

const inlineFlexCenter: TStyles = {
  display: 'inline-flex',
  alignItems: 'center',
};

const styles: TStyleSheet = {
  container: {
    ...inlineFlexCenter,
    gap: 20,
    flex: 1,
    borderRadius: 5,
    textAlign: 'left',
    position: 'relative',
    width: '100%',
    ...Mixins.transition('background-color'),
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  timeContainer: {
    ...inlineFlexCenter,
    marginRight: 0,
  },
  imageContainer: {
    width: 62,
    position: 'relative',
  },
  entityImage: {
    ...Mixins.transition('opacity'),
  },
  bulletedContainer: {
    display: 'grid',
    // Eyebrow semantically appears after the title, visually appears before.
    order: -1,
    ...Mixins.overflowGradient('to right'),
  },
  title: {
    color: Colors.white,
    ...Typography.metadataLarge,
    ...Mixins.truncateText(2),
    ...Mixins.transition('color'),
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    flex: 1,
  },
  playingNowContainer: {
    ...inlineFlexCenter,
    gap: 4,
    color: Colors.audacyOrange,
  },
  playingNowText: {
    ...text,
    color: Colors.audacyOrange,
  },
};

export default styles;
