import { ViewIDKey } from '@audacy-clients/core/atoms/config/constants';
import { SerializableParam } from 'recoil';

export enum RequestType {
  LandingPage,
  ContentPage,
  PathPage,
}

interface IViewRequest {
  viewId: string | ViewIDKey;
}

export interface ILandingPageRequest extends IViewRequest {
  type: RequestType.LandingPage;
}

export interface IContentPageRequest extends IViewRequest {
  type: RequestType.ContentPage;
  contentId: string;
}

export interface IPathRequest {
  type: RequestType.PathPage;
  path: string;
}

export type TModuleRequest = (ILandingPageRequest | IContentPageRequest | IPathRequest) &
  SerializableParam;
