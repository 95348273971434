import {
  isQueueItemStandaloneChapter,
  TQueueItem,
} from '@audacy-clients/client-services/src/personalizationServices/types';

import { wrapEpisode } from './episode';
import { wrapStandaloneChapter } from './standaloneChapter';
import { IQueueItem } from './types';

export const wrapQueueItem = (queueItem: TQueueItem): IQueueItem => {
  if (isQueueItemStandaloneChapter(queueItem)) {
    return wrapStandaloneChapter(queueItem);
  }
  return wrapEpisode(queueItem);
};
