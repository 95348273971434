import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import english from './locales/en/common.json';

// eslint-disable-next-line react-hooks/rules-of-hooks
void use(initReactI18next).init({
  debug: false,
  defaultNS: 'translation',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  lng: 'en',
  ns: ['translations'],
  resources: {
    en: english,
  },
});

export default i18n;
