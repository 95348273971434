import { Colors, Typography } from '~/styles';
import { TStyles, TStyleSheet } from '~/types/emotion-styles';

export const bullet: TStyles = {
  background: Colors.white60,
  borderRadius: 3,
  height: 2,
  minWidth: 2,
};

export const container: TStyles = {
  ...Typography.metadataSmall,
  alignItems: 'center',
  color: Colors.white60,
  display: 'inline-flex',
  gap: 10,
  width: '100%',
};

export const text: TStyles = {
  color: 'white',
  ...Typography.buttonSmall,
};

const styles: TStyleSheet = {
  bullet,
  container,
  text,
};

export default styles;
