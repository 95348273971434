export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Favorite</title>
    <path
      d="m16 29 .737-.142a.75.75 0 0 0-1.473 0L16 29Zm0 0-.476.58a.75.75 0 0 0 1.213-.442L16 29Zm-1.223-.995.472-.583-.472.583ZM16 8.534l-.619.423a.75.75 0 0 0 1.238 0L16 8.534Zm1.223 19.471-.471-.583.471.583ZM16 29l-.737.138a.75.75 0 0 0 1.214.441L16 29Zm-.736-.142v.002l-.001.002L16 29l.737.138v.003l-1.473-.283Zm1.213-.437c-.377-.31-.79-.644-1.229-.999l-.943 1.166c.44.356.848.686 1.219.991l.953-1.158Zm-1.229-.999c-2.234-1.809-5.135-4.157-7.478-6.708-2.375-2.585-4.02-5.213-4.02-7.571h-1.5c0 2.973 2.016 5.974 4.415 8.586 2.43 2.645 5.419 5.062 7.64 6.859l.944-1.166ZM3.75 13.142c0-4.157 2.863-7.392 6.75-7.392v-1.5c-4.858 0-8.25 4.056-8.25 8.893h1.5ZM10.5 5.75c1.881 0 3.603 1.34 4.881 3.207l1.238-.847c-1.39-2.03-3.505-3.86-6.119-3.86v1.5Zm11-1.5c-2.614 0-4.73 1.83-6.119 3.86l1.238.847c1.278-1.867 3-3.207 4.881-3.207v-1.5Zm8.25 8.893c0-4.837-3.392-8.893-8.25-8.893v1.5c3.887 0 6.75 3.235 6.75 7.393h1.5ZM17.695 28.588c2.221-1.797 5.21-4.214 7.64-6.86 2.4-2.61 4.415-5.612 4.415-8.585h-1.5c0 2.358-1.645 4.986-4.02 7.57-2.343 2.552-5.244 4.9-7.478 6.709l.943 1.166Zm-1.218.991c.37-.305.779-.635 1.218-.991l-.943-1.166c-.438.355-.852.69-1.228.999l.953 1.158Zm-1.213-.438v-.002h-.001v-.001L16 29l.737-.138v-.004l-1.473.283Z"
      fill="currentColor"
    />
  </svg>
);
