import { ClickFeature } from '@audacy-clients/client-services';
import { useChapters, usePlayingChapter } from '@audacy-clients/core/atoms/chapters';
import {
  isAdState,
  isInPlayer,
  nowPlaying,
  playerContentIsLiveState,
} from '@audacy-clients/core/atoms/player';
import { type IChapter } from '@audacy-clients/core/atoms/wrappers/chapter';
import { useClientServices } from '@audacy-clients/core/utils/clientServices';
import { getRecoil } from '@audacy-clients/core/utils/recoil';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import Icon from '~/components/Icon';
import { Icons } from '~/components/Icon/constants';
import { useAudioHelpersWeb } from '~/hooks/use-audio-helpers-web';
import { useGetViewContext } from '~/state/dataEvents';
import useToast from '~/state/toast';
import { Colors } from '~/styles';
import styles, { Constants } from './styles';

const TranscriptChapterControls = () => {
  const { episodeDataObject } = useRecoilValue(nowPlaying) ?? {};
  const isAd = useRecoilValue(isAdState);
  const chapters = useChapters({ episodeId: episodeDataObject?.id ?? '', live: false });
  const playingChapterIndex = usePlayingChapter(episodeDataObject?.id);
  const { showToast } = useToast();
  const audioHelpers = useAudioHelpersWeb();
  const { loading } = useClientServices();
  const getViewContext = useGetViewContext();
  const { t } = useTranslation();

  const previousChapterIndex = playingChapterIndex - 1;
  const nextChapterIndex = playingChapterIndex + 1;

  const handleChapterClick = useRecoilCallback(
    ({ snapshot }) =>
      async (chapter?: IChapter) => {
        if (!chapter || !episodeDataObject) {
          return;
        }

        const { startOffset, title } = chapter;
        const isAd = getRecoil(isAdState);
        const contentIsLive = getRecoil(playerContentIsLiveState);

        if (isAd && !contentIsLive) {
          showToast({ title: t('chapters.disabledDuringAd') });
          return;
        }

        const currentlySelected = await snapshot.getPromise(isInPlayer(episodeDataObject.id));
        const playContext = {
          chapter: title,
          contentId: episodeDataObject.id,
          feature: ClickFeature.CHAPTER,
        };

        if (currentlySelected && !loading) {
          const startTimeSeconds = episodeDataObject.startTime / 1000;
          audioHelpers.seekToTime(startOffset - startTimeSeconds);
          return;
        }

        audioHelpers.play(episodeDataObject?.getDataObject(), playContext, {
          autoPlay: true,
          startOffset,
        });
      },
    [getViewContext, loading, audioHelpers, showToast, t, episodeDataObject],
  );

  const handleNextChapterClick = useCallback(() => {
    if (playingChapterIndex === Constants.noIndexFounded) {
      return;
    }
    const nextChapter = chapters.find((el) => el.index === nextChapterIndex);
    void handleChapterClick(nextChapter);
  }, [chapters, handleChapterClick, playingChapterIndex, nextChapterIndex]);

  const handlePreviousChapterClick = useCallback(() => {
    if (playingChapterIndex === Constants.noIndexFounded) {
      return;
    }
    const previousChapter = chapters.find((el) => el.index === previousChapterIndex);
    void handleChapterClick(previousChapter);
  }, [chapters, handleChapterClick, playingChapterIndex, previousChapterIndex]);

  return (
    <div css={styles.container}>
      <button
        css={[styles.button, (previousChapterIndex < 0 || isAd) && styles.buttonDisabled]}
        onClick={handlePreviousChapterClick}
        disabled={isAd}
      >
        <Icon name={Icons.CaretLeft} iconColor={Colors.white} ariaHidden={false} sizeInPx={14} />
        <span css={styles.buttonTitle}>{t('chapters.previous')}</span>
      </button>

      <button
        css={[
          styles.button,
          (nextChapterIndex >= chapters.length || isAd) && styles.buttonDisabled,
        ]}
        onClick={handleNextChapterClick}
        disabled={isAd}
      >
        <span css={styles.buttonTitle}>{t('chapters.next')}</span>
        <Icon name={Icons.CaretRight} iconColor={Colors.white} ariaHidden={false} sizeInPx={14} />
      </button>
    </div>
  );
};
export default TranscriptChapterControls;
