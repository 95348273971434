import { Mixins } from '~/styles';
import { Constants } from '~/styles/constants';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  item: {
    position: 'relative', // Required for child component(s)
    marginBottom: 20,
    ...Mixins.reduceMotion(),
    '&:last-child': {
      marginBottom: 0,
    },
    opacity: 1,
    transition: 'opacity 0.3s ease',
    ...Mixins.focusOutline({ outlineOffset: -1 }),
  },
  childrenInvisible: {
    opacity: 0,
    transition: 'opacity 0.3s ease',
  },
  handle: {
    position: 'absolute',
    right: 3, // aligns icon to right-edge of container; 3px buffer to show full focus outline
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'ns-resize',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: Constants.minButtonSize.finePointer,
    width: Constants.minButtonSize.finePointer,
    '&:focus': {
      // Needed for :focus style specificity
      position: 'absolute',
    },
    '&:focus-visible': {
      ...Mixins.focusOutline,
    },
  },
};

export default styles;
