import { useEffect } from 'react';

const useListenWhileMounted = (setIsListening: (isListening: boolean) => void): void => {
  useEffect(() => {
    setIsListening(true);
    return () => setIsListening(false);
  }, [setIsListening]);
};

export default useListenWhileMounted;
