import { Property } from 'csstype';

import { arrayToCubicBezier } from '~/utils/strings';

interface IEasingArrayProps {
  [key: string]: number[];
}

interface IEasingProps {
  [key: string]: Property.TransitionTimingFunction;
}

const easingValuesArrays: IEasingArrayProps = {
  cb1: [0.19, 1, 0.22, 1],
};

const easingValues: IEasingProps = {
  ease: 'ease',
  cb1: arrayToCubicBezier(easingValuesArrays.cb1),
};

export const Easing: IEasingProps = {
  default: easingValues.ease,
  navigation: easingValues.cb1,
  overlay: easingValues.cb1,
};

// Use array for Framer Motion ease
export const EasingArrays: IEasingArrayProps = {
  navigation: easingValuesArrays.cb1,
};
