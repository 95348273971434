export enum LinkingActions {
  AppTrackingPrompt = 'PROMPT_APP_TRACKING',
  AutoplayFull = 'AUTOPLAY_FULL',
  AutoplayMini = 'AUTOPLAY_MINI',
  Follow = 'FOLLOW',
  LocationPrompt = 'PROMPT_LOCATION',
  Login = 'LOGIN',
  OpenAppSettings = 'OPEN_APP_SETTINGS',
  PushNotificationPrompt = 'PROMPT_PUSH_NOTIFICATIONS',
  Reauthenticate = 'REAUTHENTICATE',
  OpenDevMenu = 'ODM',
}

export enum DeeplinkQueryParams {
  Action = 'action',
  ActionContentId = 'actionContentId',
  ActionContentIds = 'actionContentIds',
  ActionOffset = 'actionOffset',
  ContentId = 'contentId',
  utmCampaign = 'utm_campaign',
  utmContent = 'utm_content',
  utmMedium = 'utm_medium',
  utmSource = 'utm_source',
  utmTerm = 'utm_term',
  ViewId = 'viewId',
  ViewType = 'viewType',
  Key = 'key',
}

export interface IDeepLinkQueryParams {
  [DeeplinkQueryParams.Action]?: LinkingActions;
  [DeeplinkQueryParams.ActionContentId]?: string;
  [DeeplinkQueryParams.ActionContentIds]?: string;
  [DeeplinkQueryParams.ActionOffset]?: string;
  [DeeplinkQueryParams.ContentId]?: string;
  [DeeplinkQueryParams.ViewId]?: string;
  [DeeplinkQueryParams.ViewType]?: string;
  [DeeplinkQueryParams.Key]?: string;
  [DeeplinkQueryParams.utmCampaign]?: string;
  [DeeplinkQueryParams.utmContent]?: string;
  [DeeplinkQueryParams.utmMedium]?: string;
  [DeeplinkQueryParams.utmSource]?: string;
  [DeeplinkQueryParams.utmTerm]?: string;
}
