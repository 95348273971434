import { mq, BP, Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 20,
  },
  homeButton: {
    //KY: button loses large button styling at wider breakpoints ~1440px (or more) and in full screen
    ...Typography.buttonLarge,
  },
  title: {
    ...Typography.titleMisc,
  },
  text: {
    flex: 'none',
    textAlign: 'center',
    ...Typography.bodySmall,
    [mq(BP.Small)]: {
      paddingLeft: 85.5,
      paddingRight: 85.5,
    },
  },
  gradientContainer: {
    //KY: height and width set at fixed values so home button is clickable, avoids adding Z-index
    //there might be potential issues if gradient is used where there is interactable content such as buttons
    height: 200,
    width: 365,
    right: 0,
    bottom: 0,
    position: 'fixed',
  },
  gradient: {
    height: '100%',
    width: '100%',
    display: 'none',
    left: 0,
    top: 0,
    [mq(BP.Large)]: {
      display: 'flex',
    },
  },
  gradientColor: {
    position: 'absolute',
    display: 'flex',
    '&:nth-of-type(1)': {
      right: '70%',
      bottom: '-100%',
    },
    '&:nth-of-type(2)': {
      right: '0%',
      bottom: '-40%',
    },
  },
};

export default styles;
