import { TModuleRequest } from '@audacy-clients/core/atoms/modules/requests';
import { IView } from '@audacy-clients/core/atoms/wrappers/modules';
import ModuleRenderer from '@audacy-clients/core/components/ModuleRenderer';
import { TViewContainer } from '@audacy-clients/core/components/ModuleRenderer/types';

import { useSetViewContext } from '~/state/dataEvents';

import { moduleMap } from './constants';
import ModuleInViewWrapper from './ModuleInViewWrapper';

interface IProps<T, R> {
  onViewLoaded?: (view: T) => void;
  render?: (view: T, modules: JSX.Element) => JSX.Element;
  request: R;
}

const ViewContainer: TViewContainer = ({ children }) => <>{children}</>;

const ModuleRendererWrapped: React.FC<IProps<IView, TModuleRequest>> = (props) => {
  const setViewContext = useSetViewContext();

  const onViewLoaded = (view: IView) => {
    setViewContext(view);
    props.onViewLoaded?.(view);
  };

  return (
    <ModuleRenderer
      {...props}
      moduleMap={moduleMap}
      moduleWrapper={ModuleInViewWrapper}
      viewContainer={ViewContainer}
      onViewLoaded={onViewLoaded}
      virtual={true}
    />
  );
};

export default ModuleRendererWrapped;
