import { IFeatureFlags } from '@audacy-clients/core/atoms/config/settings';
import { getBrazeFeatureFlags } from '@audacy-clients/core/utils/braze';
import { atom } from 'recoil';

export const brazeFeatureFlagsState = atom<IFeatureFlags>({
  key: 'BrazeFeatureFlags',
  default: {} as IFeatureFlags,
  effects: [
    ({ setSelf }) => {
      getBrazeFeatureFlags().then((flags) => setSelf(flags));
    },
  ],
});
