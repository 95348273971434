import { useEffect, useRef, useState } from 'react';

import {
  queueIsLoadingState,
  queueState as queueStateReadOnly,
} from '@audacy-clients/core/atoms/queue';
import { IQueueItem } from '@audacy-clients/core/atoms/wrappers/types';
import { IUseQueue, useQueue } from '@audacy-clients/core/hooks/queue';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import usePrevious from '~/hooks/use-previous';
import { useGetViewContextFn } from '~/state/dataEvents';
import useToast from '~/state/toast';

interface IWebQueue extends Omit<IUseQueue, 'add' | 'remove'> {
  add: (id: string) => void;
  remove: (ids: string[]) => void;
  isLoading: boolean;
  items: IQueueItem[];
  currentId: string;
  selectedItems: string[];
}

const useWebQueue = (): IWebQueue => {
  const isLoading = useRecoilValue(queueIsLoadingState);
  const { showToast } = useToast();
  const { t } = useTranslation();
  const queueState = useRecoilValue(queueStateReadOnly);
  const { add, remove, ...queue } = useQueue(useGetViewContextFn());
  const [queueClone, setQueueClone] = useState(queueState);
  const { currentId, items } = queueClone;
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const itemsToBeRemoved = useRef<string[]>();
  const prevQueueItems = usePrevious(queueState.items, queueClone.items);

  useEffect(() => {
    setQueueClone(queueState);
  }, [queueState]);

  const onRemoveSuccess = (undoCallback: () => void): void => {
    setSelectedItems([]);
    itemsToBeRemoved.current = [];
    showToast({
      cta: {
        action: () => {
          undoCallback();
        },
        title: t('queueActions.removeFromQueue.toast.cta.title'),
      },
      title: t('queueActions.removeFromQueue.toast.title'),
    });
  };

  const onRemoveError = (): void => {
    setQueueClone({
      ...queueClone,
      items: prevQueueItems,
    });
    setSelectedItems(itemsToBeRemoved.current || []);
    showToast({
      title: t('queueActions.removeFromQueue.error.title'),
    });
    itemsToBeRemoved.current = [];
  };

  const onAddSuccess = (undoCallback: () => void): void => {
    showToast({
      cta: {
        action: () => {
          undoCallback();
        },
        title: t('queueActions.addToQueue.toast.cta.title'),
      },
      title: t('queueActions.addToQueue.toast.title'),
    });
  };

  return {
    ...queue,
    add: (id) => add(id, { onSuccess: onAddSuccess }),
    remove: (ids) => {
      setSelectedItems([]);
      setQueueClone({
        ...queueClone,
        items: items.filter((item) => !ids.find((itemId) => item.id === itemId)),
      });
      itemsToBeRemoved.current = ids;
      remove(ids, { onSuccess: onRemoveSuccess, onError: onRemoveError });
    },
    isLoading,
    items,
    currentId,
    selectedItems,
  };
};

export default useWebQueue;
