import { useFeatureFlag } from '@audacy-clients/core/atoms/config/settings';

import useAuth from '~/state/auth';

import OptionsMenuButton, { IOptionsMenuButtonProps } from './OptionsMenuButton';

const OptionsMenuButtonWithState = (props: IOptionsMenuButtonProps): JSX.Element | null => {
  const isQueueEnabled = useFeatureFlag('queue') && props.isQueueEnabled;
  const { isLoggedIn } = useAuth();

  return <OptionsMenuButton {...props} isLoggedIn={isLoggedIn} isQueueEnabled={isQueueEnabled} />;
};

export default OptionsMenuButtonWithState;
