import { BP, Colors, Mixins, mq, Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  expiredPlayer: {
    [mq(BP.Large)]: {
      maxWidth: 300,
    },
  },
  expired: {
    ...Typography.metadataSmall,
    color: Colors.white60,
    marginTop: 20,
    marginBottom: 5,
    [mq(BP.Small)]: {
      maxWidth: 300,
    },
    [mq(BP.Medium)]: {
      maxWidth: 'initial',
    },
  },
  expiredButton: {
    ...Typography.metadataSmall,
    ...Mixins.transition('color'),
    fontWeight: 'bold',
    color: Colors.white,
    marginBottom: 10,
    textAlign: 'left',
    transition: 'color 100ms linear',
    ':hover': {
      color: Colors.white60,
    },
  },
};

export default styles;
