import FocusTrap from 'focus-trap-react';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';

import Overlay from '~/components/Overlay';
import { animationStatusName, fadeInOut } from '~/constants/animation';
import { Timing } from '~/styles';

import { type TStyles } from '~/types/emotion-styles';
import { type IDrawerHeaderProps } from './components/DrawerHeader';
import styles from './styles';

type IDrawerProps = {
  children?: React.ReactNode;
  renderDrawerHeader: (onClose: IDrawerContainerProps['onClose']) => JSX.Element;
  contentContainerStyle?: TStyles;
  fullHeight?: boolean;
} & Pick<IDrawerHeaderProps, 'onClose'>;

type IDrawerContainerProps = {
  isOpen: boolean;
} & IDrawerProps;

const drawerMotionProps = {
  [animationStatusName.initial]: {
    y: '100%',
  },
  [animationStatusName.animate]: {
    y: 0,
  },
  [animationStatusName.exit]: {
    y: '100%',
  },
  transition: {
    duration: Timing.drawer,
    ease: 'easeInOut',
    type: 'tween',
  },
};

const Drawer = ({
  children,
  renderDrawerHeader,
  onClose,
  contentContainerStyle,
  fullHeight,
}: IDrawerProps): JSX.Element => {
  useEffect(() => {
    const onEscKeyup = (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keyup', onEscKeyup, false);

    return () => {
      window.addEventListener('keyup', onEscKeyup, false);
    };
  });

  return (
    <FocusTrap>
      {/* extra div needed for FocusTrap, cannot use Fragment */}
      {/* https://github.com/focus-trap/focus-trap-react#usage */}
      <div css={styles.portal} role="menu">
        <motion.div
          {...drawerMotionProps}
          css={[
            styles.drawerContainer,
            fullHeight ? { height: '100%' } : { maxHeight: 'calc(100vh - 30px)' },
          ]}
        >
          <div css={contentContainerStyle ?? styles.drawer}>
            {renderDrawerHeader?.(onClose)}
            {children}
          </div>
        </motion.div>
        <motion.div {...fadeInOut()} transition={drawerMotionProps.transition}>
          <Overlay onClick={onClose} />
        </motion.div>
      </div>
    </FocusTrap>
  );
};

// Inserts Drawer into DOM when triggered
const DrawerContainer = ({ isOpen, ...rest }: IDrawerContainerProps): JSX.Element => {
  return <AnimatePresence>{isOpen && <Drawer {...rest} />}</AnimatePresence>;
};

export default DrawerContainer;
