import { useSongHistory } from '@audacy-clients/core/atoms/songHistory';
import { useModuleContext } from '@audacy-clients/core/components/ModuleRenderer/context';
import { useTranslation } from 'react-i18next';

import FooterLink from '~/components/Link/FooterLink';
import SectionWrapper from '~/components/SectionWrapper';
import SongHistoryListView from '~/components/SongHistory/SongHistoryListView';

import styles from './styles';

const ModuleSongHistoryPreview: React.FC = () => {
  const { t } = useTranslation();
  const { viewRootObjectId } = useModuleContext();
  const stationId = viewRootObjectId || '';
  const songHistory = useSongHistory({ stationId, limit: 5 });
  const pathname = location.pathname;
  const seperator = pathname.endsWith('/') ? '' : '/';
  const songHistoryHref = `${pathname}${seperator}song-history`;

  return songHistory.length > 0 ? (
    <SectionWrapper
      containerCss={styles.sectionContainer}
      title={t('songHistory.title')}
      footerContent={
        <FooterLink
          href={songHistoryHref}
          label={t('songHistory.seeMore')}
          linkCss={styles.footerlink}
          onClick={() => {}}
        />
      }
    >
      <SongHistoryListView items={songHistory} />
    </SectionWrapper>
  ) : (
    <></>
  );
};

export default ModuleSongHistoryPreview;
