import { Borders, BP, mq, Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [mq(BP.Large)]: {
      paddingBottom: 20,
      borderBottom: Borders.separator,
    },
  },
  headerCtas: {
    display: 'flex',
    '> :not(:first-of-type)': {
      marginLeft: 10,
    },
  },
  title: {
    ...Typography.titleSection,
  },

  editSelectors: {
    display: 'flex',
  },
  editSelector: {
    marginBottom: 26,
    marginRight: 20,
  },
  resetPadding: {
    padding: 0,
  },
};

export default styles;
