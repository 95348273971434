import { EntityType } from '@audacy-clients/client-services/core';
import {
  isConsideredPlayed,
  getFixedResumePoint,
} from '@audacy-clients/client-services/src/utils/playbackResumePoints';
import { IContentSummary } from '@audacy-clients/core/atoms/wrappers/types';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';

import {
  noCacheDefaultSelector,
  noCacheDefaultSelectorFamily,
} from './helpers/noCacheDefaultSelector';

export type PlaybackResumePointEntity = Pick<IContentSummary, 'id' | 'startDateTime' | 'duration'> &
  Partial<Pick<IContentSummary, 'entityType' | 'entitySubtype'>>;

export const getResumePointForEntity = (
  playbackResumePoints?: Record<string, number>,
  entity?: PlaybackResumePointEntity,
): IPlaybackResumePoint => {
  if (
    !playbackResumePoints ||
    !entity ||
    !(entity.id in playbackResumePoints) ||
    (entity.entityType !== EntityType.EPISODE &&
      entity.entityType !== EntityType.STANDALONE_CHAPTER)
  ) {
    return {};
  }

  const playbackResumePoint = playbackResumePoints[entity.id];
  const fixedPlaybackResumePointSeconds =
    getFixedResumePoint(playbackResumePoint, entity.startDateTime) ?? playbackResumePoint;

  const isPlayed = isConsideredPlayed(entity.duration, fixedPlaybackResumePointSeconds);

  const playbackResumePointStartOffset = isPlayed ? undefined : playbackResumePoint;

  return {
    fixedPlaybackResumePointSeconds,
    playbackResumePointStartOffset,
  };
};

interface IPlaybackResumePoint {
  fixedPlaybackResumePointSeconds?: number;
  playbackResumePointStartOffset?: number;
}

// NOTICE: dear colleague, before using this, pay attention to my comment
// playbackResumePointsState - this atom has many renders per second
// and incorrect use of it can lead to memory leaks

// make sure you want the live update when you subscribe to it

// if you do not need this value in a live update,
// use this atom as in resumePointForEntityState which returns a callback to receive data
// and does not subscribe to every change
export const playbackResumePointsState = atom<Record<string, number>>({
  default: {},
  key: 'PlaybackResumePoints',
});

export const useGetResumePoints = (): (() => Record<string, number> | undefined) =>
  useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const loadable = snapshot.getLoadable(playbackResumePointsState);
        const maybe = loadable.valueMaybe();

        return maybe;
      },
    [],
  );

export const useResumePoint = (entity?: PlaybackResumePointEntity): IPlaybackResumePoint => {
  const playbackResumePoints = useRecoilValue(playbackResumePointsState);
  return getResumePointForEntity(playbackResumePoints, entity);
};

export type IFixedPlaybackResumePointState = {
  id?: string;
  startDateTime?: Date;
};

export const fixedPlaybackResumePointState = noCacheDefaultSelectorFamily<
  number | undefined,
  IFixedPlaybackResumePointState
>({
  get({ id, startDateTime }) {
    return ({ get }) => {
      if (!id) {
        return 0;
      }

      const playbackResumePoint = get(playbackResumePointsState)?.[id];
      return getFixedResumePoint(playbackResumePoint, startDateTime) ?? playbackResumePoint;
    };
  },
  key: 'FixedPlaybackResumePointState',
});

export const playbackResumePointStartOffset = noCacheDefaultSelector({
  get:
    ({ get }) =>
    (entity?: PlaybackResumePointEntity): number | undefined => {
      const playbackResumePoints = get(playbackResumePointsState);
      const { playbackResumePointStartOffset } = getResumePointForEntity(
        playbackResumePoints,
        entity,
      );
      return playbackResumePointStartOffset;
    },
  key: 'PlaybackResumePointStartOffset',
});

export const resumePointForEntityState = noCacheDefaultSelector({
  get:
    ({ get }) =>
    (entity?: PlaybackResumePointEntity): IPlaybackResumePoint => {
      const playbackResumePoints = get(playbackResumePointsState);
      return getResumePointForEntity(playbackResumePoints, entity);
    },
  key: 'ResumePointForEntityState',
});

export const playbackResumePointsSelector = noCacheDefaultSelector({
  get:
    ({ get }) =>
    (id: string): number | undefined => {
      const playbackResumePoints = get(playbackResumePointsState);
      return playbackResumePoints[id];
    },
  key: 'PlaybackResumePointsSelector',
});
