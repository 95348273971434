import Icon from '~/components/Icon';
import useLinkOrButton from '~/hooks/use-link-or-button';
import { TStyles } from '~/types/emotion-styles';
import { LinkType } from '~/types/links';

import styles from './styles';
import { TButtonProps } from './types';

const Button = ({
  as = LinkType.Button,
  buttonCss,
  label = '',
  icon,
  labelCss,
  size = 'Default',
  theme = 'Primary',
  ...rest
}: TButtonProps): JSX.Element => {
  const isButton = as === LinkType.Button;
  const iconContainerCss =
    size === 'Extended' ? styles.iconContainerExtended : styles.iconContainer;
  const labelThemeCss = theme === 'SecondaryPremium' && styles.themeSecondaryPremiumLabel;

  const children = (
    <>
      {icon && (
        <div css={iconContainerCss}>
          <Icon name={icon} ariaHidden={!icon} sizeInPx={size === 'Extended' ? 20 : 12} />
        </div>
      )}
      <span css={[styles.label, labelThemeCss, labelCss]}>{label}</span>
    </>
  );

  const buttonLinkStyles = [
    styles.baseButton,
    styles.button,
    styles[`size${size}`],
    styles[`theme${theme}`],
    buttonCss,
    isButton && 'isDisabled' in rest && rest.isDisabled ? styles.disabled : {},
  ] as TStyles;

  return useLinkOrButton({
    children,
    styles: buttonLinkStyles,
    props: { as, ...rest },
  });
};

export default Button;
