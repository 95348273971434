import {
  type EntitySubtype,
  EpisodeSubType,
  StandaloneChapterSubType,
} from '@audacy-clients/client-services';
import { playerTimeState } from '@audacy-clients/core/atoms/player';
import {
  scrubbingState,
  type TTranscript,
  useIsNextTranscriptAvailable,
  useTranscriptData,
} from '@audacy-clients/core/atoms/transcripts';
import { type IChapter } from '@audacy-clients/core/atoms/wrappers/chapter';
import {
  type IEpisode,
  type IStandaloneChapter,
  type TPartialWrappedContentObject,
} from '@audacy-clients/core/atoms/wrappers/types';
import { formatSecondsToTime } from '@audacy-clients/core/utils/time';
import { format, fromUnixTime, secondsToMilliseconds } from 'date-fns';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

type UseTranscriptReturnType = {
  loading: boolean;
  hasError: boolean;
  transcript: Array<TTranscript> | undefined;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  duration: number;
  offsetEqualToZero: boolean;
  startOffset?: number;
  title?: string;
  isNextTranscriptAvailable: boolean;
  wasScrubbed: boolean;
};

const Constants = {
  noIndexFounded: -1,
  startOffset: 0,
  defaultSpeakerName: 'Speaker',
  chapterTranscriptItemTimeFormat: 'h:mm:ssaaa',
};
/**
 * Handles the transcript data and the current index of the transcript
 */
const useTranscript = (entity?: IEpisode | IStandaloneChapter): UseTranscriptReturnType => {
  const [currentIndex, setCurrentIndex] = useState(Constants.noIndexFounded);
  const [wasScrubbed, setWasScrubbed] = useState(false);
  const { duration = 0, offset = Constants.startOffset } = useRecoilValue(playerTimeState);
  const isScrubbingState = useRecoilValue(scrubbingState);
  const isNextTranscriptAvailable = useIsNextTranscriptAvailable({
    type: entity?.type,
    id: entity?.id,
  });
  const prevIndexRef = useRef<number | null>(null);

  const offsetInMilliseconds = secondsToMilliseconds(offset);
  const offsetEqualToZero = offsetInMilliseconds === 0;

  const { transcript: transcriptResponse, loading, hasError } = useTranscriptData(entity?.subtype);
  const { startOffset, title, duration: chapterDuration = 0 } = transcriptResponse ?? {};
  const chapterStartOffsetInMs = secondsToMilliseconds(startOffset ?? 0);
  const episodeStartTimeInMs = entity?.startTime;

  const transcript = useMemo(
    () =>
      transcriptResponse?.transcript.map((item) => {
        const correctSpeakerName =
          item.speaker.split(' ')[0] === Constants.defaultSpeakerName &&
          item.speaker.split(' ').length === 2
            ? `${item.speaker.split(' ')[0]} ${Number(item.speaker.split(' ')[1]) + 1}`
            : item.speaker;
        return {
          ...item,
          speaker: correctSpeakerName,
        };
      }),
    [transcriptResponse?.transcript],
  );

  useEffect(() => {
    if (isScrubbingState) {
      setWasScrubbed(true);
    }
  }, [isScrubbingState, setWasScrubbed]);

  useEffect(() => {
    if (!transcript) {
      return;
    }

    let newIndex = Constants.noIndexFounded;

    if (entity?.subtype === EpisodeSubType.BROADCAST_SHOW_EPISODE) {
      const episodeStartTimeInMs = entity?.startTime;

      newIndex = transcript.findIndex(
        (item) =>
          offsetInMilliseconds >=
            getChapterTranscriptItemOffset(
              chapterStartOffsetInMs,
              getRoundedMs(item.start_time),
              episodeStartTimeInMs,
            ) &&
          offsetInMilliseconds <
            getChapterTranscriptItemOffset(
              chapterStartOffsetInMs,
              getRoundedMs(item.end_time),
              episodeStartTimeInMs,
            ),
      );
    } else {
      newIndex = transcript.findIndex(
        (item) =>
          offsetInMilliseconds >= getRoundedMs(item.start_time) &&
          offsetInMilliseconds < getRoundedMs(item.end_time),
      );
    }

    if (newIndex === Constants.noIndexFounded && prevIndexRef.current !== null) {
      setCurrentIndex(prevIndexRef.current);
    } else {
      prevIndexRef.current = newIndex;
      setCurrentIndex(newIndex);
    }
  }, [
    transcript,
    offsetInMilliseconds,
    entity,
    startOffset,
    chapterStartOffsetInMs,
    episodeStartTimeInMs,
  ]);

  return {
    loading,
    hasError,
    transcript,
    currentIndex,
    setCurrentIndex,
    duration:
      entity?.subtype === EpisodeSubType.BROADCAST_SHOW_EPISODE ? chapterDuration : duration,
    offsetEqualToZero,
    startOffset,
    title,
    isNextTranscriptAvailable,
    wasScrubbed,
  };
};

export default useTranscript;

export const getRoundedMs = (time: number) => {
  return Math.floor(time / 1000) * 1000;
};

export const isTranscriptEntitySubtypeValid = (subtype?: EntitySubtype) => {
  switch (subtype) {
    case EpisodeSubType.BROADCAST_SHOW_EPISODE:
    case EpisodeSubType.PODCAST_EPISODE:
    case StandaloneChapterSubType.BROADCAST_EPISODE_CHAPTER:
      return true;
    default:
      return false;
  }
};

export const isNavigableTranscript = (
  entity?: TPartialWrappedContentObject,
  currentChapter?: IChapter,
) => {
  const resultItem =
    entity?.subtype === EpisodeSubType.BROADCAST_SHOW_EPISODE ? currentChapter : entity;
  const { transcript } = resultItem ?? {};
  const { isNavigableTranscript, url } = transcript ?? {};

  return !!isNavigableTranscript && !!url;
};

export const getChapterTranscriptItemOffset = (
  chapterStartOffset = 0,
  chapterTranscriptItemStartTime = 0,
  episodeStartTime = 0,
) => {
  return chapterStartOffset + chapterTranscriptItemStartTime - episodeStartTime;
};

export const getTranscriptTimeSpeaker = (startTimeInMs = 0, speaker = '') => {
  if (!speaker) {
    return '';
  }

  return `${formatSecondsToTime(startTimeInMs / 1000)} - ${speaker}`;
};

export const getChapterTranscriptTimeSpeaker = (
  startTimeInMs = 0,
  speaker = '',
  startOffset = 0,
) => {
  if (!speaker) {
    return '';
  }

  return `${getChapterTranscriptTime(startOffset + startTimeInMs / 1000)} - ${speaker}`;
};

export const getChapterTranscriptTime = (
  seconds = 0,
  timeFormat = Constants.chapterTranscriptItemTimeFormat,
) => format(fromUnixTime(seconds), timeFormat);
