import { ISongHistoryItem } from '@audacy-clients/client-services/src/Constants';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import EntityImage from '~/components/Entity/components/EntityImage';
import Icon from '~/components/Icon';
import { Icons } from '~/components/Icon/constants';
import { IImageSize } from '~/components/Image/types';
import Bulleted from '~/components/PlayStateArea/components/Bulleted';
import styles from '~/components/SongHistory/styles';
import { useViewState } from '~/state/dataEvents';

interface ISongHistoryListItemProps extends ISongHistoryItem {
  imageAlt?: string;
  isFirst: boolean;
  sizes?: IImageSize[];
}

const SongHistoryListItem = ({
  artist,
  imageAlt,
  image,
  isFirst,
  title,
  time,
  sizes,
}: ISongHistoryListItemProps): JSX.Element => {
  const { t } = useTranslation();
  const iconSize = 16;
  const viewData = useViewState();
  const stationImage = viewData?.rootObject?.image || '';

  const playingNowContainer = (
    <div css={styles.playingNowContainer}>
      <span css={styles.playingNowText}>{t('songHistory.playingNow')}</span>
      <Icon name={Icons.LiveSM} sizeInPx={iconSize} />
    </div>
  );

  const titleContainer = (
    <div css={[styles.titleContainer]}>
      <span css={styles.title} className="title">
        {title}
      </span>
      <div css={styles.bulletedContainer}>
        <Bulleted title={[artist]} />
      </div>
    </div>
  );

  const timeContainer = (
    <div css={[styles.timeContainer]}>
      <Bulleted title={[format(parseISO(time), 'h:mm aaa')]} />
    </div>
  );

  return (
    <div css={styles.container}>
      <div css={styles.imageContainer}>
        <EntityImage
          alt={imageAlt}
          entityImageCss={styles.entityImage}
          imageURIBase={image}
          sizes={sizes}
          fallbackImage={stationImage}
        />
      </div>
      <div css={styles.contentContainer}>
        {titleContainer}
        {isFirst ? playingNowContainer : timeContainer}
      </div>
    </div>
  );
};

export default SongHistoryListItem;
