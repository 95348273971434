import React from 'react';

import { useRecoilState } from 'recoil';

import { LiveRegionsAtom } from '~/state/liveRegions';
import { Visibility } from '~/styles';

const LiveRegions = (): JSX.Element => {
  const [{ assertiveMessage, politeMessage }] = useRecoilState(LiveRegionsAtom);

  return (
    <>
      <div aria-live="assertive" role="log" aria-atomic={true} css={Visibility.visHidden}>
        {assertiveMessage}
      </div>
      <div aria-live="polite" role="log" aria-atomic={true} css={Visibility.visHidden}>
        {politeMessage}
      </div>
    </>
  );
};

export default LiveRegions;
