import Song from '@audacy-clients/client-services/src/dataServices/Song';
import SongList from '@audacy-clients/client-services/src/dataServices/SongList';
import Station from '@audacy-clients/client-services/src/dataServices/Station';

import { wrapDataObject } from '.';
import { IClientServicesList, IStation } from './types';

export const wrapStation = (station: Station): IStation => ({
  ...wrapDataObject(station),
  category: station.getCategory(),
  genres: station.getGenres(),
  marketTitle: station.getMarketTitle(),
  phoneNumber: station.getPhoneNumber(),
  rewindable: station.isRewindable(),
  callsign: station.getCallsign(),
  isTtrEnabled: station.getIsTTREnabled(),
});

export interface IRecentBroadcastItem {
  artist: string;
  image: string;
  timePlayed: string;
  title: string;
}

export const wrapBroadcastStationSongList = (
  songList: SongList,
): IClientServicesList<IRecentBroadcastItem> => ({
  get: (i) => wrapBroadcastSong(songList.getObject(i) as Song),
  length: songList.getNum(),
});

export const wrapBroadcastSong = (song: Song): IRecentBroadcastItem => ({
  artist: song.getArtist(),
  image: song.getImages()?.square,
  timePlayed: song.getTimePlayed(),
  title: song.getTitle(),
});
