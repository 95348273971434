export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Account</title>
    <path
      opacity=".2"
      d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16Z"
      fill="currentColor"
    />
    <path
      d="M12.06 12.625a3.938 3.938 0 1 0 7.874 0 3.938 3.938 0 0 0-7.875 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m9.514 22.628-.548-.44-.549-.441h.001l.001-.002.003-.004.009-.01a2.273 2.273 0 0 1 .124-.141c.083-.09.203-.213.361-.359.316-.29.784-.672 1.41-1.051 1.255-.76 3.138-1.508 5.67-1.508 2.534 0 4.416.747 5.672 1.508a8.87 8.87 0 0 1 1.41 1.051 6.332 6.332 0 0 1 .485.5l.008.01.003.004.002.001v.001l-.548.44-.548.44v.001l-.012-.014a4.941 4.941 0 0 0-.344-.349 7.455 7.455 0 0 0-1.185-.882c-1.066-.646-2.7-1.305-4.942-1.305-2.243 0-3.876.659-4.942 1.305a7.454 7.454 0 0 0-1.186.882 4.942 4.942 0 0 0-.343.349l-.013.014Zm0 0-.001.001Zm12.966 0 .002.002h-.001v-.002Z"
      fill="currentColor"
    />
  </svg>
);
