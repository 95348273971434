import { Gradients, Colors } from '~/styles';

export const themeColors = `
  /* New theme colors need to be added to src/styles/theme-colors.ts */
  :root,
  :root[data-theme='default'] {
    /* backgrounds */
    --color-bg-primary: ${Colors.audacyDarkNavy};

    /* text */
    --color-text-primary: ${Colors.white};
    --color-text-secondary: ${Colors.white60};

    /* button primary */
    --color-button-bg-primary: ${Colors.audacyOrange};
    --color-button-color-primary: ${Colors.white};

    /* button primary hover */
    --color-button-bg-primary-hover: ${Gradients.primaryHover};

    /* button secondary */
    --color-button-bg-secondary: transparent;
    --color-button-border-secondary: ${Colors.white30};
    --color-button-color-secondary: ${Colors.white};

    /* button secondary hover */
    --color-button-bg-secondary-hover: transparent;
    --color-button-border-secondary-hover: ${Colors.greyA6};
  }

  /* NB: there are no Figma theme annotations; light theme is currently FPO */
  :root[data-theme='light'] {
    /* backgrounds */
    --color-bg-primary: ${Colors.white};

    /* text */
    --color-text-primary: ${Colors.black};
    --color-text-secondary: ${Colors.audacyDarkNavy};

    /* button primary */
    --color-button-bg-primary: ${Colors.audacyOrange};
    --color-button-color-primary: ${Colors.white};

    /* button primary hover */
    --color-button-bg-primary-hover: ${Gradients.primaryHover};

    /* button secondary */
    --color-button-bg-secondary: transparent;
    --color-button-border-secondary: ${Colors.greyA6};
    --color-button-color-secondary: ${Colors.black};

    /* button secondary hover */
    --color-button-bg-secondary-hover: transparent;
    --color-button-border-secondary-hover: ${Colors.grey80};
  }
`;
