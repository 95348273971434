/**
 * NB: Adapted from the mobile app's routing types:
 * https://gitlab.audacy.com/mobile-app-client/mobile-app-client/-/blob/develop/src/screens/Routes.ts
 */

import {
  EntitySubtype,
  EntityType,
  ShowSubtype,
  TagSubtype,
  ViewType,
} from '@audacy-clients/client-services/core';
import { ViewIDKey } from '@audacy-clients/core/atoms/config/constants';

export enum WebViewIDKey {
  Episode = 'episode',
  ExclusiveStation = 'exclusivestations',
  Genre = 'genre',
  Home = 'home',
  Music = 'music',
  Newstalk = 'newstalk',
  Podcast = 'podcast',
  Podcasts = 'podcasts',
  Shows = 'shows',
  Sports = 'sports',
  SportsTeams = 'sportsteams',
  Station = 'station',
  Stations = 'stations',
  Tag = 'tag',
}

// The core package ViewID type isn't exhaustive, so we extend the type here
// with values needed for the web client landing pages.
export type TViewId = ViewIDKey | WebViewIDKey;

// The web page hierarchy significantly diverges from the mobile app which will
// require unique typings for pages used to map against paths.
export type TPage =
  | 'Account'
  | 'Dev'
  | 'DeleteAccountConfirmation'
  | 'ReactivateAccount'
  | 'Home'
  | 'MyAudio'
  | 'MyAudioTab'
  | 'Registration'
  | 'RegistrationStepOne'
  | 'RegistrationStepTwo'
  | 'Search';

export const BasePath = {
  MyAudio: '/account/myaudio',
  Registration: `/account/registration`,
};

export const Path: Record<TPage, string> = {
  Account: '/account/settings',
  Dev: `/dev`,
  DeleteAccountConfirmation: '/delete-confirmation',
  ReactivateAccount: '/reactivate',
  Home: `/`,
  MyAudio: `${BasePath.MyAudio}/*`,
  MyAudioTab: `:tab/:subTab?`,
  Registration: `${BasePath.Registration}`,
  RegistrationStepOne: `${BasePath.Registration}/step-two`,
  RegistrationStepTwo: `${BasePath.Registration}/step-three`,
  Search: `/search`,
};

export const pathsWithoutNav = [
  Path.Registration,
  Path.DeleteAccountConfirmation,
  Path.ReactivateAccount,
];

export enum PageTypes {
  LandingPage = 'LandingPage',
  PlaylistPage = 'PlaylistPage',
  PodcastPage = 'PodcastPage',
  PodcastEpisodesPage = 'PodcastEpisodesPage',
  EpisodePage = 'EpisodePage',
  ShowPage = 'ShowPage',
  StationPage = 'StationPage',
  StationSchedulePage = 'StationSchedulePage',
  StationsSongHistoryPage = 'StationsSongHistoryPage',
  SportsLeaguePage = 'SportsLeaguePage',
  SportsTeamPage = 'SportsTeamPage',
  GenericContentPage = 'GenericContentPage',
  SearchResultsPage = 'SearchResultsPage',
  TopicPage = 'TopicPage',
  StandaloneChapterPage = 'StandaloneChapterPage',
}

export const getPageType = (
  viewType?: ViewType,
  entityType?: EntityType,
  entitySubtype?: EntitySubtype,
): PageTypes | undefined => {
  if (!viewType) {
    return undefined;
  }

  switch (viewType) {
    case ViewType.SEARCH:
      return PageTypes.SearchResultsPage;
    case ViewType.SCHEDULE:
      return PageTypes.StationSchedulePage;
    case ViewType.ALL_EPISODE:
      return PageTypes.PodcastEpisodesPage;
    case ViewType.TOPIC:
      return PageTypes.TopicPage;
    case ViewType.STANDALONE_CHAPTER:
      return PageTypes.StandaloneChapterPage;
  }
  switch (entitySubtype) {
    case ShowSubtype.PODCAST:
      return PageTypes.PodcastPage;
    case TagSubtype.LEAGUE:
      return PageTypes.SportsLeaguePage;
    case TagSubtype.TEAM:
      return PageTypes.SportsTeamPage;
  }
  switch (entityType) {
    case EntityType.SHOW:
      return PageTypes.ShowPage;
    case EntityType.STANDALONE_CHAPTER:
    case EntityType.EPISODE:
      return PageTypes.EpisodePage;
    case EntityType.STATION:
      if (location.pathname.includes('song-history')) {
        return PageTypes.StationsSongHistoryPage;
      }
      return PageTypes.StationPage;
    case EntityType.COLLECTION:
      return PageTypes.PlaylistPage;
    case EntityType.TAG:
    case EntityType.TOPIC:
      return undefined;
    default:
      return PageTypes.LandingPage;
  }
};

export const getPodcastAllEpisodesUrl = (podcastUrl: string): string | undefined =>
  `${podcastUrl}/episodes`;

export const getStationScheduleUrl = (stationUrl: string): string =>
  `${stationUrl}/shows/show-schedule`;

export const getPodcastUrl = (episodeDetailUrl: string): string | undefined => {
  const found = episodeDetailUrl.match(/(^\/podcast\/(.*))\/episodes\/(.*)/);
  return found && found.length >= 1 ? found[1] : undefined;
};
