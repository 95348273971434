import { useCallback } from 'react';

import { EpisodeSubType } from '@audacy-clients/client-services';
import { VOD_FEATURE_FLAG } from '@audacy-clients/client-services/src/audioServices/players/constants';
import { useFeatureFlags } from '@audacy-clients/core/atoms/config/settings';
import { isAdState, nowPlaying } from '@audacy-clients/core/atoms/player';
import { rewindSpeedControlAttribute } from '@audacy-clients/core/constants/brazeAttributes';
import { usePlaybackSpeed } from '@audacy-clients/core/hooks/audioHelpers/usePlaybackSpeed';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { useAudioHelpersWeb } from '~/hooks/use-audio-helpers-web';
import useToast from '~/state/toast';
import { setBrazeCustomAttribute } from '~/utils/braze';

import PlaybackSpeedButton from './PlaybackSpeedButton';

const PlaybackSpeedButtonWithState = (props: { isLeftAligned?: boolean }): JSX.Element | null => {
  const nowPlayingItem = useRecoilValue(nowPlaying);
  const { playbackSpeed } = usePlaybackSpeed();
  const audioHelpers = useAudioHelpersWeb();
  const featureFlags = useFeatureFlags();
  const { showToast } = useToast();
  const { t } = useTranslation();
  const setNextSpeed = audioHelpers.incrementRate;
  const isRewind =
    nowPlayingItem?.episodeDataObject?.subtype === EpisodeSubType.BROADCAST_SHOW_EPISODE;
  const isPodcast = nowPlayingItem?.episodeDataObject?.subtype === EpisodeSubType.PODCAST_EPISODE;
  const isStandaloneChapter = !!nowPlayingItem?.standaloneChapterDataObject;

  const isLive = audioHelpers.getIsLive();
  const isAd = useRecoilValue(isAdState);

  // NOTE: CCS-1104 (VOD speed controls): Remove after full feature flag rollout
  const vodFeatureEnabled = featureFlags[VOD_FEATURE_FLAG];

  const showDisabledToast = useCallback(() => {
    showToast({ title: t('global.rewindSpeedControlsMessage') });
    setBrazeCustomAttribute(rewindSpeedControlAttribute);
  }, [t, showToast]);

  const onClick = !vodFeatureEnabled && isRewind ? showDisabledToast : setNextSpeed;

  const shouldHideSpeedButton = !(isRewind || isPodcast || isStandaloneChapter) || isLive || isAd;

  if (shouldHideSpeedButton) {
    return null;
  }

  return <PlaybackSpeedButton playbackSpeed={playbackSpeed} onClick={onClick} {...props} />;
};

export default PlaybackSpeedButtonWithState;
