import { Property } from 'csstype';

import { Colors } from '~/styles';
import { TStyles } from '~/types/emotion-styles';

interface IBrandSVGProps {
  ariaLabel?: string;
  children?: React.ReactNode;
  height: Property.Height;
  width: Property.Width;
}

interface IBrandProps {
  ariaLabel?: string;
  color?: Property.Color;
  containerCss?: TStyles;
  variant?: string;
}

export enum BrandVariants {
  Icon = 'Icon',
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

const BrandSVG = ({ ariaLabel, children, height, width }: IBrandSVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-labelledby="title"
  >
    <title id="title">{ariaLabel}</title>
    {children}
  </svg>
);

const Brand = ({
  ariaLabel = 'Audacy Brand',
  color = Colors.audacyOrange,
  containerCss,
  variant = BrandVariants.Icon,
}: IBrandProps): JSX.Element => {
  let brandRender;

  switch (variant) {
    case BrandVariants.Vertical:
      brandRender = (
        <BrandSVG width="102" height="72" ariaLabel={ariaLabel}>
          <path
            d="M34.309 56.489v10.338H30.97V65.24c-1.227 1.172-3.085 1.804-5.25 1.804-4.006 0-6.243-2.075-6.243-5.792V56.49h3.338v4.294c0 2.598 1.209 3.825 3.789 3.825 2.742 0 4.366-1.317 4.366-4.6v-3.519h3.338Zm17.51-2.688v13.026h-3.337V65.51c-1.155.992-2.905 1.534-5.214 1.534-4.403 0-7.181-1.949-7.181-5.269 0-3.355 2.815-5.502 7.054-5.502 2.292 0 4.132.63 5.341 1.767V53.8h3.338Zm-3.337 7.848c0-1.894-1.606-2.94-4.529-2.94-2.922 0-4.528 1.046-4.528 2.94s1.606 2.959 4.528 2.959c2.905 0 4.529-1.065 4.529-2.959Zm19.829-1.407v6.585h-3.067v-1.66c-1.3 1.281-3.068 1.877-5.792 1.877-3.789 0-5.737-1.137-5.737-3.32 0-2.111 1.876-3.121 6.278-3.338l5.25-.27c-.09-.939-1.37-1.408-3.842-1.408-2.617 0-4.655.541-6.622 1.768l-.054-.036v-2.796c2.02-.957 4.33-1.371 6.892-1.371 4.493 0 6.694 1.298 6.694 3.969Zm-3.067 2.057v-.163l-5.684.307c-1.678.09-2.49.451-2.49 1.137 0 .703.776 1.028 2.761 1.028 3.338 0 5.413-.902 5.413-2.31ZM78 56.273c2.273 0 4.258.45 6.116 1.407v3.03l-.072.019c-1.714-1.19-3.572-1.877-6.044-1.877-2.959 0-4.547.975-4.547 2.815 0 1.84 1.588 2.797 4.547 2.797 2.472 0 4.348-.668 6.044-1.859l.072.018v3.013c-1.858.957-3.843 1.408-6.116 1.408-4.944 0-7.885-2.111-7.885-5.377 0-3.284 2.941-5.395 7.885-5.395Zm23.217.288L93.675 68.65c-1.209 1.948-2.598 2.724-5.034 2.724h-.758v-2.436l.109-.072h.36c1.642 0 2.166-.379 2.941-1.678l.208-.346-6.757-10.28.054-.073h3.717l4.655 7.253 4.492-7.253h3.501l.054.072ZM15.625 66.827h3.68l-7.884-13.026H7.884L0 66.827h3.68l1.555-2.616h8.835l1.555 2.616ZM6.81 61.56l2.842-4.783 2.841 4.783H6.811Zm28.551-43.057c-7.456 0-13.5 6.044-13.5 13.5 0 7.455 6.045 13.5 13.5 13.5 7.456 0 13.5-6.045 13.5-13.5 0-7.456-6.044-13.5-13.5-13.5ZM55.414 0H36.142l24.355 45.501h19.271L55.414 0Z"
            fill={color}
          />
        </BrandSVG>
      );
      break;
    case BrandVariants.Horizontal:
      brandRender = (
        <BrandSVG width="280" height="55" ariaLabel={ariaLabel}>
          <path
            d="M140.988 24.131v21.48h-6.935v-3.3c-2.549 2.437-6.41 3.749-10.908 3.749-8.322 0-12.97-4.31-12.97-12.033v-9.896h6.935v8.922c0 5.398 2.511 7.947 7.872 7.947 5.698 0 9.071-2.737 9.071-9.56v-7.309h6.935Zm36.382-5.585V45.61h-6.934v-2.736c-2.399 2.062-6.036 3.186-10.834 3.186-9.146 0-14.919-4.049-14.919-10.946 0-6.972 5.848-11.433 14.657-11.433 4.76 0 8.584 1.312 11.096 3.674v-8.81h6.934Zm-6.934 16.306c0-3.936-3.337-6.11-9.409-6.11-6.073 0-9.409 2.174-9.409 6.11 0 3.936 3.336 6.148 9.409 6.148 6.035 0 9.409-2.212 9.409-6.148Zm41.197-2.924V45.61h-6.373v-3.448c-2.699 2.661-6.372 3.898-12.032 3.898-7.873 0-11.921-2.362-11.921-6.897 0-4.386 3.899-6.485 13.045-6.935l10.908-.562c-.187-1.95-2.849-2.924-7.984-2.924-5.435 0-9.671 1.124-13.757 3.673l-.113-.075v-5.81c4.199-1.987 8.997-2.849 14.32-2.849 9.334 0 13.907 2.7 13.907 8.247Zm-6.373 4.273v-.337l-11.808.637c-3.486.188-5.173.938-5.173 2.362 0 1.462 1.612 2.137 5.736 2.137 6.934 0 11.245-1.875 11.245-4.799Zm26.503-12.52c4.724 0 8.847.937 12.708 2.924v6.298l-.15.037c-3.561-2.474-7.422-3.898-12.558-3.898-6.147 0-9.446 2.024-9.446 5.848 0 3.823 3.299 5.81 9.446 5.81 5.136 0 9.034-1.387 12.558-3.861l.15.037v6.26c-3.861 1.987-7.984 2.924-12.708 2.924-10.271 0-16.381-4.386-16.381-11.17 0-6.823 6.11-11.209 16.381-11.209Zm48.237.6-15.669 25.116c-2.512 4.048-5.398 5.66-10.458 5.66h-1.575v-5.06l.225-.15h.75c3.411 0 4.498-.788 6.11-3.487l.432-.72-14.039-21.359.112-.15h7.722l9.671 15.07 9.334-15.07h7.272l.113.15Zm-177.831 21.33h7.647l-16.38-27.065h-7.348L69.707 45.61h7.647l3.23-5.435H98.94l3.229 5.435ZM83.858 34.663l5.904-9.936 5.903 9.936H83.858ZM27.064 31.973c0 7.473-6.058 13.532-13.532 13.532C6.06 45.505 0 39.446 0 31.972 0 24.5 6.059 18.44 13.532 18.44c7.474 0 13.532 6.059 13.532 13.532ZM33.633 0H14.314l24.413 45.61h19.318L33.632 0Z"
            fill={color}
          />
        </BrandSVG>
      );
      break;
    default:
      brandRender = (
        <BrandSVG width="32" height="32" ariaLabel={ariaLabel}>
          <path
            d="M7.83 27.299a6.705 6.705 0 1 0 0-13.41 6.705 6.705 0 0 0 0 13.41ZM18.78 4.701H9.208L21.305 27.3h9.571L18.78 4.7Z"
            fill={color}
          />
        </BrandSVG>
      );
  }

  return <span css={containerCss}>{brandRender}</span>;
};

export default Brand;
