import React, { ReactNode, Suspense, useCallback, useRef, useState } from 'react';

import FocusTrap from 'focus-trap-react';
import { nanoid } from 'nanoid/non-secure';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import IconButton, {
  IconButtonColorThemes,
  IconButtonThemes,
} from '~/components/Button/IconButton';
import DrawerContainer from '~/components/Drawer';
import DrawerHeader from '~/components/Drawer/components/DrawerHeader';
import { IEntityImageProps } from '~/components/Entity/components/EntityImage';
import { Icons } from '~/components/Icon/constants';
import OptionsMenuContainer, {
  OptionsMenuPosition,
} from '~/components/OptionsMenu/components/OptionsMenuContainer';
import { useOptionsMenuContent } from '~/components/OptionsMenu/components/OptionsMenuContent';
import { IOptionsMenu } from '~/components/OptionsMenu/types';
import Portal from '~/components/Portal';
import { useBreakpoints } from '~/hooks/use-breakpoints';
import { useOnClickOutside } from '~/hooks/use-click-outside';
import { TStyles } from '~/types/emotion-styles';

import styles from './styles';

export interface IOptionsMenuButtonProps {
  ariaLabel?: string;
  buttonCss?: TStyles;
  buttonSizeInPx?: number;
  colorTheme?: IconButtonColorThemes;
  icon?: Icons;
  iconSizeInPx?: number;
  isQueueEnabled?: boolean;
  isLoggedIn?: boolean;
  menuContent?: ReactNode; // Content determined by recoil state by default.
  menuPosition?: OptionsMenuPosition;
  menuTitle?: string;
  menuImage?: IEntityImageProps;
  onClickCallback?: () => void;
  optionsMenu: IOptionsMenu;
  theme?: IconButtonThemes;
}

const OptionsMenuButton = ({
  ariaLabel,
  buttonCss,
  buttonSizeInPx = 24,
  colorTheme = IconButtonColorThemes.Dark,
  icon = Icons.More,
  iconSizeInPx = 18,
  isQueueEnabled = false,
  isLoggedIn = false,
  menuPosition,
  menuTitle,
  menuImage,
  onClickCallback,
  optionsMenu,
  theme = IconButtonThemes.HoverFill,
}: IOptionsMenuButtonProps): JSX.Element | null => {
  const [isVisible, setIsVisible] = useState(false);

  // Hooks
  const { lessThan } = useBreakpoints();
  const id = useRef(nanoid());
  const menuRef = useRef(null);
  const { t } = useTranslation();

  // Events
  const openMenu = useCallback(() => {
    onClickCallback && onClickCallback();
    setIsVisible(true);
  }, [onClickCallback, setIsVisible]);

  const closeMenu = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  useOnClickOutside(menuRef, closeMenu);

  // Styles
  const buttonSizeStyle = { height: buttonSizeInPx, width: buttonSizeInPx };

  // Markup
  const iconButton = (
    <IconButton
      ariaControls={id.current}
      ariaExpanded={isVisible}
      ariaHasPopup="menu"
      ariaLabel={ariaLabel || t('menuTooltip.moreOptions')}
      buttonCss={[isVisible ? styles.activeButton : {}, buttonCss ? buttonCss : {}]}
      buttonSizeInPx={buttonSizeInPx}
      colorTheme={colorTheme}
      icon={icon}
      onClick={(event) => {
        event.stopPropagation();
        isVisible ? closeMenu() : openMenu();
      }}
      iconSizeInPx={iconSizeInPx}
      theme={theme}
    />
  );

  // TODO: [A2-6551] Make OptionsMenuContent a component to allow isFollowing state to update properly
  const menuContent = useOptionsMenuContent(optionsMenu, isQueueEnabled, closeMenu, isLoggedIn);

  // Hide the 3 buttons CTA if no menu actions exist.
  if (!menuContent.length) return null;

  if (lessThan.LG) {
    return (
      <>
        {iconButton}
        <Portal>
          <DrawerContainer
            onClose={closeMenu}
            isOpen={isVisible}
            renderDrawerHeader={(onClose) => (
              <DrawerHeader image={menuImage} onClose={onClose} id={id.current} title={menuTitle} />
            )}
          >
            <ErrorBoundary FallbackComponent={() => <></>}>
              <Suspense fallback={<></>}>
                {menuContent.map((item, idx) => (
                  <React.Fragment key={`${idx}-${id.current}`}>{item}</React.Fragment>
                ))}
              </Suspense>
            </ErrorBoundary>
          </DrawerContainer>
        </Portal>
      </>
    );
  }

  return (
    <FocusTrap active={isVisible}>
      <div css={[styles.container, buttonSizeStyle]} ref={menuRef}>
        {iconButton}
        {/* Need this to prevent 'container has no tappable nodes' error while waiting for items */}
        <Suspense fallback={<></>}>
          <OptionsMenuContainer
            buttonSizeInPx={buttonSizeInPx}
            id={id.current}
            isOpen={isVisible}
            menuPosition={menuPosition}
            ref={menuRef}
          >
            {menuContent.map((item, idx) => (
              <React.Fragment key={`${idx}-${id.current}`}>{item}</React.Fragment>
            ))}
          </OptionsMenuContainer>
        </Suspense>
      </div>
    </FocusTrap>
  );
};

export default OptionsMenuButton;
