import { EntityType, EpisodeSubType, type EntitySubtype } from '@audacy-clients/client-services';
import {
  isNavigableTranscript,
  isTranscriptEntitySubtypeValid,
} from '@audacy-clients/core/hooks/useTranscript';
import clientServices from '@audacy-clients/core/utils/clientServices';
import { isBroadcastShowEpisode } from '@audacy-clients/core/utils/episode';
import { atom, selector, selectorFamily, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { chapterIndexState, chaptersSelector, currentChapter } from './chapters';
import { nowPlaying, playerContentIsLiveState } from './player';
import { type IChapter } from './wrappers/chapter';

export type TTranscriptData = {
  transcript: Array<TTranscript>;
  title?: string;
  startOffset?: number;
  duration?: number;
};

export type TTranscript = {
  duration: number;
  end_time: number;
  speaker: string;
  start_time: number;
  text: string;
};

type TTranscriptMetaData = {
  url?: string;
  title?: string;
  startOffset?: number;
  duration?: number;
};

export const emptyTranscriptQueryUrl: TTranscriptMetaData = {
  url: '',
  title: '',
};

type TTranscriptEntity = {
  type?: EntityType;
  id?: string;
};

export const scrubbingState = atom<boolean>({
  default: false,
  key: 'ScrubbingState',
});

export const transcriptHasOpenedState = atom<boolean>({
  default: false,
  key: 'TranscriptHasOpenedState',
});

export const isNextTranscriptAvailable = selectorFamily<boolean, TTranscriptEntity>({
  get:
    ({ type, id }) =>
    ({ get }) => {
      if (type !== EntityType.EPISODE || !id) {
        return true;
      }

      const chapters = get(chaptersSelector({ episodeId: id, live: false }));
      const chapterIndex = get(chapterIndexState);
      const currentChapter = chapters[chapterIndex];

      if (chapterIndex === chapters.length - 1) {
        return true;
      }

      if (!isNavigableTranscript(currentChapter)) {
        return false;
      }

      return !!chapters[chapterIndex + 1]?.transcript?.isNavigableTranscript;
    },
  key: 'isNextTranscriptAvailable',
});

export const useIsNextTranscriptAvailable = (entity: TTranscriptEntity): boolean =>
  useRecoilValue(isNextTranscriptAvailable(entity));

export const transcriptCurrentSelector = selectorFamily<
  IChapter | undefined,
  { episodeId: string; live: boolean }
>({
  get:
    ({ episodeId, live }: { episodeId: string; live: boolean }) =>
    ({ get }) => {
      try {
        const chapters = get(chaptersSelector({ episodeId: episodeId, live }));
        const chapterIndex = get(chapterIndexState);

        const currentChapter = chapters[chapterIndex];
        return currentChapter;
      } catch (e) {
        console.error('Transcript error: ', e);
      }
    },
  key: 'transcriptChapterMetaDataSelector',
});

export const transcriptChapterMetaDataSelector = selectorFamily<
  TTranscriptMetaData | undefined,
  string
>({
  get:
    (episodeId: string) =>
    ({ get }) => {
      try {
        const currentChapter = get(
          transcriptCurrentSelector({ episodeId: episodeId, live: false }),
        );
        if (currentChapter && isNavigableTranscript(currentChapter)) {
          const { title, transcript, startOffset, duration } = currentChapter;
          return {
            url: transcript?.url,
            title,
            startOffset,
            duration,
          };
        } else {
          return emptyTranscriptQueryUrl;
        }
      } catch (e) {
        console.error('Transcript error: ', e);
      }
    },
  key: 'transcriptChapterMetaDataSelector',
});

export const chaptersTranscriptQueryUrl = selector<TTranscriptMetaData | undefined>({
  get: ({ get }) => {
    try {
      const playingNow = get(nowPlaying);
      const { episodeDataObject } = playingNow ?? {};
      if (
        episodeDataObject?.episodeId &&
        isBroadcastShowEpisode(episodeDataObject?.type, episodeDataObject?.subtype)
      ) {
        return get(transcriptChapterMetaDataSelector(episodeDataObject?.id));
      }
      return emptyTranscriptQueryUrl;
    } catch (e) {
      console.error('Transcript error: ', e);
    }
  },
  key: 'chaptersTranscriptQueryUrl',
});
export const chaptersTranscriptQuery = selector<TTranscriptData | undefined>({
  get: async ({ get }) => {
    try {
      const { url, ...rest } = get(chaptersTranscriptQueryUrl) ?? {};

      if (!url) {
        return;
      }

      const transcript = await clientServices.getDataServices().getTranscript(url);
      return { ...transcript, ...rest };
    } catch (e) {
      console.error('Transcript error: ', e);
    }
  },
  key: 'chaptersTranscriptQuery',
});

export const transcriptQueryUrl = selector<string | undefined | null>({
  get: ({ get }) => {
    try {
      const playingNow = get(nowPlaying);
      const isValid = get(isTranscriptEntityValid);
      const { episodeDataObject, standaloneChapterDataObject } = playingNow ?? {};
      const entityDataObject = episodeDataObject ?? standaloneChapterDataObject;

      if (!isValid) {
        return;
      }

      return entityDataObject?.transcript?.url;
    } catch (e) {
      console.error('Transcript error: ', e);
    }
  },
  key: 'transcriptQueryUrl',
});
export const transcriptQuery = selector<TTranscriptData | undefined>({
  get: async ({ get }) => {
    try {
      const url = get(transcriptQueryUrl);

      if (!url) {
        return;
      }
      const transcript = await clientServices.getDataServices().getTranscript(url);
      return { ...transcript };
    } catch (e) {
      console.error('Transcript error: ', e);
    }
  },
  key: 'transcriptQuery',
});

export const useTranscriptData = (
  entitySubtype?: EntitySubtype,
): {
  transcript: TTranscriptData | undefined;
  loading: boolean;
  hasError: boolean;
} => {
  const data = useRecoilValueLoadable(
    entitySubtype === EpisodeSubType.BROADCAST_SHOW_EPISODE
      ? chaptersTranscriptQuery
      : transcriptQuery,
  );

  return {
    transcript: data.valueMaybe(),
    loading: data.state === 'loading',
    hasError: data.state === 'hasError',
  };
};

export const isTranscriptEntityValid = selector<boolean>({
  get: ({ get }) => {
    const { episodeDataObject, standaloneChapterDataObject } = get(nowPlaying) ?? {};
    const entity = episodeDataObject ?? standaloneChapterDataObject;
    const current = get(currentChapter({ live: true }));

    const isLive = get(playerContentIsLiveState);
    return (
      isNavigableTranscript(entity, current) &&
      isTranscriptEntitySubtypeValid(entity?.subtype) &&
      !isLive
    );
  },
  key: 'transcriptChapterMetaDataSelector',
});
