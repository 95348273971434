export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Instagram logo</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.999 6.164c3.204 0 3.583.012 4.849.07 1.17.053 1.805.248 2.228.413.522.192.994.499 1.381.898.4.387.706.86.898 1.38.164.424.36 1.06.413 2.23.058 1.265.07 1.644.07 4.848 0 3.204-.012 3.583-.07 4.849-.053 1.17-.249 1.805-.413 2.228a3.98 3.98 0 0 1-2.277 2.277c-.423.164-1.058.36-2.228.413-1.265.058-1.645.07-4.85.07-3.203 0-3.583-.012-4.848-.07-1.17-.053-1.805-.249-2.228-.413a3.716 3.716 0 0 1-1.381-.898 3.716 3.716 0 0 1-.898-1.381c-.164-.423-.36-1.058-.413-2.228-.058-1.266-.07-1.645-.07-4.849s.012-3.583.07-4.849c.053-1.17.249-1.805.413-2.228.192-.522.499-.994.898-1.381.387-.4.86-.706 1.381-.898.423-.164 1.058-.36 2.228-.413 1.266-.058 1.645-.07 4.849-.07l-.002.002ZM16.001 4c-3.259 0-3.668.014-4.947.072-1.28.059-2.152.263-2.914.56a5.89 5.89 0 0 0-2.126 1.384A5.891 5.891 0 0 0 4.63 8.142c-.297.762-.5 1.636-.558 2.913C4.014 12.332 4 12.742 4 16c0 3.259.014 3.668.072 4.947.059 1.28.261 2.15.558 2.912a5.89 5.89 0 0 0 1.384 2.125c.6.61 1.325 1.084 2.126 1.385.762.297 1.636.5 2.913.558C12.33 27.986 12.74 28 16 28c3.259 0 3.668-.014 4.947-.072 1.28-.059 2.149-.261 2.911-.558a6.131 6.131 0 0 0 3.51-3.51c.297-.762.5-1.636.558-2.913.058-1.277.072-1.688.072-4.947 0-3.259-.014-3.668-.072-4.947-.059-1.28-.261-2.149-.558-2.911a5.883 5.883 0 0 0-1.384-2.125 5.89 5.89 0 0 0-2.126-1.385c-.762-.297-1.636-.5-2.913-.558-1.276-.058-1.687-.072-4.946-.072L16.001 4Zm-.003 5.838a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324Zm0 10.163a4 4 0 1 1 0-8.001 4 4 0 0 1 0 8Zm7.846-10.406a1.44 1.44 0 1 1-2.88 0 1.44 1.44 0 0 1 2.88 0Z"
      fill="currentColor"
    />
  </svg>
);
