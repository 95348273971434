export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Settings</title>
    <path
      d="M15.083 4.708a1.5 1.5 0 0 1 1.834 0l2.526 1.952a1.5 1.5 0 0 0 .726.3l3.167.407a1.5 1.5 0 0 1 1.297 1.297l.406 3.167a1.5 1.5 0 0 0 .3.726l1.953 2.526a1.5 1.5 0 0 1 0 1.834l-1.952 2.526a1.5 1.5 0 0 0-.3.726l-.407 3.167a1.5 1.5 0 0 1-1.297 1.297l-3.166.406a1.5 1.5 0 0 0-.727.3l-2.526 1.953a1.5 1.5 0 0 1-1.834 0l-2.526-1.952a1.5 1.5 0 0 0-.726-.3l-3.167-.407a1.5 1.5 0 0 1-1.297-1.297l-.406-3.166a1.5 1.5 0 0 0-.3-.727l-1.953-2.526a1.5 1.5 0 0 1 0-1.834l1.952-2.526a1.5 1.5 0 0 0 .3-.726l.407-3.167a1.5 1.5 0 0 1 1.297-1.297l3.167-.406a1.5 1.5 0 0 0 .726-.3l2.526-1.953Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="1"
      strokeLinejoin="bevel"
    />
    <circle cx="16" cy="16" r="4" stroke="currentColor" strokeWidth="1.5" />
  </svg>
);
