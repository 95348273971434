import { Suspense } from 'react';

import { ErrorBoundary } from 'react-error-boundary';

import FullScreenError from '~/components/Error/FullScreenError';
import FullScreenLoading from '~/components/Loading/FullScreenLoading';
import { useRequest } from '~/hooks/use-request';

import PageContent from './PageContent';

const Page = (): JSX.Element => {
  const request = useRequest();

  return (
    <ErrorBoundary FallbackComponent={({ error }) => <FullScreenError error={error} />}>
      <Suspense fallback={<FullScreenLoading />}>
        <PageContent request={request} />
      </Suspense>
    </ErrorBoundary>
  );
};

export default Page;
