export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Thumbs down</title>
    <path
      d="M3.75 16.827h4.5V5.86h-4.5v10.968ZM15.24 25.923l-5.745-9.096V5.86H25.77l1.915 13.362H19.07v5.744l-3.83.958Z"
      fill="currentColor"
    />
  </svg>
);
