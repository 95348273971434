import { BP, Colors, Gradients, Mixins, mq, Typography, ZIndex } from '~/styles';
import { TStyles, TStyleSheet } from '~/types/emotion-styles';

export const miniPlayerHeight = {
  [BP.Small]: 70,
  [BP.Medium]: 80,
};

const leftBorder: TStyles = {
  '&::before': {
    content: '""',
    height: 20,
    width: 1,
    backgroundColor: Colors.white15,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
  },
};

const styles: TStyleSheet = {
  container: {
    borderTop: '1px solid',
    borderColor: Colors.white15,
    bottom: 0,
    height: miniPlayerHeight[BP.Small],
    left: 0,
    position: 'fixed',
    width: '100%',
    zIndex: ZIndex.miniPlayer,
    [mq(BP.Medium)]: {
      height: miniPlayerHeight[BP.Medium],
    },
    [mq(BP.Large)]: {
      borderTop: 'none',
      bottom: 30,
      left: 'auto',
      position: 'fixed',
      right: 30,
      width: 500,
      '&::before': {
        background: Colors.white,
        content: '""',
        position: 'absolute',
        height: 200,
        left: -10,
        opacity: 0.15,
        top: 170,
        width: 500,
        borderRadius: '50%',
        boxShadow: '0px -250px 150px 40px rgb(255 255 255)',
      },
    },
  },
  content: {
    alignItems: 'center',
    background: Colors.audacyDarkNavy,
    display: 'flex',
    height: '100%',
    padding: '0 15px',
    position: 'relative',
    width: '100%',
    [mq(BP.Medium)]: {
      padding: '0 15px',
    },
    [mq(BP.Large)]: {
      background: Colors.audacyDarkNavy95,
      borderRadius: 8,
    },
  },
  dataContainer: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    maskImage: Gradients.playerOverflowText,
    minWidth: 0,
    padding: 5,
    [mq(BP.Medium)]: {
      padding: 10,
    },
    [mq(BP.Large)]: {
      padding: 5,
    },
  },
  imageContainer: {
    flexShrink: 0,
  },
  imageInner: {
    borderRadius: 4,
    height: 30,
    overflow: 'hidden',
    width: 30,
    [mq(BP.Medium)]: {
      height: 40,
      width: 40,
    },
  },
  contentContainer: {
    flexGrow: 1,
    minWidth: 0,
    paddingLeft: 10,
    position: 'relative',
    [mq(BP.Medium)]: {
      paddingLeft: 12,
    },
    [mq(BP.Large)]: {
      flexBasis: 185,
    },
  },
  contentContainerQueue: {
    flexGrow: 0,
  },
  textContainer: {
    width: '100%',
  },
  textInner: {
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },
  title: {
    ...Typography.metadataLarge,
    color: Colors.white,
    display: 'block',
  },
  subtitle: {
    ...Typography.metadataSmall,
    color: Colors.white60,
    display: 'block',
  },
  scrubberContainer: {
    width: 150,
  },
  scrubber: {
    [mq(BP.Large)]: {
      padding: '6px 0',
    },
  },
  volumeContainer: {
    ...leftBorder,
    padding: '0 6px',
    position: 'relative',
  },
  expandContainer: {
    alignItems: 'center',
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    padding: '0 0 0 15px',
    position: 'relative',
    ...leftBorder,
    [mq(BP.Medium)]: {
      padding: '0 30px',
    },
    [mq(BP.Large)]: {
      padding: '0 5px 0 15px',
    },
  },
  expandBtn: {
    ...Mixins.accessibleMinButtonSize(),
    padding: 5,
    '& path': {
      vectorEffect: 'non-scaling-stroke',
    },
    [mq(BP.Medium)]: {
      padding: 10,
    },
    [mq(BP.Large)]: {
      backgroundColor: Colors.white30,
      borderRadius: '50%',
      padding: 5,
    },
  },
  adBanner: {
    pointerEvents: 'none', // allow click through invisible video preroll / banner ads
    position: 'absolute',
    bottom: 'calc(100% + 20px)',
    right: 20,
    [mq(BP.Large)]: {
      right: 0,
    },
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  videoAdContainer: {
    pointerEvents: 'none',
    opacity: 0,
    width: 445,
    maxWidth: 'calc(100vw - 40px)',
  },
};

export default styles;
