import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon';
import { Icons } from '~/components/Icon/constants';
import { PlayerStyles } from '~/styles';

import styles from './styles';

export interface IVolumeSliderProps {
  isFullPlayer?: boolean;
  isMuted: boolean;
  onMuteChange: (isMuted: boolean) => void;
  onVolumeChange: (volume: number) => void;
  volume: number;
}

const Constants = {
  volumeMin: 0,
  volumeMax: 1,
  volumeStep: 0.05,
  lowVolumeMax: 0.33,
  midVolumeMax: 0.66,
};

const pctVolume = (num: number) => (num * 100).toFixed(0);

const VolumeSlider = ({
  isFullPlayer,
  isMuted,
  onMuteChange,
  onVolumeChange,
  volume,
}: IVolumeSliderProps): JSX.Element => {
  const [isSliderVisible, setIsSliderVisible] = useState(false);
  const [previousVolume, setPreviousVolume] = useState(volume);
  const [usePreviousVolumeWhenUnmuting, setUsePreviousVolumeWhenUnmuting] = useState(false);
  const { t } = useTranslation();

  const handleVolumeButtonClick = () => {
    if (usePreviousVolumeWhenUnmuting) {
      onVolumeChange(previousVolume);
      setUsePreviousVolumeWhenUnmuting(false);
    }
    onMuteChange(!isMuted);
  };

  const handleVolumeSliderChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseFloat(evt.target.value);
    setUsePreviousVolumeWhenUnmuting(val === 0);
    onVolumeChange(val);
  };

  const handleInitiateVolumeSliderChange = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent> | React.TouchEvent<HTMLInputElement>,
  ) => {
    if (!('value' in e.target)) {
      return;
    }
    setPreviousVolume(parseFloat(e.target.value as string));
  };

  const volumePct = isMuted ? 0 : (volume / Constants.volumeMax) * 100;
  const isLowVolume = volume <= Constants.lowVolumeMax;
  const isMidVolume = volume > Constants.lowVolumeMax && volume < Constants.midVolumeMax;

  return (
    <div
      css={[
        styles.root,
        isFullPlayer && styles.fullPlayer,
        isSliderVisible && styles.rootSliderVisible,
      ]}
      onMouseEnter={() => setIsSliderVisible(true)}
      onMouseLeave={() => setIsSliderVisible(false)}
    >
      <button
        css={[
          styles.volumeButton,
          !isFullPlayer && styles.miniPlayerVolumeButton,
          isLowVolume && styles.lowVolume,
          isMidVolume && styles.midVolume,
        ]}
        onClick={handleVolumeButtonClick}
        aria-label={
          isMuted
            ? t('playerControls.accessibilityLabels.unmuteLabel')
            : t('playerControls.accessibilityLabels.muteLabel')
        }
      >
        <Icon
          name={isMuted || volume === Constants.volumeMin ? Icons.VolumeMuted : Icons.Volume}
          ariaHidden
        />
      </button>
      <div css={[styles.volumeSliderWrapper, isSliderVisible && styles.volumeSliderVisible]}>
        <input
          aria-valuetext={
            isMuted
              ? t('playerControls.accessibilityLabels.volumeMuted')
              : `${pctVolume(volume)}% ${t('playerControls.labels.volumeSlider.volume')}`
          }
          aria-label={t('playerControls.accessibilityLabels.volumeSliderLabel')}
          min={Constants.volumeMin}
          max={Constants.volumeMax}
          step={Constants.volumeStep}
          css={[styles.volumeSlider, PlayerStyles.sliderBackground(volumePct)]}
          type="range"
          value={isMuted ? Constants.volumeMin : volume}
          onMouseDown={handleInitiateVolumeSliderChange}
          onTouchStart={handleInitiateVolumeSliderChange}
          onChange={handleVolumeSliderChange}
        />
      </div>
    </div>
  );
};

export default VolumeSlider;
