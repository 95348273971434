import { BP } from '~/styles';

export const Constants = {
  defaultPadding: 30,
  menuWidth: {
    [BP.Small]: '100%',
    [BP.Medium]: 286,
    [BP.Large]: 220,
  },
  navPadding: {
    [BP.Medium]: 23,
    [BP.Large]: 58,
  },
  searchWidth: 308,
};
