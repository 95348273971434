import { BP, Constants, mq } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    flexDirection: 'column',
    width: '100%',
    height: 365,
    backgroundColor: '#1F2772',
    borderRadius: Constants.borderRadius,
    display: 'flex',
    padding: 0,
    position: 'relative',
    justifyContent: 'flex-end',
    [mq(BP.Medium)]: {
      flexDirection: 'row',
    },
    [mq(BP.Large)]: {
      height: 360,
    },
    [mq(BP.XXLarge)]: {
      height: 600,
    },
  },
  verticalContainer: {
    width: '100%',
    flexDirection: 'column',
    [mq(BP.Medium)]: {
      flexDirection: 'column',
    },
    [mq(BP.Large)]: {
      height: 360,
    },
    [mq(BP.XXLarge)]: {
      height: 600,
    },
  },
  imageContentArea: {
    display: 'flex',
    height: '100%',
    order: 1,
    flexGrow: 1,
    position: 'relative',
    overflow: 'hidden',
  },
  horizontalImgContainer: {
    [mq(BP.Medium)]: {
      width: '50%',
    },
  },
  backgroundImage: {
    width: '100%',
    height: 'calc(100% - 10px)',
    borderTopRightRadius: Constants.borderRadius,
    borderTopLeftRadius: Constants.borderRadius,
    objectFit: 'cover',
    objectPosition: 'center 15%',
    [mq(BP.Medium)]: {
      height: '100%',
      borderTopLeftRadius: Constants.borderRadius,
      borderBottomLeftRadius: Constants.borderRadius,
      borderTopRightRadius: 0,
    },
  },
  backgroundImageVertical: {
    borderTopRightRadius: Constants.borderRadius,
    borderBottomLeftRadius: 0,
    [mq(BP.Medium)]: {
      height: 'calc(100% - 10px)',
      borderBottomLeftRadius: 0,
      borderTopRightRadius: Constants.borderRadius,
    },
  },
  textContentArea: {
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 20px 20px 20px',
    order: 2,
    [mq(BP.Medium)]: {
      width: '50%',
      justifyContent: 'center',
    },
    [mq(BP.XXLarge)]: {
      padding: '40px',
      width: '50%',
    },
  },
  textContentEnd: {
    [mq(BP.Medium)]: {
      justifyContent: 'flex-end',
    },
  },
  fullWidthText: {
    [mq(BP.Medium)]: {
      width: 'auto',
    },
  },
  thumbnailTopRight: {
    position: 'absolute',
    top: 20,
    right: 20,
    [mq(BP.Large)]: {
      top: 25,
      right: 25,
    },
    [mq(BP.XXLarge)]: {
      top: 40,
      right: 40,
    },
  },
  hideThumbnail: {
    [mq(BP.Medium)]: {
      display: 'none',
    },
  },
  thumbnailOverlay: {
    position: 'absolute',
    right: 20,
    bottom: 0,
    [mq(BP.Medium)]: {
      display: 'none',
    },
  },
  thumbnailOverlayVertical: {
    [mq(BP.Medium)]: {
      display: 'initial',
    },
  },
  thumbnailCover: {
    display: 'none',
    [mq(BP.Medium)]: {
      height: '100%',
      backgroundColor: 'rgba(9, 5, 39, 0.5);',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexGrow: 1,
    },
  },
  thumbnailLarge: {
    margin: 'auto',
    [mq(BP.Medium)]: {
      width: 220,
      height: 220,
      borderRadius: 4,
    },
    [mq(BP.Large)]: {
      width: 240,
      height: 240,
    },
  },
  thumbnailSmall: {
    width: 60,
    height: 60,
    borderRadius: 4,
    [mq(BP.XXLarge)]: {
      width: 70,
      height: 70,
    },
    [mq(BP.XXXLarge)]: {
      width: 80,
      height: 80,
    },
  },
  highlightTextMedLargeBP: {
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 1,
    [mq(BP.Medium)]: {
      top: 20,
      left: 'calc(50% + 20px)',
    },
    [mq(BP.Large)]: {
      padding: '3px 7px 1px',
      fontSize: 11,
      top: 25,
      left: 'calc(50% + 25px)',
    },
    [mq(BP.XXLarge)]: {
      padding: '3px 7px 1px',
      fontSize: 11,
      top: 40,
      left: 'calc(50% + 40px)',
    },
  },
  highlightTextSmallBP: {
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 1,
    [mq(BP.XXLarge)]: {
      top: 40,
      left: 40,
    },
  },
  title: {
    fontSize: 16,
    [mq(BP.Medium)]: {
      fontSize: 30,
    },
    [mq(BP.Large)]: {
      fontSize: 38,
    },
  },
  titleWithoutImageSm: {
    fontSize: 30, // other bk styles match title with image
  },
  titleVerticalWithImage: {
    fontSize: 16,
    [mq(BP.Medium)]: {
      fontSize: 16,
    },
    [mq(BP.Large)]: {
      fontSize: 18,
    },
    [mq(BP.XXLarge)]: {
      fontSize: 24,
      maxWidth: '560px',
    },
  },
  titleVerticalWithoutImage: {
    fontSize: 30,
    [mq(BP.Medium)]: {
      fontSize: 30,
    },
    [mq(BP.Large)]: {
      fontSize: 38,
    },
    [mq(BP.XXLarge)]: {
      fontSize: 48,
      maxWidth: '560px',
    },
  },
  titleMaxChars: {
    fontSize: 22,
    [mq(BP.Medium)]: {
      fontSize: 22,
    },
    [mq(BP.Large)]: {
      fontSize: 22,
    },
    [mq(BP.XXLarge)]: {
      fontSize: 30,
      maxWidth: '560px',
    },
  },
};

export default styles;
