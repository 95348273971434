import { useTranslation } from 'react-i18next';

import EntityImage from '~/components/Entity/components/EntityImage';
import Icon from '~/components/Icon';
import { Icons } from '~/components/Icon/constants';
import SectionWrapper from '~/components/SectionWrapper';
import TruncatedDescription from '~/components/TruncatedDescription';
import useLinkOrButton from '~/hooks/use-link-or-button';
import { TStyles } from '~/types/emotion-styles';
import { LinkType } from '~/types/links';

import styles from './styles';

interface IPlaylistBarProps {
  image?: string;
  imageAlt?: string;
  nextUpTitle?: string;
  title?: string;
  containerCss?: TStyles;
  navigateToPlaylistUrl?: string;
  showSectionWrapper?: boolean;
}

const PlaylistBar = ({
  title,
  nextUpTitle,
  image,
  imageAlt,
  containerCss,
  navigateToPlaylistUrl,
  showSectionWrapper = true,
}: IPlaylistBarProps): JSX.Element => {
  const { t } = useTranslation();
  const upNextSubtitle = nextUpTitle ? `${t('playlistBar.upNext')} ${nextUpTitle}` : '';

  const content = (
    <div css={styles.contentWrapper}>
      <div css={styles.imageWrapper}>
        <EntityImage alt={imageAlt} entityImageCss={styles.entityImage} imageURIBase={image} />
      </div>
      <div css={styles.titleWrapper}>
        <TruncatedDescription
          numberOfLines={1}
          shouldStripInteractiveTags
          text={title || ''}
          displayShowMore={false}
          textCss={styles.title}
        />
        {nextUpTitle && (
          <TruncatedDescription
            numberOfLines={1}
            shouldStripInteractiveTags
            text={upNextSubtitle || ''}
            displayShowMore={false}
            textCss={styles.subtitle}
          />
        )}
      </div>
      <div css={styles.caretRight}>
        <Icon ariaHidden name={Icons.CaretRight} sizeInPx={15} />
      </div>
    </div>
  );

  const link = useLinkOrButton({
    children: content,
    props: {
      ariaLabel: navigateToPlaylistUrl
        ? t('horizontalEntityCard.ariaLabel.link', { title })
        : title,
      as: LinkType.Anchor,
      href: navigateToPlaylistUrl,
    },
    styles: styles.link,
  });

  const commonContent = (
    <>
      {link}
      <div css={styles.footer} />
    </>
  );

  return showSectionWrapper ? (
    <SectionWrapper
      title={t('playlistBar.sectionTitle')}
      containerCss={[styles.sectionWrapper, containerCss || {}]}
      content={nextUpTitle}
    >
      {commonContent}
    </SectionWrapper>
  ) : (
    <div css={[styles.container, containerCss || {}]}>{commonContent}</div>
  );
};

export default PlaylistBar;
