interface ISpacingProps {
  [key: string]: number;
}

export const HorizontalSpacing: ISpacingProps = {
  sm: 20,
  md: 42,
  lgLeft: 38,
  lgRight: 58,
};
