import base, { container } from '~/components/PlayStateArea/styles';
import { BP, mq } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  ...base,
  gapContainer: {
    ...container,
    gap: 5,
    marginTop: 5,
    [mq(BP.Medium)]: {
      marginTop: 6,
    },
    [mq(BP.Large)]: {
      marginTop: 10,
    },
    separatorDot: {
      borderRadius: 4,
      height: 2,
      marginBottom: 0,
      marginHorizontal: 6,
      width: 2,
    },
  },
};

export default styles;
