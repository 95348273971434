import { atom } from 'recoil';

export const sleepUntilAtom = atom({
  default: 0,
  key: 'RecoilSleepUntilState',
});

export const sleepUntilEndOfEpisodeAtom = atom({
  default: false,
  key: 'RecoilSleepUntilEndOfEpisodeState',
});
