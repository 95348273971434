import { useCallback, useMemo } from 'react';

import { EntitySubtype, EntityType, EpisodeSubType } from '@audacy-clients/client-services/core';
import playerState, { nowPlaying } from '@audacy-clients/core/atoms/player';
import { IEpisode, IStandaloneChapter } from '@audacy-clients/core/atoms/wrappers/types';
import usePlayerMetadata from '@audacy-clients/core/hooks/usePlayerMetadata';
import { DeeplinkQueryParams, LinkingActions } from '@audacy-clients/core/types/navigation';
import { PlayerState } from '@audacy-clients/core/types/player';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

interface IUsePlayerProps {
  artist?: string;
  contentId?: string;
  episodeId?: string;
  duration?: number;
  description?: string;
  episode?: IEpisode;
  hasMetaData?: boolean;
  imageAlt: string;
  imageUrl?: string;
  isIdle: boolean;
  isLoading?: boolean;
  isOpen?: boolean;
  isPlaying?: boolean;
  onOpen: () => void;
  onClose: () => void;
  station?: string;
  type?: EntityType;
  subtype?: EntitySubtype;
  srcFallback?: string;
  url?: string;
  playerTitle?: string;
  playerSubtitle?: string;
  miniPlayerTitle?: string;
  miniPlayerSubtitle?: string;
  parentShowUrl?: string;
  showContentId?: string;
  standaloneChapter?: IStandaloneChapter;
}

const usePlayerProps = (): IUsePlayerProps => {
  const playerItem = useRecoilValue(nowPlaying);
  const { playState } = useRecoilValue(playerState);
  const [searchParams, setSearchParams] = useSearchParams();

  const onOpen = useCallback(() => {
    const playerItemId = playerItem?.dataObject?.id || '';
    setSearchParams({
      action: LinkingActions.AutoplayFull,
      actionContentId: playerItemId,
    });
  }, [playerItem, setSearchParams]);

  const onClose = useCallback(() => {
    const playerItemId = playerItem?.dataObject?.id || '';
    setSearchParams({
      action: LinkingActions.AutoplayMini,
      actionContentId: playerItemId,
    });
  }, [setSearchParams, playerItem?.dataObject?.id]);

  const imageAlt =
    playerItem?.episodeDataObject?.imageAlt ||
    playerItem?.episodeDataObject?.parentImageAlt ||
    playerItem?.dataObject?.imageAlt ||
    playerItem?.dataObject?.parentImageAlt ||
    '';

  const { playerTitle, playerSubtitle, miniPlayerTitle, miniPlayerSubtitle } = usePlayerMetadata({
    contentType: playerItem?.dataObject?.subtype,
    publishDate: playerItem?.episodeDataObject?.publishDate,
    title: playerItem?.title,
    station: playerItem?.station,
    artist: playerItem?.artist,
    category: playerItem?.dataObject?.category,
    startTime: playerItem?.startTime,
    endTime: playerItem?.endTime,
    isBroadcastShow:
      playerItem?.episodeDataObject?.subtype === EpisodeSubType.BROADCAST_SHOW_EPISODE,
  });

  const description =
    playerItem?.episodeDataObject?.description ||
    playerItem?.standaloneChapterDataObject?.description ||
    playerItem?.dataObject?.description;

  const parentShowUrl =
    playerItem?.episodeDataObject?.parentShowUrl ||
    playerItem?.standaloneChapterDataObject?.parentShowUrl;

  return useMemo(
    () => ({
      imageUrl: playerItem?.image?.src,
      srcFallback: playerItem?.image?.srcFallback,
      imageAlt,
      playerTitle,
      playerSubtitle,
      miniPlayerTitle,
      miniPlayerSubtitle,
      station: playerItem?.station,
      artist: playerItem?.artist,
      description,
      contentId: playerItem?.dataObject?.id,
      episodeId: playerItem?.episodeDataObject?.episodeId || playerItem?.episodeDataObject?.id,
      isLoading: playState === PlayerState.Loading,
      hasMetaData: !!playerItem,
      isOpen: searchParams.get(DeeplinkQueryParams.Action) === LinkingActions.AutoplayFull,
      onOpen,
      onClose,
      isIdle: playState === PlayerState.Idle,
      episode: playerItem?.episodeDataObject,
      standaloneChapter: playerItem?.standaloneChapterDataObject,
      type: playerItem?.dataObject?.type,
      subtype: playerItem?.dataObject?.subtype,
      url: playerItem?.dataObject?.url,
      duration: playerItem?.dataObject?.duration,
      parentShowUrl,
      showContentId: playerItem?.episodeDataObject?.showContentId,
    }),
    [
      playerItem,
      description,
      imageAlt,
      playerTitle,
      playerSubtitle,
      miniPlayerTitle,
      miniPlayerSubtitle,
      playState,
      searchParams,
      onOpen,
      onClose,
      parentShowUrl,
    ],
  );
};

export default usePlayerProps;
