import { AnimatePresence, motion, type MotionProps } from 'framer-motion';
import React, { type JSX } from 'react';
import { fadeInOut } from '~/constants/animation';
import { Timing } from '~/styles';
import { type TStyles } from '~/types/emotion-styles';

type IAnimateProps = {
  motionCss?: TStyles | Array<TStyles>;
  children?: React.ReactNode;
  isVisible?: boolean;
} & MotionProps;

const Animate = ({ children, isVisible, motionCss, ...rest }: IAnimateProps): JSX.Element => (
  <AnimatePresence>
    {isVisible && (
      <motion.div {...rest} css={motionCss}>
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);

const FadeInOut = ({ children, isVisible, ...rest }: IAnimateProps): JSX.Element => (
  <Animate isVisible={isVisible} {...fadeInOut()} {...rest}>
    {children}
  </Animate>
);

const FadeInOnMount = ({ children }: IAnimateProps): JSX.Element => (
  <motion.div
    {...fadeInOut({
      durationIn: Timing.pageTransitionIn,
      durationOut: 0,
    })}
  >
    {children}
  </motion.div>
);

Animate.FadeInOut = FadeInOut;
Animate.FadeInOnMount = FadeInOnMount;

export default Animate;
