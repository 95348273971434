import { useState } from 'react';

import { EntityImageShape } from '@audacy-clients/core/atoms/wrappers/modules';

import placeholderImage from '~/assets/images/placeholder.svg';
import EntityGridImage from '~/components/Entity/components/EntityGridImage';
import Image from '~/components/Image';
import { IImageSize } from '~/components/Image/types';
import { Mixins } from '~/styles';
import { TStyles } from '~/types/emotion-styles';

import styles from './styles';

export interface IEntityImageProps {
  alt?: string;
  aspectRatio?: number;
  entityImageShape?: EntityImageShape;
  entityImageCss?: TStyles;
  hasRadius?: boolean;
  imageURIBase?: string | string[];
  sizes?: IImageSize[];
  isLoading?: boolean;
  fallbackImage?: string;
}

const EntityImage = ({
  alt,
  aspectRatio = 1,
  entityImageShape = EntityImageShape.Square,
  entityImageCss,
  hasRadius = true,
  imageURIBase,
  sizes,
  isLoading,
  fallbackImage,
}: IEntityImageProps): JSX.Element => {
  const [isError, setIsError] = useState(false);
  const isCircle = entityImageShape === EntityImageShape.Circular;
  const imgSrc = isError
    ? fallbackImage || placeholderImage
    : imageURIBase || fallbackImage || placeholderImage;

  // TODO: [A2-4317] remove member GridImage from EntityImageShape enum
  // to align closer with mobile. Ticket:
  // https://entercomdigitalservices.atlassian.net/browse/A2-4317
  const isGridImage = entityImageShape === EntityImageShape.GridImage;

  const [isLoaded, setIsLoaded] = useState(false);
  const onLoad = (): void => {
    setIsLoaded(true);
  };

  const onError = (): void => {
    setIsError(true);
  };

  if (Array.isArray(imgSrc)) {
    return <EntityGridImage imageURICollection={imgSrc} sizes={sizes} alt={alt} />;
  }

  return (
    <div
      css={[
        styles.container,
        hasRadius && styles.withRadius,
        isCircle && [styles.circle, Mixins.aspectRatio(1)],
        aspectRatio && !isCircle && Mixins.aspectRatio(aspectRatio),
        isGridImage && styles.gridImage,
        isLoaded && !isLoading && styles.loaded,
        entityImageCss,
      ]}
    >
      {imgSrc && (
        <Image
          alt={alt || ''}
          imgCss={styles.image}
          onError={onError}
          onLoad={onLoad}
          sizes={sizes}
          src={imgSrc}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default EntityImage;
