export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Profile</title>
    <path d="M8.994 10a7 7 0 1 0 14 0 7 7 0 0 0-14 0Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.469 27.783 3.494 27c-.975-.782-.974-.782-.974-.783l.001-.001.002-.003.006-.007.015-.018a4.734 4.734 0 0 1 .22-.25c.148-.16.362-.38.642-.638a15.751 15.751 0 0 1 2.507-1.87c2.232-1.35 5.578-2.68 10.081-2.68 4.504 0 7.85 1.33 10.082 2.68a15.75 15.75 0 0 1 2.506 1.87 11.339 11.339 0 0 1 .814.83l.049.058.015.018.006.007.002.003c.001.002.002.002-.974.784-.975.782-.974.782-.974.783a8.81 8.81 0 0 0-.632-.646 13.245 13.245 0 0 0-2.107-1.568c-1.896-1.148-4.8-2.319-8.787-2.319-3.987 0-6.89 1.17-8.786 2.32a13.247 13.247 0 0 0-2.107 1.568 8.78 8.78 0 0 0-.611.62 1.864 1.864 0 0 0-.022.026v-.001Zm0 0-.002.002v.001l.001-.002Zm23.051 0a.051.051 0 0 0 .003.003h-.001l-.001-.002Z"
      fill="currentColor"
    />
  </svg>
);
