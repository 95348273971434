export default (
  <svg width="47" height="14" viewBox="0 0 47 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="47" height="14" rx="3" fill="#FE5000" />
    <line x1="5.5" y1="10" x2="5.5" y2="7" stroke="white" />
    <line x1="7.5" y1="10" x2="7.5" y2="4" stroke="white" />
    <line x1="9.5" y1="10" x2="9.5" y2="6" stroke="white" />
    <line x1="11.5" y1="10" x2="11.5" y2="8" stroke="white" />
    <path
      d="M17.2107 10.135C15.5277 10.135 14.4297 8.839 14.4297 6.85C14.4297 4.861 15.5277 3.565 17.2107 3.565C18.8937 3.565 20.0007 4.861 20.0007 6.85C20.0007 8.839 18.8937 10.135 17.2107 10.135ZM17.2107 8.92C18.1197 8.92 18.6687 8.146 18.6687 6.85C18.6687 5.563 18.1107 4.78 17.2107 4.78C16.3197 4.78 15.7617 5.563 15.7617 6.85C15.7617 8.146 16.3017 8.92 17.2107 8.92ZM21.9425 10H20.6105V3.7H21.9785L24.1115 7.705H24.1295V3.7H25.4615V10H24.0935L21.9605 5.995H21.9425V10ZM28.6453 7.939H26.1613V6.778H28.6453V7.939ZM34.2252 10H32.9022L32.5692 9.019H30.1572L29.8242 10H28.5012L30.7512 3.7H31.9752L34.2252 10ZM31.3542 5.527L30.5622 7.849H32.1642L31.3722 5.527H31.3542ZM35.8629 10H34.5309V3.7H35.8629V10ZM40.3279 7.633V7.642L41.6059 10H40.1749L38.9869 7.786H38.1769V10H36.8449V3.7H39.4099C40.7419 3.7 41.5609 4.474 41.5609 5.734C41.5609 6.661 41.1019 7.336 40.3279 7.633ZM38.1769 4.915V6.571H39.2479C39.8869 6.571 40.2289 6.274 40.2289 5.725C40.2289 5.185 39.8959 4.915 39.2479 4.915H38.1769Z"
      fill="white"
    />
  </svg>
);
