import { EntityImageShape } from '@audacy-clients/core/atoms/wrappers/modules';

import EntityImage from '~/components/Entity/components/EntityImage';
import { IImageSize } from '~/components/Image/types';
import { Colors } from '~/styles';
import { pick } from '~/utils/array';

import styles from './styles';

interface IEntityGridImageProps {
  imageURICollection: string[];
  sizes?: IImageSize[];
  alt?: string;
}

interface IFormattedTile {
  type: ItemType;
  uri: string;
}

enum ItemType {
  Tile,
  Image,
}

const backgroundColors = [Colors.audacyOrange, Colors.audacyOrange3];

const formatTiles = (imageURICollection: string[]): IFormattedTile[] => {
  const colorTile: IFormattedTile = { type: ItemType.Tile, uri: '' };
  const reformatted = imageURICollection.map((image) => {
    return { type: ItemType.Image, uri: image };
  });
  if (imageURICollection.length === 2) {
    reformatted.splice(1, 0, colorTile, colorTile);
    return reformatted;
  } else if (imageURICollection.length === 3) {
    reformatted.splice(3, 0, colorTile);
    return reformatted;
  } else if (imageURICollection.length > 4) {
    reformatted.splice(4);
    return reformatted;
  }
  return reformatted;
};

const EntityGridImage = ({
  imageURICollection,
  sizes,
  alt,
}: IEntityGridImageProps): JSX.Element => {
  const formattedTiles = formatTiles(imageURICollection);

  return (
    <div css={styles.gridContainer}>
      {formattedTiles.map(({ uri }, index) => {
        return (
          <div key={index} css={styles.gridItem}>
            <EntityImage
              alt={alt || ''}
              aspectRatio={1}
              entityImageCss={!uri ? { background: pick(backgroundColors) } : {}}
              entityImageShape={EntityImageShape.GridImage}
              imageURIBase={uri ? uri : ''}
              sizes={sizes}
            />
          </div>
        );
      })}
    </div>
  );
};

export default EntityGridImage;
