import { A11y, FreeMode, Mousewheel, Navigation } from 'swiper';
import { NavigationOptions, SwiperOptions } from 'swiper/types';

import { Breakpoint, Breakpoints } from '~/styles/breakpoints';

// space between slides on small and medium/large/XXLarge
export const spacing = {
  small: 4,
  mediumAndLarge: 8,
  bigScreens: 20,
};

const getDefaults = (navigation: NavigationOptions): SwiperOptions => ({
  modules: [A11y, FreeMode, Mousewheel, Navigation],
  spaceBetween: spacing.small,
  slidesPerView: 2.5,
  speed: 300,
  navigation,
  watchSlidesProgress: true, // to identify visible (in viewport) vs non-visible slides
  mousewheel: {
    forceToAxis: true,
  },
  a11y: {
    slideLabelMessage: 'Slide {{index}} of {{slidesLength}}',
  },
  freeMode: {
    enabled: true,
    sticky: true,
    momentumBounceRatio: 0.25,
    momentumRatio: 1,
    momentumVelocityRatio: 1.5,
  },
  breakpoints: {
    [Breakpoints[Breakpoint.Medium]]: {
      slidesPerView: 4.25,
      spaceBetween: spacing.mediumAndLarge,
    },
    [Breakpoints[Breakpoint.Large]]: {
      slidesPerView: 6,
      spaceBetween: spacing.mediumAndLarge,
    },
  },
});

const gridImage = {
  slidesPerView: 1.75,
  breakpoints: {
    [Breakpoints[Breakpoint.Medium]]: {
      slidesPerView: 2.75,
      spaceBetween: spacing.mediumAndLarge,
    },
    [Breakpoints[Breakpoint.Large]]: {
      slidesPerView: 4,
      spaceBetween: spacing.mediumAndLarge,
    },
  },
};

const featured = {
  slidesPerView: 1,
  breakpoints: {
    [Breakpoints[Breakpoint.Medium]]: {
      slidesPerView: 2,
      spaceBetween: spacing.mediumAndLarge,
    },
    [Breakpoints[Breakpoint.XXLarge]]: {
      slidesPerView: 2,
      spaceBetween: spacing.bigScreens,
    },
  },
};

const featuredSingleAndDouble: Record<number, object> = {
  1: {
    slidesPerView: 1,
    breakpoints: {
      [Breakpoints[Breakpoint.Medium]]: {
        slidesPerView: 1,
      },
    },
  },
  2: {
    slidesPerView: 1,
    breakpoints: {
      [Breakpoints[Breakpoint.Medium]]: {
        slidesPerView: 2,
        spaceBetween: spacing.mediumAndLarge,
      },
      [Breakpoints[Breakpoint.XXLarge]]: {
        slidesPerView: 2,
        spaceBetween: spacing.bigScreens,
      },
    },
  },
};

const stacked = {
  slidesPerView: 1.1,
  breakpoints: {
    [Breakpoints[Breakpoint.Medium]]: {
      slidesPerView: 2.2,
      spaceBetween: spacing.mediumAndLarge,
    },
    [Breakpoints[Breakpoint.Large]]: {
      slidesPerView: 3,
      spaceBetween: spacing.mediumAndLarge,
    },
  },
  // As slides of stacked carousels represent columns that can contain multiple elements/cards
  // we assign "role" and "aria-label" separately inside <StackedCarousel>. Same thing goes for
  // "sideColumnStacked" further below.
  a11y: {
    slideLabelMessage: '',
    slideRole: '',
  },
};

const sideColumn = {
  breakpoints: {
    [Breakpoints[Breakpoint.Large]]: {
      slidesPerView: 3,
      spaceBetween: spacing.mediumAndLarge,
    },
  },
};

const sideColumnStacked = {
  slidesPerView: 1.1,
  breakpoints: {
    [Breakpoints[Breakpoint.Medium]]: {
      slidesPerView: 2.2,
      spaceBetween: spacing.mediumAndLarge,
    },
    [Breakpoints[Breakpoint.Large]]: {
      slidesPerView: 1,
      spaceBetween: spacing.mediumAndLarge,
    },
  },
  a11y: {
    slideLabelMessage: '',
    slideRole: '',
  },
};

export const SwiperParams = {
  getDefaults,
  gridImage,
  featured,
  featuredSingleAndDouble,
  stacked,
  sideColumn,
  sideColumnStacked,
};
