export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Report</title>
    <path
      d="m4 11 7-7h10l7 7v10l-7 7H11l-7-7V11ZM16 9.5v8"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <circle cx="16" cy="21" r="1" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
  </svg>
);
