import { useLayoutEffect } from 'react';

import throttle from 'lodash/throttle';
import { useSetRecoilState } from 'recoil';

import { windowSizeAtom } from '~/state/window';
import { isBrowser } from '~/utils/browser';

const useWindowSize = (): void => {
  const hasWindow = isBrowser();
  const setWindowSize = useSetRecoilState(windowSizeAtom);

  useLayoutEffect(() => {
    if (!hasWindow) {
      return;
    }

    const handleResize = () => {
      setWindowSize({
        height: document.documentElement.clientHeight,
        width: document.documentElement.clientWidth,
      });
    };

    const handleResizeThrottled = throttle(handleResize, 100);

    window.addEventListener('resize', handleResizeThrottled);

    handleResize(); // triggers a re-render, potentially rendering a new value for element

    return () => {
      handleResizeThrottled.cancel();
      window.removeEventListener('resize', handleResizeThrottled);
    };
  }, [hasWindow, setWindowSize]); // if element changes, reset it.
};

export default useWindowSize;
