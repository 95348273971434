import { miniPlayerHeight } from '~/components/Player/MiniPlayer/styles';
import { BP, Colors, mq, ZIndex } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const queueTopOffset = {
  [BP.Small]: miniPlayerHeight[BP.Small],
  [BP.Medium]: miniPlayerHeight[BP.Medium],
};

const styles: TStyleSheet = {
  container: {
    backgroundColor: Colors.audacyDarkNavy,
    height: '100vh',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100vw',
    zIndex: ZIndex.fullPlayer,
    [mq(BP.Large)]: {
      left: 'auto',
      overflow: 'visible',
      top: 'auto',
    },
  },
  playerContent: {
    height: '100%',
    width: '100%',
  },
  mobileHeightFix: {
    height: '-webkit-fill-available',
  },
  fullPlayer: {
    height: '100%',
    width: '100%',
    [mq(BP.Large)]: {
      display: 'flex',
    },
  },
  closeButton: {
    backgroundColor: Colors.white15,
    position: 'fixed',
    right: 24,
    top: 20,
    zIndex: ZIndex.fullPlayerCloseButton,
    '&:focus': {
      position: 'fixed',
    },
    '& path': {
      vectorEffect: 'non-scaling-stroke',
    },
    [mq(BP.Medium)]: {
      right: 26,
      top: 26,
    },
    [mq(BP.Large)]: {
      backgroundColor: Colors.white30,
    },
  },
  mainContainer: {
    [mq(BP.Large)]: {
      display: 'grid',
      gridTemplateColumns: '4fr 3fr',
      flexGrow: 1,
    },
  },
  playerContainer: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    [mq(BP.Large)]: {
      borderLeft: '1px solid',
      borderRight: '1px solid',
      borderColor: Colors.white15,
      overflow: 'visible',
      minWidth: 0, //Needed to prevent long subtitle from expanding container
    },
  },
  queueContainer: {
    backgroundColor: Colors.audacyDarkNavy,
    minHeight: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: ZIndex.fullPlayerPanel,
    [mq(BP.Large)]: {
      backgroundColor: 'transparent',
      position: 'relative',
      flexBasis: '30%',
      flexShrink: 0,
      width: 'auto',
    },
  },
  queue: {
    height: `calc(100vh - ${queueTopOffset[BP.Small]}px)`,
    overflow: 'visible',
    padding: `40px 0 80px`,
    margin: `${queueTopOffset[BP.Small]}px 20px 0 20px`,
    [mq(BP.Medium)]: {
      height: `calc(100vh - ${queueTopOffset[BP.Medium]}px)`,
      margin: `${queueTopOffset[BP.Medium]}px 42px 0 42px`,
    },
    [mq(BP.Large)]: {
      height: 'calc(100vh - 100px)',
      padding: 0,
      paddingTop: 5,
      margin: `100px 58px 0 58px`,
    },
  },
  queueListContainer: {
    padding: 0,
    height: '100%',
  },
  queueEmpty: {
    maxWidth: 240,
    [mq(BP.Medium)]: {
      maxWidth: 240,
    },
    [mq(BP.Large)]: {
      maxWidth: 240,
    },
  },
  queueList: {
    height: '100%',
  },
  infoContainer: {
    height: '100%',
    overflow: 'auto',
    position: 'relative',
  },
  queuePlayer: {
    backgroundColor: Colors.audacyDarkNavy,
    left: 0,
    padding: '0 20px',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: ZIndex.fullPlayerQueueHeader,
    [mq(BP.Medium)]: {
      padding: '0 42px',
    },
  },
  queuePlayerInner: {
    borderBottom: '1px solid',
    borderColor: Colors.white15,
    display: 'flex',
  },
  queueBackBtn: {
    marginRight: 10,
  },
  miniPlayer: {
    minWidth: 0,
    padding: 0,
    position: 'relative',
    [mq(BP.Medium)]: {
      paddingRight: 0,
    },
  },
};

export default styles;
