export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Unmuted mic</title>
    <path d="M11 8a5 5 0 0 1 10 0v7a5 5 0 0 1-10 0V8Z" stroke="currentColor" strokeWidth="1.5" />
    <path
      d="M7 14v5a5 5 0 0 0 5 5h8a5 5 0 0 0 5-5v-5M15.95 24v5"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);
