import { type IFeatureFlags } from '@audacy-clients/core/atoms/config/settings';
import URI from 'urijs';
import { assign } from 'xstate';
import { choose, sendParent } from 'xstate/lib/actions';
import AudacyLogger from '../../../AudacyLogger';
import clientServicesConfig, { AdvertisingIdAttsType } from '../../../Config';
import {
  BundleID,
  EntityType,
  Platform,
  PlayerAction,
  PlayerMetric,
  SourceStringsMap,
  StationSubType,
  StoreID,
  StoreURL,
} from '../../../Constants';
import {
  type ICredentialsProvider,
  type ILocationProvider,
  type IMarketingDataProvider,
} from '../../../Container';
import DataServices from '../../../dataServices/DataServices';
import type Station from '../../../dataServices/Station';
import { SessionStorageKey } from '../../../personalizationServices/constants';
import { sha256 } from '../../../Utilities';
import {
  webSafe64,
  serializeDOMException,
  featureFlagMap,
  isEnableTraceSessionEnabled,
} from '../../../utils';
import { expandUrlWithMacros } from '../../../utils/browser';
import { getGppString } from '../../../utils/gpp-util';
import { getRubiconUserId, syncMagnite } from '../../../utils/magnite';
import { type TPlayableObject, type IFailure } from '../../types';
import {
  ADSWIZZ_SCRIPT_URL,
  ADSWIZZ_SYNC_SCRIPT_URL,
  AD_CONTENT_TYPES,
  AMPERWAVE_URL_TEST,
  INITIAL_RETRY_DELAY,
  MAX_DELAY,
  MAX_RETRY_ATTEMPTS,
} from '../constants';
import { type ILiveStationAudioSource, type IStreamerMachineContext, type ITime } from '../types';
import { appendAmperwaveLocationParams } from './amperwave/location';

declare global {
  // com_adswizz_synchro_listenerid comes from the adswizz script
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const com_adswizz_synchro_listenerid: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const autoplayAction: any = choose([
  {
    cond: ({ autoplay }: IStreamerMachineContext<TPlayableObject>) => autoplay,
    actions: [
      sendParent({
        type: 'PLAY',
      }),
    ],
  },
  {
    actions: [
      // Destroy the stream to stop infinite HLS buffering
      sendParent({
        type: 'PAUSE',
      }),
      // Reset autoplay to true (the default)
      assign(() => ({ autoplay: true })),
    ],
  },
]);

export const DEFAULT_PLAYER_ACTIONS = {
  play: ({ player }: IStreamerMachineContext<TPlayableObject>) => player?.play(),
  playSuperHiFi: ({ player }: IStreamerMachineContext<TPlayableObject>) => player?.playSuperHiFi(),
  pause: ({ player }: IStreamerMachineContext<TPlayableObject>) => player.pause(),
  stop: ({ player }: IStreamerMachineContext<TPlayableObject>) => player.stop(),
  autoplay: autoplayAction,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logPlaybackError: (
    { contentType, ddError, item, logger, dataProvider }: IStreamerMachineContext<TPlayableObject>,
    event: object | undefined,
  ) => {
    const { error, errorMessage } = (event as IFailure) || {};
    const extractedError = error ? error.raw || error : errorMessage;
    const message = `Playback error for ${contentType} from ${item.getId()} - ${item.getTitle()}`;

    const isBrowser = typeof document !== 'undefined';
    let status = 'online';
    if (isBrowser && !window.navigator.onLine) {
      status = 'offline';
    }

    const networkStatus = isBrowser ? ` Network Status: ${status}` : '';

    const apiStats = JSON.stringify(dataProvider.stats);

    const isDOMException =
      typeof DOMException !== 'undefined' && extractedError instanceof DOMException;

    const errorString = isDOMException
      ? serializeDOMException(extractedError)
      : JSON.stringify(extractedError);

    const extractedErrorMessage = `Error: ${errorString} Api Stats: ${apiStats} ${networkStatus}`;
    logger?.error(`${message}: ${extractedErrorMessage}`);
    ddError(`${message}: ${extractedErrorMessage}`);
  },

  sendPlaybackErrorEvent: (
    context: IStreamerMachineContext<TPlayableObject>,
    event: object | undefined,
  ) => {
    context.analyticsProvider.sendPlaybackErrorEvent(context, event);
  },

  logPlaybackFailure: async ({
    contentType,
    ddError,
    item,
    logger,
    errors,
    firstLoadTime,
    analyticsProvider,
    retryCount,
    dataProvider,
    deviceInfoProvider,
  }: IStreamerMachineContext<TPlayableObject>) => {
    const isBrowser = typeof document !== 'undefined';
    let status = 'online';
    if (isBrowser && !window.navigator.onLine) {
      status = 'offline';
    }

    const networkStatus = isBrowser ? ` Network Status: ${status}` : '';
    const apiStats = JSON.stringify(dataProvider.stats);

    const { bandwidthEstimateBPS, historicBandwidthEstimateBPS, type } =
      (await deviceInfoProvider?.getNetworkInfo()) || {};

    const message = `Playback failed for ${item.getId()} - ${item.getTitle()} ${
      contentType ? `- ${contentType} Api Stats: ${apiStats} ${networkStatus}` : ''
    }`;
    logger?.error(message);
    ddError(message);

    analyticsProvider.sendEventToListener({
      type: PlayerMetric.STREAM_PLAYBACK_FAILURE,
      eventDetails: {
        stationId: item.getId(),
        streamerType: contentType,
        uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
        mostRecentError: errors?.[0],
        retryCount,
        bandwidthEstimateBPS,
        historicBandwidthEstimateBPS,
        connectivityType: type,
      },
    });
  },
  logLoadingError: ({ item, ddError, logger }: IStreamerMachineContext<TPlayableObject>) => {
    const message = `Failed to load sources from ${item.getId()} - ${item.getTitle()}`;
    logger?.error(message);
    ddError(message);
  },
  logRetry: ({
    ddError,
    item,
    logger,
    retryCount = 0,
    errors,
    firstLoadTime,
    analyticsProvider,
    contentType,
  }: IStreamerMachineContext<TPlayableObject>) => {
    if (retryCount < MAX_RETRY_ATTEMPTS) {
      const message = `Playback Failed for ${item.getId()} - ${item.getTitle()}. Attempt ${retryCount} of ${MAX_RETRY_ATTEMPTS} to restart playback.`;
      ddError(message);
      logger?.info(message);
      AudacyLogger.log(
        `[StationMachine] StreamerUtils: Retry Attempt ${retryCount} for ${item.getId()}`,
      );
    }
    analyticsProvider.sendEventToListener({
      type: PlayerMetric.STREAM_RECONNECT_ATTEMPT,
      eventDetails: {
        stationId: item.getId(),
        streamerType: contentType,
        uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
        mostRecentError: errors?.[0],
        retryCount,
      },
    });
  },
  // Analytics Failed Playback
  sendPlaybackFailedEvent: ({
    analyticsProvider,
    item,
    deviceInfoProvider,
  }: IStreamerMachineContext<TPlayableObject>) => {
    const contentId = item.getId();

    const audioRoute = deviceInfoProvider?.getAudioRoute();

    if (contentId) {
      analyticsProvider.sendPlayerEvent({
        type: PlayerAction.PLAYBACK_FAILED,
        contentId: contentId,
        streamUrl: getFirstAudioSourceUrl(item?.data.streamUrl),
        connectionType: audioRoute,
      });
    }
  },
};

type IAmperwaveOptions = {
  platform: Platform;
  marketingDataProvider?: IMarketingDataProvider;
  credentialsProvider?: ICredentialsProvider;
  locationProvider?: ILocationProvider;
  item: TPlayableObject;
  sessionId?: string;
  featureFlags?: IFeatureFlags;
};

type ISuperHifiOptions = {
  platform: Platform;
  marketingDataProvider?: IMarketingDataProvider;
  credentialsProvider?: ICredentialsProvider;
  action: string;
  continuity: number;
};

type IAudioPreroll = {
  companionAd: string;
  creativeUrl: string;
  adStartTrackers: Array<string>;
  companionAdClickThroughTracker: string;
};

export const isContentTypeAd = (contentType?: string): boolean =>
  !!contentType && AD_CONTENT_TYPES.has(contentType);

export const appendSuperHifiParameters = (
  url: string,
  { action, continuity, credentialsProvider, marketingDataProvider, platform }: ISuperHifiOptions,
) => {
  // add shf params
  const uri = new URI(url);
  uri.setQuery('action', action);
  uri.setQuery('continuity', continuity);
  uri.setQuery('s', credentialsProvider?.userToken);
  uri.setQuery('i', 1);
  uri.setQuery('udid', credentialsProvider?.userToken);
  uri.setQuery('userToken', credentialsProvider?.userToken);

  // add basic params
  uri.setQuery('apv', 'a2');
  uri.setQuery('dist', 'Audacy');
  uri.setQuery('source', SourceStringsMap[platform ?? Platform.WEB]);

  // adds GPP string as param
  uri.setQuery('gpp', getGppString({ optIn: !clientServicesConfig.disableTargetedAds }));

  const { gender, zipCode, dateOfBirth, birthYear } = marketingDataProvider?.profile || {};

  if (dateOfBirth) {
    const age = (): number | undefined =>
      dateOfBirth ? ((Date.now() - new Date(dateOfBirth).getTime()) / 3.15576e10) | 0 : undefined;
    uri.setQuery('c_age', age());
  }

  if (gender) {
    uri.setQuery('c_gender', gender);
  }

  if (zipCode) {
    uri.setQuery('c_home_zip', zipCode);
  }

  if (credentialsProvider?.userToken) {
    uri.setQuery('c_user_id', credentialsProvider.userToken);
  }

  if (birthYear) {
    uri.setQuery('c_yob', birthYear);
  }

  return uri.normalize().valueOf();
};

const appendAmperwaveParameters = async (
  url: string,
  options: IAmperwaveOptions,
): Promise<string> => {
  const { platform, marketingDataProvider, credentialsProvider, item, featureFlags, sessionId } =
    options;

  let uri: URI = new URI(url);
  uri.addQuery({
    apv: 'a2',
    dist: 'Audacy',
    source: SourceStringsMap[platform ?? Platform.WEB],
    gpp: getGppString({ optIn: !clientServicesConfig.disableTargetedAds }),
  });

  const shouldAddDbi =
    !!featureFlags?.optimizedAdBreaks &&
    item.data.entityType === EntityType.STATION &&
    item.data.entitySubtype === StationSubType.BROADCAST &&
    item.data.ownerOperatorIdent === '501-1';

  if (shouldAddDbi) {
    uri.addQuery('dbi', '1');
  }

  const appVersion =
    process.env.APP_VERSION ?? clientServicesConfig.personalizationServices.appVersion;

  if (appVersion) {
    uri.addQuery('a2_app_version', appVersion);
  }

  if (sessionId) {
    uri.addQuery('a2_session_id', sessionId);
  }

  const platformString = SourceStringsMap[platform];
  if (platformString) {
    uri.addQuery('source', platformString);
  }

  // good station to test this with is the station with slug 'comedy-now'
  if (uri.host().indexOf(AMPERWAVE_URL_TEST) > -1) {
    const { emailAddress, gender, zipCode, dateOfBirth, birthYear } =
      marketingDataProvider?.profile || {};

    if (emailAddress && !!featureFlags?.useHem) {
      uri.addQuery('hem', sha256(emailAddress));
    }

    const deviceId =
      platform === Platform.WEB
        ? await DataServices.getTritonCookie()
        : await (marketingDataProvider?.getAdvertisingId?.() ?? Promise.resolve(undefined));

    // mobile specific
    if (platform === Platform.ANDROID || platform === Platform.IOS) {
      uri.addQuery({
        'store-id': StoreID[platform],
        'store-url': StoreURL[platform],
        'bundle-id': BundleID[platform],
      });

      // IOS specific
      if (platform === Platform.IOS) {
        uri.addQuery(
          'atts',
          deviceId?.startsWith('idfa:')
            ? AdvertisingIdAttsType.AUTHORIZED
            : AdvertisingIdAttsType.DENIED,
        );
      }
    }

    // web specific
    if (platform === Platform.WEB) {
      const dataStore = clientServicesConfig?.personalizationServices?.dataStore;

      const rubiconUserId = getRubiconUserId();
      if (!rubiconUserId && credentialsProvider?.userToken) {
        syncMagnite(credentialsProvider.userToken);
      }

      if (rubiconUserId) {
        uri.addQuery('m_partner_ids', rubiconUserId);
      }

      let fullTritonCookie = dataStore?.getDataSync(
        SessionStorageKey.FULL_TRITON_COOKIE,
        sessionStorage,
      );
      if (!fullTritonCookie) {
        const tritonName =
          ((item as Station).getTritonName && (item as Station).getTritonName()) || '';

        loadTritonCookieSyncScript(tritonName);
        fullTritonCookie = await DataServices.getFullTritonCookie();
        if (fullTritonCookie) {
          dataStore?.setDataSync(
            SessionStorageKey.FULL_TRITON_COOKIE,
            fullTritonCookie,
            sessionStorage,
          );
        }
      }

      if (fullTritonCookie) {
        uri.addQuery('t_partner_ids', webSafe64(btoa(JSON.stringify(fullTritonCookie))));
      }

      let adsWizzListenerId = dataStore?.getDataSync(
        SessionStorageKey.ADSWIZZ_LISTENER_ID,
        sessionStorage,
      );
      if (!adsWizzListenerId) {
        const gppString = getGppString({
          optIn: !clientServicesConfig.disableTargetedAds,
        });
        adsWizzListenerId = await syncAdsWizzPartnerId(gppString);
        if (adsWizzListenerId) {
          dataStore?.setDataSync(
            SessionStorageKey.ADSWIZZ_LISTENER_ID,
            adsWizzListenerId,
            sessionStorage,
          );
        }
      }

      if (adsWizzListenerId) {
        uri.addQuery('a_partner_ids', adsWizzListenerId);
      }
    }

    if (deviceId) {
      uri.addQuery('deviceid', deviceId);
    } else if (credentialsProvider?.userToken) {
      uri.addQuery('deviceid', `app:${credentialsProvider.userToken}`);
    }

    if (dateOfBirth) {
      const age = (): number | undefined =>
        dateOfBirth ? ((Date.now() - new Date(dateOfBirth).getTime()) / 3.15576e10) | 0 : undefined;
      uri.addQuery('c_age', age());
    }

    if (gender) {
      uri.addQuery('c_gender', gender);
    }

    if (zipCode) {
      uri.addQuery('c_home_zip', zipCode);
    }

    if (credentialsProvider?.userToken) {
      uri.addQuery('c_user_id', credentialsProvider.userToken);
    }

    if (birthYear) {
      uri.addQuery('c_yob', birthYear);
    }

    uri = await appendAmperwaveLocationParams(uri, options);
  }

  if (isEnableTraceSessionEnabled()) {
    uri.addQuery('ete', 'all');
  }

  return uri.normalize().valueOf();
};

export const loadSourcesForItem = async ({
  marketingDataProvider,
  locationProvider,
  credentialsProvider,
  platform,
  item,
  dataProvider: { sessionId },
}: Pick<
  IStreamerMachineContext<TPlayableObject>,
  | 'marketingDataProvider'
  | 'locationProvider'
  | 'credentialsProvider'
  | 'platform'
  | 'item'
  | 'dataProvider'
>): Promise<Array<ILiveStationAudioSource>> => {
  const sources: Array<ILiveStationAudioSource> = [];
  const featureFlags = featureFlagMap.getAll();

  if ('getHlsStream' in item && item.getHlsStream()) {
    sources.push({ url: item.getHlsStream(), type: 'm3u8' });
  }

  if ('getAacStream' in item && item.getAacStream()) {
    sources.push({ url: item.getAacStream(), type: 'aac' });
  }

  if ('getMp3Stream' in item && item.getMp3Stream()) {
    sources.push({ url: item.getMp3Stream(), type: 'mp3' });
  }

  if (!('getHlsStream' in item) && 'getAudioStream' in item && item.getAudioStream()) {
    sources.push({ url: item.getAudioStream(), type: 'm3u8' });
  }

  for (const source of sources) {
    source.url = await appendAmperwaveParameters(source.url, {
      marketingDataProvider,
      locationProvider,
      credentialsProvider,
      platform,
      item,
      sessionId,
      featureFlags,
    });
  }

  return sources;
};

export function getFirstAudioSourceUrl(
  audioSources: Record<string, string> | string | null | undefined,
): string | undefined {
  if (typeof audioSources === 'string') {
    // If audioSources is a string, return it directly
    return audioSources;
  } else if (
    audioSources &&
    typeof audioSources === 'object' &&
    Object.keys(audioSources).length > 0
  ) {
    // If audioSources is an object and not empty, return the first URL
    const firstKey = Object.keys(audioSources)[0];
    return audioSources[firstKey];
  } else {
    // If audioSources is null, undefined, or an empty object, return undefined
    return;
  }
}

export function toIntegerOrUndefined(value: number | undefined): number | undefined {
  if (typeof value === 'number') {
    return Math.floor(value);
  }
  return undefined;
}

const syncAdsWizzPartnerId = async (gppString: string): Promise<string | void> => {
  try {
    const adswizzScriptUrl = expandUrlWithMacros(ADSWIZZ_SCRIPT_URL, {
      gppString: gppString,
    });
    await DataServices.loadScript(adswizzScriptUrl);

    if (com_adswizz_synchro_listenerid) {
      const adswizzSyncScriptUrl = expandUrlWithMacros(ADSWIZZ_SYNC_SCRIPT_URL, {
        partnerUserId: com_adswizz_synchro_listenerid,
      });
      DataServices.loadImg(adswizzSyncScriptUrl);

      return com_adswizz_synchro_listenerid;
    }
  } catch {
    console.error('Failed to set the adswizz cookie sync');
  }
};

const loadTritonCookieSyncScript = async (tritonName: string): Promise<void> => {
  const url = `https://playerservices.live.streamtheworld.com/api/idsync.js?station=${tritonName}&gdpr=0`;
  try {
    await DataServices.loadScript(url);
  } catch {
    console.error('Failed to set the Triton cookie sync');
  }
};

export const loadAudioPreroll = async (tritonName: string, env: string): Promise<IAudioPreroll> => {
  try {
    const preroll = await DataServices.getAudioPreroll(tritonName, env);
    return preroll;
  } catch (e) {
    console.error('Failed to get Audio Preroll', e);
  }
  return {
    adStartTrackers: [],
    creativeUrl: '',
    companionAd: '',
    companionAdClickThroughTracker: '',
  };
};

export const sendAudioPrerollTrackers = async (trackers: Array<string>): Promise<void> => {
  try {
    DataServices.sendAudioPrerollTrackers(trackers);
  } catch (e) {
    console.error('Audio Preroll Trackers Failed', e);
  }
};

export const getExponentialBackoffDelay = (retryCount?: number) => {
  const delay = Math.min(INITIAL_RETRY_DELAY * 2 ** (retryCount || 0), MAX_DELAY);
  return delay;
};

export const TIME_ZERO: ITime = { unit: 'ms', value: 0 };
export const timeFromMs = (ms: number): ITime => ({ unit: 'ms', value: ms });
export const timeFromS = (s: number): ITime => ({ unit: 's', value: s });
export const msFromTime = (time: ITime): number =>
  time.unit === 'ms' ? time.value : time.value * 1000;
export const sFromTime = (time: ITime): number =>
  time.unit === 's' ? time.value : time.value / 1000;
export const addTimes = (time1: ITime, time2: ITime): ITime =>
  timeFromMs(msFromTime(time1) + msFromTime(time2));
export const subtractTimes = (time1: ITime, time2: ITime): ITime =>
  timeFromMs(msFromTime(time1) - msFromTime(time2));
export const minTime = (time1: ITime, time2: ITime): ITime =>
  msFromTime(time1) < msFromTime(time2) ? time1 : time2;
export const maxTime = (time1: ITime, time2: ITime): ITime =>
  msFromTime(time1) > msFromTime(time2) ? time1 : time2;
