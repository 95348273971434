import { isLoggedIn } from '@audacy-clients/core/atoms/auth';
import { useFeatureFlag } from '@audacy-clients/core/atoms/config/settings';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import IconButton, { IconButtonThemes } from '~/components/Button/IconButton';
import { Icons } from '~/components/Icon/constants';
import OptionsMenuButton from '~/components/OptionsMenu/components/OptionsMenuButton';
import { OptionsMenuPosition } from '~/components/OptionsMenu/components/OptionsMenuContainer';
import { useGetPlayerOptionsMenu } from '~/components/Player/FullscreenPlayer/hooks';
import PlaybackSpeedButton from '~/components/Player/PlaybackSpeedButton';
import VolumeSlider from '~/components/Player/VolumeSlider';
import { useBreakpoints } from '~/hooks/use-breakpoints';

import styles from './styles';

type IBottomOptionsProps = {
  isQueueVisible: boolean;
  onQueueClick: () => void;
};

const BottomOptions = ({
  isQueueVisible,
  onQueueClick,

}: IBottomOptionsProps): JSX.Element => {
  const loggedIn = useRecoilValue(isLoggedIn);

  const isQueueEnabled = useFeatureFlag('queue') && loggedIn;
  const { greaterThan } = useBreakpoints();
  const { t } = useTranslation();
  const optionsMenu = useGetPlayerOptionsMenu();

  const buttonSizeProps = {
    buttonSizeInPx: greaterThan.MD ? 28 : 24,
    iconSizeInPx: greaterThan.MD ? 14 : 24,
  };

  const queueButtonTheme = greaterThan.MD
    ? isQueueVisible
      ? IconButtonThemes.Fill
      : IconButtonThemes.Outline
    : IconButtonThemes.Default;

  return (
    <div css={styles.container}>
      {isQueueEnabled && (
        <IconButton
          ariaLabel={t(
            `playerControls.accessibilityLabels.${isQueueVisible ? 'closeQueue' : 'openQueue'}`,
          )}
          onClick={onQueueClick}
          icon={Icons.Queue}
          theme={queueButtonTheme}
          {...buttonSizeProps}
        />
      )}

      <div css={styles.middleOptions}>
        <PlaybackSpeedButton isLeftAligned={!isQueueEnabled} />
        {greaterThan.SM && <VolumeSlider isFullPlayer />}
      </div>

      {optionsMenu ? (
        <OptionsMenuButton
          ariaLabel={t('playerControls.accessibilityLabels.options')}
          menuPosition={OptionsMenuPosition.TopLeft}
          optionsMenu={optionsMenu}
          theme={greaterThan.MD ? IconButtonThemes.Outline : IconButtonThemes.Default}
          {...buttonSizeProps}
        />
      ) : null}
    </div>
  );
};

export default BottomOptions;
