import { Colors } from './colors';

interface IGradientsProps {
  playerContainer: string;
  playerOverflowText: string;
  premium: string;
  premiumHover: string;
  premiumSecondary: string;
  primaryHover: string;
}

export const Gradients: IGradientsProps = {
  playerContainer: `linear-gradient(180deg, ${Colors.transparent} 0%, ${Colors.audacyDarkNavy} 100%)`,
  playerOverflowText: `linear-gradient(to right, ${Colors.black} 70%, ${Colors.transparent} 100%)`,
  premium: `linear-gradient(0deg, ${Colors.audacyDarkNavy10}, ${Colors.audacyDarkNavy10}), linear-gradient(90deg, ${Colors.secondaryPurple2} 5.46%, ${Colors.audacyOrange} 100%)`,
  premiumHover: `linear-gradient(0deg, ${Colors.audacyDarkNavy20}, ${Colors.audacyDarkNavy20}), linear-gradient(90deg, ${Colors.secondaryPurple2} 5.46%, ${Colors.audacyOrange2} 100%)`,
  premiumSecondary: `linear-gradient(to right, ${Colors.secondaryPurple2}, ${Colors.audacyOrange2})`,
  primaryHover: `linear-gradient(0deg, ${Colors.audacyDarkNavy15}, ${Colors.audacyDarkNavy15}), ${Colors.audacyOrange}`,
};
