import { DataEventType, PlayerAction } from '@audacy-clients/client-services/core';

export interface IEvent {
  type: string;
}

export const facebookEventAllowlist: string[] = [
  PlayerAction.PLAY,
  DataEventType.REGISTRATION_SUCCESSFUL,
];

export const filterFacebookEvents = (events: IEvent[]): IEvent[] => {
  const matchingEvents = events.filter((e) => facebookEventAllowlist.includes(e.type));

  return matchingEvents;
};
