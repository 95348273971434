import { EntityType } from '@audacy-clients/client-services/core';

const playableEntities = [
  EntityType.STATION,
  EntityType.EPISODE,
  EntityType.CLIP,
  EntityType.STANDALONE_CHAPTER,
  EntityType.PLAYLIST,
];

export const isPlayable = (entityType: EntityType): boolean =>
  playableEntities.includes(entityType);
