import { useContext } from 'react';

import { playerControlsState } from '@audacy-clients/core/atoms/player';
import { isPlayerButtonVisible } from '@audacy-clients/core/utils/player';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import Icon from '~/components/Icon';
import { FullPrimaryIconSize } from '~/components/Player/FullscreenPlayer/Components/FullPlayer/constants';
import usePlayerProps from '~/components/Player/hooks/usePlayerProps';
import { PlayerContext, PlayerQueueContext } from '~/components/Player/PlayerContextProvider';
import { PlayerControlsSpinner } from '~/components/Player/PlayerControls/Components/PlayerControlsSpinner';
import { fullPlayerStyles, miniPlayerStyles } from '~/components/Player/PlayerControls/styles';
import { IPlayerAction } from '~/components/Player/PlayerControls/types';
import { useBreakpoints } from '~/hooks/use-breakpoints';

type TPrimaryButtonProps = {
  action: IPlayerAction;
};
export const PlayerPrimaryButton = ({ action }: TPrimaryButtonProps): JSX.Element => {
  const { playButton } = useRecoilValue(playerControlsState) || {};

  const { hasMetaData, isLoading } = usePlayerProps();
  const { t } = useTranslation();
  const { bk } = useBreakpoints();
  const { isQueueHead } = useContext(PlayerQueueContext);
  const { isFullscreenPlayer } = useContext(PlayerContext);

  const canPlay = isPlayerButtonVisible(playButton);
  const primaryIconSize = isFullscreenPlayer ? FullPrimaryIconSize[bk] : 22;
  const styles = isFullscreenPlayer ? fullPlayerStyles : miniPlayerStyles;

  const isLoadingOrNoMetaData = !hasMetaData || isLoading;

  return (
    <button
      css={styles.primaryActionBtn}
      onClick={action.onClick}
      aria-label={t(...action.accessibilityLabel)}
      disabled={action.name === 'play' && !canPlay}
    >
      <div
        css={[
          isQueueHead ? styles.primaryActionBtnInnerQueue : styles.primaryActionBtnInner,
          isLoadingOrNoMetaData && styles.primaryActionButtonInnerLoading,
        ]}
      >
        {hasMetaData && !isLoading && (
          <Icon ariaHidden name={action.icon} sizeInPx={primaryIconSize} />
        )}
        {isLoading && <PlayerControlsSpinner />}
      </div>
    </button>
  );
};
