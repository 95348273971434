import styles from './styles';

interface ISkeletonTextProps {
  length?: number;
  fontSize?: number;
}

const SkeletonText = ({ length = 20, fontSize = 8 }: ISkeletonTextProps): JSX.Element => {
  return (
    <span css={styles.container} style={{ width: `${length}ch`, fontSize: `${fontSize}px` }} />
  );
};

export default SkeletonText;
