import isBoolean from 'lodash/isBoolean';
import isString from 'lodash/isString';

import { isContentTypeAd } from '../streamers/utils';
import { ONE_MINUTE, VOD_FEATURE_FLAG, AD, ADVERTISEMENT, CONTENT } from './constants';
import { IPlayerMetadata, IPlayerOptions, ISuperHifiCurrent } from './types';

const METADATA_MAP = {
  albumArtUrl: 'image',
  artist: 'artist',
  canPause: 'canPause',
  companionAdIframeUrl: 'companionAdIframeUrl',
  duration: 'duration',
  id: 'trackId',
  image: 'image',
  isLive: 'isLive',
  is_live: 'isLive', // legacy marconi tag?
  isSkippable: 'isSkippable',
  playId: 'playId',
  play_id: 'playId', // legacy marconi tag?
  playlistTrackStartTime: 'trackStart',
  playlist_track_start_time: 'trackStart', // legacy marconi tag?
  playlistTailStartTime: 'playlistTailStartTime',
  playlistNextStartTime: 'playlistNextStartTime',
  liveTime: 'liveTime',
  segmentStartTime: 'segmentStartTime',
  skips: 'skips',
  stationId: 'stationId',
  title: 'songOrShow',
  type: 'contentType',
  datumStartTime: 'datumTime',
  datum_start_time: 'datumTime', // legacy marconi tag?
  isRewindable: 'isRewindable',
  // lsdr prefix is used for Amperwave live metadata text tracks
  'lsdr/X-SEGMENT-START-TIME': 'segmentStartTime',
  'lsdr/X-IMAGE': 'image',
  'lsdr/X-PLAYLIST-TRACK-START-TIME': 'trackStart',
  'lsdr/X-SESSION-PLAY-ID': 'playId',
  'lsdr/X-SESSION-SKIPS': 'skips',
  'lsdr/X-SONG-ALBUM-ART-URL': 'image',
  'lsdr/X-SONG-ARTIST': 'artist',
  'lsdr/X-SONG-CAN-PAUSE': 'canPause',
  'lsdr/X-SONG-DURATION': 'duration',
  'lsdr/X-SONG-ID': 'trackId',
  'lsdr/X-SONG-IS-SKIPPABLE': 'isSkippable',
  'lsdr/X-SONG-STATION-ID': 'stationId',
  'lsdr/X-SONG-TITLE': 'songOrShow',
  'lsdr/X-SONG-TYPE': 'contentType',
  'lsdr/X-DATUM-START-TIME': 'datumTime',
  'lsdr/X-IS-LIVE': 'isLive',
  'lsdr/X-IS-REWINDABLE': 'isRewindable',
  'lsdr/X-PLAYLIST-TAIL-START-TIME': 'playlistTailStartTime',
  'lsdr/X-PLAYLIST-NEXT-START-TIME': 'playlistNextStartTime',
  'lsdr/X-LIVE-TIME': 'liveTime',
  'X-SEGMENT-START-TIME': 'segmentStartTime',
  'X-IMAGE': 'image',
  'X-PLAYLIST-TRACK-START-TIME': 'trackStart',
  'X-SESSION-PLAY-ID': 'playId',
  'X-SESSION-SKIPS': 'skips',
  'X-SONG-ALBUM-ART-URL': 'image',
  'X-SONG-ARTIST': 'artist',
  'X-SONG-CAN-PAUSE': 'canPause',
  'X-SONG-DURATION': 'duration',
  'X-SONG-ID': 'trackId',
  'X-SONG-IS-SKIPPABLE': 'isSkippable',
  'X-SONG-STATION-ID': 'stationId',
  'X-SONG-TITLE': 'songOrShow',
  'X-SONG-TYPE': 'contentType',
  'X-DATUM-START-TIME': 'datumTime',
  'X-IS-LIVE': 'isLive',
  'X-IS-REWINDABLE': 'isRewindable',
  'X-PLAYLIST-TAIL-START-TIME': 'playlistTailStartTime',
  'X-PLAYLIST-NEXT-START-TIME': 'playlistNextStartTime',
  'X-LIVE-TIME': 'liveTime',
  //native hls player for super hifi
  TIT2: 'songOrShow',
  TPE1: 'artist',
  WXXX: 'image',
  TXXX: 'shfId',
  'id3/TIT2': 'songOrShow',
  'id3/TPE1': 'artist',
  'id3/WXXX': 'image',
  'id3/TXXX': 'shfId',
  shfId: 'shfId',
  // The properties below are the remaining SHF metadata keys [CCS-2753]
  // Only unmapped SHF keys are 'album' and 'isrc' because neither is used by client
  available_skips: 'skips',
  duration_ms: 'duration',
  continuity: 'continuity',
  station: 'stationId',
  // We may need to revisit this mapping when updating time logic [CCS-2753]
  start_ms: 'datumTime',
} as const;

export type TNativeMetadata = typeof METADATA_MAP;
export type TNativeMetadataKey = keyof TNativeMetadata;

export type TParseMetadata = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};
export type IPlayerMetadataKey = keyof IPlayerMetadata;
export type TSuperHifiMetadataKey = keyof ISuperHifiCurrent;

// SHF sends track metadata inside the current object within #EXTINF
// This maps the key/value pairs in Super-Hifi's current object to our metadata convention
export const parseSuperHifi = (current: ISuperHifiCurrent): IPlayerMetadata => {
  const retval: IPlayerMetadata = {};
  const retvalProxy: { [key in keyof IPlayerMetadata]: unknown } = retval;

  for (const key in current) {
    const mappedKey = METADATA_MAP[key as TNativeMetadataKey];
    if (mappedKey) {
      retvalProxy[mappedKey] = current[key as TSuperHifiMetadataKey];
    }
  }

  // Converts Super Hi-Fi's milliseconds (string) into seconds (float)
  if (retval.datumTime) {
    if (isString(retval.datumTime)) {
      retval.datumTime = parseFloat(retval.datumTime);
    }
    retval.datumTime /= 1000;
  }

  if (retval.duration) {
    if (isString(retval.duration)) {
      retval.duration = parseFloat(retval.duration);
    }
    retval.duration /= 1000;
  }

  if (retval.stationId && retval.contentType === CONTENT) {
    retval.shfId = retval.stationId;
  } else if (
    (retval.trackId && retval.contentType === AD) ||
    retval.contentType === ADVERTISEMENT
  ) {
    retval.shfId = retval.trackId;
  }

  return retval;
};

export const parseMetadata = (meta: TParseMetadata): IPlayerMetadata => {
  const retval: IPlayerMetadata = {};
  const retvalProxy: { [key in keyof IPlayerMetadata]: unknown } = retval;

  for (const key in meta) {
    const mappedKey = METADATA_MAP[key as TNativeMetadataKey];
    if (mappedKey) {
      retvalProxy[mappedKey] = meta[key as TNativeMetadataKey];
    } else if (key === 'current') {
      const superHifiCurrent = meta['current'] as ISuperHifiCurrent;
      const parsedSuperHifi = parseSuperHifi(superHifiCurrent);
      for (const prop in parsedSuperHifi) {
        retvalProxy[prop as IPlayerMetadataKey] = parsedSuperHifi[prop as IPlayerMetadataKey];
      }
    }
  }

  // datumTime seems to be the stream clock sent at the beginning of each 10-second HLS segment
  if (isString(retval.datumTime)) {
    retval.datumTime = parseFloat(retval.datumTime);
  }

  if (isString(retval.isLive)) {
    retval.isLive = retval.isLive === 'TRUE';
  }

  if (isString(retval.isRewindable)) {
    retval.isRewindable = retval.isRewindable === 'TRUE';
  }

  if (isString(retval.duration)) {
    retval.duration = parseFloat(retval.duration);
  }

  if (isString(retval.skips)) {
    retval.skips = parseInt(retval.skips);
  }

  if (isString(retval.trackStart)) {
    retval.trackStart = parseFloat(retval.trackStart);
  }

  if (isString(retval.playlistTailStartTime)) {
    retval.playlistTailStartTime = parseFloat(retval.playlistTailStartTime);
  }

  if (isString(retval.playlistNextStartTime)) {
    retval.playlistNextStartTime = parseFloat(retval.playlistNextStartTime);
  }

  if (isString(retval.liveTime)) {
    retval.liveTime = parseFloat(retval.liveTime);
  }

  return retval;
};

export const parseBoolean = (input?: string | boolean): boolean | undefined => {
  if (input) {
    if (isBoolean(input)) {
      return input;
    } else if (isString(input)) {
      return input?.toLowerCase() === 'true';
    }
  }
};

export const overrideContentType = (
  currentMetadata: TParseMetadata,
  incomingMetadata: TParseMetadata,
): boolean => {
  const currentContentTypeIsAd = isContentTypeAd(currentMetadata['X-SONG-TYPE']);
  const hasContentType = !!incomingMetadata['X-SONG-TYPE'];
  const isContentTrackId = incomingMetadata['X-SONG-ID'].includes('tra.');

  return currentContentTypeIsAd && !hasContentType && isContentTrackId;
};

// NOTE: CCS-1104 (VOD speed controls): Remove after full feature flag rollout
export const isVodFeatureEnabled = (options: IPlayerOptions) => {
  return options?.featureFlags?.[VOD_FEATURE_FLAG] ?? false;
};

export const isCloseToLive = (metadata: IPlayerMetadata, multiplier = 1) => {
  const { liveTime, trackStart } = metadata;
  const timeToLive = liveTime && trackStart ? Math.floor(liveTime - trackStart) : 0;
  const maxTime = ONE_MINUTE * multiplier;
  const closeToLive = timeToLive <= maxTime;

  return closeToLive;
};
