import { useTranslation } from 'react-i18next';

import { TStyles } from '~/types/emotion-styles';

import styles from './styles';

interface IQueueExpiredMessageProps {
  hideExpiredMessage: () => void;
  isPlayerQueue?: boolean;
  textCss?: TStyles;
}

const QueueExpiredMessage = ({
  hideExpiredMessage,
  isPlayerQueue,
  textCss,
}: IQueueExpiredMessageProps): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <>
      <p css={[styles.expired, isPlayerQueue && styles.expiredPlayer, textCss]}>
        {t('queueOverlay.expired.text')}
      </p>
      <button css={styles.expiredButton} onClick={hideExpiredMessage}>
        {t('queueOverlay.expired.button')}
      </button>
    </>
  );
};

export default QueueExpiredMessage;
