export const isBrowser = (): boolean => typeof window !== 'undefined';

export const hasNativeLoadingSupport = (): boolean => {
  return isBrowser() && !!window.HTMLImageElement && 'loading' in window.HTMLImageElement.prototype;
};

export const canGoBack = (): boolean => window.history?.state?.idx > 0;

export const isMobileIOSDevice = (): boolean => {
  return Boolean(navigator?.userAgent?.match(/iPhone/i) || navigator.userAgent.match(/iPod/i));
};
