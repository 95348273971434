import { useMemo } from 'react';

/**
 * Given an amount of seconds, formats it into [minutes]:[seconds] or [hours]:[minutes]:[seconds]
 *
 * @argument {number} secondsValue - The number of seconds to format.
 */
export const formatSecondsToTime = (secondsValue: number): string => {
  const fixedSecondsValue = Math.abs(secondsValue);

  const hours = Math.floor(fixedSecondsValue / 3600);

  const remainingMinutes = fixedSecondsValue / 60 - 60 * hours;

  const minutes = Math.floor(remainingMinutes);
  const seconds = Math.floor((remainingMinutes - minutes) * 60);

  const formattedSeconds = seconds.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(hours !== 0 ? 2 : 1, '0');

  const signal = secondsValue < 0 ? '-' : '';

  if (hours) {
    return `${signal}${hours}:${formattedMinutes}:${formattedSeconds}`;
  }

  return `${signal}${formattedMinutes}:${formattedSeconds}`;
};

/**
 * Mainly used on debug screens, it convert seconds to formatted time
 */
export const useFormattedSeconds = (seconds?: number, shouldRoundSeconds?: boolean): string => {
  const value = useMemo(() => {
    if (seconds === undefined) {
      return 'undefined';
    }

    const maybeRoundedSeconds = shouldRoundSeconds ? '~' + seconds.toFixed(2) : seconds;

    return `${maybeRoundedSeconds} s ( ${formatSecondsToTime(seconds)} )`;
  }, [seconds, shouldRoundSeconds]);

  return value;
};
