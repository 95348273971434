import { useState, forwardRef, type ForwardedRef } from 'react';

import { useTranslation } from 'react-i18next';
import { type NavigateFunction } from 'react-router';

import Button from '~/components/Button';
import IconButton from '~/components/Button/IconButton';
import { Icons } from '~/components/Icon/constants';
import Link from '~/components/Link';
import AudacyLogoButton from '~/components/Navigation/AudacyLogoButton';
import MenuButton from '~/components/Navigation/MenuButton';
import SearchBar from '~/components/SearchBar';
import { useBreakpoints } from '~/hooks/use-breakpoints';
import { LinkType } from '~/types/links';

import styles from './styles';

interface INavigationBarProps {
  isMenuOpen?: boolean;
  isLoggedIn?: boolean;
  shouldShowSearch?: boolean;
  onClickMenu: () => void;
  navigate: NavigateFunction;
  isHome?: boolean;
  onClose: () => void;
}

const NavigationBar = (
  {
    onClose,
    navigate,
    isHome,
    isMenuOpen,
    onClickMenu,
    isLoggedIn,
    shouldShowSearch,
  }: INavigationBarProps,
  ref: ForwardedRef<HTMLDivElement>,
): JSX.Element => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { is, greaterThan, lessThan } = useBreakpoints();
  const { t } = useTranslation();
  const isMenuOpenAndNotLarge = isMenuOpen && lessThan.LG;
  const isMenuOpenAndMedium = isMenuOpen && is.MD;
  const isMenuOpenAndSmall = isMenuOpen && is.SM;

  const isSearchOpenOrNotSmall = isSearchOpen || greaterThan.SM;
  const tabIndex = isMenuOpenAndNotLarge ? -1 : 0;

  const handleToggleSearch = (): void => {
    setIsSearchOpen((prev) => !prev);
  };

  const searchToggle = isSearchOpen ? (
    <Link
      as={LinkType.Button}
      ariaLabel={t('global.search.close')}
      onClick={handleToggleSearch}
      ariaHidden={isMenuOpenAndSmall}
      tabIndex={tabIndex}
      isTiny
    >
      Cancel
    </Link>
  ) : (
    <IconButton
      ariaHasPopup
      ariaHidden={isMenuOpenAndSmall}
      ariaLabel={t('global.search.open')}
      css={styles.searchButtonMobile}
      icon={Icons.Search}
      onClick={handleToggleSearch}
      tabIndex={tabIndex}
    />
  );

  return (
    <nav aria-label={t('navigation.ariaLabels.main')} css={styles.container} ref={ref}>
      <div css={[styles.navLeft, isMenuOpenAndSmall && styles.navLeftSmall]}>
        <MenuButton
          ariaHidden={isMenuOpenAndMedium}
          onClick={onClickMenu}
          isMenuOpen={isMenuOpen}
          tabIndex={isMenuOpenAndMedium ? -1 : 0}
        />
        <AudacyLogoButton
          isHome={isHome}
          navigate={navigate}
          onClose={onClose}
          containerCss={[
            styles.brandLink,
            isMenuOpenAndSmall && !isLoggedIn ? styles.brandLinkOpen : {},
          ]}
          logoCss={styles.logo}
        />
      </div>

      <div css={[styles.navRight, isSearchOpen && is.SM && styles.navRightSearchOpen]}>
        {isLoggedIn && (
          <Button
            buttonCss={styles.getAppButton}
            label={t('global.getTheApp')}
            onClick={() => console.info('get app')}
            theme="Secondary"
            size="Small"
            tabIndex={tabIndex}
          />
        )}
        {shouldShowSearch && (
          <>
            {isSearchOpenOrNotSmall && (
              <SearchBar tabIndex={tabIndex} onSubmitCallback={handleToggleSearch} />
            )}
            {is.SM && searchToggle}
          </>
        )}
      </div>
    </nav>
  );
};

export default forwardRef(NavigationBar);
