import { ModuleType } from '@audacy-clients/client-services/core';
import { type IView, type IModule } from '@audacy-clients/core/atoms/wrappers/modules';
import {
  type IEpisode,
  type IShow,
  type IStation,
} from '@audacy-clients/core/atoms/wrappers/types';
import { needsHTMLRendering } from '@audacy-clients/core/utils/strings';
import { type IViewContext } from '@audacy-clients/core/utils/viewContext';
import branch from 'branch-sdk';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import useToast from '~/state/toast';
import { env } from '~/utils/env';

const getLandingPageTitle = (modules: Array<IModule>): string | undefined => {
  const titleModule = modules.find((module) => module.type === ModuleType.VIEW_TITLE);

  return titleModule?.config?.titleFragments?.join(' ');
};

enum BranchEvents {
  PageView = 'pageview',
}

export type IBranchMetadata = {
  viewId: string;
  viewType: string;
  contentId?: string;
  entityType?: string;
  entitySubtype?: string;
  title: string;
  description?: string;
  image?: string;
  category?: string;
  genre?: string;
  market?: string;
};

type IUseBranchJourneyProps = {
  onOpen?: () => void;
  onClose?: () => void;
};

type IUseBranchJourney = {
  branchJourneyElement: JSX.Element;
  metadata?: IBranchMetadata;
  onPageLoad: (args?: { view?: IView; viewContext?: IViewContext }) => void;
};

export const useBranchJourney = ({
  onOpen,
  onClose,
}: IUseBranchJourneyProps): IUseBranchJourney => {
  // Initialize Branch SDK
  const [isInitialized, setIsInitialized] = useState(false);
  // Set page Branch Metadata
  const [pageMetadata, setPageMetadata] = useState<IBranchMetadata>();
  const { t } = useTranslation();
  const { showToast } = useToast();

  const handleSessionData = useCallback(
    ({ data_parsed }: branch.SessionData): void => {
      if ('action' in data_parsed) {
        const dataParsed = data_parsed as { action: string };

        switch (dataParsed.action) {
          case 'REAUTHENTICATE':
            showToast({ title: t('authState.resetPassword.success') });
            break;
        }
      }
    },
    [showToast, t],
  );

  useEffect(() => {
    const init = () => {
      const options = { no_journeys: true };
      branch.init(env.BRANCH_API_KEY, options, (_err, data) => {
        if (data) {
          handleSessionData(data);
        }
        setIsInitialized(true);
      });
    };

    if (!isInitialized && !!pageMetadata) {
      init();
    }

    return () => branch.closeJourney();
  }, [handleSessionData, isInitialized, pageMetadata]);

  // Close Branch Journey on page change
  const location = useLocation();
  useEffect(() => {
    if (isInitialized) {
      // close
      branch.closeJourney();
    }
  }, [isInitialized, location]);

  // Execute callbacks on Branch banner events
  useEffect(() => {
    branch.addListener(undefined, (event) => {
      if (event === 'didShowJourney') {
        onOpen && onOpen();
      } else if (event === 'didClickJourneyClose') {
        onClose && onClose();
      }
    });
  }, [onOpen, onClose]);

  const onPageLoad = ({ view, viewContext }: { view?: IView; viewContext?: IViewContext } = {}) => {
    if (viewContext) {
      const { viewId } = viewContext;

      let metadata: IBranchMetadata = {
        viewId,
        viewType: '',
        title: '',
      };

      if (view) {
        metadata = {
          ...metadata,
          viewType: view.type,
          title: getLandingPageTitle(view.modules) || '',
        };

        if (view.rootObject) {
          const rootObject = view.rootObject as IEpisode & IShow & IStation;
          metadata = {
            ...metadata,
            title: rootObject.title,
            contentId: rootObject.id,
            entityType: rootObject.type,
            entitySubtype: rootObject.subtype,
            description:
              rootObject.description && !needsHTMLRendering(rootObject.description)
                ? rootObject.description
                : undefined,
            image: rootObject.image,
            category: rootObject.category,
            genre: rootObject.genres ? rootObject.genres.join(',') : undefined,
            market: rootObject.marketTitle,
          };
        }
        setPageMetadata(metadata);
      }
      branch.setBranchViewData({
        data: metadata,
      });
    }

    branch.track(BranchEvents.PageView);
  };

  return {
    branchJourneyElement: <div className="branch-journeys-top" />,
    metadata: pageMetadata,
    onPageLoad,
  };
};
