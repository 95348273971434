/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { clientServicesConfig } from '@audacy-clients/client-services';
import {
  type CarouselType,
  type ModuleSubType,
  ModuleType,
  type ViewType,
} from '@audacy-clients/client-services/core';
import {
  type FeaturedPlayable,
  type Module,
  type Status,
  type View,
} from '@audacy-clients/client-services/src/dataServices/DataServices';
import type Episode from '@audacy-clients/client-services/src/dataServices/Episode';
import type Show from '@audacy-clients/client-services/src/dataServices/Show';
import type Station from '@audacy-clients/client-services/src/dataServices/Station';
import isString from 'lodash/isString';
import pick from 'lodash/pick';
import { nanoid } from 'nanoid/non-secure';
import { type StyleProp, type ViewStyle } from 'react-native';

import { wrapContentObject, wrapContentSummary } from './content';
import { type IContentSummary, type TWrappedContentObject } from './types';

export enum ViewTitleType {
  Large = 'LARGE',
  Small = 'SMALL',
}

export enum EntityImageShape {
  Circular = 'CIRCULAR',
  Grid = 'GRID',
  GridImage = 'GRID_IMAGE',
  Small = 'SMALL',
  Square = 'SQUARE',
}

export enum ModuleSize {
  Large = 'LARGE',
  Small = 'SMALL',
  Default = 'DEFAULT',
}

const filterEmptyCardModules = (m: IModule, view: View): boolean => {
  switch (m.type) {
    // NOTICE: Entity cards should only appear if the content powering them is provided
    case ModuleType.ENTITY_CARD_HORIZONTAL:
    case ModuleType.ENTITY_CARD_VERTICAL:
    case ModuleType.ENTITY_CARD_FEATURED:
    case ModuleType.ENTITY_CARD_COLLECTION: {
      return Boolean(getViewContent(m.config?.contentId, view) || m.config.url);
    }

    // NOTICE: Containers should only appear if they have at least one valid content child
    case ModuleType.CAROUSEL:
    case ModuleType.CAROUSEL_PERSONALIZED:
    case ModuleType.CONTENT_ROW:
    case ModuleType.SECTION_WRAPPER:
    case ModuleType.SAFE_SECTION_WRAPPER:
      return Boolean(m.modules?.filter((m2) => filterEmptyCardModules(m2, view))?.length);
    default: {
      let isDefinedModule = false;
      for (const [_, value] of Object.entries(ModuleType)) {
        if (m.type === value) {
          isDefinedModule = true;
        }
      }
      if (!isDefinedModule) {
        clientServicesConfig.logger.warn(
          'Unknown module of type ' + m.type + ' encountered on view ' + view.id,
        );
      }
      return isDefinedModule;
    }
  }
};

export type TShadowResolver = (id: string | undefined) => IContentSummary | undefined;

export type IFeaturedPlayable = {
  contentObj: TWrappedContentObject;
  ctaTitle?: string;
};

type IUniquelyIdentifiable = {
  uniqueId: string;
};

export type IModuleViewComponent = {
  getContent: TShadowResolver;
  module?: IModule;
};

export type IView = {
  featuredPlayable?: IFeaturedPlayable;
  getContent: TShadowResolver;
  id: string;
  meta?: IMeta;
  modules: Array<IModule>;
  rootObject?: TWrappedContentObject;
  status?: Status;
  type: ViewType;
} & IUniquelyIdentifiable;

const getViewContent = (id: string | undefined, view: View): IContentSummary | undefined => {
  if (!id) {
    return undefined;
  }

  const summary = view.content?.[id];

  if (summary) {
    return wrapContentSummary(summary);
  }
};

const wrapFeaturedPlayable = (
  contentObj?: Episode | Show | Station,
  ctaTitle?: string,
): IFeaturedPlayable | undefined => {
  if (!contentObj) {
    return;
  }
  const wrapped = wrapContentObject(contentObj);
  if (wrapped) {
    return {
      contentObj: wrapped,
      ctaTitle,
    };
  }
};

export const wrapView = (view: View, featuredPlayable: FeaturedPlayable | undefined): IView => {
  const filteredModules =
    view?.modules?.map(wrapModule).filter((m) => filterEmptyCardModules(m, view)) || [];
  setModulesPositionAndDepth(filteredModules);
  return {
    ...pick(view, 'id', 'type'),
    featuredPlayable: wrapFeaturedPlayable(
      featuredPlayable?.contentObj,
      featuredPlayable?.ctaTitle,
    ),
    getContent: (id) => getViewContent(id, view),
    meta: view.meta,
    modules: filteredModules,
    rootObject: wrapContentObject(view?.contentObj),
    status: view?.status ?? {
      code: -1,
      message: 'Missing Status',
    },
    uniqueId: nanoid(),
  };
};

export type IViewModuleTitle = {
  contentId?: string;
  label?: string;
  url?: string;
  viewId?: string;
  viewType?: ViewType;
};

export type IModuleConfig = {
  actionId?: string;
  carouselType?: CarouselType;
  contentId?: string;
  entityImageShape?: EntityImageShape;
  entityType?: string;
  url?: string;
  eyebrow?: string;
  footer?: IViewModuleTitle;
  header?: IViewModuleTitle;
  headerCta?: IViewModuleTitle;
  hideEyebrow?: boolean;
  hideThumbnail?: boolean;
  highlight?: string;
  idList?: Array<IIdList>;
  image?: string;
  imageAlt?: string;
  images?: Array<string>;
  itemsPerColumn?: number;
  itemsPerRow?: number;
  label?: string;
  numberOfLines?: number;
  placeholderLabel?: string;
  selectable?: boolean;
  subtitle: string;
  thumbnail?: string;
  ctaText?: string;
  text?: string;
  title?: IViewModuleTitle | string;
  titleFragments?: Array<string>;
  type: ViewTitleType;
  viewId?: string;
  viewType?: ViewType;
  isExternalUrl?: boolean;
  author?: string;
  totalDurationInSeconds?: string;
  moduleSize?: ModuleSize;
  isStacked?: boolean;
  subType?: ModuleSubType;
};

export type IModule = {
  config: IModuleConfig;
  id: string;
  modules?: Array<IModule>;
  type: ModuleType;
  position?: string;
  depth?: string;
} & IUniquelyIdentifiable;

export type IMeta = {
  canonical: string;
  description: string;
  robots?: string | undefined;
  social: {
    description: string;
    image?: string | undefined;
    imageHeight?: string | undefined;
    imageWidth?: string | undefined;
    locale: string;
    siteName?: string | undefined;
    title: string;
    type?: string | undefined;
  };
  title: string;
};

export type IIdList = {
  id: string;
};

export const wrapModule = (module: Module): IModule => ({
  config: module.config || {},
  id: module.moduleId,
  modules: module.modules?.map(wrapModule),
  type: module.moduleType,
  uniqueId: module.moduleId,
});

const setModulesPositionAndDepth = (modules: Array<IModule>): void => {
  let carouselCount = 0;
  for (let i = 0; i < modules.length; i++) {
    const section = modules[i];
    if (section) {
      const subModules = section.modules;
      if (subModules) {
        for (let j = 0; j < subModules.length; j++) {
          const subModule = subModules[j];
          if (subModule.type === ModuleType.CAROUSEL) {
            carouselCount++;
            setCarouselPositionAndDepth(section, subModule, carouselCount.toString());
          }
        }
      }
    }
  }
};

const setCarouselPositionAndDepth = (section: IModule, carousel: IModule, position: string) => {
  if (section.position === undefined) {
    setPositionAndDepth(section, position, '1');
  }
  setPositionAndDepth(carousel, position, '1');
  setCarouselItemsPositionAndDepth(carousel);
};

const setCarouselItemsPositionAndDepth = (carousel: IModule) => {
  const items = carousel.modules;
  if (items) {
    const itemsPerColumn = getItemsPerColumn(carousel);
    for (let i = 0; i < items.length; i++) {
      const depth = Math.trunc(i / itemsPerColumn) + 1;
      setPositionAndDepth(items[i], carousel.position, depth.toString());
    }
  }
};

const getItemsPerColumn = (carousel: IModule): number =>
  carousel.config.itemsPerColumn && carousel.config.itemsPerColumn > 0
    ? carousel.config.itemsPerColumn
    : 1;

const setPositionAndDepth = (module: IModule, position?: string, depth?: string) => {
  module.position = position;
  module.depth = depth;
};
