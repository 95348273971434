export type TLoading = 'lazy' | 'eager';

export const LoadingOptions: Record<string, TLoading> = {
  Eager: 'eager',
  Lazy: 'lazy',
};

export enum LazyLoadingMethod {
  Checking = 'Checking',
  IO = 'IO',
  Native = 'Native',
  None = 'None',
}
