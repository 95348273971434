import { type IQueue } from '@audacy-clients/client-services/src/personalizationServices/types';
import { CacheTimes } from '@audacy-clients/core/atoms/helpers/constants';
import {
  type IQueueState,
  queueHadExpiredItemsState,
  queueIsLoadingState,
  queueLastUpdateState,
  queueState,
} from '@audacy-clients/core/atoms/queue';
import { wrapQueueItem } from '@audacy-clients/core/atoms/wrappers/queue';
import { useClientServices } from '@audacy-clients/core/utils/clientServices';
import { type TGetViewContext } from '@audacy-clients/core/utils/viewContext';
import { useCallback } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { useQueueDataEvents } from './useQueueDataEvents';

export interface TQueueCallbacks {
  beforeAnyAction?: () => void;
  onError?: (retryCallback: () => void) => void;
  onSuccess?: (undoCallback: () => void) => void;
  onUndo?: (ids: Array<string>) => void;
  onUndoError?: () => void;
}

type TAddToQueue = (id: string, callbacks?: TQueueCallbacks) => Promise<void>;
type TQueueContains = (id: string) => boolean;
type TRemoveFromQueue = (ids: Array<string>, callbacks?: TQueueCallbacks) => Promise<void>;
type TRequestQueue = () => void;
type TSetQueue = (queueState: IQueueState) => Promise<void>;

export interface IUseQueue {
  add: TAddToQueue;
  contains: TQueueContains;
  remove: TRemoveFromQueue;
  request: TRequestQueue;
  set: TSetQueue;
}

export const useQueue = (getViewContext?: TGetViewContext): IUseQueue => {
  const { clientServices } = useClientServices();
  const [lastUpdate, setLastUpdate] = useRecoilState(queueLastUpdateState);
  const setHadExpiredItems = useSetRecoilState(queueHadExpiredItemsState);
  const setIsLoading = useSetRecoilState(queueIsLoadingState);
  const [{ currentId, items }, setQueueState] = useRecoilState(queueState);
  const queueDataEvents = useQueueDataEvents(getViewContext);

  const request = useCallback((): void => {
    const now = Date.now();

    if (now - lastUpdate > CacheTimes.limit60s) {
      setIsLoading(true);
      clientServices
        .getPersonalizationServices()
        .getQueue()
        .then((queue: IQueue | Record<string, never>): void => {
          setLastUpdate(Date.now());
          setQueueState({
            currentId: queue.currentId ?? '',
            items: queue.items?.map((item) => wrapQueueItem(item)) || [],
          });
          setHadExpiredItems(!!queue.itemsRemoved);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [clientServices, lastUpdate, setHadExpiredItems, setIsLoading, setLastUpdate, setQueueState]);

  const contains = useCallback(
    (id: string): boolean => {
      return items.some((item) => item.id === id);
    },
    [items],
  );

  const set = useCallback(
    async (queue: IQueueState): Promise<void> => {
      // TODO: [A2-950] https://entercomdigitalservices.atlassian.net/browse/A2-950
      // Add the correct error handling if the set call returns an error,
      // and the queue we sent is not the valid one anymore.

      // We force the current queue to be the expected queue, and it
      // will also refresh after the event emitter kicks in.
      setQueueState(queue);

      const removedIds = items.reduce<Array<string>>((acc, item) => {
        const wasRemoved = !queue.items.some((newQueueItem) => newQueueItem.id === item.id);
        wasRemoved && acc.push(item.id);
        return acc;
      }, []);

      !!removedIds.length && queueDataEvents.onRemove(removedIds);

      // Updates the queue on the server and let the event listener flow happen
      await clientServices.getPersonalizationServices().setQueue({
        currentId: queue.currentId,
        items: queue.items.map((item) => item.getDataObject()),
      });
    },
    [clientServices, items, queueDataEvents, setQueueState],
  );

  const remove = useCallback(
    async (ids: Array<string>, callbacks: TQueueCallbacks = {}): Promise<void> => {
      const { onError, onSuccess } = callbacks;

      queueDataEvents.onRemove(ids);
      clientServices
        .getPersonalizationServices()
        .removeFromQueue(ids)
        .then(() => {
          onSuccess?.(async () => {
            await set({ currentId, items });
          });
        })
        .catch(() => {
          onError?.(() => remove(ids));
        });
    },
    [clientServices, currentId, items, queueDataEvents, set],
  );

  const add = useCallback(
    async (id: string, callbacks: TQueueCallbacks = {}): Promise<void> => {
      const { onSuccess, onError } = callbacks;

      queueDataEvents.onAdd(id);
      clientServices
        .getPersonalizationServices()
        .addToQueue(id)
        .then(() => {
          onSuccess?.(async () => {
            await remove([id]);
          });
        })
        .catch(() => {
          onError?.(() => add(id));
        });
    },
    [clientServices, queueDataEvents, remove],
  );

  return {
    add,
    contains,
    remove,
    request,
    set,
  };
};
