import { BP } from '~/styles';

export interface IGridProps {
  columnCount: number;
  gap: number;
}

export const Grid: Record<string, IGridProps> = {
  [BP.Small]: {
    columnCount: 5,
    gap: 10,
  },
  [BP.Medium]: {
    columnCount: 8,
    gap: 20,
  },
  [BP.Large]: {
    columnCount: 12,
    gap: 20,
  },
  [BP.ExtraLarge]: {
    columnCount: 12,
    gap: 20,
  },
};
