import { useTranslation } from 'react-i18next';

import IconButton from '~/components/Button/IconButton';
import { Icons } from '~/components/Icon/constants';
import styles from '~/components/Player/FullscreenPlayer/styles';
import MiniPlayer from '~/components/Player/MiniPlayer';

type TPlayerQueueHeaderProps = {
  onBackButtonClick: () => void;
};

const PlayerQueueHeader = ({ onBackButtonClick }: TPlayerQueueHeaderProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div css={styles.queuePlayer}>
      <div css={styles.queuePlayerInner}>
        <IconButton
          css={styles.queueBackBtn}
          icon={Icons.Back}
          onClick={onBackButtonClick}
          ariaLabel={t('playerControls.accessibilityLabels.closeQueue')}
        />
        <MiniPlayer containerCss={styles.miniPlayer} />
      </div>
    </div>
  );
};

export default PlayerQueueHeader;
