import { Borders } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: Borders.separator,
  },
  episodeLink: {
    marginTop: 20,
    marginBottom: 20,
  },
  sectionWrapper: {
    marginTop: 40,
  },
};

export default styles;
