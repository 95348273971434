import {
  EpisodeSubType,
  StandaloneChapterSubType,
  StationSubType,
} from '@audacy-clients/client-services/core';
import { INowPlayingState, isAdState } from '@audacy-clients/core/atoms/player';
import { formatFromTo, formatPublishDate } from '@audacy-clients/core/utils/date';
import { secondsToMinutes } from 'date-fns';
import i18n from 'i18next';
import { isUndefined } from 'lodash';
import { useRecoilValue } from 'recoil';

type TPlayerMetadataProps = INowPlayingState & {
  artist?: string;
  category?: string;
  contentType?: string;
  endTime?: number;
  isBroadcastShow: boolean;
  isLive?: boolean;
  publishDate?: Date;
  startTime?: number;
  station?: string;
  title?: string;
};

type TPlayerMetadata = {
  miniPlayerSubtitle: string;
  miniPlayerTitle: string;
  playerSubtitle: string;
  playerTitle: string;
};

const getPlayerMetadataConfig = (playerItem?: TPlayerMetadataProps) => {
  const common = {
    title: playerItem?.title,
    station: playerItem?.station,
    artist: playerItem?.artist,
    startTime: playerItem?.startTime,
    endTime: playerItem?.endTime,
    category: playerItem?.dataObject?.category,
    contentType: playerItem?.dataObject?.subtype,
    publishDate: playerItem?.dataObject?.publishDate,
    isLive: playerItem?.isLive,
    isBroadcastShow: false,
  };

  switch (playerItem?.dataObject?.subtype) {
    case StationSubType.BROADCAST: {
      return {
        ...common,
        publishDate: playerItem?.episodeDataObject?.publishDate,
        isBroadcastShow:
          playerItem?.episodeDataObject?.subtype === EpisodeSubType.BROADCAST_SHOW_EPISODE,
      };
    }
    case StandaloneChapterSubType.BROADCAST_EPISODE_CHAPTER: {
      const duration = playerItem?.duration ? ` · ${secondsToMinutes(playerItem?.duration)}m` : '';
      return {
        ...common,
        title: `${common.title} ${duration}`,
      };
    }

    default: {
      return common;
    }
  }
};

const usePlayerMetadata = (props?: TPlayerMetadataProps): TPlayerMetadata => {
  const {
    artist = '',
    category = '',
    contentType,
    endTime,
    isBroadcastShow = false,
    publishDate,
    startTime,
    station = '',
    title = '',
  } = getPlayerMetadataConfig(props);

  const isAd = useRecoilValue(isAdState);

  const isValidArtist = !!artist && artist !== title;

  let playerTitle = title;
  let playerSubtitle = station;
  let miniPlayerTitle = title;
  let miniPlayerSubtitle = isValidArtist ? `${artist} • ${station}` : station;

  const formattedPublishDate = publishDate ? ` • ${formatPublishDate(publishDate)}` : '';

  switch (contentType) {
    case StationSubType.EXCLUSIVE: {
      playerTitle += isValidArtist ? ` • ${artist}` : '';
      break;
    }
    case StationSubType.PODCAST: {
      playerSubtitle = artist + formattedPublishDate;
      miniPlayerSubtitle = artist;
      break;
    }
    case StationSubType.BROADCAST: {
      if (isBroadcastShow && !isUndefined(startTime) && !isUndefined(endTime)) {
        playerSubtitle += ` •  ${formatFromTo(startTime, endTime)}`;
      }
      if (category === 'Music') {
        playerTitle += isValidArtist ? ` • ${artist}` : '';
      }
      break;
    }
    case StandaloneChapterSubType.BROADCAST_EPISODE_CHAPTER: {
      playerSubtitle = station + formattedPublishDate;
      miniPlayerSubtitle = station;
      break;
    }
  }

  if (isAd) {
    miniPlayerTitle = i18n.t('global.ad');
    playerSubtitle = `${station} • ${i18n.t('global.ad')}`;
  }

  return {
    miniPlayerSubtitle,
    miniPlayerTitle,
    playerSubtitle,
    playerTitle,
  };
};

export default usePlayerMetadata;
