import { Colors } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    display: 'block',
    height: '1em',
    marginBottom: '1em',
    backgroundColor: Colors.white30,

    '&:last-child': {
      marginBottom: 0,
    },
  },
};

export default styles;
