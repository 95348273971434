import { ReactNode } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import Item, { IItem } from '~/components/SortableList/Item';

export interface ISortableItemProps<T> {
  children: ReactNode;
  item: T;
  listId: string;
  isReordering?: boolean;
  isEditMode?: boolean;
  isSortable?: boolean;
}

const SortableListItem = <T extends IItem>(props: ISortableItemProps<T>): JSX.Element => {
  const { listeners, setNodeRef, transform, transition } = useSortable({
    id: props.item.id,
    data: {
      title: props.item.title,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return <Item ref={setNodeRef} {...props} listeners={listeners} style={style} />;
};

export default SortableListItem;
