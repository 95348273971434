import { collectionQuery } from '@audacy-clients/core/atoms/collections';
import { useModuleContext } from '@audacy-clients/core/components/ModuleRenderer/context';
import { useRecoilValue } from 'recoil';

import styles from '~/components/ModuleRenderer/modules/ModuleSongHistory/styles';
import Playlist from '~/components/Playlist';
import { Mixins } from '~/styles';

const ModulePlaylistItemList: React.FC = () => {
  const { viewRootObjectId: contentId = '' } = useModuleContext();
  const { durationSeconds, itemsIdList } = useRecoilValue(collectionQuery(contentId)) ?? {};

  if (!durationSeconds || !itemsIdList?.length) return null;

  return (
    <section css={[Mixins.horizontalModulePadding(), styles.container]}>
      <Playlist durationSeconds={durationSeconds} itemIds={itemsIdList} playlistId={contentId} />
    </section>
  );
};

export default ModulePlaylistItemList;
