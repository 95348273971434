export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Facebook logo</title>
    <path
      d="M28 16.073C28 9.405 22.627 4 16 4S4 9.405 4 16.073C4 22.1 8.388 27.094 14.125 28v-8.437h-3.047v-3.49h3.047v-2.66c0-3.026 1.792-4.697 4.533-4.697 1.312 0 2.686.236 2.686.236v2.971H19.83c-1.491 0-1.956.93-1.956 1.887v2.263h3.328l-.532 3.49h-2.796V28C23.612 27.094 28 22.1 28 16.073Z"
      fill="currentColor"
    />
  </svg>
);
