export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Copy link</title>
    <path
      d="m18.298 23.573-2.145 2.145a6.672 6.672 0 0 1-9.435-9.435l2.144-2.145M23.444 18.427l2.144-2.144a6.672 6.672 0 0 0-9.435-9.436l-2.145 2.145M11.864 20.571l8.577-8.577"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
