import { isConsideredPlayed } from '@audacy-clients/client-services/src/utils/playbackResumePoints';
import { Duration } from 'date-fns';
import { t } from 'i18next';

export enum DurationSummaryStates {
  NotPlayed = 'NotPlayed',
  PartiallyPlayed = 'PartiallyPlayed',
  Played = 'Played',
}

interface IGetDurationSummaryStateData {
  duration?: number;
  isPlayed?: boolean;
  playbackResumePoint?: number;
}

export const getDurationSummaryState = ({
  isPlayed,
  duration,
  playbackResumePoint,
}: IGetDurationSummaryStateData): DurationSummaryStates => {
  if (isPlayed || isConsideredPlayed(duration, playbackResumePoint)) {
    return DurationSummaryStates.Played;
  }

  if (!!duration && !isPlayed && !!playbackResumePoint && playbackResumePoint > 0) {
    return DurationSummaryStates.PartiallyPlayed;
  }

  return DurationSummaryStates.NotPlayed;
};

export const getAccessibilityLabel = (duration: Duration): string => {
  const hours = duration.hours
    ? t('durationSummary.accessibility.hour', {
        count: duration.hours,
      })
    : '';

  const minutes = duration.minutes
    ? t('durationSummary.accessibility.minute', {
        count: duration.minutes,
      })
    : '';

  return `${hours} ${minutes}`.trim();
};

export const getPlayingAccessibilityLabelAndText = (
  formattedDurationLeft: {
    duration: Duration;
    label?: string;
  },
  isPlaying?: boolean,
): {
  accessibilityLabel?: string;
  text?: string;
} => {
  const durationLeftLabel = getAccessibilityLabel(formattedDurationLeft.duration);

  const isUnderMin =
    formattedDurationLeft?.duration?.hours !== undefined &&
    formattedDurationLeft?.duration?.hours < 1 &&
    formattedDurationLeft?.duration?.minutes !== undefined &&
    formattedDurationLeft?.duration?.minutes < 1;

  return {
    accessibilityLabel: t('durationSummary.accessibility.progress', {
      time: isUnderMin ? t('durationSummary.accessibility.underMin') : durationLeftLabel,
    }),
    text: isPlaying
      ? t('durationSummary.playing', {
          time: isUnderMin ? t('durationSummary.underMin') : `${formattedDurationLeft?.label}`,
        })
      : t('durationSummary.duration', {
          time: isUnderMin ? t('durationSummary.underMin') : `${formattedDurationLeft?.label}`,
        }),
  };
};
