import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    borderRadius: 4,
    height: 250,
    overflow: 'hidden',
    width: 300,
    pointerEvents: 'auto',
  },
  iframe: {
    height: '100%',
    width: '100%',
  },
};

export default styles;
