import { type Property } from 'csstype';
import { forwardRef } from 'react';

import { toLowercaseFirstCharacter } from 'utils/strings';
import Icon from '~/components/Icon';
import { Icons } from '~/components/Icon/constants';
import useLinkOrButton from '~/hooks/use-link-or-button';
import { TStyles } from '~/types/emotion-styles';
import {
  IGlobalAsAnchorProps,
  IGlobalAsButtonProps,
  LinkType,
  TButtonOrAnchorRef,
} from '~/types/links';

import styles, { buttonSizeStyles } from './styles';

export enum IconButtonColorThemes {
  Dark = 'Dark',
  Light = 'Light',
}

export enum IconButtonThemes {
  Fill = 'Fill',
  HoverFill = 'HoverFill',
  Outline = 'Outline',
  Default = 'Default',
}

export interface IIconButtonProps {
  buttonCss?: TStyles | TStyles[];
  buttonSizeInPx?: number;
  colorTheme?: IconButtonColorThemes;
  icon: Icons;
  iconSizeInPx?: number;
  theme?: IconButtonThemes;
  color?: Property.Color;
}

export interface IIconButtonAsAnchorProps extends IGlobalAsAnchorProps, IIconButtonProps {}
export interface IIconButtonAsButtonProps extends IGlobalAsButtonProps, IIconButtonProps {}
export type TIconButtonProps = IIconButtonAsAnchorProps | IIconButtonAsButtonProps;

const IconButton = (
  {
    as = LinkType.Button,
    buttonCss,
    buttonSizeInPx = 20,
    colorTheme = IconButtonColorThemes.Dark,
    icon,
    iconSizeInPx = 20,
    theme = IconButtonThemes.Default,
    color,
    ...rest
  }: TIconButtonProps,
  ref: TButtonOrAnchorRef,
): JSX.Element => {
  const isButton = as === LinkType.Button;

  const buttonLinkStyles = [
    styles.baseButton,
    styles.iconButton,
    buttonSizeStyles(buttonSizeInPx),
    isButton && 'isDisabled' in rest && rest.isDisabled ? styles.disabled : {},
    styles[`${toLowercaseFirstCharacter(theme)}${colorTheme}Theme`],
    buttonCss,
  ];

  const children = <Icon name={icon} sizeInPx={iconSizeInPx} iconColor={color} ariaHidden />;

  return useLinkOrButton({
    children,
    styles: buttonLinkStyles as TStyles,
    props: { as, ...rest },
    ref,
  });
};

export default forwardRef(IconButton);
