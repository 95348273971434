export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Thumbs up</title>
    <path
      d="M3.75 14.955h4.5v10.968h-4.5V14.955ZM15.24 5.86l-5.745 9.095v10.968H25.77l1.915-13.362H19.07V6.817l-3.83-.958Z"
      fill="currentColor"
    />
  </svg>
);
