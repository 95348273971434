import { ClickFeature } from '@audacy-clients/client-services/core';
import {
  useChapters,
  useLiveChapterIndex,
  usePlayingChapter,
} from '@audacy-clients/core/atoms/chapters';
import { isAdState, isInPlayer, playerContentIsLiveState } from '@audacy-clients/core/atoms/player';
import { IChapter } from '@audacy-clients/core/atoms/wrappers/chapter';
import { IEpisode } from '@audacy-clients/core/atoms/wrappers/types';
import { useClientServices } from '@audacy-clients/core/utils/clientServices';
import { getRecoil } from '@audacy-clients/core/utils/recoil';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { useAudioHelpersWeb } from '~/hooks/use-audio-helpers-web';
import { useGetViewContext } from '~/state/dataEvents';
import useToast from '~/state/toast';
import { TStyles } from '~/types/emotion-styles';

import Chapters from './Chapters';

interface IChaptersWithState {
  episode: IEpisode;
  live?: boolean;
  hasSectionWrapper?: boolean;
  containerCss?: TStyles;
}

const ChaptersWithState = ({
  episode,
  live,
  hasSectionWrapper,
  containerCss,
}: IChaptersWithState): JSX.Element | null => {
  const { showToast } = useToast();
  const { t } = useTranslation();
  const chapters = useChapters({ episodeId: episode.id, live });
  const audioHelpers = useAudioHelpersWeb();
  const playingChapterIndex = usePlayingChapter(episode.id);
  const liveChapterIndex = useLiveChapterIndex(episode, chapters);
  const { clientServices, loading } = useClientServices();
  const getViewContext = useGetViewContext();

  const handleChapterClick = useRecoilCallback(
    ({ snapshot }) =>
      async (chapter: IChapter) => {
        const isAd = getRecoil(isAdState);
        const isLive = getRecoil(playerContentIsLiveState);
        if (isAd && !isLive) {
          showToast({ title: t('chapters.disabledDuringAd') });
          return;
        }
        const currentlySelected = await snapshot.getPromise(isInPlayer(episode.id));

        const playContext = {
          chapter: chapter.title,
          contentId: episode.id,
          feature: ClickFeature.CHAPTER,
        };

        episode.startTime;

        if (currentlySelected && !loading) {
          /*
           * Mobile app does the calculation this this way too.
           * Would be nice to switch to our time utilities in client services rather than using hardcoded 1000
           */
          const startTimeSeconds = (episode.startTime || 0) / 1000;
          audioHelpers.seekToTime(chapter.startOffset - startTimeSeconds);
          return;
        }

        audioHelpers.play(episode.getDataObject(), playContext, {
          autoPlay: true,
          startOffset: chapter.startOffset,
        });
      },
    [audioHelpers, clientServices, episode, getViewContext, t, loading, showToast],
  );

  if (!chapters || chapters.length === 0) return null;

  return (
    <Chapters
      episode={episode}
      chapters={chapters}
      hasSectionWrapper={hasSectionWrapper}
      playingChapterIndex={playingChapterIndex}
      liveChapterIndex={liveChapterIndex}
      onItemClick={handleChapterClick}
      containerCss={containerCss}
    />
  );
};

export default ChaptersWithState;
