import DataObject from './DataObject';
import { ObjectType } from '../Constants';

/**
 * @module Clip
 */
export default class Clip extends DataObject {
  /**
   * Creates a new instance of a Clip.
   * @param {Object}       data
   */
  constructor(data) {
    super(ObjectType.CLIP, data);
    this.data = data;
  }

  /**
   * Get the clip duration.
   * @returns {Number}
   */
  getDuration() {
    return this?.data?.duration || 0;
  }

  getAudioStream() {
    let retval;

    if (this.data.streamUrls) {
      if (this.data.streamUrls.m3u8) retval = this.data.streamUrls.m3u8;
      else if (this.data.streamUrls.aac) retval = this.data.streamUrls.aac;
      else if (this.data.streamUrls.mp3) retval = this.data.streamUrls.mp3;
    }

    return retval;
  }

  /**
   * Get the text description of the image associated with the entity.
   * @returns {String}
   */
  getImageAlt() {
    return this.data.images && this.data.images.alt;
  }

  /**
   * Get the square station image.
   * @returns {String}
   */
  getImageSquare() {
    return this.data.images && this.data.images.square;
  }
}
