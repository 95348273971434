export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Volume</title>
    <path
      d="m7 12 8-7v23l-8-7H2v-9h5ZM18 19c1.5 0 2.5-1.12 2.5-2.5S19.5 14 18 14"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path d="M18 23.5c3.5 0 7-2.5 7-7s-3.5-7-7-7" stroke="currentColor" strokeWidth="1.5" />
    <path
      d="M18 28c5.35 0 11.5-4.619 11.5-11.5S23.35 5 18 5"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);
