import { ReactQueryStorageKey } from '@audacy-clients/client-services';

function remoteNavigation() {
  return [ReactQueryStorageKey.REMOTE_NAVIGATION];
}

function remoteSubNavigation() {
  return [ReactQueryStorageKey.REMOTE_SUB_NAVIGATION];
}

export const queryKeys = {
  remoteNavigation,
  remoteSubNavigation,
};
