export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Bell</title>
    <path
      d="m18.97 6.57-.743.106.062.429.402.16.278-.696ZM24 18.6h-.75v.39l.32.224.43-.614Zm2 1.4h.75v-.39l-.32-.224L26 20Zm0 3v.75h.75V23H26ZM6 23h-.75v.75H6V23Zm0-3-.43-.614-.32.224V20H6Zm2-1.4.43.614.32-.223V18.6H8Zm5.03-12.03.28.696.401-.161.062-.43-.742-.106ZM24.75 14a8.753 8.753 0 0 0-5.502-8.127l-.557 1.393A7.253 7.253 0 0 1 23.25 14h1.5Zm0 4.6V14h-1.5v4.6h1.5Zm1.68.786-2-1.4-.86 1.228 2 1.4.86-1.228ZM26.75 23v-3h-1.5v3h1.5ZM6 23.75h20v-1.5H6v1.5ZM5.25 20v3h1.5v-3h-1.5Zm2.32-2.014-2 1.4.86 1.228 2-1.4-.86-1.228ZM7.25 14v4.6h1.5V14h-1.5Zm5.502-8.127A8.753 8.753 0 0 0 7.25 14h1.5a7.253 7.253 0 0 1 4.56-6.734l-.558-1.393Zm1.021.803A2.25 2.25 0 0 1 16 4.75v-1.5a3.75 3.75 0 0 0-3.712 3.212l1.485.214ZM16 4.75a2.25 2.25 0 0 1 2.227 1.926l1.485-.214A3.75 3.75 0 0 0 16 3.25v1.5ZM20 25c0 1.657-1.79 3-4 3s-4-1.343-4-3h8Z"
      fill="currentColor"
    />
  </svg>
);
