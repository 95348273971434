const SYNC_RETRY_INTERVAL_MS = 3000;
const LOCK_IFRAME_RELOAD_MS = 15000;
const RETRY_LIMIT = 3;

const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts: string[] = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
};

const setCookieExpirationMonth = (name: string, value: string, path = '/') => {
  const expiryDate = new Date();
  expiryDate.setMonth(expiryDate.getMonth() + 1);
  document.cookie = `${name}=${value}; expires=${expiryDate.toUTCString()}; path=${path}`;
};

const eraseCookie = (name: string) => {
  document.cookie = name + '=; Max-Age=0';
};

export const syncMagnite = (auid = '') => {
  if (getRubiconUserId()) {
    return;
  }

  const iframe = document.getElementById('multisync-iframe') as HTMLIFrameElement;
  const dataLoadTime = iframe?.getAttribute('data-load-time');
  if (dataLoadTime && parseInt(dataLoadTime) + LOCK_IFRAME_RELOAD_MS > Date.now()) {
    return;
  }

  iframe.onload = () => {
    let syncRequestRetries = 0;
    const resyncInterval = setInterval(() => {
      fetch(`https://sync.amperwave.net/api/get/magnite/auid=${auid}`)
        .then((res) => res.json())
        .then((data) => {
          const { partner_id: partnerId } = data;

          if (partnerId) {
            setCookieExpirationMonth('partner_id', partnerId);
            clearSync();
          } else {
            syncRequestRetries++;
            if (syncRequestRetries === RETRY_LIMIT) {
              clearSync();
            }
          }
        })
        .catch((err) => {
          console.error(err);
          clearSync();
        });
    }, SYNC_RETRY_INTERVAL_MS);

    const clearSync = () => {
      clearInterval(resyncInterval);
      iframe.removeAttribute('onload');
    };
  };

  iframe.src = `https://eus.rubiconproject.com/usync.html?p=7562&endpoint=us-west&auid=${auid}`;
  iframe.setAttribute('data-load-time', Date.now().toString());
};

export const getRubiconUserId = (): string => {
  return getCookie('partner_id') ?? '';
};

export const eraseRubiconUserId = () => {
  eraseCookie('partner_id');
};
