export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Reaction</title>
    <path
      d="M26.912 21A11.956 11.956 0 0 0 28 16c0-6.627-5.373-12-12-12S4 9.373 4 16s5.373 12 12 12c.985 0 1.943-.119 2.86-.343"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M21 17a5 5 0 0 1-10 0" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
    <path
      d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM20 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      fill="currentColor"
    />
    <path d="M24 22.5v5M21.5 25h5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" />
  </svg>
);
