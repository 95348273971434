import List from './List';
import { ObjectType } from '../Constants';

/** @typedef { import('./types').ISegment } ISegment */
export default class SegmentList extends List {
  /**
   * Creates a new instance of a List.
   * @param {String}        version
   * @param {Array}         data
   * @param {Object}        data[].attributes
   * @param {String}        data[].attributes.end_time
   * @param {String}        data[].attributes.segment_type
   * @param {String}        data[].attributes.start_time
   * @param {String}        data[].attributes.stream_url
   * @param {DataServices}  dataService
   * @param {Episode}      [episode=undefined]
   * @param {Station}      [station=undefined]
   */
  constructor(version, data, dataService, episode = undefined, station = undefined) {
    super(version, data, dataService, ObjectType.SEGMENT_LIST);

    let duration = 0;

    for (let i = 0; i < data.length; i++) {
      if (this.data[i].attributes.segment_type === 'content') {
        let segDuration = this.data[i].attributes.duration;

        if (segDuration === null) {
          segDuration = (episode.getEndTimeMillis() - this.getSegmentStartTime(i)) / 1000;
          this.data[i].attributes.duration = segDuration;
        }

        duration += this.data[i].attributes.duration;
      }
    }

    let fract = 0;

    for (let i = 0; i < data.length; i++) {
      if (this.data[i].attributes.segment_type === 'content') {
        this.data[i].fract = fract;
        this.data[i].fractLength = this.data[i].attributes.duration / duration;

        fract += this.data[i].fractLength;

        this.data[i].attributes.station_id = '101-' + this.data[i].attributes.station_id;
      } else {
        this.data[i].fract = fract;
        this.data[i].fractLength = 0;
        this.data[i].attributes.station_id = '101-' + this.data[i].attributes.station_id;
      }
    }

    this.episode = episode;
    this.station = station;
    this.duration = duration;
  }

  getAd(index) {
    return this.data[index].aasAd;
  }

  getAudioUrl(index) {
    return this.data[index]?.aasAd?.getStream() || this.data[index]?.attributes?.stream_url;
  }

  getContentDuration() {
    return this.duration;
  }

  getAdFracts() {
    const retval = [];

    for (let i = 0; i < this.data.length; i++)
      if (this.data[i].attributes.segment_type === 'ad') retval.push(this.data[i].fract);

    return retval;
  }

  getDuration(index) {
    return this.data[index]?.attributes?.duration || 0;
  }

  /**
   * Get the end time of the segment in milliseconds since the epoch.
   * @param {Number} index
   * @returns {Number}
   */
  getEndTime(index) {
    return new Date(this.data[index].attributes.end_time).getTime();
  }

  getEpisode() {
    return this.episode;
  }

  getFract(index) {
    return this.data[index].fract;
  }

  getFractForTime(time) {
    const index = this.getSegmentIndexByTime(time);
    const offset = (time - this.getStartTime(index)) / 1000 / this.getDuration(index);

    return this.getFract(index) + offset * this.getFractLength(index);
  }

  getFractLength(index) {
    return this.data[index].fractLength;
  }

  insert(index, data) {
    this.data.splice(index, 0, data);
  }

  /**
   * @returns {ISegment|undefined}
   */
  getLiveSegment() {
    return this.data[this.getLiveSegmentIndex()];
  }

  /**
   * @returns {number|undefined}
   */
  getLiveSegmentIndex() {
    const endTime = this.data[this.data.length - 1]?.attributes?.end_time;
    return !endTime ? this.data.length - 1 : undefined;
  }

  /**
   * Returns the number of segments in the list.
   * @returns {Number}
   */
  getNum() {
    return this.data.length;
  }

  getRaw(index) {
    return this.data[index];
  }

  /**
   * Returns the segment index at the passed fraction
   *
   * @param {Number} fract
   * @returns {Number|undefined}
   */
  getSegmentByFract(fract) {
    let retval;

    for (let i = 0; i < this.data.length; i++) {
      if (fract < this.data[i].fract + this.data[i].fractLength) {
        retval = i;
        break;
      }
    }

    return retval;
  }

  getSegmentIndexByTime(time) {
    let retval;

    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].endTime === undefined)
        this.data[i].endTime = new Date(this.data[i].attributes.end_time).getTime();

      let endTime = this.data[i].endTime;

      if (!endTime) endTime = new Date(this.episode.data.endDateTime).getTime();

      if (time < endTime) {
        retval = i;
        break;
      }
    }

    return retval;
  }

  /**
   *
   * @param {Number} index
   * @returns {Number|undefined}
   */
  getSegmentStartTime(index) {
    const segment = this.data[index];

    if (segment && segment.startTime === undefined)
      segment.startTime = new Date(segment.attributes.start_time).getTime();

    return segment?.startTime;
  }

  remove(index) {
    this.data.splice(index, 1);
  }

  /**
   * Get the start time of the segment in milliseconds since the epoch.
   * @param {Number} index
   * @returns {Number}
   */
  getStartTime(index) {
    return new Date(this.data[index].attributes.start_time).getTime();
  }

  getStation() {
    return this.station;
  }

  getStationId(index) {
    return this.data[index]?.attributes?.station_id;
  }

  getType(index) {
    return this.data[index] && this.data[index].attributes.segment_type;
  }

  removeAds() {
    for (let i = this.data.length - 1; i >= 0; i--) {
      if (this.data[i].attributes.segment_type !== 'content') this.data.splice(i, 1);
    }
  }

  replaceSegments(segmentList) {
    this.data = segmentList.data;
    this.duration = segmentList.duration;
    this.episode = segmentList.episode;
    this.station = segmentList.station;
  }
}
