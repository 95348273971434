import List from './List';
import { ObjectType } from '../Constants';

/**
 * @module SongList
 */
export default class SongList extends List {
  /**
   * Creates a new instance of a SongList.
   * @param {String}       version
   * @param {Object}       data
   * @param {DataServices} dataService
   */
  constructor(version, data, dataService) {
    super(version, data, dataService, ObjectType.SONG_LIST);
  }

  /**
   * Get the song artist for specified index.
   * @param   {Number} index
   * @returns {String}
   */
  getArtist(index) {
    return this.data[index] && this.data[index].artist;
  }

  /**
   * Get the image for specified index.
   * @param   {Number} index
   * @returns {String}
   */
  getImage(index) {
    return this.data[index] && this.data[index].image;
  }

  /**
   * Get the song title for specified index.
   * @param   {Number} index
   * @returns {String}
   */
  getTitle(index) {
    return this.data[index] && this.data[index].title;
  }

  /**
   * Get the time the song played for specified index.
   * @param   {Number} index
   * @returns {String}
   */
  getTimePlayed(index) {
    return this.data[index] && this.data[index].timePlayed;
  }
}
