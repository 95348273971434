import { IImageSize } from './types';
import { formatSrc } from './utils';

export interface IResponsiveImageProps {
  children?: React.ReactNode;
  sizes?: IImageSize[];
  src: string;
}

const ResponsiveImage = ({ children, sizes = [], src }: IResponsiveImageProps): JSX.Element => {
  if (!sizes.length) return <>{children}</>;

  return (
    <picture>
      {sizes?.map(({ maxWidth, size }, i) => {
        const defaultAttributes = {
          key: `${src}-${size}-${i}`,
          srcSet: formatSrc(src, size),
        };

        return maxWidth ? (
          <source {...defaultAttributes} media={`(max-width: ${maxWidth}px)`} />
        ) : (
          <source {...defaultAttributes} />
        );
      })}
      {children}
    </picture>
  );
};

export default ResponsiveImage;
