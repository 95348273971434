import { CHAT_HOST_PREFIX } from '../Constants';
import ChatSession from './ChatSession';
import Service from '../Service';
import Container from '../Container';

const serviceBus = Container;

/**
 * @typedef {Object} ChatSessionEvent
 * @property {String} type
 * @property {Object} data
 */

/**
 * @callback ChatSessionListener
 * @param {ChatSessionEvent} event
 */

/**
 * @module ChatServices
 */
export default class ChatServices extends Service {
  /**
   * Creates a new instance of ChatServices.
   */
  constructor() {
    super();

    this.chatSessions = {};
  }

  /**
   * @param {String} contentId
   * @param {String} sessionId
   * @param {ChatSessionListener} eventListener
   * @param {String} displayName
   * @param {Boolean} isHost
   * @returns {Promise<ChatSession>}
   */
  async createSession(
    contentId,
    sessionId,
    eventListener,
    displayName = undefined,
    isHost = false,
  ) {
    if (!this.userDisplayName || !this.userProfilePictureUrl)
      await this.updateDisplayName(displayName, isHost);

    const prevSession = this.chatSessions[sessionId];

    if (prevSession === undefined || prevSession.isActive() === false)
      return (this.chatSessions[sessionId] = new ChatSession(
        contentId,
        sessionId,
        eventListener,
        this.userDisplayName,
        this.userProfilePictureUrl,
      ));
    else return prevSession;
  }

  /**
   * @param {String} displayName
   * @param {Boolean} isHost
   * @returns {Promise}
   */
  async updateDisplayName(displayName = undefined, isHost = false) {
    const createDisplayName = () => {
      const min = 100000;
      const max = 999999;
      const rand = Math.random();
      const name = 'User-' + (((rand * (max - min)) | 0) + min);

      serviceBus.personalizationServices.dataStore.setData('displayName', name);

      return name;
    };

    let profile;

    if (!displayName) {
      profile = await serviceBus.personalizationServices.getProfileData();

      displayName = profile?.displayName;

      if (!displayName)
        displayName = await serviceBus.personalizationServices.dataStore.getData('displayName');

      if (!displayName) displayName = profile?.firstName;

      if (!displayName) displayName = createDisplayName();
    } else await serviceBus.personalizationServices.dataStore.setData('displayName', displayName);

    this.userDisplayName = (isHost === true ? CHAT_HOST_PREFIX : '') + displayName;

    for (let session of Object.values(this.chatSessions))
      session.setScreenName(this.userDisplayName);

    if (!profile) profile = await serviceBus.personalizationServices.getProfileData();

    this.userProfilePictureUrl =
      profile?.avatar || 'https://images.radio.com/logos/Audacy_generic_300x250.jpg';
  }
}
