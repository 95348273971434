import { BP, Colors, mq, mqClamp, Typography } from '~/styles';
import { type TStyleSheet } from '~/types/emotion-styles';

export const Constants = {
  estimatedItemSize: 120,
  flashListviewOffset: 0,
  flashListviewPosition: 0,
  indSize: 48,
  startOffset: 0,
  resumeBtnOpacityDuration: 500,
  resumeBtnOpacityStartVal: 0,
  resumeBtnOpacityEndVal: 1,
  resumeBtnRotationStartValue: 180,
  resumeBtnRotationEndValue: 0,
  scrollTimeoutMs: 10000,
  noIndexFounded: -1,
  transcriptHeaderTimeFormat: 'h:mmaaa',
};

const defaultTextStyle: React.CSSProperties = {
  color: Colors.secondaryLilac2,
};

const styles: TStyleSheet = {
  scrollContainer: {
    flexGrow: 1,
    position: 'relative',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  transcriptContentContainer: {
    padding: '0px 20px',
    [mqClamp(BP.Medium, BP.MediumMax)]: {
      padding: '0px 42px',
    },
  },
  contentContainer: {
    paddingTop: '8px',
  },
  item: {
    cursor: 'pointer',
    padding: '15px 0px',
    [mq(BP.Large)]: {
      padding: '15px 0px',
    },
  },
  highlightedItem: {
    color: Colors.white,
  },
  resumeButton: {
    backgroundColor: Colors.white,
    width: '70px',
    borderRadius: '20px',
  },
  resumeButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    padding: '20px 0',
    justifyItems: 'center',
    bottom: '5px',
    [mq(BP.Large)]: {
      bottom: '7px',
    },
  },
  speaker: {
    ...Typography.bodySmall,
    lineHeight: '18px',
    letterSpacing: '0.15px',
    ...defaultTextStyle,
  },
  text: {
    ...Typography.bodyLarge,
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    ...defaultTextStyle,
  },
  loadContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  listFooterComponent: {
    backgroundColor: Colors.semitransparentWhite30,
    flexDirection: 'row',
    alignItems: 'center',
    margin: '20px 0px 40px',
    padding: '15px 30px',
    borderRadius: 5,
  },
  transcriptAdContainer: {
    height: 'calc(100% - 50px)',
  },
  transcriptAdBlock: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    height: '100%',
    borderRadius: '8px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  transcriptAdBlockTitle: {
    fontFamily: 'Maison Neue',
    fontWeight: 400,
    fontSize: '15px',
    letterSpacing: 0.01,
    color: Colors.white,
    marginTop: '10px',
    marginBottom: '10px',
  },
  transcriptAdBlockSubtitle: {
    fontFamily: 'Maison Neue',
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: 0.02,
    color: 'rgba(255, 255, 255, 0.6)',
  },
  transcriptHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },

  transcriptHeaderPillowContainer: {
    width: 'fit-content',
    borderRadius: 100,
    color: Colors.black,
    backgroundColor: Colors.white,
    padding: '6px 14px',
  },
  transcriptHeaderPillowText: {
    ...Typography.buttonLarge,
  },
  transcriptHeaderText: {
    ...Typography.headlineSmall,
    marginTop: '10px',
    wordBreak: 'break-word',
  },
};

export default styles;
