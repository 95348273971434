import { useEffect } from 'react';

import { ClickFeature } from '@audacy-clients/client-services/core';
import { isLoggedIn } from '@audacy-clients/core/atoms/auth';
import playerState, { nowPlaying } from '@audacy-clients/core/atoms/player';
import { queueHadExpiredItemsState, queueIsLoadingState } from '@audacy-clients/core/atoms/queue';
import { PlayerState } from '@audacy-clients/core/types/player';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useAudioHelpersWeb } from '~/hooks/use-audio-helpers-web';
import useWebQueue from '~/hooks/use-web-queue';

import Queue, { IQueueProps } from './Queue';
import QueueExpiredMessage from './QueueExpiredMessage';

export type TQueueWithState = {
  isPlayerQueue?: boolean;
} & Pick<
  IQueueProps,
  | 'shouldRenderHeader'
  | 'containerCss'
  | 'headerCss'
  | 'listCss'
  | 'shouldForceFocus'
  | 'emptyQueueCss'
>;

const QueueWithState = (props: TQueueWithState): JSX.Element => {
  const { t } = useTranslation();
  const nowPlayingItem = useRecoilValue(nowPlaying);
  const nowPlayingItemId = nowPlayingItem?.dataObject?.id;
  const nowPlayingItemEpisodeId = nowPlayingItem?.episodeDataObject?.id;
  const { playState } = useRecoilValue(playerState);
  const { request, ...queue } = useWebQueue();
  const audioHelpers = useAudioHelpersWeb();
  const [hadExpiredItems, setHadExpiredItems] = useRecoilState(queueHadExpiredItemsState);
  const loggedIn = useRecoilValue(isLoggedIn);
  const setIsLoading = useSetRecoilState(queueIsLoadingState);

  useEffect(() => {
    if (loggedIn) {
      request();
    } else {
      setIsLoading(false);
    }
  }, [request, loggedIn, setIsLoading]);

  const onPlayQueueItem = (episodeId: string): void => {
    const playContext = {
      contentId: episodeId,
      feature: ClickFeature.QUEUE,
    };

    const episodeIsNowPlaying = [nowPlayingItemId, nowPlayingItemEpisodeId].includes(episodeId);

    if (episodeIsNowPlaying && playState === PlayerState.Playing) {
      audioHelpers.pause(playContext);
    } else if (episodeIsNowPlaying) {
      audioHelpers.resume(playContext);
    } else {
      audioHelpers.playQueue(episodeId, playContext);
    }
  };

  const hideExpiredMessage = (): void => {
    if (hadExpiredItems) {
      // We set the state to false if the user clicks to hide, leaves the queue,
      // or starts editing it. Only API calls to the queue can set it to true.
      setHadExpiredItems(false);
    }
  };

  // Remove the expired message on unmount
  useEffect(() => {
    return () => {
      hadExpiredItems && setHadExpiredItems(false);
    };
  }, [hadExpiredItems, setHadExpiredItems]);

  return (
    <Queue
      isLoading={queue.isLoading}
      nowPlayingItemId={nowPlayingItemId}
      queueItems={queue.items}
      onRemove={queue.remove}
      selectedItems={queue.selectedItems}
      onSortEnd={(items) => queue.set({ currentId: queue.currentId, items })}
      renderListLabel={() => (
        <>
          {hadExpiredItems ? (
            <QueueExpiredMessage
              hideExpiredMessage={hideExpiredMessage}
              isPlayerQueue={props.isPlayerQueue}
            />
          ) : (
            t('myAudioScreen.dynamicHeader.myQueue', { count: queue.items.length })
          )}
        </>
      )}
      onItemClick={onPlayQueueItem}
      hadExpiredItems={hadExpiredItems}
      hideExpiredMessage={hideExpiredMessage}
      {...props}
    />
  );
};

export default QueueWithState;
