import { type EntitySubtype, EpisodeSubType } from '@audacy-clients/client-services';
import {
  getChapterTranscriptTimeSpeaker,
  getTranscriptTimeSpeaker,
} from '@audacy-clients/core/hooks/useTranscript';
import { css } from '@emotion/react';
import { forwardRef, memo } from 'react';
import styles from './styles';

type IProps = {
  index: number;
  isActive: boolean;
  startTime: number;
  speaker: string;
  text: string;
  handleTranscriptPress: (startTime: number, index: number) => () => void;
  handleTranscriptRef: (index: number) => (ref: HTMLDivElement | null) => void;
  startOffset?: number;
  entitySubtype?: EntitySubtype;
  renderHeader?: () => React.JSX.Element;
};

const ItemCell = forwardRef<HTMLDivElement, IProps>(
  ({
    index,
    isActive,
    startTime,
    speaker,
    text,
    handleTranscriptPress,
    handleTranscriptRef,
    startOffset = 0,
    entitySubtype,
    renderHeader,
  }) => {
    let timeSpeaker;

    switch (entitySubtype) {
      case EpisodeSubType.BROADCAST_SHOW_EPISODE:
        timeSpeaker = getChapterTranscriptTimeSpeaker(startTime, speaker, startOffset);
        break;
      default:
        timeSpeaker = getTranscriptTimeSpeaker(startTime, speaker);
    }
    return (
      <div
        ref={handleTranscriptRef(index)}
        css={styles.item}
        onClick={handleTranscriptPress(startTime, index)}
      >
        {renderHeader?.()}
        <p css={css(styles.speaker, isActive && styles.highlightedItem)}>{timeSpeaker}</p>
        <p css={css(styles.text, isActive && styles.highlightedItem)}>{text}</p>
      </div>
    );
  },
);

export default memo(ItemCell);
