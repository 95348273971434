import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '~/components/Icon';
import { Icons } from '~/components/Icon/constants';
import { Colors } from '~/styles';
import styles from './styles';

const TranscriptAdBlock: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div css={styles.transcriptAdContainer}>
      <div css={styles.transcriptAdBlock}>
        <Icon name={Icons.Advertisement} iconColor={Colors.white} ariaHidden={false} sizeInPx={24} />
        <span css={styles.transcriptAdBlockTitle}>{t('transcript.adBlockTitle')}</span>
        <span css={styles.transcriptAdBlockSubtitle}>{t('transcript.adBlockSubTitle')}</span>
      </div>
    </div>
  );
};

export default TranscriptAdBlock;
