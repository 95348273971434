import { useState } from 'react';
import TranscriptChapterControls from '~/components/Transcript/TranscriptChapterControls';
import TranscriptContent from '~/components/Transcript/TranscriptContent';
import TranscriptOverlay from '~/components/Transcript/TranscriptOverlay';
import styles from './styles';

type TranscriptPanelProps = {
  hasChapter?: boolean;
};

const TranscriptPanel = ({ hasChapter = false }: TranscriptPanelProps): JSX.Element => {
  const [isContentExpanded, setIsContentExpanded] = useState(false);

  const toggleContentExpansion = () => {
    setIsContentExpanded((prev) => !prev);
  };

  return (
    <>
      <div css={[styles.transcriptContainer, !isContentExpanded ? styles.collapsedContainer : {}]}>
        <div css={styles.transcriptHeader}>
          <h2 css={styles.transcriptTitle}>Transcript</h2>
          <button css={styles.toggleButton} onClick={toggleContentExpansion}>
            {isContentExpanded ? 'Collapse' : 'Expand'}
          </button>
        </div>

        {isContentExpanded && (
          <>
            <div css={[styles.transcriptContent, hasChapter && styles.disablePaddingBottom]}>
              <TranscriptOverlay containerStyle={styles.contentContainer}>
                <TranscriptContent />
              </TranscriptOverlay>
            </div>
            {hasChapter && (
              <div css={styles.transcriptChapterControlsContainer}>
                <TranscriptChapterControls />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TranscriptPanel;
