import { useTranslation } from 'react-i18next';

import { TStyles } from '~/types/emotion-styles';

import illustration from './illustration';
import styles from './styles';

const QueueEmpty = ({ containerCss }: { containerCss?: TStyles }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div css={[styles.container, containerCss]}>
      <h3 css={styles.title}>{t('queueOverlay.emptyQueue.title')}</h3>
      <p css={styles.subtitle}>{t('queueOverlay.emptyQueue.subtitle')}</p>
      {illustration}
    </div>
  );
};

export default QueueEmpty;
