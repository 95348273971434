import { ViewIDKey } from '@audacy-clients/core/atoms/config/constants';
import { EditableItems } from '@audacy-clients/core/atoms/editMode';

export type TMyAudioTabItemType = EditableItems;

export enum FollowsCategoryTabStrings {
  MyQueue = 'myQueue',
  OnDemands = 'onDemands',
  Recents = 'recents',
  Stations = 'stations',
  Topics = 'topics',
  Playlists = 'playlists',
  Followed = 'followed',
}

export interface ITabConfig {
  href: string;
  title: string;
  itemType: EditableItems;
  viewIdKey: ViewIDKey;
}

export interface IDoubleLevelTabs extends ITabConfig {
  tabs?: ITabConfig[];
}
