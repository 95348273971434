import { Mixins } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  truncated: {
    ...Mixins.truncateText(3),
  },
  masked: {
    maskImage: 'linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 33%)',
  },
  toggleButton: {
    marginTop: 10,
  },
};

export default styles;
