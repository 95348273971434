import { useEffect, useRef } from 'react';

import { ClickFeature, Preference } from '@audacy-clients/client-services/core';
import { ECollectionPlaybackMode } from '@audacy-clients/client-services/src/audioServices/types';
import {
  collectionPlaybackModeState,
  selectedPlaylistIdState,
} from '@audacy-clients/core/atoms/collections';
import { playerControlsState } from '@audacy-clients/core/atoms/player';
import clientServices from '@audacy-clients/core/utils/clientServices';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import usePlayerProps from '~/components/Player/hooks/usePlayerProps';
import { useAudioHelpersWeb } from '~/hooks/use-audio-helpers-web';
import { likeState, userTimeState as userTimeStateAtom } from '~/state/player';
import useToast from '~/state/toast';

export interface IUsePlayerControls {
  onLike: () => void;
  onPause: () => void;
  onPlay: () => void;
  onSendNext: () => void;
  onSkip: (seconds: number) => void;
  onSkipToLive: () => void;
  onStop: () => void;
  onNext: () => void;
  onPrev: () => void;
  skipsRemaining?: number;
  isLiked?: boolean;
}

const usePlayerControls = (): IUsePlayerControls => {
  const { skipsRemaining } = useRecoilValue(playerControlsState) || {};
  const [userTimeState, setUserTimeState] = useRecoilState(userTimeStateAtom);
  const [isLiked, setIsLiked] = useRecoilState(likeState);

  const { episodeId, isOpen, miniPlayerTitle, imageUrl, contentId, artist, isIdle, playerTitle } =
    usePlayerProps();
  const audioHelpers = useAudioHelpersWeb();
  const { showToast } = useToast();
  const { t } = useTranslation();

  const hasRendered = useRef(false);
  const collectionPlaybackMode = useRecoilValue(collectionPlaybackModeState);
  const isPlaylist = collectionPlaybackMode === ECollectionPlaybackMode.Playlist;
  const selectedPlaylistId = useRecoilValue(selectedPlaylistIdState) ?? '';

  const { userPlaybackFraction } = userTimeState;

  // TODO:[A2-6155] Use unique identifier for track to reset and store state in atom. Remove hasRendered logic.
  // Reset isLiked state when next content plays
  // Resets on change of title, imageUrl, and/or subtitle (artist).
  useEffect(() => {
    // Do not reset on first render of new player
    if (hasRendered.current) {
      setIsLiked(false);
    }
  }, [miniPlayerTitle, imageUrl, artist, setIsLiked]);

  // Set when player has rendered once
  useEffect(() => {
    hasRendered.current = true;
  }, []);

  const playContext = {
    contentId: episodeId || contentId,
    feature: isOpen ? ClickFeature.FULL_PLAYER : ClickFeature.MINI_PLAYER,
  };

  const onLike = () => {
    if (isLiked) {
      setIsLiked(false);
      audioHelpers.sendPreference(Preference.DISLIKE);
      if (contentId && miniPlayerTitle && artist) {
        clientServices
          .getAnalyticServices()
          .sendSongLikeEvent(contentId, miniPlayerTitle, artist, false);
      }
    } else {
      setIsLiked(true);
      audioHelpers.sendPreference(Preference.LIKE);
      showToast({
        title: t('playerControls.toast.thumbsUp'),
      });
      if (contentId && miniPlayerTitle && artist) {
        clientServices
          .getAnalyticServices()
          .sendSongLikeEvent(contentId, miniPlayerTitle, artist, true);
      }
    }
  };

  // Do not pass the play method directly, because the event object gets passed as the content object
  const onPlay = (): void => {
    const isValidFraction = typeof userPlaybackFraction === 'number';
    if (isValidFraction) {
      !isIdle && audioHelpers.seekToProgress(userPlaybackFraction);
      setUserTimeState({});
    }

    audioHelpers.resume(playContext);
  };

  const onSkip = (secs: number) => audioHelpers.skipByTime(secs, playContext);

  const onSendNext = () => {
    if (skipsRemaining) {
      audioHelpers.skip({ ...playContext, artist, songTitle: playerTitle });
      showToast({
        title: t('playerControls.toast.skipMessage', {
          skips: skipsRemaining - 1,
        }),
      });
    } else {
      showToast({
        title: t('playerControls.toast.skipReachedLimitMessage'),
      });
    }
  };

  const onStop = () => audioHelpers.stop(playContext);

  const onPause = () => audioHelpers.pause(playContext);

  const onNext = () =>
    audioHelpers.next({
      contentId: episodeId || contentId,
      ...(isPlaylist
        ? {
            playlistId: selectedPlaylistId,
            feature: ClickFeature.PLAYLIST_NEXT,
          }
        : { feature: ClickFeature.QUEUE_NEXT }),
    });

  const onPrev = () =>
    audioHelpers.previous({
      contentId: episodeId || contentId,
      ...(isPlaylist
        ? {
            playlistId: selectedPlaylistId,
            feature: ClickFeature.PLAYLIST_PREVIOUS,
          }
        : { feature: ClickFeature.QUEUE_PREVIOUS }),
    });

  return {
    onPlay,
    onSkip,
    onNext,
    onPrev,
    onPause,
    onSkipToLive: audioHelpers.seekToLivePosition,
    onStop,
    onLike,
    onSendNext,
    skipsRemaining,
    isLiked,
  };
};

export default usePlayerControls;
