import { type ReactElement } from 'react';

import Account from '~/assets/icons/account';
import Advertisement from '~/assets/icons/advertisement';
import Apple from '~/assets/icons/apple';
import ArrowDown from '~/assets/icons/arrow-down';
import Back from '~/assets/icons/back';
import Back15 from '~/assets/icons/back-15';
import Bell from '~/assets/icons/bell';
import BellFilled from '~/assets/icons/bell-filled';
import Bluetooth from '~/assets/icons/bluetooth';
import Broadcast from '~/assets/icons/broadcast';
import Calendar from '~/assets/icons/calendar';
import Call from '~/assets/icons/call';
import CaretContained from '~/assets/icons/caret-contained';
import CaretDown from '~/assets/icons/caret-down';
import CaretLeft from '~/assets/icons/caret-left';
import CaretRight from '~/assets/icons/caret-right';
import CaretUp from '~/assets/icons/caret-up';
import Check from '~/assets/icons/check';
import Close from '~/assets/icons/close';
import CloseCircle from '~/assets/icons/close-circle';
import Code from '~/assets/icons/code';
import Completed from '~/assets/icons/completed';
import Computer from '~/assets/icons/computer';
import CopyLink from '~/assets/icons/copy-link';
import Detail from '~/assets/icons/detail';
import Download from '~/assets/icons/download';
import Downloaded from '~/assets/icons/downloaded';
import Email from '~/assets/icons/email';
import ExitFullScreen from '~/assets/icons/exit-full-screen';
import Facebook from '~/assets/icons/facebook';
import Forward15 from '~/assets/icons/forward-15';
import FullScreen from '~/assets/icons/full-screen';
import Gear from '~/assets/icons/gear';
import Google from '~/assets/icons/google';
import GotoBig from '~/assets/icons/goto-big';
import GotoCircle from '~/assets/icons/goto-circle';
import Handle from '~/assets/icons/handle';
import Headphone from '~/assets/icons/headphone';
import Headset from '~/assets/icons/headset';
import Heart from '~/assets/icons/heart';
import HeartFilled from '~/assets/icons/heart-filled';
import History from '~/assets/icons/history';
import Home from '~/assets/icons/home';
import Info from '~/assets/icons/info';
import InfoBig from '~/assets/icons/info-big';
import Instagram from '~/assets/icons/instagram';
import Key from '~/assets/icons/key';
import Leave from '~/assets/icons/leave';
import ListHandle from '~/assets/icons/list-handle';
import Live from '~/assets/icons/live';
import LiveLG from '~/assets/icons/live-lg';
import LiveSM from '~/assets/icons/live-sm';
import LiveTag from '~/assets/icons/live-tag';
import Location from '~/assets/icons/location';
import Location2 from '~/assets/icons/location2';
import Lock from '~/assets/icons/lock';
import Logo from '~/assets/icons/logo';
import Markets from '~/assets/icons/markets';
import Menu from '~/assets/icons/menu';
import Message from '~/assets/icons/message';
import Mic from '~/assets/icons/mic';
import MicMuted from '~/assets/icons/mic-muted';
import MicUnmuted from '~/assets/icons/mic-unmuted';
import MinusCircle from '~/assets/icons/minus-circle';
import More from '~/assets/icons/more';
import Music from '~/assets/icons/music';
import Newsletter from '~/assets/icons/newsletter';
import Next from '~/assets/icons/next';
import Pause from '~/assets/icons/pause';
import PauseCircle from '~/assets/icons/pause-circle';
import People from '~/assets/icons/people';
import Phone from '~/assets/icons/phone';
import Placeholder from '~/assets/icons/placeholder';
import Play from '~/assets/icons/play';
import PlayMini from '~/assets/icons/play-mini';
import PlusCircle from '~/assets/icons/plus-circle';
import Previous from '~/assets/icons/previous';
import Profile from '~/assets/icons/profile';
import Progress from '~/assets/icons/progress';
import Queue from '~/assets/icons/queue';
import QueueAdd from '~/assets/icons/queue-add';
import QueueRemove from '~/assets/icons/queue-remove';
import Reaction from '~/assets/icons/reaction';
import Report from '~/assets/icons/report';
import Search from '~/assets/icons/search';
import SearchNav from '~/assets/icons/search-nav';
import Share from '~/assets/icons/share';
import Shield from '~/assets/icons/shield';
import Sort from '~/assets/icons/sort';
import SortArrows from '~/assets/icons/sort-arrows';
import SoundSystem from '~/assets/icons/sound-system';
import Sports from '~/assets/icons/sports';
import Stop from '~/assets/icons/stop';
import ThumbsDown from '~/assets/icons/thumbs-down';
import ThumbsDownFilled from '~/assets/icons/thumbs-down-filled';
import ThumbsUp from '~/assets/icons/thumbs-up';
import ThumbsUpFilled from '~/assets/icons/thumbs-up-filled';
import Ticket from '~/assets/icons/ticket';
import Time from '~/assets/icons/time';
import Trash from '~/assets/icons/trash';
import Trending from '~/assets/icons/trending';
import Twitter from '~/assets/icons/twitter';
import Upload from '~/assets/icons/upload';
import Video from '~/assets/icons/video';
import Volume from '~/assets/icons/volume';
import VolumeMuted from '~/assets/icons/volume-muted';
import Website from '~/assets/icons/website';
import XLogo from '~/assets/icons/x-logo';
import YouTube from '~/assets/icons/you-tube';

// TODO: [A2-1410] Investigate upgrading SVGR to 6+

export enum Icons {
  Account = 'Account',
  Advertisement = 'Advertisement',
  Apple = 'Apple',
  ArrowDown = 'ArrowDown',
  Back15 = 'Back15',
  Back = 'Back',
  BellFilled = 'BellFilled',
  Bell = 'Bell',
  Bluetooth = 'Bluetooth',
  Broadcast = 'Broadcast',
  Calendar = 'Calendar',
  Call = 'Call',
  CaretContained = 'CaretContained',
  CaretDown = 'CaretDown',
  CaretLeft = 'CaretLeft',
  CaretRight = 'CaretRight',
  CaretUp = 'CaretUp',
  Check = 'Check',
  CloseCircle = 'CloseCircle',
  Close = 'Close',
  Code = 'Code',
  Completed = 'Completed',
  Computer = 'Computer',
  CopyLink = 'CopyLink',
  Detail = 'Detail',
  Download = 'Download',
  Downloaded = 'Downloaded',
  Email = 'Email',
  ExitFullScreen = 'ExitFullScreen',
  Facebook = 'Facebook',
  Forward15 = 'Forward15',
  FullScreen = 'FullScreen',
  Gear = 'Gear',
  Google = 'Google',
  GotoBig = 'GotoBig',
  GotoCircle = 'GotoCircle',
  Handle = 'Handle',
  Headphone = 'Headphone',
  Headset = 'Headset',
  Heart = 'Heart',
  HeartFilled = 'HeartFilled',
  History = 'History',
  Home = 'Home',
  Info = 'Info',
  InfoBig = 'InfoBig',
  Instagram = 'Instagram',
  Key = 'Key',
  Leave = 'Leave',
  ListHandle = 'ListHandle',
  LiveLG = 'LiveLG',
  LiveSM = 'LiveSM',
  Live = 'Live',
  LiveTag = 'LiveTag',
  Location = 'Location',
  Location2 = 'Location2',
  Lock = 'Lock',
  Logo = 'Logo',
  Markets = 'Markets',
  Menu = 'Menu',
  Message = 'Message',
  MicMuted = 'MicMuted',
  MicUnmuted = 'MicUnmuted',
  Mic = 'Mic',
  MinusCircle = 'MinusCircle',
  More = 'More',
  Music = 'Music',
  Newsletter = 'Newsletter',
  Next = 'Next',
  Pause = 'Pause',
  PauseCircle = 'PauseCircle',
  People = 'People',
  Phone = 'Phone',
  Placeholder = 'Placeholder',
  PlayMini = 'PlayMini',
  Play = 'Play',
  PlusCircle = 'PlusCircle',
  Previous = 'Previous',
  Profile = 'Profile',
  Progress = 'Progress',
  QueueAdd = 'QueueAdd',
  QueueRemove = 'QueueRemove',
  Queue = 'Queue',
  Reaction = 'Reaction',
  Report = 'Report',
  Search = 'Search',
  SearchNav = 'SearchNav',
  Share = 'Share',
  Shield = 'Shield',
  Sort = 'Sort',
  SortArrows = 'SortArrows',
  SoundSystem = 'SoundSystem',
  Sports = 'Sports',
  Stop = 'Stop',
  ThumbsDownFilled = 'ThumbsDownFilled',
  ThumbsDown = 'ThumbsDown',
  ThumbsUpFilled = 'ThumbsUpFilled',
  ThumbsUp = 'ThumbsUp',
  Ticket = 'Ticket',
  Time = 'Time',
  Trash = 'Trash',
  Trending = 'Trending',
  Twitter = 'Twitter',
  Upload = 'Upload',
  Video = 'Video',
  VolumeMuted = 'VolumeMuted',
  Volume = 'Volume',
  Website = 'Website',
  XLogo = 'XLogo',
  YouTube = 'YouTube',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isIcon = (props: any): props is Icons => Object.values(Icons).includes(props as Icons);

export const MainNavIconMap: Record<string, ReactElement> = {
  [Icons.Broadcast]: Broadcast,
  [Icons.Home]: Home,
  [Icons.Logo]: Logo,
  [Icons.Music]: Music,
  [Icons.Mic]: Mic,
  [Icons.Profile]: Profile,
  [Icons.Sports]: Sports,
  [Icons.SearchNav]: SearchNav,
};

export const ControlsIconMap: Record<string, ReactElement> = {
  [Icons.Play]: Play,
  [Icons.Stop]: Stop,
  [Icons.Pause]: Pause,
  [Icons.Previous]: Previous,
  [Icons.Next]: Next,
  [Icons.Back15]: Back15,
  [Icons.Forward15]: Forward15,
};

export const OthersIconMap: Record<string, ReactElement> = {
  [Icons.Account]: Account,
  [Icons.Advertisement]: Advertisement,
  [Icons.Apple]: Apple,
  [Icons.ArrowDown]: ArrowDown,
  [Icons.Back]: Back,
  [Icons.BellFilled]: BellFilled,
  [Icons.Bell]: Bell,
  [Icons.Bluetooth]: Bluetooth,
  [Icons.Calendar]: Calendar,
  [Icons.Call]: Call,
  [Icons.CaretContained]: CaretContained,
  [Icons.CaretDown]: CaretDown,
  [Icons.CaretLeft]: CaretLeft,
  [Icons.CaretRight]: CaretRight,
  [Icons.CaretUp]: CaretUp,
  [Icons.Check]: Check,
  [Icons.CloseCircle]: CloseCircle,
  [Icons.Close]: Close,
  [Icons.Code]: Code,
  [Icons.Completed]: Completed,
  [Icons.Computer]: Computer,
  [Icons.CopyLink]: CopyLink,
  [Icons.Detail]: Detail,
  [Icons.Download]: Download,
  [Icons.Downloaded]: Downloaded,
  [Icons.Email]: Email,
  [Icons.ExitFullScreen]: ExitFullScreen,
  [Icons.Facebook]: Facebook,
  [Icons.FullScreen]: FullScreen,
  [Icons.Gear]: Gear,
  [Icons.Google]: Google,
  [Icons.GotoBig]: GotoBig,
  [Icons.GotoCircle]: GotoCircle,
  [Icons.Handle]: Handle,
  [Icons.Headphone]: Headphone,
  [Icons.Headset]: Headset,
  [Icons.Heart]: Heart,
  [Icons.HeartFilled]: HeartFilled,
  [Icons.History]: History,
  [Icons.Info]: Info,
  [Icons.InfoBig]: InfoBig,
  [Icons.Instagram]: Instagram,
  [Icons.Key]: Key,
  [Icons.Leave]: Leave,
  [Icons.ListHandle]: ListHandle,
  [Icons.LiveLG]: LiveLG,
  [Icons.LiveSM]: LiveSM,
  [Icons.Live]: Live,
  [Icons.LiveTag]: LiveTag,
  [Icons.Location]: Location,
  [Icons.Location2]: Location2,
  [Icons.Lock]: Lock,
  [Icons.Markets]: Markets,
  [Icons.Menu]: Menu,
  [Icons.Message]: Message,
  [Icons.MicMuted]: MicMuted,
  [Icons.MicUnmuted]: MicUnmuted,
  [Icons.MinusCircle]: MinusCircle,
  [Icons.More]: More,
  [Icons.Newsletter]: Newsletter,
  [Icons.PauseCircle]: PauseCircle,
  [Icons.People]: People,
  [Icons.Phone]: Phone,
  [Icons.Placeholder]: Placeholder,
  [Icons.PlayMini]: PlayMini,
  [Icons.PlusCircle]: PlusCircle,
  [Icons.Progress]: Progress,
  [Icons.QueueAdd]: QueueAdd,
  [Icons.QueueRemove]: QueueRemove,
  [Icons.Queue]: Queue,
  [Icons.Reaction]: Reaction,
  [Icons.Report]: Report,
  [Icons.Search]: Search,
  [Icons.Share]: Share,
  [Icons.Shield]: Shield,
  [Icons.Sort]: Sort,
  [Icons.SortArrows]: SortArrows,
  [Icons.SoundSystem]: SoundSystem,
  [Icons.ThumbsDownFilled]: ThumbsDownFilled,
  [Icons.ThumbsDown]: ThumbsDown,
  [Icons.ThumbsUpFilled]: ThumbsUpFilled,
  [Icons.ThumbsUp]: ThumbsUp,
  [Icons.Ticket]: Ticket,
  [Icons.Time]: Time,
  [Icons.Trash]: Trash,
  [Icons.Trending]: Trending,
  [Icons.Twitter]: Twitter,
  [Icons.Upload]: Upload,
  [Icons.Video]: Video,
  [Icons.VolumeMuted]: VolumeMuted,
  [Icons.Volume]: Volume,
  [Icons.Website]: Website,
  [Icons.XLogo]: XLogo,
  [Icons.YouTube]: YouTube,
};

export const IconMap: Record<string, ReactElement> = {
  ...MainNavIconMap,
  ...ControlsIconMap,
  ...OthersIconMap,
};
