import React, { useEffect } from 'react';

import { IQueue } from '@audacy-clients/client-services/src/personalizationServices/types';
import {
  queueState,
  queueHadExpiredItemsState,
  queueIsLoadingState,
  queueLastUpdateState,
} from '@audacy-clients/core/atoms/queue';
import { wrapQueueItem } from '@audacy-clients/core/atoms/wrappers/queue';
import { useClientServices } from '@audacy-clients/core/utils/clientServices';
import { useSetRecoilState } from 'recoil';

const QueueStateConnector: React.FC = () => {
  const { clientServices, loading } = useClientServices();
  const setHadExpiredItems = useSetRecoilState(queueHadExpiredItemsState);
  const setIsLoading = useSetRecoilState(queueIsLoadingState);
  const setLastUpdate = useSetRecoilState(queueLastUpdateState);
  const setQueueState = useSetRecoilState(queueState);

  useEffect(() => {
    if (!loading) {
      const updateQueueState = ({ currentId, items, itemsRemoved }: IQueue): void => {
        setQueueState({
          currentId: currentId ?? '',
          items: items.map((item) => wrapQueueItem(item)),
        });
        setHadExpiredItems(!!itemsRemoved);
        setLastUpdate(Date.now());
        setIsLoading(false);
      };

      clientServices.getPersonalizationServices().addQueueListener(updateQueueState);

      return () =>
        clientServices.getPersonalizationServices().removeQueueListener(updateQueueState);
    }
  }, [clientServices, loading, setHadExpiredItems, setIsLoading, setLastUpdate, setQueueState]);

  return null;
};

export default QueueStateConnector;
