import React, { CSSProperties, useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import DescriptionWrapper from '~/components/DescriptionWrapper';
import { Icons } from '~/components/Icon/constants';
import Link from '~/components/Link';
import { TStyles } from '~/types/emotion-styles';
import { LinkType } from '~/types/links';

import styles from './styles';

export enum MaskImageDirection {
  ToLeft = 'to left',
  ToRight = 'to right',
  ToBottom = 'to bottom',
  ToTop = 'to top',
}

interface IProps {
  containerCss?: TStyles;
  displayShowMore?: boolean;
  numberOfLines?: number;
  shouldStripTags?: boolean;
  shouldStripInteractiveTags?: boolean;
  shouldShowMaskImage?: boolean;
  text: string;
  textCss?: TStyles;
  textId?: string;
  maskImageDirection?: MaskImageDirection;
}

const TruncatedDescription: React.FC<IProps> = ({
  containerCss,
  displayShowMore = true,
  numberOfLines = 3,
  shouldStripTags = false,
  shouldStripInteractiveTags = false,
  shouldShowMaskImage = false,
  text,
  textCss,
  textId = 'truncated-desc',
  maskImageDirection = MaskImageDirection.ToTop,
}): JSX.Element => {
  const [needsTruncation, setNeedsTruncation] = useState(false);
  const [isTruncated, setIsTruncated] = useState(true);

  // Because of the App loading state, the first time the component renders
  // the container node height does so without registering a height value.
  // To solve this, we set a one-time resize listener, that determines if we need
  // to truncate the content/display the "show more" button, once the container
  // renders with height.
  const descRef = useCallback((node: HTMLDivElement): void => {
    const resizeObserver = new ResizeObserver(() => {
      // Compare scrollHeight & clientHeight to determine if line clamp is in effect
      // on initial container node render
      if (node.scrollHeight && node.clientHeight) {
        setNeedsTruncation(node.scrollHeight !== node.clientHeight);
        resizeObserver.disconnect();
      }
    });
    if (node) {
      resizeObserver.observe(node);
    }
  }, []);

  const onToggleText = (): void => {
    setIsTruncated((prev) => !prev);
  };

  const { t } = useTranslation();

  const READ_MORE = t('collapsible.readMore');
  const READ_LESS = t('collapsible.readLess');

  const showHideLabel = isTruncated ? READ_MORE : READ_LESS;

  const VISUALLY_EXPAND = t('collapsible.visuallyExpand');
  const VISUALLY_SHRINK = t('collapsible.visuallyShrink');

  const showHideAriaLabel = isTruncated ? VISUALLY_EXPAND : VISUALLY_SHRINK;

  const oneLineHeightAsPercent = (1 / numberOfLines) * 100;
  const truncatedCss = [];
  const truncatedStyle: CSSProperties = {};

  const isExpandable = displayShowMore && needsTruncation;

  const shouldStripInteractiveTagsExpr = !isExpandable
    ? false
    : shouldStripInteractiveTags && isTruncated;

  if (isTruncated) {
    // isTruncated defaults to true, so line-clamp property is applied on render.
    // this is required so that we can determine whether we need to also display the
    // "show more" button
    truncatedCss.push(styles.truncated);
    truncatedStyle.WebkitLineClamp = numberOfLines;

    if (isExpandable || shouldShowMaskImage) {
      truncatedCss.push(styles.masked);
      truncatedStyle.WebkitMaskImage = `linear-gradient(${maskImageDirection}, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) ${oneLineHeightAsPercent}%)`;
    }
  }

  return (
    <div css={containerCss}>
      <div css={truncatedCss} id={textId} ref={descRef} style={truncatedStyle}>
        <DescriptionWrapper
          description={text}
          shouldStripInteractiveTags={shouldStripInteractiveTagsExpr}
          shouldStripTags={shouldStripTags && isTruncated}
          textCss={textCss}
        />
      </div>
      {isExpandable && (
        <div css={styles.toggleButton}>
          <Link
            as={LinkType.Button}
            ariaExpanded={!isTruncated}
            ariaControls={textId}
            ariaLabel={showHideAriaLabel}
            hasIconAfter
            icon={isTruncated ? Icons.CaretDown : Icons.CaretUp}
            isTiny
            onClick={onToggleText}
          >
            {showHideLabel}
          </Link>
        </div>
      )}
    </div>
  );
};

export default TruncatedDescription;
