import { useEffect, useRef } from 'react';

import { nowPlaying } from '@audacy-clients/core/atoms/player';
import { EntityImageShape } from '@audacy-clients/core/atoms/wrappers/modules';
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import { useRecoilValue } from 'recoil';

import Button from '~/components/Button';
import Btn from '~/components/Link';
import { useBreakpoints } from '~/hooks/use-breakpoints';
import { TStyles } from '~/types/emotion-styles';
import { LinkType } from '~/types/links';
import { reduceVariantMotion } from '~/utils/animation';
import { getFocusableChildren } from '~/utils/dom';

import { controlsVariants } from './animation';
import CardList from './CardList';
import { ICardListItem } from './CardListElement';
import styles from './styles';

export interface IToolbarAction {
  label: string;
  onClick: () => void;
}
export interface ICardListViewProps<T> {
  bulkEditActionButton?: IToolbarAction;
  containerCss?: TStyles;
  entityImageShape?: EntityImageShape;
  hadExpiredItems?: boolean;
  headerCss?: TStyles;
  isEditable?: boolean;
  isEditing?: boolean;
  isPlayerQueue?: boolean;
  isSortable?: boolean;
  items: T[];
  itemType: string;
  listCss?: TStyles;
  nowPlayingItemId?: string;
  scrollClass?: string;
  selectButtons?: IToolbarAction[];
  selectedItems?: string[];
  shouldForceFocus?: boolean;
  shouldShowEntityType?: boolean;
  title?: string;
  toggleEditModeButton?: IToolbarAction;
  onItemClick?: (id: string) => void;
  onItemHrefClick?: (id: string) => void;
  onItemToggleSelected: (id: string) => void;
  onSortEnd?: (items: T[]) => void;
  renderEmptyMessage: () => string | JSX.Element;
  renderEntityRightColumn?: (contentId: string) => JSX.Element;
  renderListLabel?: (items: T[]) => JSX.Element | string;
}

const CardListView = <T extends ICardListItem>({
  containerCss,
  headerCss,
  listCss,
  nowPlayingItemId,
  items,
  selectedItems,
  isEditable,
  isEditing,
  shouldForceFocus,
  selectButtons,
  bulkEditActionButton,
  toggleEditModeButton,
  title,
  renderListLabel,
  renderEmptyMessage,
  onItemToggleSelected,
  scrollClass,
  onItemHrefClick,
  hadExpiredItems,
  ...rest
}: ICardListViewProps<T>): JSX.Element => {
  const shouldReduceMotion = useReducedMotion();
  const listEl = useRef<HTMLDivElement>(null);
  const isEmpty = items.length === 0;
  const isEditMode = !!(isEditable && isEditing);
  const hasTitle = !!title;
  const breakpoint = useBreakpoints();
  const hasPlayer = useRecoilValue(nowPlaying);

  useEffect(() => {
    if (shouldForceFocus && listEl.current) {
      const focusable = getFocusableChildren(listEl.current);
      if (focusable.length > 0 && focusable[0] instanceof HTMLElement) {
        focusable[0].focus();
      }
    }
  }, [shouldForceFocus]);

  const metadataElement = renderListLabel ? (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={reduceVariantMotion(controlsVariants, shouldReduceMotion)}
      css={styles.metadata}
    >
      {renderListLabel(items)}
    </motion.div>
  ) : null;
  const shouldShowBulkEditButton =
    bulkEditActionButton && isEditMode && !!selectedItems && !!selectedItems.length;
  const shouldShowToggleEditModeButton = !!toggleEditModeButton && !!isEditable;
  const shouldShowEditActions = shouldShowBulkEditButton || shouldShowToggleEditModeButton;
  const editActionsElement = shouldShowEditActions ? (
    <div css={styles.headerCtas}>
      {shouldShowBulkEditButton && (
        <Button
          label={bulkEditActionButton.label}
          onClick={bulkEditActionButton.onClick}
          css={breakpoint.lessThan.LG && hasPlayer ? styles.editCtaPlayerVisible : styles.editCta}
          size={breakpoint.is.SM ? 'Default' : 'Small'}
          theme="White"
        />
      )}
      {shouldShowToggleEditModeButton && (
        <Button
          label={toggleEditModeButton.label}
          onClick={toggleEditModeButton.onClick}
          size="Small"
          theme="Secondary"
        />
      )}
    </div>
  ) : null;

  const headerElement = (
    <header css={[styles.header, headerCss]}>
      <h2 css={shouldShowBulkEditButton ? styles.titleGradient : styles.title}>{title}</h2>
      {!isEmpty && editActionsElement}
    </header>
  );
  const shouldRenderEditSelectors = isEditMode && !!selectButtons && !!selectButtons.length;
  const editSelectorsElement = (shouldRenderEditSelectors || metadataElement) && (
    <div key="toolbar" css={hadExpiredItems ? undefined : styles.editSelectorsElement}>
      {shouldRenderEditSelectors ? (
        <motion.ul
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={reduceVariantMotion(controlsVariants, shouldReduceMotion)}
          css={styles.editSelectors}
        >
          {selectButtons?.map(({ label, onClick }, index) => (
            <li key={`${label}_${index}`} css={styles.editSelector}>
              <Btn as={LinkType.Button} isTiny onClick={onClick}>
                {label}
              </Btn>
            </li>
          ))}
        </motion.ul>
      ) : (
        metadataElement
      )}
    </div>
  );

  if (isEmpty) {
    const emptyMessage = renderEmptyMessage();
    return (
      <section css={[styles.container, containerCss]}>
        {hasTitle && headerElement}
        {typeof emptyMessage === 'string' ? (
          <span css={styles.emptyMessage}>{renderEmptyMessage()}</span>
        ) : (
          emptyMessage
        )}
      </section>
    );
  }

  const shouldShowToolbar = !!editSelectorsElement || !!editActionsElement;
  const toolbar = !hasTitle && shouldShowToolbar && (
    <div css={styles.toolbar}>
      <AnimatePresence initial={false}>
        {editSelectorsElement}
        {editActionsElement}
      </AnimatePresence>
    </div>
  );

  return (
    <section css={[styles.container, containerCss]}>
      {hasTitle ? headerElement : toolbar}
      <motion.div
        layoutScroll
        css={[hasTitle && styles.contentWithMediumPadding, listCss]}
        ref={listEl}
        className={scrollClass}
      >
        {hasTitle && <AnimatePresence initial={false}>{editSelectorsElement}</AnimatePresence>}
        <div css={[toolbar && styles.listWithToolbar, hasTitle && styles.listWithoutToolbar]}>
          <CardList
            {...rest}
            isEditMode={isEditMode}
            nowPlayingItemId={nowPlayingItemId}
            onToggleSelected={onItemToggleSelected}
            items={items}
            selectedItems={selectedItems || []}
            onItemHrefClick={onItemHrefClick}
          />
        </div>
      </motion.div>
    </section>
  );
};

export default CardListView;
