import { useTranslation } from 'react-i18next';

import { TStyles } from '~/types/emotion-styles';
import { TButtonRef } from '~/types/links';

import styles from './styles';

interface IMenuButtonProps {
  ariaHidden?: boolean;
  containerCss?: TStyles;
  containerRef?: TButtonRef;
  isMenuOpen?: boolean;
  onClick: () => void;
  tabIndex?: number;
}

const MenuButton = ({
  ariaHidden,
  containerCss,
  containerRef,
  isMenuOpen,
  onClick,
  tabIndex = 0,
}: IMenuButtonProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <button
      aria-expanded={isMenuOpen}
      aria-haspopup
      aria-hidden={ariaHidden}
      aria-label={isMenuOpen ? t('global.hamburger.close') : t('global.hamburger.open')}
      css={[styles.container, containerCss]}
      onClick={onClick}
      ref={containerRef}
      tabIndex={tabIndex}
    >
      <span css={[styles.line, isMenuOpen && styles.lineClosed]} />
      <span css={[styles.line, isMenuOpen && styles.lineClosed]} />
    </button>
  );
};

export default MenuButton;
