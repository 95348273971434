import { IEpisode } from '@audacy-clients/core/atoms/wrappers/types';
import { useTranslation } from 'react-i18next';

import EpisodeItem from '~/components/EpisodeItem';
import SectionWrapper from '~/components/SectionWrapper';

interface IPreviousEpisodeProps {
  episode: IEpisode;
  isShowLive?: boolean;
  onPlayClick: (id?: string) => void;
}

const PreviousEpisode = ({
  episode,
  isShowLive,
  onPlayClick,
}: IPreviousEpisodeProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <SectionWrapper
      title={
        isShowLive
          ? t('showScreen.previousEpisode.header.previous')
          : t('showScreen.previousEpisode.header.latest')
      }
      content={<EpisodeItem episode={episode} onPlayClick={onPlayClick} />}
    />
  );
};

export default PreviousEpisode;
