import DataObject from './DataObject';
import { ObjectType } from '../Constants';

/**
 * @module Collection
 */
export default class Collection extends DataObject {
  /**
   * Creates a new instance of a Collection.
   * @param {CollectionJson} data
   */
  constructor(data) {
    super(ObjectType.COLLECTION, data);
  }

  /**
   * Get the collection author.
   * @returns {String}
   */
  getAuthor() {
    return this.data.author;
  }

  /**
   * Get the episode duration.
   * @returns {Number}
   */
  getDuration() {
    return this?.data?.durationSeconds || 0;
  }

  /**
   * Get the image metadata associated with the entity.
   * @returns {{ [key: string]: any }} data
   */
  getImageMetadata() {
    return this.data.imageMetadata;
  }

  /**
   * Get the text description of the image associated with the entity.
   * @returns {String}
   */
  getImageAlt() {
    return this.data.images && this.data.images.alt;
  }

  /**
   * Get the hero image.
   * @returns {String}
   */
  getImageHero() {
    return this.data.images && this.data.images.hero;
  }

  /**
   * Get the hero image attribution.
   * @returns {String}
   */
  getImageHeroAttribution() {
    return this.data.images && this.data.images.heroAttribution;
  }

  /**
   * Get the square image.
   * @returns {String}
   */
  getImageSquare() {
    return this.data.images && this.data.images.square;
  }

  /**
   * Get the square image attribution.
   * @returns {String}
   */
  getImageSquareAttribution() {
    return this.data.images && this.data.images.squareAttribution;
  }

  /**
   * Get the array of content ids.
   * @returns {Array<String>}
   */
  getItems() {
    return this.data.itemsIdList;
  }

  /**
   * Get the content updated time.
   * @returns {String}
   */
  getUpdatedTime() {
    return this.data.contentUpdated;
  }

  /**
   * Get the content updated date.
   * @returns {String}
   */
  getUpdatedDate() {
    return this.data.updateDate;
  }

  /**
   * @returns {Array<String>}
   */
  getItemsIdList() {
    return this.data.itemsList;
  }
}
