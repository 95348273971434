import { useEffect, useState } from 'react';

import random from 'lodash/random';

import { Colors, Mixins } from '~/styles';

import { LineWidth } from './constants';
import styles, { lineWidthStyles, randomLineStyles } from './styles';

interface IBackgroundProps {
  colors?: [string, string];
  lineWidth?: LineWidth;
}

const Background = ({
  colors = [Colors.secondaryDusk2, Colors.white],
  lineWidth = LineWidth.Thin,
}: IBackgroundProps): JSX.Element => {
  const [randomVals, setRandomVals] = useState<number[]>();

  useEffect(() => {
    /* Generate random values to make the lines look & animate differently */
    const newVals = [...Array(4)];
    newVals.forEach((_, idx) => {
      newVals[idx] = random(0.5, 2, true);
    });

    setRandomVals(newVals);
  }, []);

  return (
    <div css={styles.container}>
      <div css={styles.gradient}>
        <div css={styles.gradientColor}>
          <div style={Mixins.backgroundGradientStyle(colors[0], 0.7, 200)} />
        </div>
        <div css={styles.gradientColor}>
          <div style={Mixins.backgroundGradientStyle(colors[1], 0.3, 220)} />
        </div>
      </div>
      <div css={styles.lines}>
        {randomVals?.map((val, idx) => (
          <div css={[styles.line, lineWidthStyles(lineWidth), randomLineStyles(val)]} key={idx} />
        ))}
      </div>
    </div>
  );
};

export default Background;
