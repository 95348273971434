import { useTranslation } from 'react-i18next';

import { TStyles } from '~/types/emotion-styles';

import styles from './styles';

interface IHighlightTextProps {
  text: string;
  containerCss?: TStyles;
}

const HighlightText = ({ text, containerCss }: IHighlightTextProps): JSX.Element => {
  const { t } = useTranslation();

  return <span css={[styles.highlightText, containerCss]}>{t(text)}</span>;
};

export default HighlightText;
