import React from 'react';

import { ClickFeature } from '@audacy-clients/client-services/core';
import { EntityImageShape } from '@audacy-clients/core/atoms/wrappers/modules';
import { IContentSummary } from '@audacy-clients/core/atoms/wrappers/types';

import HorizontalEntityCard from '~/components/Entity/HorizontalEntityCard';

interface IPlaylistItemProps {
  item: IContentSummary;
  playlistId: string;
}

const playContext = { feature: ClickFeature.PLAYLIST };

const PlaylistItem = ({ item, playlistId }: IPlaylistItemProps): JSX.Element => {
  return (
    <HorizontalEntityCard
      {...item}
      collectionId={playlistId}
      href={item.url}
      imageURIBase={item.image ?? item.parentImage}
      entityImageShape={EntityImageShape.Square}
      contentId={item.id}
      entitySubtype={item.entitySubtype}
      entityType={item.entityType}
      playContext={playContext}
      isPlayable
    />
  );
};

export default PlaylistItem;
