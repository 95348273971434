import Episode from './Episode';
import { ObjectType } from '../Constants';
import Container from '../Container';
import Show from './Show';
import SimpleObject from './SimpleObject';
import Song from './Song';
import Station from './Station';
import Tag from './Tag';
import clientServicesConfig from '../Config';
import StandaloneChapter from './StandaloneChapter';

const logger = Container;

/**
 * @module List
 */
export default class List extends SimpleObject {
  /**
   * Creates a new instance of a List.
   * @param {String}       version
   * @param {Object}       data
   * @param {DataServices} dataService
   * @param {ObjectType}   type
   */
  constructor(version, data, dataService, type) {
    super(type);
    this.version = version;
    this.data = data ? data : [];
    this.dataService = dataService;
  }

  getAllObjects() {
    const retval = [];

    for (let i = 0; i < this.data.length; i++) {
      const obj = this.getObject(i);

      if (obj) retval.push(obj);
    }

    return retval;
  }

  /**
   * Returns a data object corresponding to the specified index.
   * @param {Number} index
   * @returns {Episode|Show|Station|Song|Tag|StandaloneChapter}
   */
  getObject(index) {
    let retval;

    switch (this.getObjectType()) {
      case ObjectType.EPISODE_LIST:
        retval = this.data[index] ? new Episode(this.data[index]) : undefined;
        break;

      case ObjectType.SEARCH_RESULT_LIST:
        if (this.data[index].entityType === 'SHOW') retval = new Show(this.data[index]);
        else if (this.data[index].entityType === 'STATION') retval = new Station(this.data[index]);
        else if (this.data[index].entityType === 'EPISODE') retval = new Episode(this.data[index]);
        else if (this.data[index].entityType === 'SONG') retval = new Song(this.data[index]);
        else if (this.data[index].entityType === 'TAG') retval = new Tag(this.data[index]);
        break;

      case ObjectType.SHOW_LIST:
        retval = new Show(this.data[index]);
        break;

      case ObjectType.SONG_LIST:
        retval = new Song(this.data[index]);
        break;

      case ObjectType.STATION_LIST:
        retval = new Station(this.data[index]);
        break;

      case ObjectType.TAG:
        retval = new Tag(this.data[index]);
        break;

      case ObjectType.STANDALONE_CHAPTER:
        retval = new StandaloneChapter(this.data[index]);

      default:
        logger.error('Unknown data object type = ' + this.getObjectType());
        clientServicesConfig.ddError(`Unknown data object type: ${this.getObjectType()}`);
        break;
    }

    return retval;
  }

  /**
   * Get the episode name for specified index.
   * @param   {number} index
   * @returns {string}
   */
  getName(index) {
    let retval;

    switch (this.version) {
      case 'v1':
        retval = this.data[index].attributes.title;
        break;

      case 'v2':
        retval = this.data[index].title;
        break;

      case 'v3':
        retval = this.data[index].title;
        break;
    }

    return retval;
  }

  /**
   * Get the number if elements in the list.
   * @returns {Number}
   */
  getNum() {
    return this.data.length;
  }
}
