import { BP } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

export const bottomOptionsHeight = {
  [BP.Small]: 54,
  [BP.Medium]: 90,
};

const styles: TStyleSheet = {
  container: {
    display: 'grid',
    gridTemplateColumns: '[start] 40px [queue-button] auto [more-button] 50px [end]',
    alignItems: 'center',
    justifyItems: 'center',
  },
  middleOptions: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    gridColumnStart: 'queue-button',
    gridColumnEnd: 'more-button',
  },
};

export default styles;
