import { Children, cloneElement, ReactElement, useMemo } from 'react';

import { SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types';

import { TStyles } from '~/types/emotion-styles';

import CarouselView from './components/CarouselView';

interface IProps {
  ariaLabel?: string;
  children: ReactElement[];
  carouselViewCss?: TStyles;
  swiperParams?: SwiperOptions;
}

const Carousel = ({ ariaLabel, children, carouselViewCss, swiperParams }: IProps): JSX.Element => {
  const items = useMemo(() => {
    const mapItem = (child: ReactElement, idx: number): ReactElement => {
      return (
        <SwiperSlide
          key={idx}
          role="group"
          aria-label={`Slide ${idx + 1} of ${children.length}`}
          virtualIndex={idx}
        >
          {cloneElement(child)}
        </SwiperSlide>
      );
    };

    return Children.map(children, mapItem);
  }, [children]);

  return (
    <CarouselView
      ariaLabel={ariaLabel}
      swiperParams={swiperParams}
      carouselViewCss={carouselViewCss}
    >
      {items}
    </CarouselView>
  );
};

export default Carousel;
