export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Email</title>
    <path
      d="m3.61 5.917 12.835 11.916L29.277 5.918"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path stroke="currentColor" strokeWidth="1.5" d="M3.75 5.75h25.389v20.5H3.75z" />
  </svg>
);
