export default (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>X logo</title>
    <path
      d="M 17.640625 14.921875 L 25.078125 6.25 L 23.316406 6.25 L 16.859375 13.78125 L 11.699219 6.25 L 5.75 6.25 L 13.550781 17.636719 L 5.75 26.730469 L 7.511719 26.730469 L 14.332031 18.777344 L 19.78125 26.730469 L 25.730469 26.730469 Z M 15.226562 17.738281 L 14.4375 16.601562 L 8.148438 7.582031 L 10.855469 7.582031 L 15.929688 14.863281 L 16.71875 15.996094 L 23.316406 25.460938 L 20.609375 25.460938 Z M 15.226562 17.738281 "
      fill="currentColor"
    />
  </svg>
);
