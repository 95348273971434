export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Pause</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 5H7v22h7V5Zm11 0h-7v22h7V5Z"
      fill="currentColor"
    />
  </svg>
);
