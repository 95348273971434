import { IPlayContext } from '@audacy-clients/core/hooks/useAudioHelpers';

import { usePlayableCardProps } from '~/components/Entity/HorizontalEntityCard/hooks/use-playable-card-props';
import { IEntitySummaryProps, IHorizontalEntityProps } from '~/components/Entity/types';
import { usePlayOrPause } from '~/utils/hooks/usePlayOrPause';

import { useEyebrowText } from './hooks/use-eyebrow-text';
import HorizontalEntityCard from './HorizontalEntityCard';

const HorizontalEntityCardWithState = ({
  isPlayable,
  contentId = '',
  entitySubtype,
  entityType,
  isEditMode,
  isEditModeSelected,
  isPlayerQueue,
  isRewindable = true,
  startDateTime,
  duration,
  clickFeature = undefined,
  onPlayLiveItem,
  parentId,
  href: url,
  shouldShowEntityType = true,
  playContext,
  renderEntityRightColumn,
  collectionId,
  ...props
}: IHorizontalEntityProps & IEntitySummaryProps & { playContext: IPlayContext }): JSX.Element => {
  const playOrPause = usePlayOrPause(contentId, collectionId);

  const {
    href,
    isExclusiveStation,
    isLive,
    isPlayed,
    isPlaying,
    liveUntil,
    onClick,
    onPlayStateClick,
    resumePoint,
    isScrubbing,
    nowPlayingDuration,
    isPlayList,
  } = usePlayableCardProps({
    isLive: props.isLive,
    isPlayable,
    isPlayerQueue,
    item: {
      id: contentId,
      entityType,
      entitySubtype,
      startDateTime,
      duration,
      url,
    },
    playContext,
    onPlayLiveItem,
    parentId,
    title: props.title,
    collectionId,
    playOrPause,
  });
  const eyebrowText = useEyebrowText({ entitySubtype, ...props }, shouldShowEntityType);

  const entityRightColumn = renderEntityRightColumn
    ? renderEntityRightColumn(contentId)
    : undefined;

  return (
    <HorizontalEntityCard
      {...props}
      isPlayList={isPlayList}
      clickFeature={clickFeature}
      href={href}
      isEditMode={isEditMode}
      isExclusiveStation={isExclusiveStation}
      isLive={props.isLive || isLive}
      isPlayed={isPlayed}
      isPlaying={isPlaying}
      liveUntil={props.liveUntil || liveUntil}
      isEditModeSelected={isEditModeSelected}
      onClick={props.onClick || onClick}
      onPlayStateClick={props.onPlayStateClick || onPlayStateClick}
      resumePoint={resumePoint}
      duration={isPlaying ? nowPlayingDuration : duration}
      entitySubtype={entitySubtype}
      eyebrow={eyebrowText}
      isScrubbing={isScrubbing}
      entityType={entityType}
      isPlayable={isPlayable}
      entityRightColumn={entityRightColumn}
      isRewindable={isRewindable}
    />
  );
};

export default HorizontalEntityCardWithState;
