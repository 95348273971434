import {
  EntitySubtype,
  EpisodeSubType,
  ShowSubtype,
  StationSubType,
  TagSubtype,
} from '@audacy-clients/client-services/core';
import { useTranslation } from 'react-i18next';

import { TModuleFeaturedCardProps } from '~/components/Card';
import { usePlayableCardProps } from '~/components/Entity/HorizontalEntityCard/hooks/use-playable-card-props';
import { INowPlaying } from '~/components/Entity/types';
import { useAudioHelpersWeb } from '~/hooks/use-audio-helpers-web';
import useToast from '~/state/toast';
import { usePlayOrPause } from '~/utils/hooks/usePlayOrPause';

import FeaturedCard from './FeaturedCard';

export interface IFeaturedCardProps {
  ctaHref?: string;
  duration?: number;
  entitySubtype?: EntitySubtype;
  entityURL?: string;
  eyebrow?: string | string[];
  forceVertical?: boolean;
  hideThumbnail?: boolean;
  highlightText?: string;
  imageAltText?: string;
  imageURIBase?: string;
  isExclusiveStation?: boolean;
  isExternalUrl?: boolean;
  isLive?: boolean;
  isNowPlaying?: boolean;
  isPlayable?: boolean;
  isPlaying?: boolean;
  isScrubbing?: boolean;
  linkText?: string;
  liveUntil?: INowPlaying;
  onPlayStateClick?: (id?: string) => void;
  publishDate?: Date;
  replayableUntilDateTime?: string;
  resumePoint?: number;
  subtitle?: string;
  thumbnailAltText?: string;
  thumbnailURI?: string;
  title: string;
}

const FeaturedCardWithState = ({
  contentId = '',
  playContext,
  entityType,
  entitySubtype,
  replayableUntilDateTime,
  ...props
}: TModuleFeaturedCardProps): JSX.Element => {
  const { showToast } = useToast();
  const audioHelpers = useAudioHelpersWeb();
  const { t } = useTranslation();
  const playOrPause = usePlayOrPause(contentId);

  const onPlayLiveItem = () => {
    if (replayableUntilDateTime && new Date(replayableUntilDateTime) > new Date()) {
      audioHelpers.playFromId(contentId, playContext);
      // need if else here for live shows
    } else {
      showToast({
        title: t('featuredCard.expiredError'),
      });
    }
  };

  const playableProps = usePlayableCardProps({
    isLive: props.isLive,
    isPlayable: props.isPlayable,
    item: { id: contentId, entityType, entitySubtype, duration: props.duration },
    playContext,
    onPlayLiveItem:
      entitySubtype === EpisodeSubType.BROADCAST_SHOW_EPISODE ? onPlayLiveItem : undefined,
    playOrPause,
  });

  const getLinkText = (type?: EntitySubtype) => {
    switch (type) {
      case EpisodeSubType.PODCAST_EPISODE:
      case EpisodeSubType.BROADCAST_SHOW_EPISODE:
        return t('featuredCard.goToEpisodePage');
      case ShowSubtype.PODCAST:
        return t('featuredCard.goToPodcastPage');
      case ShowSubtype.BROADCAST:
        return t('featuredCard.goToShowPage');
      case StationSubType.BROADCAST:
      case StationSubType.EXCLUSIVE:
        return t('featuredCard.goToStationPage');
      case TagSubtype.LEAGUE:
        return t('featuredCard.goToLeaguePage');
      case TagSubtype.TEAM:
        return t('featuredCard.goToTeamPage');
      case TagSubtype.GENRE:
        return t('featuredCard.goToGenrePage');
      default:
        return t('featuredCard.goToPage', {
          text: type?.toLowerCase().split('_').join(' '),
        });
    }
  };

  const ctaProps =
    !props.isPlayable && !playableProps.isExclusiveStation
      ? {
          ctaHref: props.ctaHref || props.entityURL,
          linkText: props.linkText || getLinkText(entitySubtype),
        }
      : {};

  return <FeaturedCard {...props} {...playableProps} {...ctaProps} />;
};

export default FeaturedCardWithState;
