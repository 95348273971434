import Collection from './Collection';
import Episode from './Episode';
import Show from './Show';
import Station from './Station';
import Song from './Song';
import Tag from './Tag';
import Topic from './Topic';

/**
 * @module SearchResultList
 */
export default class SearchResultList {
  /**
   * Creates a new instance of a SearchResultList.
   * @param {String}        version
   * @param {Object}        result
   * @param {Number}       [result.code]
   * @param {Number}        result.page
   * @param {Number}        result.total
   * @param {Array}         result.results
   * @param {Number}        result.results[].duration
   * @param {String}        result.results[].entitySubtype
   * @param {String}        result.results[].entityType
   * @param {String}        result.results[].id
   * @param {String}        result.results[].parentId
   * @param {String}        result.results[].publishDate
   * @param {String}        result.results[].title
   * @param {DataServices}  dataService
   * @param {String}        searchStr
   */
  constructor(version, result, dataService, searchStr) {
    this.version = version;
    this.dataService = dataService;
    this.searchStr = searchStr;
    this.data = [];
    this.pageMap = [];

    if (result && result.code >= 400) result = undefined;

    if (result) {
      this.pageSize = 20; // TODO: [CCS-2793] replace with result.pageSize when available
      this.total = result.total;

      const page = result.page;
      const array = result.results;
      const index = page * this.pageSize;

      this.pageMap[page] = true;

      for (let i = 0; i < array.length; i++) this.data[index + i] = array[i];
    }
  }

  createObject(data) {
    let retval;

    if (data) {
      if (data.entityType === 'SHOW') retval = new Show(data);
      else if (data.entityType === 'STATION') retval = new Station(data);
      else if (data.entityType === 'EPISODE') retval = new Episode(data);
      else if (data.entityType === 'SONG') retval = new Song(data);
      else if (data.entityType === 'TAG') retval = new Tag(data);
      else if (data.entityType === 'COLLECTION') retval = new Collection(data);
      else if (data.entityType === 'TOPIC') retval = new Topic(data);
    }

    return retval;
  }

  /**
   * Get the number of search results.
   * @returns {Number}
   */
  getNum() {
    return this.total;
  }

  /**
   * Get the number of search results.
   * @returns {Number}
   */
  getNumInCore() {
    let retval = 0;

    for (let i = 0; i < this.data.length; i++) if (this.data[i] !== undefined) retval++;

    return retval;
  }

  /**
   * @param {Number} index
   * @returns {Promise<Episode|Show|Station|Song|Tag>}
   */
  getObject(index) {
    const resolver = (resolve) => {
      const item = this.data[index];

      if (item === undefined) {
        const page = (index / this.pageSize) | 0;

        this.dataService.search(this.searchStr, undefined, undefined, page).then((result) => {
          this.patchDataSet(result);
          resolve(this.createObject(this.data[index]));
        });
      } else resolve(this.createObject(item));
    };

    return new Promise(resolver);
  }

  /**
   * @param {Number} index
   * @returns {Episode|Show|Station|Song|Tag}
   */
  getObjectSync(index) {
    return this.createObject(this.data[index]);
  }

  patchDataSet(list) {
    if (list) {
      const page = list.pageMap.indexOf(true);
      const array = list.data;
      const index = page * this.pageSize;

      this.total = list.total;
      this.pageMap[page] = true;

      for (let i = index; i < array.length; i++) this.data[i] = array[i];
    }
  }

  /**
   * @param {Number} startIndex
   * @param {Number} endIndex
   * @returns {Promise}
   */
  prefetch(startIndex, endIndex) {
    const needMap = [];
    const promises = [];

    for (let i = startIndex; i <= endIndex; i++) {
      const page = (i / this.pageSize) | 0;

      if (needMap.indexOf(page) === -1 && this.pageMap[page] !== true) {
        needMap.push(page);
        promises.push(this.dataService.search(this.searchStr, undefined, undefined, page));
      }
    }

    return Promise.all(promises).then((lists) => {
      for (let i = 0; i < lists.length; i++) this.patchDataSet(lists[i]);
    });
  }
}
