import IDataStore from '../IDataStore';

/**
 * A class for storing and retrieving data in the browser's local/session storage
 * @implements IDataStore
 */
export default class HtmlDataStore implements IDataStore {
  /**
   * Clear the data for a given key in local storage
   * @param key - The key for the data to be cleared
   * @param storage - The storage object for which it is used
   */
  async clearData(key: string, storage: Storage = localStorage) {
    storage.removeItem(key);
  }

  /**
   * Get the data for a given key in local storage
   * @param key - The key for the data to be retrieved
   * @param storage - The storage object for which it is used
   * @returns A promise that resolves to the data, or undefined if the data is not found or invalid
   */
  getData<T>(key: string, storage: Storage = localStorage): Promise<T | undefined> {
    return Promise.resolve(this.getDataSync(key, storage));
  }

  getDataSync<T>(key: string, storage: Storage = localStorage): T | undefined {
    const obj = storage.getItem(key);
    try {
      return obj ? JSON.parse(obj) : undefined;
    } catch {
      return undefined;
    }
  }

  /**
   * Set the data for a given key in local storage
   * @param key - The key for the data to be set
   * @param val - The value to be set
   * @param storage - The storage object for which it is used
   */
  async setData<T>(key: string, val: T, storage: Storage = localStorage) {
    this.setDataSync(key, val, storage);
  }

  setDataSync<T>(key: string, val: T, storage: Storage = localStorage) {
    storage.setItem(key, JSON.stringify(val));
  }
}
