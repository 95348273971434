import { Icons } from '~/components/Icon/constants';
import { TStyles } from '~/types/emotion-styles';
import { IGlobalAsAnchorProps, IGlobalAsButtonProps } from '~/types/links';

export enum ButtonType {
  Button = 'button',
  Submit = 'submit',
}

export type TTagTheme = 'Secondary' | 'White';
export type TButtonTheme = TTagTheme | 'Premium' | 'Primary' | 'SecondaryPremium';
export type TButtonSize = 'Default' | 'Extended' | 'Large' | 'Small';

export interface IButtonProps {
  buttonCss?: TStyles;
  icon?: Icons;
  label: string;
  labelCss?: TStyles;
  size?: TButtonSize;
  theme?: TButtonTheme;
}

export interface IButtonAsAnchorProps extends IGlobalAsAnchorProps, IButtonProps {}
export interface IButtonAsButtonProps extends IGlobalAsButtonProps, IButtonProps {}
export type TButtonProps = IButtonAsAnchorProps | IButtonAsButtonProps;
