'use strict';

import AudacyError from './AudacyError';
import { CLIENT_SIDE_TIMEOUT } from './Constants';

/**
 * Add parameters to a url.
 * @param {String} url
 * @param {Array<String>} params
 * @returns {String}
 */
export function appendUrlParameters(url, params) {
  let retval = url;

  if (url) {
    const needsInitial = url.indexOf('?') === -1;

    for (let i = 0; i < params.length; i++)
      if (params[i].length > 0)
        retval += (i === 0 && needsInitial === true ? '?' : '&') + params[i];
  }

  return retval;
}

export function isDesktopSafari() {
  const uA = navigator.userAgent;
  const { vendor } = navigator;
  if (/Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA)) {
    // Desktop Safari
    return true;
  }
  return false;
}

export function isIpad() {
  return Boolean(navigator?.userAgent?.match(/iPad/i));
}

export function isMobileIOSDevice() {
  return Boolean(navigator?.userAgent?.match(/iPhone/i) || navigator?.userAgent?.match(/iPod/i));
}

export function createSimpleDateTimeString(time, onlyHoursMinSec = false) {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const padDigit = (num) => (num < 10 ? '0' : '') + num;
  const date = new Date(time);
  const month = months[date.getUTCMonth()];
  const dayName = padDigit(date.getUTCDate());
  const day = days[date.getUTCDay()];
  const hour = padDigit(date.getHours());
  const min = padDigit(date.getMinutes());
  const sec = padDigit(date.getSeconds());

  return (onlyHoursMinSec === false ? `${month} ${dayName} ${day} ` : '') + `${hour}:${min}:${sec}`;
}

/**
 * Creates a new uuid.
 * @returns {String}
 */
export function createUuid() {
  let now = Date.now();

  const replace = function (formatStr) {
    const digit = (now + Math.random() * 16) % 16 | 0;

    now = Math.floor(now / 16);

    return (formatStr === 'x' ? digit : (digit & 0x3) | 0x8).toString(16);
  };

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, replace);
}

/**
 * Adapted from https://geraintluff.github.io/sha256/
 *
 * @param {String} ascii
 * @returns {String}
 */
export function sha256(ascii) {
  const rightRotate = (value, amount) => {
    return (value >>> amount) | (value << (32 - amount));
  };

  const mathPow = Math.pow;
  const maxWord = mathPow(2, 32);
  const lengthProperty = 'length';
  let i, j; // Used as a counter across the whole file
  let result = '';

  const words = [];
  const asciiBitLength = ascii[lengthProperty] * 8;

  //* caching results is optional - remove/add slash from front of this line to toggle
  // Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
  // (we actually calculate the first 64, but extra values are just ignored)
  let hash = (sha256.h = sha256.h || []);
  // Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
  const k = (sha256.k = sha256.k || []);
  let primeCounter = k[lengthProperty];
  const isComposite = {};

  for (let candidate = 2; primeCounter < 64; candidate++) {
    if (!isComposite[candidate]) {
      for (i = 0; i < 313; i += candidate) {
        isComposite[i] = candidate;
      }
      hash[primeCounter] = (mathPow(candidate, 0.5) * maxWord) | 0;
      k[primeCounter++] = (mathPow(candidate, 1 / 3) * maxWord) | 0;
    }
  }

  ascii += '\x80'; // Append Ƈ' bit (plus zero padding)

  while ((ascii[lengthProperty] % 64) - 56) ascii += '\x00'; // More zero padding

  for (i = 0; i < ascii[lengthProperty]; i++) {
    j = ascii.charCodeAt(i);

    if (j >> 8) return ''; // ASCII check: only accept characters in range 0-255

    words[i >> 2] |= j << (((3 - i) % 4) * 8);
  }

  words[words[lengthProperty]] = (asciiBitLength / maxWord) | 0;
  words[words[lengthProperty]] = asciiBitLength;

  // process each chunk
  for (j = 0; j < words[lengthProperty]; ) {
    const w = words.slice(j, (j += 16)); // The message is expanded into 64 words as part of the iteration
    const oldHash = hash;
    // This is now the undefined working hash", often labelled as variables a...g
    // (we have to truncate as well, otherwise extra entries at the end accumulate
    hash = hash.slice(0, 8);

    for (i = 0; i < 64; i++) {
      // Expand the message into 64 words
      // Used below if
      const w15 = w[i - 15],
        w2 = w[i - 2];

      // Iterate
      const a = hash[0],
        e = hash[4];
      const temp1 =
        hash[7] +
        (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) + // S1
        ((e & hash[5]) ^ (~e & hash[6])) + // ch
        k[i] +
        // Expand the message schedule if needed
        (w[i] =
          i < 16
            ? w[i]
            : (w[i - 16] +
                (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15 >>> 3)) + // s0
                w[i - 7] +
                (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2 >>> 10))) | // s1
              0);
      // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadable
      const temp2 =
        (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) /* S0 */ +
        ((a & hash[1]) ^ (a & hash[2]) ^ (hash[1] & hash[2])); // maj

      hash = [(temp1 + temp2) | 0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
      hash[4] = (hash[4] + temp1) | 0;
    }

    for (i = 0; i < 8; i++) {
      hash[i] = (hash[i] + oldHash[i]) | 0;
    }
  }

  for (i = 0; i < 8; i++) {
    for (j = 3; j + 1; j--) {
      const b = (hash[i] >> (j * 8)) & 255;
      result += (b < 16 ? 0 : '') + b.toString(16);
    }
  }

  return result;
}

/**
 * Strip spaces and uncaptialize a string
 * @param {String} str
 * @returns {String}
 */
export const stripSpacesAndUncaptialize = (str) => {
  return str ? str.toLowerCase().replace(/ /g, '') : '';
};

export function hasParam(param) {
  // const url = window?.location?.search;
  // return new URLSearchParams(url).has(param);
}

// would send both param and platform to "getParam" function and do the platform checking in the utility function
// unprotected either window.location.search or urlsearch params will break mobile builds
export function getParam(param) {
  const url = window?.location?.search;
  return new URLSearchParams(url).get(param);
}

// didn't end up using it, but could be useful in the  future
// we had some very light client side segment validation in a1 as well
export function validateChapterData(chapters, episode) {
  const chapterDuration = chapters.map((chapter) => chapter.data.duration).reduce((a, b) => a + b);
  const showDuration = (episode.getEndTimeMillis() - episode.getStartTimeMillis()) / 1000;

  const percent = (chapterDuration / showDuration) * 100;

  if (percent < 25) return false;
  return true;
}

// currently used to forward users to a1 rewind on iphone browser
export function isMobileIOSBrowser() {
  return navigator?.userAgent?.match(/iPhone/i) || navigator.userAgent.match(/iPod/i);
}

/**
 * Checks if format of string is a valid US Zipcode
 * @param {String} zipCode
 * @returns Boolean
 */
export const validateZipCode = (zipCode) => {
  const isValidZipcode = /^\d{5}(-\d{4})?$/;
  return isValidZipcode.test(zipCode);
};

/**
 * Remove bad placeholder data by passing a regex and the data you want sanitized.
 * @param {RegExp} placeholder
 * @param {object} data
 * @returns
 */
export const removePlaceholderData = (placeholder, data) => {
  return JSON.parse(JSON.stringify(data).replace(placeholder, '""'));
};

export async function fetchWithTimeout(url, options = {}) {
  const { timeout = 30000 } = options;

  let controller = undefined;

  if (typeof AbortController !== 'undefined') {
    controller = new AbortController();
  }

  const id = setTimeout(() => controller?.abort(new AudacyError(CLIENT_SIDE_TIMEOUT)), timeout);

  const response = await fetch(url, {
    ...options,
    signal: controller?.signal,
  });

  clearTimeout(id);

  return response;
}
