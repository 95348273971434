import React, { useEffect, useMemo } from 'react';

import { isLoggedIn } from '@audacy-clients/core/atoms/auth';
import { nowPlaying } from '@audacy-clients/core/atoms/player';
import { useRefreshRecents } from '@audacy-clients/core/atoms/recents';
import { useClientServices } from '@audacy-clients/core/utils/clientServices';
import { useRecoilValue } from 'recoil';

const RecentsStateConnector: React.FC = () => {
  const { clientServices, loading } = useClientServices();
  const refreshRecentsCache = useRefreshRecents();
  const nowPlayingItem = useRecoilValue(nowPlaying);

  const nowPlayingItemId = useMemo(
    () => nowPlayingItem?.episodeDataObject?.episodeId || nowPlayingItem?.dataObject?.id || '',
    [nowPlayingItem?.dataObject?.id, nowPlayingItem?.episodeDataObject?.episodeId],
  );

  const loggedIn = useRecoilValue(isLoggedIn);

  useEffect(() => {
    refreshRecentsCache(true);
  }, [loggedIn, refreshRecentsCache]);

  useEffect(() => {
    if (loading) {
      return;
    }
    // timeout prevents race condition from addToHistory (handled in play() fn in client services)
    const timeout = setTimeout(() => {
      refreshRecentsCache(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [refreshRecentsCache, clientServices, nowPlayingItemId, loading]);

  return null;
};

export default RecentsStateConnector;
