export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Trash</title>
    <path
      d="M8 9.5v18h16v-18M14 15v6M18 15v6M5.75 9.25h20.5M11 8.5V5h10v3.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);
