export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Logo</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.51192 5.51917H18.4063L29.6465 26.5192H20.7522L9.51192 5.51917ZM14.4611 20.2886C14.4611 23.7296 11.6716 26.5191 8.23054 26.5191C4.78951 26.5191 2 23.7296 2 20.2886C2 16.8476 4.78951 14.058 8.23054 14.058C11.6716 14.058 14.4611 16.8476 14.4611 20.2886Z"
      fill="currentColor"
    />
  </svg>
);
