import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/free-mode';
import 'swiper/css/mousewheel';
import 'swiper/css/navigation';

import { css } from '@emotion/react';

import { fonts, globalFont } from '~/styles/global/fonts';
import { reset } from '~/styles/global/reset';
import { theme } from '~/styles/global/theme';
import { themeColors } from '~/styles/global/theme-colors';

export const global = css`
  ${reset}
  ${fonts}
  ${globalFont}
  ${themeColors}
  ${theme}
`;
