import { atom } from 'recoil';

interface IUserTimeState {
  userOffset?: number;
  userPlaybackFraction?: number;
  userRemaining?: number;
}

export const userTimeState = atom<IUserTimeState>({
  default: {},
  key: 'UserTimeState',
});

// TODO: [A2-6155] Change to array to store songs that have been liked
export const likeState = atom<boolean>({
  default: false,
  key: 'LikeState',
});
