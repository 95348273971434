import { useEffect } from 'react';

import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

type TAllowTouchMove = (el: HTMLElement | Element) => boolean;

/**
 * allows touch move on elements with specified class if the element needs to scroll.
 */
export const allowTouchMoveBasedOnClass: (scrollableClass: string) => TAllowTouchMove =
  (scrollableClass: string) => (element: Element | HTMLElement) => {
    const scrollParent = element.closest(`.${scrollableClass}`);

    if (scrollParent === null) {
      return false;
    }

    const { scrollHeight, clientHeight } = scrollParent;

    // Taking 300px away accounts for player controls & bottom
    // options which obscure the scrollable area
    const availableScreenHeight = screen.height - 300;

    return scrollHeight > clientHeight || scrollHeight > availableScreenHeight;
  };

/**
 * Defaults to disabling touch move on the target element.
 * If part of the overlay that we are targeting needs to be scrollable,
 * an `allowTouchMove` function will need to be provided.
 */
const useBodyScrollLock = (
  lockBody: boolean,
  targetElement: HTMLElement | null | undefined,
  allowTouchMove?: TAllowTouchMove,
): null => {
  useEffect(() => {
    if (targetElement) {
      if (lockBody) {
        disableBodyScroll(targetElement, {
          allowTouchMove: allowTouchMove ? allowTouchMove : () => false,
        });
      } else {
        enableBodyScroll(targetElement);
      }
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [lockBody, targetElement, allowTouchMove]);

  return null;
};

export default useBodyScrollLock;
