import React from 'react';

import { useTranslation } from 'react-i18next';

import IconButton, { IconButtonThemes } from '~/components/Button/IconButton';
import { Icons } from '~/components/Icon/constants';
import styles from '~/components/SearchBar/styles';

interface IClearButton {
  showClearButton: boolean;
  onClear: () => void;
}
const SearchClearButton = ({ showClearButton, onClear }: IClearButton): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      {showClearButton && (
        <span css={[styles.clearIconContainer]}>
          <IconButton
            buttonCss={styles.iconClear}
            icon={Icons.Close}
            ariaLabel={t('searchNavLandingScreen.overview.searchInput.clearSearch')}
            buttonSizeInPx={20}
            iconSizeInPx={10}
            theme={IconButtonThemes.Fill}
            onClick={onClear}
            css={styles.clearButton}
          />
        </span>
      )}
    </>
  );
};

export default SearchClearButton;
