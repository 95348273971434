import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  gridContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: 4,
    overflow: 'hidden',
  },
  gridItem: {
    flex: '0 0 50%',
  },
};

export default styles;
