import {
  AlphaColors,
  AudacyColors,
  BasicColors,
  GreyScale,
} from '@audacy-clients/core/styles/colors';
import { transparentize } from 'polished';

export const CustomColors = {
  audacyDarkNavy10: transparentize(0.9, AudacyColors.audacyDarkNavy),
  audacyDarkNavy15: transparentize(0.85, AudacyColors.audacyDarkNavy),
  audacyDarkNavy20: transparentize(0.8, AudacyColors.audacyDarkNavy),
  audacyDarkNavy60: transparentize(0.4, AudacyColors.audacyDarkNavy),
  audacyDarkNavy70: transparentize(0.3, AudacyColors.audacyDarkNavy),
  audacyDarkNavy95: transparentize(0.05, AudacyColors.audacyDarkNavy),
  white07: transparentize(0.93, BasicColors.white),
};

export const Colors = {
  ...AlphaColors,
  ...AudacyColors,
  ...BasicColors,
  ...CustomColors,
  ...GreyScale,
};
