import { Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  title: {
    ...Typography.titleSection,
    marginBottom: 5,
  },
  footerLink: {
    ...Typography.buttonSmall,
  },
};

export default styles;
