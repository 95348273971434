import PersonalizationServices from '.';
import { AuthState } from '../Constants';
import DataServices, { ContentSummary, PlaybacksHashMap } from '../dataServices/DataServices';
import Episode from '../dataServices/Episode';
import StandaloneChapter from '../dataServices/StandaloneChapter';
import { IHistoryEntry } from '../dataServices/types';

// TODO: [DTC-146] eventually move to DataServices
export type TProfileAPI = Pick<
  DataServices,
  | 'sessionId'
  | 'clearHistory'
  | 'addToHistory'
  | 'getHistory'
  | 'triggerPasswordResetEmail'
  | 'patchProfileData'
  | 'getProfileData'
  | 'getPlaybacks'
  | 'setPlayback'
  | 'setPlaybacks'
  | 'addFollows'
  | 'deleteFollow'
  | 'bulkUnfollow'
  | 'getFollows'
  | 'getLogoutUrl'
  | 'getQueue'
  | 'createAnonymousUser'
  | 'getIncompleteProfileData'
  | 'setQueue'
  | 'getAutoView'
  | 'getAdTargetingStatus'
  | 'setAdTargetingStatus'
  | 'getAdTargetingEligibility'
  | 'getPreferences'
  | 'setPreferences'
>;
export type TExperienceAPI = Pick<
  DataServices,
  'getContentSummaries' | 'getContentObject' | 'getContentObjects'
>;

/*** Follows ***/

export type IFollowsProvider = Pick<
  PersonalizationServices,
  'addFollows' | 'getFollows' | 'getFollowsWithSummaries' | 'deleteFollow' | 'bulkUnfollow'
>;

/*** Auth ***/

interface IAuthState {
  state: AuthState;
}

interface IUnauthenticatedAuthState extends IAuthState {
  state: AuthState.NONE;
}

interface IAnonymousAuthState extends IAuthState {
  state: AuthState.ANON;
  userToken: string;
}

interface IAuthenticatedAuthState extends IAuthState {
  state: AuthState.AUTH;
  bearerHeader?: string;
  refreshToken: string;
  userToken: string;
}

export type TAuthState = IUnauthenticatedAuthState | IAnonymousAuthState | IAuthenticatedAuthState;
export type TClientProvidedAuthState =
  | IUnauthenticatedAuthState
  | Pick<IAnonymousAuthState, 'state'>
  | (Pick<IAuthenticatedAuthState, 'state'> &
      (
        | {
            isTrusted: boolean;
            origin: string;
            data: Pick<IAuthenticatedAuthState, 'refreshToken'> & {
              authorizationHeader: string;
              userId: string;
            };
          }
        | (Pick<IAuthenticatedAuthState, 'refreshToken'> & {
            accessToken: string;
            userId: string;
          })
      ));

/*** Queue ***/

export type IQueueProvider = Pick<
  PersonalizationServices,
  | 'getQueue'
  | 'setQueue'
  | 'addToQueue'
  | 'removeFromQueue'
  | 'clearQueue'
  | 'addQueueListener'
  | 'removeQueueListener'
>;
export type IPlaybacksProvider = Pick<PersonalizationServices, 'getPlaybacks' | 'setPlayback'>;
export type IProfileProvider = Pick<
  PersonalizationServices,
  'getProfileData' | 'getIncompleteProfileData' | 'setProfileData'
>;

export type IPlayerSettingsProvider = Pick<
  PersonalizationServices,
  'getVolume' | 'setVolume' | 'getIsMuted' | 'setIsMuted' | 'getPlaybackRate' | 'setPlaybackRate'
>;

export type TQueueItem = Episode | StandaloneChapter;

export const isQueueItemIsEpisode = (item: unknown): item is Episode => {
  return item instanceof Episode;
};

export const isQueueItemStandaloneChapter = (item: unknown): item is StandaloneChapter => {
  return item instanceof StandaloneChapter;
};

export interface IQueue {
  currentId?: string;
  items: TQueueItem[];
  itemsRemoved?: boolean;
}

export type TQueueListener = (queue: IQueue) => void;

/*** Playbacks ***/

export type TPlaybacksListener = (playbacks: PlaybacksHashMap) => void;

export type TPlayback = {
  contentId: string;
  playbackOffset: number;
};

/*** History ***/

export interface IHistoryEntryWithSummary extends IHistoryEntry {
  content: ContentSummary;
}

/*** Auto Home ***/

export interface IAutoHomeWithSummary {
  header: string;
  items: IHistoryEntryWithSummary[];
  showLimit?: number;
  viewId: string;
  viewType: string;
  isLive?: boolean;
  moduleId: string;
}

/*** Profile ***/

export type TProfilePatch = Partial<
  Pick<
    IProfile,
    | 'userType'
    | 'dateOfBirth'
    | 'emailAddress'
    | 'firstName'
    | 'gender'
    | 'lastName'
    | 'notifications'
    | 'zipCode'
  >
>;

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NON_BINARY = 'NON_BINARY',
  NOT_SPECIFIED = 'PREFER_NOT_TO_SAY',
}

export enum Notifications {
  AUDACY_EMAIL = 'AUDACY_EMAIL',
  AUDACY_PUSH = 'AUDACY_PUSH',
}

export enum UserType {
  CONSUMER = 'CONSUMER',
  HOST = 'HOST',
}

export interface IProfile {
  dateOfBirth?: string;
  emailAddress: string;
  birthYear?: number;
  firstName?: string;
  gender?: Gender;
  lastLogin?: string;
  lastName?: string;
  notifications?: Notifications[];
  userType?: UserType;
  zipCode?: string;
}

export enum AdTargetingStatusParams {
  OPT_IN = 'OPT_IN',
  OPT_OUT = 'OPT_OUT',
}

export enum AdTargetingStatusResponse {
  OPT_IN = 'OPT_IN',
  OPT_OUT = 'OPT_OUT',
  INELIGIBLE = 'INELIGIBLE',
}

export interface IAdTargeting {
  adTargeting: AdTargetingStatusResponse;
}

export interface IAdTargetingEligibility {
  adTargeting: {
    eligible: boolean;
    canBeRequestedToOptIn: boolean;
  };
}

export enum AutoplayFrom {
  RESUME_POINT = 'RESUME_POINT',
  LIVE = 'LIVE',
}

export interface IPreferences {
  autoplay: boolean;
  autoplayFrom: AutoplayFrom;
  autoplayPromptEachTime: boolean;
  notifications: INotifications;
}

export interface INotifications {
  enabled?: boolean;
  newsPromo?: boolean;
  stationIds?: string[];
  podcastIds?: string[];
}

export type TPreferencesPatch = Partial<IPreferences>;
