import { EntitySubtype, EntityType, EpisodeSubType } from '@audacy-clients/client-services/core';
import { INowPlayingState } from '@audacy-clients/core/atoms/player';
import {
  IContentSummary,
  IEpisode,
} from '@audacy-clients/core/atoms/wrappers/types';
import { NOT_AVAILABLE } from '@audacy-clients/core/constants/player';
import {
  formatFromTo,
  formatPublishDateWithDayOfWeek,
  getPlayingUntilFromStartAndDuration,
} from '@audacy-clients/core/utils/date';
import { isFuture } from 'date-fns';

type TEpisodeType = 'validEpisode' | 'validFiller' | 'invalidFiller';

const getEpisodeType = (episode: IEpisode): TEpisodeType => {
  // Valid Episodes
  // Can be distinguished by the presence of a parent show
  if (episode.showContentId) {
    return 'validEpisode';
  }

  // Invalid Filler Episode
  // Audacy does not have accept to schedule data for stations CNN,
  // so Client Services makes a filler under the hood to support internal player functions.
  // This episode shoud never be shown to the user because the start/end time are made up.
  if (episode.parentTitle === NOT_AVAILABLE) {
    return 'invalidFiller';
  }

  // Valid Filler Episode
  // Set up in backend to fill gaps in schedule.
  // Start/end times are valid and can be displayed to the user.
  return 'validFiller';
};

export const isBroadcastShowEpisode = (entityType?: EntityType, entitySubtype?: EntitySubtype) => {
  return (
    entityType === EntityType.EPISODE && entitySubtype === EpisodeSubType.BROADCAST_SHOW_EPISODE
  );
};

export const isValidEpisodeOrFiller = (episode?: IEpisode): boolean =>
  !!episode && getEpisodeType(episode) !== 'invalidFiller';

export const isValidEpisode = (episode?: IEpisode): boolean =>
  !!episode && getEpisodeType(episode) === 'validEpisode';

export const isLiveNowEpisode = (episode: IEpisode | IContentSummary): boolean => {
  const playingUntil = getPlayingUntilFromStartAndDuration(episode.startDateTime, episode.duration);
  return !!playingUntil && isFuture(new Date(playingUntil));
};

export const isRewindEpisode = (episode?: IEpisode): boolean => !!episode?.isRewind;

export const getEpisodeTitleAndImage = (
  episode?: IEpisode | IContentSummary,
  isStation = false,
): [string | undefined, string | undefined] => {
  if (!episode) {
    return [undefined, undefined];
  }

  if (isStation) {
    return [episode.parentTitle, episode.parentImage];
  }

  let title: string | undefined = episode.title;

  if (!title) {
    title = formatPublishDateWithDayOfWeek(episode.publishDate || episode.startDateTime);
  }

  return [title, episode.image ?? episode.parentImage];
};

export const getEpisodeTitle = (playerItem?: INowPlayingState) => {
  // Format start and end times for valid filler episodes
  if (
    playerItem?.episodeDataObject &&
    getEpisodeType(playerItem.episodeDataObject) === 'validFiller' &&
    playerItem?.startTime &&
    playerItem?.endTime
  ) {
    return formatFromTo(playerItem.startTime, playerItem.endTime, ' - ', false);
  }

  return playerItem?.title;
};

export const getScheduleItemTitle = (
  isPlaceholder: boolean,
  startTime: number,
  endTime: number,
  parentTitle?: string,
) => (isPlaceholder ? formatFromTo(startTime, endTime, ' - ', false) : parentTitle);

/**
 * Determines if the specific type of episode is queueable
 * @param entityType
 * @param entitySubType
 * @returns boolean | undefined (if entityType or entitySubtype are not defined
 */
export const isQueueableEpisode = (entityType?: EntityType, entitySubType?: EntitySubtype): boolean | undefined => {
  if(!entityType || !entitySubType){
    return undefined;
  }
  return !isBroadcastShowEpisode(entityType, entitySubType);
}
