import { BP, Colors, Mixins, mq, Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    backgroundColor: Colors.white15,
    borderRadius: 6,
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
  iconContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: 46,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [mq(BP.Large)]: {
      left: 8,
      width: 40,
    },
  },
  clearIconContainer: {
    position: 'relative',
  },
  iconClear: {
    position: 'absolute',
    right: 15,
    top: 10,
    [mq(BP.Large)]: {
      right: 20,
      top: 15,
    },
  },
  input: {
    ...Mixins.focusOutline({}),
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    width: '100%',
    margin: 0,
    height: 40,
    padding: '12px 40px 12px 41px',
    [mq(BP.Small)]: {
      fontSize: 16, //Avoid zoom in on safari on mobile
    },
    [mq(BP.Medium)]: {
      ...Typography.metadataSmall,
    },
    [mq(BP.Large)]: {
      height: 50,
      padding: '12px 40px 12px 48px',
    },

    '::placeholder': {
      color: Colors.white60,
    },
  },
  form: {
    width: '100%',
  },
  clearButton: {
    opacity: 0.6,
  },
};

export default styles;
