import { useLayoutEffect, useRef, useState } from 'react';

import { TOptional } from '@audacy-clients/core/types/utility';

import { TStyles } from '~/types/emotion-styles';

import styles from './styles';

interface IListProps<T> {
  ariaLabel?: string;
  isOrdered?: boolean;
  items: T[];
  itemCss?: TStyles;
  renderItem: ({
    item,
    isLast,
    isFirst,
  }: {
    item: T;
    isLast?: boolean;
    isFirst: boolean;
  }) => JSX.Element;
  initial?: 'visible' | 'hidden';
}

interface IListItemId {
  id: string | number;
  title: string;
  time: string;
}

type TOptionalListId = TOptional<IListItemId, 'id' | 'title' | 'time'>;

const List = <T extends TOptionalListId>({
  ariaLabel = 'List',
  isOrdered = false,
  items,
  itemCss,
  renderItem,
}: IListProps<T>): JSX.Element => {
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLOListElement>(null);

  useLayoutEffect(() => {
    if (!height && ref?.current?.clientHeight) setHeight(ref.current.clientHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  const itemElements = items.map((item, index) => {
    const getKey = () => {
      if (item?.id) return item.id;
      if (item?.time && item?.title) return item.title + item?.time;
      return index.toString();
    };
    return (
      <li key={getKey()} css={[styles.listItem, itemCss]} tabIndex={-1}>
        {renderItem({ item, isLast: index === items.length - 1, isFirst: !index })}
      </li>
    );
  });

  const listPadding = 20;
  return isOrdered ? (
    <ol style={{ minHeight: height + listPadding }} ref={ref} aria-label={ariaLabel}>
      {itemElements}
    </ol>
  ) : (
    <ul ref={ref} style={{ minHeight: height + listPadding }} aria-label={ariaLabel}>
      {itemElements}
    </ul>
  );
};
export default List;
