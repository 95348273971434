import { type TPlayableObject } from '@audacy-clients/client-services/src/audioServices/types';
import type Collection from '@audacy-clients/client-services/src/dataServices/Collection';
import { type INowPlayingState } from '@audacy-clients/core/atoms/player';
import { type IContentSummary } from '@audacy-clients/core/atoms/wrappers/types';
import { getDurationFromSeconds, formatPublishDate } from '@audacy-clients/core/utils/date';
import { type TOptions } from 'i18next';

import clientServices from './clientServices';
import { reorderPlayableObjectsById } from './objects';

type II18nextInstance = {
  t(key: string, options?: TOptions): string;
  // other methods and properties from the i18next instance...
};

type ILocalizationParams = {
  i18n: II18nextInstance;
  playlistCountKey: string;
  playlistDurationKey: string;
};

export const getPlaylistCountAndDuration = (
  count: number,
  durationSeconds: number,
  separator: 'dot' | 'comma',
  { i18n, playlistCountKey, playlistDurationKey }: ILocalizationParams,
): string => {
  let playlistCountText = `${i18n.t(playlistCountKey, {
    count,
  })}`;

  if (!durationSeconds) {
    return playlistCountText;
  }

  const { hours, minutes } = getDurationFromSeconds(durationSeconds);

  const useComma = separator === 'comma';

  playlistCountText += `${useComma ? ',' : ' •'} ${i18n.t(playlistDurationKey, {
    hours,
    minutes,
  })}`;

  return playlistCountText;
};

export const getItemToPlay = async (
  collectionId: string,
  itemId?: string,
): Promise<{
  itemToPlay: TPlayableObject | undefined;
  orderedContent: Array<TPlayableObject>;
  noneToPlay?: boolean;
  itemIds?: Array<string>;
}> => {
  const dataServices = clientServices.getDataServices();

  const contentObj: Partial<Collection> = await dataServices.getContentObject(collectionId);

  const itemIds = contentObj.getItems?.();
  const contentObjects = await dataServices.getContentObjects(itemIds ?? []);

  if (!Object.keys(contentObjects.content).length || !itemIds) {
    return {
      itemToPlay: undefined,
      orderedContent: [],
      noneToPlay: true,
    };
  }

  const contentArray = Object.values(contentObjects.content).map((i) => i as TPlayableObject);

  const orderedContent = reorderPlayableObjectsById(itemIds, contentArray);
  const itemToPlay = orderedContent.find((i) => i.getId() === itemId);

  return { itemToPlay, orderedContent, itemIds };
};

export const checkContentPosition = (
  activeCollection: Array<TPlayableObject> | Array<IContentSummary>,
  currentContent?: INowPlayingState,
) => {
  const id = currentContent?.dataObject?.id;
  const currentIdx = activeCollection.findIndex((i) => {
    return 'getId' in i ? i.getId() === id : i.id === id;
  });
  const isFirst = currentIdx === 0;
  const isLast = currentIdx === activeCollection.length - 1;

  return { isFirst, isLast, currentIdx };
};

export const getPlaylistPageSubtitle = (author?: string, updateDate?: Date): string =>
  `${author ? `By ${author}` : ''}${author && updateDate ? ' • ' : ''}${
    updateDate ? `Updated ${formatPublishDate(updateDate)}` : ''
  }`;
