import { EntityType } from '../Constants';
import SimpleObject from './SimpleObject';

/** @typedef { import('../Constants').EntitySubtype  } EntitySubtype */
/** @typedef { import('../Constants').EntityType     } EntityType */
/** @typedef { import('../Constants').EpisodeSubType } EpisodeSubType */
/** @typedef { import('../Constants').ObjectType     } ObjectType */
/** @typedef { import('../Constants').ShowSubtype    } ShowSubtype */
/** @typedef { import('../Constants').StationSubType } StationSubType */
/** @typedef { import('../Constants').StandaloneChapterSubType } StandaloneChapterSubType */
/** @typedef { import('../Constants').ViewType       } ViewType */
/** @typedef { import('../Constants').Image          } Image */

/**
 * @module DataObject
 */
export default class DataObject extends SimpleObject {
  /**
   * Create a new instance of DataObject.
   * @param {ObjectType} type
   * @param {{ [key: string]: any }} data
   */
  constructor(type, data) {
    super(type);
    this.data = data;
  }

  static cleanJsonObject(object) {
    switch (object.entityType) {
      case EntityType.EPISODE:
        delete object.image;
        delete object.imageAlt;
        delete object.streamUrls;
        break;

      case EntityType.SHOW:
        delete object.bband;
        delete object.image;
        delete object.imageAlt;
        break;

      case EntityType.STATION:
        delete object.image;
        delete object.imageAlt;
        delete object.streamUrls;
        break;

      case EntityType.STANDALONE_CHAPTER:
        delete object.image;
        delete object.imageAlt;
        delete object.streamUrls;
        break;
    }
  }

  /**
   * @returns {EntitySubtype}
   */
  getEntitySubtype() {
    return this.data.entitySubtype;
  }

  /**
   * @returns {EntityType}
   */
  getEntityType() {
    return this.data.entityType;
  }

  /**
   * Get the object id.
   * @returns {String}
   */
  getId() {
    return this.data.id;
  }

  /**
   * Returns the object as json data.
   * @returns {StationJson}
   */
  getJson() {
    return this.data;
  }

  /**
   * Get the page url.
   * @returns {String|null}
   */
  getUrl() {
    return this.data.url;
  }

  /**
   * Get the entity description.
   * @returns {String}
   */
  getDescription() {
    return this.data.description || this.description;
  }

  /**
   * Returns resume point of the object.
   * @returns {number}
   */
  getResumePoint() {
    return this.resumePoint;
  }

  /**
   * @returns {string}
   */
  getTitle() {
    return this.data.title || this.name || this.title;
  }

  /**
   * Returns whether this object has been marked as a favorite.
   * @returns {boolean}
   */
  isFavorite() {
    return this.isFav;
  }

  /**
   * Returns whether this object can be followed.
   * @returns {boolean}
   */
  isFollowable() {
    return this.data.followable;
  }

  /**
   * @param {boolean} bool Sets the favorite status of this object
   */
  markAsFavorite(bool) {
    this.isFav = bool;
  }

  /**
   * @param {EpisodeSubType|ShowSubtype|StationSubType|StandaloneChapterSubType} subtype
   */
  setEntitySubtype(subtype) {
    this.entitySubtype = subtype;
  }

  /**
   * @param {number} time
   */
  setResume(time) {
    this.resumePoint = time;
  }

  /**
   * @returns {String[]}
   */
  getGenres() {
    return this.data.genres;
  }

  /**
   * @returns {String}
   */
  getMarketTitle() {
    return this.data.marketTitle;
  }

  /**
   * @returns {Image}
   */
  getImages() {
    return this.data.images;
  }

  /**
   * @returns {String}
   */
  getPublisher() {
    return this.data.publisher;
  }
}
