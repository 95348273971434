import { useEffect, useRef } from 'react';

import { ClickFeature } from '@audacy-clients/client-services/core';
import { useMostRecentlyPlayed } from '@audacy-clients/core/atoms/recents';
import { useAudioHelpers } from '@audacy-clients/core/hooks/useAudioHelpers';
import { DeeplinkQueryParams, LinkingActions } from '@audacy-clients/core/types/navigation';
import { useSearchParams } from 'react-router-dom';

// This is the autoplay feature, currently dead code
const MostRecentlyPlayedStateConnector: React.FC = () => {
  const [searchParams] = useSearchParams();
  const action = searchParams.get(DeeplinkQueryParams.Action);
  const isPlayingFromDeeplink =
    action === LinkingActions.AutoplayFull || action === LinkingActions.AutoplayMini;

  const { playFromId } = useAudioHelpers();
  const hasInitiatedMostRecentPlayback = useRef(false);

  const mostRecent = useMostRecentlyPlayed();

  useEffect(() => {
    if (!isPlayingFromDeeplink && !hasInitiatedMostRecentPlayback.current && mostRecent?.item) {
      playFromId(
        mostRecent.item.id,
        { feature: ClickFeature.MINI_PLAYER },
        {
          autoPlay: false,
          startOffset: mostRecent.resumePoint,
        },
      );
      hasInitiatedMostRecentPlayback.current = true;
    }
  }, [playFromId, mostRecent, isPlayingFromDeeplink]);

  return <></>;
};

export default MostRecentlyPlayedStateConnector;
