import { Transition } from 'framer-motion';

import Animate from '~/components/Animate';

import styles from './styles';

interface IOverlayContentProps {
  onClick?: () => void;
}

interface IOverlayProps extends IOverlayContentProps {
  isAnimated?: boolean;
  isVisible?: boolean;
  motionTransition?: Transition;
  zIndex?: number;
}

const OverlayContent = ({ onClick }: IOverlayContentProps): JSX.Element => {
  const sharedProps = {
    css: styles.overlay,
    className: 'overlay',
  };

  return onClick ? (
    <button {...sharedProps} aria-hidden onClick={onClick} tabIndex={-1} />
  ) : (
    <div {...sharedProps} />
  );
};

const Overlay = ({
  isAnimated,
  isVisible,
  motionTransition,
  zIndex = 0,
  ...rest
}: IOverlayProps): JSX.Element => {
  return isAnimated ? (
    <Animate.FadeInOut
      isVisible={isVisible}
      motionCss={{ ...styles.container, zIndex }}
      transition={motionTransition}
    >
      <OverlayContent {...rest} />
    </Animate.FadeInOut>
  ) : (
    <OverlayContent {...rest} />
  );
};

export default Overlay;
