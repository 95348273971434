import { IModuleProps } from '@audacy-clients/core/components/ModuleRenderer/types';
import isString from 'lodash/isString';

import FooterLink from '~/components/Link/FooterLink';
import SectionWrapper from '~/components/SectionWrapper';
import { Typography } from '~/styles';

const ModuleSectionWrapper: React.FC<IModuleProps> = ({ children, isLast, module }) => {
  const subtitle = module?.config?.header?.label || '';
  const footerLabel = module?.config?.footer?.label || '';
  const footerUrl = module?.config?.footer?.url;

  const footerContent = footerUrl && footerLabel && (
    <FooterLink label={footerLabel} linkCss={{ ...Typography.buttonSmall }} href={footerUrl} />
  );

  return (
    <SectionWrapper
      isLast={isLast}
      href={isString(module?.config?.title) ? undefined : module?.config?.title?.url}
      title={isString(module?.config?.title) ? module.config.title : module?.config?.title?.label}
      subtitle={subtitle}
      footerContent={footerContent}
    >
      {children}
    </SectionWrapper>
  );
};

export default ModuleSectionWrapper;
