import { type IAnalyticsEvent } from '@audacy-clients/client-services/src/analyticServices';
import { type IFeatureFlags } from '@audacy-clients/core/atoms/config/settings';
import {
  type IBrazeFeatureFlag,
  type IBrazeFeatureFlagsProvider,
  type IBrazeInstance,
} from '@audacy-clients/core/types/braze';
import { deferred } from '@audacy-clients/core/utils/deferred';
import { flattenObject } from '@audacy-clients/core/utils/objects';

const onReadyDeferred = deferred<IFeatureFlags>();

export class BrazeFeatureFlagsService implements IBrazeFeatureFlagsProvider {
  private readonly brazeInstance: IBrazeInstance;

  constructor(brazeInstance: IBrazeInstance) {
    this.brazeInstance = brazeInstance;

    //DOC for refreshFeatureFlags method: https://www.braze.com/docs/developer_guide/platform_wide/feature_flags/create/?tab=javascript#:~:text=will%20be%20referenced.-,JAVASCRIPT,-SWIFT
    this.brazeInstance.refreshFeatureFlags();
  }

  async getAllFeatureFlags(): Promise<Array<IBrazeFeatureFlag> | undefined> {
    //DOC for getAllFeatureFlags method: https://www.braze.com/docs/developer_guide/platform_wide/feature_flags/create/#accessing-properties:~:text=enabled%20feature%20flags%3A-,JAVASCRIPT,-SWIFT
    return this.brazeInstance.getAllFeatureFlags();
  }

  async getFeatureFlag(featureId: keyof IFeatureFlags): Promise<IBrazeFeatureFlag | undefined | null> {
    return this.brazeInstance.getFeatureFlag(featureId);
  }
}

function extractFeatureFlags(flags: Array<IBrazeFeatureFlag>): IFeatureFlags {
  return flags.reduce(
    (acc, flag) => (flag.enabled ? { ...acc, [flag.id]: !!flag.properties?.active?.value } : acc),
    {} as IFeatureFlags,
  );
}

export let sharedBrazeFeatureFlagsService: BrazeFeatureFlagsService;

export const initializeBrazeFeatureFlagsService = (brazeInstance: IBrazeInstance) => {
  if (!sharedBrazeFeatureFlagsService) {
    sharedBrazeFeatureFlagsService = new BrazeFeatureFlagsService(brazeInstance);
    sharedBrazeFeatureFlagsService
      .getAllFeatureFlags()
      .then((flags) => onReadyDeferred.resolve(extractFeatureFlags(flags || [])))
      .catch(() => onReadyDeferred.reject());
  } else {
    console.warn('BrazeFeatureFlagsService is already initialized.');
  }
};

export const getBrazeFeatureFlags = (): Promise<IFeatureFlags> =>
  onReadyDeferred.promise.then((flags) => flags);

export const sendEventsToBraze = (
  logFunction: (name: string, props: object) => void,
  events: Array<IAnalyticsEvent>,
  appProps: object,
): void => {
  events.forEach((event) => {
    const flattenedEvent = flattenObject(event);
    logFunction(event.type, { ...flattenedEvent, ...appProps });
  });
};
