// A list of ViewIDKeys that can be used to retrieve ViewID values from /config/settings endpoint

export enum ViewIDKey {
  AccountReactivateRequest = 'accountReactivateRequest',
  AllEpisodes = 'allEpisodes',
  AndroidAutoNowPlaying = 'androidAutoNowPlaying',
  AndroidAutoChapterList = 'androidAutoChapterList',
  AndroidAutoHomePage = 'androidAutoHomePage',
  CarPlayLoggedOut = 'carPlayLoggedOut',
  CarPlayInternetConnection = 'carPlayInternetConnection',
  CarPlayRecentsList = 'carPlayRecentsList',
  CarPlayHomePage = 'carPlayHomePage',
  CarPlayChapterList = 'carPlayChapterList',
  CarPlayNestedList = 'carPlayNestedList',
  CarPlayNowPlaying = 'carPlayNowPlaying',
  Contact = 'contactAudacy',
  SettingsDeleteConfirmation = 'settingsDeleteConfirmation',
  DeveloperScreen = 'developerScreen',
  Landing = 'landing',
  Migration = 'migration',
  MyAudioPodcastsAndShows = 'myAudioPodcastsAndShows',
  MyAudioFollowed = 'myAudioFollowed',
  MyAudioQueue = 'myAudioQueue',
  MyAudioRecent = 'myAudioRecent',
  MyAudioStations = 'myAudioStations',
  MyAudioTopics = 'myAudioTopics',
  OnboardingStations = 'onboardingStations',
  Player = 'player',
  PrivacyLegal = 'privacyLegal',
  PrivacyPolicy = 'legalPrivacyPolicy',
  ProfileAccount = 'profileAccount',
  ProfileContactSupport = 'profileContactSupport',
  ProfileDelete = 'profileDelete',
  ProfileLocation = 'profileLocation',
  ProfileNotification = 'profileNotification',
  ProfileOpenSource = 'profileOpenSource',
  ProfileOverview = 'profileOverview',
  ProfilePassword = 'profilePassword',
  ProfileSecurity = 'profileSecurity',
  Queue = 'queue',
  RegistrationAdditionalDetails = 'registrationAdditionalDetails',
  RegistrationName = 'registrationName',
  Schedule = 'schedule',
  SongHistory = 'songHistory',
  Search = 'search',
  Terms = 'legalTermsOfUse',
  TapToRecordScreen = 'tapToRecordScreen',
  TranscriptScreen = 'transcriptScreen',
  Playlist = 'playlist',
}
