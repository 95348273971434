import { Colors, Mixins } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    alignSelf: 'center',
    borderRadius: '50%',
    height: 20,
    position: 'relative',
    width: 20,
  },
  line: {
    backgroundColor: Colors.white,
    height: 1,
    left: 0,
    position: 'absolute',
    top: 0,
    transformOrigin: 'left center',
    width: 14,
    ...Mixins.transition(['width, transform']),
    ...Mixins.reduceMotion(),
    '&:nth-of-type(1)': {
      transform: 'translate3d(3px, 6px, 0)',
    },
    '&:nth-of-type(2)': {
      transform: 'translate3d(3px, 13px, 0)',
    },
  },
  lineClosed: {
    width: 18,
    '&:nth-of-type(1)': {
      transform: 'translate3d(4px, 3px, 0) rotate(45deg)',
    },
    '&:nth-of-type(2)': {
      transform: 'translate3d(4px, 16px, 0) rotate(-45deg)',
    },
  },
};

export default styles;
