import { DataEventType, PlayerAction } from '@audacy-clients/client-services/core';

export interface IEvent {
  type: string;
}

export const branchEventAllowlist: string[] = [
  DataEventType.FOLLOW,
  PlayerAction.PLAY,
  DataEventType.REGISTRATION_ENTRY,
  DataEventType.REGISTRATION_SUCCESSFUL,
];

export const filterBranchEvents = (events: IEvent[]): IEvent[] => {
  const matchingEvents = events.filter((e) => branchEventAllowlist.includes(e.type));
  return matchingEvents;
};
