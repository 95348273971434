import { BP, mq, ZIndex } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const padding = {
  large: 30,
  small: 20,
};

const styles: TStyleSheet = {
  container: {
    display: 'inline-flex',
    justifyContent: 'center',
    left: padding.small,
    pointerEvents: 'none',
    position: 'fixed',
    right: padding.small,
    top: padding.small,
    width: `calc(100% - ${padding.small * 2}px)`,
    zIndex: ZIndex.toast,
    [mq(BP.Large)]: {
      bottom: padding.large,
      justifyContent: 'flex-start',
      left: padding.large,
      right: padding.large,
      top: 'auto',
      width: `calc(100% - ${padding.large * 2}px)`,
    },
  },
};

export default styles;
