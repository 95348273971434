import React from 'react';
import BaseLink from '~/components/BaseLink';
import { ButtonType } from '~/components/Button/types';
import { type TStyles } from '~/types/emotion-styles';
import {
  type IGlobalAsAnchorProps,
  type IGlobalAsButtonProps,
  LinkType,
  type TAnchorRef,
  type TButtonOrAnchorRef,
  type TButtonRef,
  type TGlobalAnchorOrButton,
} from '~/types/links';

type IUseLinkProps = {
  props: TGlobalAnchorOrButton;
  children?: React.ReactNode;
  styles?: TStyles;
  ref?: TButtonOrAnchorRef;
};

const useLinkOrButton = ({ props, children, styles, ref }: IUseLinkProps): JSX.Element => {
  const {
    ariaControls,
    ariaDescribedBy,
    ariaExpanded,
    ariaHasPopup,
    ariaHidden,
    ariaPosInSet,
    ariaPressed,
    ariaLabel,
    ariaSelected,
    ariaSetSize,
    as,
    className,
    id,
    onClick,
    onFocus,
    onKeyDown,
    onPointerDown,
    role,
    tabIndex,
    title,
    ...rest
  } = props;
  const { href } = rest as IGlobalAsAnchorProps;
  const { isDisabled, type } = rest as IGlobalAsButtonProps;

  const isAnchor = as === LinkType.Anchor;

  const sharedProps = {
    'aria-controls': ariaControls,
    'aria-describedby': ariaDescribedBy,
    'aria-hidden': ariaHidden,
    'aria-label': ariaLabel,
    'aria-posinset': ariaPosInSet,
    'aria-selected': ariaSelected,
    'aria-setsize': ariaSetSize,
    className,
    id,
    onClick,
    onFocus,
    onKeyDown,
    onPointerDown,
    role,
    tabIndex,
  };

  const anchorProps = {
    ...sharedProps,
    href,
    linkCss: styles,
    ref: ref as TAnchorRef,
  };

  const buttonProps = {
    ...sharedProps,
    'aria-controls': ariaControls,
    'aria-disabled': isDisabled,
    'aria-expanded': ariaExpanded,
    'aria-haspopup': ariaHasPopup,
    'aria-pressed': ariaPressed,
    css: styles,
    disabled: isDisabled,
    type: type || ButtonType.Button,
    ref: ref as TButtonRef,
    title,
  };

  if (isAnchor && 'href' in props) {
    return <BaseLink {...anchorProps}>{children}</BaseLink>;
  }

  return <button {...buttonProps}>{children}</button>;
};

export default useLinkOrButton;
