import { IToast } from '@audacy-clients/core/atoms/toast';
import { useTranslation } from 'react-i18next';

import IconButton, { IconButtonColorThemes } from '~/components/Button/IconButton';
import { Icons } from '~/components/Icon/constants';
import Link from '~/components/Link';
import { LinkType } from '~/types/links';

import styles from './styles';

interface IToastProps extends IToast {
  onClick?: () => void;
  onClose: () => void;
}

const ToastItem = ({ cta, onClick, onClose, title }: IToastProps): JSX.Element => {
  const { t } = useTranslation();

  const handleOnClick = () => {
    cta?.action && cta.action();
    onClick && onClick();
  };

  return (
    <div css={styles.container}>
      <span css={styles.title} role="status">
        {title}
      </span>

      {!!cta && (
        <div css={styles.ctaDivider}>
          <Link
            as={LinkType.Button}
            css={styles.cta}
            isTiny
            labelCss={styles.ctaLabel}
            onClick={handleOnClick}
          >
            {cta.title}
          </Link>
        </div>
      )}

      <IconButton
        ariaLabel={t('toast.ariaLabels.close')}
        buttonCss={styles.closeButton}
        buttonSizeInPx={12}
        colorTheme={IconButtonColorThemes.Light}
        icon={Icons.Close}
        iconSizeInPx={12}
        onClick={onClose}
      />
    </div>
  );
};

export default ToastItem;
