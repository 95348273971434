import { BP, mq, mqHover } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const VerticalSpacing = {
  sm: 45,
  md: 60,
  lg: 60,
};

const styles: TStyleSheet = {
  container: {
    marginBottom: VerticalSpacing.sm,
    [mq(BP.Medium)]: {
      marginBottom: VerticalSpacing.md,
    },

    // If a SectionWrapper contains a carousel, show its previous and next
    // buttons when hovering over the SectionWrapper, also give slides that are
    // peeking full opacity.
    ...mqHover({
      '.swiper-button, .swiper-slide': {
        opacity: 1,
      },
    }),
  },
};

export default styles;
