import { animationStatusName } from '~/constants/animation';
import { EasingArrays, Timing } from '~/styles';

const Constants = {
  translateY: 12,
};

export const innerContainerVariants = {
  [animationStatusName.initial]: {
    y: Constants.translateY,
  },
  [animationStatusName.animate]: {
    y: 0,
    transition: {
      ease: 'easeOut',
      duration: Timing.navigationMenuOpen,
      type: 'tween',
    },
  },
  [animationStatusName.exit]: {
    y: Constants.translateY,
    transition: {
      duration: Timing.navigationMenuOpen / 2,
    },
  },
};

export const navTransition = {
  duration: Timing.navigationPanel,
  ease: EasingArrays.navigation,
  type: 'tween',
};
