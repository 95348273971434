export default (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Play</title>
    <rect y="0.5" width="20" height="20" rx="10" fill="currentColor" />
    <path d="M14.875 10.5L7.5625 14.7219L7.5625 6.27813L14.875 10.5Z" fill="white" />
  </svg>
);
