import { playerVolumeState } from '@audacy-clients/core/atoms/player';
import { useRecoilValue } from 'recoil';

import { useAudioHelpersWeb } from '~/hooks/use-audio-helpers-web';

import VolumeSlider from './VolumeSlider';

interface IVolumeSliderWithState {
  isFullPlayer?: boolean;
}

const VolumeSliderWithState = ({ isFullPlayer }: IVolumeSliderWithState): JSX.Element => {
  const audioHelpers = useAudioHelpersWeb();
  const { volume, isMuted } = useRecoilValue(playerVolumeState);

  return (
    <VolumeSlider
      isFullPlayer={isFullPlayer}
      isMuted={isMuted}
      volume={volume}
      onMuteChange={(muted: boolean) => audioHelpers.setMuted(muted)}
      onVolumeChange={(vol: number) => audioHelpers.setVolume(vol)}
    />
  );
};

export default VolumeSliderWithState;
