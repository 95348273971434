import { noCacheDefaultSelector } from '@audacy-clients/core/atoms/helpers/noCacheDefaultSelector';
import { atom, DefaultValue } from 'recoil';

export interface ILiveRegionsState {
  assertiveMessage: string;
  politeMessage: string;
}

const defaultMessages: ILiveRegionsState = {
  assertiveMessage: '',
  politeMessage: '',
};

export const LiveRegionsAtom = atom<ILiveRegionsState>({
  default: defaultMessages,
  key: 'LiveRegions',
});

export const AssertiveLiveRegionMessageSelector = noCacheDefaultSelector<string>({
  key: 'LiveRegionsAssertive',
  get: ({ get }) => get(LiveRegionsAtom).assertiveMessage,
  set: ({ get, set }, newMessage) => {
    const messages = get(LiveRegionsAtom);

    set(LiveRegionsAtom, {
      ...messages,
      assertiveMessage: newMessage instanceof DefaultValue ? '' : newMessage,
    });
  },
});

export const PoliteLiveRegionMessageSelector = noCacheDefaultSelector<string>({
  key: 'LiveRegionsPolite',
  get: ({ get }) => get(LiveRegionsAtom).politeMessage,
  set: ({ get, set }, newMessage) => {
    const messages = get(LiveRegionsAtom);

    set(LiveRegionsAtom, {
      ...messages,
      politeMessage: newMessage instanceof DefaultValue ? '' : newMessage,
    });
  },
});
