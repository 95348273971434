import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon';
import { Icons } from '~/components/Icon/constants';
import usePlayerProps from '~/components/Player/hooks/usePlayerProps';
import styles from '~/components/Player/MiniPlayer/styles';

export const ExpandPlayerButton = (): JSX.Element => {
  const { onOpen } = usePlayerProps();
  const { t } = useTranslation();

  return (
    <button
      css={styles.expandBtn}
      onClick={onOpen}
      aria-label={t('playerControls.accessibilityLabels.openFullPlayer')}
    >
      <Icon ariaHidden name={Icons.CaretUp} sizeInPx={14} />
    </button>
  );
};
