import { ShowSubtype, StationSubType, TagSubtype } from '@audacy-clients/client-services/core';
import { getPlaylistCountAndDuration } from '@audacy-clients/core/utils/playlist';
import i18n from 'i18next';
import toLower from 'lodash/toLower';
import { useTranslation } from 'react-i18next';

type TTitleContent = {
  title?: string;
  subtitle?: string;
  headerTitle?: string;
  itemsCount?: number;
  duration?: number;
};

export const useTitleContent = (
  title: string | undefined,
  entitySubType: string | undefined,
  ganres: string[] | undefined,
  marketTitle: string | undefined,
  author: string | undefined,
  itemsCount: number | undefined,
  duration: number | undefined,
): TTitleContent => {
  const { t } = useTranslation();
  let subtitle, headerTitle;

  switch (entitySubType) {
    case StationSubType.BROADCAST:
    case StationSubType.INTERNET:
    case StationSubType.TOPIC:
    case StationSubType.PERSONALIZED:
      headerTitle = t('horizontalEntityCard.eyebrow.station');
      subtitle = marketTitle;
      break;
    case StationSubType.EXCLUSIVE:
      headerTitle = t('horizontalEntityCard.eyebrow.station');
      subtitle = ganres?.[0] ? ganres[0] : '';
      break;
    case ShowSubtype.BROADCAST:
    case ShowSubtype.DIGITAL:
    case ShowSubtype.HOST_CREATED:
      headerTitle = t('horizontalEntityCard.eyebrow.show');
      subtitle = author;
      break;
    case ShowSubtype.PODCAST:
      headerTitle = t('horizontalEntityCard.eyebrow.podcast');
      subtitle = author;
      break;
    case ShowSubtype.PLAYLIST:
      subtitle = getPlaylistCountAndDuration(itemsCount ?? 0, duration ?? 0, 'dot', {
        i18n,
        playlistCountKey: 'playlistScreen.playlistCount',
        playlistDurationKey: 'playlistScreen.playlistDuration',
      });
      break;
    case TagSubtype.TEAM:
      headerTitle = `${title} ${toLower(t('horizontalEntityCard.eyebrow.team'))}`;
      break;

    case TagSubtype.LEAGUE:
      headerTitle = t('horizontalEntityCard.eyebrow.league');
      break;
  }

  return { title, subtitle, headerTitle };
};
