export const PLAYBACK_RATES = [0.8, 1, 1.2, 1.5, 2] as const;

export const ONE_MINUTE = 60;

export const VOD_FEATURE_FLAG = 'vodSpeedControls';

export const ADVERTISEMENT = 'advertisement';

export const CONTENT = 'content';

export const AD = 'ad';

export const SUPERHIFI_FRAG_LOAD_POLICY = {
    default: {
      timeoutRetry: {
        maxNumRetry: 10,
        retryDelayMs: 1000,
        maxRetryDelayMs: 10000
      },
      maxTimeToFirstByteMs: 10000,
      maxLoadTimeMs: 10000,
      errorRetry: {
        maxNumRetry: 10,
        retryDelayMs: 1000,
        maxRetryDelayMs: 10000,
      }
    },
  }

  export const SUPERHIFI_MANIFEST_LOAD_POLICY = {
    default: {
      maxTimeToFirstByteMs: 10000,
      maxLoadTimeMs: 10000,
      timeoutRetry: {
        maxNumRetry: 10,
        retryDelayMs: 1000,
        maxRetryDelayMs: 10000
      },
      errorRetry: {
        maxNumRetry: 10,
        retryDelayMs: 1000,
        maxRetryDelayMs: 10000,
      },
    },
  }

  export const SUPERHIFI_PLAYLIST_LOAD_POLICY = {
    default: {
      maxTimeToFirstByteMs: 10000,
      maxLoadTimeMs: 20000,
      timeoutRetry: {
        maxNumRetry: 5,
        retryDelayMs: 25000,
        maxRetryDelayMs: 5000,
      },
      errorRetry: {
        maxNumRetry: 5,
        retryDelayMs: 5000,
        maxRetryDelayMs: 8000,
      },
    },
  }

  export const SUPERHIFI_STEERING_MANIFEST_LOAD_POLICY = {
    default: {
      maxTimeToFirstByteMs: 10000,
      maxLoadTimeMs: 20000,
      timeoutRetry: {
        maxNumRetry: 5,
        retryDelayMs: 1000,
        maxRetryDelayMs: 10000,
      },
      errorRetry: {
        maxNumRetry: 5,
        retryDelayMs: 1000,
        maxRetryDelayMs: 8000,
      },
    }
  }

  export const AMPERWAVE_HLS_CONFIG = {
    maxBufferSize: 0,
    maxBufferLength: 60,
    backBufferLength: 0,
    // debug: true,
      playlistLoadPolicy: {
        default: {
          maxTimeToFirstByteMs: 10000,
          maxLoadTimeMs: 20000,
          timeoutRetry: {
            maxNumRetry: 5,
            retryDelayMs: 25000,
            maxRetryDelayMs: 5000,
          },
          errorRetry: {
            maxNumRetry: 5,
            retryDelayMs: 5000,
            maxRetryDelayMs: 8000,
          },
        },
      },
  }