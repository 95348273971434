import { atom } from 'recoil';

export interface IToast {
  cta?: {
    action: () => void;
    title: string;
  };
  shouldPreventAutoHide?: boolean;
  id?: string;
  title: string;
}

interface IToastState {
  queue: IToast[];
}

export const toastAtom = atom<IToastState>({
  default: {
    queue: [],
  },
  key: 'Toasts',
});
