export const Constants = {
  isConsideredPlayedEpsilon: 0.98,
  minimumSecondsForStarted: 10,
};

export const getFixedResumePoint = (
  resumePoint?: number,
  startDateTime?: Date,
): number | undefined => {
  // NOTE: [CCS-2922] - This is a hack and only occurs on mobile
  // There should be no way that startDateTime is a string here,
  // but because DS types everything in DataObject as `any`, and just forces the return type on the getters,
  // this appears to typescript as though it is working. 🙃
  const startDT = typeof startDateTime === 'string' ? new Date(startDateTime) : startDateTime;

  if (resumePoint === undefined) {
    return undefined;
  }

  const year2000 = new Date('2000').getTime() / 1000;

  if (resumePoint > year2000) {
    const startTime = startDT?.getTime() ?? 0;

    return resumePoint - startTime / 1000;
  }

  return resumePoint;
};

export const isConsideredStarted = (secondsPlayed?: number): boolean => {
  if (secondsPlayed === undefined) {
    return false;
  }

  if (secondsPlayed >= Constants.minimumSecondsForStarted) {
    return true;
  }

  return false;
};

export const isConsideredPlayed = (duration?: number, resumePoint?: number): boolean => {
  if (duration === undefined || resumePoint === undefined) {
    return false;
  }

  if (resumePoint / duration >= Constants.isConsideredPlayedEpsilon) {
    return true;
  }

  return false;
};
