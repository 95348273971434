import DataObject from '@audacy-clients/client-services/src/dataServices/DataObject';

import { IClientServicesList, IDataObjectWrapper } from './types';

export const wrapDataObject = <T extends DataObject>(dataObject: T): IDataObjectWrapper<T> => ({
  description: dataObject.getDescription(),
  getDataObject: () => dataObject,
  id: dataObject.getId(),
  image: dataObject.getImages()?.square,
  imageAlt: dataObject.getImages()?.alt,
  isFollowable: dataObject.isFollowable() || false,
  subtype: dataObject.getEntitySubtype(),
  entitySubtype: dataObject.getEntitySubtype(),
  title: dataObject.getTitle() ?? '',
  type: dataObject.getEntityType(),
  entityType: dataObject.getEntityType(),
  url: dataObject.getUrl() ?? undefined,
});

export const listFrom = <T>(array: T[]): IClientServicesList<T> => ({
  get: (i) => array[i],
  length: array.length,
});
