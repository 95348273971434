/**
 * Miscelleneous constants
 */

const minButtonSize = {
  coarsePointer: 44,
  finePointer: 24,
};

const borderRadius = 4;

export const Constants = { minButtonSize, borderRadius };
