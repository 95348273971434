import { Colors, Mixins, mqHover } from '~/styles';
import { baseButton, disabled } from '~/styles/button';
import { TStyles, TStyleSheet } from '~/types/emotion-styles';

export const buttonSizeStyles = (size: number): TStyles => ({
  height: size,
  width: size,
});

const styles: TStyleSheet = {
  baseButton,
  iconButton: {
    ...Mixins.accessibleMinButtonSize(),
    ...Mixins.transition(['color', 'background-color']),
    alignSelf: 'center',
    borderRadius: '100%',
  },
  defaultDarkTheme: {
    color: Colors.white,
    ...mqHover({
      color: Colors.white60,
    }),
  },
  defaultLightTheme: {
    color: Colors.audacyDarkNavy,
    ...mqHover({
      color: Colors.audacyDarkNavy60,
    }),
  },
  fillDarkTheme: {
    backgroundColor: Colors.white,
    color: Colors.audacyDarkNavy,
    ...mqHover({
      color: Colors.audacyDarkNavy60,
    }),
  },
  fillLightTheme: {
    backgroundColor: Colors.audacyDarkNavy,
    color: Colors.white,
    ...mqHover({
      color: Colors.white60,
    }),
  },
  hoverFillDarkTheme: {
    backgroundColor: 'transparent',
    color: Colors.white,
    ...mqHover({
      backgroundColor: Colors.white15,
      color: Colors.white60,
    }),
  },
  hoverFillLightTheme: {
    backgroundColor: 'transparent',
    color: Colors.audacyDarkNavy,
    ...mqHover({
      backgroundColor: Colors.audacyDarkNavy,
      color: Colors.white60,
    }),
  },
  outlineDarkTheme: {
    background: 'transparent',
    boxShadow: `0 0 0 1px ${Colors.white30}`,
    color: Colors.white,
    ...mqHover({
      color: Colors.white60,
    }),
  },
  outlineLightTheme: {
    background: 'transparent',
    boxShadow: `0 0 0 1px ${Colors.audacyDarkNavy60}`,
    color: Colors.audacyDarkNavy,
    ...mqHover({
      color: Colors.audacyDarkNavy60,
    }),
  },
  disabled,
};

export default styles;
