import { useEffect, useState, useRef } from 'react';

import {
  Announcements,
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useTranslation } from 'react-i18next';

import { Visibility } from '~/styles';

import Item, { IItem } from './Item';
import SortableListItem from './SortableListItem';
import styles from './styles';

interface ISortableProps<T> {
  isEditMode: boolean;
  items: T[];
  listId: string;
  renderItem({ item }: { item: T }): JSX.Element;
  onSortEnd?: (items: T[]) => void;
}

const SortableList = <T extends IItem>({
  isEditMode,
  items,
  listId,
  renderItem,
  onSortEnd,
}: ISortableProps<T>): JSX.Element => {
  const { t } = useTranslation();
  const [sortedItems, setSortedItems] = useState(items);
  const [activeItemId, setActiveItemId] = useState<string | null>(null);
  const activeItem = activeItemId && sortedItems.find((item) => item.id === activeItemId);
  const isSortable = isEditMode && items.length > 1;
  const reorderHelpTextId = `${listId}-help-text`;
  const isFirstAnnouncement = useRef(true);

  useEffect(() => {
    setSortedItems(items);
  }, [items]);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;

    setActiveItemId(String(active.id));
    isFirstAnnouncement.current = true;
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    setActiveItemId(null);

    if (over && active.id !== over.id) {
      setSortedItems((itemsArr) => {
        const oldIndex = itemsArr.findIndex((item) => item.id === active.id);
        const newIndex = itemsArr.findIndex((item) => item.id === over.id);
        const updatedQueue = arrayMove(itemsArr, oldIndex, newIndex);
        onSortEnd?.(updatedQueue);
        return updatedQueue;
      });
    }
  };

  const announcements: Announcements = {
    onDragStart({ active }) {
      return t('sortable.list.announcements.onDragStart', { title: active.data.current?.title });
    },
    onDragOver({ active, over }) {
      // The first `onDragOver` event doesn't need to be announced, because it is called
      // immediately after the `onDragStart` announcement and is redundant.
      // See this thread: https://github.com/clauderic/dnd-kit/issues/424
      // Solution: https://github.com/clauderic/dnd-kit/commit/8844cc719aaafea7f78d36d7a35698696a6853c0
      if (isFirstAnnouncement.current) {
        isFirstAnnouncement.current = false;
        return;
      }

      if (over) {
        return t('sortable.list.announcements.onDragOver.success', {
          activeTitle: active.data.current?.title,
          overIndex: over.data.current?.sortable.index + 1,
        });
      }

      return t('sortable.list.announcements.onDragOver.error');
    },
    onDragEnd({ active, over }) {
      if (over) {
        return t('sortable.list.announcements.onDragEnd.success', {
          activeTitle: active.data.current?.title,
          overIndex: over.data.current?.sortable.index + 1,
        });
      }

      return t('sortable.list.announcements.onDragEnd.error');
    },
    onDragCancel({ active }) {
      return t('sortable.list.announcements.onDragCancel', {
        activeTitle: active.data.current?.title,
      });
    },
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
      accessibility={{ announcements }}
    >
      <p css={Visibility.visHidden} id={reorderHelpTextId}>
        {t('sortable.list.reorderHelpText')}
      </p>
      <ol>
        <SortableContext items={sortedItems} strategy={verticalListSortingStrategy}>
          {sortedItems.map((item) => (
            <SortableListItem
              key={item.id}
              item={item}
              listId={listId}
              isSortable={isSortable}
              isEditMode={isEditMode}
              isReordering={activeItemId === item.id}
            >
              {renderItem({ item })}
            </SortableListItem>
          ))}
        </SortableContext>
      </ol>
      <DragOverlay css={styles.overlay}>
        {activeItem ? (
          <Item listId={listId} isSortable isEditMode item={activeItem} isDragging>
            {renderItem({ item: activeItem })}
          </Item>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default SortableList;
