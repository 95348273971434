import { useTranslation } from 'react-i18next';

import SectionWrapper from '~/components/SectionWrapper';
import TruncatedDescription from '~/components/TruncatedDescription';
import { useBreakpoints } from '~/hooks/use-breakpoints';
import { TStyles } from '~/types/emotion-styles';

import styles from './styles';

interface IAboutProps {
  containerCss?: TStyles;
  text: string;
  textCss?: TStyles;
}

const About = ({ containerCss, text, textCss }: IAboutProps): JSX.Element => {
  const { t } = useTranslation();
  const { greaterThan } = useBreakpoints();

  return (
    <SectionWrapper
      title={t('showScreen.about.header')}
      containerCss={[styles.sectionWrapper, containerCss || {}]}
      content={
        <TruncatedDescription
          numberOfLines={greaterThan.MD ? 4 : 3}
          shouldStripInteractiveTags
          text={text}
          textCss={textCss}
        />
      }
    />
  );
};

export default About;
