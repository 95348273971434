import { IMetadata } from '@audacy-clients/core/atoms/player';
import { PlayerState } from '@audacy-clients/core/types/player';

export const checkCollectionIdsForPlaying = (
  playState: PlayerState,
  itemIds?: string[],
  metadata?: IMetadata,
): boolean => {
  if (playState !== PlayerState.Playing || !itemIds) {
    return false;
  }

  const metadataId = metadata?.dataObject?.id;
  const episodeDataId = metadata?.episodeDataObject?.id;

  if (!metadataId && !episodeDataId) {
    return false;
  }

  return itemIds.some((id) => metadataId === id || episodeDataId === id);
};

export const checkCollectionIdsForInPlayer = (
  itemIds?: string[],
  metadata?: IMetadata,
): boolean => {
  const metadataId = metadata?.dataObject?.id;
  const episodeDataId = metadata?.episodeDataObject?.id;
  if ((!metadataId && !episodeDataId) || !itemIds) {
    return false;
  }
  return itemIds.some((id) => metadataId === id || episodeDataId === id);
};
