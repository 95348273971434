import { Fragment } from 'react';

import { TStyles } from '~/types/emotion-styles';

import styles from './styles';

interface IBulletedProps {
  bulletedCss?: TStyles;
  hasOverflowGradient?: boolean;
  title: string[];
}

const Bulleted = ({ bulletedCss, title = [] }: IBulletedProps): JSX.Element => {
  return (
    <div css={[styles.container, bulletedCss]}>
      {title.map((text, i) => (
        <Fragment key={i}>
          <span css={styles.text}>{text}</span>
          {i !== title.length - 1 && <div css={styles.bullet} />}
        </Fragment>
      ))}
    </div>
  );
};

export default Bulleted;
