import { EntityType } from '@audacy-clients/client-services/core';
import isArray from 'lodash/isArray';
import i18n from '~/i18n';

import { formatPublishDate } from '~/utils/date';

type IFormatEyebrowParams = {
  publishDate?: Date;
  updateDate?: Date;
  subtitle?: string | Array<string>;
  entityType?: EntityType;
};

export const formatEyebrow = ({
  publishDate,
  updateDate,
  subtitle,
  entityType,
}: IFormatEyebrowParams): Array<string> => {
  let eyebrow = [];

  if (publishDate && entityType !== EntityType.COLLECTION) {
    eyebrow.push(formatPublishDate(publishDate));
  }

  if (entityType === EntityType.COLLECTION && updateDate) {
    eyebrow.push(formatPublishDate(updateDate));
  }

  if (subtitle) {
    if (isArray(subtitle)) {
      eyebrow = [...eyebrow, ...subtitle];
    } else {
      eyebrow.push(subtitle);
    }
  }
  return eyebrow;
};

export function getExpirationMessage(replayableUntilDateTime: string): string {
  const now = new Date().getTime();
  const expireDate = new Date(replayableUntilDateTime).getTime();

  const diffTime = expireDate - now;

  // Convert milliseconds to days
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays === 0) {
    return i18n.t('global.expiresToday');
  } else if (diffDays > 0) {
    return i18n.t('global.expires', { days: diffDays });
  }

  return '';
}
