import Icon from '~/components/Icon';
import { TOptionsMenuItemProps } from '~/components/OptionsMenu/types';
import useLinkOrButton from '~/hooks/use-link-or-button';
import { LinkType } from '~/types/links';

import styles from './styles';

const OptionsMenuItem = ({
  as = LinkType.Anchor,
  icon,
  itemCss = {},
  text,
  ...rest
}: TOptionsMenuItemProps): JSX.Element => {
  const isAnchor = as === LinkType.Anchor;

  const Item = (
    <>
      {icon && <Icon name={icon} sizeInPx={24} iconCss={styles.icon} ariaHidden />}
      <span>{text}</span>
    </>
  );

  const itemStyles = [styles.item, itemCss];
  const linkStyles = isAnchor
    ? itemStyles
    : [itemStyles, 'isDisabled' in rest && rest.isDisabled ? styles.disabled : {}];

  return useLinkOrButton({ props: { as, ...rest }, children: Item, styles: linkStyles });
};

export default OptionsMenuItem;
