import {
  IPlayerControlsState,
  PlayerButtonState,
  ScrubberBarType,
} from '@audacy-clients/client-services/src/audioServices/streamers/types';

export const PLAYER_CONTROL_STATE_DEFAULT: Readonly<IPlayerControlsState> = {
  backToLiveButton: PlayerButtonState.Hidden,
  ff15sButton: PlayerButtonState.Hidden,
  nextTrackButton: PlayerButtonState.Hidden,
  pauseButton: PlayerButtonState.Hidden,
  playButton: PlayerButtonState.Hidden,
  prevTrackButton: PlayerButtonState.Hidden,
  replayButton: PlayerButtonState.Hidden,
  rw15sButton: PlayerButtonState.Hidden,
  scrubberBarType: ScrubberBarType.ElapsedTime,
  scrubberIsUserControlled: false,
  skipButton: PlayerButtonState.Hidden,
  skipsRemaining: 0,
  stopButton: PlayerButtonState.Hidden,
  thumbsUpButton: PlayerButtonState.Hidden,
  scrubberIsDisabled: false,
  shouldDisplayLiveIndicator: false,
  restartShowButton: PlayerButtonState.Hidden,
  previousChapterButton: PlayerButtonState.Hidden,
  nextChapterButton: PlayerButtonState.Hidden,
};

export const PLAYER_CONTROL_TIMINGS = {
  skip10: 10,
  skip15: 15,
};

export const NOT_AVAILABLE = 'Not Available';
export const MIN_TIME_OFFSET_TO_ENABLE_RW_FF = 1;
