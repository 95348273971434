const optInGppString = 'DBABrGA~BVQqAAAACWA.QA~BUoAAAJY.QA~BVoAABY~BVoAAFg.QA~BVKAAAWA~BVoAAAFg.QA';

const optOutGppString =
  'DBABrGA~BVQVAAAABWA.QA~BUUAAAFY.QA~BVUAABY~BVUAAFg.QA~BVFAAAWA~BVUAAAFg.QA';

/**
 * Get the Global Privacy Platform (gpp) string used to opt in/out of personalized ads
 * GPP Decoder: https://iabgpp.com/
 * GPP Field Definitions: https://help.consentmanager.net/books/cmp/page/using-the-iab-gpp
 * @param optIn Whether the user opted into personalized ads
 * @returns encoded string e.g. DBAA
 */
export const getGppString = ({ optIn }: { optIn: boolean }) => {
  return optIn ? optInGppString : optOutGppString;
};
