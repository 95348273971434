import { useCallback, useEffect } from 'react';

import { atom, useRecoilState } from 'recoil';

export type EditableItems =
  | 'myQueue'
  | 'followed'
  | 'recents'
  | 'onDemands'
  | 'stations'
  | 'topics'
  | 'playlists'
  | 'followed';

export interface IEditModeData {
  isChanged?: boolean;
  isEditMode: boolean;
  selectedItems: string[];
}

export const defaultEditMode = {
  myQueue: { isChanged: false, isEditMode: false, selectedItems: [] },
  followed: { isEditMode: false, selectedItems: [] },
  onDemands: { isEditMode: false, selectedItems: [] },
  recents: { isEditMode: false, selectedItems: [] },
  stations: { isEditMode: false, selectedItems: [] },
  topics: { isEditMode: false, selectedItems: [] },
  playlists: { isEditMode: false, selectedItems: [] },
};

export const editModeAtom = atom<Record<EditableItems, IEditModeData>>({
  default: defaultEditMode,
  key: 'EditMode',
});

export interface IEditModeHook {
  isChanged: boolean | undefined;
  isEditMode: boolean;
  selectedItems: string[];
  setIsChanged: (newState: boolean) => void;
  setIsEditMode: (newState: boolean) => void;
  setSelectedItems: (newSelectedItems: string[]) => void;
}

interface IGlobalEditModeHook {
  resetAllEditMode: () => void;
}

export function useGlobalEditMode(): IGlobalEditModeHook {
  const [, setEditModeState] = useRecoilState(editModeAtom);

  const resetAllEditMode = useCallback((): void => {
    setEditModeState(defaultEditMode);
  }, [setEditModeState]);

  return {
    resetAllEditMode,
  };
}

export default function useEditMode(
  itemName: EditableItems,
  defaultSelected?: string[],
): IEditModeHook {
  const [editModeState, setEditModeState] = useRecoilState(editModeAtom);

  const changeState = useCallback(
    (data: Partial<IEditModeData>): void => {
      setEditModeState((oldIsEditModeState) => ({
        ...oldIsEditModeState,
        [itemName]: { ...oldIsEditModeState[itemName], ...data },
      }));
    },
    [itemName, setEditModeState],
  );

  useEffect(() => {
    if (defaultSelected) {
      changeState({ selectedItems: defaultSelected });
    }
  }, [changeState, defaultSelected]);

  const setIsEditMode = useCallback(
    (newState: boolean): void => {
      changeState({ isEditMode: newState, selectedItems: [] });
    },
    [changeState],
  );

  const setSelectedItems = useCallback(
    (newSelectedItems: string[]): void => {
      changeState({ selectedItems: newSelectedItems });
    },
    [changeState],
  );

  const setIsChanged = useCallback(
    (newState: boolean): void => {
      changeState({ isChanged: newState });
    },
    [changeState],
  );

  return {
    isChanged: editModeState[itemName].isChanged,
    isEditMode: editModeState[itemName].isEditMode,
    selectedItems: editModeState[itemName].selectedItems,
    setIsChanged,
    setIsEditMode,
    setSelectedItems,
  };
}
