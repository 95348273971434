export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Search</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.022 19.264A8.1 8.1 0 1 0 20.041 8.402 8.1 8.1 0 0 0 8.022 19.264Zm12.172 1.93A9.6 9.6 0 0 1 7.594 6.71a9.6 9.6 0 0 1 13.56.685c3.298 3.65 3.272 9.137.112 12.75l5.795 5.794L26 27l-5.806-5.806Z"
      fill="currentColor"
    />
  </svg>
);
