/* eslint-disable @typescript-eslint/no-explicit-any */
import AnalyticServices from './analyticServices';
import AudioServices from './audioServices';
import { ECollectionPlaybackMode } from './audioServices/types';
import ChatServices from './chatServices/ChatServices';
import clientServicesConfig, { type IClientServicesConfig } from './Config';
import { Platform } from './Constants';
import Container, {
  type ILocationProvider,
  type INetworkStateProvider,
  type IOnlineStatusProvider,
  type IServiceBus,
  type TConnectivityType,
} from './Container';
import DataServices from './dataServices/DataServices';
import PersonalizationServices, { UnauthenticatedServices } from './personalizationServices';
import type Service from './Service';

export default class ClientServices {
  private globalLoggerShim = Container;
  private onlineStatusProvider: IOnlineStatusProvider = Container;
  private locationProvider: ILocationProvider = Container;
  private networkStateProvider: INetworkStateProvider = Container;
  private serviceBus: IServiceBus = Container;
  private onReadyPromises: Array<Promise<void>> = [];

  constructor(config: IClientServicesConfig) {
    // Expose the config globally
    Object.assign(clientServicesConfig, config);

    this.onlineStatusProvider.online = false;

    const rootLogger = config.logger;
    const globalLogger = rootLogger.getSubLogger({ name: 'Global' });
    this.globalLoggerShim.error = globalLogger.error;
    this.globalLoggerShim.log = globalLogger.log;
    this.globalLoggerShim.warn = globalLogger.warn;
    this.globalLoggerShim.ddLogger = config.ddLogger;

    this.initServices(config);
  }

  getAnalyticServices = () => this.serviceBus.analyticServices;
  getAudioServices = () => this.serviceBus.audioServices;
  getChatServices = () => this.serviceBus.chatServices;
  getDataServices = () => this.serviceBus.dataServices;
  getPersonalizationServices = () => this.serviceBus.personalizationServices;
  getOnlineStatus = () => this.onlineStatusProvider.online;

  onReady = () =>
    Promise.all(this.onReadyPromises).then(() => {
      /* converts Promise<void[]> to Promise<void> */
    });

  setLatLong(lat: number, long: number) {
    this.locationProvider.lat = lat;
    this.locationProvider.long = long;
  }

  setOnlineStatus(isOnline: boolean) {
    this.onlineStatusProvider.online = isOnline;
  }

  setConnectivityType(connectivityType: TConnectivityType) {
    this.networkStateProvider.connectivityType = connectivityType;
  }

  initServices(config: IClientServicesConfig) {
    this.serviceBus.dataServices = new DataServices({
      ...config.dataServices,
    });
    if (config.personalizationServices.platform === Platform.WEB) {
      this.serviceBus.unauthenticatedServices = new UnauthenticatedServices({
        dataStore: config.personalizationServices.dataStore,
      });
    }

    this.serviceBus.personalizationServices = new PersonalizationServices({
      ...config.personalizationServices,
      playerVolume: config.audioServices.player.getVolume(),
      profileApi: this.serviceBus.dataServices,
      experienceApi: this.serviceBus.dataServices,
      logger: config.logger.getSubLogger({
        name: 'PersonalizationServices',
      }),
    });
    this.serviceBus.analyticServices = new AnalyticServices({
      ...config.analyticServices,
      eventSender: this.serviceBus.dataServices,
      marketingDataProvider: this.serviceBus.personalizationServices.marketingDataProvider,
      networkStateProvider: this.networkStateProvider,
      logger: config.logger.getSubLogger({ name: 'AnalyticServices' }),
    });
    this.serviceBus.audioServices = new AudioServices({
      ...config.audioServices,
      personalizationProvider: this.serviceBus.personalizationServices,
      unauthenticatedProvider: this.serviceBus.unauthenticatedServices,
      analyticsProvider: this.serviceBus.analyticServices,
      playerSettingsProvider: this.serviceBus.personalizationServices,
      credentialsProvider: this.serviceBus.personalizationServices,
      marketingDataProvider: this.serviceBus.personalizationServices.marketingDataProvider,
      locationProvider: this.locationProvider,
      logger: config.logger.getSubLogger({ name: 'AudioServices' }),
      dataProvider: this.serviceBus.dataServices,
      ddError: config.ddError,
      ddLogger: config.ddLogger,
      featureFlags: config.featureFlags,
    });
    this.serviceBus.chatServices = new ChatServices();

    this.serviceBus.personalizationServices.addQueueListener((queue) => {
      if (
        this.serviceBus.audioServices.getCollectionPlaybackMode() === ECollectionPlaybackMode.Queue
      ) {
        this.serviceBus.audioServices.updateCollection(queue.items);
      }
    });

    this.serviceBus.personalizationServices.addPlaybacksListener((playbacks) =>
      this.serviceBus.audioServices.updatePlaybacks(playbacks),
    );

    const serviceConfig: { [Key in keyof IServiceBus]?: Service } = {
      chatServices: this.serviceBus.chatServices,
      dataServices: this.serviceBus.dataServices,
    };

    this.onReadyPromises = [];

    for (const service of Object.values(serviceConfig)) {
      const promise = service.postServiceInit(this.serviceBus);
      if (promise) {
        this.onReadyPromises.push(promise);
      }
    }
  }
}
