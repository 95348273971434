import { memo } from 'react';

import { IStandaloneChapter } from '@audacy-clients/core/atoms/wrappers/types';
import { format } from 'date-fns';
import { t } from 'i18next';

import HorizontalEntityCard from '~/components/Entity/HorizontalEntityCard/HorizontalEntityCard';
import { Icons } from '~/components/Icon/constants';
import Link from '~/components/Link';
import SectionWrapper from '~/components/SectionWrapper';
import { TStyles } from '~/types/emotion-styles';
import { LinkType } from '~/types/links';
import { formatPublishDate } from '~/utils/date';

import styles from './styles';

interface IStandaloneChapterEpisodeCardProps {
  standaloneChapter: IStandaloneChapter;
  containerCss?: TStyles;
}

/**
 * This component is used to display the standalone chapter episode card
 * inside the FullScreenPlayer when playing a standalone chapter.
 */
const StandaloneChapterEpisodeCard = ({
  standaloneChapter,
  containerCss,
}: IStandaloneChapterEpisodeCardProps): JSX.Element => {
  const { parentEpisodeUrl, parentImage, parentTitle, publishDate } = standaloneChapter;

  let title = parentTitle;
  let eyebrow = '';

  if (publishDate) {
    eyebrow = `${formatPublishDate(publishDate)} · ${parentTitle}`;
    title = format(publishDate, 'EEEE - LLL d, yyyy');
  }

  return (
    <SectionWrapper
      title={t('chapterScreen.playingFrom.header')}
      containerCss={[styles.sectionWrapper, containerCss || {}]}
      content={
        <div css={[styles.container]}>
          <HorizontalEntityCard imageURIBase={parentImage} title={title} eyebrow={eyebrow} />
          <Link
            as={LinkType.Anchor}
            hasIconAfter
            icon={Icons.CaretRight}
            isTiny
            css={[styles.episodeLink]}
            href={parentEpisodeUrl}
          >
            Go to full episode
          </Link>
        </div>
      }
    />
  );
};

export default memo(StandaloneChapterEpisodeCard, isPropsEqual);

function isPropsEqual(
  { standaloneChapter: prev }: IStandaloneChapterEpisodeCardProps,
  { standaloneChapter: next }: IStandaloneChapterEpisodeCardProps,
): boolean {
  return (
    prev?.parentEpisodeUrl === next?.parentEpisodeUrl &&
    prev?.parentImage === next?.parentImage &&
    prev?.parentTitle === next?.parentTitle &&
    prev?.publishDate?.toString() === next?.publishDate?.toString()
  );
}
