import { BasePath, Path } from '~/routes';

const music = {
  iconRef: 'Music',
  label: 'Music',
  id: 'music',
  href: '/music',
};

const podcasts = {
  iconRef: 'Mic',
  label: 'Podcasts',
  id: 'podcasts',
  href: '/podcast',
};

const home = {
  iconRef: 'Home',
  label: 'For you',
  id: 'home',
  href: Path.Home,
};

const sports = {
  iconRef: 'Sports',
  label: 'Sports',
  id: 'sports',
  href: '/sports',
};

const news = {
  iconRef: 'Broadcast',
  label: 'News',
  id: 'news',
  href: '/news',
};

const all = [
  {
    label: 'All podcasts',
    id: 'all-podcasts',
    href: '/podcast/all',
  },
  {
    label: 'All stations',
    id: 'all-stations',
    href: '/stations',
  },
];

const myAudio = {
  title: {
    label: 'My audio',
    id: 'myaudio',
    href: BasePath.MyAudio,
  },
};

const settings = {
  title: {
    label: 'Settings',
    id: 'settings',
    href: Path.Account,
  },
};

const devMenu = {
  label: 'Dev Utilities',
  id: 'devUtils',
  href: Path.Dev,
};

export default {
  main: [home, music, podcasts, sports, news],
  all,
  devMenu,
  myAudio,
  settings,
};
