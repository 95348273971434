/**
 * Re-export modules
 */
export { LocalStorageKey, ReactQueryStorageKey } from './personalizationServices/constants';

/**
 * Enum containing the available API versions
 */
export enum ApiVersion {
  /**
   * Version 1 of the API
   */
  V1 = 'v1',
  /**
   * Version 2 of the API
   */
  V2 = 'v2',
  /**
   * Version 3 of the API
   */
  V3 = 'v3',
}

/**
 * Enumeration of subtypes for entities.
 * @enum {string}
 */
export type EntitySubtype =
  | ArticleSubtype
  | AudioClipSubtype
  | CollectionSubType
  | EpisodeSubType
  | OrganizationSubtype
  | ShowSubtype
  | PersonSubtype
  | StationSubType
  | TagSubtype
  | StandaloneChapterSubType;

/**
 * Enum for different types of article subtypes
 * @enum {string}
 */
export enum ArticleSubtype {
  ARTICLE = 'ARTICLE',
}

/**
 * Enum for different types of audio clip subtypes
 * @enum {string}
 */
export enum AudioClipSubtype {
  ADVERT = 'ADVERT',
  CLIP = 'CLIP',
  SEGMENT = 'SEGMENT',
  SONG = 'SONG',
  SOUNDER = 'SOUNDER',
}

/**
 * Enum for the possible audio states.
 */
export enum AudioState {
  ATL_PAUSE,
  ATL_PLAY,
  ATL_REWOUND_PLAY,
  EXCLUSIVE_ADPAUSE,
  EXCLUSIVE_ADPLAY,
  EXCLUSIVE_EXAD,
  EXCLUSIVE_PAUSE,
  EXCLUSIVE_PLAY,
  LIVE_AD,
  LIVE_EXAD,
  LIVE_PLAY,
  LIVE_STOP,
  PODCAST_EXAD,
  PODCAST_PAUSE,
  PODCAST_PLAY,
  REWIND_NOREPLAY_ADPLAY,
  REWIND_NOREPLAY_EXAD,
  REWIND_NOREPLAY_PLAY,
  REWIND_NOREPLAY_STOP,
  REWIND_REPLAY_ADPLAY,
  REWIND_REPLAY_EXAD,
  REWIND_REPLAY_PLAY,
  REWIND_REPLAY_STOP,
  REWOUND_ADPAUSE,
  REWOUND_ADPLAY,
  REWOUND_EXAD,
  REWOUND_PAUSE,
  REWOUND_PLAY,
}

/**
 * Enum for the possible audio state strings.
 */
export const AudioStateString = {
  [AudioState.ATL_PAUSE]: 'ATL Paused',
  [AudioState.ATL_PLAY]: 'ATL Playing',
  [AudioState.ATL_REWOUND_PLAY]: 'ATL Playing Rewound',
  [AudioState.EXCLUSIVE_ADPAUSE]: 'Exclusive Ad Paused',
  [AudioState.EXCLUSIVE_ADPLAY]: 'Exclusive Ad Playing',
  [AudioState.EXCLUSIVE_EXAD]: 'Exclusive External Ad',
  [AudioState.EXCLUSIVE_PAUSE]: 'Exclusive Paused',
  [AudioState.EXCLUSIVE_PLAY]: 'Exclusive Playing',
  [AudioState.LIVE_AD]: 'Live Ad Playing',
  [AudioState.LIVE_EXAD]: 'Live External Ad',
  [AudioState.LIVE_PLAY]: 'Live Playing',
  [AudioState.LIVE_STOP]: 'Live Stopped',
  [AudioState.PODCAST_EXAD]: 'Podcast External Ad',
  [AudioState.PODCAST_PAUSE]: 'Podcast Paused',
  [AudioState.PODCAST_PLAY]: 'Podcast Playing',
  [AudioState.REWIND_NOREPLAY_ADPLAY]: 'Rewind No Replay Ad Playing',
  [AudioState.REWIND_NOREPLAY_EXAD]: 'Rewind No Replay None External Ad',
  [AudioState.REWIND_NOREPLAY_PLAY]: 'Rewind No Replay None Playing',
  [AudioState.REWIND_NOREPLAY_STOP]: 'Rewind No Replay None Stopped',
  [AudioState.REWIND_REPLAY_ADPLAY]: 'Rewind Replay Ad Playing',
  [AudioState.REWIND_REPLAY_EXAD]: 'Rewind Replay External Ad',
  [AudioState.REWIND_REPLAY_PLAY]: 'Rewind Replay Playing',
  [AudioState.REWIND_REPLAY_STOP]: 'Rewind Replay Stopped',
  [AudioState.REWOUND_ADPAUSE]: 'Rewound Ad Paused',
  [AudioState.REWOUND_ADPLAY]: 'Rewound Ad Playing',
  [AudioState.REWOUND_EXAD]: 'Rewound External Ad',
  [AudioState.REWOUND_PAUSE]: 'Rewound Paused',
  [AudioState.REWOUND_PLAY]: 'Rewound Playing',
};

/**
 * Enumeration of possible authentication states
 */
export enum AuthState {
  ANON,
  AUTH,
  NONE,
}

export enum AuthStateKeys {
  isLoggedIn = 'isLoggedIn',
  isSoftDeleted = 'isSoftDeleted',
  profile = 'profile',
  authState = 'authState',
  incompleteProfileData = 'incompleteProfileData',
}

/**
 * Constant string for chat host prefix
 */
export const CHAT_HOST_PREFIX = '(H)';

/**
 * Enumeration of possible click features
 */
export enum ClickFeature {
  ANDROID_AUTO = 'ANDROID_AUTO',
  AUDACY_ASSISTANT = 'AUDACY_ASSISTANT',
  CARPLAY = 'CARPLAY',
  CHAPTER = 'CHAPTER',
  CTA_BUTTON = 'CTA_BUTTON',
  LIST_ITEM = 'LIST_ITEM',
  FULL_PLAYER = 'FULL_PLAYER',
  MINI_PLAYER = 'MINI_PLAYER',
  EXTERNAL = 'EXTERNAL',
  MENU = 'MENU',
  MODULE = 'MODULE',
  QUEUE = 'QUEUE',
  QUEUE_NEXT = 'QUEUE_NEXT',
  QUEUE_PREVIOUS = 'QUEUE_PREVIOUS',
  TEXT_LINK = 'TEXT_LINK',
  LOGOUT_BUTTON = 'LOGOUT_BUTTON',
  PLAYLIST = 'PLAYLIST',
  PLAYLIST_NEXT = 'PLAYLIST_NEXT',
  PLAYLIST_PREVIOUS = 'PLAYLIST_PREVIOUS',
  TRANSCRIPTION = 'TRANSCRIPTION',
}

/**
 * Enumeration of possible content types
 */
export enum ContentType {
  FULL = 'FULL',
  SUMMARY = 'SUMMARY',
}

/**
 * Enumeration of possible collection subtypes
 */
export enum CollectionSubType {
  PLAYLIST = 'PLAYLIST',
}

/**
 * Enumeration of possible environments
 */
export enum Environment {
  DEV = 'DEV',
  PROD = 'PROD',
  QA = 'QA',
  STG = 'STG',
}

/**
 * Enumeration of possible carousel types
 */
export enum CarouselType {
  SMALL = 'SMALL',
}

/**
 * Enumeration of possible categories
 */
export enum Category {
  MUSIC = 'music',
  NEWS_TALK = 'news-talk',
  SPORTS = 'sports',
  Playlist = 'playlist',
}

/**
 * Constants for video ad integration
 */
export const VideoAdConstants = {
  IMA3_LIBRARY_URL: 'https://imasdk.googleapis.com/js/sdkloader/ima3.js',
  // See https://support.google.com/admanager/answer/10678356
  VIDEO_URL:
    'https://pubads.g.doubleclick.net/gampad/ads?sz=640x480|1280x720&pp=Pre-Roll%20Video&iu=/21674100491/[doubleclick_prerolltag]/livestreamplayer&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&url=[referrer_url]&description_url=[description_url]&correlator=[timestamp]&adk=4166447473&ciu_szs&cust_params=[cust_params]&dt=1476275385063&flash=23.0.0.185&frm=0&ged=ve4_td8_tt1_pd8_la8000_er28.234.574.1204_vi0.0.710.1437_vp100_eb24171&osd=6&scor=3434674995068928&sdkv=3.249.0&sdr=1&u_ah=812&u_asa=1&u_aw=1440&u_cd=24&u_h=900&u_his=2&u_java=false&u_nmime=7&u_nplug=5&u_tz=-240&u_w=1440&videoad_start_delay=0&max_ad_duration=31000',
  DEBUG_VIDEO_URL:
    'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_preroll_skippable&sz=640x480&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=',
  VIDEO_AD_COOLDOWN_MS: 5 * 60 * 1000, // 5 mins, minimum value 5000 ms
  AD_LOAD_TIMEOUT: 4000, // ms
} as const;

/**
 * Enumeration of possible module types
 */
export enum ModuleType {
  BANNER_AD = 'BANNER_AD',
  CAROUSEL = 'CAROUSEL',
  CAROUSEL_PERSONALIZED = 'CAROUSEL_PERSONALIZED',
  CONTENT_ROW = 'CONTENT_ROW',
  COLLECTION_CARD = 'COLLECTION_CARD',
  COLLECTION_PLAYLIST_CARD = 'COLLECTION_PLAYLIST_CARD',
  COLLECTION_TOPIC_CARD = 'COLLECTION_TOPIC_CARD',
  CONTINUE_LISTENING = 'CONTINUE_LISTENING',
  EARLIER_TODAY = 'EARLIER_TODAY',
  ENTITY_CARD_IMAGE_GROUP = 'ENTITY_CARD_IMAGE_GROUP',
  ENTITY_CARD_FEATURED = 'ENTITY_CARD_FEATURED',
  ENTITY_CARD_HORIZONTAL = 'ENTITY_CARD_HORIZONTAL',
  ENTITY_CARD_VERTICAL = 'ENTITY_CARD_VERTICAL',
  ENTITY_CARD_COLLECTION = 'ENTITY_CARD_COLLECTION',
  LIST = 'LIST',
  ONBOARDING_LEGACY_FOLLOWS = 'ONBOARDING_LEGACY_FOLLOWS',
  PRIMARY_ACTION = 'PRIMARY_ACTION',
  SAFE_SECTION_WRAPPER = 'SAFE_SECTION_WRAPPER',
  SEARCH_PILL = 'SEARCH_PILL',
  SECTION_WRAPPER = 'SECTION_WRAPPER',
  SHOW_LIVE_NOW_AND_PREV = 'SHOW_LIVE_NOW_AND_PREV',
  SQUARE_AD = 'SQUARE_AD',
  STATION_LIVE_NOW = 'STATION_LIVE_NOW',
  SUB_NAVIGATION = 'SUB_NAVIGATION',
  VIEW_TITLE = 'VIEW_TITLE',
  TEXT_BLOCK = 'TEXT_BLOCK',
  RECENTLY_PLAYED = 'RECENTLY_PLAYED',
  RECENTLY_PLAYED_STATIONS = 'RECENTLY_PLAYED_STATIONS',
  RECENTLY_PLAYED_STATIONS_LIVE = 'RECENTLY_PLAYED_STATIONS_LIVE',
  SEARCH_FIELD = 'SEARCH_FIELD',
  SONG_HISTORY = 'SONG_HISTORY',
  PREVIEW_SONG_HISTORY = 'PREVIEW_SONG_HISTORY',
  PLAYLIST_ITEM_LIST = 'PLAYLIST_ITEM_LIST',
  PICKUP_LEFT_OFF = 'PICKUP_LEFT_OFF',
}

/**
 * Enum for the different types of entities
 */
export enum EntityType {
  ARTICLE = 'ARTICLE',
  CLIP = 'CLIP',
  COLLECTION = 'COLLECTION',
  EPISODE = 'EPISODE',
  GEOGRAPHY = 'GEOGRAPHY',
  ORGANIZATION = 'ORGANIZATION',
  PERSON = 'PERSON',
  STATION = 'STATION',
  SHOW = 'SHOW',
  TAG = 'TAG',
  PLAYLIST = 'PLAYLIST', // ???? - NPL
  STANDALONE_CHAPTER = 'CHAPTER',
  TOPIC = 'TOPIC',
}

/**
 * Constant mapping of environments to their corresponding host URLs
 */
export const EnvironmentHosts = Object.freeze({
  [Environment.DEV]: 'https://api.dev.audacy.com',
  [Environment.PROD]: 'https://api.audacy.com',
  [Environment.QA]: 'https://api.qa.audacy.com',
  [Environment.STG]: 'https://stageapi.audacy.com',
});

/**
 * Enumeration of possible entity types
 */
export enum EntitiesType {
  ARTICLE = 'ARTICLE',
  CLIP = 'CLIP',
  COLLECTION = 'COLLECTION',
  EPISODE = 'EPISODE',
  GEOGRAPHY = 'GEOGRAPHY',
  ORGANIZATION = 'ORGANIZATION',
  PERSON = 'PERSON',
  STATION = 'STATION',
  SHOW = 'SHOW',
  TAG = 'TAG',
}

/**
 * Enumeration of possible episode sub-types
 */
export enum EpisodeSubType {
  BROADCAST_SHOW_EPISODE = 'BROADCAST_SHOW_EPISODE',
  PODCAST_EPISODE = 'PODCAST_EPISODE',
  HOST_CREATED_EPISODE = 'HOST_CREATED_EPISODE',
}

/**
 * Enumeration of possible chapter sub-types
 */
export enum StandaloneChapterSubType {
  BROADCAST_EPISODE_CHAPTER = 'BROADCAST_EPISODE_CHAPTER',
}

/**
 * Enumeration of object types
 */
export enum ObjectType {
  UNKNOWN = -1,
  ATL_HOST_LIST = 0,
  CHAPTER = 1,
  CLIP = 2,
  COLLECTION = 3,
  EPISODE = 4,
  EPISODE_LIST = 5,
  GENRE_LIST = 6,
  MARKET_LIST = 7,
  PODCAST = 8,
  PODCAST_CATEGORY_LIST = 9,
  PODCAST_EPISODE = 10,
  PODCAST_EPISODE_LIST = 11,
  PODCAST_LIST = 12,
  SEARCH_RESULT_LIST = 13,
  SEGMENT_LIST = 14,
  SHOW = 15,
  SHOW_EPISODE = 16,
  SHOW_EPISODE_LIST = 17,
  SHOW_LIST = 18,
  SHOW_SUMMARY = 19,
  SONG = 20,
  SONG_LIST = 21,
  STANDALONE_CHAPTER = 22,
  STATION = 23,
  STATION_LIST = 24,
  STATION_SUMMARY = 25,
  TAG = 26,
  TOPIC = 27,
}

/**
 * Enumeration of organization subtypes
 */
export enum OrganizationSubtype {
  ORGANIZATION = 'ORGANIZATION',
}

/**
 * Enumeration of permission actions
 */
export enum PermissionAction {
  IDFA_PERMISSION = 'IDFA_PERMISSION',
  LOCATION_PERMISSION = 'LOCATION_PERMISSION',
  NOTIFICATIONS_PERMISSION = 'NOTIFICATIONS_PERMISSION',
}

/**
 * Enumeration of person subtypes
 */
export enum PersonSubtype {
  PERSON = 'PERSON',
}

/**
 * Enumeration of platforms
 */
export enum Platform {
  ANDROID,
  ANDROID_CREATOR,
  CS_TEST,
  IOS,
  IOS_CREATOR,
  WEB,
}

/**
 * Enumeration of source strings
 */
export const SourceStringsMap: { [key in Platform]?: string } = {
  [Platform.ANDROID]: 'androidA2',
  [Platform.IOS]: 'iosA2',
  [Platform.WEB]: 'webA2',
} as const;

/**
 * Enumeration of player action timings
 */
export const PlayerActionTimings = {
  CONTINUE_LISTEN_DURATION: 60000, // milliseconds // 60 seconds
  CONTINUE_LISTEN_DURATION_10: 60000 * 10, // milliseconds // 10 minutes
} as const;

/**
 * Enumeration of Resume point timings
 */
export const SaveResumePointTimings = {
  RESUME_POINT_INTERVAL_MSEC: 5000, // milliseconds // 5 seconds
} as const;

/**
 * Enumeration of podcast sort options
 */
export enum PodcastSort {
  DATE_ASC = 'DATE_ASC',
  DATE_DESC = 'DATE_DESC',
}

/**
 * Enumeration of podcast types
 */
export enum PodcastType {
  EPISODIC = 'episodic',
  SERIAL = 'serial',
}

/**
 * Enumeration of preferences
 */
export enum Preference {
  LIKE = 'like',
  DISLIKE = 'dislike',
}

// 3 days in milliseconds
export const MAX_CACHE_EXPIRATION = 259200000;
// 4 hours in milliseconds
export const SCHEDULE_CACHE_EXPIRATION = 14400000;
// 1 minute in milliseconds;
export const LIVE_CHAPTER_CACHE_EXPIRATION = 60000;

// 12 hours in milliseconds, initially for use with "getFollows"
export const TWELVE_HOURS = 12 * 60 * 60 * 1000;
export const FOLLOWS = 'follows';

// 5 minutes
export const FIVE_MINUTES = 5 * 60 * 1000;

export enum Vast {
  DUMMY_STATION_ID = '6974', // this id should work every time
  BROKEN_ID = '0000', // this id should be broken every time
  CURRENT_CAMPAIGN_ID = '9437', // as of 5/9/24 this is an id of a station with a current campaign, will need to be updated over time
  IMPRESSION_TEMPLATES = 'ads[0].impressionURLTemplates',
  VAST_URL = 'https://vader.amperwave.net/api/v1/engine/gateway/audio/',
  QA_VAST_URL = 'https://qa-vader.amperwave.net/api/v1/engine/instream_preroll/audio/',
  // Test google ad is 10 seconds of piano and a dummy companion ad as well
  TEST_GOOGLE_AD = `https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=`,
}

/**
 * Preroll Related Constants
 */
export enum Preroll {
  AUDIO_PREROLL = 'AUDIO_PREROLL',
  AUDACY_OWNED_AND_OPERATED_CODE = '501-1',
  DO_NOT_PLAY_PREROLL = 'Do Not Play Preroll',
}

/**
 * Enumeration of show subtypes
 */
export enum ShowSubtype {
  DIGITAL = 'DIGITAL_SHOW',
  BROADCAST = 'BROADCAST_SHOW',
  HOST_CREATED = 'HOST_CREATED',
  PODCAST = 'PODCAST',
  PLAYLIST = 'PLAYLIST',
}

/**
 * Enumeration of tag subtypes
 */
export enum TagSubtype {
  TEAM = 'Team',
  LEAGUE = 'League',
  GENRE = 'Genre',
}

/**
 * Enumeration of sleep timer events
 */
export enum SleepTimerEvents {
  CANCELLED,
  DURATION_SET,
  ENDED,
  EPISODE_SET,
}

/**
 * Enumeration of station subtypes
 */
export enum StationSubType {
  BROADCAST = 'BROADCAST_RADIO',
  INTERNET = 'INTERNET_RADIO',
  EXCLUSIVE = 'EXCLUSIVE_STATION',
  TOPIC = 'TOPIC_BASED',
  PERSONALIZED = 'PERSONALIZED_RADIO',
  PODCAST = 'PODCAST_EPISODE',
}

/**
 * Enumeration of stream errors
 */
export enum StreamErrors {
  EXPIRED,
  FORBIDDEN,
  NOT_SKIPPABLE,
  PLAYER_ABORT,
  PROVISIONING_FAILURE,
  STREAM_NOT_LOADED,
  SW_STREAM_FAILURE,
  UNKNOWN_ERROR,
}

/**
 * Enumeration of stream providers
 */
export enum StreamProvider {
  AMPERWAVE = 'AMPERWAVE',
  STREAMING_OTHER = 'STREAMING_OTHER',
  STREAM_GUYS = 'STREAM_GUYS',
  TRITON = 'TRITON',
  WIDE_ORBIT = 'WIDE_ORBIT',
  SUPERHIFI = 'SUPERHIFI',
}

/**
 * Enumeration of trigger times
 */
export const TriggerTime = {
  FAR_TRIGGER: 60,
  NEAR_TRIGGER: 30,
} as const;

/**
 * Enumeration of list states
 */
export enum ListState {
  BOTH,
  NONE,
  ONLY_NEXT,
  ONLY_PREV,
}

/**
 * Enumeration of player event types
 */
export enum PlayerEventType {
  // ABORT = 'abort',
  // ATL_MEM_DEMOTED = 'altMemberDemoted',
  ATL_MEM_JOINED = 'atlMemberJoined',
  // ATL_MEM_LEFT = 'atlMemberLeft',
  // ATL_MEM_PROMOTED = 'altMemberPromoted',
  ATL_MEM_TALKING = 'atlMemberTalking',
  ATL_MEM_UPDATED = 'atlMemberUpdated',
  ATL_MEMLIST_UPDATED = 'atlMemberListUpdated',
  // ATL_PLAYBACK_ENDED = 'atlPlaybackEnded',
  // ATL_PLAYBACK_STARTED = 'atlPlaybackStarted',
  // ATL_PLAYBACK_UPDATED = 'atlPlaybackUpdated',
  // ATL_REC_ENDED = 'atlRecordingEnded',
  // ATL_REC_STARTED = 'atlRecordingStarted',
  // ATL_REC_UPDATED = 'atlRecordingUpdated',
  // ATL_ROOM_AUD_COUNT = 'atlRoomAudCount',
  ATL_ROOM_JOINED = 'atlRoomJoined',
  ATL_ROOM_LEFT = 'atlRoomLeft',
  ATL_ROOM_UPDATED = 'atlRoomUpdated',
  ENDED = 'ended',
  // ERROR = 'error',
  // LOADING = 'loading',
  // METADATA = 'metadata',
  // NEW_STREAM = 'newStream',
  // PAUSED = 'pause',
  // PLAYING = 'playing',
  // SLEEP = 'sleep',
  // STALLED = 'stalled',
  // STATE = 'state',
  // UPDATE = 'update',
  // XSTATE = 'xstate',
}

/**
 * Enumeration of player events uses for KPIs
 */
export enum PlayerMetric {
  PLAYER_LOOPING_ERROR = 'PLAYER_LOOPING_ERROR',
  STREAM_LOAD = 'STREAM_LOAD',
  STREAM_PLAY = 'STREAM_PLAY',
  STREAM_PAUSE = 'STREAM_PAUSE',
  STREAM_BUFFERING = 'STREAM_BUFFERING',
  STREAM_BUFFERING_COUNT = 'STREAM_BUFFERING_COUNT',
  STREAM_PLAYBACK_FAILURE = 'STREAM_PLAYBACK_FAILURE', // DEPRECATED: this should be removed after migrating to native player error handling
  STREAM_RESUME_FROM_BUFFERING = 'STREAM_RESUME_FROM_BUFFERING',
  STREAM_PLAYBACK_FAILED = 'STREAM_PLAYBACK_FAILED',
  STREAM_FAILED_TO_LOAD = 'STREAM_FAILED_TO_LOAD',
  STREAM_RECONNECT_SUCCESSFUL = 'STREAM_RECONNECT_SUCCESSFUL',
  STREAM_RECONNECT_ATTEMPT = 'STREAM_RECONNECT_ATTEMPT',
  STREAM_RECONNECT_ABORTED = 'STREAM_RECONNECT_ABORTED',
  STREAM_SESSION_TRACING_ENABLED = 'STREAM_SESSION_TRACING_ENABLED',
}

/**
 * Enumeration of player actionscd
 */
export enum PlayerAction {
  CONTINUE_LISTEN = 'CONTINUE_LISTEN',
  CONTINUE_LISTEN_10 = 'CONTINUE_LISTEN_10',
  FAST_FORWARD = 'FAST_FORWARD',
  JUMP_TO_LIVE = 'JUMP_TO_LIVE',
  PLAY = 'PLAY',
  REWIND = 'REWIND',
  SKIP_SONG = 'SKIP_SONG',
  PLAYBACK_SPEED = 'PLAYBACK_SPEED',
  PLAYER_LOOPING_ERROR = 'PLAYER_LOOPING_ERROR',
  STOP = 'STOP',
  PLAYBACK_FAILED = 'PLAYBACK_FAILED',
  PLAYBACK_ERROR = 'PLAYBACK_ERROR',
  SLEEP_TIMER_CANCELED = 'SLEEP_TIMER_CANCELED',
  SLEEP_TIMER_SET = 'SLEEP_TIMER_SET',
  SCRUB = 'SCRUB',
  SONG_LISTENED = 'SONG_LISTENED',
}

/**
 * Enumeration of view types
 */
export enum ViewType {
  ALL_EPISODE = 'ALL_EPISODE',
  COLLECTION = 'COLLECTION',
  EPISODE = 'EPISODE',
  MY_AUDIO = 'MY_AUDIO',
  MODULES = 'MODULES',
  ONBOARDING_FOLLOWS = 'ONBOARDING_FOLLOWS',
  PLAYLIST = 'PLAYLIST',
  SCHEDULE = 'SCHEDULE',
  SHOW = 'SHOW',
  SEARCH = 'SEARCH',
  STATION = 'STATION',
  TAG = 'TAG',
  TOPIC = 'TOPIC',
  STANDALONE_CHAPTER = 'CHAPTER',
}

/**
 * Enumeration of data event types
 */
export enum DataEventType {
  ADD_TO_QUEUE = 'ADD_TO_QUEUE',
  APP_CRASH = 'APP_CRASH',
  APP_EXIT = 'APP_EXIT',
  APP_START = 'APP_START',
  ASK_TO_SPEAK = 'ASK_TO_SPEAK',
  AUTOMOTIVE_CONNECT = 'AUTOMOTIVE_CONNECT',
  AUTOMOTIVE_DISCONNECT = 'AUTOMOTIVE_DISCONNECT',
  CAST_TO_DEVICE = 'CAST_TO_DEVICE',
  CHAT_COMMENT_POST = 'CHAT_COMMENT_POST',
  CHAT_COMMENT_LIKE = 'CHAT_COMMENT_LIKE',
  CHAT_REACTION = 'CHAT_REACTION',
  CONTENT_SHARE = 'CONTENT_SHARE',
  FIFTEEN_SECOND_CLICK = '15_SECOND_CLICK',
  FOLLOW = 'FOLLOW',
  JUMP_TO_LIVE = 'JUMP_TO_LIVE',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  MODULE_VIEW = 'MODULE_VIEW',
  NAVIGATION_CLICK = 'NAVIGATION_CLICK',
  NEXT_CHAPTER = 'NEXT_CHAPTER',
  PLAY_CLICK = 'PLAY_CLICK',
  STOP_CLICK = 'STOP_CLICK',
  PREVIOUS_CHAPTER = 'PREVIOUS_CHAPTER',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
  REACTIVATE_ACCOUNT = 'REACTIVATE_ACCOUNT',
  REGISTRATION_ENTRY = 'REGISTRATION_ENTRY',
  REGISTRATION_NAME_ENTRY = 'REGISTRATION_NAME_ENTRY',
  REGISTRATION_NAME_SUCCESSFUL = 'REGISTRATION_NAME_SUCCESSFUL',
  REGISTRATION_PII_ENTRY = 'REGISTRATION_PII_ENTRY',
  REGISTRATION_PII_SUCCESSFUL = 'REGISTRATION_PII_SUCCESSFUL',
  REGISTRATION_SUCCESSFUL = 'REGISTRATION_SUCCESSFUL',
  REMOVE_FROM_QUEUE = 'REMOVE_FROM_QUEUE',
  RESTART_EPISODE = 'RESTART_EPISODE',
  SCREEN_VIEW = 'SCREEN_VIEW',
  SEARCH = 'SEARCH',
  SEARCH_RESULTS_CLICK = 'SEARCH_RESULTS_CLICK',
  SONG_LIKE = 'SONG_LIKE',
  SONG_UNLIKE = 'SONG_UNLIKE',
  STATION_CALL = 'STATION_CALL',
  UNFOLLOW = 'UNFOLLOW',
  TTR_RECORD = 'TTR_RECORD',
  TTR_SEND = 'TTR_SEND',
  TTR_RECEIVED = 'TTR_RECEIVED',
  TTR_FAIL = 'TTR_FAIL',
  TTR_PREVIEW = 'TTR_PREVIEW',
  TTR_RERECORD = 'TTR_RERECORD',
  TTR_CANCEL = 'TTR_CANCEL',
  TTR_RETRY = 'TTR_RETRY',
}

export enum LinkMenuItemType {
  EPISODE_DETAILS = 'EPISODE_DETAILS',
  PODCAST = 'PODCAST',
  STATION = 'STATION',
  TOPIC = 'TOPIC',
  EPISODE_GO_TO = 'EPISODE_GO_TO',
  SHOW_GO_TO = 'SHOW_GO_TO',
}

/**
 * Represents an item in a song history list
 */
export type ISongHistoryItem = {
  /**
   * The artist of the song
   */
  artist: string;
  /**
   * The title of the song
   */
  title: string;
  /**
   * The time the song was played
   */
  time: string;
  /**
   * The image of the artist or album cover
   */
  image: string;
};

/**
 * bundle-id used as a param for Amperwave calls
 */

export const BundleID = Object.freeze({
  [Platform.ANDROID]: 'com.radiocom',
  [Platform.IOS]: 'com.cbsradio.cbsradioplayer',
});

/**
 * store-id used as a param for Amperwave calls
 */
export const StoreID = Object.freeze({
  [Platform.ANDROID]: 'com.radiocom',
  [Platform.IOS]: '323701765',
});

/**
 * store-url used as a param for Amperwave calls (must be url encoded)
 */
export const StoreURL = Object.freeze({
  [Platform.ANDROID]: 'https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.radiocom',
  [Platform.IOS]:
    'https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Faudacy-sports-radio-music%2Fid323701765',
});

export const SILENT_AUDIO =
  'data:audio/ogg;base64,SUQzBAAAAAAAI1RTU0UAAAAPAAADTGF2ZjU4LjQ1LjEwMAAAAAAAAAAAAAAA//tQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAASW5mbwAAAA8AAAACAAABoADAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDA//////////////////////////////////////////////////////////////////8AAAAATGF2YzU4LjM1AAAAAAAAAAAAAAAAJAAAAAAAAAAAAaBA/CmTAAAAAAAAAAAAAAAAAAAAAP/7EGQAD/AAAGkAAAAIAAANIAAAAQAAAaQAAAAgAAA0gAAABExBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV//sQZCIP8AAAaQAAAAgAAA0gAAABAAABpAAAACAAADSAAAAEVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVTEFNRTMuMTAwVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVU=';

export enum AmperwaveLocationOptions {
  APPLY_AMPERWAVE_GEOLOCATION,
  FORCE_SIMULCAST_ON,
  FORCE_SIMULCAST_OFF,
}

export const CLIENT_SIDE_TIMEOUT = 'client side timeout';

export const EXCLUDED_MODULE_VIEW_TITLE = '_VIEW_TITLE';

export const PLACEHOLDER_REGEX = /"(~\^~|0)"/g;
export enum RefreshTokenStatus {
  PENDING,
  AUTHENTICATED,
  UNAUTHENTICATED,
}

export enum ModuleSubType {
  UNFINISHED_EPISODES = 'UNFINISHED_EPISODES',
}
