import { IEventDetails } from "../analyticServices";
import AudacyLogger, { LoggerTopic } from "../AudacyLogger";
import { PlayerMetric } from "../Constants";
import { IAnalyticsProvider } from "./types";


// This is a band-aid solution to the issue of buffering events being sent too frequently. This should be removed after that is fixed.
class BufferingEventCounter {
    private startBufferingCount = 0;
    private endBufferingCount = 0;
    private debounceTimeout: NodeJS.Timeout | null = null;
    private debounceDelay = 5000;
    private firstEventTimestamp: number | null = null;
    private analyticsProvider?: IAnalyticsProvider;
    private eventDetails?: IEventDetails

    public init(analyticsProvider:IAnalyticsProvider, eventDetails:IEventDetails) {
        this.analyticsProvider = analyticsProvider;
        this.eventDetails = eventDetails;
    }


    public addEvent(eventType: 'start_buffering' | 'end_buffering') {
        AudacyLogger.log(`[${LoggerTopic.Streaming}] BufferingEventCounter: Received event: ${eventType}`);
        if (!this.firstEventTimestamp) {
            this.firstEventTimestamp = Date.now();
        }

        if (eventType === 'start_buffering') {
            this.startBufferingCount++;
        } else if (eventType === 'end_buffering') {
            this.endBufferingCount++;
        }

        this.debounceLogCounts();
    }

    private debounceLogCounts() {
        if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout);
        }

        this.debounceTimeout = setTimeout(() => {
            this.logCounts();
        }, this.debounceDelay);
    }

    private logCounts() {
        const currentTime = Date.now() - this.debounceDelay;

        const durationInMinutes = this.firstEventTimestamp
            ? (currentTime - this.firstEventTimestamp) / (1000 * 60)
            : 1;

        const startEventsPerMinute = durationInMinutes > 0
            ? this.startBufferingCount / durationInMinutes
            : 0;
        
        const endEventsPerMinute = durationInMinutes > 0
            ? this.endBufferingCount / durationInMinutes
            : 0;

        if (this.analyticsProvider) {
            AudacyLogger.log(`[${LoggerTopic.Streaming}] BufferingEventCounter: Sending analytics event`);
            this.analyticsProvider.sendEventToListener({
                type: PlayerMetric.STREAM_BUFFERING_COUNT,
                eventDetails: {
                    ...this.eventDetails,
                    bufferingStartCount: this.startBufferingCount,
                    bufferingEndCount: this.endBufferingCount,
                    bufferingStartEventsPerMinute: startEventsPerMinute,
                    bufferingEndEventsPerMinute: endEventsPerMinute,
                    duration: currentTime - (this.firstEventTimestamp || 0),
                },
            });
        } else {
            AudacyLogger.error(`[${LoggerTopic.Streaming}] BufferingEventCounter: Analytics provider not initialized`);
        }

        this.startBufferingCount = 0;
        this.endBufferingCount = 0;
        this.firstEventTimestamp = null;
    }
}

export default new BufferingEventCounter();
