import { TStyles } from '~/types/emotion-styles';

import styles from './styles';

interface IAdBannerProps {
  containerCss?: TStyles;
  title?: string;
  url: string;
}

const AdBanner = ({ containerCss, title = 'Advertisement', url }: IAdBannerProps): JSX.Element => {
  return (
    <div css={[styles.container, containerCss]}>
      <iframe css={styles.iframe} src={url} title={title} scrolling="no" />
    </div>
  );
};

export default AdBanner;
