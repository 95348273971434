export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Broadcast</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.25 15C4.25 8.51 9.51 3.25 16 3.25S27.75 8.51 27.75 15c0 3.912-1.911 7.377-4.851 9.512l.94 2.39c3.863-2.55 6.411-6.928 6.411-11.902C30.25 7.13 23.87.75 16 .75S1.75 7.13 1.75 15c0 4.974 2.548 9.352 6.41 11.901l.941-2.389A11.734 11.734 0 0 1 4.25 15Zm7 0a4.75 4.75 0 1 1 8.848 2.403l1.06 2.691a7.25 7.25 0 1 0-10.317 0l1.06-2.69A4.729 4.729 0 0 1 11.25 15Zm4.716-.851L21.914 31h-2.651l-3.23-9.148L12.985 31H10.35l5.617-16.852Z"
      fill="currentColor"
    />
  </svg>
);
