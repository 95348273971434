import DataObject from './DataObject';
import { ObjectType } from '../Constants';

/**
 * @module Topic
 */
export default class Topic extends DataObject {
  /**
   * Creates a new instance of a Topic.
   * @param {Object} data
   */
  constructor(data) {
    super(ObjectType.TOPIC, data);
  }
}
