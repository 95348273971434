import { playerFormattedHoursMinutesRemaining } from '@audacy-clients/core/atoms/player';
import { customFormatDuration, getDurationFromSeconds } from '@audacy-clients/core/utils/date';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { IEntitySummaryProps } from '~/components/Entity/types';
import Icon from '~/components/Icon';
import { Icons } from '~/components/Icon/constants';
import { Colors } from '~/styles';

import styles from './styles';
import { getPlayingAccessibilityLabelAndText } from './utils';

const Duration = ({
  duration,
  _isChapter,
  isExclusiveStation,
  _isEpisode,
  isPlayable,
  isPlayed,
  isPlaying,
  isPlayList,
  isScheduleItem,
  isScrubbing,
  itemsCount,
  resumePoint = 0,
}: IEntitySummaryProps): JSX.Element | null => {
  const { t } = useTranslation();
  const durationLeft = useRecoilValue(playerFormattedHoursMinutesRemaining);
  const isPlayingOrScrubbing = isPlaying || isScrubbing;
  // Exclusive stations do not include a duration value for playback.
  if (isExclusiveStation) {
    return (
      <div css={styles.gapContainer}>
        <Icon
          iconColor={Colors.white15}
          name={isPlaying ? Icons.PauseCircle : Icons.PlayMini}
          iconCss={styles.icon}
        />
        <p css={styles.text}>
          {isPlaying ? t('durationSummary.playingLive') : t('durationSummary.unplayed')}
        </p>
      </div>
    );
  }

  // PlayList may not include number of elements or duration
  if (isPlayList) {
    const isShowItems = itemsCount !== undefined;
    const { hours, minutes } = getDurationFromSeconds(duration ?? 0);
    return (
      <div css={styles.container}>
        <div css={styles.gapContainer}>
          {isPlaying ? (
            <>
              <Icon iconColor={Colors.white15} name={Icons.PauseCircle} iconCss={styles.icon} />
              <p css={styles.text}>{t('durationSummary.playListPlaying')}</p>
            </>
          ) : (
            <>
              <Icon iconColor={Colors.white15} name={Icons.PlayMini} iconCss={styles.icon} />
              {isShowItems && (
                <p css={styles.text}>
                  {t('durationSummary.playListItems', {
                    items: itemsCount,
                  })}
                </p>
              )}
              {!!duration && (
                <p css={styles.text}>
                  {t('durationSummary.playlistDuration', {
                    hours,
                    minutes,
                  })}
                </p>
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  // No Playstate Area
  if (!duration || !isPlayable) {
    return null;
  }

  const formattedDurationLeft = customFormatDuration(duration - resumePoint);
  const { text: durationLeftLabel } = getPlayingAccessibilityLabelAndText(
    isPlaying && !!durationLeft.label ? durationLeft : formattedDurationLeft,
    isPlayingOrScrubbing,
  );

  // Schedule Broadcast Episode not playing
  if (isScheduleItem && !isPlaying) {
    return (
      <div css={styles.gapContainer}>
        <Icon iconColor={Colors.white15} name={Icons.PlayMini} iconCss={styles.icon} />
        <p css={styles.text}>{t('global.rewind')}</p>
      </div>
    );
  }

  // Currently Playing
  if (isPlayingOrScrubbing) {
    return (
      <div css={styles.gapContainer}>
        <Icon iconColor={Colors.white15} name={Icons.PauseCircle} iconCss={styles.icon} />
        <p css={styles.text}>
          {/* because isScrubbing is not foolproof, if entity duration is still loading, display "Playing" */}
          {!durationLeft.label || isScheduleItem
            ? t('durationSummary.playingLive')
            : durationLeftLabel}
        </p>
      </div>
    );
  }

  // Podcast Episode or Latest Episode module

  // Played
  if (isPlayed) {
    return (
      <div css={styles.container}>
        <div css={styles.gapContainer}>
          <Icon iconColor={Colors.white15} name={Icons.PlayMini} iconCss={styles.icon} />
          <p css={styles.text}>{t('durationSummary.played')}</p>
          <Icon name={Icons.Check} sizeInPx={10} />
        </div>
      </div>
    );
  }

  // Now playing
  if (isPlaying) {
    return (
      <div css={styles.container}>
        <div css={styles.gapContainer}>
          <Icon iconColor={Colors.white15} name={Icons.PlayMini} iconCss={styles.icon} />
          <p css={styles.text}>
            {`${durationLeftLabel} ${t('durationSummary.accessibility.left')}`}
          </p>
        </div>
      </div>
    );
  }

  // Partially played or not played at all
  return (
    <div css={styles.container}>
      <div css={styles.gapContainer}>
        <Icon iconColor={Colors.white15} name={Icons.PlayMini} iconCss={styles.icon} />
        <p css={styles.text}>{durationLeftLabel}</p>
      </div>
    </div>
  );
};

export default Duration;
