import { Colors, Mixins } from '~/styles';
import { TStyles } from '~/types/emotion-styles';

export const Constants = {
  buttonBorder: '1px solid',
};

export const ButtonBorder = {
  default: `${Constants.buttonBorder} ${Colors.white30}`,
  disabled: `${Constants.buttonBorder} ${Colors.white15}`,
  hover: `${Constants.buttonBorder} ${Colors.white15}`,
  white: `${Constants.buttonBorder} ${Colors.white}`,
};

export const baseButton: TStyles = {
  ...Mixins.transition(['border', 'background-color']),
  alignItems: 'center',
  display: 'inline-flex',
  justifyContent: 'center',
  position: 'relative',
};

export const disabled: TStyles = {
  // We include the selector below to match the specificity of any existing
  // transitionMixBackgroundColor or transitionGradientBackground mixin styles.
  // Disable styles should always take precedence.
  '&': {
    background: 'transparent',
  },
  '& span': {
    WebkitTextFillColor: 'inherit', // Override themeSecondaryPremium
  },
  border: ButtonBorder.disabled,
  pointerEvents: 'none',
  color: Colors.greyC5,
};

export const label: TStyles = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const themeWhite: TStyles = {
  background: Colors.white,
  color: Colors.audacyDarkNavy,
  ...Mixins.transitionMixBackgroundColor(Colors.white),
};
