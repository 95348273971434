import { Colors, focusOffset, Outlines } from '~/styles';

const noOutline = `outline: none;`;
const outline = `outline: ${Outlines.focus}; outline-offset: ${focusOffset}px;`;

// Focus-visible trick to set a focus fallback where focus-visible isn't available
// https://css-tricks.com/focus-visible-and-backwards-compatibility/
export const theme = `
  *:active {${noOutline}}

  *:focus {${outline}}

  *:focus:not(:focus-visible){${noOutline}}

  *:focus-visible {${outline}}

  body {
    background-color: ${Colors.audacyDarkNavy};
    color: ${Colors.white};
  }
`;
