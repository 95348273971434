import { ModuleType, ViewType } from '@audacy-clients/client-services';
import { useModuleContext } from '@audacy-clients/core/components/ModuleRenderer/context';

import BannerAd from '~/components/BannerAd';
import { defineAdSlot, destroyAdSlots, getAdSlot } from '~/utils/googleAds';

const ModuleBannerAd: React.FC = () => {
  // BannerAd placement on SHOW pages must be handled within the ModuleShowLiveNowAndPreviousEpisodes
  const isShow = useModuleContext().viewType === ViewType.SHOW;
  if (isShow) return <></>;

  const bannerAd = getAdSlot(ModuleType.BANNER_AD);
  if (bannerAd) destroyAdSlots([bannerAd]);

  defineAdSlot(ModuleType.BANNER_AD);

  return <BannerAd />;
};

export default ModuleBannerAd;
