export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Phone</title>
    <path stroke="currentColor" strokeWidth="1.5" d="M8 4h16v24H8zM12 25.25h8" />
  </svg>
);
