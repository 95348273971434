import { useContext } from 'react';

import { Oval } from 'react-loader-spinner';

import { FullPrimaryIconSize } from '~/components/Player/FullscreenPlayer/Components/FullPlayer/constants';
import { PlayerContext } from '~/components/Player/PlayerContextProvider';
import { useBreakpoints } from '~/hooks/use-breakpoints';
import { Colors } from '~/styles';

const DEFAULT_SPINNER_SIZE = 22;

export const PlayerControlsSpinner = (): JSX.Element => {
  const { isFullscreenPlayer } = useContext(PlayerContext);

  const { bk } = useBreakpoints();
  const spinnerSize = isFullscreenPlayer ? FullPrimaryIconSize[bk] * 1.2 : DEFAULT_SPINNER_SIZE;

  return (
    <Oval
      visible
      ariaLabel="oval-loading"
      color={Colors.greyEB}
      height={spinnerSize}
      width={spinnerSize}
      secondaryColor={Colors.greyA6}
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  );
};
