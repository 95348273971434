import { Constants as NavigationBarConstants } from '~/components/NavigationBar/constants';
import { Borders, BP, Colors, Mixins, mq, mqHover, mqPortrait, Typography, ZIndex } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

import { Constants } from './constants';

const styles: TStyleSheet = {
  container: {
    ...Mixins.verticalScrollBar,
    backgroundColor: Colors.audacyDarkNavy,
    color: Colors.white,
    overflowX: 'hidden',
    overflowY: 'scroll',
    padding: 20,
    position: 'fixed',
    top: NavigationBarConstants.barHeight,
    width: Constants.menuWidth[BP.Small],
    zIndex: ZIndex.navigationWithBranchJourney,
    [mq(BP.Medium)]: {
      borderTop: 'none',
      padding: `${Constants.navPadding[BP.Medium]}px 40px 0 85px`,
      top: 0,
      width: Constants.menuWidth[BP.Medium],
      zIndex: ZIndex.navigation,
    },
    [mq(BP.Large)]: {
      padding: `${Constants.navPadding[BP.Large]}px 20px 0 ${Constants.navPadding[BP.Large]}px`,
      width: Constants.menuWidth[BP.Large],
    },
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100vh - ${NavigationBarConstants.barHeight}px)`,
    position: 'relative',
    [mq(BP.Medium)]: {
      height: `calc(100vh - ${Constants.navPadding[BP.Medium]}px)`,
    },
    [mqPortrait(BP.Medium)]: {
      height: '-webkit-fit-available',
      paddingBottom: Constants.navPadding[BP.Medium],
    },
    [mq(BP.Large)]: {
      height: `calc(100vh - ${Constants.navPadding[BP.Large]}px)`,
    },
  },
  bordered: {
    borderBottom: Borders.separator,
  },
  navLinks: {
    [mq(BP.Medium)]: {
      marginTop: 40,
    },
    [mq(BP.Large)]: {
      marginTop: 20,
    },
  },
  linksList: {
    padding: `${Constants.defaultPadding}px 0`,
    [mq(BP.Large)]: {
      width: 142,
    },
  },
  listItem: {
    display: 'flex',
    '&:not(:last-child)': {
      marginBottom: 11,
    },
  },
  logo: {
    alignItems: 'center',
    display: 'flex',
    height: NavigationBarConstants.logoHeight,
    width: NavigationBarConstants.logoWidth,
    ...Mixins.transition('opacity'),
    ...mqHover({
      opacity: 0.6,
    }),
  },
  close: {
    left: 42,
    position: 'absolute',
    top: 25,
  },

  loggedInContainer: {
    paddingTop: Constants.defaultPadding,
  },
  signInButton: {
    marginTop: 20,
    [mq(BP.Medium)]: {
      marginTop: 0,
    },
  },
  bottomContainer: {
    paddingBottom: NavigationBarConstants.barHeight,
    marginTop: 10,
    [mq(BP.Medium)]: {
      marginTop: 'auto',
      paddingBottom: Constants.navPadding[BP.Large],
    },
  },
  joinText: {
    ...Typography.metadataSmall,
    paddingTop: Constants.defaultPadding,
  },
  joinButton: {
    marginTop: NavigationBarConstants.barHeight,
    [mq(BP.Medium)]: {
      marginTop: 20,
    },
  },
  searchContainer: {
    position: 'absolute',
    width: Constants.searchWidth,
    right: Constants.navPadding[BP.Large],
    top: Constants.navPadding[BP.Large],
    zIndex: ZIndex.navigation,
  },
};

export default styles;
