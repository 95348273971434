import { BP, Colors, Mixins, mq, mqHover, Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    alignItems: 'center',
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
  },
  primaryActionBtn: {
    borderRadius: '50%',
  },
  primaryActionBtnInner: {
    ...Mixins.transition('background-color'),
    ...mqHover({
      backgroundColor: Colors.white60,
    }),
    'button:disabled &': {
      backgroundColor: Colors.white30,
    },
  },
  primaryActionButtonInnerLoading: {
    backgroundColor: Colors.white30,
    [mq(BP.Medium)]: {
      backgroundColor: Colors.white30,
    },
  },
  secondaryActionBtn: {
    ...Mixins.transition('color'),
    ...Mixins.focusOutline({ outlineOffset: 1 }),
    ...mqHover({
      color: Colors.white60,
    }),
    borderRadius: '50%',
    position: 'relative',
    '&:disabled': {
      color: Colors.white30,
    },
  },
  skipsRemaining: {
    ...Typography.buttonSmall,
    backgroundColor: Colors.secondaryMediumGrey,
    border: '2px solid',
    borderColor: Colors.audacyDarkNavy,
    alignItems: 'center',
    borderRadius: '50%',
    color: Colors.white,
    display: 'flex',
    height: 22,
    justifyContent: 'center',
    left: 2,
    position: 'absolute',
    top: 2,
    width: 22,
  },
};

export const miniPlayerStyles = {
  container: {
    ...styles.container,
    paddingRight: 15,
    [mq(BP.Medium)]: {
      paddingRight: 30,
    },
    [mq(BP.Large)]: {
      paddingRight: 15,
      paddingLeft: 15,
    },
  },
  queueHeaderContainer: {
    paddingRight: 0,
    [mq(BP.Medium)]: {
      paddingRight: 0,
    },
  },
  primaryActionBtn: {
    ...styles.primaryActionBtn,
    padding: 5,
    [mq(BP.Medium)]: {
      padding: 10,
      color: Colors.audacyDarkNavy,
    },
    [mq(BP.Large)]: {
      padding: 5,
    },
  },
  primaryActionBtnInner: {
    ...styles.primaryActionBtnInner,
    [mq(BP.Medium)]: {
      alignItems: 'center',
      backgroundColor: Colors.white,
      display: 'flex',
      height: 40,
      justifyContent: 'center',
      width: 40,
    },
    borderRadius: '50%',
  },
  primaryActionBtnInnerQueue: {
    ...styles.primaryActionBtnInner,
    [mq(BP.Medium)]: {
      padding: 0,
      color: Colors.white,
    },
  },
  primaryActionButtonInnerLoading: {
    ...styles.primaryActionButtonInnerLoading,
  },
  secondaryActionBtn: {
    ...styles.secondaryActionBtn,
    padding: 10,
    [mq(BP.Large)]: {
      padding: 5,
    },
  },
  skipsRemaining: styles.skipsRemaining,
};

export const fullPlayerStyles = {
  container: {
    ...styles.container,
    marginTop: 20,
    marginBottom: 3,
    [mq(BP.Large)]: {
      marginTop: 40,
    },
    alignSelf: 'start',
  },
  queueHeaderContainer: {}, // empty object needed to prevent type error - property is present in miniPlayerStyles
  primaryActionBtn: {
    ...styles.primaryActionBtn,
    color: Colors.audacyDarkNavy,
    margin: '0 12px',
    [mq(BP.Medium)]: {
      margin: '0 27px',
    },
    [mq(BP.Large)]: {
      margin: '0 12px',
    },
  },
  primaryActionBtnInner: {
    ...styles.primaryActionBtnInner,
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderRadius: '50%',
    display: 'flex',
    height: 60,
    justifyContent: 'center',
    width: 60,
    [mq(BP.Medium)]: {
      height: 70,
      width: 70,
    },
  },
  primaryActionBtnInnerQueue: {},
  primaryActionButtonInnerLoading: {
    ...styles.primaryActionButtonInnerLoading,
  },
  secondaryActionBtn: {
    ...styles.secondaryActionBtn,
    padding: 12,
    [mq(BP.Medium)]: {
      margin: '0 15px',
    },
    [mq(BP.Large)]: {
      margin: 0,
    },
  },
  skipsRemaining: styles.skipsRemaining,
};
