import { ViewType } from '@audacy-clients/client-services/core';
import { type ITabNavigation } from '@audacy-clients/core/atoms/config/navigation';

export const _defaultSubNavigationTabs: Array<ITabNavigation> = [
  {
    iconRef: 'Logo',
    label: 'For You',
    tabKey: `tab:${ViewType.MODULES}:home`,
    viewId: 'home',
    viewType: ViewType.MODULES,
  },
  {
    iconRef: 'Logo',
    label: 'Podcasts',
    tabKey: `tab:${ViewType.MODULES}:podcasts`,
    viewId: 'podcasts_index',
    viewType: ViewType.MODULES,
  },
  {
    iconRef: 'Logo',
    label: 'Music',
    tabKey: `tab:${ViewType.MODULES}:music`,
    viewId: 'music',
    viewType: ViewType.MODULES,
  },
  {
    iconRef: 'Logo',
    label: 'Sports',
    tabKey: `tab:${ViewType.MODULES}:sports`,
    viewId: 'sports',
    viewType: ViewType.MODULES,
  },
  {
    iconRef: 'Logo',
    label: 'News & Talk',
    tabKey: `tab:${ViewType.MODULES}:newstalk`,
    viewId: 'newstalk',
    viewType: ViewType.MODULES,
  },
];

type TSubnavigationConfiguration = {
  viewId: string;
  route: string;
  tabs: Array<ITabNavigation>;
};

/**
 * SubNavigation configuration singleton
 * */
class SubnavigationConfiguration {
  private _subNavigationConfigurationMap: Map<string, TSubnavigationConfiguration>;
  private _defaultRoute: string = 'default';
  constructor() {
    this._subNavigationConfigurationMap = new Map<string, TSubnavigationConfiguration>();
  }
  /**
   * Set the viewId but will default to default route and empty tabs
   */
  public setViewId(viewId: string): TSubnavigationConfiguration | undefined {
    const subNavigationConfiguration = {
      viewId: viewId,
      route: this._defaultRoute,
      tabs: [],
    };
    this._subNavigationConfigurationMap.set(viewId, subNavigationConfiguration);
    return subNavigationConfiguration;
  }
  /**
   * Set the Route to be used by the Navigation endpoint, with the viewId as a key
   */
  public setViewIdRoute(
    viewId: string,
    route: string | null | undefined,
  ): TSubnavigationConfiguration | undefined {
    if (route) {
      const subNavigationConfiguration = {
        viewId: viewId,
        route: route,
        tabs: [],
      };
      this._subNavigationConfigurationMap.set(viewId, subNavigationConfiguration);
      return subNavigationConfiguration;
    }
  }

  /**
   * Set the Sub Navigation tabs that are to be shown for a specific viewId
   * @param viewId - the viewId to be used as a key for storing Sub Navigation configuration
   * @param tabs - the tabs to be displayed for the specific viewId
   */
  public setViewIdTabs(
    viewId: string,
    tabs: Array<ITabNavigation>,
  ): TSubnavigationConfiguration | undefined {
    if (tabs.length > 0) {
      const currentValue = this._subNavigationConfigurationMap.get(viewId);
      this._subNavigationConfigurationMap.set(viewId, {
        viewId,
        route: currentValue?.route ?? this._defaultRoute,
        tabs,
      });
      return currentValue;
    }
  }
  /**
   * If no entry is found for the `viewId` provided, then an undefined object is returned
   */
  public getViewIdContent(viewId: string): TSubnavigationConfiguration | undefined {
    return this._subNavigationConfigurationMap.get(viewId);
  }
  /**
   * Fetch all the default values for a SubNavigation Configuration
   */
  public getDefaultSubNavigationConfiguration(): TSubnavigationConfiguration {
    return {
      // TODO: is this the correct default for viewId
      viewId: 'default',
      route: this._defaultRoute,
      tabs: _defaultSubNavigationTabs,
    };
  }
  public removeViewIdSubnavigationConfiguration(viewId: string): boolean {
    return this._subNavigationConfigurationMap.delete(viewId);
  }
}
export default new SubnavigationConfiguration();
