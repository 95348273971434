import { shouldShowPlayerState } from '@audacy-clients/core/atoms/player';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { AdBannerWrapper } from '~/components/Player/MiniPlayer/Components/AdBannerWrapper';
import { BroadcastImage } from '~/components/Player/MiniPlayer/Components/BroadcastImage';
import { BroadcastMetadata } from '~/components/Player/MiniPlayer/Components/BroadcastMetadata';
import { MiniPlayerControls } from '~/components/Player/MiniPlayer/Components/MiniPlayerControls';
import { useGetAnimationProps } from '~/components/Player/MiniPlayer/hooks';
import { TStyles } from '~/types/emotion-styles';

import styles from './styles';

interface IMiniPlayerProps {
  containerCss?: TStyles;
}

const MiniPlayer = ({ containerCss }: IMiniPlayerProps): JSX.Element => {
  const isOpen = useRecoilValue(shouldShowPlayerState);

  const { t } = useTranslation();

  const animationProps = useGetAnimationProps();

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          {...animationProps}
          css={[styles.container, containerCss]}
          role="region"
          aria-label={t('player.regionLabel')}
        >
          <AdBannerWrapper />
          <div css={styles.content}>
            <div css={styles.dataContainer}>
              <BroadcastImage />
              <BroadcastMetadata />
            </div>

            <MiniPlayerControls />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MiniPlayer;
