import { useTranslation } from 'react-i18next';

import IconButton from '~/components/Button/IconButton';
import EntityImage, { type IEntityImageProps } from '~/components/Entity/components/EntityImage';
import { Icons } from '~/components/Icon/constants';
import { type TStyles } from '~/types/emotion-styles';
import styles from './styles';

export type IDrawerHeaderProps = {
  id: string;
  image?: IEntityImageProps;
  onClose: () => void;
  headerContainerStyle?: TStyles;
  title?: string;
  titleStyle?: TStyles;
  subtitle?: string;
  subtitleStyle?: TStyles;
  seperator?: JSX.Element;
};

const DrawerHeader = ({
  id,
  image,
  onClose,
  headerContainerStyle,
  title,
  titleStyle,
  subtitle,
  subtitleStyle,
  seperator,
}: IDrawerHeaderProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div css={[styles.wrapper, headerContainerStyle]}>
      {image && (
        <EntityImage imageURIBase={image.imageURIBase} entityImageCss={styles.entityImage} />
      )}
      <div css={styles.textContainer}>
        <h1 css={[styles.title, titleStyle]}>{title}</h1>
        <h2 css={[styles.subtitle, subtitleStyle]}>{subtitle}</h2>
      </div>
      {seperator ?? <div css={styles.seperator} />}
      <IconButton
        ariaControls={id}
        buttonCss={styles.closeButton}
        icon={Icons.CloseCircle}
        ariaLabel={t('drawer.close')}
        iconSizeInPx={28}
        onClick={onClose}
        css={styles.closeIcon}
      />
    </div>
  );
};

export default DrawerHeader;
