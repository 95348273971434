import { ViewType } from '@audacy-clients/client-services';
import DataServices, {
  FeaturedPlayable,
  View,
} from '@audacy-clients/client-services/src/dataServices/DataServices';
import { CacheTimes } from '@audacy-clients/core/atoms/helpers/constants';
import { getMarketIdsString } from '@audacy-clients/core/atoms/helpers/markets';
import { refreshableSelectorFamily } from '@audacy-clients/core/atoms/helpers/refreshable';
import { locationMarketsSelector } from '@audacy-clients/core/atoms/location';
import { wrapContentObject } from '@audacy-clients/core/atoms/wrappers/content';
import { IView, wrapView } from '@audacy-clients/core/atoms/wrappers/modules';
import clientServices from '@audacy-clients/core/utils/clientServices';
import { SerializableParam, useRecoilValue } from 'recoil';

import { RequestType, TModuleRequest } from './requests';

const getView = async (
  request: TModuleRequest,
  dataServices: DataServices,
  markets?: string,
): Promise<View> => {
  if (request.type === RequestType.LandingPage || request.type === RequestType.ContentPage) {
    switch (request.type) {
      case RequestType.ContentPage:
        return await dataServices.getView(request.viewId, request.contentId, markets);

      case RequestType.LandingPage:
        return await dataServices.getView(request.viewId, undefined, markets);
    }
  }
  return await dataServices.getPage(request.path, markets);
};

const getFeaturedPlayable = async (
  contentId: string | undefined,
  dataServices: DataServices,
): Promise<FeaturedPlayable | undefined> => {
  if (!contentId) {
    return undefined;
  }

  return await dataServices.getFeaturedPlayable(contentId);
};

const performRequest = async (
  request: TModuleRequest,
  dataServices: DataServices,
  markets?: string,
): Promise<IView> => {
  if (request.type === RequestType.LandingPage || request.type === RequestType.ContentPage) {
    const [view, featuredPlayable] = await Promise.all([
      getView(request, dataServices, markets),
      request.type === RequestType.ContentPage
        ? getFeaturedPlayable(request.contentId, dataServices)
        : undefined,
    ]);
    return wrapView(view, featuredPlayable);
  } else {
    const view = await getView(request, dataServices, markets);
    const rootObj = wrapContentObject(view?.contentObj);
    const featuredPlayable = rootObj?.id
      ? await getFeaturedPlayable(rootObj.id, dataServices)
      : undefined;
    return wrapView(view, featuredPlayable);
  }
};

type TQueryParams = TModuleRequest & SerializableParam;

export const { selector: modulesSelector, useRefreshCache: useRefreshModules } =
  refreshableSelectorFamily<IView, TQueryParams>({
    get:
      (moduleRequest) =>
      async ({ get }) => {
        const { markets, isLoading } = get(locationMarketsSelector);
        const marketsString = getMarketIdsString(markets);

        if (!isLoading) {
          return await performRequest(
            moduleRequest,
            clientServices.getDataServices(),
            marketsString,
          );
        }

        // return an empty view while market data loads
        return {
          type: ViewType.MODULES,
          getContent: () => undefined,
          id: 'empty-module-placeholder',
          modules: [],
          uniqueId: Date.now().toString(),
        };
      },
    key: 'Modules',
    cacheLimit: CacheTimes.limit10m,
  });

export const useModules = (request: TModuleRequest) => useRecoilValue(modulesSelector(request));
