import {
  CollectionSubType,
  type EntitySubtype,
  EntityType,
  EpisodeSubType,
  ReactQueryStorageKey,
  ShowSubtype,
  ViewType,
} from '@audacy-clients/client-services/core';
import clientServices, { getClientServices } from '@audacy-clients/core/utils/clientServices';
import { useCallback, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { queryKeys } from '../helpers/api';
import { noCacheDefaultSelector } from '../helpers/noCacheDefaultSelector';
import { type ViewIDKey } from './constants';
import subNavigationConfiguration from '@audacy-clients/core/atoms/config/subNavigation';
import { featureFlagMap } from '@audacy-clients/client-services/src/utils';

export type IViewInfo = {
  viewId: string | ViewIDKey;
  viewType: ViewType;
};

export type ITabNavigation = {
  iconRef: string;
  label: string;
  tabKey: string;
} & IViewInfo;

export type IViewMap = Record<string, Record<string, IViewInfo>>;

export type IClientSubNavigation = {
  tabs: Array<ITabNavigation>;
  viewMap: IViewMap;
};

export type IClientNavigation = {
  tabs: Array<ITabNavigation>;
  viewMap: IViewMap;
};

export const defaultViewMap: IViewMap = {
  [EntityType.EPISODE]: {
    [EpisodeSubType.BROADCAST_SHOW_EPISODE]: {
      viewId: 'episode',
      viewType: ViewType.EPISODE,
    },
    [EpisodeSubType.HOST_CREATED_EPISODE]: {
      viewId: 'episode',
      viewType: ViewType.EPISODE,
    },
    [EpisodeSubType.PODCAST_EPISODE]: {
      viewId: 'episode',
      viewType: ViewType.EPISODE,
    },
  },
  [EntityType.SHOW]: {
    [ShowSubtype.BROADCAST]: {
      viewId: 'shows',
      viewType: ViewType.SHOW,
    },
    [ShowSubtype.DIGITAL]: {
      viewId: 'shows',
      viewType: ViewType.SHOW,
    },
    [ShowSubtype.HOST_CREATED]: {
      viewId: 'shows',
      viewType: ViewType.SHOW,
    },
    [ShowSubtype.PODCAST]: {
      viewId: 'podcasts',
      viewType: ViewType.SHOW,
    },
  },
  [EntityType.COLLECTION]: {
    [CollectionSubType.PLAYLIST]: {
      viewId: 'playlists',
      viewType: ViewType.PLAYLIST,
    },
  },
};

export const loadRemoteSubNavigation = async (viewId: string): Promise<IClientSubNavigation> => {
  const id = subNavigationConfiguration.getViewIdContent(viewId)?.route;
  const response = await getClientServices().then((cs) =>
    cs.getDataServices().getClientSubNavigation(id),
  );

  const subNavigationTabs = featureFlagMap.getAll().curatorDefinedNavigation
    ? response.tabs
    : subNavigationConfiguration.getDefaultSubNavigationConfiguration().tabs;

  const wrapped: IClientSubNavigation = {
    tabs: (subNavigationTabs || []).map((t) => ({
      iconRef: t.iconRef,
      label: t.label,
      tabKey: `tab:${t.viewType}:${t.viewId}`,
      viewId: t.viewId,
      viewType: t.viewType,
    })),
    viewMap: {
      ...defaultViewMap,
      ...(response.viewMap || {}),
    },
  };

  return wrapped;
};

export const loadRemoteNavigation = async (): Promise<IClientNavigation> => {
  const response = await getClientServices().then((cs) =>
    // Revert to Client Navigation Two after 16.13.0 release
    cs.getDataServices().getClientNavigation(),
  );

  const wrapped: IClientNavigation = {
    tabs: (response.tabs || []).map((t) => ({
      iconRef: t.iconRef,
      label: t.label,
      tabKey: `tab:${t.viewType}:${t.viewId}`,
      viewId: t.viewId,
      viewType: t.viewType,
    })),
    viewMap: {
      ...defaultViewMap,
      ...(response.viewMap || {}),
    },
  };

  return wrapped;
};

export const webClientNavigation = noCacheDefaultSelector<IClientNavigation>({
  get: () => loadRemoteNavigation(),
  key: 'WebClientNavigation',
});

export const useViewInfoByTypeAndSubtype = (
  type?: EntityType,
  subtype?: EntitySubtype,
): IViewInfo | undefined => {
  const { viewMap } = useClientNavigation();
  if (!type || !subtype) {
    return undefined;
  }
  return viewMap[type]?.[subtype] || viewMap[type]?.default;
};

export const mapEntityToParentTypes = (
  type?: EntityType,
  subtype?: EntitySubtype,
): [EntityType, EntitySubtype] | undefined => {
  if (type === EntityType.EPISODE) {
    if (
      subtype === EpisodeSubType.BROADCAST_SHOW_EPISODE ||
      subtype === EpisodeSubType.HOST_CREATED_EPISODE
    ) {
      return [EntityType.SHOW, ShowSubtype.BROADCAST];
    }

    if (subtype === EpisodeSubType.PODCAST_EPISODE) {
      return [EntityType.SHOW, ShowSubtype.PODCAST];
    }
  }

  if (type === EntityType.STANDALONE_CHAPTER) {
    return [EntityType.SHOW, ShowSubtype.BROADCAST];
  }

  return undefined;
};

type TGetViewInfoByTypeAndSubtype = (
  type?: EntityType,
  subtype?: EntitySubtype,
  options?: { shouldGetInfoForParent?: boolean },
) => IViewInfo | undefined;

const initialData = {
  tabs: [
    {
      iconRef: 'Logo',
      label: 'Home',
      tabKey: 'tab:MODULES:home',
      viewId: 'home',
      viewType: ViewType.MODULES,
    },
  ],
  viewMap: { ...defaultViewMap },
};

const handleRemoteNavigationSuccess = (data: IClientNavigation) => {
  clientServices
    .getPersonalizationServices()
    .dataStore.setDataSync(ReactQueryStorageKey.REMOTE_NAVIGATION, data);
};

export const useClientNavigation = (): IClientNavigation => {
  const isConnected = clientServices.getOnlineStatus();
  const CACHE_TIME = 4 * 60 * 60 * 1000; // 4 hours in milliseconds

  const { data = initialData } = useQuery(queryKeys.remoteNavigation(), loadRemoteNavigation, {
    cacheTime: CACHE_TIME,
    enabled: isConnected,
    refetchInterval: CACHE_TIME,
    onSuccess: handleRemoteNavigationSuccess,
  });

  return data;
};

const handleRemoteSubNavigationSuccess = (data: IClientSubNavigation) => {
  clientServices
    .getPersonalizationServices()
    .dataStore.setDataSync(ReactQueryStorageKey.REMOTE_SUB_NAVIGATION, data);
};

export const useClientSubNavigation = (viewId: string): IClientSubNavigation => {
  const isConnected = clientServices.getOnlineStatus();
  const queryClient = useQueryClient();
  const CACHE_TIME = 4 * 60 * 60 * 1000; // 4 hours in milliseconds

  const subNavigationQueryKeys = [queryKeys.remoteSubNavigation(), viewId];
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: subNavigationQueryKeys });
  }, [featureFlagMap.getAll().curatorDefinedNavigation]);

  const {
    data = {
      tabs: subNavigationConfiguration.getDefaultSubNavigationConfiguration().tabs,
      viewMap: { ...defaultViewMap },
    },
  } = useQuery(subNavigationQueryKeys, () => loadRemoteSubNavigation(viewId), {
    cacheTime: CACHE_TIME,
    enabled: isConnected,
    onSuccess: handleRemoteSubNavigationSuccess,
  });

  subNavigationConfiguration.setViewIdTabs(viewId, data.tabs);

  return data;
};

export const useGetViewInfoByTypeAndSubtype = (): TGetViewInfoByTypeAndSubtype => {
  const queryClient = useQueryClient();
  const { viewMap } =
    queryClient.getQueryData<IClientNavigation>(queryKeys.remoteNavigation()) || initialData;

  return useCallback(
    (type, subtype, { shouldGetInfoForParent } = {}) => {
      let [finalType, finalSubtype] = [type, subtype];

      if (shouldGetInfoForParent) {
        [finalType, finalSubtype] = mapEntityToParentTypes(type, subtype) || [];
      }

      if (!finalType || !finalSubtype) {
        return undefined;
      }

      return viewMap[finalType]?.[finalSubtype] || viewMap[finalType]?.default;
    },
    [viewMap],
  );
};
