import { Borders, Colors, Mixins, mqHover, Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    marginTop: 20,
  },
  contentWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    ...Mixins.transition('opacity'),
    ...mqHover({
      opacity: 0.6,
    }),
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 64%',
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
  },
  subtitle: {
    ...Typography.metadataSmall,
    color: Colors.white60,
  },
  title: {
    color: Colors.white,
    ...Typography.bodySmall,
    ...Mixins.transition('color'),
  },
  sectionWrapper: {
    marginTop: 40,
  },
  imageWrapper: {
    width: 62,
    marginRight: 20,
  },
  footer: {
    borderTop: Borders.separator,
    marginTop: 20,
    paddingTop: 20,
  },
  caretRight: {
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

export default styles;
