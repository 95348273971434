import { assign, createMachine, sendParent } from 'xstate';
import { choose } from 'xstate/lib/actions';

import AudacyLogger, { LoggerTopic } from '../../../AudacyLogger';
import {
  EntityType,
  EpisodeSubType,
  StationSubType,
  PlayerAction,
  PlayerMetric,
  Preroll,
  Platform,
} from '../../../Constants';
import Episode from '../../../dataServices/Episode';
import Station from '../../../dataServices/Station';
import { cache } from '../../../utils';
import { TPlayableObject, TStreamerMachineEvent, EContentType } from '../../types';
import { COMMON_ACTIONS, MAX_RETRY_ATTEMPTS } from '../constants';
import { ILiveStationAudioSource, IStreamerMachineContext, TStreamSelector } from '../types';
import {
  DEFAULT_PLAYER_ACTIONS,
  isContentTypeAd,
  loadSourcesForItem,
  loadAudioPreroll,
  sendAudioPrerollTrackers,
  getExponentialBackoffDelay,
  getFirstAudioSourceUrl,
} from '../utils';
import bufferingEventCounter from '../../bufferingEventCounter';


// Test station slugs, e.g. /stations/alice1059, on STG backend:
// alice1059, kroq, wcbsfm, etc.

interface ILiveStationMachineContext extends IStreamerMachineContext<TPlayableObject> {
  audioSourceIndex: number;
  audioSources: ILiveStationAudioSource[];
  isAudioPreroll: boolean;
  audioPrerollTrackers: string[];
}

type TLiveStationMachineServices = {
  loadSources: {
    data: Awaited<ReturnType<typeof loadSourcesForItem>>;
  };
};

// TODO: [CCS-2784] ensure that all assigns are cleaned up

const liveStationMachine = createMachine(
  {
    predictableActionArguments: true,
    id: 'liveStationMachine',
    initial: 'LOADING',
    tsTypes: {} as import('./LiveStationMachine.typegen').Typegen0,
    schema: {
      context: {} as ILiveStationMachineContext,
      events: {} as TStreamerMachineEvent,
      services: {} as TLiveStationMachineServices,
    },
    states: {
      LOADING: {
        entry: ['assignDefaults'],
        invoke: {
          src: 'loadSources',
          onDone: {
            actions: choose([
              {
                cond: 'shouldPlayAudioPreroll',
                actions: ['assignAudioSources', 'loadAudioVastAd'],
              },
              {
                actions: ['assignAudioSources', 'loadAudio'],
              },
            ]),
          },
        },
        on: {
          REPORT_LOADED: {
            target: 'LOADED',
          },
          REPORT_METADATA: {
            actions: 'assignMetadata',
          },
          PAUSE: {
            target: 'PAUSED',
            actions: 'pause',
          },
          REPORT_PLAYBACK_FAILED: {
            target: 'FAILURE_WITH_RETRY',
            actions: 'sendNativePlaybackFailedEvent',
          },
          ...COMMON_ACTIONS,
        },
      },
      TRANSITION_TO_BUFFERING: {
        entry: ['sendBufferingEvent'],
        on: {
          REPORT_PLAYBACK_FAILED: {
            target: 'FAILURE_WITH_RETRY',
            actions: 'sendNativePlaybackFailedEvent',
          },
          REPORT_PLAYING: {
            actions: ['sendResumeFromBufferingEvent'],
            target: 'PLAYING',
          },
          ...COMMON_ACTIONS,
        },
        after: [
          {
            // When android buffers data, it will enter a loading state and a pause state directly after.
            // This will cause the player to pause the stream. This will ignore that pause event then let the user pause manually
            target: 'BUFFERING',
            delay: 100,
          }
        ],
      },
      LOADED: {
        entry: 'autoplay',
        on: {
          PLAY: {
            target: 'PLAY_REQUESTED',
          },
          REPORT_METADATA: {
            actions: 'assignMetadata',
          },
          PAUSE: {
            target: 'PAUSED',
            actions: 'pause',
          },
          REPORT_PLAYBACK_FAILED: {
            target: 'FAILURE_WITH_RETRY',
            actions: 'sendNativePlaybackFailedEvent',
          },
          ...COMMON_ACTIONS,
        },
      },
      PLAY_REQUESTED: {
        entry: 'play',
        on: {
          REPORT_PLAYING: {
            actions: ['sendPlayEvent', 'addToHistory'],
            target: 'PLAYING',
          },
          REPORT_METADATA: {
            actions: 'assignMetadata',
          },
          REPORT_AUTOPLAY_POLICY: {
            target: 'PAUSED',
          },
          PAUSE: {
            target: 'PAUSED',
            actions: 'pause',
          },
          STOP: {
            target: 'PAUSED',
            actions: ['stop', 'sendStopEvent'],
          },
          REPORT_PLAYBACK_FAILED: {
            target: 'FAILURE_WITH_RETRY',
            actions: 'sendNativePlaybackFailedEvent',
          },
          ...COMMON_ACTIONS,
        },
      },
      BUFFERING: {
        on: {
          PAUSE: {
            target: 'PAUSE_REQUESTED',
          },
          REPORT_LOADING: {
            target: 'TRANSITION_TO_BUFFERING',
          },
          REPORT_PAUSED: {
            target: 'PAUSED',
          },
          REPORT_PLAYING: {
            actions: ['sendResumeFromBufferingEvent'],
            target: 'PLAYING',
          },
          REPORT_METADATA: {
            actions: ['sendLiveSongListenedEvent', 'assignMetadata'],
          },
          STOP: {
            target: 'PAUSED',
            actions: ['stop', 'sendStopEvent'],
          },
          REPORT_PLAYBACK_FAILED: {
            target: 'FAILURE_WITH_RETRY',
            actions: 'sendNativePlaybackFailedEvent',
          },
          ...COMMON_ACTIONS,
        },
      },
      PLAYING: {
        initial: 'IS_PLAYING',
        entry: ['assignRetryCountToZero'],
        states: {
          IS_PLAYING: {
            on: {
              REPORT_TIME_UPDATE: {
                target: 'IS_PLAYING',
              },
            },
          },
        },
        on: {
          PAUSE: {
            target: 'PAUSE_REQUESTED',
          },
          REPORT_LOADING: {
            target: 'TRANSITION_TO_BUFFERING',
          },
          REPORT_PAUSED: {
            target: 'PAUSED',
          },
          REPORT_METADATA: {
            actions: ['sendLiveSongListenedEvent', 'assignMetadata'],
          },
          STOP: {
            actions: ['stop', 'sendStopEvent'],
          },
          REPORT_PLAYBACK_FAILED: {
            target: 'FAILURE_WITH_RETRY',
            actions: 'sendNativePlaybackFailedEvent',
          },
          ...COMMON_ACTIONS,
        },
      },
      PAUSE_REQUESTED: {
        // This only works when the htmlplayer is playing
        // otherwise it Will never report, paused because it needs to transition from a playing state
        // So, when loading or in the retrying state, we go directly to paused
        entry: 'pause',
        on: {
          ...COMMON_ACTIONS,
          REPORT_PAUSED: {
            target: 'PAUSED',
          },
          REPORT_PLAYBACK_FAILED: {
            target: 'FAILURE_WITH_RETRY',
            actions: 'sendNativePlaybackFailedEvent',
          },
        },
      },
      PAUSED: {
        entry: ['pause', 'sendStopEvent', 'assignRetryCountToZero'],
        on: {
          PLAY: {
            target: 'LOADING',
          },
          REPORT_PLAYBACK_FAILED: {
            target: 'FAILURE_WITH_RETRY',
            actions: 'sendNativePlaybackFailedEvent',
          },
          ...COMMON_ACTIONS,
        },
      },
      FAILURE_WITH_RETRY: {
        entry: ['assignIncrementRetryCount', 'logRetry'],
        on: {
          ...COMMON_ACTIONS,
        },
        after: [
          {
            // iOS will immediately send a pause event after a failure. This will cancel the retry attempt. 
            // The FAILURE_WITH_RETRY state needs to ignore the Pause event and we quickly transition to
            // WAITING_TO_RETRY so the user can pause the stream.
            target: 'WAITING_TO_RETRY',
            delay: 100,
          }
        ],
      },
      WAITING_TO_RETRY: {
        on: {
          PLAY: {
            target: 'LOADING',
          },
          PAUSE: {
            target: 'PAUSED',
            actions: 'pause',
          },
          STOP: {
            actions: ['stop', 'sendStopEvent'],
          },
          REPORT_PAUSED: {
            target: 'PAUSED',
          },
          ...COMMON_ACTIONS,
        },
        after: [
          {
            target: 'FAILURE',
            delay: (context) => getExponentialBackoffDelay(context.retryCount),
            cond: ({ retryCount = 0 }) => retryCount >= MAX_RETRY_ATTEMPTS,
          },
          {
            actions: ['stop', 'loadAudio', sendParent({ type: 'PLAY' })],
            target: 'LOADING',
            delay: (context) => getExponentialBackoffDelay(context.retryCount),
            cond: ({ retryCount = 0 }) => retryCount < MAX_RETRY_ATTEMPTS,
          },
        ],
      },
      FAILURE: {
        id: 'failure',
        on: COMMON_ACTIONS,
        entry: ['sendPlaybackFailedEvent'],
        after: [{ delay: 1500, target: 'PAUSED', actions: sendParent({ type: 'CHILD.TRANSITIONED' })}],
      },
      DESTROYED: {
        entry: ['stop', 'sendStopEvent'],
        type: 'final',
      },
    },
  },
  {
    services: {
      loadSources: loadSourcesForItem,
    },
    guards: {
      shouldPlayAudioPreroll: (ctx) => {
        const isMobilePlatform = ctx.platform === Platform.ANDROID || ctx.platform === Platform.IOS;
        const isAffiliate =
          ctx.item.data.ownerOperatorIdent !== Preroll.AUDACY_OWNED_AND_OPERATED_CODE;

        const tritonName = (ctx.item as Station).getTritonName() || '';

        const fiveMinutesHasExpired = cache.get(Preroll.AUDIO_PREROLL) ? false : true;

        if (isMobilePlatform && isAffiliate && fiveMinutesHasExpired && tritonName) {
          return true;
        }
        // set to true to attempt to return an ad every time for testing
        return false;
      },
    },
    actions: {
      assignDefaults: assign(({ analyticsProvider, item, metadata, firstLoadTime }) => {
        if (!metadata) {
          // Initial load
          analyticsProvider.sendEventToListener({
            type: PlayerMetric.STREAM_LOAD,
            eventDetails: {
              stationId: item.getId(),
              streamerType: EContentType.LiveStation,
              isNativeErrorHandling: true,
            },
          });
          bufferingEventCounter.init(analyticsProvider, {
            stationId: item.getId(),
            streamerType: EContentType.Rewind,
          });
        }
        return {
          contentType: EContentType.LiveStation,
          firstLoadTime: firstLoadTime || Date.now(),
          metadata: {
            ...metadata,
            image: item.getImageSquare(),
            station: item.data?.parentTitle ?? item.getTitle(), // If this is a GoLive v1 episode, then parentTitle is the name of the show and title is the name of the episode
            artist: item.data?.parentTitle ? item.getTitle() : undefined,
          },
        };
      }),
      assignAudioSources: assign((_, { data }) => ({
        audioSources: data,
      })),
      assignRetryCountToZero: assign({
        retryCount: 0,
      }),
      assignIncrementRetryCount: assign({
        retryCount: ({ retryCount = 0 }) => retryCount + 1,
      }),
      addToHistory: (ctx) => {
        const id = ctx.item.getId();
        if (!ctx.personalizationProvider.isInTopOfHistoryStack(id)) {
          ctx.personalizationProvider.addToHistory([id]);
        }
      },
      loadAudioVastAd: async (ctx) => {
        const prerollInProgress = ctx.player.isAudioPreroll;

        if (prerollInProgress) {
          ctx.player.playPrerollInProgress();
        } else {
          // in the VAST enum in constants there are other id's to use in lieu or triton name
          // that will work or break on purpose depending on what you would like to test
          const tritonName = (ctx.item as Station).getTritonName() || '';

          const env = ctx.dataProvider.env;
          const source = ctx.audioSources[0];

          const preroll = await loadAudioPreroll(tritonName, env);

          ctx.player?.load({
            url: preroll.creativeUrl || source.url,
            isPodcast: false,
            isSuperHifi: false,
            isAudioPreroll: preroll.creativeUrl ? true : false,
            liveContentUrl: source.url,
            companionAd: preroll.companionAd,
          });
          if (preroll.adStartTrackers.length > 0) {
            sendAudioPrerollTrackers(preroll.adStartTrackers);
          }
        }
      },
      assignMetadata: assign({
        metadata: ({ metadata: existingMetadata }, { metadata }) => {  
          const isNewContentOrAd = Boolean(metadata?.contentType)
          const isSameAd = isContentTypeAd(existingMetadata?.contentType) && !isNewContentOrAd && !metadata?.songOrShow
          if (
            isNewContentOrAd &&
            !metadata?.companionAdIframeUrl &&
            existingMetadata?.companionAdIframeUrl
          ) {
            // Make sure ad image gets deleted after ad
              delete existingMetadata?.companionAdIframeUrl
          } else if (isSameAd) {
            // Make sure ad metadata is retained if paused and resume ad
              metadata.companionAdIframeUrl = existingMetadata?.companionAdIframeUrl;
              metadata.songOrShow = existingMetadata?.songOrShow;
              metadata.artist = existingMetadata?.artist
          }

          return {
            ...existingMetadata,
            ...metadata,
            isAd: isContentTypeAd(metadata?.contentType),
          };
        },
      }),
      loadAudio: ({ audioSources, player, autoplay }) => {
        // Use HLS.js or fallback to native HLS or AAC/MP3 if that's the first source
        const source = audioSources[0];

        if (source) {
          player?.load({
            url: source.url,
            isPodcast: source.type !== 'm3u8',
            isSuperHifi: false,
            isAudioPreroll: false,
            liveContentUrl: '',
            autoplay: autoplay,
          });
        }
      },
      sendLiveSongListenedEvent: (ctx, e) => {
        const existingMetadata = ctx.metadata;
        const newMetadata = e.metadata;
        const isNewSong = existingMetadata?.songOrShow !== newMetadata?.songOrShow;
        const newSong = existingMetadata?.songOrShow || '';
        const isContent = existingMetadata && existingMetadata.contentType === 'content';
        const hasArtist = Boolean(existingMetadata?.artist);
        const hasSong = Boolean(existingMetadata?.songOrShow);
        const audioRoute = ctx.deviceInfoProvider?.getAudioRoute();
        
        if (existingMetadata && isNewSong && isContent && hasArtist && hasSong) {
          ctx.analyticsProvider.sendSongListenedEvent(
            PlayerAction.SONG_LISTENED,
            ctx.item.data.id,
            newSong,
            {
              connectionType: audioRoute,
              streamUrl: getFirstAudioSourceUrl(ctx.item.data.streamUrl)
            }
          );
        }
      },
      sendPlayEvent: ({ analyticsProvider, item, firstLoadTime, retryCount, errors }) => {
        if (item.data.id) {
          analyticsProvider.sendPlayerEvent({ type: PlayerAction.PLAY, contentId: item.data.id, streamUrl: getFirstAudioSourceUrl(item.data.streamUrl) });
          analyticsProvider.sendEventToListener({
            type: PlayerMetric.STREAM_PLAY,
            eventDetails: {
              stationId: item.getId(),
              streamerType: EContentType.LiveStation,
              uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
              retryCount,
              isNativeErrorHandling: true,
            },
          });
          if (retryCount) {
            analyticsProvider.sendEventToListener({
              type: PlayerMetric.STREAM_RECONNECT_SUCCESSFUL,
              eventDetails: {
                stationId: item.getId(),
                streamerType: EContentType.LiveStation,
                uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
                retryCount,
                mostRecentError: errors?.[0],
                isNativeErrorHandling: true,
              },
            });
          }
        }
      },
      sendStopEvent: ({ analyticsProvider, item, firstLoadTime, retryCount, errors }) => {
        if (item.data.id) {
          analyticsProvider.sendPlayerEvent({ type: PlayerAction.STOP, contentId: item.data.id, streamUrl: getFirstAudioSourceUrl(item.data.streamUrl)});
          analyticsProvider.sendEventToListener({
            type: PlayerMetric.STREAM_PAUSE,
            eventDetails: {
              stationId: item.getId(),
              streamerType: EContentType.LiveStation,
              uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
              retryCount,
              isNativeErrorHandling: true,
            },
          });
          if (retryCount) {
            analyticsProvider.sendEventToListener({
              type: PlayerMetric.STREAM_RECONNECT_ABORTED,
              eventDetails: {
                stationId: item.getId(),
                streamerType: EContentType.LiveStation,
                uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
                retryCount,
                mostRecentError: errors?.[0],
                isNativeErrorHandling: true,
              },
            });
          }
        }
      },
      sendBufferingEvent: assign(() => {
        bufferingEventCounter.addEvent('start_buffering');
          // analyticsProvider.sendEventToListener({
          //   type: PlayerMetric.STREAM_BUFFERING,
          //   eventDetails: {
          //     stationId: item.getId(),
          //     streamerType: EContentType.LiveStation,
          //     uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
          //     isNativeErrorHandling: true,
          //   },
          // });
          return {
            bufferStartTime: Date.now(),
          };
      }),
      sendResumeFromBufferingEvent: assign(() => {
        bufferingEventCounter.addEvent('end_buffering');
        // analyticsProvider.sendEventToListener({
        //   type: PlayerMetric.STREAM_RESUME_FROM_BUFFERING,
        //   eventDetails: {
        //     stationId: item.getId(),
        //     streamerType: EContentType.LiveStation,
        //     uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
        //     timeBufferingMs: bufferStartTime ? Date.now() - bufferStartTime : 0,
        //     isNativeErrorHandling: true,
        //   },
        // });
        return {bufferStartTime: undefined}
    }),
      sendNativePlaybackFailedEvent: (
        { analyticsProvider, item, firstLoadTime, retryCount },
        event,
      ) => {
        AudacyLogger.info(
          `[${
            LoggerTopic.Streaming
          }]: LiveStationMachine: sendNativePlaybackFailedEvent event:\n${JSON.stringify(
            event,
            null,
            2,
          )}`,
        );

        analyticsProvider.sendEventToListener({
          type: PlayerMetric.STREAM_PLAYBACK_FAILED,
          eventDetails: {
            stationId: item.getId(),
            streamerType: EContentType.LiveStation,
            uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
            retryCount,
            error: {
              errorMessage: event.errorMessage,
              errorType: event.error,
            },
            isNativeErrorHandling: true,
          },
        });
      },
      ...DEFAULT_PLAYER_ACTIONS,
    },
  },
);

const selector: TStreamSelector<typeof liveStationMachine> = async (dataObject) => {
  if (
    dataObject instanceof Station &&
    dataObject.getEntityType() === EntityType.STATION &&
    dataObject.getEntitySubtype() === StationSubType.BROADCAST
  ) {
    AudacyLogger.info(
      `[${LoggerTopic.Streaming}]: LiveStationMachine: using NativePlayerErrorHandling streaming machine`,
    );
    return liveStationMachine.withContext({
      ...liveStationMachine.context,
      item: dataObject,
    });
  } else if (
    dataObject instanceof Episode &&
    dataObject.getEntitySubtype() === EpisodeSubType.HOST_CREATED_EPISODE
  ) {
    AudacyLogger.info(
      `[${LoggerTopic.Streaming}]: LiveStationMachine: using NativePlayerErrorHandling streaming machine`,
    );
    return liveStationMachine.withContext({
      ...liveStationMachine.context,
      item: dataObject,
    });
  }
};

export default selector;
