export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>List handle</title>
    <path stroke="currentColor" strokeWidth="1.5" d="M9 13.25h15M9 18.25h15" />
  </svg>
);
