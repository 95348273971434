import { getChapterTranscriptTime } from '@audacy-clients/core/hooks/useTranscript';
import React from 'react';
import styles, { Constants } from './styles';

type TranscriptHeaderProps = {
  startOffset: number;
  title?: string;
};

const TranscriptHeader: React.FC<TranscriptHeaderProps> = ({ startOffset, title }) => {
  return (
    <div css={styles.transcriptHeaderContainer}>
      <div css={styles.transcriptHeaderPillowContainer}>
        <text css={styles.transcriptHeaderPillowText}>
          {getChapterTranscriptTime(startOffset, Constants.transcriptHeaderTimeFormat)}
        </text>
      </div>
      <text css={styles.transcriptHeaderText}>{title}</text>
    </div>
  );
};

export default TranscriptHeader;
