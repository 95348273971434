import { Borders, Colors, Mixins } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  checkmark: {
    ...Mixins.accessibleMinButtonSize(),
    border: Borders.input,
    borderRadius: '50%',
    color: Colors.audacyDarkNavy,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 28,
    width: 28,
    ...Mixins.transition(['background', 'border-color']),
    // NOTE: removing hover state for now. It's not typically
    // something you see on a native checkbox.
    // Hover state triggered only when hovering over the input
    // When using Checkmark outside of Checkbox context, hover &
    // focus states should be controlled by parent.
    // 'input[type="checkbox"]:hover ~ span > &': {
    //   opacity: 0.6,
    // },
  },
  checked: {
    background: Colors.white,
    borderColor: Colors.white,
  },
  icon: {
    opacity: 0,
    ...Mixins.transition('opacity'),
  },
  iconChecked: {
    opacity: 1,
  },

  inputContainer: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  input: {
    // Reset focus on input
    ...Mixins.focusReset(),
    // Add focus on span
    ...Mixins.focusOutline({ selector: '~ span' }),
    appearance: 'none',
    backgroundColor: Colors.audacyDarkNavy,
    margin: 0,
  },
  label: {
    marginLeft: 20,
  },
};

export default styles;
