import PagedList from './PagedList';
import { ObjectType } from '../Constants';
import Container from '../Container';

const logger = Container;

/**
 * @module EpisodeList
 */
export default class AtlHostsSearchResultList extends PagedList {
  /**
   * Creates a new instance of a List.
   * @param {string}                  version
   * @param {object}                  data
   * @param {DataServices}            dataService
   * @param {String}                  searchStr
   * @param {PagedListQueryFunction} [queryFunction=undefined]
   */
  constructor(version, data, dataService, searchStr, queryFunction = undefined) {
    super(version, data, dataService, ObjectType.ATL_HOST_LIST, queryFunction);
    this.searchStr = searchStr;
  }

  /**
   * @param {Number} index
   * @returns {Number}
   */
  getId(index) {
    return this.data[index]?.id;
  }

  /**
   * Get the text description of the image associated with the entity.
   * @param {Number} index
   * @returns {String}
   */
  getImageAlt(index) {
    return this.data[index]?.image.alt;
  }

  /**
   * Get the square station image.
   * @param {Number} index
   * @returns {String}
   */
  getImageSquare(index) {
    return this.data[index]?.image.square;
  }

  /**
   * @param {Number} index
   * @returns {String}
   */
  getFirstName(index) {
    return this.data[index]?.firstName;
  }

  /**
   * @param {Number} index
   * @returns {String}
   */
  getLastName(index) {
    return this.data[index]?.lastName;
  }

  /**
   * @param {Number} index
   * @returns {Promise<undefined>}
   */
  getObject(index) {
    logger.error('Needs to be implemented once person object defined');
    return Promise.resolve();
  }

  /**
   * @param {Number} index
   * @returns {undefined}
   */
  getObjectSync(index) {
    logger.error('Needs to be implemented once person object defined');
    return undefined;
  }
}
