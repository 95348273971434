import { CollectionSubType, EntityType, ModuleType } from '@audacy-clients/client-services/core';
import { EntityImageShape, IModule } from '@audacy-clients/core/atoms/wrappers/modules';
import { IContentSummary } from '@audacy-clients/core/atoms/wrappers/types';
import { IModuleContext } from '@audacy-clients/core/components/ModuleRenderer/types';
import { IPlayContext } from '@audacy-clients/core/hooks/useAudioHelpers';
import { getPlayingUntilFromStartAndDuration } from '@audacy-clients/core/utils/date';
import { getEpisodeTitleAndImage } from '@audacy-clients/core/utils/episode';

import { CardType, ICard, TModuleFeaturedCardProps } from '~/components/Card';
import { isPlayable } from '~/utils/card';
import { formatPublishDate } from '~/utils/date';

const CardTypeMapping: { [Key in ModuleType]?: CardType } = {
  [ModuleType.ENTITY_CARD_FEATURED]: CardType.Featured,
  [ModuleType.ENTITY_CARD_HORIZONTAL]: CardType.Horizontal,
  [ModuleType.ENTITY_CARD_IMAGE_GROUP]: CardType.Vertical,
  [ModuleType.ENTITY_CARD_VERTICAL]: CardType.Vertical,
  [ModuleType.COLLECTION_PLAYLIST_CARD]: CardType.Vertical,
};

export const getCardType = (
  moduleType: ModuleType,
  moduleContext: IModuleContext,
): CardType | undefined => {
  if (moduleContext.carousel?.isStacked) {
    return CardType.Horizontal;
  } else {
    return CardTypeMapping[moduleType];
  }
};

export const isStacked = (module: IModule): boolean => !!module?.config?.itemsPerColumn;

export const formatFeaturedCard = (
  type: CardType,
  module: IModule,
  playContext: IPlayContext,
  data?: IContentSummary,
  context?: IModuleContext,
): TModuleFeaturedCardProps => {
  const eyebrow = module.config?.hideEyebrow
    ? undefined
    : module.config?.eyebrow
    ? module.config.eyebrow
    : data?.publishDate
    ? [`${formatPublishDate(data.publishDate)}`, data.parentTitle || ''].filter((item) => !!item)
    : data?.parentTitle || data?.title;
  const episodeThumbnail = data?.entityType === EntityType.EPISODE ? data?.parentImage : undefined;
  const thumbnail =
    module.config.thumbnail && !module.config.hideThumbnail ? module.config.thumbnail : undefined;

  return {
    contentId: data?.id,
    ctaHref: module.config.url,
    duration: data?.duration,
    entitySubtype: data?.entitySubtype,
    entityType: data?.entityType,
    entityURL: data?.url,
    eyebrow,
    forceVertical: !!context?.carousel && context?.carousel.numberOfItems > 1,
    hideThumbnail: module.config.hideThumbnail,
    highlightText: module.config.highlight,
    imageAltText: module.config.imageAlt,
    imageURIBase: module.config.image,
    isExternalUrl: module.config.isExternalUrl,
    isPlayable: data?.entityType ? isPlayable(data.entityType) : false,
    linkText: module.config.ctaText,
    playContext,
    replayableUntilDateTime: data?.replayableUntilDateTime,
    subtitle: module.config.subtitle,
    thumbnailURI: thumbnail || episodeThumbnail || data?.image,
    title: (module.config.title as string) || '',
    type,
  };
};

export const formatCard = (
  type: CardType,
  module: IModule,
  playContext: IPlayContext,
  data?: IContentSummary,
  context?: IModuleContext,
): ICard => {
  const href = data?.url;
  const showHref = data?.showUrl;
  const duration =
    data?.entitySubtype == CollectionSubType.PLAYLIST && module.config?.totalDurationInSeconds
      ? +module.config.totalDurationInSeconds
      : data?.duration;

  const isFixed =
    type === CardType.Horizontal && !!context?.carousel && !context.carousel.isStacked;

  const [title, image] = getEpisodeTitleAndImage(data);

  return {
    contentId: data?.id,
    duration,
    isPlayable: data?.entityType ? isPlayable(data.entityType) : false,
    liveUntil: {
      playingUntil: getPlayingUntilFromStartAndDuration(data?.startDateTime, data?.duration),
    },
    publishDate: data?.publishDate,
    entityType: data?.entityType,
    entitySubtype: data?.entitySubtype,
    href,
    episodeHref: data?.episodeUrl,
    showHref,
    image,
    imageAlt: title,
    imageShape: module.config.entityImageShape || EntityImageShape.Square,
    showContentId: data?.showContentId,
    startDateTime: data?.startDateTime,
    title,
    type,
    isFixed,
    playContext,
    parentTitle: data?.parentTitle,
    genres: data?.genres,
    marketTitle: data?.marketTitle,
    author: data?.author,
    itemsCount: module.config?.idList?.length,
    replayableUntilDateTime: data?.replayableUntilDateTime,
  };
};
