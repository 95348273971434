import DataObject from './DataObject';
import { ObjectType } from '../Constants';

/**
 * @module Station_Summary
 */
export default class StationSummary extends DataObject {
  /**
   * Creates a new instance of a Station Summary.
   * @param {StationSummary} data
   */
  constructor(data) {
    super(ObjectType.STATION_SUMMARY, data);
  }

  /**
   * Return array of genres
   * @eeturns {Array<String>}
   */
  getGenres() {
    return this.data.genres;
  }

  /**
   * Returns isFav
   * @returns {String}
   */
  getIsFav() {
    return this.data.isFav;
  }

  /**
   * Returns marketTitle
   * @returns {String}
   */
  getMarketTitle() {
    return this.data.marketTitle;
  }

  /**
   * Returns title
   * @returns {String}
   */
  getTitle() {
    return this.data.title;
  }
}
