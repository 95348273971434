import { useCallback, useState } from 'react';

import { ScrubberBarType } from '@audacy-clients/client-services/src/audioServices/streamers/types';
import {
  nowPlaying,
  playerControlsState,
  playerTimeState,
} from '@audacy-clients/core/atoms/player';
import { formatSecondsToTime } from '@audacy-clients/core/utils/time';
import format from 'date-fns/format';
import { useRecoilState, useRecoilValue } from 'recoil';

import Constants from '~/components/Player/Scrubber/constants';
import { IScrubberProps } from '~/components/Player/Scrubber/Scrubber';
import { useAudioHelpersWeb } from '~/hooks/use-audio-helpers-web';
import { userTimeState as userTimeStateAtom } from '~/state/player';

interface IUseScrubberProps {
  playProgress?: number;
}

type TUseScrubber = Pick<
  IScrubberProps,
  | 'customEnd'
  | 'onScrubAction'
  | 'onScrubActionStart'
  | 'onScrubActionEnd'
  | 'playProgressValue'
  | 'scrubToTime'
  | 'isScrubbing'
  | 'currentTime'
>;

// TODO: [CCS-2304]: Should this be memoized?
export const useScrubberProps = ({ playProgress }: IUseScrubberProps): TUseScrubber => {
  const audioHelpers = useAudioHelpersWeb();

  const { scrubberBarType } = useRecoilValue(playerControlsState) || {};
  const [{ duration = 0, offset = 0, playbackFraction = 0 }] = useRecoilState(playerTimeState);
  const playerItem = useRecoilValue(nowPlaying);
  const [userTimeState, setUserTimeState] = useRecoilState(userTimeStateAtom);
  const { userOffset, userPlaybackFraction } = userTimeState;
  const [isScrubbing, setIsScrubbing] = useState(false);

  const showUserScrubTimes = typeof userOffset === 'number';
  const offsetToUse = showUserScrubTimes ? userOffset || 0 : offset;
  const shouldUseUserTime = showUserScrubTimes && typeof userPlaybackFraction === 'number';

  let customEnd = formatSecondsToTime(isScrubbing ? offset - duration : offsetToUse - duration);
  let scrubToTime = formatSecondsToTime(offsetToUse);

  let playProgressValue = shouldUseUserTime
    ? userPlaybackFraction
    : playProgress ?? playbackFraction;

  switch (scrubberBarType) {
    case ScrubberBarType.Live:
      customEnd = '';
      playProgressValue = 1;
      break;
    case ScrubberBarType.WallClock:
      if (playerItem?.endTime && playerItem?.startTime) {
        customEnd = format(playerItem.endTime, Constants.timeFormat);
        const wallTimeByFract = playerItem.startTime + playProgressValue * duration * 1000;
        scrubToTime = format(wallTimeByFract || 0, Constants.timeFormat);
      }
      break;
  }

  const onScrubAction = useCallback(
    (value: number) => {
      if (isScrubbing) {
        const newOffset = value * duration;

        setUserTimeState({
          userOffset: newOffset,
          userPlaybackFraction: value,
          userRemaining: duration - newOffset,
        });
      }
    },
    [duration, isScrubbing, setUserTimeState],
  );

  const onScrubActionEnd = useCallback(() => {
    setIsScrubbing(false);
    audioHelpers.scrubbed();
    audioHelpers.seekToProgress(playProgressValue);
    setUserTimeState({});
  }, [audioHelpers, setUserTimeState, playProgressValue]);

  const onScrubActionStart = useCallback(() => {
    setIsScrubbing(true);
  }, [setIsScrubbing]);

  const props: TUseScrubber = {
    scrubToTime,
    currentTime: offsetToUse,
    customEnd,
    onScrubAction,
    onScrubActionEnd,
    onScrubActionStart,
    isScrubbing,
    playProgressValue,
  };

  return props;
};
