import Loading from '~/components/Loading';
import styles from '~/components/Loading/styles';

const CenteredLoading = (): JSX.Element => {
  return (
    <div aria-label="Centered Loading" css={styles.center}>
      <Loading />
    </div>
  );
};

export default CenteredLoading;
