import { Colors, Mixins, mqHover, Typography } from '~/styles';
import { TStyles, TStyleSheet } from '~/types/emotion-styles';

export const link: TStyles = {
  ...Mixins.transition('color'),
  alignItems: 'center',
  color: Colors.white,
  ...mqHover({
    color: Colors.white60,
  }),
  display: 'inline-flex',
  position: 'relative',
};

const styles: TStyleSheet = {
  inline: {
    ...Typography.bodyLarge,
    textDecoration: 'underline',
  },
  label: {
    ...Typography.buttonLarge,
  },
  link: {
    ...link,
    gap: 5,
  },
  iconBefore: {
    marginRight: 5,
  },
  iconAfter: {
    marginLeft: 5,
  },
  tiny: {
    ...Typography.buttonSmall,
  },
};

export const inlineLink: TStyles = {
  ...link,
  ...styles.inline,
};

export default styles;
