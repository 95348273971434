import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import Button from '~/components/Button';
import { Path } from '~/routes';
import { Colors, Mixins } from '~/styles';
import { LinkType } from '~/types/links';

import styles from './styles';

//KY: Was told this gradient might be used elsewhere in the application
//Written to be easily extractable into it's own component if needed.

const BottomRightGradient = () => {
  return (
    <>
      <div css={[styles.gradientContainer]}>
        <div css={styles.gradient}>
          <div
            css={[styles.gradientColor]}
            style={Mixins.backgroundGradientStyle(Colors.audacyOrange, 0.3, 200)}
          />
          <div
            css={[styles.gradientColor]}
            style={Mixins.backgroundGradientStyle(Colors.secondaryPurple, 0.3, 190)}
          />
        </div>
      </div>
    </>
  );
};

const PageNotFound = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <div css={Mixins.fullScreenContainer}>
        <div role="alert" css={styles.errorContainer}>
          <h1 css={styles.title}>{t('global.404.title')}</h1>
          <p css={styles.text}>{t('global.404.errorText')}</p>
          <Button
            as={LinkType.Anchor}
            size="Default"
            theme="Secondary"
            label={t('global.404.home')}
            href={Path.Home}
          />
        </div>
        <BottomRightGradient />
      </div>
    </>
  );
};

export default PageNotFound;
