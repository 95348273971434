import { Colors } from '~/styles';
import { type TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  topOverlayContainer: {
    position: 'sticky',
    top: 0,
    pointerEvents: 'none',
    zIndex: 1,
    overflow: 'visible',
  },
  topOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    background: `linear-gradient(to bottom, ${Colors.secondaryDeepBlue}, transparent)`,
    padding: '10px',
  },
  bottomOverlay: {
    position: 'sticky',
    bottom: -1,
    left: 0,
    right: 0,
    height: '30px',
    pointerEvents: 'none',
    background: `linear-gradient(to top, ${Colors.secondaryDeepBlue}, transparent)`,
    zIndex: 1,
  },
};
export default styles;
