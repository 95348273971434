export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Markets</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.5 28.5v-24h-15v15.205L9.504 16.63 3.5 21.246V28.5h25ZM19.977 8H17v3.976h2.977V8ZM17 15h2.977v3.977H17V15Zm7.977-7H22v3.976h2.977V8ZM22 15h2.977v3.977H22V15ZM8.004 22h3v3h-3v-3Z"
      fill="currentColor"
    />
  </svg>
);
