import { BP, Colors, Mixins, mq, PlayerStyles, Typography, ZIndex } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  root: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0 20px',
    justifyContent: 'space-between',
    position: 'relative',
    [mq(BP.Large)]: {
      flexWrap: 'nowrap',
    },
  },
  time: {
    ...Typography.metadataSmall,
    color: Colors.white60,
    flexShrink: 0,
    minWidth: 55,
    '&:last-of-type': {
      textAlign: 'right',
    },
    [mq(BP.Large)]: {
      '&:first-of-type': {
        order: '0',
        textAlign: 'right',
      },
      '&:last-of-type': {
        order: '2',
        textAlign: 'left',
      },
    },
  },
  inputWrapper: {
    flexBasis: '100%',
    position: 'relative',
    [mq(BP.Large)]: {
      order: 1,
    },
  },
  scrubBar: {
    appearance: 'none',
    background: 'transparent',
    display: 'block',
    height: 2,
    opacity: 1,
    padding: '10px 0',
    width: '100%',
    ...PlayerStyles.sliderStyles({ isThumbHidden: true }),
    '&:is(:hover:not(:disabled), :focus:not(:disabled))': {
      '&::-webkit-slider-thumb': {
        opacity: 1,
        transition: 'opacity 0.3s ease',
      },
      '&::-moz-range-thumb': {
        opacity: 1,
        transition: 'opacity 0.3s ease',
      },
    },
    [mq(BP.Large)]: {
      padding: '15px 0',
    },
  },
  scrubPill: {
    opacity: 0,
    backgroundColor: '#3C3853',
    borderRadius: '100px',
    color: 'white',
    padding: '6px 14px',
    fontSize: '12px',
    textAlign: 'center',
    transition: 'opacity 0.3s ease',
    position: 'relative',
    left: '-50%',
    fontVariantNumeric: 'tabular-nums',
  },
  scrubPillContainer: {
    width: 'auto',
    position: 'absolute',
    left: PlayerStyles.THUMB_WIDTH / 2,
  },
  scrubPillVisible: {
    opacity: 1,
  },
  darken: {
    opacity: 0,
  },
  bar: {
    height: '100%',
    alignItems: 'center',
    pointerEvents: 'none',
    position: 'absolute',
    display: 'flex',
    top: 0,
    left: 0,
    width: '100%',
  },
  barInner: {
    position: 'relative',
    width: '100%',
    height: 2,
    '& > span': {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      '&:first-of-type': {
        backgroundColor: Colors.white30,
      },
      '&:last-of-type': {
        backgroundColor: Colors.white,
      },
    },
  },
  liveContent: {
    zIndex: ZIndex.liveIndicator,
    opacity: 1,
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translateY(-100%)',
    [mq(BP.Large)]: {
      minWidth: 55,
    },
    transition: 'opacity 0.7s ease',
  },
  liveIndicatorCenter: {
    [mq(BP.Large)]: {
      transform: 'translateY(30%)',
    },
  },
  skipButton: {
    ...Mixins.accessibleMinButtonSize(),
  },
  liveIndicator: {
    ...Typography.buttonSmall,
    display: 'flex',
    gap: 5,
    color: Colors.audacyOrange,
  },
};

export default styles;
