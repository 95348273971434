import {
  CollectionSubType,
  EntitySubtype,
  EntityType,
  EpisodeSubType,
  ModuleType,
  ShowSubtype,
  StationSubType,
} from '@audacy-clients/client-services/core';
import { ViewIDKey } from '@audacy-clients/core/atoms/config/constants';
import { useRecoilValue } from 'recoil';

import { OptionsMenuTypes } from '~/components/OptionsMenu/constants';
import { WebViewIDKey } from '~/routes';
import { selectedTab as selectedTabReadOnly } from '~/state/my-audio';

export const useOptionsMenuType = (
  viewId: string,
  type?: EntityType,
  subtype?: EntitySubtype,
  moduleId?: string,
  hasExternalActions?: boolean,
): OptionsMenuTypes | null => {
  const selectedTab = useRecoilValue(selectedTabReadOnly);

  const isContinueListening = moduleId?.includes(ModuleType.CONTINUE_LISTENING);

  switch (viewId) {
    // MyAudio pages
    case ViewIDKey.MyAudioFollowed:
    case ViewIDKey.MyAudioPodcastsAndShows:
    case ViewIDKey.MyAudioQueue:
    case ViewIDKey.MyAudioRecent:
    case ViewIDKey.MyAudioStations:
    case ViewIDKey.MyAudioTopics:
      switch (selectedTab) {
        case 'myQueue':
          return OptionsMenuTypes.MyAudioQueue;
        case 'stations':
          return subtype === StationSubType.EXCLUSIVE
            ? OptionsMenuTypes.MyAudioExclusiveStation
            : OptionsMenuTypes.MyAudioRadioStation;
        case 'onDemands':
          return subtype === ShowSubtype.PODCAST
            ? OptionsMenuTypes.MyAudioPodcast
            : OptionsMenuTypes.MyAudioRadioShow;
        case 'topics':
          return OptionsMenuTypes.MyAudioTopic;
        case 'recents':
          if (type === EntityType.STATION) {
            return subtype === StationSubType.EXCLUSIVE
              ? OptionsMenuTypes.RecentExclusiveStation
              : OptionsMenuTypes.RecentRadioStation;
          }
          if (type === EntityType.SHOW || type === EntityType.EPISODE) {
            if (subtype === ShowSubtype.PODCAST || subtype === EpisodeSubType.PODCAST_EPISODE) {
              return OptionsMenuTypes.RecentPodcast;
            }
            return OptionsMenuTypes.RecentRadioShow;
          }
          if (type === EntityType.STANDALONE_CHAPTER) {
            return OptionsMenuTypes.StandaloneChapter;
          }
          return null;
        default:
          return null;
      }
    case ViewIDKey.Playlist:
      return OptionsMenuTypes.Playlist;
    // Player
    case ViewIDKey.Player:
      switch (type) {
        case EntityType.STATION:
          return subtype === StationSubType.EXCLUSIVE
            ? OptionsMenuTypes.FullPlayerExclusiveStation
            : OptionsMenuTypes.FullPlayerStation;
        case EntityType.EPISODE:
          return subtype === EpisodeSubType.PODCAST_EPISODE
            ? OptionsMenuTypes.FullPlayerPodcastEpisode
            : OptionsMenuTypes.FullPlayerBroadcastEpisode;
        default:
          return null;
      }

    case WebViewIDKey.ExclusiveStation:
      return null;

    case WebViewIDKey.Stations:
      switch (type) {
        case EntityType.EPISODE:
          return OptionsMenuTypes.BroadcastEpisode;
        default:
          return OptionsMenuTypes.RadioStationEntity;
      }

    case ViewIDKey.Search:
      switch (type) {
        case EntityType.STATION:
          return OptionsMenuTypes.SearchStation;
        case EntityType.SHOW:
          switch (subtype) {
            case ShowSubtype.PODCAST:
              return OptionsMenuTypes.SearchPodcastEntity;
            default:
              return null;
          }
        case EntityType.COLLECTION:
          switch (subtype) {
            case CollectionSubType.PLAYLIST:
              return OptionsMenuTypes.Playlist;
            default:
              return null;
          }
        case EntityType.EPISODE:
          switch (subtype) {
            case EpisodeSubType.PODCAST_EPISODE:
              return OptionsMenuTypes.SearchPodcastEpisode;
            default:
              return null;
          }
      }
      return null;

    // All other cases
    default:
      switch (type) {
        case EntityType.STANDALONE_CHAPTER:
          return OptionsMenuTypes.StandaloneChapter;
        case EntityType.CLIP:
          return OptionsMenuTypes.RadioShowClip;
        case EntityType.EPISODE:
          switch (subtype) {
            case EpisodeSubType.BROADCAST_SHOW_EPISODE:
              return isContinueListening
                ? OptionsMenuTypes.ContinueListeningBroadcastEpisode
                : OptionsMenuTypes.RadioShowEpisode;
            case EpisodeSubType.PODCAST_EPISODE:
              if (hasExternalActions) {
                return OptionsMenuTypes.SearchPodcastEpisode;
              }
              if (isContinueListening) {
                return OptionsMenuTypes.ContinueListeningPodcastEpisode;
              }
              return OptionsMenuTypes.PodcastEpisode;
            default:
              return null;
          }
        case EntityType.SHOW:
          switch (subtype) {
            case ShowSubtype.PODCAST:
              return OptionsMenuTypes.PodcastEntity;
            default:
              return null;
          }
        case EntityType.STATION:
          return subtype === StationSubType.EXCLUSIVE
            ? OptionsMenuTypes.RecentExclusiveStation
            : OptionsMenuTypes.RecentRadioStation;
        case EntityType.TAG:
        case EntityType.TOPIC:
          return OptionsMenuTypes.TopicEntity;
        default:
          return null;
      }
  }
};
