import { isLoggedIn } from '@audacy-clients/core/atoms/auth';
import { useFeatureFlag } from '@audacy-clients/core/atoms/config/settings';
import FocusTrap from 'focus-trap-react';
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { scrollableClassState } from '~/atoms/scrollClass';
import Animate from '~/components/Animate';
import CenteredLoading from '~/components/Loading/CenteredLoading';
import FullPlayer from '~/components/Player/FullscreenPlayer/Components/FullPlayer';
import MinimizePlayerButton from '~/components/Player/FullscreenPlayer/Components/MinimizePlayerButton';
import PlayerQueueHeader from '~/components/Player/FullscreenPlayer/Components/PlayerQueueHeader';
import InfoPanel from '~/components/Player/Info';
import QueueWithState from '~/components/Queue';
import { animationStatusName, fadeInOut } from '~/constants/animation';
import useBodyScrollLock, { allowTouchMoveBasedOnClass } from '~/hooks/use-body-scroll-lock';
import { useBreakpoints } from '~/hooks/use-breakpoints';
import useToast from '~/state/toast';
import { reduceVariantMotion } from '~/utils/animation';

import {
  Constants,
  fullPlayerVariantsLg,
  fullPlayerVariantsSm,
  queueContainerVariantsInitial,
  queueContainerVariantsReady,
} from './constants';
import { useContainerAnimation, useDelayedDisplay, useQueueAnimation } from './hooks';
import styles from './styles';

const scrollClass = 'fullPlayerScrollableContent';
const allowTouchMove = allowTouchMoveBasedOnClass(scrollClass);

const FullscreenPlayer = (): JSX.Element => {
  const { greaterThan, lessThan } = useBreakpoints();
  const loggedIn = useRecoilValue(isLoggedIn);
  const isQueueEnabled = useFeatureFlag('queue') && loggedIn;
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const { currentToast } = useToast();
  const fullPlayerEl = useRef<HTMLDivElement>(null);
  const queueEl = useRef<HTMLDivElement>(null);
  const shouldReduceMotion = useReducedMotion();

  const {
    handlePlayerContentVisibleComplete,
    handlePlayerVisibleComplete,
    isPlayerContentVisible,
    isPlayerContentAnimationComplete,
    isPlayerVisible,
  } = useContainerAnimation();
  const {
    handleQueueAnimationComplete,
    handleQueueClick,
    handleQueueContainerAnimationComplete,
    isQueueTransitioned,
    isQueueVisible,
  } = useQueueAnimation();
  const queueDelayedDisplay = useDelayedDisplay(
    isPlayerContentAnimationComplete,
    Constants.queueDelay,
  );
  const infoDelayedDisplay = useDelayedDisplay(
    isPlayerContentAnimationComplete,
    Constants.infoDelay,
  );

  useBodyScrollLock(isPlayerContentAnimationComplete, fullPlayerEl.current, allowTouchMove);
  useBodyScrollLock(isPlayerContentAnimationComplete, queueEl.current, allowTouchMove);

  const setScrollableClass = useSetRecoilState(scrollableClassState);

  useEffect(() => {
    setScrollableClass(scrollClass);
  }, [setScrollableClass]);

  const handleContainerAnimationComplete = (definition: string) => {
    if (definition === animationStatusName.animate) {
      setIsPlayerReady(true);
    } else if (definition === animationStatusName.exit) {
      setIsPlayerReady(false);
      queueDelayedDisplay.setIsVisibleAfterDelay(false);
      infoDelayedDisplay.setIsVisibleAfterDelay(false);
    }

    handlePlayerVisibleComplete(definition);
  };

  const queueContainerVariants =
    isPlayerReady && isPlayerContentAnimationComplete
      ? queueContainerVariantsReady
      : queueContainerVariantsInitial;
  const visibleQueueContainerVariants = reduceVariantMotion(
    queueContainerVariants,
    shouldReduceMotion,
  );

  const fullPlayerVariant = greaterThan.MD ? fullPlayerVariantsLg : fullPlayerVariantsSm;
  const visibleFullPlayerVariants = reduceVariantMotion(fullPlayerVariant, shouldReduceMotion);
  const transitionKey = greaterThan.MD ? 'large' : 'small'; // Framer Motion uses this to reset animation properties
  const isInfoVisible = infoDelayedDisplay.isVisibleAfterDelay;

  const handleOutsideClick = (evt: MouseEvent | TouchEvent) => {
    const el = evt.target as Element;

    return el.className.split(' ').some((className: string) => className.includes('ab-'));
  };

  return (
    <Animate
      isVisible={isPlayerVisible}
      motionCss={[styles.container, styles.mobileHeightFix]}
      onAnimationComplete={handleContainerAnimationComplete}
      style={{ originY: 1, originX: 1 }}
      variants={visibleFullPlayerVariants}
      key={transitionKey}
      {...animationStatusName}
    >
      <Animate.FadeInOut
        motionCss={styles.playerContent}
        isVisible={isPlayerContentVisible}
        onAnimationComplete={handlePlayerContentVisibleComplete}
        variants={fadeInOut()}
        {...animationStatusName}
      >
        <FocusTrap
          paused={!!currentToast}
          focusTrapOptions={{ allowOutsideClick: handleOutsideClick }}
        >
          <div css={styles.fullPlayer}>
            <MinimizePlayerButton />
            {isQueueEnabled && (
              <AnimatePresence>
                {isQueueVisible && (
                  <motion.div
                    variants={visibleQueueContainerVariants}
                    onAnimationComplete={handleQueueContainerAnimationComplete}
                    css={styles.queueContainer}
                    {...animationStatusName}
                  >
                    {queueDelayedDisplay.isVisibleAfterDelay || lessThan.LG ? (
                      <AnimatePresence>
                        {isQueueTransitioned && (
                          <motion.div
                            variants={fadeInOut()}
                            onAnimationComplete={handleQueueAnimationComplete}
                            {...animationStatusName}
                          >
                            <div>
                              {lessThan.LG && (
                                <PlayerQueueHeader onBackButtonClick={handleQueueClick} />
                              )}
                              <div css={styles.queue} ref={queueEl}>
                                <QueueWithState
                                  containerCss={styles.queueListContainer}
                                  isPlayerQueue
                                  emptyQueueCss={styles.queueEmpty}
                                  shouldForceFocus={greaterThan.MD}
                                />
                              </div>
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    ) : (
                      <CenteredLoading />
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            )}

            <Animate.FadeInOut
              motionCss={styles.mainContainer}
              isVisible={!isQueueVisible || greaterThan.MD}
            >
              <div css={styles.playerContainer} ref={fullPlayerEl}>
                <FullPlayer
                  isInfoVisible={isInfoVisible}
                  isQueueVisible={isQueueVisible && isQueueEnabled}
                  onQueueClick={handleQueueClick}
                />
              </div>

              {greaterThan.MD && (
                <div css={styles.infoContainer}>
                  <InfoPanel isVisible={isInfoVisible} />
                </div>
              )}
            </Animate.FadeInOut>
          </div>
        </FocusTrap>
      </Animate.FadeInOut>
    </Animate>
  );
};

export default FullscreenPlayer;
