import { Icons } from '~/components/Icon/constants';
import PlayerConstants from '~/constants/player';

import { IPlayerAction, TGetPrimaryAction, TGetSecondaryAction } from './types';

export const getSecondaryAction = ({
  canScrub,
  canSkip,
  canSwitchTracks,
  isRewindDisabled,
  isForwardDisabled,
  isLiked,
  isLikeDisabled,
  isSkipDisabled,
  isNextDisabled,
  isPrevDisabled,
  onLike,
  onSendNext,
  onSkip,
  onNext,
  onPrev,
}: TGetSecondaryAction): {
  leftControl?: IPlayerAction;
  rightControl?: IPlayerAction;
  prevBtn?: IPlayerAction;
  nextBtn?: IPlayerAction;
} => {
  let leftControl: IPlayerAction | undefined;
  let rightControl: IPlayerAction | undefined;
  let prevBtn: IPlayerAction | undefined;
  let nextBtn: IPlayerAction | undefined;

  if (canSkip) {
    leftControl = {
      name: 'like',
      accessibilityLabel: isLiked
        ? ['playerControls.accessibilityLabels.thumbsFilled']
        : ['playerControls.accessibilityLabels.thumbs'],
      icon: isLiked ? Icons.ThumbsUpFilled : Icons.ThumbsUp,
      isDisabled: isLikeDisabled,
      onClick: onLike,
    };
    rightControl = {
      name: 'next',
      accessibilityLabel: ['playerControls.accessibilityLabels.skip'],
      icon: Icons.Next,
      isDisabled: isSkipDisabled,
      onClick: onSendNext,
    };
  } else if (canScrub && onSkip) {
    leftControl = {
      name: 'back15',
      accessibilityLabel: [
        'playerControls.accessibilityLabels.rewind',
        { seconds: PlayerConstants.skip15 },
      ],
      icon: Icons.Back15,
      isDisabled: isRewindDisabled,
      onClick: () => onSkip(PlayerConstants.skip15 * -1),
    };
    rightControl = {
      name: 'forward15',
      accessibilityLabel: [
        'playerControls.accessibilityLabels.forward',
        { seconds: PlayerConstants.skip15 },
      ],
      icon: Icons.Forward15,
      isDisabled: isForwardDisabled,
      onClick: () => onSkip(PlayerConstants.skip15),
    };
  }

  if (canSwitchTracks) {
    prevBtn = {
      name: 'previous',
      accessibilityLabel: ['playerControls.accessibilityLabels.previous'],
      icon: Icons.Previous,
      isDisabled: isPrevDisabled,
      onClick: onPrev,
    };
    nextBtn = {
      name: 'next',
      accessibilityLabel: ['playerControls.accessibilityLabels.next'],
      icon: Icons.Next,
      isDisabled: isNextDisabled,
      onClick: onNext,
    };
  }

  return {
    leftControl,
    rightControl,
    prevBtn,
    nextBtn,
  };
};

export const getPrimaryAction = ({
  onPause,
  onPlay,
  onStop,
  hasPause,
  hasStop,
}: TGetPrimaryAction): IPlayerAction => {
  if (hasPause) {
    return {
      name: 'pause',
      accessibilityLabel: ['playerControls.accessibilityLabels.pause'],
      icon: Icons.Pause,
      onClick: onPause,
    };
  } else if (hasStop) {
    return {
      name: 'stop',
      accessibilityLabel: ['playerControls.accessibilityLabels.stop'],
      icon: Icons.Stop,
      onClick: onStop,
    };
  } else {
    return {
      name: 'play',
      accessibilityLabel: ['playerControls.accessibilityLabels.play'],
      icon: Icons.Play,
      onClick: onPlay,
    };
  }
};
