import { IQueueItem } from '@audacy-clients/core/atoms/wrappers/types';
import { atom } from 'recoil';

export interface IQueueState {
  currentId: string;
  items: IQueueItem[];
}

export const queueHadExpiredItemsState = atom<boolean>({
  default: false,
  key: 'QueueHadExpiredItems',
});

export const queueIsLoadingState = atom<boolean>({
  default: true,
  key: 'QueueIsLoading',
});

export const queueLastUpdateState = atom<number>({
  default: 0,
  key: 'QueueLastUpdate',
});

export const queueState = atom<IQueueState>({
  default: { currentId: '', items: [] },
  key: 'Queue',
});
