import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { env } from './env';

const gtmParams = {
  id: env.GTM_ID,
  environment: {
    gtm_auth: env.GTM_AUTH,
    gtm_preview: env.GTM_PREVIEW,
  },
};

export { GTMProvider, gtmParams };
