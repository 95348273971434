import { useTranslation } from 'react-i18next';

import Button from '~/components/Button';

import styles from './styles';

const Error = (): JSX.Element => {
  const { t } = useTranslation();

  // NB: This could change to refreshing the currently used
  // recoil selectors instead of a full page refresh
  const onRefresh = () => {
    window.location.reload();
  };

  return (
    <div role="alert" css={styles.container}>
      <p css={styles.copy}>{t('global.error')}</p>
      <Button onClick={onRefresh} size="Default" theme="Secondary" label={t('global.retry')} />
    </div>
  );
};

export default Error;
