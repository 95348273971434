export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Completed</title>
    <circle cx="16" cy="16" r="12" stroke="currentColor" strokeWidth="1.5" />
    <path
      d="m10.768 16.105 3.987 3.443 6.344-7.472"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);
