export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Upload</title>

    <path
      d="M22 21.23h1.891c3.185 0 5.109-1.927 5.109-4.481 0-2.147-1.25-3.887-3.272-4.712C25.75 7.369 22.435 4 18.196 4c-2.772 0-4.783 1.486-6.033 3.336-2.38-.639-5.304 1.21-5.38 4.14C4.457 11.86 3 13.797 3 16.275c0 2.752 2.043 4.953 5.424 4.953h2.701"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m16.256 13.78.53-.53.53.53 3.298 3.298.53.53-1.06 1.061-.53-.53-2.018-2.018v10.956h-1.5V16.121l-2.017 2.018-.53.53-1.061-1.06.53-.53 3.298-3.299Z"
      fill="currentColor"
    />
  </svg>
);
