import { IModuleProps } from '@audacy-clients/core/components/ModuleRenderer/types';

import Title from '~/components/Title';

const ModuleViewTitle: React.FC<IModuleProps> = ({ module }) => {
  const nonEmptyFragment = module?.config?.titleFragments?.find(
    (fragment) => fragment.replace(/\s/g, '').length > 0,
  );
  return nonEmptyFragment ? <Title>{module?.config?.titleFragments?.join(' ')}</Title> : <></>;
};

export default ModuleViewTitle;
