import { Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'inherit',
  },
  footerlink: {
    ...Typography.buttonSmall,
  },
};

export default styles;
