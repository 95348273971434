import React from 'react';

const BubbleQuote: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    {...props} // This spreads any props passed to BubbleQuote, including style
  >
    <g id="Bubble">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 3.25C1.5 2.42157 2.17157 1.75 3 1.75H8C8.82843 1.75 9.5 2.42157 9.5 3.25V6.75C9.5 7.57843 8.82843 8.25 8 8.25H4.96429C4.84317 8.25 4.72618 8.29396 4.63503 8.37371L3.75 9.14812V8.75C3.75 8.47386 3.52614 8.25 3.25 8.25H3C2.17157 8.25 1.5 7.57843 1.5 6.75V3.25ZM3 0.75C1.61929 0.75 0.5 1.86929 0.5 3.25V6.75C0.5 8.04634 1.48667 9.11222 2.75 9.23766V10.25C2.75 10.4462 2.86474 10.6242 3.04339 10.7053C3.22205 10.7864 3.43161 10.7555 3.57925 10.6263L5.15215 9.25H8C9.38071 9.25 10.5 8.13071 10.5 6.75V3.25C10.5 1.86929 9.38071 0.75 8 0.75H3ZM3.32305 6.03409V6.875C4.60146 6.73864 5.25893 6.10227 5.25893 5V3.5H3.25V5.19318H3.96834C3.96834 5.68182 3.82224 5.89773 3.32305 6.03409ZM6.00162 6.03409V6.875C7.28003 6.73864 7.9375 6.10227 7.9375 5V3.5H5.92857V5.19318H6.64692C6.64692 5.68182 6.50081 5.89773 6.00162 6.03409Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default BubbleQuote;
