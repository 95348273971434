export interface IDeferred<T> {
  promise: Promise<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reject: (reason?: any) => void;
  resolve: (value: T | PromiseLike<T>) => void;
}

export const deferred = <T>(): IDeferred<T> => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  let _resolve: IDeferred<T>['resolve'], _reject: IDeferred<T>['reject'];
  const promise = new Promise<T>((resolve, reject) => {
    _resolve = resolve;
    _reject = reject;
  });
  return {
    promise,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    reject: _reject!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    resolve: _resolve!,
  };
};
