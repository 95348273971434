export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Close</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16Zm0-17.06-4.5-4.5-1.06 1.06 4.5 4.5-4.5 4.5 1.06 1.06 4.5-4.5 4.5 4.5 1.06-1.06-4.5-4.5 4.5-4.5-1.06-1.06-4.5 4.5Z"
      fill="currentColor"
    />
  </svg>
);
