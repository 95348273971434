import React from 'react';

import { IModuleProps } from '@audacy-clients/core/components/ModuleRenderer/types';

import ListView from '~/components/List/ListView';

const ModuleList: React.FC<IModuleProps> = ({ children }) => {
  const listItems = children as JSX.Element[];

  return <ListView isListModule>{listItems}</ListView>;
};

export default ModuleList;
