import React, { ReactNode } from 'react';

import { needsHTMLRendering } from '@audacy-clients/core/utils/strings';
import isEmpty from 'lodash/isEmpty';

import HTML from '~/components/HTML';
import { TStyles } from '~/types/emotion-styles';

import styles from './styles';

interface IProps {
  description: string;
  render?: (children: ReactNode) => JSX.Element;
  shouldStripTags?: boolean;
  shouldStripInteractiveTags?: boolean;
  textCss?: TStyles;
}

const DescriptionWrapper: React.FC<IProps> = ({
  description,
  render,
  shouldStripTags = false,
  shouldStripInteractiveTags = false,
  textCss,
}) => {
  if (!isEmpty(description)) {
    const element = needsHTMLRendering(description) ? (
      <HTML
        shouldStripTags={shouldStripTags}
        shouldStripInteractiveTags={shouldStripInteractiveTags}
        text={description}
        textCss={textCss}
      />
    ) : (
      <p css={[styles.text, textCss]}>{description}</p>
    );
    return render ? render(element) : element;
  }

  return <></>;
};

export default DescriptionWrapper;
