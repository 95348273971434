import { type EntitySubtype, EntityType, EpisodeSubType } from '@audacy-clients/client-services';
import { ViewIDKey } from '@audacy-clients/core/atoms/config/constants';
import { useRecoilValue } from 'recoil';

import { useOptionsMenuType } from '~/components/OptionsMenu/hooks';
import { type IOptionsMenu } from '~/components/OptionsMenu/types';
import { useFollowEntity } from '~/hooks/use-follow-entity';
import { viewContext } from '~/state/dataEvents';

type IUseOptionsMenuHook = {
  contentId?: string;
  entityType?: EntityType;
  entitySubtype?: EntitySubtype;
  image?: string;
  isLive?: boolean;
  showContentId?: string;
  title?: string;
  url?: string;
  isFullPlayer?: boolean;
  moduleId?: string;
  hasExternalActions?: boolean;
  parentShowUrl?: string;
  parentEpisodeUrl?: string;
}

const useOptionsMenu = ({
  contentId = '',
  entityType,
  entitySubtype,
  image,
  isLive,
  showContentId,
  title,
  url,
  isFullPlayer,
  moduleId,
  hasExternalActions,
  parentShowUrl,
  parentEpisodeUrl,
}: IUseOptionsMenuHook): IOptionsMenu | undefined => {
  const { viewId } = useRecoilValue(viewContext);

  const followId =
    // Notice: use showContentId for podcast episode since showId is not provided in dataObject
    entityType === EntityType.EPISODE && entitySubtype === EpisodeSubType.PODCAST_EPISODE
      ? showContentId ?? contentId
      : contentId;

  const { isFollowing, toggleFollow } = useFollowEntity({
    contentId: followId,
    isShowUnfollowToast: true,
    title: title ?? '',
  });

  // The player is a special case where the viewId from the viewContext may
  // reflect the played entity or underlying page, so we force the viewId here.
  const optionsMenuType = useOptionsMenuType(
    isFullPlayer ? ViewIDKey.Player : viewId,
    entityType,
    entitySubtype,
    moduleId,
    hasExternalActions,
  );

  if (!contentId) {
    return;
  }

  return {
    contentId,
    data: {
      image,
      parentEntity: {
        contentId: showContentId,
      },
      url,
      title,
    },
    toggleFollow: () => toggleFollow && toggleFollow(),
    isFollowing,
    isLive,
    entityType,
    subtype: entitySubtype,
    type: optionsMenuType,
    parentShowUrl,
    parentEpisodeUrl,
  };
};

export default useOptionsMenu;
