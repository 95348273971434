import { TStyles, TStyleSheet } from '~/types/emotion-styles';

import { Colors } from './colors';

export const getTrackBackground = (pct: number): string =>
  `linear-gradient(90deg, ${Colors.white} 0%, ${Colors.white} ${pct}%, ${Colors.white30} ${pct}%, ${Colors.white30} 100%)`;

const THUMB_WIDTH = 12;

/**
 * This util fakes a "fill lower" style using a gradient.
 */
const sliderBackground = (pct: number): TStyles => ({
  '&::-webkit-slider-runnable-track': {
    background: getTrackBackground(pct),
  },
  '&::-moz-range-track': {
    background: getTrackBackground(pct),
  },
});

const rangeStyles: TStyleSheet = {
  thumb: {
    appearance: 'none',
    backgroundColor: Colors.white,
    border: 'none',
    borderRadius: '50%',
    height: THUMB_WIDTH,
    marginTop: -5,
    width: THUMB_WIDTH,
  },
  track: {
    height: 2,
    backgroundColor: Colors.white30,
  },
};

const sliderStyles = ({ isThumbHidden }: { isThumbHidden: boolean }): TStyleSheet => ({
  '&::-webkit-slider-thumb': {
    ...rangeStyles.thumb,
    opacity: isThumbHidden ? 0 : 1,
    transition: 'opacity 0.3s ease',
  },
  '&::-moz-range-thumb': {
    ...rangeStyles.thumb,
    opacity: isThumbHidden ? 0 : 1,
  },
  '&::-webkit-slider-runnable-track': {
    ...rangeStyles.track,
  },
  '&::-moz-range-track': {
    ...rangeStyles.track,
  },
});

export const PlayerStyles = {
  sliderBackground,
  sliderStyles,
  getTrackBackground,
  THUMB_WIDTH,
};
