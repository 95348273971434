import { PodcastSort, PodcastType } from '@audacy-clients/client-services/core';
import { contentQuery } from '@audacy-clients/core/atoms/content';
import {
  noCacheDefaultSelector,
  noCacheDefaultSelectorFamily,
} from '@audacy-clients/core/atoms/helpers/noCacheDefaultSelector';
import { wrapEpisode, wrapEpisodeList } from '@audacy-clients/core/atoms/wrappers/episode';
import {
  type IClientServicesPagedList,
  type IContentSummary,
  type IEpisode,
  type IShow,
} from '@audacy-clients/core/atoms/wrappers/types';
import clientServices from '@audacy-clients/core/utils/clientServices';
import { useCallback, useEffect, useState } from 'react';
import { atomFamily, type SerializableParam, useRecoilState, useRecoilValueLoadable } from 'recoil';

import { PageSizes } from './helpers/constants';
import { getResumePointForEntity } from './playbackResumePoints';
import { getSchedules } from './schedules';

type IEpisodeRequest = {
  page?: number;
  sort?: PodcastSort;
};

type IEpisodesParams = {
  showContentId: string;
} & IEpisodeRequest;

export const Constants: {
  defaultRequest: IEpisodeRequest;
} = {
  defaultRequest: {
    page: 0,
  },
};

const episodesAtom = atomFamily<Array<IEpisode>, string>({
  default: [],
  key: 'EpisodesItems',
});

export const podcastSortAtom = atomFamily<PodcastSort, string | undefined>({
  default: (param) =>
    param === PodcastType.EPISODIC ? PodcastSort.DATE_DESC : PodcastSort.DATE_ASC,
  key: 'PodcastSort',
});

type IEpisodeResults = {
  hasMoreResults: boolean;
  isLoading: boolean;
  isLoadingMoreEpisodes: boolean;
  loadMoreEpisodes: () => void;
  resultsData: Array<IEpisode>;
};

type IPaginationIndexes = {
  endIndex: number;
  startIndex: number;
};

const getPaginationIndexes = (startIndex: number): IPaginationIndexes => {
  return {
    endIndex: startIndex + (PageSizes.episodesList - 1),
    startIndex,
  };
};

export function useEpisodesResults(contentId: string, sort: PodcastSort): IEpisodeResults {
  const { contents: episodeList, state: episodeListState } = useRecoilValueLoadable<
    IClientServicesPagedList<IEpisode | undefined>
  >(episodesQuery({ showContentId: contentId, sort }));
  const [resultsState, setResultsState] = useRecoilState(episodesAtom(contentId));
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMoreEpisodes, setIsLoadingMoreEpisodes] = useState(false);

  const updateResultState = useCallback(
    ({ startIndex, endIndex }: IPaginationIndexes) => {
      const newResults = [];

      if (episodeListState === 'hasValue') {
        for (let i = startIndex; i <= endIndex; i++) {
          episodeList.get(i) && newResults.push(episodeList.get(i));
        }

        setIsLoading(false);
        setResultsState([...resultsState, ...(newResults as Array<IEpisode>)]);
        const hasMore =
          episodeList && episodeList.getLength ? episodeList.getLength() - 1 > endIndex : false;
        setHasMoreResults(hasMore);
        setIsLoadingMoreEpisodes(false);
      }
    },
    [episodeList, resultsState, setResultsState, episodeListState],
  );

  const loadMoreEpisodes = useCallback(() => {
    if (hasMoreResults && episodeList) {
      setIsLoadingMoreEpisodes(true);
      const { endIndex, startIndex } = getPaginationIndexes(resultsState.length);

      episodeList.prefetch(startIndex, endIndex).then(() => {
        updateResultState({ endIndex, startIndex });
      });
    }
  }, [
    episodeList,
    hasMoreResults,
    resultsState.length,
    updateResultState,
    setIsLoadingMoreEpisodes,
  ]);

  // Set the result state on the first load/render
  useEffect(() => {
    if (episodeList && resultsState.length === 0) {
      updateResultState(getPaginationIndexes(0));
    }
  }, [episodeList, resultsState.length, updateResultState]);

  // We clean the list to avoid duplicating content anytime
  // the user re-opens the same podcast page.
  // TODO: [A2-2329] https://entercomdigitalservices.atlassian.net/browse/A2-2329
  useEffect(() => {
    return () => {
      setResultsState([]);
    };
  }, [setResultsState, sort]);

  return {
    hasMoreResults,
    isLoading,
    isLoadingMoreEpisodes,
    loadMoreEpisodes,
    resultsData: resultsState,
  };
}

export const episodesQuery = noCacheDefaultSelectorFamily<
  ReturnType<typeof wrapEpisodeList>,
  IEpisodesParams & SerializableParam
>({
  get:
    ({ showContentId, sort }) =>
    ({ get }) => {
      const show = get(contentQuery<IShow>(showContentId))?.getDataObject();
      // Using a promise here, instead of async/await, because Recoil triggers an
      // invalid promise rejection if an async selector calls an async selector

      // TODO: [CCS-487] Fix expect-error after Data Services conversion
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return show.getEpisodeList(sort).then((list) => wrapEpisodeList(list));
    },
  key: 'Episodes',
});

export const episodeQuery = noCacheDefaultSelectorFamily({
  get:
    (episodeId: string) =>
    ({ get }) => {
      const episode = get(contentQuery<IEpisode>(episodeId))?.getDataObject();
      if (!episode) {
        return;
      }
      return wrapEpisode(episode);
    },
  key: 'Episode',
});

export const getCurrentEpisodes = noCacheDefaultSelectorFamily({
  get: (id: string) => async () =>
    wrapEpisodeList(await clientServices.getDataServices().getCurrentEpisodes(id)),
  key: 'CurrentEpisodes',
});

export type CurrentEpisode = {
  episode: IEpisode | undefined;
};

const nullCurrentEpisodeResponse = {
  episode: undefined,
};

export const fetchCurrentEpisode = noCacheDefaultSelector({
  get: ({ getCallback }) =>
    getCallback(
      ({ snapshot }) =>
        async (
          stationId: string,
          recents: Array<IContentSummary> = [],
        ): Promise<CurrentEpisode> => {
          if (!stationId) {
            return nullCurrentEpisodeResponse;
          }

          const episodeList = await snapshot.getPromise(getSchedules(stationId));

          if (!episodeList) {
            return nullCurrentEpisodeResponse;
          }

          const endDateTime = Date.now();

          const playbackResumePoints = await clientServices
            .getPersonalizationServices()
            .getPlaybacks();

          // Filter episodes based on startTime and presence of a resume point in one step
          const validEpisodes = episodeList.getAll().filter((ep) => {
            const resumePoint = getResumePointForEntity(playbackResumePoints, ep);
            return ep.startTime <= endDateTime && resumePoint;
          });

          // Create a Map for quick access to episodes by ID
          const validEpisodesMap = new Map(validEpisodes.map((ep) => [ep.episodeId, ep]));

          // Find the first ID in recents that exists in validEpisodesMap, and get the corresponding IEpisode
          const mostRecentValidEpisodeId = recents.find((recent) =>
            validEpisodesMap.has(recent.id),
          )?.id;
          const mostRecentValidEpisode = mostRecentValidEpisodeId
            ? validEpisodesMap.get(mostRecentValidEpisodeId)
            : undefined;

          return { episode: mostRecentValidEpisode };
        },
    ),
  key: 'CurrentEpisode',
});
