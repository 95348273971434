import { motion } from 'framer-motion';

import styles from './styles';

interface IPlaybackSpeedButton {
  playbackSpeed: number;
  onClick: () => void;
  isLeftAligned?: boolean;
}

const PlaybackSpeedButton = ({
  playbackSpeed,
  onClick,
  isLeftAligned,
}: IPlaybackSpeedButton): JSX.Element | null => {
  return (
    <motion.div css={styles.wrapper} whileTap={{ scale: 1.2 }}>
      <button
        type="button"
        onClick={onClick}
        css={[styles.button, isLeftAligned && styles.buttonLeft]}
      >
        {playbackSpeed}x
      </button>
    </motion.div>
  );
};

export default PlaybackSpeedButton;
