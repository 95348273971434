import { Colors, Mixins, mqHover, PlayerStyles } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const volumeVisibleStyles = {
  height: 'auto',
  opacity: 1,
  overflow: 'visible',
  padding: '12px 8px 12px 0',
  width: 'auto',
};

const styles: TStyleSheet = {
  root: {
    padding: 6,
    position: 'relative',
    width: 'fit-content',
    '&:is(:focus-within)': {
      backgroundColor: Colors.audacyDarkNavy,
      borderRadius: '0 0 8px 8px',
      '& > div': {
        ...volumeVisibleStyles,
      },
    },
    '&:only-child': {
      marginLeft: 'auto',
    },
  },
  rootSliderVisible: {
    backgroundColor: Colors.audacyDarkNavy,
    borderRadius: '0 0 8px 8px',
  },
  fullPlayer: {
    border: `1px solid ${Colors.white30}`,
    borderRadius: '50%',
    padding: 0,
    margin: '0 6px',
    '&:is(:hover, :focus-within)': {
      borderRadius: '0 0 10px 10px',
      borderTopColor: 'transparent',
      padding: 6,
      margin: 0,
    },
    '& > div': {
      border: `1px solid ${Colors.white30}`,
      borderLeft: 'none',
      borderRadius: '0 10px 10px 0',
    },
    '&:only-child': {
      marginLeft: 'auto',
    },
  },
  volumeButton: {
    ...Mixins.accessibleMinButtonSize(),
    borderRadius: '50%',
    color: Colors.white,
    padding: 6,
    ...mqHover({
      color: Colors.white60,
    }),
    '& svg': {
      width: 14,
      height: 14,
    },
  },
  miniPlayerVolumeButton: {
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  lowVolume: {
    '& svg > path:nth-last-of-type(-n + 2)': {
      color: Colors.white60,
    },
  },
  midVolume: {
    '& svg > path:nth-last-of-type(-n + 1)': {
      color: Colors.white60,
    },
  },
  volumeSliderWrapper: {
    alignItems: 'center',
    backgroundColor: Colors.audacyDarkNavy,
    borderRadius: '0 8px 8px 0',
    display: 'flex',
    height: 0,
    left: '50%',
    opacity: 0,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: -20,
    transform: 'rotate(270deg)',
    transformOrigin: 'left center',
    width: 0,
  },
  volumeSliderVisible: {
    ...volumeVisibleStyles,
  },
  volumeSlider: {
    appearance: 'none',
    background: 'transparent',
    borderRadius: 10,
    cursor: 'pointer',
    height: 20,
    padding: '8px 6px',
    width: 72,
    ...PlayerStyles.sliderStyles({ isThumbHidden: false }),
  },
};

export default styles;
