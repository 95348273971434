import { useModuleContext } from '@audacy-clients/core/components/ModuleRenderer/context';

import { IBaseLinkProps } from '~/components/BaseLink';
import Link from '~/components/Link';
import { TitleTag, TTitleTag } from '~/components/Title';
import { Mixins } from '~/styles';
import { TStyles } from '~/types/emotion-styles';
import { appendRightArrow } from '~/utils/strings';

import styles from './styles';

interface ISectionWrapperProps extends IBaseLinkProps {
  children?: React.ReactNode;
  containerCss?: TStyles;
  content?: React.ReactNode;
  contentCss?: TStyles;
  footerContent?: React.ReactNode;
  isLast?: boolean;
  isOverflowHidden?: boolean;
  headerContent?: React.ReactNode;
  subtitle?: string;
  subtitleAs?: TTitleTag;
  title?: string;
  titleAs?: TTitleTag;
  titleCta?: React.ReactNode;
}

const SectionWrapper = ({
  children,
  containerCss,
  content,
  contentCss,
  footerContent,
  href,
  isLast,
  isOverflowHidden,
  headerContent,
  subtitle,
  subtitleAs = 'h3',
  title,
  titleAs = 'h2',
  titleCta,
  ...rest
}: ISectionWrapperProps): JSX.Element => {
  const { isSideColumn } = useModuleContext();
  const SubtitleTag = subtitleAs;

  return (
    <section
      css={[
        Mixins.horizontalModulePadding(isSideColumn),
        styles.container,
        isOverflowHidden && styles.overflowHidden,
        isLast && styles.lastSection,
        containerCss,
      ]}
    >
      {(title || titleCta) && (
        <div css={[styles.titleContainer, subtitle && styles.hasSubtitle]}>
          {title && (
            <TitleTag as={titleAs} titleCss={styles.title}>
              {href ? (
                <Link
                  {...rest}
                  href={href}
                  label={appendRightArrow(title)}
                  labelCss={styles.title}
                />
              ) : (
                title
              )}
            </TitleTag>
          )}
          {titleCta}
        </div>
      )}
      {subtitle && <SubtitleTag css={styles.subtitle}>{subtitle}</SubtitleTag>}
      {headerContent && <header css={styles.header}>{headerContent}</header>}
      {(children || content) && <div css={contentCss}>{children || content}</div>}
      {footerContent && <footer css={styles.footer}>{footerContent}</footer>}
    </section>
  );
};

export default SectionWrapper;
