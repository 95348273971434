export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Placeholder</title>
    <path stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" d="M5 5h22v22H5z" />
    <path
      d="m12.464 12.464 7.072 7.071m-7.072 0 7.072-7.07"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);
