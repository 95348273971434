export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Apple logo</title>
    <path
      d="M25.904 21.141c-.515 1.145-.764 1.657-1.427 2.668-.929 1.412-2.238 3.176-3.856 3.187-1.44.013-1.812-.94-3.768-.923-1.954.01-2.362.942-3.804.927-1.62-.015-2.858-1.603-3.787-3.014-2.597-3.954-2.871-8.591-1.266-11.057 1.135-1.749 2.93-2.777 4.618-2.777 1.718 0 2.798.944 4.218.944 1.379 0 2.218-.945 4.206-.945 1.502 0 3.09.818 4.225 2.23-3.714 2.037-3.11 7.34.641 8.76ZM19.528 8.572C20.251 7.643 20.8 6.334 20.6 5c-1.178.08-2.557.833-3.362 1.808-.73.89-1.336 2.209-1.1 3.485 1.286.04 2.618-.727 3.39-1.721Z"
      fill="currentColor"
    />
  </svg>
);
