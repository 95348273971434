import { EntityType } from '@audacy-clients/client-services/core';
import Collection from '@audacy-clients/client-services/src/dataServices/Collection';
import DataObject from '@audacy-clients/client-services/src/dataServices/DataObject';
import { ContentSummary } from '@audacy-clients/client-services/src/dataServices/DataServices';
import Episode from '@audacy-clients/client-services/src/dataServices/Episode';
import Show from '@audacy-clients/client-services/src/dataServices/Show';
import StandaloneChapter from '@audacy-clients/client-services/src/dataServices/StandaloneChapter';
import Station from '@audacy-clients/client-services/src/dataServices/Station';
import { getNullSafeDate } from '@audacy-clients/core/utils/date';

import { wrapDataObject } from '.';
import { wrapCollection } from './collection';
import { wrapEpisode } from './episode';
import { wrapShow } from './show';
import { wrapStandaloneChapter } from './standaloneChapter';
import { wrapStation } from './station';
import { IContentSummary, TWrappedContentObject } from './types';

export const wrapContentObject = (content?: DataObject): TWrappedContentObject | undefined => {
  if (!content) {
    return undefined;
  }

  const entityType = content.getEntityType();

  switch (entityType) {
    case EntityType.STATION:
      return wrapStation(content as Station);
    case EntityType.EPISODE:
      return wrapEpisode(content as Episode);
    case EntityType.SHOW:
      return wrapShow(content as Show);
    case EntityType.COLLECTION:
      return wrapCollection(content as Collection);
    case EntityType.STANDALONE_CHAPTER: {
      return wrapStandaloneChapter(content as StandaloneChapter);
    }
    default:
      return wrapDataObject(content);
  }
};

export const wrapContentSummary = (contentSummary: ContentSummary): IContentSummary => {
  const base = {
    duration: contentSummary.durationSeconds,
    entitySubtype: contentSummary.entitySubtype,
    entityType: contentSummary.entityType,
    id: contentSummary.id,
    image: contentSummary.images?.square ?? contentSummary.parentImage?.square,
    parentImage: contentSummary.parentImage?.square,
    parentTitle: contentSummary.parentTitle,
    marketTitle: contentSummary.marketTitle,
    genres: contentSummary.genres,
    publishDate: getNullSafeDate(contentSummary.publishDate),
    author: contentSummary.author,
    showContentId: contentSummary.showContentId,
    startDateTime: getNullSafeDate(contentSummary.startDateTime),
    replayableUntilDateTime: contentSummary.replayableUntilDateTime,
    title: contentSummary.title,
    url: contentSummary.url,
    itemsIdList: contentSummary.itemsIdList,
    updateDate: getNullSafeDate(contentSummary.updateDate),
  };

  switch (contentSummary.entityType) {
    case EntityType.STANDALONE_CHAPTER: {
      const parentEpisode = contentSummary.parentEpisode;
      const parentShow = contentSummary.parentShow;

      return {
        ...base,
        episodeUrl: parentEpisode?.url,
        showUrl: parentShow?.url,
        showContentId: parentShow?.id,
        episodeContentId: parentEpisode?.id,
      };
    }
    case EntityType.STATION: {
      const isRewindable = contentSummary.isRewindable;

      return {
        ...base,
        isRewindable,
      };
    }
    default:
      return base;
  }
};
