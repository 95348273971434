import React, { memo } from 'react';

import { SourceStringsMap, clientServicesConfig } from '@audacy-clients/client-services';
import { ModuleProvider } from '@audacy-clients/core/components/ModuleRenderer/context';
import { IModuleViewProps } from '@audacy-clients/core/components/ModuleRenderer/types';
import { useMergedContext } from '@audacy-clients/core/components/ModuleRenderer/utils';

const mapSubmodules = <T,>({
  module: { modules },
  moduleMap,
  getData,
  moduleWrapper,
  navigation,
  onNavigate,
  viewId,
}: IModuleViewProps<T>): JSX.Element[] | undefined => {
  return modules?.map((submodule, idx) => (
    <ModuleView
      getData={getData}
      index={idx}
      key={idx}
      module={submodule}
      moduleMap={moduleMap}
      moduleWrapper={moduleWrapper}
      navigation={navigation}
      viewId={viewId}
      onNavigate={onNavigate}
    />
  ));
};

const ModuleView = <T,>(moduleProps: IModuleViewProps<T>): JSX.Element => {
  const { getData, isTopLevel, module, moduleMap, moduleWrapper, viewId } = moduleProps;
  const ModuleWrapper = moduleWrapper;
  const ModuleComponent = moduleMap.get(module.type);
  const { logger, analyticServices } = clientServicesConfig;
  const platformName = SourceStringsMap[analyticServices.platform];
  if (!ModuleComponent) {
    logger.warn(
      'Unsupported module of type ' +
        module.type +
        ' encountered on ' +
        platformName +
        ' client on view ' +
        viewId,
    );
  }

  const mergedContext = useMergedContext(moduleProps);
  const submodules = mapSubmodules(moduleProps);
  const data = getData(module.config?.contentId);

  return (
    <ModuleWrapper isTopLevel={isTopLevel} module={module}>
      <ModuleProvider key={module.uniqueId} value={mergedContext}>
        {ModuleComponent ? (
          <ModuleComponent
            {...moduleProps}
            data={data}
            // Pass ModuleView/moduleMap to avoid a circular dependency
            moduleMap={moduleMap}
            moduleViewComponent={ModuleView}
          >
            {submodules}
          </ModuleComponent>
        ) : (
          <React.Fragment />
        )}
      </ModuleProvider>
    </ModuleWrapper>
  );
};

export default memo(ModuleView);
