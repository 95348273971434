export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Lock</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 4.25A6.75 6.75 0 0 0 9.25 11v1.25h-2v14.5h17.5v-14.5h-2V11A6.75 6.75 0 0 0 16 4.25Zm5.25 8V11a5.25 5.25 0 1 0-10.5 0v1.25h10.5Zm-12 1.5h-.5v11.5h14.5v-11.5h-14Z"
      fill="currentColor"
    />
    <path stroke="currentColor" strokeWidth="1.5" d="M16.05 17v5" />
  </svg>
);
