import PubNub from 'pubnub';
import Container from '../Container';

const DEFAULT_MAX_EVENTS = 50;

const serviceBus = Container;
const logger = Container;

/**
 * @module ChatSession
 */
export default class ChatSession {
  /**
   * @param {String} contentId
   * @param {String} sessionId
   * @param {Function} [eventListener]
   * @param {String} [userDisplayName=undefined]
   * @param {String} [userProfilePictureUrl=undefined]
   * @returns {ChatSession}
   */
  constructor(contentId, sessionId, eventListener, userDisplayName, userProfilePictureUrl) {
    this.contentId = contentId;
    this.sessionId = sessionId;
    this.eventListener = eventListener;
    this.userDisplayName = userDisplayName;
    this.userProfilePictureUrl = userProfilePictureUrl;
  }

  /**
   * Delete a chat message
   * @param {String} messageId
   * @returns {Promise}
   */
  deleteMessage(messageId) {
    return serviceBus.dataServices.deleteChatMessage(this.sessionId, messageId);
  }

  /**
   * Edit a chat message
   * @param {String} messageId
   * @param {String} text
   */
  editMessage(messageId, text) {
    return serviceBus.dataServices.editChatMessage(this.sessionId, messageId, text);
  }

  /**
   * Unsubscribe from the current pubnub channel
   */
  end() {
    const channelId = 'chat.' + this.sessionId;

    try {
      this.pubnub.unsubscribe({ channels: [channelId] });
      this.sessionId = undefined;
    } catch (e) {
      logger.error('Unable to end pubnub session');
      throw e;
    }
  }

  /**
   * @typedef {Object} QuickReactionRollupEntry
   * @prototype {Number} streamOffsetMs
   * @prototype {Number} reactionIdent
   * @prototype {Number} reactionCount
   */

  /**
   * Get quick reactions associated with this content starting from a given offset
   * @param {Number}  [offset=Date.now()]
   * @param {Number}  [page=0]
   * @param {Number}  [maxEvents=DEFAULT_MAX_EVENTS]
   * @param {Boolean} [fetchBackward=true]
   * @returns {Promise<Array<QuickReactionRollupEntry>>}
   */
  getQuickReactionRollups(
    offset = Date.now(),
    page = 0,
    maxEvents = DEFAULT_MAX_EVENTS,
    fetchBackward = true,
  ) {
    return serviceBus.dataServices
      .getChatQuickReactionRollups(this.sessionId, offset, page, maxEvents, fetchBackward)
      .then((response) => response.events);
  }

  /**
   * @typedef {Object} QuickReactionsSummaryEntry
   * @property {Number} reactionId
   * @property {Number} reactionCount
   */

  /**
   * Get summary of quick reactions associated with this content
   * @returns {Promise<Array<QuickReactionsSummaryEntry>>}
   */
  getQuickReactionsSummary() {
    return serviceBus.dataServices
      .getChatQuickReactionsSummary(this.sessionId)
      .then((response) => response.quickReactionCounts);
  }

  /**
   * @typedef {Object} TextEventEntry
   * @prototype {String}  id
   * @prototype {Boolean} isAuthor
   * @prototype {Boolean} isEdited
   * @prototype {Number}  likeCount
   * @prototype {Boolean} liked
   * @prototype {Number}  streamOffsetMs
   * @prototype {String}  text
   * @prototype {String}  userAvatarUrl
   * @prototype {String}  userDisplayName
   */

  /**
   * Get messages associated with this content starting from a given offset
   * @param {Number}  [offset=Date.now()]
   * @param {Number}  [page=0]
   * @param {Number}  [maxEvents=DEFAULT_MAX_EVENTS]
   * @param {Boolean} [fetchBackward=true]
   * @returns {Promise<Array<TextEventEntry>>}
   */
  getTextEvents(
    offset = Date.now(),
    page = 0,
    maxEvents = DEFAULT_MAX_EVENTS,
    fetchBackward = true,
  ) {
    return serviceBus.dataServices
      .getChatTextEvents(this.sessionId, offset, page, maxEvents, fetchBackward)
      .then((response) => response.events);
  }

  /**
   * Returns whether the chat session is still active.
   * @returns {Boolean}
   */
  isActive() {
    return this.sessionId !== undefined;
  }

  /**
   * Report a chat message for moderation
   * @param {String} messageId
   * @param {String} reportedReason
   * @returns {Promise}
   */
  reportMessage(messageId, reportedReason) {
    return serviceBus.dataServices.reportChatMessage(messageId, reportedReason);
  }

  /**
   * Post a text message
   * @param {String}  text
   * @param {Number} [offset=Date.now()]
   * @returns {Promise}
   */
  sendMessage(text, offset = Date.now()) {
    serviceBus.analyticServices.sendChatCommentPost(this.contentId);
    return serviceBus.dataServices.sendChatMessage(
      this.sessionId,
      text,
      offset,
      this.userDisplayName,
      this.userProfilePictureUrl,
    );
  }

  /**
   * Post a reaction to a specific message
   * @param {String}  targetMessageId
   * @param {String}  action
   * @param {Number} [offset=Date.now()]
   * @returns {Promise}
   */
  sendMessageReaction(targetMessageId, action, offset) {
    serviceBus.analyticServices.sendChatCommentLike(this.contentId);
    return serviceBus.dataServices.sendChatMessageReaction(
      this.sessionId,
      targetMessageId,
      action,
      offset,
      this.userDisplayName,
      this.userProfilePictureUrl,
    );
  }

  /**
   * Post a quick reaction
   * @param {Number}  reactionId
   * @param {Number} [offset=Date.now()]
   */
  sendQuickReaction(reactionId, offset = Date.now()) {
    serviceBus.analyticServices.sendChatReaction(this.contentId);
    return serviceBus.dataServices.sendChatQuickReaction(
      this.sessionId,
      reactionId,
      offset,
      this.userDisplayName,
      this.userProfilePictureUrl,
    );
  }

  setScreenName(displayName) {
    this.userDisplayName = displayName;
  }

  /**
   * @typedef {Object} QuickReactionEntry
   * @prototype {Number} id
   * @prototype {String} display
   */

  /**
   * @typedef {Object} ChatConfig
   * @prototype {String}                    authToken
   * @prototype {Array<String>}             chatChannels
   * @prototype {String}                    pubKey
   * @prototype {Array<QuickReactionEntry>} quickReactions
   * @prototype {String}                    subKey
   * @prototype {String}                    uuid
   */

  /**
   * @returns {Promise<ChatConfig>}
   */
  start() {
    const createSession = () => {
      return serviceBus.dataServices
        .getChatConfig(this.sessionId)
        .then((chatConfig) => {
          if (chatConfig.code === 404) throw chatConfig.auxData.errorResponse;

          if (!this.pubnub) {
            this.pubnub = new PubNub({
              restore: true,
              heartbeatInterval: 0,
              publishKey: chatConfig.pubKey,
              subscribeKey: chatConfig.subKey,
              uuid: chatConfig.uuid,
              authKey: chatConfig.authToken,
            });

            this.pubnub.addListener({
              file: (data) =>
                this.eventListener && this.eventListener({ type: 'file', data: data }),
              message: (data) =>
                this.eventListener && this.eventListener({ type: 'message', data: data }),
              messageAction: (data) =>
                this.eventListener && this.eventListener({ type: 'messageAction', data: data }),
              objects: (data) =>
                this.eventListener && this.eventListener({ type: 'objects', data: data }),
              presence: (data) =>
                this.eventListener && this.eventListener({ type: 'presence', data: data }),
              signal: (data) =>
                this.eventListener && this.eventListener({ type: 'signal', data: data }),
              status: (data) =>
                this.eventListener && this.eventListener({ type: 'status', data: data }),
            });
          }

          this.pubnub.subscribe({ channels: chatConfig.chatChannels });

          return chatConfig;
        })
        .catch((error) => {
          this.eventListener && this.eventListener({ type: 'error', error });
        });
    };

    return createSession();
  }
}
