import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';

import i18n from '~/i18n';

export const formatPublishDate = (date: Date): string => {
  if (isToday(date)) {
    return i18n.t('global.today');
  }

  if (isYesterday(date)) {
    return i18n.t('global.yesterday');
  }

  return format(date, 'MMM d, yyyy');
};
