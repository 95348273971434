type DeviceInfo = Partial<{
  platform: 'ios' | 'android' | 'web';
}>;


class AudacyDeviceInfo {
  info: DeviceInfo = {};

  setDeviceInfo(newDeviceInfo: DeviceInfo) {
    for (const key in newDeviceInfo) {
        this.info[key as keyof DeviceInfo] = newDeviceInfo[key as keyof DeviceInfo];
    }
  }

  getDeviceInfo(): DeviceInfo {
    return this.info;
  }

}
export default new AudacyDeviceInfo();
