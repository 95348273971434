import { BP, Colors, mq, Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    textAlign: 'center',
    margin: '0 auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 350,
    minHeight: 'inherit',
    [mq(BP.Medium)]: {
      maxWidth: 375,
    },
    [mq(BP.Large)]: {
      maxWidth: 375,
    },
  },
  title: {
    ...Typography.metadataLarge,
    marginBottom: 10,
  },
  subtitle: {
    ...Typography.metadataLarge,
    color: Colors.white60,
    marginBottom: 30,
    [mq(BP.Medium)]: {
      marginBottom: 40,
    },
    [mq(BP.Large)]: {
      marginBottom: 30,
    },
  },
};

export default styles;
