import { clientServicesConfig, AuthState, LocalStorageKey } from '@audacy-clients/client-services';
import { Environment, Platform, PlayerAction } from '@audacy-clients/client-services/core';
import { type IAnalyticsEvent } from '@audacy-clients/client-services/src/analyticServices';
import AudacyLogger from '@audacy-clients/client-services/src/AudacyLogger';
import { type IPlayer } from '@audacy-clients/client-services/src/audioServices/players/types';
import { type IddLogger, type ILogger } from '@audacy-clients/client-services/src/logger';
import {
  AdTargetingStatusParams,
  AdTargetingStatusResponse,
} from '@audacy-clients/client-services/src/personalizationServices/types';
import { createUuid, sha256 } from '@audacy-clients/client-services/src/Utilities';
import { featureFlagMap } from '@audacy-clients/client-services/src/utils';
import { HtmlDataStore, HtmlPlayer, NativeHlsPlayer } from '@audacy-clients/client-services/web';
import { adTargetingState } from '@audacy-clients/core/atoms/adTargeting';
import { type IFeatureFlags } from '@audacy-clients/core/atoms/config/settings';
import {
  getBrazeFeatureFlags,
  initializeBrazeFeatureFlagsService,
  sendEventsToBraze,
  sharedBrazeFeatureFlagsService,
} from '@audacy-clients/core/utils/braze';
import clientServices, {
  getClientServices,
  type IAppInfo,
  type IClientServicesConfig,
  initializeClientServices,
  type IPlatformSpecificConfig,
  type TSafeClientServices,
} from '@audacy-clients/core/utils/clientServices';
import { setRecoil } from '@audacy-clients/core/utils/recoil';
import * as braze from '@braze/web-sdk';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { Logger } from 'tslog';
import { sendEventsToBranch } from '~/utils/branch';
import { addBrazeEventListener, disableBraze } from '~/utils/braze';
import { isMobileIOSDevice } from '~/utils/browser';
import { sendEventsToDataDog } from '~/utils/datadog';
import { env } from '~/utils/env';
import { sendEventsToFacebook } from '~/utils/facebook';
import { setGoogleAdPreferences } from '~/utils/googleAds';
import { dataDogLoggerTransport } from '../utils/datadog';
import { type AuthWithDeviceId, type BrazeOptions } from './types';

let hasInit = false;
const isSafariMobile = isMobileIOSDevice();

const appInfo: IAppInfo = {
  appBuildNumber: '',
  appName: 'Audacy',
  appVersion: env.APP_VERSION ?? '',
};

const config: IClientServicesConfig = {
  env: env.API_ENV ? Environment[env.API_ENV] : Environment.PROD,
  platform: Platform.WEB,
  webcastMetricsStr: 'DIST=CBS&TGT=radiocomDev&SRS=CBS',
};

// used by browsers and extensions that send a GPC signal https://globalprivacycontrol.github.io/gpc-spec/
type IGPCNavigator = {
  globalPrivacyControl?: boolean;
} & Navigator;

const minLevel = Number(env.LOG_LEVEL) || 4;
const rootLogger = new Logger({ minLevel, hideLogPositionForProduction: true });

const loggerFrom = (l: typeof rootLogger): ILogger => ({
  debug: l.debug.bind(l),
  error: l.error.bind(l),
  info: l.info.bind(l),
  trace: l.trace.bind(l),
  warn: l.warn.bind(l),
  log: l.info.bind(l),
  getSubLogger(params) {
    return loggerFrom(l.getSubLogger(params));
  },
});

const logger = loggerFrom(rootLogger);

let ddLogger: IddLogger;

const getAudioPlayer = (featureFlags: IFeatureFlags): IPlayer => {
  let audioPlayer: IPlayer;

  // injecting different players based on whether the browser supports Media Source Extensions or native playback will give
  // us great ability to debug errors between platforms and develop platform specifici solutions without affecting each other.
  // At the moment this is just iphone Safari which does not support Media Source Extensions, and all other supported browsers.
  if (isSafariMobile) {
    audioPlayer = new NativeHlsPlayer({
      logger: logger.getSubLogger({ name: 'NativeHlsPlayer ' }),
      featureFlags,
    });
  } else {
    audioPlayer = new HtmlPlayer({
      logger: logger.getSubLogger({ name: 'HtmlPlayer ' }),
      featureFlags,
    });
  }

  return audioPlayer;
};

const sendErrorToDataDog = (message: string) => datadogRum.addError(message);

const getPlatformSpecific = (featureFlags: IFeatureFlags): IPlatformSpecificConfig => ({
  analyticsEventListener: (events: Array<IAnalyticsEvent>): void => {
    // filter out CONTINUE_LISTEN in favor of CONTINUE_LISTEN_10
    const filteredEvents = events.filter((event) => event.type !== PlayerAction.CONTINUE_LISTEN);

    if (!disableBraze) {
      try {
        sendEventsToBraze(braze.logCustomEvent, filteredEvents, appInfo);
      } catch (_e) {
        // catch error in case of broken braze campaign/data
      }
    }

    sendEventsToBranch(filteredEvents);
    sendEventsToFacebook(filteredEvents);
    sendEventsToDataDog(filteredEvents);
  },
  dataStore:
    typeof document !== 'undefined'
      ? new HtmlDataStore()
      : {
          clearData: async (): Promise<void> => {
            // empty
          },
          getData: async (): Promise<undefined> => undefined,
          getDataSync: (): undefined => undefined,
          setData: async (): Promise<void> => {
            // empty
          },
          setDataSync: (): void => {
            // empty
          },
        },
  featureFlags,
  player: getAudioPlayer(featureFlags),
  logger,
  ddError: sendErrorToDataDog,
  ddLogger,
  callBrazeEvent: braze.logCustomEvent.bind(braze),
});

export async function initClientServices(): Promise<TSafeClientServices> {
  if (hasInit) {
    return getClientServices();
  }
  hasInit = true;

  featureFlagMap.set(featureFlagMap.brazeFlags, await getBrazeFeatureFlags());
  const flags = featureFlagMap.getAll();

  if (!disableBraze) {
    if (flags.verboseDataDogLogging) {
      ddLogger = bindDataDogLogger();
    }
  }

  // add Audacy Logger Transport here
  AudacyLogger.addTransport(dataDogLoggerTransport);

  initializeClientServices(appInfo, config, getPlatformSpecific(flags));
  return getClientServices();
}

const getAuthStateFromDataStore = (): AuthWithDeviceId => {
  const dataStore = new HtmlDataStore();
  const refreshToken = dataStore.getDataSync<string>(LocalStorageKey.REFRESH_TOKEN);
  const userToken = dataStore.getDataSync<string>(LocalStorageKey.USER_TOKEN);
  const deviceId = dataStore.getDataSync<string>(LocalStorageKey.BRAZE_DEVICE_ID);

  if (refreshToken && userToken) {
    return {
      state: AuthState.AUTH,
      deviceId: deviceId,
    };
  } else if (userToken) {
    return {
      state: AuthState.ANON,
      deviceId: userToken,
    };
  } else {
    const f8kValue = `f8k:${sha256(createUuid()).substring(3, 35)}`;
    dataStore.setData(LocalStorageKey.BRAZE_DEVICE_ID, f8kValue);
    return {
      state: AuthState.NONE,
      deviceId: f8kValue,
    };
  }
};

export const initBraze = (): void => {
  if (disableBraze) {
    console.warn(
      `Braze disabled, if this was a mistake remove the DISABLE_BRAZE var from your config.
      env.DISABLE_BRAZE: ${env.DISABLE_BRAZE}`,
    );
    return;
  }

  const options: BrazeOptions = {};
  const { deviceId } = getAuthStateFromDataStore();
  options.deviceId = deviceId;
  braze.initialize(env.BRAZE_API_KEY, {
    baseUrl: env.BRAZE_SDK_ENDPOINT,
    allowUserSuppliedJavascript: true,
    minimumIntervalBetweenTriggerActionsInSeconds: 1,
    ...options,
  });
  braze.automaticallyShowInAppMessages();
  braze.openSession();
  if (deviceId) {
    braze.getUser()?.addAlias('USER_ALIAS', deviceId);
  }

  // listens for events sent from braze iframes
  addBrazeEventListener();

  initializeBrazeFeatureFlagsService(braze);

  // Set the max unauthenticated playback duration in local storage if the 'meteredContent' feature flag is active.
  sharedBrazeFeatureFlagsService
    .getFeatureFlag('meteredContent')
    .then((meteredContentBrazeFeatureFlag) => {
      getClientServices().then((cs) => {
        if (
          meteredContentBrazeFeatureFlag?.properties?.active &&
          meteredContentBrazeFeatureFlag.properties?.duration?.value !== undefined
        ) {
          cs.getPersonalizationServices().dataStore.setDataSync(
            LocalStorageKey.UNAUTHENTICATED_PLAYBACK_MAX_DURATION,
            meteredContentBrazeFeatureFlag.properties.duration.value,
          );
        } else {
          cs.getPersonalizationServices().dataStore.clearData(
            LocalStorageKey.UNAUTHENTICATED_PLAYBACK_MAX_DURATION,
          );
        }
      });
    });
};

function bindDataDogLogger(): IddLogger {
  datadogLogs.setGlobalContext({ env: env.API_ENV });

  const ddLoggerFrom = (l: typeof datadogLogs): IddLogger => ({
    debug: l.logger.debug.bind(l.logger),
    error: l.logger.error.bind(l.logger),
    info: l.logger.info.bind(l.logger),
    warn: l.logger.warn.bind(l.logger),
  });
  return ddLoggerFrom(datadogLogs);

  // Usage Notes: ddLogger.debug | info | warn | error (message: string, messageContext?: Context, error?: Error)
  // More info about the above methods: https://docs.datadoghq.com/logs/log_collection/javascript/
}

// gets ad preferences for authenticated users
export const syncAdPreferences = async (): Promise<void> => {
  const response = await clientServices.getPersonalizationServices().getAdTargetingStatus();

  if (response?.adTargeting === AdTargetingStatusResponse.INELIGIBLE) {
    clientServicesConfig.disableTargetedAds = true;
    setRecoil(adTargetingState, AdTargetingStatusResponse.INELIGIBLE);
    // Google privacy settings for INELIGIBLE should be same as OPT_OUT
    setGoogleAdPreferences(AdTargetingStatusParams.OPT_OUT);
  } else if (
    response?.adTargeting === AdTargetingStatusResponse.OPT_OUT ||
    (navigator as IGPCNavigator).globalPrivacyControl // globalPrivacyControl setting comes from a browser or plugin
  ) {
    clientServicesConfig.disableTargetedAds = true;
    setRecoil(adTargetingState, AdTargetingStatusResponse.OPT_OUT);
    setGoogleAdPreferences(AdTargetingStatusParams.OPT_OUT);
  } else {
    clientServicesConfig.disableTargetedAds = false;
    setRecoil(adTargetingState, AdTargetingStatusResponse.OPT_IN);
    setGoogleAdPreferences(AdTargetingStatusParams.OPT_IN);
  }
};

// reads adTargeting property on OneTrust cookie and updates CS Config
export const readOneTrustCookieAndUpdateAdPreferences = (): void => {
  const allCookies = document.cookie;

  // this is the name of the ad preference property on OneTrust's OptanonConsent cookie
  // https://developer.onetrust.com/onetrust/docs/onetrust-cookies#optanonconsent
  const adTargetingProperty = 'C0004%3A';

  // finds associated value with adTargeting property on OT cookie
  const adTargetingValue = Number(
    allCookies[allCookies.indexOf(adTargetingProperty) + adTargetingProperty.length],
  );

  // updates CS Config with adTargeting value
  if (adTargetingValue === 0 || (navigator as IGPCNavigator).globalPrivacyControl) {
    clientServicesConfig.disableTargetedAds = true;
    setGoogleAdPreferences(AdTargetingStatusParams.OPT_OUT);
  } else {
    clientServicesConfig.disableTargetedAds = false;
    setGoogleAdPreferences(AdTargetingStatusParams.OPT_IN);
  }
};

// adds OneTrust scripts to document head
export const addOneTrustScripts = (): void => {
  // get the document head
  const head = document.getElementsByTagName('head')[0];

  // create the script elements for OneTrust
  const sc1 = document.createElement('script');
  sc1.setAttribute('id', 'sc1');
  sc1.setAttribute('type', 'text/javascript');
  sc1.setAttribute(
    'src',
    'https://cdn.cookielaw.org/consent/d23e8eac-7fda-41b2-a8fd-a00900db6c40/OtAutoBlock.js',
  );
  const sc2 = document.createElement('script');
  sc2.setAttribute('id', 'sc2');
  sc2.setAttribute('type', 'text/javascript');
  sc2.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
  sc2.setAttribute('data-domain-script', 'd23e8eac-7fda-41b2-a8fd-a00900db6c40');
  const sc3 = document.createElement('script');
  sc3.setAttribute('id', 'sc3');
  sc3.setAttribute('type', 'text/javascript');
  sc3.innerHTML = 'function OptanonWrapper() {}';

  // insert scripts at top of document head
  head.insertBefore(sc3, head.firstChild);
  head.insertBefore(sc2, head.firstChild);
  head.insertBefore(sc1, head.firstChild);
};

export const initOneTrust = async (): Promise<void> => {
  const authState = clientServices.getPersonalizationServices().getAuthState();
  const isAuthenticated = authState === AuthState.AUTH;

  // ad preferences of authenticated users is handled by identity
  if (isAuthenticated) {
    syncAdPreferences();
    return;
  }

  // initial read of ad preferences from OneTrust cookie
  readOneTrustCookieAndUpdateAdPreferences();

  // adds OneTrust scripts to document head
  addOneTrustScripts();
};
