import { AudacyColors } from '@audacy-clients/core/styles/colors';

import { BP, Colors, Mixins, mq, mqHover, Typography, ZIndex } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  titleSubtitleContainer: {
    //usually 22px, however Duration has a top margin of 10px
    marginBottom: 12,
  },
  playStateButton: {
    zIndex: ZIndex.siblingOverlay,
  },
  eyebrow: {
    ...Typography.metadataSmall,
    color: AudacyColors.secondaryCopy,
    marginBottom: 5,
  },
  linkContainer: {
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      zIndex: 1,
      ...Mixins.transition(['background-color']),
    },
    ...mqHover({
      [`&::after`]: {
        backgroundColor: Colors.white07,
      },
    }),
  },
  //title defaults to 30px fontsize
  title: {
    ...Mixins.truncateText(3),
    ...Typography.titleMisc,
    fontSize: 30,
    fontWeight: 800,
    [mq(BP.Medium)]: {
      fontSize: 30,
    },
    [mq(BP.Large)]: {
      fontSize: 38,
    },
    [mq(BP.ExtraLarge)]: {
      fontSize: 38,
    },
    [mq(BP.XXLarge)]: {
      fontSize: 48,
    },
  },
  subtitle: {
    ...Mixins.truncateText(3),
    ...Typography.metadataLarge,
    color: AudacyColors.secondaryCopy,
    marginTop: 8,
  },
  linkText: {
    ...Typography.metadataSmall,
    ...mqHover({
      color: Colors.white60,
    }),
    ...Mixins.transition('color'),
    fontWeight: 700,
    marginTop: 10,
  },
};

export default styles;
