import {
  ModuleType,
  EntityType,
  ShowSubtype,
  type EntitySubtype,
  EpisodeSubType,
} from '@audacy-clients/client-services/core';
import { ViewIDKey } from '@audacy-clients/core/atoms/config/constants';
import { type IModule, type TShadowResolver } from '@audacy-clients/core/atoms/wrappers/modules';
import {
  type IModuleContext,
  type IModuleContextCarouselData,
  type IModuleContextListData,
  type IModuleViewProps,
} from '@audacy-clients/core/components/ModuleRenderer/types';
import { cloneDeep, merge, uniq } from 'lodash';
import { useMemo } from 'react';

import { useModuleContext } from './context';

const Constants = {
  defaultItemsCount: 1,
};

const getCarouselContext = (
  module: IModule,
  getData: TShadowResolver,
): IModuleContextCarouselData | undefined => {
  if (module.type !== ModuleType.CAROUSEL) {
    return undefined;
  }

  const firstSubmodule = module.modules?.[0];
  const { config } = module;

  const cardContentIds =
    module.modules?.map((m) => m.config.contentId).filter((id): id is string => !!id) || [];

  const eyebrowLabel = (
    eyebrowEntityType: EntityType | undefined,
    eyebrowEntitySubtype: EntitySubtype | undefined,
  ): string => {
    switch (eyebrowEntityType) {
      case EntityType.STATION:
        return EntityType.STATION;
      case EntityType.SHOW:
        switch (eyebrowEntitySubtype) {
          case ShowSubtype.PODCAST:
            return ShowSubtype.PODCAST;
          default:
            return EpisodeSubType.BROADCAST_SHOW_EPISODE;
        }
      case EntityType.TAG:
        return EntityType.TAG;
      case EntityType.TOPIC:
        return EntityType.TOPIC;
      default:
        return '';
    }
  };

  const typeMap = module.modules?.map((m) =>
    eyebrowLabel(
      getData(m.config.contentId)?.entityType,
      getData(m.config.contentId)?.entitySubtype,
    ),
  );

  return {
    cardContentIds,
    hasMultipleEntitySubtypes: uniq(typeMap).length > 1,
    hasSearchPills: firstSubmodule?.type === ModuleType.SEARCH_PILL,
    isFeatured: firstSubmodule?.type === ModuleType.ENTITY_CARD_FEATURED,
    isStacked: (!!config?.itemsPerColumn && config.itemsPerColumn > 1) || !!config.isStacked,
    itemsPerColumn: config?.itemsPerColumn ?? Constants.defaultItemsCount,
    numberOfItems: module.modules?.length || cardContentIds.length,
    type: config?.carouselType,
  };
};

const getListContext = (module: IModule): IModuleContextListData | undefined => {
  if (module.type !== ModuleType.LIST) {
    return undefined;
  }

  return {
    itemsPerRow: module.config.itemsPerRow ?? Constants.defaultItemsCount,
    isSelectable: module.config.selectable ?? false,
  };
};

type TMergeableProps<N> = Pick<
  IModuleViewProps<N>,
  | 'module'
  | 'isTopLevel'
  | 'viewRootObjectId'
  | 'viewRootObjectUrl'
  | 'viewRootObjectRewindable'
  | 'getData'
  | 'isSideColumn'
  | 'viewId'
  | 'viewType'
>;

function getModuleViewContext<N>(
  parentContext: IModuleContext | undefined,
  {
    module,
    isTopLevel,
    viewRootObjectId,
    viewRootObjectUrl,
    viewRootObjectRewindable,
    getData,
    isSideColumn,
    viewType,
  }: TMergeableProps<N>,
): IModuleContext {
  return merge(cloneDeep(parentContext), {
    carousel: getCarouselContext(module, getData),
    list: getListContext(module),
    moduleSubType: module.config.subType,
    moduleId: module.id,
    sectionId: isTopLevel ? module.id : undefined,
    viewRootObjectId,
    viewRootObjectUrl,
    viewRootObjectRewindable,
    isSideColumn,
    viewType,
  });
}

export const useMergedContext = <N>(props: TMergeableProps<N>): IModuleContext => {
  const {
    getData,
    isTopLevel,
    module,
    viewRootObjectId,
    viewRootObjectUrl,
    viewRootObjectRewindable,
    viewId,
    viewType,
  } = props;

  const parentContext = useModuleContext();

  const mergedContext = useMemo(
    () =>
      getModuleViewContext(parentContext, {
        module,
        isTopLevel,
        viewRootObjectId,
        viewRootObjectUrl,
        viewRootObjectRewindable,
        getData,
        isSideColumn: viewId === ViewIDKey.Player,
        viewType,
      }),
    [
      getData,
      isTopLevel,
      module,
      parentContext,
      viewRootObjectId,
      viewRootObjectUrl,
      viewRootObjectRewindable,
      viewId,
      viewType,
    ],
  );

  return mergedContext;
};
