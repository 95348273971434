const getTime = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

export enum LoggerTopic {
  Streaming = 'Streaming',
  Analytics = 'Analytics',
  DataStore = 'DataStore',
  LocalDataStore = 'LocalDataStore',
  Onboarding = 'Onboarding',
  Auth = 'Auth',
  DeepLinking = 'DeepLinking',
  Native = 'Native',
}

export type AudacyLoggerTransport = {
  error: (message: string) => void;
  warn: (message: string) => void;
  info: (message: string) => void;
  debug: (message: string) => void;
  log: (message: string) => void;
};

export const whiteListedTopics: Array<LoggerTopic> = [
  // LoggerTopic.Streaming,
  // LoggerTopic.Analytics,
  // LoggerTopic.DataStore,
  // LoggerTopic.LocalDataStore,
  // LoggerTopic.Onboarding,
  // LoggerTopic.Auth,
  // LoggerTopic.DeepLinking,
  // LoggerTopic.Native,
];

const includesWhiteListedTopic = (message: string): boolean => {
  return Boolean(whiteListedTopics) && whiteListedTopics.some((topic) => message.includes(topic));
};

const logLevels = {
  error: 4,
  warn: 3,
  log: 2,
  info: 2,
  debug: 1,
  trace: 0,
} as const;

class AudacyLogger {
  level: number;
  transports: Array<AudacyLoggerTransport>;

  constructor() {
    this.transports = [];
    this.level = 0;
  }

  public setLogLevel(level: number): void {
    this.level = level;
  }

  public addTransport(transport: AudacyLoggerTransport): void {
    this.transports.push(transport);
  }

  public log(message: string): void {
    console.log(message);
    this.transports.forEach((transport) => {
      transport.debug(message);
    });
  }

  public error(message: string): void {
    if (this.level > logLevels.error) {
      return;
    }
    if (includesWhiteListedTopic(message)) {
      console.error(`${getTime()} ` + message);
    }
    this.transports.forEach((transport) => {
      transport.error(message);
    });
  }

  public warn(message: string): void {
    if (this.level > logLevels.warn) {
      return;
    }
    if (includesWhiteListedTopic(message)) {
      console.warn(`${getTime()} ` + message);
    }
    this.transports.forEach((transport) => {
      transport.warn(message);
    });
  }

  public info(message: string): void {
    if (this.level > logLevels.info) {
      return;
    }
    if (includesWhiteListedTopic(message)) {
      console.info(`${getTime()} ` + message);
    }
    this.transports.forEach((transport) => {
      transport.info(message);
    });
  }

  public debug(message: string): void {
    if (this.level > logLevels.debug) {
      return;
    }
    if (includesWhiteListedTopic(message)) {
      console.log(`${getTime()} ` + message);
    }
    this.transports.forEach((transport) => {
      transport.debug(message);
    });
  }
}

export default new AudacyLogger();
