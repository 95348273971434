import { useEffect } from 'react';

import useEditMode, { useGlobalEditMode } from '@audacy-clients/core/atoms/editMode';
import { EntityImageShape } from '@audacy-clients/core/atoms/wrappers/modules';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { scrollableClassState } from '~/atoms/scrollClass';

import { ICardListItem } from './CardListElement';
import CardListView, { ICardListViewProps } from './CardListView';

interface ICardListViewWithStateProps<T>
  extends Omit<
    ICardListViewProps<T>,
    | 'selectedItems'
    | 'isEditable'
    | 'isEditing'
    | 'isSortable'
    | 'bulkEditActionButton'
    | 'toggleEditModeButton'
    | 'onItemClick'
    | 'onItemToggleSelected'
  > {
  defaultSelected?: string[];
  itemType: Parameters<typeof useEditMode>[0];
  isListEditable?: boolean;
  isListSortable?: boolean;
  isPlayerQueue?: boolean;
  entityImageShape?: EntityImageShape;
  onSortEnd?: (items: T[]) => void;
  onItemClick?: (id: string) => void;
  renderEditActionButton?: (quantity: number) => {
    label: string;
    onClick: (ids: string[]) => () => void;
  };
  hideExpiredMessage?: () => void;
  shouldShowEntityType?: boolean;
  onItemHrefClick?: (id: string) => void;
}

const CardListViewWithState = <T extends ICardListItem>({
  defaultSelected,
  items,
  itemType,
  isListEditable,
  isListSortable,
  selectButtons,
  renderEditActionButton,
  hideExpiredMessage,
  hadExpiredItems,
  onItemHrefClick,
  ...rest
}: ICardListViewWithStateProps<T>): JSX.Element => {
  const { t } = useTranslation();
  const { resetAllEditMode } = useGlobalEditMode();
  const {
    isEditMode: isEditing,
    setIsEditMode,
    selectedItems = [],
    setSelectedItems,
  } = useEditMode(itemType, defaultSelected);

  const isAllSelected = selectedItems.length === items.length;
  const isEditMode = !!isListEditable && isEditing;

  const scrollableClass = useRecoilValue(scrollableClassState);

  useEffect(() => {
    return () => {
      resetAllEditMode();
    };
  }, [resetAllEditMode]);

  useEffect(() => {
    setSelectedItems(defaultSelected || []);
  }, [defaultSelected, setSelectedItems]);

  const handleToggleSelect = (episodeId: string): void => {
    const newSelectedItems = [...selectedItems];
    const episodeIndex = newSelectedItems.indexOf(episodeId);

    if (episodeIndex !== -1) {
      newSelectedItems.splice(episodeIndex, 1);
    } else {
      newSelectedItems.push(episodeId);
    }

    setSelectedItems(newSelectedItems);
  };

  const handleToggleSelectAll = (): void => {
    setSelectedItems(isAllSelected ? [] : items.map((episode) => episode.id));
  };

  const handleBulkEditActionClick = () => {
    if (!bulkEditActionButton) return;

    bulkEditActionButton.onClick(selectedItems)();
    setSelectedItems([]);
  };

  const bulkEditActionButton =
    renderEditActionButton && renderEditActionButton(selectedItems.length);

  return (
    <CardListView
      {...rest}
      items={items}
      selectedItems={selectedItems}
      isEditable={isListEditable}
      isEditing={isEditMode}
      isSortable={isListSortable}
      onItemHrefClick={onItemHrefClick}
      onItemToggleSelected={handleToggleSelect}
      hadExpiredItems={hadExpiredItems}
      selectButtons={[
        {
          label: isAllSelected
            ? t('queueOverlay.myQueue.unselectAll')
            : t('queueOverlay.myQueue.selectAll'),
          onClick: handleToggleSelectAll,
        },
        ...(selectButtons ? selectButtons : []),
      ]}
      bulkEditActionButton={
        bulkEditActionButton
          ? {
              ...bulkEditActionButton,
              onClick: handleBulkEditActionClick,
            }
          : undefined
      }
      toggleEditModeButton={{
        label: isEditMode ? t('global.done') : t('global.edit'),
        onClick: () => {
          hadExpiredItems && hideExpiredMessage?.();
          setIsEditMode(!isEditMode);
        },
      }}
      scrollClass={scrollableClass}
      itemType={itemType}
    />
  );
};

export default CardListViewWithState;
