import { useFeatureFlags } from '@audacy-clients/core/atoms/config/settings';
import { playerCompanionAdIframeUrlState } from '@audacy-clients/core/atoms/player';
import { useRecoilValue } from 'recoil';

import AdBanner from '~/components/AdBanner';
import usePlayerProps from '~/components/Player/hooks/usePlayerProps';
import styles from '~/components/Player/MiniPlayer/styles';
import { useBreakpoints } from '~/hooks/use-breakpoints';

export const AdBannerWrapper = (): JSX.Element => {
  const adUrl = useRecoilValue(playerCompanionAdIframeUrlState);
  const { greaterThan } = useBreakpoints();
  const featureFlags = useFeatureFlags();

  const { miniPlayerTitle } = usePlayerProps();

  return (
    <>
      {greaterThan.SM && adUrl && (
        <div css={styles.adBanner}>
          <AdBanner url={adUrl} title={miniPlayerTitle} />
        </div>
      )}
      {/* Video Ad Stub (see VideoAd.ts in client-services) */}
      {featureFlags.videoPreRoll && (
        <div css={styles.adBanner}>
          <div id="videoAdContainer" css={styles.videoAdContainer} />
        </div>
      )}
    </>
  );
};
