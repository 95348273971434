import { IEpisode } from '@audacy-clients/core/atoms/wrappers/types';

import { formatPublishDate } from '~/utils/date';

export const getEyebrow = (episode: IEpisode, shouldHaveParentTitle = true): string[] => {
  const { parentTitle, publishDate } = episode;
  const eyebrow = [];

  if (publishDate) {
    const formattedDate = formatPublishDate(publishDate);
    if (formattedDate) {
      eyebrow.push(formattedDate);
    }
  }

  if (shouldHaveParentTitle && parentTitle) {
    const trimmedTitle =
      parentTitle.length > 100 ? `${parentTitle.substring(0, 100)}...` : parentTitle;
    eyebrow.push(trimmedTitle);
  }

  return eyebrow;
};
