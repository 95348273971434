export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Ticket</title>
    <path
      d="M28 8h.75v-.75H28V8ZM4 8v-.75h-.75V8H4Zm24 4.126.187.726.563-.145v-.581H28Zm0 7.748h.75v-.581l-.563-.145-.187.726ZM28 24v.75h.75V24H28ZM4 24h-.75v.75H4V24Zm0-4.064-.133-.738-.617.111v.627H4Zm0-7.872h-.75v.627l.617.11.133-.737ZM28 7.25H4v1.5h24v-1.5Zm.75 4.876V8h-1.5v4.126h1.5Zm-3 3.874a3.252 3.252 0 0 1 2.437-3.148l-.374-1.452A4.752 4.752 0 0 0 24.25 16h1.5Zm2.437 3.148A3.252 3.252 0 0 1 25.75 16h-1.5a4.752 4.752 0 0 0 3.563 4.6l.374-1.452ZM28.75 24v-4.126h-1.5V24h1.5ZM4 24.75h24v-1.5H4v1.5Zm-.75-4.814V24h1.5v-4.064h-1.5ZM6.536 16a3.251 3.251 0 0 1-2.67 3.198l.267 1.476A4.751 4.751 0 0 0 8.036 16h-1.5Zm-2.67-3.198A3.251 3.251 0 0 1 6.537 16h1.5a4.751 4.751 0 0 0-3.903-4.675l-.266 1.477ZM3.25 8v4.064h1.5V8h-1.5Z"
      fill="currentColor"
    />
    <path stroke="currentColor" strokeWidth="1.5" d="M11.75 8v16" />
  </svg>
);
