export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Home</title>
    <path d="M16 4 5 13v14h8v-9h6v9h8V13L16 4Z" fill="currentColor" />
  </svg>
);
