export const LOADING_TIMEOUT = 15000; // 15 seconds

export const SILENCE_TIMEOUT = 5000; // 5 seconds

export const AUTO_TRANSITION = 500; // 0.5 seconds

export const STREAMER_ERROR_STRING = 'Unable to load stream.';

export const AD_CONTENT_TYPES = new Set(['sounder', 'ad']);

export const AMPERWAVE_URL_TEST = 'live.amperwave.net';

export const SCHEDULE_UPDATE_INTERVAL = 60000; // Msec

export const MAX_RETRY_ATTEMPTS = 10; // Total time = (# attempts - 3) * 30 seconds = 3 minutes

export const INITIAL_RETRY_DELAY = 1000; // Starting with a 1-second delay

export const MAX_DELAY = 30000; // Maximum delay of 30 seconds

export const COMMON_ACTIONS = {
  DESTROY: {
    target: 'DESTROYED',
  },
};

export const NON_REWINDABLE_ERROR = 'Content is not rewindable';

export const ADSWIZZ_SCRIPT_URL = `https://synchrobox.adswizz.com/register2.php?gpp={gppString}`;

export const ADSWIZZ_SYNC_SCRIPT_URL = `https://synchroscript.deliveryengine.adswizz.com/syncMe?partnerDomain=audacy.com&partnerUserId={partnerUserId}&idType=cookie`;
