/**
 * @module AudacyAuthError
 */
export default class AudacyAuthError extends Error {
  /**
   * Creates a new instance of an AudacyAuthError.
   */
  constructor(locationStr) {
    super('User is not logged in. ' + locationStr);
  }
}
