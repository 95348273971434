import { EContentType } from '@audacy-clients/client-services/src/audioServices/types';
import { StateValue } from 'xstate';

import { IDeviceInfoProvider } from '../../Config';
import Chapter from '../../dataServices/Chapter';
import Episode from '../../dataServices/Episode';
import StandaloneChapter from '../../dataServices/StandaloneChapter';
import Station from '../../dataServices/Station';
import { ILogger } from '../../logger';
import { IPlayer, IPlayerMetadata } from '../players/types';
import { IMachineContext, IDataProvider, TPlayableObject } from '../types';
import { type IFeatureFlags } from '@audacy-clients/core/atoms/config/settings';

export interface IStreamerMachineContext<T extends TPlayableObject> extends IMachineContext {
  contentType: EContentType;
  item: T;
  player: IPlayer;
  metadata?: IPlayerMetadata & {
    isAd?: boolean;
  };
  elapsed?: number;
  station?: Station;
  episode?: Episode;
  standaloneChapter?: StandaloneChapter;
  currentChapterIndex?: number;
  chapters?: Chapter[];
  requestedOffset?: number;
  failed?: boolean;
  logger: ILogger;
  shouldDestroy?: boolean;
  shouldClearPlayer?: boolean;
  timeSinceLastSavedResumePoint?: number;
  retryCount?: number;
  featureFlags?: IFeatureFlags;
  deviceInfoProvider?: IDeviceInfoProvider;
  firstLoadTime?: number;
  bufferStartTime?: number;
  errors?: unknown[];
  trackId?: string;
}

export interface IStreamerState<T extends TPlayableObject> {
  value: StateValue;
  context: IStreamerMachineContext<T>;
}

export enum ScrubberBarType {
  ElapsedTime = 'ElapsedTime',
  Live = 'Live',
  WallClock = 'WallClock',
}

export enum PlayerButtonState {
  Hidden = 'Hidden',
  VisibleDisabled = 'VisibleDisabled',
  VisibleEnabled = 'VisibleEnabled',
}

export interface IPlayerControlsState {
  backToLiveButton: PlayerButtonState;
  ff15sButton: PlayerButtonState;
  nextTrackButton: PlayerButtonState;
  pauseButton: PlayerButtonState;
  playButton: PlayerButtonState;
  prevTrackButton: PlayerButtonState;
  // At the end of on-demand content, we show the replay
  // button so the user can restart the audio.The UI can
  // be the same as the play button.
  replayButton: PlayerButtonState;
  rw15sButton: PlayerButtonState;
  scrubberBarType: ScrubberBarType;
  scrubberIsUserControlled: boolean;
  skipButton: PlayerButtonState;
  skipsRemaining: number;
  stopButton: PlayerButtonState;
  thumbsUpButton?: PlayerButtonState;
  scrubberIsDisabled: boolean;
  shouldDisplayLiveIndicator: boolean;
  previousChapterButton: PlayerButtonState;
  nextChapterButton: PlayerButtonState;
  restartShowButton: PlayerButtonState;
}

export type TStreamSelector<T> = (
  dataObject: TPlayableObject,
  dataProvider: IDataProvider,
  logger: ILogger,
) => Promise<T | undefined>;
export interface ILiveStationAudioSource {
  url: string;
  type: 'aac' | 'mp3' | 'm3u8';
}

type TTimeUnit = 's' | 'ms';
export interface ITime {
  unit: TTimeUnit;
  value: number;
}
