export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Twitter logo</title>
    <path
      d="M26.198 11.228c.016.228.016.456.016.686C26.214 18.92 20.88 27 11.128 27v-.004A15.01 15.01 0 0 1 3 24.619a10.647 10.647 0 0 0 7.847-2.198 5.309 5.309 0 0 1-4.953-3.682 5.286 5.286 0 0 0 2.393-.091 5.303 5.303 0 0 1-4.253-5.197v-.068a5.27 5.27 0 0 0 2.407.664A5.309 5.309 0 0 1 4.8 6.967a15.049 15.049 0 0 0 10.927 5.54 5.308 5.308 0 0 1 9.036-4.836 10.64 10.64 0 0 0 3.367-1.287 5.323 5.323 0 0 1-2.33 2.932 10.545 10.545 0 0 0 3.044-.835 10.771 10.771 0 0 1-2.646 2.747Z"
      fill="currentColor"
    />
  </svg>
);
