import { useCallback } from 'react';

import { playbackResumePointsState } from '@audacy-clients/core/atoms/playbackResumePoints';
import { IQueueItem } from '@audacy-clients/core/atoms/wrappers/types';
import { IToastHook } from '@audacy-clients/core/types/toast';
import clientServices from '@audacy-clients/core/utils/clientServices';
import { useSetRecoilState } from 'recoil';

type MarkAsPlayedCallback = (item: IQueueItem, endOfBroadcastEpisode?: number) => void;

export const useMarkItemAsPlayed = (useToast: () => IToastHook): MarkAsPlayedCallback => {
  const setPlaybackResumePoints = useSetRecoilState(playbackResumePointsState);
  const { showToast } = useToast();

  return useCallback<MarkAsPlayedCallback>(
    (item, endOfBroadcastEpisode) => {
      // TODO: [A2-6219] update this once is done, as we'll have a new setPlayed function
      const durationToUse = endOfBroadcastEpisode ? endOfBroadcastEpisode : item.duration;

      if (item.id && durationToUse) {
        setPlaybackResumePoints((prevResumePoints) => {
          return {
            ...prevResumePoints,
            [item.id]: durationToUse,
          };
        });

        clientServices
          .getPersonalizationServices()
          .setPlayback(item.id, durationToUse)
          .catch(() => {
            setPlaybackResumePoints((prevResumePoints) => {
              delete prevResumePoints[item.id];
              return { ...prevResumePoints };
            });
            showToast({ title: 'Error marking item as played' });
          });
      }
    },
    [setPlaybackResumePoints, showToast],
  );
};
