import { TCSSObject, TStyles } from '~/types/emotion-styles';

export enum Breakpoint {
  Small,
  SmallMax,
  Medium,
  MediumMax,
  Large,
  LargeMax,
  ExtraLarge,
  XXLarge,
  XXXLarge,
}

export const Breakpoints = {
  [Breakpoint.Small]: 0,
  [Breakpoint.SmallMax]: 599,
  [Breakpoint.Medium]: 600,
  [Breakpoint.MediumMax]: 1119,
  [Breakpoint.Large]: 1120,
  [Breakpoint.LargeMax]: 1439,
  [Breakpoint.ExtraLarge]: 1440,
  [Breakpoint.XXLarge]: 1920,
  [Breakpoint.XXXLarge]: 2560,
};

export const mq = (min: keyof typeof Breakpoints): string =>
  `@media (min-width: ${Breakpoints[min]}px)`;

export const mqMax = (max: keyof typeof Breakpoints): string =>
  `@media (max-width: ${Breakpoints[max]}px)`;

export const mqClamp = (min: keyof typeof Breakpoints, max: keyof typeof Breakpoints): string =>
  `@media (min-width: ${Breakpoints[min]}px) and (max-width: ${Breakpoints[max]}px)`;

export const mqPortrait = (min: keyof typeof Breakpoints): string =>
  `@media (min-width: ${min}px) and (orientation: portrait)`;

export const mqHover = (styles: TStyles, selector = '&:hover'): TCSSObject => ({
  '@media (hover: hover)': {
    [selector]: styles,
  },
});

export const mqReducedMotion = (styles: TStyles): TCSSObject => ({
  '@media (prefers-reduced-motion: reduce)': {
    ...styles,
  },
});

export const MediaQueries = {
  mediumAndAbove: {
    [mqMax(Breakpoint.SmallMax)]: {
      display: 'none',
    },
    [mq(Breakpoint.Medium)]: {
      display: 'inherit',
    },
  },
  largeAndAbove: {
    [mqMax(Breakpoint.MediumMax)]: {
      display: 'none',
    },
    [mq(Breakpoint.Large)]: {
      display: 'inherit',
    },
  },
  belowLarge: {
    [mq(Breakpoint.Large)]: {
      display: 'none',
    },
  },
  smallOnly: {
    [mqMax(Breakpoint.SmallMax)]: {
      display: 'inherit',
    },
    [mq(Breakpoint.Medium)]: {
      display: 'none',
    },
  },
  mediumOnly: {
    [mqMax(Breakpoint.SmallMax)]: {
      display: 'none',
    },
    [mqClamp(Breakpoint.Medium, Breakpoint.MediumMax)]: {
      display: 'inherit',
    },
    [mq(Breakpoint.Large)]: {
      display: 'none',
    },
  },
  largeOnly: {
    [mqMax(Breakpoint.MediumMax)]: {
      display: 'none',
    },
    [mqClamp(Breakpoint.Large, Breakpoint.LargeMax)]: {
      display: 'inherit',
    },
    [mq(Breakpoint.ExtraLarge)]: {
      display: 'none',
    },
  },
  extraLargeOnly: {
    [mqMax(Breakpoint.LargeMax)]: {
      display: 'none',
    },
    [mq(Breakpoint.ExtraLarge)]: {
      display: 'inherit',
    },
  },
};
