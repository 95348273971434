import { useEffect, useRef } from 'react';
import { BrazeEvent, EventSource } from '~/utils/braze';
import { useLocationPermission } from '../use-location-permission';

export const useBrazeLocationPrompt = () => {
  const locationPermissionStatus = useRef<PermissionStatus>();
  const { requestPermission } = useLocationPermission();
  useEffect(() => {
    const brazeMessageHandler = (
      event: MessageEvent<{ type: BrazeEvent; source: EventSource }>,
    ) => {
      if (event.data.source === EventSource.Braze && event.data.type === BrazeEvent.Location) {
        if (locationPermissionStatus.current?.state !== 'denied') {
          requestPermission();
        }
      }
    };
    window.addEventListener('message', brazeMessageHandler);
    return () => window.removeEventListener('message', brazeMessageHandler);
  }, [requestPermission]);
};
