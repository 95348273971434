import { Borders, BP, Colors, mq, mqHover, Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const VerticalSpacing = {
  sm: 45,
  md: 60,
  lg: 60,
};

const styles: TStyleSheet = {
  container: {
    marginBottom: VerticalSpacing.sm,
    [mq(BP.Medium)]: {
      marginBottom: VerticalSpacing.md,
    },

    // If a SectionWrapper contains a carousel, show its previous and next
    // buttons when hovering over the SectionWrapper, also give slides that are
    // peeking full opacity.
    ...mqHover({
      '.swiper-button, .swiper-slide': {
        opacity: 1,
      },
    }),
  },
  lastSection: {
    marginBottom: 0,
  },
  'container *': {
    width: '100%',
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  footer: {
    borderTop: Borders.separator,
    marginTop: 20,
    paddingTop: 20,
  },
  header: {
    marginBottom: 20,
  },
  title: {
    // Override TitleTag breakpoint styles which Typography.titleSection does
    // not cover by default.
    [mq(BP.Small)]: {
      ...Typography.titleSection,
    },
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  hasSubtitle: {
    marginBottom: 5,
  },
  subtitle: {
    ...Typography.metadataLarge,
    color: Colors.white60,
    marginBottom: 20,
  },
};

export default styles;
