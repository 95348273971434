import AudacyLogger, { LoggerTopic } from '../AudacyLogger';
import { EContentType, IFailure, TPlayableObject } from '../audioServices/types';
import { IDeviceInfoProvider } from '../Config';
import {
  ClickFeature,
  DataEventType,
  EXCLUDED_MODULE_VIEW_TITLE,
  PermissionAction,
  Platform,
  PlayerAction,
  PlayerActionTimings,
  PlayerMetric,
} from '../Constants';
import { IMarketingDataProvider, INetworkStateProvider, TConnectivityType } from '../Container';
import { enableObjectTracing, ILogger } from '../logger';
import { serializeDOMException } from '../utils';
import { getWebConnectivityType } from '../utils/browser';
import { IStreamerMachineContext } from '../audioServices/streamers/types';
import { getFirstAudioSourceUrl } from '../audioServices/streamers/utils';
import { IViewContext } from '@audacy-clients/core/utils/viewContext';

export type SearchType =
  | 'Manual'
  | 'Suggested Term'
  | 'Audacy Assistant Voice'
  | 'Audacy Assistant Text'
  | 'Audacy Assistant Suggest Term'
  | 'Onboarding';

interface IAnalyticServicesConfig {
  platform: Platform;
  appName: string;
  appVersion: string;
  appBuildNumber: string;
  eventListener?: TEventListener;
  eventSender: IEventSender;
  logger?: ILogger;
  marketingDataProvider: IMarketingDataProvider;
  deviceInfoProvider?: IDeviceInfoProvider;
  networkStateProvider: INetworkStateProvider;
}

export interface IAnalyticsEvent {
  type: DataEventType | PermissionAction | PlayerAction | PlayerMetric;
  timestamp: string;
  context?: IViewContext;
  data?: unknown;
  eventDetails?: IEventDetails;
}

export interface IEventDetails {
  bandwidthEstimateBPS?: number;
  historicBandwidthEstimateBPS?: Array<{
    bandwidth: number;
    timestamp: number;
  }>;
  connectionType?: string;
  contentId?: string;
  currentPosition?: number,
  moduleId?: string;
  stationId?: string;
  streamerType?: EContentType;
  rewindFlag?: boolean;
  uptimeMs?: number;
  mostRecentError?: unknown;
  retryCount?: number;
  reason?: string;
  messageLength?: number;
  listenDuration?: number;
  exitDestination?: string;
  isAutomotiveFlag?: boolean;
  isLive?: boolean;
  contentShareMethod?: string;
  secondsOffset?: string;
  streamUrl?: string;
  chapter?: string;
  isOnboarding?: boolean;
  registrationMethod?: string;
  hasIncompleteProfile?: boolean;
  pushMessage?: string;
  permissionStatus?: string;
  feature?: ClickFeature;
  searchTerm?: string;
  searchType?: SearchType;
  songTitle?: string;
  artist?: string;
  speedSelection?: string;
  exception?: string;
  exceptionObject?: string;
  endOfEpisode?: boolean;
  duration?: number;
  connectivityType?: TConnectivityType;
  isNativeErrorHandling?: boolean;
  error?: unknown;
  timeBufferingMs?:number;
  branchParams?: unknown;

  //To Be Removed
  bufferingStartCount?: number,
  bufferingEndCount?: number,
  bufferingStartEventsPerMinute?: number,
  bufferingEndEventsPerMinute?: number,
}

export enum AnalyticsPlatform {
  MOBILE = 'MOBILE',
  WEB = 'WEB',
  CLIENT_SERVICES_TEST = 'CLIENT_SERVICES_TEST',
  CREATOR = 'CREATOR',
}

export enum AnalyticsPlatformVariant {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  NONE = 'NONE',
}

interface IAssistantProps {
  audacyAssistantFlag: string;
  audacyAssistantSessionId: string;
  audacyAssistantUserId: string;
}

export interface ISearchAssistantProps extends Partial<IAssistantProps> {
  audacyAssistantUtterance?: string;
}

interface IPlayerEventDetails {
  type: PlayerAction;
  contentId: string;
  speedSelection?: string;
  currentPosition?: number;
  streamUrl?: string;
  replayFlag?: 'yes' | 'no';
  rewindFlag?: boolean;
  connectionType?: string;
}

interface IPlayClickContext {
  contentId?: string;
  feature: ClickFeature;
  moduleId?: string;
  chapter?: string;
  featureText?: string;
  itemsList?: string[];
  isAutomotiveFlag?: boolean;
  secondsOffset?: string;
}

interface IStopClickContext {
  contentId?: string;
  feature: ClickFeature;
  isAutomotiveFlag?: boolean;
}

interface ISkipByTimeClickContext {
  contentId?: string;
  feature: ClickFeature;
  isAutomotiveFlag?: boolean;
}

interface INavigationClickContext {
  contentId?: string;
  feature: ClickFeature;
  isAutomotiveFlag?: boolean;
  itemsList?: string[];
  moduleId?: string;
  viewId?: string;
}

interface IModuleViewProps {
  contentId?: string;
  isAutomotiveFlag?: boolean;
  moduleDepth?: string;
  moduleId: string;
  modulePosition?: string;
  viewContext: IViewContext;
}

export interface IEventSender {
  sendUserEvents(
    appName: string,
    appVersion: string,
    appBuildNumber: string,
    userAgent?: string,
    body?: string,
  ): Promise<{ code: number }>;
  setPlatformAndVariant(
    platform: AnalyticsPlatform,
    platformVariant: AnalyticsPlatformVariant,
  ): void;
}

type TEventListener = (events: IAnalyticsEvent[]) => void;

export default class AnalyticServices {
  logger?: ILogger;
  platform: AnalyticsPlatform;
  private appName: string;
  private appVersion: string;
  private appBuildNumber: string;
  private eventListener?: TEventListener;
  private platformVariant: AnalyticsPlatformVariant;
  private events: IAnalyticsEvent[] = [];
  private queueTime = 2000;
  private userAgent?: string;
  private timer?: ReturnType<typeof setTimeout>;
  private eventSender: IEventSender;
  private marketingDataProvider: IMarketingDataProvider;
  private networkStateProvider: INetworkStateProvider;
  private lastContinueListen?: { contentId: string; time: number };
  private lastContinueListen10?: { contentId: string; time: number };
  private songsPlayed: [string];

  constructor(config: IAnalyticServicesConfig) {
    this.appName = config.appName;
    this.appVersion = config.appVersion;
    this.appBuildNumber = config.appBuildNumber;
    this.eventListener = config.eventListener;
    this.eventSender = config.eventSender;
    this.logger = config.logger;
    this.marketingDataProvider = config.marketingDataProvider;
    this.networkStateProvider = config.networkStateProvider;
    this.songsPlayed = [''];

    switch (config.platform) {
      case Platform.ANDROID:
        this.platform = AnalyticsPlatform.MOBILE;
        this.platformVariant = AnalyticsPlatformVariant.ANDROID;
        break;
      case Platform.ANDROID_CREATOR:
        this.platform = AnalyticsPlatform.CREATOR;
        this.platformVariant = AnalyticsPlatformVariant.ANDROID;
        break;
      case Platform.IOS:
        this.platform = AnalyticsPlatform.MOBILE;
        this.platformVariant = AnalyticsPlatformVariant.IOS;
        break;
      case Platform.IOS_CREATOR:
        this.platform = AnalyticsPlatform.CREATOR;
        this.platformVariant = AnalyticsPlatformVariant.IOS;
        break;
      case Platform.WEB:
        this.platform = AnalyticsPlatform.WEB;
        this.platformVariant = AnalyticsPlatformVariant.NONE;
        break;
      case Platform.CS_TEST:
        this.platform = AnalyticsPlatform.CLIENT_SERVICES_TEST;
        this.platformVariant = AnalyticsPlatformVariant.NONE;
        break;
    }

    this.eventSender.setPlatformAndVariant(this.platform, this.platformVariant);
  }

  public get queuedEvents(): IAnalyticsEvent[] {
    return [...this.events];
  }

  sendEventToListener(event: Omit<IAnalyticsEvent, 'timestamp'>): void {
    const timestampedEvent = {
      ...event,
      timestamp: new Date().toISOString(),
    };

    AudacyLogger.info(
      `[${
        LoggerTopic.Analytics
      }] AnalyticsService: sending event to platform listenters:\n${JSON.stringify(
        timestampedEvent,
        null,
        2,
      )}`,
    );

    this.eventListener?.([timestampedEvent]);
  }

  private pushEvent(event: Omit<IAnalyticsEvent, 'timestamp'>): void {
    const timestampedEvent = {
      ...event,
      timestamp: new Date().toISOString(),
    };

    this.events.push(timestampedEvent);
    this.eventListener?.([timestampedEvent]);

    AudacyLogger.info(
      `[${LoggerTopic.Analytics}] AnalyticsService: adding event:\n${JSON.stringify(
        timestampedEvent,
        null,
        2,
      )}`,
    );

    if (!this.timer) {
      this.timer = setTimeout(() => {
        this.timer = undefined;
        this.send();
      }, this.queueTime);
    }
  }

  private handleEventSendFailure(events: IAnalyticsEvent[]): void {
    this.logger?.warn('Unable to send events, putting them back in the queue.');
    this.events = events.concat(this.events);
  }

  private async send(): Promise<void> {
    // we filter out CONTINUE_LISTEN_10 events before sending them to the data events api
    const events = this.events.filter((event) => event.type !== PlayerAction.CONTINUE_LISTEN_10);

    if (!events || events.length === 0) {
      return;
    }

    this.events = [];

    const payload = JSON.stringify({
      campaign: this.marketingDataProvider.campaign,
      events,
    });

    try {
      const response = await this.eventSender.sendUserEvents(
        this.appName,
        this.appVersion,
        this.appBuildNumber,
        this.userAgent,
        payload,
      );

      if (response?.code >= 400) {
        this.handleEventSendFailure(events);
      }
    } catch (_error) {
      this.handleEventSendFailure(events);
    }
  }

  async sendAppCrash() {
    this.pushEvent({ type: DataEventType.APP_CRASH });
    // Immediately flush and send crash event
    await this.send();
  }

  sendAppExitEvent(viewContext: IViewContext, exitDestination: string, contentId: string) {
    this.pushEvent({
      type: DataEventType.APP_EXIT,
      context: viewContext,
      eventDetails: {
        ...(contentId && { contentId }),
        exitDestination,
      },
    });
  }

  sendAppStart() {
    this.pushEvent({ type: DataEventType.APP_START });
  }

  sendAskToSpeak(contentId: string) {
    this.pushEvent({
      type: DataEventType.ASK_TO_SPEAK,
      eventDetails: {
        contentId,
      },
    });
  }
  sendAutomotiveConnectEvent(isConnected: boolean) {
    const e = isConnected ? DataEventType.AUTOMOTIVE_CONNECT : DataEventType.AUTOMOTIVE_DISCONNECT;
    const event = {
      type: e,
      eventDetails: {
        isAutomotiveFlag: true,
      },
    };
    this.pushEvent(event);
  }

  sendCastToDevice(viewContext: IViewContext, contentId: string, castDevice?: string) {
    this.pushEvent({
      type: DataEventType.CAST_TO_DEVICE,
      context: viewContext,
      eventDetails: {
        ...(castDevice && { castDevice }),
        contentId,
      },
    });
  }

  sendChatCommentPost(contentId: string) {
    this.pushEvent({
      type: DataEventType.CHAT_COMMENT_POST,
      eventDetails: {
        contentId,
      },
    });
  }

  sendChatCommentLike(contentId: string) {
    this.pushEvent({
      type: DataEventType.CHAT_COMMENT_LIKE,
      eventDetails: {
        contentId,
      },
    });
  }

  sendChatReaction(contentId: string) {
    this.pushEvent({
      type: DataEventType.CHAT_REACTION,
      eventDetails: {
        contentId,
      },
    });
  }

  sendContentShareEvent(
    viewContext: IViewContext,
    contentId: string,
    contentShareMethod: string,
    secondsOffset?: string,
    chapter?: string,
  ) {
    this.pushEvent({
      type: DataEventType.CONTENT_SHARE,
      context: viewContext,
      eventDetails: {
        contentId,
        contentShareMethod,
        secondsOffset,
        chapter,
      },
    });
  }

  sendFollow(
    viewContext: IViewContext,
    contentId: string,
    isOnboarding: boolean,
    isFollow: boolean,
  ) {
    this.pushEvent({
      type: isFollow ? DataEventType.FOLLOW : DataEventType.UNFOLLOW,
      context: viewContext,
      eventDetails: {
        contentId,
        isOnboarding,
      },
    });
  }

  sendLoginLogout(
    viewContext: IViewContext,
    hasIncompleteProfile: boolean,
    registrationMethod?: string,
    isLogin?: boolean,
  ) {
    this.pushEvent({
      type: isLogin ? DataEventType.LOGIN : DataEventType.LOGOUT,
      context: viewContext,
      eventDetails: registrationMethod ? { registrationMethod, hasIncompleteProfile } : undefined,
    });
  }

  sendJumpToLive(contentId: string, eventDetails?: IEventDetails) {
    this.pushEvent({
      type: DataEventType.JUMP_TO_LIVE,
      eventDetails: {
        contentId,
        ...eventDetails,
      },
    });
  }

  sendModuleView(props: IModuleViewProps) {
    const { viewContext, moduleId, contentId, modulePosition, moduleDepth, isAutomotiveFlag } =
      props;

    if (!moduleId.includes(EXCLUDED_MODULE_VIEW_TITLE)) {
      this.pushEvent({
        type: DataEventType.MODULE_VIEW,
        context: viewContext,
        eventDetails: {
          ...(contentId && { contentId }),
          moduleId,
          ...(modulePosition && moduleDepth ? { modulePosition, moduleDepth } : {}),
          isAutomotiveFlag,
        },
      });
    }
  }

  sendOpenFromPushNotification(
    viewContext: IViewContext,
    pushMessage: string,
    viewId?: string,
    contentId?: string,
    url?: string,
    pushType?: string,
  ) {
    this.pushEvent({
      type: DataEventType.PUSH_NOTIFICATION,
      context: viewContext,
      eventDetails: {
        pushMessage,
        ...(viewId ? { viewId } : {}),
        ...(contentId ? { contentId } : {}),
        ...(url ? { url } : {}),
        ...(pushType ? { pushType } : {}),
      },
    });
  }

  sendPermissionEvent(
    viewContext: IViewContext,
    type: PermissionAction,
    permissionStatus: string,
    isOnboarding: boolean,
  ) {
    this.pushEvent({
      type: type,
      context: viewContext,
      eventDetails: {
        permissionStatus: permissionStatus,
        isOnboarding: isOnboarding,
      },
    });
  }

  sendNavigationClickEvent(
    viewContext: IViewContext,
    navigationClickContext: INavigationClickContext,
  ) {
    this.pushEvent({
      type: DataEventType.NAVIGATION_CLICK,
      context: viewContext,
      eventDetails: navigationClickContext,
    });
  }
  sendPlayClickEvent(
    viewContext: IViewContext,
    playClickContext: IPlayClickContext,
    playAssistantProps?: IAssistantProps,
  ) {
    this.pushEvent({
      type: DataEventType.PLAY_CLICK,
      context: viewContext,
      eventDetails: {
        ...playClickContext,
        ...(playAssistantProps ?? {}),
      },
    });
  }
  sendStopClickEvent(viewContext: IViewContext, stopClickContext: IStopClickContext) {
    this.pushEvent({
      type: DataEventType.STOP_CLICK,
      context: viewContext,
      eventDetails: {
        contentId: stopClickContext?.contentId,
        feature: stopClickContext?.feature,
        isAutomotiveFlag: stopClickContext?.isAutomotiveFlag,
      },
    });
  }
  sendSkipByTimeClickEvent(
    viewContext: IViewContext,
    skipByTimeClickContext: ISkipByTimeClickContext,
  ) {
    this.pushEvent({
      type: DataEventType.FIFTEEN_SECOND_CLICK,
      context: viewContext,
      eventDetails: {
        contentId: skipByTimeClickContext?.contentId,
        feature: skipByTimeClickContext?.feature,
        isAutomotiveFlag: skipByTimeClickContext?.isAutomotiveFlag,
      },
    });
  }
  sendAutoPlayClickEvent(
    type: DataEventType,
    viewContext: IViewContext,
    playClickContext: IPlayClickContext,
  ) {
    this.pushEvent({
      type,
      context: viewContext,
      eventDetails: playClickContext,
    });
  }

  sendSleepTimerSetEvent(
    type: PlayerAction,
    contentId: string,
    endOfEpisode: boolean,
    duration: number,
  ): void {
    this.pushEvent({
      type,
      eventDetails: {
        contentId,
        endOfEpisode,
        duration,
      },
    });
  }

  sendSongListenedEvent(
    type: PlayerAction,
    contentId: string,
    songName: string,
    eventDetails: IAnalyticsEvent['eventDetails'] = {},
    replayFlag?: 'yes' | 'no',
  ): void {
    if (this.songsPlayed.includes(songName) || songName === '') {
      return;
    }

    this.songsPlayed.push(songName);
    if (this.songsPlayed.length > 10) {
      this.songsPlayed.shift();
    }
    this.sendPlayerEvent({ type, contentId, ...eventDetails, replayFlag });
  }

  sendPlayerEvent(playerEventDetails: IPlayerEventDetails): void {
    let eventDetailsOverride = {};
    let connectivityType = this.networkStateProvider.connectivityType;
    const connectionType = playerEventDetails.connectionType ?? 'unknown';

    if (!connectivityType){
      connectivityType = getWebConnectivityType();
    }

    const currentTime = Date.now();
    const { type: type, ...eventDetailsWithoutType } = playerEventDetails;

    if (type === PlayerAction.CONTINUE_LISTEN) {
      if (this.shouldSendContinueListenEvent(playerEventDetails.contentId, currentTime)) {
        this.lastContinueListen = { contentId: playerEventDetails.contentId, time: currentTime };
        eventDetailsOverride = {
          listenDuration: PlayerActionTimings.CONTINUE_LISTEN_DURATION,
        };

        this.pushEvent({
          type,
          eventDetails: {
            ...eventDetailsWithoutType,
            ...eventDetailsOverride,
            connectivityType,
            connectionType
          },
        });
      }

      if (this.shouldSendContinueListenEvent10(playerEventDetails.contentId, currentTime)) {
        this.lastContinueListen10 = { contentId: playerEventDetails.contentId, time: currentTime };
        eventDetailsOverride = {
          listenDuration: PlayerActionTimings.CONTINUE_LISTEN_DURATION_10,
        };

        this.pushEvent({
          type: PlayerAction.CONTINUE_LISTEN_10,
          eventDetails: {
            ...eventDetailsWithoutType,
            ...eventDetailsOverride,
            connectivityType,
            connectionType
          },
        });
      }
    } else {
      this.pushEvent({
        type,
        eventDetails: {
          ...eventDetailsWithoutType,
          ...eventDetailsOverride,
          connectivityType,
          connectionType
        },
      });
    }
  }

  async sendPlaybackErrorEvent(
    context: IStreamerMachineContext<TPlayableObject>,
    event: object | undefined,
  ): Promise<void> {
    const { contentType, item } = context;
    const audioRoute = context.deviceInfoProvider?.getAudioRoute();
    const { type } = (await context.deviceInfoProvider?.getNetworkInfo()) || {};
    const streamUrl = getFirstAudioSourceUrl(item.data.streamUrl);
    const { error, errorMessage } = (event as IFailure) || {};
    const extractedError = error?.raw || error || errorMessage;
    const message = `Playback error for ${contentType} from ${item.getId()} - ${item.getTitle()}`;

    const isDOMException =
      typeof DOMException !== 'undefined' && extractedError instanceof DOMException;

    const exceptionObject = isDOMException
      ? serializeDOMException(extractedError)
      : JSON.stringify(extractedError);

    this.pushEvent({
      type: PlayerAction.PLAYBACK_ERROR,
      eventDetails: {
        contentId: item.getId(),
        exception: message,
        exceptionObject,
        connectionType: audioRoute,
        connectivityType: type,
        streamUrl,
      },
    });
  }

  sendQueueAddRemove(viewContext: IViewContext, contentId: string, isAdd: boolean) {
    this.pushEvent({
      type: isAdd ? DataEventType.ADD_TO_QUEUE : DataEventType.REMOVE_FROM_QUEUE,
      context: viewContext,
      eventDetails: {
        contentId,
      },
    });
  }

  sendReactivateAccountEvent(viewContext: IViewContext) {
    this.pushEvent({
      type: DataEventType.REACTIVATE_ACCOUNT,
      context: viewContext,
    });
  }

  sendRegistrationEntry() {
    this.pushEvent({ type: DataEventType.REGISTRATION_ENTRY });
  }
  sendRegistrationNameEntry() {
    this.pushEvent({ type: DataEventType.REGISTRATION_NAME_ENTRY });
  }
  sendRegistrationNameSuccessful() {
    this.pushEvent({ type: DataEventType.REGISTRATION_NAME_SUCCESSFUL });
  }
  sendRegistrationPiiEntry() {
    this.pushEvent({ type: DataEventType.REGISTRATION_PII_ENTRY });
  }
  sendRegistrationPiiSuccessful() {
    this.pushEvent({ type: DataEventType.REGISTRATION_PII_SUCCESSFUL });
  }
  sendRegistrationSuccessful() {
    this.pushEvent({ type: DataEventType.REGISTRATION_SUCCESSFUL });
  }

  sendScreenView(viewContext: IViewContext) {
    this.pushEvent({
      type: DataEventType.SCREEN_VIEW,
      context: viewContext,
    });
  }

  sendSearchClickedEvent(
    searchTerm: string,
    searchType: SearchType,
    isOnboarding: boolean,
    viewId?: string,
    contentId?: string,
  ) {
    this.pushEvent({
      type: DataEventType.SEARCH_RESULTS_CLICK,
      eventDetails: {
        searchTerm,
        searchType,
        isOnboarding,
        ...(viewId ? { viewId } : {}),
        ...(contentId ? { contentId } : {}),
      },
    });
  }

  sendSearchEvent(
    viewContext: IViewContext,
    searchTerm: string,
    searchType: SearchType,
    isOnboarding: boolean,
  ) {
    this.pushEvent({
      type: DataEventType.SEARCH,
      context: viewContext,
      eventDetails: {
        searchTerm,
        searchType,
        isOnboarding,
      },
    });
  }

  sendSongLikeEvent(contentId: string, songTitle: string, artist: string, isLike: boolean) {
    this.pushEvent({
      type: isLike ? DataEventType.SONG_LIKE : DataEventType.SONG_UNLIKE,
      eventDetails: {
        contentId,
        songTitle,
        artist,
      },
    });
  }

  sendStationCallEvent(viewContext: IViewContext, contentId: string) {
    this.pushEvent({
      type: DataEventType.STATION_CALL,
      context: viewContext,
      eventDetails: {
        contentId,
      },
    });
  }

  setTTRRecordEvent(viewContext: IViewContext) {
    this.pushEvent({
      type: DataEventType.TTR_RECORD,
      context: viewContext,
    });
  }

  sendTTRSendEvent(viewContext: IViewContext, eventDetails: { messageLength: number }) {
    this.pushEvent({
      type: DataEventType.TTR_SEND,
      context: viewContext,
      eventDetails,
    });
  }

  sendTTRReceivedEvent(viewContext: IViewContext, _eventDetails: { messageLength: number }) {
    this.pushEvent({
      type: DataEventType.TTR_RECEIVED,
      context: viewContext,
      // TODO uncomment eventDetails when Data Events API supports messageLength property
      // eventDetails,
    });
  }

  sendTTRFailedEvent(viewContext: IViewContext, _eventDetails: { reason: string }) {
    this.pushEvent({
      type: DataEventType.TTR_FAIL,
      context: viewContext,
      // TODO uncomment eventDetails when Data Events API supports messageLength property
      // eventDetails,
    });
  }

  sendTTRPreviewEvent(viewContext: IViewContext) {
    this.pushEvent({
      type: DataEventType.TTR_PREVIEW,
      context: viewContext,
    });
  }

  sendTTRReRecordEvent(viewContext: IViewContext) {
    this.pushEvent({
      type: DataEventType.TTR_RERECORD,
      context: viewContext,
    });
  }

  sendTTRCancelEvent(viewContext: IViewContext) {
    this.pushEvent({
      type: DataEventType.TTR_CANCEL,
      context: viewContext,
    });
  }

  sendTTRRetryEvent(viewContext: IViewContext) {
    this.pushEvent({
      type: DataEventType.TTR_RETRY,
      context: viewContext,
    });
  }

  setOffline() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  }

  setOnline(): void {
    if (this.events && this.events.length > 0) {
      this.send();
    }
  }

  setUserAgent(userAgent?: string): void {
    this.userAgent = userAgent;
  }

  private shouldSendContinueListenEvent(contentId: string, currentTime: number) {
    if (!this.lastContinueListen || contentId !== this.lastContinueListen.contentId) {
      return true;
    }
    const delta = Math.round((currentTime - this.lastContinueListen.time) / 1000) * 1000;
    return delta >= PlayerActionTimings.CONTINUE_LISTEN_DURATION;
  }

  private shouldSendContinueListenEvent10(contentId: string, currentTime: number) {
    if (!this.lastContinueListen10 || contentId !== this.lastContinueListen10.contentId) {
      // set the counter to one minute earlier since the first event comes after one minute has elapsed on the client
      this.lastContinueListen10 = {
        contentId,
        time: currentTime - PlayerActionTimings.CONTINUE_LISTEN_DURATION,
      };
      return false;
    }

    const delta = Math.round((currentTime - this.lastContinueListen10.time) / 1000) * 1000;
    return delta >= PlayerActionTimings.CONTINUE_LISTEN_DURATION_10;
  }
}

enableObjectTracing(AnalyticServices);
