import { TStyles } from '~/types/emotion-styles';

import styles from './styles';

interface IGridProps {
  gridCss?: TStyles;
  children?: React.ReactNode;
}

const Grid = ({ children, gridCss }: IGridProps): JSX.Element => (
  <div css={[styles.grid, gridCss]}>{children}</div>
);

export default Grid;
