export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Thumbs down</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m15.421 26.541-.526.132-.29-.459-5.524-8.746H3V5h23.421l.093.644 1.915 13.361.122.857H19.82v5.58l-.568.142-3.83.957ZM10.245 6.5H25.12l1.7 11.862H18.319v5.909l-2.735.684-5.34-8.454V6.5Zm-1.5 0v9.468H4.5V6.5h4.245Z"
      fill="currentColor"
    />
  </svg>
);
