import React, { memo, useCallback, useEffect, useState } from 'react';

import { ClickFeature, EpisodeSubType } from '@audacy-clients/client-services/core';
import { ViewIDKey } from '@audacy-clients/core/atoms/config/constants';
import { useFollows } from '@audacy-clients/core/atoms/follows';
import { nowPlaying, playerTimeState } from '@audacy-clients/core/atoms/player';
import { DeeplinkQueryParams, LinkingActions } from '@audacy-clients/core/types/navigation';
import { getClientServices } from '@audacy-clients/core/utils/clientServices';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { appInitializationState } from '~/atoms/app';
import usePlayerProps from '~/components/Player/hooks/usePlayerProps';
import { useAudioHelpersWeb } from '~/hooks/use-audio-helpers-web';
import useAuth from '~/state/auth';
import { useGetViewContextFn } from '~/state/dataEvents';
import useToast from '~/state/toast';

const DeepLinkStateConnector: React.FC = () => {
  const { t } = useTranslation();
  const isAppInitialized = useRecoilValue(appInitializationState);
  const { isLoggedIn } = useAuth();
  const audioHelpers = useAudioHelpersWeb();
  const [hasCompletedAction, setHasCompletedAction] = useState(false);
  const [searchParams] = useSearchParams();
  const { isIdle, duration } = usePlayerProps();

  const playerItem = useRecoilValue(nowPlaying);
  const defaultEpisode = { startTime: undefined, endTime: undefined, subtype: undefined };
  const { startTime, endTime, subtype } = playerItem?.episodeDataObject || defaultEpisode;
  const { follow: followById } = useFollows(useGetViewContextFn());
  const { showToast } = useToast();
  const setPlayerTimeState = useSetRecoilState(playerTimeState);
  const action = searchParams.get(DeeplinkQueryParams.Action);
  const actionContentId = searchParams.get(DeeplinkQueryParams.ActionContentId);
  const actionOffset = searchParams.get(DeeplinkQueryParams.ActionOffset);
  const utm_campaign = searchParams.get(DeeplinkQueryParams.utmCampaign);
  const utm_content = searchParams.get(DeeplinkQueryParams.utmContent);
  const utm_medium = searchParams.get(DeeplinkQueryParams.utmMedium);
  const utm_source = searchParams.get(DeeplinkQueryParams.utmSource);
  const utm_term = searchParams.get(DeeplinkQueryParams.utmTerm);

  const follow = useCallback(() => {
    const callbacks = {
      onSuccess: (undoCallback: () => void): void => {
        const onUndo = () => {
          undoCallback();
        };

        showToast({
          cta: { action: onUndo, title: t('global.undo') },
          title: t('entityHeader.followSuccess', {
            title: actionContentId,
          }),
        });
      },
      onError: (retryCallback: () => void): void => {
        showToast({
          cta: { action: retryCallback, title: t('global.retry') },
          title: t('entityHeader.followError', {
            title: actionContentId,
          }),
        });
      },
      onUndoError: (retryCallback: () => void): void => {
        showToast({
          cta: { action: retryCallback, title: t('global.retry') },
          title: t('entityHeader.undoError'),
        });
      },
    };

    if (actionContentId && isLoggedIn) {
      followById(actionContentId, callbacks);
    }
  }, [actionContentId, followById, isLoggedIn, showToast, t]);

  const autoPlay = useCallback(
    (viewId?: ViewIDKey) => {
      if (actionContentId && isIdle) {
        audioHelpers.playFromId(
          actionContentId,
          {
            feature: ClickFeature.EXTERNAL,
          },
          {
            ...(actionOffset && { startOffset: Number(actionOffset) }),
            viewId,
            autoPlay: false,
          },
        );
      }
    },
    [audioHelpers, actionContentId, actionOffset, isIdle],
  );

  useEffect(() => {
    // handle rewind episodes seperately since the offset is epoch time.
    if (actionOffset && subtype === EpisodeSubType.BROADCAST_SHOW_EPISODE) {
      const startTimeSeconds = startTime / 1000;
      const endTimeSeconds = endTime / 1000;
      const newOffset = Number(actionOffset);
      const calculatedDuration = endTimeSeconds - startTimeSeconds;
      const position = newOffset - startTimeSeconds;
      if (calculatedDuration <= position) {
        setPlayerTimeState({
          duration,
          offset: 0,
          playbackFraction: 0,
        });
      } else {
        setPlayerTimeState({
          duration: calculatedDuration,
          offset: position,
          playbackFraction: position / calculatedDuration,
          remaining: calculatedDuration - position,
        });
      }
      return;
    }

    if (actionOffset && duration) {
      const newOffset = Number(actionOffset);
      if (duration <= newOffset) {
        setPlayerTimeState({
          duration,
          offset: 0,
          playbackFraction: 0,
        });
      } else {
        setPlayerTimeState({
          duration,
          offset: newOffset,
          playbackFraction: newOffset / duration,
          remaining: duration - newOffset,
        });
      }
    }
  }, [duration, actionOffset, setPlayerTimeState, endTime, startTime, subtype]);

  useEffect(() => {
    if (!isAppInitialized || !action || hasCompletedAction) {
      return;
    }

    switch (action) {
      case LinkingActions.AutoplayFull:
        autoPlay(ViewIDKey.Player);
        break;
      case LinkingActions.AutoplayMini:
        autoPlay();
        break;
      case LinkingActions.Follow:
        follow();
        break;
      default:
        break;
    }

    setHasCompletedAction(true);
  }, [autoPlay, hasCompletedAction, isAppInitialized, isIdle, follow, action, actionContentId]);

  useEffect(() => {
    if (utm_campaign || utm_content || utm_medium || utm_source || utm_term) {
      getClientServices().then((clientServices) =>
        clientServices.getPersonalizationServices().setCampaignData({
          content: utm_content?.toString(),
          medium: utm_medium?.toString(),
          name: utm_campaign?.toString(),
          source: utm_source?.toString(),
          term: utm_term?.toString(),
        }),
      );
    }
  }, [utm_campaign, utm_content, utm_medium, utm_source, utm_term]);

  return <></>;
};

export default memo(DeepLinkStateConnector);
