import DataObject from './DataObject';
import { ObjectType, StationSubType, StreamProvider } from '../Constants';
import Container from '../Container';
import { SCHEDULE_CACHE_EXPIRATION } from '../Constants';
import { cache } from '../utils/index';

/** @typedef { import('./EpisodeList').default } EpisodeList */

const serviceBus = Container;

/**
 * @module Station
 */
export default class Station extends DataObject {
  /**
   * Creates a new instance of a Station.
   * @param {StationJson} data
   */
  constructor(data) {
    super(ObjectType.STATION, data);

    DataObject.cleanJsonObject(this.data);
  }

  /**
   * Get the aac stream associated with the station if it exists.
   * @returns {String}
   */
  getAacStream() {
    return this.data.streamUrl && this.data.streamUrl.aac;
  }

  /**
   * Get the AmperWave station id.
   * @returns {Number}
   */
  getAmperWaveId() {
    return this.data.amperwaveStationId;
  }

  /**
   * Get the station callsign.
   * @returns {String}
   */
  getCallsign() {
    return this.data.callsign;
  }

  /**
   * Get the station category.
   * @returns {String}
   */
  getCategory() {
    return this.data.category;
  }

  /**
   * Get the station colors.
   * @returns {Colors}
   */
  getColors() {
    return this.data.colors;
  }

  /**
   * Get the station core id.
   * @returns {String}
   */
  getCoreId() {
    return this.data.identifiers && this.data.identifiers.coreId;
  }

  /**
   * Get the station description.
   * @returns {String}
   */
  getDescription() {
    return this.data.description;
  }

  /**
   * Get the stations genres.
   * @returns {Array<String>}
   */
  getGenres() {
    return this.data.genres;
  }

  /**
   * Get the hls stream associated with the station if it exists.
   * @returns {String}
   */
  getHlsStream() {
    return this.data.streamUrl && this.data.streamUrl.m3u8;
  }

  /**
   * Get the text description of the image associated with the entity.
   * @returns {String}
   */
  getImageAlt() {
    return this.data.images && this.data.images.alt;
  }

  /**
   * Get the square station image.
   * @returns {String}
   */
  getImageSquare() {
    return this.data.images && this.data.images.square;
  }

  /**
   * Get the meta description.
   * @returns {String}
   */
  getMetaDescription() {
    return this.data.metaDescription;
  }

  /**
   * Get the meta title.
   * @returns {String}
   */
  getMetaTitle() {
    return this.data.metaTitle;
  }

  /**
   * Get the hls stream associated with the station if it exists.
   * @returns {String}
   */
  getMp3Stream() {
    return this.data.streamUrl && this.data.streamUrl.mp3;
  }

  /**
   * @returns {String}
   */
  getPhoneNumber() {
    return this.data.phoneNumber;
  }

  /**
   * @returns {Promise<EpisodeList>}
   */
  async getSchedules() {
    const id = this.getId();
    const entityType = this.getEntitySubtype();

    const isBroadcast = entityType === StationSubType.BROADCAST;
    if (!isBroadcast) return new Promise((resolve) => resolve());

    let schedule = cache.get(id);
    if (schedule) {
      return schedule;
    }
    schedule = await serviceBus.dataServices.getSchedules(id);
    if (schedule) {
      cache.set(id, schedule, SCHEDULE_CACHE_EXPIRATION);
      cache.clean();
    }

    return schedule || null;
  }

  /**
   * @returns {String}
   */
  getSiteSlug() {
    return this.data.siteSlug;
  }

  /**
   * Get the stream provider.
   * @returns {StreamProvider}
   */
  getStreamProvider() {
    return this.data.streamProviderName;
  }

  /**
   * Get the Triton name of the station.
   * @returns {String}
   */
  getTritonName() {
    return this.data.identifiers && this.data.identifiers.tritonName;
  }

  /**
   * @returns {Boolean}
   */
  isFollowable() {
    return this.data.followable;
  }

  /**
   * @returns {Boolean}
   */
  isRewindable() {
    return this.data.rewindable;
  }

  /**
   * @returns {Boolean}
   */
  getIsTTREnabled() {
    return this.data.identifiers && this.data.identifiers.isTtrEnabled;
  }
}
