import { Variants } from 'framer-motion';

import { Timing } from '~/styles';

interface ITransitionProps {
  durationIn: number;
  durationOut: number;
}

export const animationStatusName = {
  initial: 'initial',
  animate: 'animate',
  exit: 'exit',
};

export const fadeInOut = (
  { durationIn, durationOut }: ITransitionProps = {
    durationIn: Timing.ms250,
    durationOut: Timing.ms250,
  },
): Variants => ({
  [animationStatusName.initial]: { opacity: 0 },
  [animationStatusName.animate]: { opacity: 1, transition: { duration: durationIn } },
  [animationStatusName.exit]: { opacity: 0, transition: { duration: durationOut } },
});
