import { BP, Colors, Mixins, mq, mqHover, Outlines, Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  item: {
    ...Mixins.focusOutline({ outline: `${Outlines.focusDark}`, outlineOffset: -1 }),
    ...Mixins.transition('background-color'),
    ...Typography.bodySmall,
    alignItems: 'center',
    backgroundColor: Colors.white,
    color: Colors.audacyDarkNavy,
    display: 'flex',
    padding: '15px 0',
    position: 'relative',
    flex: 1,
    width: '100%',
    ...mqHover({ backgroundColor: Colors.greyC5 }),
    '> span': {
      whiteSpace: 'nowrap',
    },
    [mq(BP.Large)]: {
      padding: '5px 15px',
      width: 'auto',
    },
  },
  disabled: {
    color: Colors.audacyDarkNavy60,
    pointerEvents: 'none',
    ...mqHover({ backgroundColor: Colors.white }),
  },
  icon: {
    marginRight: 18,
  },
};

export default styles;
