import { BP, Colors, mq, Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    marginBottom: 45,
    [mq(BP.Medium)]: {
      marginBottom: 60,
    },
  },
  title: {
    ...Typography.headlineSmall,
    color: Colors.white,
  },
  titleLarge: {
    ...Typography.headlineLarge,
    color: Colors.white,
  },
};

export default styles;
