import { BP, mq, Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    padding: `20px 0`,
    overflow: 'hidden',
    [mq(BP.Medium)]: {
      padding: 0,
    },
    [mq(BP.Large)]: {
      height: '100%',
      overflowX: 'hidden',
      overflowY: 'auto',
      padding: '60px 0',
    },
  },
  module: {
    marginBottom: 45,
    [mq(BP.Medium)]: {
      padding: 0,
    },
  },
  chapters: {
    marginTop: 5,
    [mq(BP.Medium)]: {
      marginTop: 40,
    },
  },
  text: {
    ...Typography.bodySmall,
  },
};

export default styles;
