import { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { windowSizeAtom } from '~/state/window';
import { BP, Breakpoints } from '~/styles';
import { getBreakpoint } from '~/utils/breakpoints';

export function useBreakpoints() {
  const { width } = useRecoilValue(windowSizeAtom);
  const [bk, setBK] = useState<BP>(BP.Small);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!width) {
      return;
    }
    setBK(getBreakpoint(width));
    setIsLoading(false);
  }, [width]);

  return {
    bk,
    greaterThan: {
      SM: Breakpoints[bk] > Breakpoints[BP.SmallMax],
      MD: Breakpoints[bk] > Breakpoints[BP.MediumMax],
      LG: Breakpoints[bk] > Breakpoints[BP.LargeMax],
    },
    is: {
      SM: bk === BP.Small || bk === BP.SmallMax,
      MD: bk === BP.Medium || bk === BP.MediumMax,
      LG: bk === BP.Large || bk === BP.LargeMax,
      XL: bk === BP.ExtraLarge,
    },
    isLoading,
    lessThan: {
      MD: Breakpoints[bk] < Breakpoints[BP.Medium],
      LG: Breakpoints[bk] < Breakpoints[BP.Large],
      XL: Breakpoints[bk] < Breakpoints[BP.ExtraLarge],
    },
  };
}
