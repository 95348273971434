import ClientServices, {
  ApiVersion,
  AuthState,
  type Environment,
  type Platform,
} from '@audacy-clients/client-services/core';
import { type IPlayer } from '@audacy-clients/client-services/src/audioServices/players/types';
import { type AdvertisingIdFunction } from '@audacy-clients/client-services/src/Config';
import type PersonalizationServices from '@audacy-clients/client-services/src/personalizationServices';
import type IDataStore from '@audacy-clients/client-services/src/personalizationServices/IDataStore';
import { forceRefreshAutoHomeTab } from '@audacy-clients/core/atoms/autoHome';
import { type IFeatureFlags } from '@audacy-clients/core/atoms/config/settings';
import { forceRefreshRecents } from '@audacy-clients/core/atoms/recents';
import { type IBrazeInstance } from '@audacy-clients/core/types/braze';
import { useEffect, useState } from 'react';

import { deferred } from './deferred';

export type TSafeClientServices = Pick<
  ClientServices,
  | 'getAnalyticServices'
  | 'getAudioServices'
  | 'getChatServices'
  | 'getDataServices'
  | 'getPersonalizationServices'
  | 'setOnlineStatus'
  | 'setLatLong'
  | 'setConnectivityType'
  | 'getOnlineStatus'
> & {
  onReady(): Promise<void>;
};

export type IAppInfo = {
  appBuildNumber: string;
  appName: string;
  appVersion: string;
};

export type IClientServicesConfig = {
  env: Environment;
  platform: Platform;
  webcastMetricsStr: string;
};

export type IPlatformSpecificConfig = {
  analyticsEventListener?: ConstructorParameters<
    typeof ClientServices
  >[0]['analyticServices']['eventListener'];
  dataStore: IDataStore;
  domParser?: unknown;
  getAdvertisingId?: AdvertisingIdFunction;
  deviceInfoProvider?: ConstructorParameters<
    typeof ClientServices
  >[0]['audioServices']['deviceInfoProvider'];
  player: IPlayer;
  profileEventListener?: ConstructorParameters<
    typeof ClientServices
  >[0]['personalizationServices']['profileEventListener'];
  logger: ConstructorParameters<typeof ClientServices>[0]['logger'];
  ddError: (message: string) => Promise<void> | void;
  ddLogger?: ConstructorParameters<typeof ClientServices>[0]['ddLogger'];
  featureFlags?: IFeatureFlags;
  callBrazeEvent?: IBrazeInstance['logCustomEvent'];
  removeFromBrazeAttributeArray?: IBrazeInstance['removeFromCustomUserAttributeArray'];
  addToBrazeAttributeArray?: IBrazeInstance['addToCustomUserAttributeArray'];
};

let sharedClientServicesInstance: ClientServices;
const onReadyDeferred = deferred<TSafeClientServices>();

export const initializeClientServices = (
  appInfo: IAppInfo,
  config: IClientServicesConfig,
  platformSpecific: IPlatformSpecificConfig,
): void => {
  const cs = new ClientServices({
    analyticServices: {
      ...config,
      ...appInfo,
      eventListener: platformSpecific.analyticsEventListener,
      deviceInfoProvider: platformSpecific.deviceInfoProvider,
    },
    audioServices: {
      eventListener: () => {},
      ...config,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      domParser: platformSpecific.domParser as any,
      player: platformSpecific.player,
      // TODO: [A2-465] https://entercomdigitalservices.atlassian.net/browse/A2-465
      // Remove this prop when we stop using rewind legacy
      rewindAds: false,
      getAdvertisingId: platformSpecific.getAdvertisingId,
      deviceInfoProvider: platformSpecific.deviceInfoProvider,
      ddError: platformSpecific.ddError,
      featureFlags: platformSpecific.featureFlags,
    },
    dataServices: {
      ...config,
      ver: ApiVersion.V3,
    },
    personalizationServices: {
      ...config,
      ...appInfo,
      dataStore: platformSpecific.dataStore,
      eventListener: () => {},
      language: 'en_US',
      profileEventListener: platformSpecific.profileEventListener,
      historyEventListener: () => {
        forceRefreshRecents();
        if (typeof document === 'undefined') {
          forceRefreshAutoHomeTab();
        }
      },
      getAdvertisingId: platformSpecific.getAdvertisingId,
      callBrazeEvent: platformSpecific.callBrazeEvent,
      removeFromBrazeAttributeArray: platformSpecific.removeFromBrazeAttributeArray,
      addToBrazeAttributeArray: platformSpecific.addToBrazeAttributeArray,
    },
    logger: platformSpecific.logger,
    ddError: platformSpecific.ddError,
    ddLogger: platformSpecific.ddLogger,
    featureFlags: platformSpecific.featureFlags,
    deviceInfoProvider: platformSpecific.deviceInfoProvider,
  });

  cs.onReady()
    .then(async () => {
      const personalizationServices = cs.getPersonalizationServices();
      if ((await personalizationServices.getAuthState()) === AuthState.NONE) {
        await personalizationServices.setAuthState({ state: AuthState.ANON });
      }
    })
    .then(() => {
      sharedClientServicesInstance = cs;
      onReadyDeferred.resolve(sharedClientServicesInstance);
    })
    .catch(onReadyDeferred.reject);
};

const wrappedClientServices: TSafeClientServices = {
  setLatLong: (lat, long) => sharedClientServicesInstance.setLatLong(lat, long),
  setConnectivityType: (connectivityType) =>
    sharedClientServicesInstance.setConnectivityType(connectivityType),
  getAnalyticServices: () => sharedClientServicesInstance.getAnalyticServices(),
  getAudioServices: () => sharedClientServicesInstance.getAudioServices(),
  getChatServices: () => sharedClientServicesInstance.getChatServices(),
  getDataServices: () => sharedClientServicesInstance.getDataServices(),
  getPersonalizationServices: () => sharedClientServicesInstance.getPersonalizationServices(),
  onReady: () => onReadyDeferred.promise.then(() => {}),
  setOnlineStatus: (isOnline) => sharedClientServicesInstance.setOnlineStatus(isOnline),
  getOnlineStatus: () => sharedClientServicesInstance?.getOnlineStatus(),
};

export default wrappedClientServices;

type IClientServicesHook = {
  clientServices: TSafeClientServices;
  loading: boolean;
  personalizationServices?: PersonalizationServices;
};

let ready = false;
const onReadyPromise = onReadyDeferred.promise.then(() => (ready = true));

export const getClientServices = (): Promise<TSafeClientServices> =>
  Promise.all([onReadyPromise, onReadyDeferred.promise]).then(([, cs]) => cs);

export const useClientServices = (): IClientServicesHook => {
  const [isReady, setIsReady] = useState(ready);

  useEffect(() => {
    if (!isReady) {
      onReadyPromise.then(setIsReady);
    }
  }, [isReady]);

  return {
    clientServices: wrappedClientServices,
    loading: !isReady,
  };
};
