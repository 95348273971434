import { Property } from 'csstype';

import { Colors } from './colors';

export interface IBorderProps {
  fullWhite: Property.Border;
  input: Property.Border;
  separator: Property.Border;
}

export const Borders: IBorderProps = {
  fullWhite: `1px solid ${Colors.white}`,
  input: `1px solid ${Colors.white30}`,
  separator: `1px solid ${Colors.white15}`,
};
