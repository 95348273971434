import { Colors } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    position: 'relative',
  },
  overlay: {
    backgroundColor: Colors.audacyDarkNavy70,
    height: '100%',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
  },
};

export default styles;
