import { useState, useEffect } from 'react';

import { ModuleType } from '@audacy-clients/client-services';

import { useBreakpoints } from '~/hooks/use-breakpoints';
import { getAdSlot, defineAdSlot, destroyAdSlots } from '~/utils/googleAds';

import styles from './styles';

const BannerAd = (): JSX.Element => {
  const [wasVisited, setWasVisited] = useState(false);
  const [breakpoint, setBreakpoint] = useState<number>(0);
  const newBreakpoint = useBreakpoints().bk;

  // refreshes when breakpoint changes, and not on initial load
  if (breakpoint !== newBreakpoint && Math.abs(newBreakpoint - breakpoint) === 2) {
    const bannerAd = getAdSlot(ModuleType.BANNER_AD);
    if (bannerAd) {
      destroyAdSlots([bannerAd]);
      defineAdSlot(ModuleType.BANNER_AD);
    }
    setBreakpoint(newBreakpoint);
  }

  // redefines and displays for already visited page
  if (wasVisited && !!window.googletag) {
    const bannerAd = getAdSlot(ModuleType.BANNER_AD);
    if (!bannerAd) defineAdSlot(ModuleType.BANNER_AD);
    window.googletag.display('google-ad-manager___inline-1');
  }

  useEffect(() => {
    if (!wasVisited && !!window.googletag) {
      window.googletag.cmd.push(function () {
        window.googletag?.display('google-ad-manager___inline-1');
      });
      setWasVisited(true);
    }
  }, [wasVisited]);

  return <div id="google-ad-manager___inline-1" css={styles.container} />;
};

export default BannerAd;
