/**
 * @typedef {Object} AudacyErrorJson
 * @property {Number}         code
 * @property {String}         correlationId
 * @property {String}         domain
 * @property {Array<String>} [errors]
 * @property {String}         message
 * @property {String}         sessionId
 * @property {String}         status
 */

/**
 * @module AudacyError
 */
export default class AudacyError extends Error {
  /**
   * Creates a new instance of an AudacyError.
   * @param {String} message
   * @param {AudacyErrorJson} errorJson
   */
  constructor(message, errorJson) {
    super(message);

    this.audacyErrorJson = errorJson;
  }

  /**
   * @returns {AudacyErrorJson}
   */
  getAudacyError() {
    return this.audacyErrorJson;
  }
}
