import { Constants } from '~/components/Navigation/constants';
import { BP, Mixins, mq, ZIndex } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  skipToMainContentContainer: {
    position: 'absolute',
    zIndex: ZIndex.skipToMainContent,
    width: Constants.menuWidth[BP.Large],
  },
  skipToMainContentButton: {
    ...Mixins.transition(['opacity', 'transform']),
    opacity: 0,
    position: 'absolute',
    right: 0,
    transform: 'translate3d(0, -100%, 0)',
    '&:focus': {
      opacity: 1,
      transform: 'translate3d(-20px, 20px, 0)',
      [mq(BP.Medium)]: {
        transform: 'translate3d(0, 20px, 0)',
      },
      [mq(BP.Large)]: {
        transform: 'translate3d(0, 45px, 0)',
      },
    },
  },
};

export default styles;
