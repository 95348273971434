import DataObject from './DataObject';
import { ObjectType } from '../Constants';

/**
 * @module Song
 */
export default class Song extends DataObject {
  /**
   * Creates a new instance of a Song.
   * @param {Object} data
   */
  constructor(data) {
    super(ObjectType.SONG, data);
  }

  /**
   * @returns {string}
   */
  getArtist() {
    return this.data.artist;
  }

  /**
   * @returns {String}
   */
  getTimePlayed() {
    return this.data.timePlayed;
  }
}
