import { link } from '~/components/Link/styles';
import { Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  link: {
    ...link,
    gap: 10,
  },
  primaryNav: {
    ...Typography.titleMainNav,
  },
  secondaryNav: {
    ...Typography.titleMisc,
  },
};

export default styles;
