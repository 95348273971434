import { Colors } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative', // necessary to position content
  },
  activeButton: {
    backgroundColor: Colors.white15,
    color: Colors.white,
  },
};

export default styles;
