import {
  recentlyPlayedStationsSelector,
  useWrappedRecents,
} from '@audacy-clients/core/atoms/recents';
import { IModuleProps } from '@audacy-clients/core/components/ModuleRenderer/types';

import ModuleInViewWrapper from '~/components/ModuleRenderer/ModuleInViewWrapper';

const ModuleRecentlyPlayedStations: React.FC<IModuleProps> = ({
  moduleMap,
  moduleViewComponent,
}) => {
  const recentlyPlayedStations = useWrappedRecents(recentlyPlayedStationsSelector);
  if (!recentlyPlayedStations?.module) {
    return null;
  }

  const { module: recentlyPlayedStationsModule, getContent } = recentlyPlayedStations;

  const ModuleViewComponent = moduleViewComponent;

  return (
    <ModuleViewComponent
      getData={getContent}
      module={recentlyPlayedStationsModule}
      moduleMap={moduleMap}
      moduleWrapper={ModuleInViewWrapper}
    />
  );
};

export default ModuleRecentlyPlayedStations;
