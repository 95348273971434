import { EntitySubtype, EntityType, EpisodeSubType, StationSubType } from '../Constants';

export const shouldEnablePlaybackRates = (
  entityType?: EntityType,
  entitySubtype?: EntitySubtype,
  isRewind?: boolean,
): boolean => {
  const isPodcastEpisode =
    entityType === EntityType.EPISODE && entitySubtype === EpisodeSubType.PODCAST_EPISODE;
  const isRewindEpisode =
    entityType === EntityType.EPISODE && entitySubtype === EpisodeSubType.BROADCAST_SHOW_EPISODE;
  // NOTE: [CCS-2636] - VOD: Solution for an edgecase to enable playback rates on rewind stations while live
  const isLiveRewindEpisode =
    entityType === EntityType.STATION && entitySubtype === StationSubType.BROADCAST && !!isRewind;
  const isStandaloneChapter = entityType === EntityType.STANDALONE_CHAPTER;

  return isPodcastEpisode || isRewindEpisode || isLiveRewindEpisode || isStandaloneChapter;
};
