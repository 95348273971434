import { useMemo } from 'react';

import { RequestType, TModuleRequest } from '@audacy-clients/core/atoms/modules/requests';
import { useLocation } from 'react-router';
import UrlParser from 'url-parse';

interface IParsedUrlParams {
  viewId?: string;
  contentId?: string;
}

const parseUrlParams = (search: string): IParsedUrlParams => {
  const parsedUrl = new UrlParser(search, true);
  const { viewId, contentId } = parsedUrl.query;

  return { viewId, contentId };
};

export const useRequest = (): TModuleRequest => {
  const { pathname: encodedPathname, search } = useLocation();
  const pathname = decodeURIComponent(encodedPathname);

  return useMemo(() => {
    const { viewId, contentId } = parseUrlParams(search);
    let request: TModuleRequest;

    if (viewId && contentId) {
      request = {
        type: RequestType.ContentPage,
        contentId,
        viewId,
      };
    } else if (viewId) {
      request = {
        type: RequestType.LandingPage,
        viewId,
      };
    } else {
      request = {
        path: pathname,
        type: RequestType.PathPage,
      };
    }

    return request;
  }, [search, pathname]);
};
