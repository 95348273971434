import { useTranslation } from 'react-i18next';

import IconButton from '~/components/Button/IconButton';
import { Icons } from '~/components/Icon/constants';
import styles from '~/components/Player/FullscreenPlayer/styles';
import usePlayerProps from '~/components/Player/hooks/usePlayerProps';
import { useBreakpoints } from '~/hooks/use-breakpoints';

const MinimizePlayerButton = (): JSX.Element => {
  const { t } = useTranslation();
  const { onClose } = usePlayerProps();
  const { is } = useBreakpoints();

  return (
    <IconButton
      buttonCss={styles.closeButton}
      ariaLabel={t('playerControls.accessibilityLabels.closeFullPlayer')}
      icon={Icons.CaretDown}
      onClick={onClose}
      buttonSizeInPx={is.MD ? 36 : 28}
      iconSizeInPx={is.MD ? 18 : 12}
    />
  );
};

export default MinimizePlayerButton;
