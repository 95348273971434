import { atom } from 'recoil';

interface INavigationPathState {
  currentPath: string;
  previousPath: string;
}

export const navigationPathState = atom<INavigationPathState>({
  default: {
    currentPath: '/',
    previousPath: '',
  },
  key: 'NavigationPathState',
});
