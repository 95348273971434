import { ClickFeature, EntitySubtype, EntityType } from '@audacy-clients/client-services/core';
import { EntityImageShape } from '@audacy-clients/core/atoms/wrappers/modules';
import { IPlayContext } from '@audacy-clients/core/hooks/useAudioHelpers';
import { getEpisodeTitleAndImage } from '@audacy-clients/core/utils/episode';
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';

import { itemVariants } from '~/components/CardListView/animation';
import HorizontalEntityCard from '~/components/Entity/HorizontalEntityCard';
import { Checkmark } from '~/components/Input/Checkbox';
import useOptionsMenu from '~/hooks/use-options-menu';
import { FollowsCategoryTabStrings } from '~/pages/MyAudioPage/types';
import { useNavClickEvent } from '~/utils/analytics';
import { reduceVariantMotion } from '~/utils/animation';
import { isPlayable } from '~/utils/card';

import styles from './styles';

export interface ICardListItem {
  entitySubtype: EntitySubtype;
  entityType: EntityType;
  id: string;
  showContentId?: string;
  title: string;
  url?: string;
  showUrl?: string;
  episodeUrl?: string;
  publishDate?: Date;
  image?: string;
  parentImage?: string;
  duration?: number;
  startDateTime?: Date;
  shouldShowEntityType?: boolean;
}
export interface ICardListElement<T> {
  contentId: string;
  entityImageShape?: EntityImageShape;
  entitySubtype: EntitySubtype;
  entityType: EntityType;
  isEditMode: boolean;
  isPlayerQueue?: boolean;
  isSelected: boolean;
  item: T;
  itemType: string;
  shouldShowEntityType?: boolean;
  onItemClick?: (id?: string) => void;
  onItemHrefClick?: (id: string) => void;
  onToggleSelected(): void;
  renderEntityRightColumn?: (contentId: string) => JSX.Element;
}

const CardListElement = <T extends ICardListItem>({
  contentId,
  entityType,
  entitySubtype,
  item,
  isEditMode,
  isSelected,
  isPlayerQueue,
  itemType,
  entityImageShape,
  shouldShowEntityType,
  onItemClick,
  onItemHrefClick,
  onToggleSelected,
  renderEntityRightColumn,
}: ICardListElement<T>): JSX.Element => {
  const shouldReduceMotion = useReducedMotion();
  const { startDateTime, episodeUrl, duration, publishDate, showContentId, showUrl, url } = item;

  let image = item.image;
  let title = item.title;
  if (entityType === EntityType.EPISODE) {
    [title = item.title, image] = getEpisodeTitleAndImage(item);
  } else if (entityType === EntityType.STANDALONE_CHAPTER) {
    image = item.parentImage;
  }

  const optionsMenu = useOptionsMenu({
    contentId,
    entityType,
    entitySubtype,
    showContentId,
    title,
    url,
    parentShowUrl: showUrl,
    parentEpisodeUrl: episodeUrl,
    hasExternalActions: !!renderEntityRightColumn,
  });

  const trackAnalytics = useNavClickEvent({
    contentId: item.id,
    entityType,
    entitySubtype,
    feature: ClickFeature.LIST_ITEM,
  });

  let playContext: IPlayContext;

  if (itemType === FollowsCategoryTabStrings.Playlists || entityType === EntityType.COLLECTION) {
    playContext = {
      feature: ClickFeature.PLAYLIST,
      playlistId: item?.id,
    };
  } else {
    playContext = {
      feature: ClickFeature.LIST_ITEM,
    };
  }

  const cardProps = {
    ...item,
    isPlayable: isPlayable(item.entityType),
    duration,
    startDateTime,
    imageURIBase: image,
    entitySubtype: item.entitySubtype,
    publishDate,
    title,
    contentId: item.id,
    optionsMenu,
    isPlayerQueue,
    href: url,
    entityImageShape,
    shouldShowEntityType,
    renderEntityRightColumn,
  };

  const renderCheckbox = () => (
    <AnimatePresence>
      {isEditMode && (
        <motion.span
          variants={reduceVariantMotion(itemVariants, shouldReduceMotion)}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Checkmark checkmarkCss={styles.checkmark} iconSizeInPx={6} isChecked={isSelected} />
        </motion.span>
      )}
    </AnimatePresence>
  );

  if (!isEditMode) {
    return (
      <HorizontalEntityCard
        {...cardProps}
        onPlayStateClick={onItemClick ? () => onItemClick(contentId) : undefined}
        onHrefClick={() => {
          trackAnalytics();
          onItemHrefClick && onItemHrefClick(item.id);
        }}
        playContext={playContext}
      />
    );
  }

  return (
    <HorizontalEntityCard
      {...cardProps}
      href={undefined} // In edit mode, card should not have a clickable anchor tag
      isEditMode
      isEditModeSelected={isSelected}
      onClick={onToggleSelected}
      containerCss={styles.editableContainer}
      renderCheckbox={renderCheckbox}
      playContext={playContext}
    />
  );
};

export default CardListElement;
