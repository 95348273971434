import Episode from './Episode';
import Show from './Show';
import SimpleObject from './SimpleObject';
import Station from './Station';
import Song from './Song';
import Tag from './Tag';
import StandaloneChapter from './StandaloneChapter';

/**
 * @typedef {Function} PagedListQueryFunction
 * @property {Number} page
 * @returns {Object}
 */

/**
 * @module PagedList
 */
export default class PagedList extends SimpleObject {
  /**
   * Creates a new instance of a PagedList.
   * @param {String}                  version
   * @param {Object}                  result
   * @param {Number}                 [result.code]
   * @param {Number}                  result.page
   * @param {Number}                  result.pageSize
   * @param {Number}                  result.total
   * @param {Object}                  result.results
   * @param {DataServices}            dataService
   * @param {ObjectType}              type
   * @param {PagedListQueryFunction}  queryFunction
   */
  constructor(version, result, dataService, type, queryFunction) {
    super(type);

    this.version = version;
    this.dataService = dataService;
    this.queryFunction = queryFunction;
    this.data = [];
    this.pageMap = [];
    this.total = 0;

    if (result && result.code >= 400) result = undefined;

    if (result) {
      this.pageSize = result.pageSize || 20; // TODO: [CCS-2793] remove 20 when result contains pageSize from the server
      this.total = result.total;

      const page = result.page;
      const array = result.results;
      const index = page * this.pageSize;

      this.pageMap[page] = true;

      for (let i = 0; i < array.length; i++) this.data[index + i] = array[i];
    }
  }

  createObject(data) {
    let retval;

    // TODO: [CCS-2304]: Strings should be enums
    if (data) {
      if (data.entityType === 'SHOW') retval = new Show(data);
      else if (data.entityType === 'STATION') retval = new Station(data);
      else if (data.entityType === 'EPISODE') retval = new Episode(data);
      else if (data.entityType === 'SONG') retval = new Song(data);
      else if (data.entityType === 'TAG') retval = new Tag(data);
      else if (data.entityType === 'CHAPTER') retval = new StandaloneChapter(data);
    }

    return retval;
  }

  getAllObjects() {
    const retval = [];
    const length = this.data.length;

    for (let i = 0; i < length; i++) {
      if (this.data[i] !== undefined) {
        const obj = this.getObjectSync(i);

        if (obj) retval.push(obj);
      }
    }

    return retval;
  }

  /**
   * Get the number of search results.
   * @returns {Number}
   */
  getNum() {
    return this.total;
  }

  /**
   * Get the number of search results.
   * @returns {Number}
   */
  getNumInCore() {
    let retval = 0;

    for (let i = 0; i < this.data.length; i++) if (this.data[i] !== undefined) retval++;

    return retval;
  }

  /**
   * @param {Number} index
   * @returns {Promise<Episode|Show|Station|Song|Tag>}
   */
  getObject(index) {
    const resolver = (resolve, reject) => {
      const item = this.data[index];

      if (item === undefined) {
        const page = (index / this.pageSize) | 0;

        if (this.queryFunction === undefined) reject();
        else
          this.queryFunction(page).then((result) => {
            this.patchDataSet(result);

            if (this.data[index]) resolve(this.createObject(this.data[index]));
            else reject();
          });
      } else resolve(this.createObject(item));
    };

    return new Promise(resolver);
  }

  /**
   * @param {Number} index
   * @returns {Episode|Show|Station|Song|Tag|undefined}
   */
  getObjectSync(index) {
    return this.createObject(this.data[index]);
  }

  patchDataSet(list) {
    if (list) {
      const page = list.pageMap.indexOf(true);
      const array = list.data;
      const index = page * this.pageSize;

      this.total = list.total;
      this.pageMap[page] = true;

      for (let i = index; i < array.length; i++) this.data[i] = array[i];
    }
  }

  /**
   * @param {Number} startIndex
   * @param {Number} endIndex
   * @returns {Promise}
   */
  prefetch(startIndex, endIndex) {
    const needMap = [];
    const promises = [];

    for (let i = startIndex; i <= endIndex; i++) {
      const page = (i / this.pageSize) | 0;

      if (needMap.indexOf(page) === -1 && this.pageMap[page] !== true) {
        needMap.push(page);
        promises.push(this.queryFunction(page));
      }
    }

    return Promise.all(promises).then((lists) => {
      for (let i = 0; i < lists.length; i++) this.patchDataSet(lists[i]);
    });
  }
}
