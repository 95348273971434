import { formatPlayingUntil } from '@audacy-clients/core/utils/date';
import { useTranslation } from 'react-i18next';

import { IEntitySummaryProps } from '~/components/Entity/types';
import Icon from '~/components/Icon';
import { Icons } from '~/components/Icon/constants';
import { useBreakpoints } from '~/hooks/use-breakpoints';

import styles from './styles';

const Live = ({
  ctaColor,
  liveUntil,
  isPlaying,
  resumePoint,
  isScheduleItem,
}: IEntitySummaryProps): JSX.Element => {
  const { t } = useTranslation();
  const playingUntil = liveUntil?.playingUntil;
  const { is } = useBreakpoints();
  const iconSize = is.LG ? 24 : 20;

  // Limit the container dimension and apply a text fill gradient when maxWidth is passed.
  return (
    <div css={[styles.container, ctaColor && { color: ctaColor }]}>
      {isPlaying ? (
        <div css={styles.gapContainer}>
          <Icon name={Icons.PauseCircle} sizeInPx={iconSize} />
          <p css={styles.text}>{t('durationSummary.playingLive')}</p>
        </div>
      ) : (
        <div css={styles.gapContainer}>
          <Icon name={Icons.PlayMini} sizeInPx={iconSize} />
          {resumePoint ? (
            <span css={styles.text}>
              {isScheduleItem
                ? t('entity.entitySummary.live.listen')
                : t('entity.entitySummary.live.resume')}
            </span>
          ) : (
            <>
              {!playingUntil ? (
                <span css={styles.text}>{t('entity.entitySummary.live.listen')}</span>
              ) : (
                <span css={styles.text}>
                  {t('entity.entitySummary.live.playingUntil', {
                    time: formatPlayingUntil(playingUntil),
                  })}
                </span>
              )}
            </>
          )}
        </div>
      )}
      <Icon name={Icons.LiveSM} sizeInPx={16} />
    </div>
  );
};

export default Live;
