import { link } from '~/components/Link/styles';
import { Colors, mqHover, Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  link: {
    ...link,
    ...Typography.bodySmall,
    '&:active': {
      color: Colors.white15,
    },
    textAlign: 'left',
  },
  secondaryLink: {
    ...Typography.metadataSmall,
    color: Colors.white60,
    ...mqHover({
      color: Colors.white,
    }),
    textDecoration: 'underline',
  },
};

export default styles;
