import { IAutoHomeWithSummary } from '@audacy-clients/client-services/src/personalizationServices/types';
import { getMarketIdsString } from '@audacy-clients/core/atoms/helpers/markets';
import refreshableSelector from '@audacy-clients/core/atoms/helpers/refreshable';
import { locationMarketsSelector } from '@audacy-clients/core/atoms/location';
import { wrapContentSummary } from '@audacy-clients/core/atoms/wrappers/content';
import { IContentSummary } from '@audacy-clients/core/atoms/wrappers/types';
import clientServices from '@audacy-clients/core/utils/clientServices';

export type IAutoHomeSection = Omit<IAutoHomeWithSummary, 'items'> & {
  items: IContentSummary[];
};

let currentAutoHomePromise: Promise<IAutoHomeSection[]> | undefined;

const getAutoHomeTab = async (marketIds?: string): Promise<IAutoHomeSection[]> =>
  await clientServices
    .getPersonalizationServices()
    .getAutoHome(marketIds)
    .then((entries) =>
      entries.map((entry) => ({
        ...entry,
        items: entry.items.map((item) => ({
          ...wrapContentSummary(item.content),
          lastListenDateTime: item.timestamp,
        })),
      })),
    );

export const {
  useRefreshCache: useRefreshHomeTab,
  useWrappedCachedValue: useCachedHomeTab,
  selector: autoHomeTabSelector,
  forceRefresh: forceRefreshAutoHomeTab,
} = refreshableSelector<IAutoHomeSection[]>({
  get: async ({ get }) => {
    try {
      const { markets } = get(locationMarketsSelector);
      const marketsString = getMarketIdsString(markets);

      currentAutoHomePromise = getAutoHomeTab(marketsString);
      return await currentAutoHomePromise;
    } finally {
      currentAutoHomePromise = undefined;
    }
  },
  key: 'AutoHomeTab',
});
