export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Detail</title>
    <path
      d="M25 9.5V29H7V3h12m6 6.5h-6V3m6 6.5L19 3M11.75 19.25h8.5M11.75 23.25h8.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);
