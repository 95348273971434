import { LocalStorageKey } from '@audacy-clients/client-services/core';
import {
  getFixedResumePoint,
  isConsideredPlayed,
} from '@audacy-clients/client-services/src/utils/playbackResumePoints';
import { collectionQuery, useCollectionItems } from '@audacy-clients/core/atoms/collections';
import { playbackResumePointsState } from '@audacy-clients/core/atoms/playbackResumePoints';
import { nowPlaying } from '@audacy-clients/core/atoms/player';
import { useClientServices } from '@audacy-clients/core/utils/clientServices';
import { checkContentPosition } from '@audacy-clients/core/utils/playlist';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

export interface IPlaylistBarPropsResult {
  lastSelectedPlaylistId?: string;
  navigateToPlaylistUrl?: string;
  nextUpTitle?: string;
  playlistImage?: string;
  playlistImageAlt?: string;
  playlistTitle?: string;
  showPlaylistBar: boolean;
}

export const usePlaylistBarProps = (): IPlaylistBarPropsResult => {
  const [collectionId, setCollectionId] = useState<string | null>(null);
  const { clientServices, loading } = useClientServices();
  const playbackResumePoints = useRecoilValue(playbackResumePointsState);

  useEffect(() => {
    let mounted = true;

    const getStoredCollectionId = () => {
      const storedCollectionId = clientServices
        .getPersonalizationServices()
        .dataStore.getDataSync<string>(LocalStorageKey.COLLECTION_ID);

      if (storedCollectionId && mounted) {
        setCollectionId(storedCollectionId);
      }
    };

    if (mounted && !loading) {
      getStoredCollectionId();
    }

    return () => {
      mounted = false;
    };
  }, [clientServices, loading]);

  const currentContent = useRecoilValue(nowPlaying);
  const collection = useRecoilValue(collectionQuery(collectionId ?? ''));
  const playlistItems = useCollectionItems(collection?.itemsIdList ?? []);

  const { isLast, currentIdx } = checkContentPosition(playlistItems ?? [], currentContent);

  const nextPlayableIndex = useMemo(
    () =>
      playlistItems?.findIndex((item, index) => {
        const playbackOffset =
          getFixedResumePoint(playbackResumePoints?.[item.id], item.startDateTime) ?? 0;

        return index > currentIdx && !isConsideredPlayed(item.duration, playbackOffset);
      }),
    [playlistItems, playbackResumePoints, currentIdx],
  );

  const nextUpTitle =
    isLast || nextPlayableIndex === undefined
      ? undefined
      : playlistItems?.[nextPlayableIndex]?.title;

  return useMemo(
    () => ({
      showPlaylistBar: currentIdx !== -1,
      nextUpTitle,
      playlistImage: collection?.image,
      playlistImageAlt: collection?.imageAlt,
      playlistTitle: collection?.title,
      navigateToPlaylistUrl: collection?.url,
    }),
    [collection, nextUpTitle, currentIdx],
  );
};
