export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Thumbs up</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.421 5.132 14.895 5l-.29.459-5.524 8.746H3v12.468h23.421l.093-.644 1.915-13.361.122-.857H19.82v-5.58l-.568-.142-3.83-.957Zm-5.176 20.041H25.12l1.7-11.862H18.319V7.402l-2.735-.684-5.34 8.454v10.001Zm-1.5 0v-9.468H4.5v9.468h4.245Z"
      fill="currentColor"
    />
  </svg>
);
