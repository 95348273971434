/**
 * This file is generated by ./scripts/mapStationIdToPrtId.ts
 * Do not edit it by hand or your changes will be lost next time we run the script
 */

export const stationIdToPrtId = {
  984: '7070',
  1017: '6860',
  449: '7069',
  2354: '6738',
  888: '6704',
  905: '7031',
  907: '6839',
  473: '6964',
  445: '7091',
  2572: '6927',
  657: '6850',
  1013: '7051',
  1130: '6902',
  487: '6695',
  935: '6691',
  954: '6965',
  3713: '6725',
  993: '6831',
  1035: '6817',
  1034: '6853',
  1036: '6859',
  1037: '6971',
  1038: '6740',
  419: '6953',
  1007: '6954',
  503: '6997',
  501: '6897',
  393: '7077',
  315: '6955',
  399: '7043',
  493: '6925',
  321: '6976',
  4233: '6801',
  383: '7030',
  1127: '6742',
  581: '6980',
  357: '6931',
  1051: '6803',
  401: '6880',
  325: '6881',
  887: '7063',
  916: '6876',
  918: '6941',
  3378: '7037',
  407: '6884',
  499: '6966',
  497: '6903',
  387: '6708',
  441: '6999',
  439: '6823',
  437: '6828',
  955: '6888',
  950: '6957',
  971: '6921',
  975: '7059',
  981: '7062',
  946: '6924',
  947: '6851',
  977: '6968',
  982: '6933',
  985: '6993',
  457: '6991',
  459: '6970',
  429: '6936',
  373: '7023',
  1098: '6866',
  599: '6867',
  617: '6975',
  653: '6837',
  615: '6977',
  297: '6906',
  919: '7053',
  921: '7032',
  934: '6917',
  942: '6988',
  593: '7003',
  561: '6932',
  847: '6841',
  539: '7034',
  783: '6755',
  601: '7093',
  293: '6974',
  1102: '6845',
  1226: '6809',
  299: '7083',
  349: '6942',
  545: '7007',
  323: '6944',
  2577: '6734',
  295: '7090',
  964: '6854',
  965: '7039',
  970: '7041',
  956: '6889',
  962: '6901',
  978: '6969',
  4380: '6985',
  838: '6958',
  961: '7050',
  1016: '6818',
  966: '6960',
  839: '6922',
  979: '7013',
  967: '6990',
  1100: '6959',
  995: '6972',
  3268: '6820',
  513: '6930',
  529: '6952',
  771: '6808',
  945: '6885',
  952: '6887',
  959: '6961',
  992: '6852',
  337: '7020',
  1005: '7383',
  335: '7052',
  659: '7010',
  4238: '6878',
  1175: '7076',
  149: '6786',
  1113: '6844',
  969: '6939',
  973: '6923',
  1126: '6819',
  987: '7075',
  1576: '6791',
  988: '6935',
  1577: '6794',
  509: '7057',
  369: '7040',
  1020: '6895',
  1129: '7386',
  1104: '7072',
  1128: '6814',
  341: '6900',
  379: '7042',
  343: '6920',
  841: '7014',
  363: '7026',
  587: '6905',
  553: '7006',
  395: '6875',
  519: '7036',
  353: '6812',
  573: '6919',
  4280: '7160',
  898: '6910',
  1049: '6796',
  913: '6874',
  914: '6805',
  922: '6913',
  929: '6914',
  933: '6916',
  1039: '6928',
  1044: '6896',
  1041: '6857',
  1042: '6858',
  1043: '7079',
  937: '6986',
  940: '6929',
  941: '6987',
  974: '6904',
  904: '6873',
  923: '7033',
  1131: '6811',
  924: '6943',
  301: '6879',
  928: '6945',
  889: '6834',
  890: '6838',
  925: '6981',
  926: '6840',
  1088: '6882',
  931: '6948',
  932: '6883',
  531: '7035',
  1097: '6843',
  637: '6829',
  303: '6871',
  329: '7065',
  920: '6908',
  899: '6909',
  902: '7064',
  1101: '6797',
  908: '6978',
  912: '7028',
  377: '6982',
  583: '6736',
  1176: '6816',
  1177: '6938',
  309: '6937',
  537: '6949',
  3375: '6886',
  3374: '6956',
  1118: '7021',
  511: '7009',
  495: '6967',
  893: '6824',
  894: '6912',
  1048: '6865',
  1000: '6697',
  953: '6813',
  999: '6855',
  1001: '6926',
};
