import { Property } from 'csstype';

import { Breakpoint as BP, mq } from '~/styles/breakpoints';

interface IFontProps {
  fontFamily: Property.FontFamily;
  fontSize: Property.FontSize;
  fontWeight: number;
  letterSpacing: Property.LetterSpacing;
  lineHeight: Property.LineHeight;
}

interface IHeadlineProps {
  headlineLarge: IFontProps;
  headlineMedium: IFontProps;
  headlineSmall: IFontProps;
}

interface ITitleProps {
  titleMisc: IFontProps;
  titleSection: IFontProps;
  titleMainNav: IFontProps;
}

interface IBodyProps {
  bodyLarge: IFontProps;
  bodySmall: IFontProps;
}

interface IMetadataProps {
  metadataLarge: IFontProps;
  metadataSmall: IFontProps;
}

interface IButtonProps {
  buttonLarge: IFontProps;
  buttonSmall: IFontProps;
}

type TTypographyProps = IHeadlineProps & ITitleProps & IBodyProps & IMetadataProps & IButtonProps;

const Constants = {
  fontFamily: {
    maisonNeue: 'Maison Neue, Helvetica Neue, Helvetica, Arial, sans-serif',
    maisonNeueExtended: 'Maison Neue Extended, Helvetica Neue, Helvetica, Arial, sans-serif',
  },
  fontWeight: {
    normal: 400,
    semi: 600,
    bold: 700,
  },
};

const fontStyles = (fontSize: number, lineHeight: number, letterSpacingPercentage = 0) => ({
  fontSize: `${fontSize / 10}rem`,
  lineHeight: lineHeight / fontSize,
  letterSpacing: `${letterSpacingPercentage / 100}em`,
});

export const Headline: IHeadlineProps = {
  headlineLarge: {
    fontFamily: Constants.fontFamily.maisonNeueExtended,
    fontWeight: Constants.fontWeight.bold,
    ...fontStyles(42, 46),
    [mq(BP.Medium)]: {
      ...fontStyles(52, 57, -1),
    },
    [mq(BP.Large)]: {
      ...fontStyles(80, 80, -2),
    },
    [mq(BP.ExtraLarge)]: {
      ...fontStyles(96, 91, -2),
    },
  },
  headlineMedium: {
    fontFamily: Constants.fontFamily.maisonNeueExtended,
    fontWeight: Constants.fontWeight.bold,
    ...fontStyles(34, 37),
    [mq(BP.Medium)]: {
      ...fontStyles(42, 50, -1),
    },
    [mq(BP.Large)]: {
      ...fontStyles(56, 62, -2),
    },
    [mq(BP.ExtraLarge)]: {
      ...fontStyles(64, 70, -2),
    },
  },
  headlineSmall: {
    fontFamily: Constants.fontFamily.maisonNeueExtended,
    fontWeight: Constants.fontWeight.bold,
    ...fontStyles(24, 29),
    [mq(BP.Medium)]: {
      ...fontStyles(32, 38, -1),
    },
    [mq(BP.Large)]: {
      ...fontStyles(36, 43, -1),
    },
    [mq(BP.ExtraLarge)]: {
      ...fontStyles(40, 48, -1),
    },
  },
};

export const Title: ITitleProps = {
  titleMisc: {
    fontFamily: Constants.fontFamily.maisonNeueExtended,
    fontWeight: Constants.fontWeight.bold,
    ...fontStyles(15, 18, 1),
    [mq(BP.Medium)]: {
      ...fontStyles(20, 23, 1),
    },
    [mq(BP.Large)]: {
      ...fontStyles(22, 25, 1),
    },
    [mq(BP.ExtraLarge)]: {
      ...fontStyles(32, 37, 1),
    },
  },
  titleSection: {
    fontFamily: Constants.fontFamily.maisonNeueExtended,
    fontWeight: Constants.fontWeight.semi,
    ...fontStyles(18, 20, 3),
    [mq(BP.ExtraLarge)]: {
      ...fontStyles(24, 26, 3),
    },
  },
  titleMainNav: {
    fontFamily: Constants.fontFamily.maisonNeueExtended,
    fontWeight: Constants.fontWeight.semi,
    ...fontStyles(14, 19, 4),
  },
};

export const Body: IBodyProps = {
  bodyLarge: {
    fontFamily: Constants.fontFamily.maisonNeue,
    fontWeight: Constants.fontWeight.normal,
    ...fontStyles(18, 29, 1),
  },
  bodySmall: {
    fontFamily: Constants.fontFamily.maisonNeue,
    fontWeight: Constants.fontWeight.normal,
    ...fontStyles(15, 24, 1),
  },
};

export const Metadata: IMetadataProps = {
  metadataLarge: {
    fontFamily: Constants.fontFamily.maisonNeue,
    fontWeight: Constants.fontWeight.normal,
    ...fontStyles(15, 18, 1),
  },
  metadataSmall: {
    fontFamily: Constants.fontFamily.maisonNeue,
    fontWeight: Constants.fontWeight.normal,
    ...fontStyles(12, 16, 2),
  },
};

export const Button: IButtonProps = {
  buttonLarge: {
    fontFamily: Constants.fontFamily.maisonNeue,
    fontWeight: Constants.fontWeight.bold,
    ...fontStyles(15, 20, 2),
  },
  buttonSmall: {
    fontFamily: Constants.fontFamily.maisonNeue,
    fontWeight: Constants.fontWeight.bold,
    ...fontStyles(12, 16, 2),
  },
};

export const Typography: TTypographyProps = {
  ...Headline,
  ...Title,
  ...Body,
  ...Metadata,
  ...Button,
};
