import { link } from '~/components/Link/styles';
import { Mixins, Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const Constants = {
  imageSize: 20,
};

const styles: TStyleSheet = {
  link: {
    ...Mixins.accessibleMinButtonSize(),
    ...Typography.metadataSmall,
    ...link,
    gap: 8,
  },
  image: {
    height: Constants.imageSize,
    width: Constants.imageSize,
  },
};

export default styles;
