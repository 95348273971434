import { type TModuleRequest } from '@audacy-clients/core/atoms/modules/requests';
import { lazy } from 'react';
import FullScreenError from '~/components/Error/FullScreenError';
import { PageTypes, getPageType } from '~/routes';
import { useModules } from '~/state/modules';

// Codesplit different page templates
const EpisodePage = lazy(() => import('~/pages/EpisodePage'));
const GenericContentPage = lazy(() => import('~/pages/GenericContentPage'));
const LandingPage = lazy(() => import('~/pages/LandingPage'));
const PlaylistPage = lazy(() => import('~/pages/PlaylistPage'));
const PodcastPage = lazy(() => import('~/pages/PodcastPage'));
const PodcastEpisodesPage = lazy(() => import('~/pages/PodcastPage/PodcastEpisodesPage'));
const SearchResultsPage = lazy(() => import('~/pages/SearchResultsPage'));
const ShowPage = lazy(() => import('~/pages/ShowPage'));
const SportsLeaguePage = lazy(() => import('~/pages/SportsLeaguePage'));
const SportsTeamPage = lazy(() => import('~/pages/SportsTeamPage'));
const StandaloneChapterPage = lazy(() => import('~/pages/StandaloneChapterPage'));
const StationPage = lazy(() => import('~/pages/StationPage'));
const StationSchedulePage = lazy(() => import('~/pages/StationPage/StationSchedulePage'));
const StationSongHistoryPage = lazy(() => import('~/pages/StationPage/StationSongHistoryPage'));
const TopicPage = lazy(() => import('~/pages/TopicPage'));

type TPageComponent =
  | typeof LandingPage
  | typeof PodcastPage
  | typeof PlaylistPage
  | typeof EpisodePage
  | typeof ShowPage
  | typeof StationPage
  | typeof SportsLeaguePage
  | typeof SportsTeamPage
  | typeof GenericContentPage
  | typeof SearchResultsPage
  | typeof TopicPage
  | typeof StandaloneChapterPage;

const pageTemplateMap: Record<PageTypes, TPageComponent> = {
  [PageTypes.LandingPage]: LandingPage,
  [PageTypes.PlaylistPage]: PlaylistPage,
  [PageTypes.PodcastPage]: PodcastPage,
  [PageTypes.PodcastEpisodesPage]: PodcastEpisodesPage,
  [PageTypes.EpisodePage]: EpisodePage,
  [PageTypes.ShowPage]: ShowPage,
  [PageTypes.StationPage]: StationPage,
  [PageTypes.StationSchedulePage]: StationSchedulePage,
  [PageTypes.StationsSongHistoryPage]: StationSongHistoryPage,
  [PageTypes.SportsLeaguePage]: SportsLeaguePage,
  [PageTypes.SportsTeamPage]: SportsTeamPage,
  [PageTypes.GenericContentPage]: GenericContentPage,
  [PageTypes.SearchResultsPage]: SearchResultsPage,
  [PageTypes.TopicPage]: TopicPage,
  [PageTypes.StandaloneChapterPage]: StandaloneChapterPage,
};

type IPageContentProps = {
  request: TModuleRequest;
};

const PageContent = ({ request }: IPageContentProps): JSX.Element => {
  const view = useModules(request);
  let pageTemplateName = PageTypes.LandingPage;

  if (!!view?.rootObject?.type && !!view?.rootObject?.subtype) {
    // entity driven page i.e. podcast, station, etc.
    const { type: entityType, subtype: entitySubtype } = view.rootObject;
    // fall back to GenericContentPage
    pageTemplateName =
      getPageType(view.type, entityType, entitySubtype) || PageTypes.GenericContentPage;
  } else if (view?.type) {
    // landing page i.e. for-you, music, etc.
    // fall back to LandingPage
    pageTemplateName = getPageType(view.type) || PageTypes.LandingPage;
  }

  const PageComponent = pageTemplateMap[pageTemplateName];

  // landing page
  if (pageTemplateName === PageTypes.LandingPage) {
    return <LandingPage request={request} seoMetadata={view.meta} />;
  }

  // search results page
  if (pageTemplateName === PageTypes.SearchResultsPage) {
    return <SearchResultsPage request={request} seoMetadata={view.meta} />;
  }

  // entity driven page
  if (view?.id && view?.rootObject?.id) {
    return (
      <PageComponent
        contentId={view.rootObject.id}
        viewId={view.id}
        request={request}
        seoMetadata={view.meta}
      />
    );
  }

  // fallback to landing page if we have modules to render
  if (view?.modules?.length > 0) {
    return <LandingPage request={request} seoMetadata={view.meta} />;
  }

  // if not a landing page no viewId and contentId are present, and no modules, then show an error:
  console.error('PageLoadError: not a landing page, no viewId and contentId, no modules', {
    pageTemplateName,
    view,
  });
  return <FullScreenError />;
};

export default PageContent;
