import { animationStatusName } from '~/constants/animation';

export const Constants = {
  queueDelay: 0.2,
  infoDelay: 0.4,
};

export const queueContainerVariantsInitial = {
  [animationStatusName.initial]: {
    opacity: 0,
  },
  [animationStatusName.animate]: {
    opacity: 1,
  },
  [animationStatusName.exit]: {
    opacity: 0,
  },
};

export const queueContainerVariantsReady = {
  [animationStatusName.initial]: {
    flexBasis: 0,
    opacity: 0,
  },
  [animationStatusName.animate]: {
    flexBasis: '30%',
    opacity: 1,
    transition: { bounce: 0 },
  },
  [animationStatusName.exit]: {
    flexBasis: 0,
    opacity: 0,
    transition: { bounce: 0 },
  },
};

export const fullPlayerVariantsLg = {
  [animationStatusName.initial]: {
    bottom: 30,
    opacity: 0,
    right: 30,
    width: 500,
    height: 80,
  },
  [animationStatusName.animate]: {
    bottom: 0,
    opacity: 1,
    right: 0,
    y: 0,
    width: '100%',
    height: '100%',
    transition: {
      bounce: 0.1,
      duration: 0.3,
    },
  },
  [animationStatusName.exit]: {
    opacity: 0,
    y: '10%',
    transition: {
      bounce: 0.1,
      duration: 0.3,
    },
  },
};

export const fullPlayerVariantsSm = {
  [animationStatusName.initial]: {
    opacity: 0,
    y: '25%',
  },
  [animationStatusName.animate]: {
    opacity: 1,
    y: 0,
    transition: {
      bounce: 0.1,
      duration: 0.3,
    },
  },
  [animationStatusName.exit]: {
    opacity: 0,
    y: '10%',
    transition: {
      bounce: 0.1,
      duration: 0.3,
    },
  },
};
