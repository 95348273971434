import { useClientServices } from '@audacy-clients/core/utils/clientServices';
import * as braze from '@braze/web-sdk';
import { useRef, useCallback, useEffect } from 'react';
import { env } from '~/utils/env';

const checkLocationPermission = async () => {
  return await navigator.permissions.query({ name: 'geolocation' });
};

type UseLocationPermissionProps = {
  onChange?: ((this: PermissionStatus, ev: Event) => void) | null;
};

type PrintDebugOptions = {
  level: 'log' | 'warn' | 'error';
  force: boolean;
};

const printDebug = (msg: string, data?: unknown, options: Partial<PrintDebugOptions> = {}) => {
  const opts: PrintDebugOptions = {
    level: options.level ?? 'log',
    force: options.force ?? false,
  };

  if ((env.APP_MODE === 'development' && env.ENABLE_DEBUG) ?? opts.force) {
    console[opts.level](msg, data);
  }
};

export const useLocationPermission = ({ onChange }: UseLocationPermissionProps = {}) => {
  printDebug('#! useLocationPermission -> Initializing');

  const locationPermissionStatus = useRef<PermissionStatus>();
  const { clientServices } = useClientServices();

  const setLatLong = useCallback(
    (lat: number, long: number) => {
      clientServices.setLatLong(lat, long);
    },
    [clientServices],
  );

  const requestPermission = useCallback(() => {
    printDebug('#! useLocationPermission -> Requesting location permission');

    navigator.geolocation.getCurrentPosition(
      (position) => {
        printDebug('#! useLocationPermission -> Got location: ', position);
        setLatLong(position.coords.latitude, position.coords.longitude);
      },
      (e) => {
        printDebug('#! useLocationPermission -> Error getting location: ', e, { level: 'error' });
      },
    );
  }, [setLatLong]);

  useEffect(() => {
    printDebug('#! useLocationPermission -> Checking location permission');

    checkLocationPermission()
      .then((locationStatus) => {
        printDebug('#! useLocationPermission -> Location permission status: ', locationStatus);

        locationPermissionStatus.current = locationStatus;
        locationStatus.onchange = onChange ?? null;

        if (locationStatus.state === 'granted') {
          printDebug(
            '#! useLocationPermission -> Location permission granted, requesting location',
          );
          requestPermission();
        }

        if (locationStatus.state === 'prompt') {
          printDebug(
            '#! useLocationPermission -> Sending braze event to prompt user for location permissions',
          );
          braze.logCustomEvent('LOCATION_PROMPT');
        }
      })
      .catch((e) => {
        printDebug('#! useLocationPermission -> Error getting location: ', e, { level: 'error' });
      });

    return () => {
      printDebug('#! useLocationPermission -> Cleaning up');

      if (locationPermissionStatus.current) {
        printDebug('#! useLocationPermission -> Removing onchange listener');
        locationPermissionStatus.current.onchange = null;
      }
    };
  }, [requestPermission, onChange]);

  return { requestPermission };
};
