export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Handle</title>
    <rect opacity=".4" y="14" width="32" height="4" rx="2" fill="currentColor" />
  </svg>
);
