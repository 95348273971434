/* eslint-disable @typescript-eslint/no-namespace, @typescript-eslint/consistent-type-definitions, @typescript-eslint/no-empty-interface */
import { z } from 'zod';

const NodeEnvs = z.enum(['production', 'development', 'test']);
const ApiEnvs = z.enum(['DEV', 'QA', 'STG', 'PROD']);

const booleanString = z
  .string()
  .refine((value) => value === 'true' || value === 'false', {
    message: 'Value must be a boolean',
  })
  .transform((value) => value === 'true');

const numberString = z
  .string()
  .refine((value) => !isNaN(Number(value)), {
    message: 'Value must be a number',
  })
  .transform((value) => Number(value));

export const envSchema = z.object({
  NODE_ENV: NodeEnvs,
  API_ENV: ApiEnvs.default('STG'),
  APP_VERSION: z.string().default(''),
  APP_MODE: NodeEnvs.optional(), // Will default to NODE_ENV if not supplied
  BASE_NAME: z.string().default('/'),
  BRAZE_API_KEY: z.string().default('194a36b8-c85b-4186-8c05-44ac56b6b031'),
  BRAZE_SDK_ENDPOINT: z.string().default('sdk.iad-03.braze.com'),
  BRANCH_API_KEY: z.string().default('key_test_egXpBHlV0yEQBJLIZ9kLuclnwtn9yRzu'),
  GTM_ID: z.string(),
  GTM_AUTH: z.string(),
  GTM_PREVIEW: z.string(),
  DISABLE_BRAZE: z.union([booleanString, z.boolean()]).default(false),
  LOG_LEVEL: z.union([numberString, z.number()]).default(0),
  DISABLE_XSTATE_INSPECTOR: z.union([booleanString, z.boolean()]).default(false),
  ENABLE_DEBUG: z.union([booleanString, z.boolean()]).default(false),
  DATA_DOG_ENV: z.string().default('nonprod-dev'),

  // Additional build-time environment variables
  BUILD_VISUALIZE: z.union([booleanString, z.boolean()]).default(false),
  BUILD_MINIFY: z.union([booleanString, z.boolean()]).default(false),
  BUILD_SOURCEMAP: z.union([booleanString, z.boolean()]).default(false),
});

declare global {
  namespace NodeJS {
    interface ProcessEnv extends z.infer<typeof envSchema> {}
  }
}

export function init(preload?: Record<string, unknown>) {
  const parsed = envSchema.safeParse(preload ?? process.env);

  if (parsed.success === false) {
    console.error('❌ Invalid environment variables:', parsed.error.flatten().fieldErrors);

    throw new Error('Invalid environment variables');
  }
}

export function parseEnv(preload?: Record<string, unknown>) {
  const env = preload ?? process.env;

  return envSchema.parse({
    NODE_ENV: env.NODE_ENV,
    API_ENV: env.API_ENV,
    APP_VERSION: env.APP_VERSION ?? env.npm_package_version,
    APP_MODE: env.APP_MODE ?? env.NODE_ENV,
    BASE_NAME: env.BASE_NAME,
    BRAZE_API_KEY: env.BRAZE_API_KEY,
    BRAZE_SDK_ENDPOINT: env.BRAZE_SDK_ENDPOINT,
    BRANCH_API_KEY: env.BRANCH_API_KEY,
    GTM_ID: env.GTM_ID,
    GTM_AUTH: env.GTM_AUTH,
    GTM_PREVIEW: env.GTM_PREVIEW,
    DISABLE_BRAZE: env.DISABLE_BRAZE,
    LOG_LEVEL: env.LOG_LEVEL,
    DISABLE_XSTATE_INSPECTOR: env.DISABLE_XSTATE_INSPECTOR,
    ENABLE_DEBUG: env.ENABLE_DEBUG,
    DATA_DOG_ENV: env.DATA_DOG_ENV,

    // Additional build-time environment variables
    BUILD_VISUALIZE: env.BUILD_VISUALIZE,
    BUILD_MINIFY: env.BUILD_MINIFY,
    BUILD_SOURCEMAP: env.BUILD_SOURCEMAP,
  });
}

export const env = parseEnv();

type ENV = typeof env;

declare global {
  const ENV: ENV;
  interface Window {
    ENV: ENV;
  }
}
