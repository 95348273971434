import { useEffect } from 'react';

import { locationMarketsState, IMarketsState, IMarket } from '@audacy-clients/core/atoms/location';
import { getClientServices } from '@audacy-clients/core/utils/clientServices';
import { useSetRecoilState } from 'recoil';
import { isEqual } from 'lodash';
import { setBrazeCustomAttribute } from '~/utils/braze';
import { userMarketIDsAttribute } from '@audacy-clients/core/constants/brazeAttributes';

const LocationMarketsStateConnector = (): JSX.Element => {
  const setMarkets = useSetRecoilState(locationMarketsState);
  useEffect(() => {
    const fetchAndSetMarkets = async () => {
      // get fallbacks from local storage and attempt to get markets from experience
      const fallbackMarkets = localStorage.getItem('locationMarketsState');

      // Set markets loading
      setMarkets({ markets: [], isLoading: true });

      const cs = await getClientServices();
      const markets = await cs.getDataServices().getUserMarkets(undefined, undefined, true);

      // set markets, prefer fresh data over cached
      if (markets) {
        setMarkets({ markets, isLoading: false });

        const storedMarkets = JSON.parse(localStorage.getItem('locationMarketsState') || '{ "markets": [] }');
        const marketIDs = markets.map(market => market.marketId);
        const brazeMarketIDs: string[] = storedMarkets?.markets?.map((market: IMarket) => market.marketId);
    
        if (marketIDs.length && !isEqual(marketIDs, brazeMarketIDs)) {
          setBrazeCustomAttribute({
            attributeKey: userMarketIDsAttribute.attributeKey,
            attributeValue: marketIDs
          });
        }

        localStorage.setItem('locationMarketsState', JSON.stringify({ markets }));
      } else if (fallbackMarkets) {
        const fallbackMarketsData = JSON.parse(fallbackMarkets) as IMarketsState;
        fallbackMarketsData.isLoading = false;
        setMarkets(fallbackMarketsData);
      }
    };

    fetchAndSetMarkets();
  }, [setMarkets]);

  return <></>;
};

export default LocationMarketsStateConnector;
