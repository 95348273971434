import { ISongHistoryItem } from '@audacy-clients/client-services/src/Constants';
import { TSongHistoryProps } from '@audacy-clients/core/atoms/songHistory';
import clientServices from '@audacy-clients/core/utils/clientServices';

export const getSongHistory = ({
  stationId,
  limit,
}: TSongHistoryProps): Promise<ISongHistoryItem[]> => {
  return clientServices.getDataServices().getSongHistory(stationId, limit);
};
