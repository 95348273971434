import { useTranslation } from 'react-i18next';

import { Icons } from '~/components/Icon/constants';
import OptionsMenuItem from '~/components/OptionsMenu/components/OptionsMenuItem';

interface IUnfollowMenuItemProps {
  isFollowing?: boolean;
  onClickCallback?: () => void;
  toggleFollow: () => void;
}

const UnfollowMenuItem = ({
  isFollowing,
  onClickCallback,
  toggleFollow,
}: IUnfollowMenuItemProps): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <OptionsMenuItem
      icon={isFollowing ? Icons.MinusCircle : Icons.PlusCircle}
      onClick={() => {
        toggleFollow();
        onClickCallback && onClickCallback();
      }}
      text={isFollowing ? t('optionsMenu.unfollow') : t('optionsMenu.follow')}
    />
  );
};

export default UnfollowMenuItem;
