import MaisonNeueExtendedBlack from '~/assets/fonts/MaisonNeueExtendedWEB-Black.woff';
import MaisonNeueExtendedBlack2 from '~/assets/fonts/MaisonNeueExtendedWEB-Black.woff2';
import MaisonNeueExtendedBlackItalic from '~/assets/fonts/MaisonNeueExtendedWEB-BlackItalic.woff';
import MaisonNeueExtendedBlackItalic2 from '~/assets/fonts/MaisonNeueExtendedWEB-BlackItalic.woff2';
import MaisonNeueExtendedBold from '~/assets/fonts/MaisonNeueExtendedWEB-Bold.woff';
import MaisonNeueExtendedBold2 from '~/assets/fonts/MaisonNeueExtendedWEB-Bold.woff2';
import MaisonNeueExtendedBoldItalic from '~/assets/fonts/MaisonNeueExtendedWEB-BoldItalic.woff';
import MaisonNeueExtendedBoldItalic2 from '~/assets/fonts/MaisonNeueExtendedWEB-BoldItalic.woff2';
import MaisonNeueExtendedBook from '~/assets/fonts/MaisonNeueExtendedWEB-Book.woff';
import MaisonNeueExtendedBook2 from '~/assets/fonts/MaisonNeueExtendedWEB-Book.woff2';
import MaisonNeueExtendedBookItalic from '~/assets/fonts/MaisonNeueExtendedWEB-BookItalic.woff';
import MaisonNeueExtendedBookItalic2 from '~/assets/fonts/MaisonNeueExtendedWEB-BookItalic.woff2';
import MaisonNeueExtendedDemi from '~/assets/fonts/MaisonNeueExtendedWEB-Demi.woff';
import MaisonNeueExtendedDemi2 from '~/assets/fonts/MaisonNeueExtendedWEB-Demi.woff2';
import MaisonNeueExtendedDemiItalic from '~/assets/fonts/MaisonNeueExtendedWEB-DemiItalic.woff';
import MaisonNeueExtendedDemiItalic2 from '~/assets/fonts/MaisonNeueExtendedWEB-DemiItalic.woff2';
import MaisonNeueExtendedExtraBold from '~/assets/fonts/MaisonNeueExtendedWEB-ExtraBold.woff';
import MaisonNeueExtendedExtraBold2 from '~/assets/fonts/MaisonNeueExtendedWEB-ExtraBold.woff2';
import MaisonNeueExtendedExtraBoldItalic from '~/assets/fonts/MaisonNeueExtendedWEB-ExtraBoldItalic.woff';
import MaisonNeueExtendedExtraBoldItalic2 from '~/assets/fonts/MaisonNeueExtendedWEB-ExtraBoldItalic.woff2';
import MaisonNeueExtendedExtraThin from '~/assets/fonts/MaisonNeueExtendedWEB-ExtraThin.woff';
import MaisonNeueExtendedExtraThin2 from '~/assets/fonts/MaisonNeueExtendedWEB-ExtraThin.woff2';
import MaisonNeueExtendedExtraThinItalic from '~/assets/fonts/MaisonNeueExtendedWEB-ExtraThinItalic.woff';
import MaisonNeueExtendedExtraThinItalic2 from '~/assets/fonts/MaisonNeueExtendedWEB-ExtraThinItalic.woff2';
import MaisonNeueExtendedLight from '~/assets/fonts/MaisonNeueExtendedWEB-Light.woff';
import MaisonNeueExtendedLight2 from '~/assets/fonts/MaisonNeueExtendedWEB-Light.woff2';
import MaisonNeueExtendedLightItalic from '~/assets/fonts/MaisonNeueExtendedWEB-LightItalic.woff';
import MaisonNeueExtendedLightItalic2 from '~/assets/fonts/MaisonNeueExtendedWEB-LightItalic.woff2';
import MaisonNeueExtendedMedium from '~/assets/fonts/MaisonNeueExtendedWEB-Medium.woff';
import MaisonNeueExtendedMedium2 from '~/assets/fonts/MaisonNeueExtendedWEB-Medium.woff2';
import MaisonNeueExtendedMediumItalic from '~/assets/fonts/MaisonNeueExtendedWEB-MediumItalic.woff';
import MaisonNeueExtendedMediumItalic2 from '~/assets/fonts/MaisonNeueExtendedWEB-MediumItalic.woff2';
import MaisonNeueExtendedThin from '~/assets/fonts/MaisonNeueExtendedWEB-Thin.woff';
import MaisonNeueExtendedThin2 from '~/assets/fonts/MaisonNeueExtendedWEB-Thin.woff2';
import MaisonNeueExtendedThinItalic from '~/assets/fonts/MaisonNeueExtendedWEB-ThinItalic.woff';
import MaisonNeueExtendedThinItalic2 from '~/assets/fonts/MaisonNeueExtendedWEB-ThinItalic.woff2';
import MaisonNeueBold from '~/assets/fonts/MaisonNeueWEB-Bold.woff';
import MaisonNeueBold2 from '~/assets/fonts/MaisonNeueWEB-Bold.woff2';
import MaisonNeueBoldItalic from '~/assets/fonts/MaisonNeueWEB-BoldItalic.woff';
import MaisonNeueBoldItalic2 from '~/assets/fonts/MaisonNeueWEB-BoldItalic.woff2';
import MaisonNeueBook from '~/assets/fonts/MaisonNeueWEB-Book.woff';
import MaisonNeueBook2 from '~/assets/fonts/MaisonNeueWEB-Book.woff2';
import MaisonNeueBookItalic from '~/assets/fonts/MaisonNeueWEB-BookItalic.woff';
import MaisonNeueBookItalic2 from '~/assets/fonts/MaisonNeueWEB-BookItalic.woff2';

interface IFontProps {
  fontDisplay?: string;
  fontFamily: string;
  fontStyle: string;
  fontWeight: string;
  source: string;
  source2: string;
}

const fontFace = ({
  fontDisplay,
  fontFamily,
  fontStyle,
  fontWeight,
  source,
  source2,
}: IFontProps): string => {
  return `
    @font-face {
      ${fontDisplay && `font-display: ${fontDisplay};`}
      font-family: "${fontFamily}";
      font-style: ${fontStyle};
      font-weight: ${fontWeight};
      src:
        url('${source2}') format('woff2'),
        url('${source}') format('woff')
    }
  `;
};

export const fonts = `
  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue',
    fontStyle: 'normal',
    fontWeight: 'normal',
    source: MaisonNeueBook,
    source2: MaisonNeueBook2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue',
    fontStyle: 'italic',
    fontWeight: 'normal',
    source: MaisonNeueBookItalic,
    source2: MaisonNeueBookItalic2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue',
    fontStyle: 'normal',
    fontWeight: '700',
    source: MaisonNeueBold,
    source2: MaisonNeueBold2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue',
    fontStyle: 'italic',
    fontWeight: '700',
    source: MaisonNeueBoldItalic,
    source2: MaisonNeueBoldItalic2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue Extended',
    fontStyle: 'normal',
    fontWeight: '100',
    source: MaisonNeueExtendedExtraThin,
    source2: MaisonNeueExtendedExtraThin2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue Extended',
    fontStyle: 'italic',
    fontWeight: '100',
    source: MaisonNeueExtendedExtraThinItalic,
    source2: MaisonNeueExtendedExtraThinItalic2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue Extended',
    fontStyle: 'normal',
    fontWeight: '200',
    source: MaisonNeueExtendedThin,
    source2: MaisonNeueExtendedThin2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue Extended',
    fontStyle: 'italic',
    fontWeight: '200',
    source: MaisonNeueExtendedThinItalic,
    source2: MaisonNeueExtendedThinItalic2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue Extended',
    fontStyle: 'normal',
    fontWeight: '300',
    source: MaisonNeueExtendedLight,
    source2: MaisonNeueExtendedLight2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue Extended',
    fontStyle: 'italic',
    fontWeight: '300',
    source: MaisonNeueExtendedLightItalic,
    source2: MaisonNeueExtendedLightItalic2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue Extended',
    fontStyle: 'normal',
    fontWeight: 'normal',
    source: MaisonNeueExtendedBook,
    source2: MaisonNeueExtendedBook2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue Extended',
    fontStyle: 'italic',
    fontWeight: 'normal',
    source: MaisonNeueExtendedBookItalic,
    source2: MaisonNeueExtendedBookItalic2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue Extended',
    fontStyle: 'normal',
    fontWeight: '500',
    source: MaisonNeueExtendedMedium,
    source2: MaisonNeueExtendedMedium2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue Extended',
    fontStyle: 'italic',
    fontWeight: '500',
    source: MaisonNeueExtendedMediumItalic,
    source2: MaisonNeueExtendedMediumItalic2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue Extended',
    fontStyle: 'normal',
    fontWeight: '600',
    source: MaisonNeueExtendedDemi,
    source2: MaisonNeueExtendedDemi2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue Extended',
    fontStyle: 'italic',
    fontWeight: '600',
    source: MaisonNeueExtendedDemiItalic,
    source2: MaisonNeueExtendedDemiItalic2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue Extended',
    fontStyle: 'normal',
    fontWeight: '700',
    source: MaisonNeueExtendedBold,
    source2: MaisonNeueExtendedBold2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue Extended',
    fontStyle: 'italic',
    fontWeight: '700',
    source: MaisonNeueExtendedBoldItalic,
    source2: MaisonNeueExtendedBoldItalic2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue Extended',
    fontStyle: 'normal',
    fontWeight: '800',
    source: MaisonNeueExtendedExtraBold,
    source2: MaisonNeueExtendedExtraBold2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue Extended',
    fontStyle: 'italic',
    fontWeight: '800',
    source: MaisonNeueExtendedExtraBoldItalic,
    source2: MaisonNeueExtendedExtraBoldItalic2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue Extended',
    fontStyle: 'normal',
    fontWeight: '900',
    source: MaisonNeueExtendedBlack,
    source2: MaisonNeueExtendedBlack2,
  })}

  ${fontFace({
    fontDisplay: 'swap',
    fontFamily: 'Maison Neue Extended',
    fontStyle: 'italic',
    fontWeight: '900',
    source: MaisonNeueExtendedBlackItalic,
    source2: MaisonNeueExtendedBlackItalic2,
  })}
`;

export const globalFont = `
  body {
    font-family: "Maison Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
  }
`;
