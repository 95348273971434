import { ModuleType } from '@audacy-clients/client-services/core';
import { type TModuleMapping } from '@audacy-clients/core/components/ModuleRenderer/types';

import ModuleCard from '~/components/ModuleRenderer/modules/ModuleCard';
import ModuleCarousel from '~/components/ModuleRenderer/modules/ModuleCarousel';
import ModuleContinueListening from '~/components/ModuleRenderer/modules/ModuleContinueListening';
import ModuleEarlierToday from '~/components/ModuleRenderer/modules/ModuleEarlierToday';
import ModuleList from '~/components/ModuleRenderer/modules/ModuleList';
import ModuleLiveNow from '~/components/ModuleRenderer/modules/ModuleLiveNow';
import ModulePlaylistItemList from '~/components/ModuleRenderer/modules/ModulePlaylistItemList';
import ModuleRecentlyPlayedStations from '~/components/ModuleRenderer/modules/ModuleRecentlyPlayedStations';
import ModuleSectionWrapper from '~/components/ModuleRenderer/modules/ModuleSectionWrapper';
import ModuleShowLiveNowAndPreviousEpisode from '~/components/ModuleRenderer/modules/ModuleShowLiveNowAndPreviousEpisode';
import ModuleSongHistory from '~/components/ModuleRenderer/modules/ModuleSongHistory';
import ModuleSongHistoryPreview from '~/components/ModuleRenderer/modules/ModuleSongHistoryPreview';
import ModuleViewTitle from '~/components/ModuleRenderer/modules/ModuleViewTitle';

import ModuleBannerAd from './modules/ModuleBannerAd';
import ModuleSquareAd from './modules/ModuleSquareAd';
import ModuleStationContinueListening from './modules/ModuleStationContinueListening';

export const moduleMap: TModuleMapping = new Map([
  [ModuleType.CONTINUE_LISTENING, ModuleContinueListening],
  [ModuleType.CAROUSEL, ModuleCarousel],
  [ModuleType.RECENTLY_PLAYED_STATIONS, ModuleRecentlyPlayedStations],
  [ModuleType.SECTION_WRAPPER, ModuleSectionWrapper],
  [ModuleType.SAFE_SECTION_WRAPPER, ModuleSectionWrapper],
  [ModuleType.STATION_LIVE_NOW, ModuleLiveNow],
  [ModuleType.SHOW_LIVE_NOW_AND_PREV, ModuleShowLiveNowAndPreviousEpisode],
  [ModuleType.EARLIER_TODAY, ModuleEarlierToday],
  [ModuleType.VIEW_TITLE, ModuleViewTitle],
  [ModuleType.ENTITY_CARD_FEATURED, ModuleCard],
  [ModuleType.ENTITY_CARD_HORIZONTAL, ModuleCard],
  [ModuleType.ENTITY_CARD_IMAGE_GROUP, ModuleCard],
  [ModuleType.ENTITY_CARD_VERTICAL, ModuleCard],
  [ModuleType.LIST, ModuleList],
  [ModuleType.SONG_HISTORY, ModuleSongHistory],
  [ModuleType.PREVIEW_SONG_HISTORY, ModuleSongHistoryPreview],
  [ModuleType.PLAYLIST_ITEM_LIST, ModulePlaylistItemList],
  [ModuleType.COLLECTION_PLAYLIST_CARD, ModuleCard],
  [ModuleType.BANNER_AD, ModuleBannerAd],
  [ModuleType.SQUARE_AD, ModuleSquareAd],
  [ModuleType.PICKUP_LEFT_OFF, ModuleStationContinueListening],
]);
