interface ITimingProps {
  [key: string]: number;
}

const timingValues: ITimingProps = {
  ms0: 0,
  ms250: 0.25,
  ms400: 0.4,
  ms500: 0.5,
};

export const Timing: ITimingProps = {
  none: timingValues.ms0,
  default: timingValues.ms250,
  navigationMenuOpen: timingValues.ms400,
  navigationPanel: timingValues.ms250,
  menuTooltip: timingValues.ms500,
  overlay: timingValues.ms500,
  drawer: timingValues.ms500,
  pageTransitionIn: timingValues.ms250,
};
