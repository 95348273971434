import isEqual from 'lodash/isEqual';
import {
  Loadable,
  ReadOnlySelectorOptions,
  RecoilState,
  RecoilValue,
  RecoilValueReadOnly,
  WrappedValue,
} from 'recoil';

import { noCacheDefaultSelector } from './noCacheDefaultSelector';

/**
 * A selector but doesn't rerender when the new object is deep equal to the old one
 */
export const deepEqualSelector = <T>({
  get,
  key,
}: ReadOnlySelectorOptions<T>): RecoilValueReadOnly<T> => {
  let cache: T | Promise<T> | RecoilValue<T> | Loadable<T> | WrappedValue<T>;

  return noCacheDefaultSelector({
    get: (getOptions) => {
      const newResult = get(getOptions);

      if (cache !== undefined && isEqual(cache, newResult)) {
        return cache;
      }

      cache = newResult;

      return newResult;
    },
    key,
  });
};

/*
 * Wrap an atom with this to avoid rerending when the new object is deep equal to the old one
 * (Not the recoil way of doing things, just keeping previous behavior)
 */
export const deepEqualAtomWrapper = <T>(state: RecoilState<T>): RecoilState<T> =>
  noCacheDefaultSelector<T>({
    get({ get }) {
      return get(state);
    },
    key: `${state.key}_Deep_Compare`,
    set({ get, set }, newValue) {
      const oldValue = get(state);
      if (!isEqual(oldValue, newValue)) {
        set(state, newValue);
      }
    },
  });
