export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Play</title>
    <path d="M29 16L9.5 27.2583L9.5 4.74167L29 16Z" fill="currentColor" />
  </svg>
);
