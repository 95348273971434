import { IChapter } from '@audacy-clients/core/atoms/wrappers/chapter';

/**
 * Determines which chapters to show when the chapter listing is collapsed, and returns indexes for playing
 * and live chapters which are relative to `chaptersToShow` rather than the full `chapters` array
 */
export const getChaptersToShowConfig = (
  showAll: boolean,
  chapters: IChapter[],
  maxItems: number,
  playingIndex: number,
  liveIndex: number,
): {
  chaptersToShow: IChapter[];
  relativeLiveIndex: number;
  relativePlayingIndex: number;
} => {
  // By default we show the first chapters of the episode, so the relative indexes are the same as the chapter indexes
  let relativePlayingIndex = playingIndex;
  let relativeLiveIndex = liveIndex;

  if (!showAll && maxItems !== 0) {
    const lastVisibleIndex = maxItems - 1;

    let startIndex = 0;
    let endIndex = maxItems;

    // If the currently playing chapter is not one of the first chapters, we need to alter the chapters which are
    // displayed to make sure that it's visible. The currently playing chapter becomes the last in the array, and
    // we display the ones before it.
    if (playingIndex > lastVisibleIndex) {
      endIndex = playingIndex + 1;
      startIndex = endIndex - maxItems;
      relativeLiveIndex =
        liveIndex < startIndex || liveIndex > playingIndex ? -1 : liveIndex - startIndex;
      relativePlayingIndex = playingIndex - startIndex;

      // If nothing is playing but a chapter is live, we make sure that the live chapter is visible
    } else if (playingIndex === -1 && liveIndex > lastVisibleIndex) {
      endIndex = liveIndex + 1;
      startIndex = endIndex - maxItems;
      relativeLiveIndex = liveIndex - startIndex;
    }

    return {
      chaptersToShow: chapters.slice(startIndex, endIndex),
      relativeLiveIndex,
      relativePlayingIndex,
    };
  }

  return {
    chaptersToShow: chapters,
    relativeLiveIndex,
    relativePlayingIndex,
  };
};
