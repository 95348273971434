import { clamp, isUndefined } from 'lodash';
import URI from 'urijs';
import { createMachine, assign, sendParent } from 'xstate';
import AudacyLogger, { LoggerTopic } from '../../../AudacyLogger';
import clientServicesConfig from '../../../Config';
import {
  EntityType,
  EpisodeSubType,
  PlayerAction,
  PlayerMetric,
  SaveResumePointTimings,
} from '../../../Constants';
import type Chapter from '../../../dataServices/Chapter';
import Episode from '../../../dataServices/Episode';
import { handleErrorAction, handleTimeoutAction } from '../../../utils';
import { getGppString } from '../../../utils/gpp-util';
import { type TStreamerMachineEvent, EContentType } from '../../types';
import { AUTO_TRANSITION, COMMON_ACTIONS, LOADING_TIMEOUT, MAX_RETRY_ATTEMPTS } from '../constants';
import { type IStreamerMachineContext, type TStreamSelector } from '../types';
import {
  DEFAULT_PLAYER_ACTIONS,
  getExponentialBackoffDelay,
  getFirstAudioSourceUrl,
  toIntegerOrUndefined,
} from '../utils';

export const PODCAST_MACHINE_ID = 'podcastEpisodeMachine';

// Test station slugs, e.g. /podcast/hidden-brain-4d4d1, on STG backend:
// hidden-brain-4d4d1, the-joe-rogan-experience-cc8ef, etc.

// TODO: [CCS-2784] ensure that all assigns are cleaned up

const { RESUME_POINT_INTERVAL_MSEC } = SaveResumePointTimings;

const getEpisodeInfo = async (
  ctx: IStreamerMachineContext<Episode>,
): Promise<{
  chapters: Chapter[];
  requestedOffset?: number;
}> => {
  const chaptersPromise = ctx.item.getChapters({
    broadcastChapters: ctx.featureFlags?.broadcastChapters,
  });
  const playbacksPromise = ctx.personalizationProvider.getPlaybacks();

  const chapters = await chaptersPromise;
  const playbacks = await playbacksPromise;

  const requestedOffset = playbacks[ctx.item.getId()];

  return { chapters, requestedOffset };
};

type TPodcastEpisodeMachineServices = {
  getEpisodeInfo: {
    data: Awaited<ReturnType<typeof getEpisodeInfo>>;
  };
};

const saveResumePoints = (context: IStreamerMachineContext<Episode>, shouldSaveOnServer = true) => {
  const { elapsed, personalizationProvider, item } = context;
  if (elapsed === undefined) {
    return;
  }
  personalizationProvider.setPlayback(item.getId(), elapsed, shouldSaveOnServer);
};
const podcastEpisodeMachine = createMachine(
  {
    predictableActionArguments: true,
    id: 'podcastEpisodeMachine',
    initial: 'LOADING',
    tsTypes: {} as import('./PodcastEpisodeMachine.typegen').Typegen0,
    schema: {
      context: {
        timeSinceLastSavedResumePoint: Date.now(),
      } as IStreamerMachineContext<Episode>,
      events: {} as TStreamerMachineEvent,
      services: {} as TPodcastEpisodeMachineServices,
    },
    states: {
      LOADING: {
        entry: ['assignDefaults', 'clearFailed'],
        initial: 'LOADING_INFO',
        states: {
          LOADING_INFO: {
            invoke: {
              id: 'getEpisodeInfo',
              src: 'getEpisodeInfo',
              onDone: {
                actions: ['assignChapters', 'assignRequestedOffset'],
                target: 'LOADING_AUDIO',
              },
              onError: {
                target: '#failure',
                actions: handleErrorAction('LOADING_INFO', 'podcastEpisodeMachine'),
              },
            },
          },
          LOADING_AUDIO: {
            entry: 'loadAudio',
          },
        },
        on: {
          REPORT_LOADED: {
            target: 'LOADED',
          },
          REPORT_FAILURE: {
            actions: ['logPlaybackError', 'sendPlaybackErrorEvent', 'assignTrackError'],
            target: 'FAILURE',
          },
          PAUSE: {
            target: 'PAUSE_REQUESTED',
          },
          REPORT_PLAYBACK_FAILED: {
            target: 'FAILURE_WITH_RETRY',
            actions: 'sendNativePlaybackFailedEvent',
          },
          ...COMMON_ACTIONS,
        },
        after: {
          [LOADING_TIMEOUT]: {
            target: 'FAILURE',
            actions: handleTimeoutAction('LOADING', 'podcastEpisodeMachine'),
          },
        },
      },
      TRANSITION_TO_BUFFERING: {
        on: {
          PAUSE: {
            actions: 'logPauseAttempt',
          },
          REPORT_PLAYBACK_FAILED: {
            target: 'FAILURE_WITH_RETRY',
            actions: 'sendNativePlaybackFailedEvent',
          },
          REPORT_PLAYING: {
            actions: ['sendResumeFromBufferingEvent'],
            target: 'PLAYING',
          },
          ...COMMON_ACTIONS,
        },
        after: [
          {
            // When android buffers data, it will enter a loading state and a pause state directly after.
            // This will cause the player to pause the stream. This will ignore that pause event then let the user pause manually
            // Podcasts should load differently, but while investigating it has been found that podcasts can load up parts of the audio at a time,
            // instead of loading up the entire audio file at once. So it shouldn't run into this often, but good to keep for now.
            target: 'BUFFERING',
            delay: 100,
          },
        ],
      },
      LOADED: {
        entry: 'autoplay',
        on: {
          PLAY: {
            target: 'PLAY_REQUESTED',
          },
          SEEK_TIME: {
            actions: ['seekTime', 'assignTime', 'clearRequestedOffset', 'saveResumePoint'],
          },

          REPORT_PLAYBACK_FAILED: {
            target: 'FAILURE_WITH_RETRY',
            actions: 'sendNativePlaybackFailedEvent',
          },
          ...COMMON_ACTIONS,
        },
      },
      PLAY_REQUESTED: {
        entry: ['playWithOffset', 'clearRequestedOffset'],
        on: {
          REPORT_PLAYING: {
            actions: ['sendPlayEvent', 'addToHistory'],
            target: 'PLAYING',
          },
          REPORT_AUTOPLAY_POLICY: {
            target: 'PAUSED',
          },
          PAUSE: {
            target: 'PAUSE_REQUESTED',
          },
          REPORT_PLAYBACK_FAILED: {
            target: 'FAILURE_WITH_RETRY',
            actions: 'sendNativePlaybackFailedEvent',
          },
          ...COMMON_ACTIONS,
        },
        after: {
          [LOADING_TIMEOUT]: {
            target: 'FAILURE',
          },
        },
      },
      BUFFERING: {
        on: {
          PAUSE: {
            target: 'PAUSE_REQUESTED',
          },
          REPORT_LOADING: {
            target: 'TRANSITION_TO_BUFFERING',
          },
          REPORT_PAUSED: {
            target: 'PAUSED',
          },
          REPORT_PLAYING: {
            actions: ['sendResumeFromBufferingEvent'],
            target: 'PLAYING',
          },
          REPORT_METADATA: {
            actions: ['sendLiveSongListenedEvent', 'assignMetadata'],
          },
          STOP: {
            target: 'PAUSED',
            actions: ['stop', 'sendStopEvent'],
          },
          REPORT_PLAYBACK_FAILED: {
            target: 'FAILURE_WITH_RETRY',
            actions: 'sendNativePlaybackFailedEvent',
          },
          ...COMMON_ACTIONS,
        },
      },
      PLAYING: {
        initial: 'IS_PLAYING',
        states: {
          IS_PLAYING: {
            on: {
              REPORT_TIME_UPDATE: {
                target: 'IS_PLAYING',
                actions: [
                  'assignTime',
                  'assignDuration',
                  'updateChapterIndex',
                  'saveLocalResumePoint',
                ],
              },
            },
          },
          IS_ERROR: {
            on: {
              REPORT_FAILURE: {
                actions: ['logPlaybackError', 'sendPlaybackErrorEvent', 'assignTrackError'],
              },
              REPORT_TIME_UPDATE: {
                target: 'IS_PLAYING',
                actions: ['assignTime', 'assignDuration', 'updateChapterIndex'],
              },
            },
          },
          IS_FATAL_ERROR: {
            entry: 'logPlaybackFailure',
            after: [{ delay: AUTO_TRANSITION, target: '#failure' }],
          },
        },
        on: {
          PAUSE: {
            target: 'PAUSE_REQUESTED',
          },
          REPORT_ENDED: {
            target: 'ENDED',
          },
          REPORT_LOADING: {
            target: 'TRANSITION_TO_BUFFERING',
          },
          REPORT_PAUSED: {
            target: 'PAUSED',
          },
          SEEK_TIME: {
            actions: ['seekTime', 'assignTime', 'saveResumePoint'],
          },
          STOP: {
            actions: ['stop', 'sendStopEvent', 'saveResumePoint'],
          },
          REPORT_PLAYBACK_FAILED: {
            target: 'FAILURE_WITH_RETRY',
            actions: 'sendNativePlaybackFailedEvent',
          },
          ...COMMON_ACTIONS,
        },
      },
      PAUSE_REQUESTED: {
        entry: 'pause',
        on: {
          ...COMMON_ACTIONS,
          REPORT_PAUSED: {
            target: 'PAUSED',
            actions: 'saveResumePoint',
          },
          REPORT_PLAYBACK_FAILED: {
            target: 'FAILURE_WITH_RETRY',
            actions: 'sendNativePlaybackFailedEvent',
          },
        },
        after: {
          [LOADING_TIMEOUT]: {
            target: 'FAILURE',
          },
        },
      },
      PAUSED: {
        on: {
          PLAY: [
            {
              target: 'LOADING',
              cond: 'hasFailed',
            },
            {
              target: 'PLAY_REQUESTED',
            },
          ],
          // Android works differently than web and iOS, where when we seek we go into PAUSED state
          // Keepig for consistency sake between streamers and states
          REPORT_PLAYING: {
            target: 'PLAYING',
          },
          REPORT_TIME_UPDATE: {
            actions: ['assignTime'],
          },
          SEEK_TIME: {
            actions: ['seekTime', 'assignTime', 'updateChapterIndex', 'saveResumePoint'],
          },
          REPORT_PLAYBACK_FAILED: {
            target: 'FAILURE_WITH_RETRY',
            actions: 'sendNativePlaybackFailedEvent',
          },
          ...COMMON_ACTIONS,
        },
      },
      FAILURE_WITH_RETRY: {
        entry: ['assignIncrementRetryCount', 'logRetry'],
        on: {
          ...COMMON_ACTIONS,
        },
        after: [
          {
            // iOS will immediately send a pause event after a failure. This will cancel the retry attempt.
            // The FAILURE_WITH_RETRY state needs to ignore the Pause event and we quickly transition to
            // WAITING_TO_RETRY so the user can pause the stream.
            // Keeping for consistency sake with other streamers as well. It's much less likely that android will run into a
            // buffer state with podcasts, but will be a good addition for now.
            target: 'WAITING_TO_RETRY',
            delay: 100,
          },
        ],
      },
      WAITING_TO_RETRY: {
        on: {
          PLAY: {
            target: 'LOADING',
          },
          PAUSE: {
            target: 'PAUSED',
            actions: 'pause',
          },
          STOP: {
            actions: ['stop', 'sendStopEvent'],
          },
          REPORT_PAUSED: {
            target: 'PAUSED',
          },
          ...COMMON_ACTIONS,
        },
        after: [
          {
            target: 'FAILURE',
            delay: (context) => getExponentialBackoffDelay(context.retryCount),
            cond: ({ retryCount = 0 }) => retryCount >= MAX_RETRY_ATTEMPTS,
          },
          {
            actions: ['stop', 'loadAudio', sendParent({ type: 'PLAY' })],
            target: 'LOADING',
            delay: (context) => getExponentialBackoffDelay(context.retryCount),
            cond: ({ retryCount = 0 }) => retryCount < MAX_RETRY_ATTEMPTS,
          },
        ],
      },
      FAILURE: {
        id: 'failure',
        on: COMMON_ACTIONS,
        entry: ['sendPlaybackFailedEvent'],
        after: [{ delay: 1500, target: 'PAUSED' }],
      },
      ENDED: {
        entry: ['sendStopEvent', 'saveResumePoint'],
        on: {
          PLAY: [
            {
              target: 'PLAY_REQUESTED',
              cond: 'isPlaybackPositionAtEnd',
              actions: 'resetTime',
            },
            {
              target: 'PLAY_REQUESTED',
              actions: 'resetTime',
            },
          ],
          SEEK_TIME: {
            target: 'PAUSED',
            actions: ['assignTime', 'seekTime'],
          },
          ...COMMON_ACTIONS,
        },
      },
      DESTROYED: {
        entry: ['stop', 'sendStopEvent', 'saveResumePoint'],
        type: 'final',
      },
    },
  },
  {
    services: { getEpisodeInfo },
    guards: {
      hasFailed: ({ failed }) => !!failed,
      isPlaybackPositionAtEnd: ({ elapsed, episode, metadata }) => {
        if (!elapsed || !episode) {
          return true;
        }

        const durationSeconds = metadata?.duration ?? episode.getDuration();

        return elapsed >= durationSeconds;
      },
    },
    actions: {
      addToHistory: (ctx) => {
        const id = ctx.item.getId();
        if (!ctx.personalizationProvider.isInTopOfHistoryStack(id)) {
          ctx.personalizationProvider.addToHistory([id]);
        }
      },
      assignDefaults: assign(({ item, firstLoadTime, analyticsProvider, metadata }) => {
        if (!metadata) {
          // Initial load
          analyticsProvider.sendEventToListener({
            type: PlayerMetric.STREAM_LOAD,
            eventDetails: {
              stationId: item.getId(),
              streamerType: EContentType.Podcast,
            },
          });
        }
        return {
          elapsed: 0,
          contentType: EContentType.Podcast,
          firstLoadTime: firstLoadTime || Date.now(),
          metadata: {
            duration: item.getDuration(),
            songOrShow: item.getTitle(),
            image: item.getImageSquare() || item.getParentImageSquare(),
            artist: item.getParentTitle(),
          },
          episode: item,
        };
      }),
      assignChapters: assign((_, { data: { chapters } }) => ({
        chapters,
      })),
      updateChapterIndex: assign(({ elapsed, chapters }) => {
        if (!elapsed || !chapters || chapters.length === 0) {
          return {};
        }
        const currentChapterIndex = chapters?.findIndex(
          (chapter) =>
            elapsed >= chapter.getStartOffset() &&
            elapsed < chapter.getStartOffset() + chapter.getDuration(),
        );
        // If no such chapter satisfies the conditions, return undefined instead of -1
        if (currentChapterIndex === -1) {
          return {
            currentChapterIndex: undefined,
          };
        }
        return {
          currentChapterIndex,
        };
      }),
      clearRequestedOffset: assign({ requestedOffset: (_) => undefined }),
      assignRequestedOffset: assign((ctx, { data: { requestedOffset } }) => {
        // prefer offset from audioservices to playback (comes from deeplinks, etc...)
        if (isUndefined(ctx.requestedOffset)) {
          return { requestedOffset };
        }

        return ctx;
      }),
      loadAudio: (context) => {
        const audioStreamUrl = context.item?.getAudioStream();
        const gppString = getGppString({
          optIn: !clientServicesConfig.disableTargetedAds,
        });
        const url = new URI(audioStreamUrl).addQuery('gpp', gppString);
        const urlString = url.valueOf();

        if (!context.metadata) {
          // Initial load
          context.analyticsProvider.sendEventToListener({
            type: PlayerMetric.STREAM_LOAD,
            eventDetails: {
              contentId: context.item.getId(),
              streamerType: EContentType.Podcast,
              isNativeErrorHandling: true,
            },
          });
        }

        context?.player?.load({
          url: urlString,
          isPodcast: true,
          isSuperHifi: false,
          isAudioPreroll: false,
          liveContentUrl: '',
          autoplay: false,
        });
      },
      playWithOffset: ({ player, requestedOffset }) => {
        player.playWithOffset(requestedOffset);
      },
      resetTime: (context) => context?.player?.setPosition(0),
      seekTime: (
        { metadata, item, analyticsProvider, player, elapsed, deviceInfoProvider },
        event,
      ) => {
        player?.setPosition(clamp(event.time, 0, metadata?.duration ?? item.data.duration));
        if (elapsed !== undefined) {
          const eventType = event.time > elapsed ? PlayerAction.FAST_FORWARD : PlayerAction.REWIND;
          const audioRoute = deviceInfoProvider?.getAudioRoute();
          const url = getFirstAudioSourceUrl(item?.data.streamUrl);

          analyticsProvider?.sendPlayerEvent({
            type: eventType,
            contentId: item?.data.id,
            connectionType: audioRoute,
            currentPosition: toIntegerOrUndefined(elapsed),
            streamUrl: url,
          });
        }
      },
      assignTime: assign({
        elapsed: (_, { time }) => time,
      }),
      assignTrackError: assign(({ errors }, event) => {
        const { error, errorMessage } = event || {};
        const extractedError = error ? error.raw || error : errorMessage;

        return {
          errors: [
            // Save the 5 most recent errors to send to Datadog
            { errorMessage, context: extractedError },
            ...(errors || []),
          ].slice(0, 5),
        };
      }),
      assignDuration: assign({
        metadata: (context, event) => ({
          ...context.metadata,
          duration: event.duration ?? context.metadata?.duration,
        }),
      }),
      saveResumePoint: (context) => {
        saveResumePoints(context);
      },
      sendResumeFromBufferingEvent: assign(
        ({ analyticsProvider, item, bufferStartTime, firstLoadTime }) => {
          analyticsProvider.sendEventToListener({
            type: PlayerMetric.STREAM_RESUME_FROM_BUFFERING,
            eventDetails: {
              stationId: item.getId(),
              streamerType: EContentType.Podcast,
              uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
              timeBufferingMs: bufferStartTime ? Date.now() - bufferStartTime : 0,
              isNativeErrorHandling: true,
            },
          });
          return { bufferStartTime: undefined };
        },
      ),
      sendPlayEvent: ({
        analyticsProvider,
        episode,
        firstLoadTime,
        retryCount,
        errors,
        deviceInfoProvider,
        elapsed,
      }) => {
        if (!episode?.data.id) {
          return;
        }

        const audioRoute = deviceInfoProvider?.getAudioRoute();
        const position = toIntegerOrUndefined(elapsed ?? episode?.data.currentPosition);
        const url = getFirstAudioSourceUrl(episode?.data.streamUrl);

        analyticsProvider.sendPlayerEvent({
          type: PlayerAction.PLAY,
          contentId: episode.data.id,
          connectionType: audioRoute,
          currentPosition: position,
          streamUrl: url,
        });

        analyticsProvider.sendEventToListener({
          type: PlayerMetric.STREAM_PLAY,
          eventDetails: {
            stationId: episode?.data.id,
            streamerType: EContentType.Podcast,
            uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
            retryCount,
            mostRecentError: errors?.[0],
            isNativeErrorHandling: true,
          },
        });

        if (retryCount) {
          analyticsProvider.sendEventToListener({
            type: PlayerMetric.STREAM_RECONNECT_SUCCESSFUL,
            eventDetails: {
              stationId: episode?.data.id,
              streamerType: EContentType.Podcast,
              uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
              retryCount,
              mostRecentError: errors?.[0],
              isNativeErrorHandling: true,
            },
          });
        }
      },
      sendStopEvent: ({
        analyticsProvider,
        episode,
        firstLoadTime,
        retryCount,
        errors,
        elapsed,
        deviceInfoProvider,
      }) => {
        if (!episode?.data.id) {
          return;
        }

        const audioRoute = deviceInfoProvider?.getAudioRoute();
        const url = getFirstAudioSourceUrl(episode?.data.streamUrl);
        const position = toIntegerOrUndefined(elapsed ?? episode.data.currentPosition);

        analyticsProvider.sendPlayerEvent({
          type: PlayerAction.STOP,
          contentId: episode.data.id,
          connectionType: audioRoute,
          streamUrl: url,
          currentPosition: position,
        });

        analyticsProvider.sendEventToListener({
          type: PlayerMetric.STREAM_PAUSE,
          eventDetails: {
            stationId: episode?.data.id,
            streamerType: EContentType.Podcast,
            uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
            retryCount,
            isNativeErrorHandling: true,
          },
        });
        if (retryCount) {
          analyticsProvider.sendEventToListener({
            type: PlayerMetric.STREAM_RECONNECT_ABORTED,
            eventDetails: {
              stationId: episode?.data.id,
              streamerType: EContentType.Podcast,
              uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
              retryCount,
              mostRecentError: errors?.[0],
              isNativeErrorHandling: true,
            },
          });
        }
      },
      // using ts-ignore because, otherwise it throws error on using constant RESUME_POINT_INTERVAL_MSEC from other file
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      saveLocalResumePoint: (context) => {
        if (!context.timeSinceLastSavedResumePoint) {
          context.timeSinceLastSavedResumePoint = Date.now();
        }
        const currentTime = Date.now();
        const updateTime = context.timeSinceLastSavedResumePoint + RESUME_POINT_INTERVAL_MSEC;

        if (currentTime >= updateTime) {
          saveResumePoints(context, false);
          context.timeSinceLastSavedResumePoint = Date.now();
        }
      },
      clearFailed: assign({ failed: false }),
      sendNativePlaybackFailedEvent: (
        { analyticsProvider, item, firstLoadTime, retryCount },
        event,
      ) => {
        AudacyLogger.info(
          `[${
            LoggerTopic.Streaming
          }]: LiveStationMachine: sendNativePlaybackFailedEvent event:\n${JSON.stringify(
            event,
            null,
            2,
          )}`,
        );

        analyticsProvider.sendEventToListener({
          type: PlayerMetric.STREAM_PLAYBACK_FAILED,
          eventDetails: {
            stationId: item.getId(),
            streamerType: EContentType.LiveStation,
            uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
            retryCount,
            error: {
              errorMessage: event.errorMessage,
              errorType: event.error,
            },
            isNativeErrorHandling: true,
          },
        });
      },
      ...DEFAULT_PLAYER_ACTIONS,
    },
  },
);

const selector: TStreamSelector<typeof podcastEpisodeMachine> = async (dataObject) => {
  if (
    dataObject instanceof Episode &&
    dataObject.getEntityType() === EntityType.EPISODE &&
    dataObject.getEntitySubtype() === EpisodeSubType.PODCAST_EPISODE
  ) {
    return podcastEpisodeMachine.withContext({
      ...podcastEpisodeMachine.context,
      item: dataObject,
    });
  }
};

export default selector;
