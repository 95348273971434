export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Location</title>
    <path d="M28 4 16.387 28l-2.322-10.065L4 15.613 28 4Z" fill="currentColor" />
  </svg>
);
