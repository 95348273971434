import { getCurrentEpisodes } from '@audacy-clients/core/atoms/episodes';
import { getSchedules } from '@audacy-clients/core/atoms/schedules';
import {
  type IEpisode,
  type TWrappedContentObject,
} from '@audacy-clients/core/atoms/wrappers/types';
import { isValidEpisodeOrFiller } from '@audacy-clients/core/utils/episode';
import { type SerializableParam, useRecoilValueLoadable } from 'recoil';
import { contentQuery } from './content';
import { RefreshTimes } from './helpers/constants';
import { noCacheDefaultSelectorFamily } from './helpers/noCacheDefaultSelector';
import { refreshableSelectorFamily } from './helpers/refreshable';

type ILiveNowParams = {
  contentId?: string;
  isStation?: boolean;
};

type TLiveEpisodeFromScheduleReturn = {
  episode: IEpisode | undefined;
  isLoading: boolean;
};

export const {
  useWrappedCachedValue: useCachedLiveEpisodeFromSchedule,
  selector: liveEpisodeFromSchedule,
} = refreshableSelectorFamily<IEpisode | undefined, ILiveNowParams & SerializableParam>({
  get:
    ({ contentId, isStation }) =>
    ({ get }) => {
      if (!contentId) {
        return undefined;
      }
      const episodes = get(isStation ? getSchedules(contentId) : getCurrentEpisodes(contentId));
      if (!episodes) {
        return undefined;
      }
      const now = Date.now();
      const episodeList = episodes.getAll();
      const liveNowEpisode = episodeList.find(
        (e) =>
          now < e?.endTime && now > e?.startTime && isValidEpisodeOrFiller(e) && !e.isOnAirOnly,
      );
      if (!liveNowEpisode || episodeList.length <= 1) {
        // Do not display module when station schedule contains only one episode -
        // when this is the case, it is a single filler episode populated in Client Services to facilitate player functionality
        return undefined;
      }
      return liveNowEpisode;
    },
  key: 'LiveEpisodeFromSchedule',
  refreshEverySeconds: RefreshTimes.every30s,
});

export const useLiveEpisodeFromSchedule = ({
  contentId,
  isStation,
}: ILiveNowParams): TLiveEpisodeFromScheduleReturn => {
  const liveEpisodeLoadable = useRecoilValueLoadable(
    liveEpisodeFromSchedule({ contentId, isStation }),
  );

  const episode = liveEpisodeLoadable.valueMaybe();
  const state = liveEpisodeLoadable.state;

  return { episode, isLoading: state === 'loading' };
};

type ICurrentShowParams = {
  contentId?: string;
  isStation: boolean;
};

export const getCurrentShow = noCacheDefaultSelectorFamily<
  { currentShow: TWrappedContentObject | undefined; url: string | undefined } | undefined,
  ICurrentShowParams
>({
  get:
    ({ contentId, isStation }) =>
    ({ get }) => {
      if (!contentId) {
        return undefined;
      }

      const liveScheduleItem = get(liveEpisodeFromSchedule({ contentId, isStation }));
      const show = get(contentQuery(liveScheduleItem?.showContentId));

      return { currentShow: show, url: liveScheduleItem?.url };
    },
  key: 'CurrentShow',
});
