import {
  EpisodeSubType,
  ModuleSubType,
  ShowSubtype,
  StandaloneChapterSubType,
  StationSubType,
  TagSubtype,
} from '@audacy-clients/client-services/core';
import { Characters } from '@audacy-clients/core/utils/strings';
import isArray from 'lodash/isArray';
import toLower from 'lodash/toLower';
import { useTranslation } from 'react-i18next';
import { getExpirationMessage } from '../../utils';

export const useEyebrowText = (
  // Eyebrow text will be dependent upon entity type, unsure how to type item to account for every entity shape at this time -- use "any" in the meantime
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: Record<string, any>,
  shouldShowEntityType: boolean,
): string | Array<string> => {
  const { t } = useTranslation();
  const getGenre = (type: string, showType: boolean, genre?: string) =>
    genre &&
    `${genre}${
      showType ? Characters.spaceSingle + toLower(t(`horizontalEntityCard.eyebrow.${type}`)) : ''
    }`;

  if (item.subType === ModuleSubType.UNFINISHED_EPISODES && item.replayableUntilDateTime) {
    return getExpirationMessage(item.replayableUntilDateTime as string);
  }

  let eyebrow;
  switch (item?.entitySubtype) {
    case TagSubtype.GENRE:
      eyebrow = t('horizontalEntityCard.eyebrow.topic');
      break;
    case TagSubtype.LEAGUE:
      eyebrow = t('horizontalEntityCard.eyebrow.league');
      break;
    case StandaloneChapterSubType.BROADCAST_EPISODE_CHAPTER:
    case EpisodeSubType.BROADCAST_SHOW_EPISODE:
    case EpisodeSubType.PODCAST_EPISODE:
      eyebrow = item.parentTitle;
      break;
    case ShowSubtype.PODCAST:
      eyebrow = [getGenre('podcast', shouldShowEntityType, item.genres?.[0]), item.author];
      break;
    case StationSubType.EXCLUSIVE:
      eyebrow = [getGenre('station', shouldShowEntityType, item.genres?.[0]), item.parentTitle];
      break;
    case StationSubType.BROADCAST:
      eyebrow = [
        getGenre('station', shouldShowEntityType, item.genres?.[0]),
        item.parentTitle,
        item.marketTitle,
      ];
      break;
    case ShowSubtype.BROADCAST:
      eyebrow = [
        getGenre('show', shouldShowEntityType, item.genres?.[0] || item.parentGenres?.[0]),
        item.parentTitle,
      ];
      break;
    case ShowSubtype.PLAYLIST:
      eyebrow = item.author;
      break;
    case TagSubtype.TEAM:
    default:
      eyebrow = [];
      break;
  }

  return isArray(eyebrow) ? eyebrow.filter((tag: string) => !!tag) : eyebrow;
};
