import React from 'react';

import { TModuleRequest } from '@audacy-clients/core/atoms/modules/requests';
import { IView } from '@audacy-clients/core/atoms/wrappers/modules';
import RootModule from '@audacy-clients/core/components/ModuleRenderer/RootModule';
import {
  TModuleMapping,
  TModuleWrapper,
  TViewContainer,
} from '@audacy-clients/core/components/ModuleRenderer/types';

interface IProps<T> {
  moduleMap: TModuleMapping;
  moduleWrapper: TModuleWrapper;
  navigation?: T;
  onNavigate?: () => void;
  onViewLoaded?: (view: IView) => void;
  render?: (view: IView, modules: JSX.Element) => JSX.Element;
  request: TModuleRequest;
  viewContainer: TViewContainer;
  shouldHandleErrorsSilently?: boolean;
  skipRendering?: boolean;
  virtual?: boolean;
}

const ModuleRenderer = <T,>({
  moduleMap,
  moduleWrapper,
  navigation,
  onNavigate,
  onViewLoaded,
  render,
  request,
  viewContainer,
  shouldHandleErrorsSilently,
  skipRendering,
  virtual,
}: IProps<T>): JSX.Element => {
  const ViewContainer = viewContainer;
  return (
    <ViewContainer request={request} shouldHandleErrorsSilently={shouldHandleErrorsSilently}>
      <RootModule
        moduleMap={moduleMap}
        moduleWrapper={moduleWrapper}
        navigation={navigation}
        render={render}
        request={request}
        skipRendering={skipRendering}
        virtual={virtual}
        onNavigate={onNavigate}
        onViewLoaded={onViewLoaded}
      />
    </ViewContainer>
  );
};

export default ModuleRenderer;
