export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Progress</title>
    <g>
      <path
        d="M16 29c7.18 0 13-5.82 13-13S23.18 3 16 3 3 8.82 3 16s5.82 13 13 13Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
        d="M13.75 11.75v8.5M18.75 11.75v8.5"
      />
    </g>
    <path
      d="M16 29C8.82 29 3 23.18 3 16S8.82 3 16 3"
      opacity=".6"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
