import { ClickFeature } from '@audacy-clients/client-services/core';
import playerState, {
  nowPlaying,
  playerControlsState,
  playerTimeState,
} from '@audacy-clients/core/atoms/player';
import { PLAYER_CONTROL_TIMINGS } from '@audacy-clients/core/constants/player';
import { useAudioHelpers } from '@audacy-clients/core/hooks/useAudioHelpers';
import { isPlayerButtonVisible } from '@audacy-clients/core/utils/player';
import { useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { isAndroid } from '~/utils/device';

const useNowPlaying = (): void => {
  const { duration } = useRecoilValue(playerTimeState) || {};
  const { scrubberIsUserControlled, scrubberBarType } = useRecoilValue(playerControlsState) || {};
  const nowPlayingState = useRecoilValue(nowPlaying);

  const hasPlayerItem = !!nowPlayingState;
  const { title, artist, image, station } = nowPlayingState || {};

  // Android does not display album info on the lock screen,
  // so if artist is unavailable we can populate it with album data
  const androidArtistReplacement = isAndroid() ? station : artist;

  const hasMediaSession = 'mediaSession' in navigator;

  useEffect(() => {
    if (hasMediaSession) {
      if (hasPlayerItem) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title,
          artist: artist ?? androidArtistReplacement,
          album: station,
          artwork: image?.src ? [{ src: image.src }] : [],
        });
      } else {
        navigator.mediaSession.metadata = new MediaMetadata({});
      }
    }
  }, [
    artist,
    duration,
    hasMediaSession,
    hasPlayerItem,
    image,
    scrubberIsUserControlled,
    station,
    title,
    androidArtistReplacement,
    scrubberBarType,
  ]);
};

type TMediaSessionActionHandler = [MediaSessionAction, MediaSessionActionHandler | null];

const usePlayerControls = (): void => {
  const audioHelpers = useAudioHelpers();
  const { ff15sButton, pauseButton, rw15sButton, skipButton, stopButton } =
    useRecoilValue(playerControlsState) || {};
  const { duration } = useRecoilValue(playerTimeState) || {};
  const { playState } = useRecoilValue(playerState);
  const playerItem = useRecoilValue(nowPlaying);

  const secondsToSkip = PLAYER_CONTROL_TIMINGS.skip15;

  const tryToSeek = useCallback(
    (seg: number | null | undefined) => {
      if (duration && seg) {
        const seekValue = seg / duration;
        audioHelpers.seekToProgress(seekValue);
      }
    },
    [duration, audioHelpers],
  );

  const hasMediaSession = 'mediaSession' in navigator;

  useEffect(() => {
    if (hasMediaSession) {
      const { dataObject, episodeDataObject } = playerItem || {};
      const { title, artist, image, station } = playerItem || {};
      const androidArtistReplacement = isAndroid() ? station : artist;

      const playContext = {
        contentId: episodeDataObject?.id || dataObject?.id,
        feature: ClickFeature.EXTERNAL,
      };

      const actionHandlers: Array<TMediaSessionActionHandler> = [
        [
          'play',
          () => { 
            // play audio
            audioHelpers.resume(playContext);
            // set playback state
            navigator.mediaSession.playbackState = 'playing';
            if (playerItem) {
              navigator.mediaSession.metadata = new MediaMetadata({
                title,
                artist: artist ?? androidArtistReplacement,
                album: station,
                artwork: image?.src ? [{ src: image.src }] : [],
              });
            }
          },
        ],
        [
          'pause',
          () => {
            // pause audio
            // note: ClientServices calls stop handler under the hood when streaming audio,
            // so no need for a separate "stop" handler.

            audioHelpers.pause(playContext);
            // set playback state
            navigator.mediaSession.playbackState = 'paused';
            if (playerItem) {
              navigator.mediaSession.metadata = new MediaMetadata({
                title,
                artist: artist ?? androidArtistReplacement,
                album: station,
                artwork: image?.src ? [{ src: image.src }] : [],
              });
            }
          },
        ],
        [
          'stop',
          () => {
            // stop audio
            // note: This handler doesn't act like the others. It does not force a "Stop"
            // button to appear in any UI. But one use-case for it is when clicking on the
            // "Close" button of the Google Chrome media player.
            audioHelpers.stop(playContext);
            // set playback state
            navigator.mediaSession.playbackState = 'none';
          },
        ],
        [
          'nexttrack',
          isPlayerButtonVisible(skipButton)
            ? () => {
                audioHelpers.skip({ ...playContext, artist, songTitle: title });
              }
            : null,
        ],
        [
          'seekforward',
          isPlayerButtonVisible(ff15sButton, true)
            ? () => {
                audioHelpers.skipByTime(secondsToSkip, playContext);
              }
            : null,
        ],
        [
          'seekbackward',
          isPlayerButtonVisible(rw15sButton, true)
            ? () => {
                audioHelpers.skipByTime(-1 * secondsToSkip, playContext);
              }
            : null,
        ],
        [
          'seekto',
          isPlayerButtonVisible(ff15sButton, true) && isPlayerButtonVisible(rw15sButton, true)
            ? ({ seekTime }) => {
                tryToSeek(seekTime);
              }
            : null,
        ],
      ];

      for (const [action, handler] of actionHandlers) {
        try {
          navigator.mediaSession.setActionHandler(action, handler);
        } catch (_error) {
          console.info(`The media session action "${action}" is not supported yet.`);
        }
      }
    }
  }, [
    audioHelpers,
    hasMediaSession,
    ff15sButton,
    pauseButton,
    playerItem,
    playState,
    rw15sButton,
    secondsToSkip,
    skipButton,
    stopButton,
    tryToSeek,
  ]);
};

const useMusicControl = (): void => {
  useNowPlaying();
  usePlayerControls();
};

export default useMusicControl;
