export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Headphone</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 5.75C10.34 5.75 5.75 10.34 5.75 16v2.25h6v8.5h-7.5V16C4.25 9.51 9.51 4.25 16 4.25S27.75 9.51 27.75 16v10.75h-7.5v-8.5h6V16c0-5.66-4.59-10.25-10.25-10.25Zm10.25 14h-4.5v5.5h4.5v-5.5Zm-20.5 5.5v-5.5h4.5v5.5h-4.5Z"
      fill="currentColor"
    />
  </svg>
);
