import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  listItem: {
    position: 'relative', // Required for child component(s)
    marginBottom: 20,
  },
};

export default styles;
