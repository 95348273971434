import { isArray, isFunction } from 'lodash';

export const simpleArrayToObject = <T>(
  arr: T[],
  getKey: (item: T) => string,
): Record<string, T> => {
  return arr.reduce((obj, item) => {
    obj[getKey(item)] = item;

    return obj;
  }, {} as Record<string, T>);
};

export const arrayToObject = <T, R>(
  arr: T[],
  getKey: (item: T) => string,
  valueOrGetValue: ((item: T) => R) | R,
): Record<string, R> => {
  const getValue = isFunction(valueOrGetValue) ? valueOrGetValue : () => valueOrGetValue;

  return arr.reduce((obj, item) => {
    obj[getKey(item)] = getValue(item);

    return obj;
  }, {} as Record<string, R>);
};

export const stringArrayToObject = <R>(
  arr: string[],
  valueOrGetValue: ((item: string) => R) | R,
): Record<string, R> => {
  return arrayToObject<string, R>(arr, (item) => item, valueOrGetValue);
};

export const arrayify = <T>(itemOrItems: T | T[]): T[] => {
  return isArray(itemOrItems) ? itemOrItems : [itemOrItems];
};
