import clientServices from '@audacy-clients/core/utils/clientServices';

export const checkIfHasIncompleteProfileData = async (): Promise<boolean> => {
  const incompleteProfileData = await clientServices
    .getPersonalizationServices()
    .getIncompleteProfileData();
  return !!incompleteProfileData?.length;
};

// This no longer needs to be a hook but it's still used in 2 other apps
export const useProfile = () => {
  return {
    checkIfHasIncompleteProfileData,
  };
};
