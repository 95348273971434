import AnalyticServices from './analyticServices';
import AudioServices from './audioServices';
import ChatServices from './chatServices/ChatServices';
import { IServiceBus } from './Container';
import DataServices from './dataServices/DataServices';
import PersonalizationServices from './personalizationServices';
export default abstract class Service {
  analyticServices!: AnalyticServices;
  audioServices!: AudioServices;
  chatServices!: ChatServices;
  dataServices!: DataServices;
  personalizationServices!: PersonalizationServices;

  async postServiceInit(config: IServiceBus): Promise<void> {
    this.audioServices = config.audioServices;
    this.analyticServices = config.analyticServices;
    this.chatServices = config.chatServices;
    this.dataServices = config.dataServices;
    this.personalizationServices = config.personalizationServices;
  }
}
