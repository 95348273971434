import { IQueueItem } from '@audacy-clients/core/atoms/wrappers/types';
import { useTranslation } from 'react-i18next';

import CardListViewWithState from '~/components/CardListView';
import CenteredLoading from '~/components/Loading/CenteredLoading';
import { FollowsCategoryTabStrings } from '~/pages/MyAudioPage/types';
import { TStyles } from '~/types/emotion-styles';

import QueueEmpty from './QueueEmpty';
import QueueExpiredMessage from './QueueExpiredMessage';
import styles from './styles';

export interface IQueueProps {
  containerCss?: TStyles;
  headerCss?: TStyles;
  isLoading?: boolean;
  isPlayerQueue?: boolean;
  listCss?: TStyles;
  nowPlayingItemId: string | undefined;
  queueItems: IQueueItem[];
  shouldForceFocus?: boolean;
  shouldRenderHeader?: boolean;
  onRemove?: (ids: string[]) => void;
  onSortEnd?: (items: IQueueItem[]) => void;
  selectedItems?: string[];
  handleSelectAllPlayed?: () => void;
  renderListLabel?: () => JSX.Element | string;
  onItemClick?: (id: string) => void;
  hadExpiredItems: boolean;
  hideExpiredMessage: () => void;
  emptyQueueCss?: TStyles;
}

const Queue = ({
  isLoading,
  queueItems,
  onRemove,
  shouldRenderHeader = true,
  selectedItems,
  emptyQueueCss,
  ...rest
}: IQueueProps): JSX.Element => {
  const { t } = useTranslation();
  const handleRemoveItems = (ids: string[]) => {
    onRemove?.(ids);
  };

  if (isLoading) {
    return <CenteredLoading />;
  }

  return (
    <CardListViewWithState
      {...rest}
      items={queueItems}
      itemType={FollowsCategoryTabStrings.MyQueue}
      isListEditable
      isListSortable
      renderEditActionButton={(quantity: number) => ({
        label: t('global.removeX', { quantity }),
        onClick: (ids: string[]) => () => handleRemoveItems(ids),
      })}
      title={shouldRenderHeader ? t('queueOverlay.myQueue.title') : undefined}
      // my audio gives list entire padding - don't include this padding for the empty expired message
      containerCss={
        queueItems.length === 0 && rest.hadExpiredItems ? styles.resetPadding : rest.containerCss
      }
      renderEmptyMessage={() => {
        if (rest.hadExpiredItems) {
          return (
            <QueueExpiredMessage
              hideExpiredMessage={rest.hideExpiredMessage}
              isPlayerQueue={rest.isPlayerQueue}
            />
          );
        }

        return <QueueEmpty containerCss={emptyQueueCss} />;
      }}
      defaultSelected={selectedItems}
    />
  );
};

export default Queue;
