import { IEntitySummaryProps } from '~/components/Entity/types';

import Bulleted from './components/Bulleted';
import Duration from './components/Duration';
import Live from './components/Live';

const PlayStateArea = ({
  ctaColor,
  duration,
  hasOverflowGradient,
  _isChapter,
  isExclusiveStation,
  _isEpisode,
  isLive,
  isPlayed,
  isPlaying,
  liveUntil,
  resumePoint,
  title,
  isScrubbing,
  isScheduleItem,
  isPlayable,
  isPlayList,
  itemsCount,
}: IEntitySummaryProps): JSX.Element => {
  if (Array.isArray(title)) {
    return <Bulleted title={title} hasOverflowGradient={hasOverflowGradient} />;
  }

  const isPlayingUntilFuture =
    liveUntil?.playingUntil && new Date(liveUntil?.playingUntil).getTime() > Date.now();

  if (isLive || isPlayingUntilFuture) {
    // Station
    return (
      <Live
        isPlaying={isPlaying}
        ctaColor={ctaColor}
        liveUntil={liveUntil}
        hasOverflowGradient={hasOverflowGradient}
        resumePoint={resumePoint}
        isScheduleItem={isScheduleItem}
      />
    );
  }

  // Station, Podcast Episode, Clip, Segment
  return (
    <Duration
      isPlayList={isPlayList}
      _isChapter={_isChapter}
      duration={duration}
      isExclusiveStation={isExclusiveStation}
      _isEpisode={_isEpisode}
      isPlayed={isPlayed}
      isPlaying={isPlaying}
      hasOverflowGradient={hasOverflowGradient}
      resumePoint={resumePoint}
      isScrubbing={isScrubbing}
      isScheduleItem={isScheduleItem}
      isPlayable={isPlayable}
      itemsCount={itemsCount}
    />
  );
};

export default PlayStateArea;
