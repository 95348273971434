import { TStyles, TStyleSheet } from '~/types/emotion-styles';

export const svgSizeStyles = (size: number): TStyles => ({
  '& svg': {
    height: size,
    width: size,
  },
});

const styles: TStyleSheet = {
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default styles;
