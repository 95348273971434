export default (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 25H3V5H28V16" stroke="currentColor" strokeWidth="1.5" />
    <mask id="path-2-inside-1_5430_1614" fill="white">
      <path d="M15.0112 19L12.2552 11H10.756L8 19H9.6205L10.0284 17.7543H12.9828L13.3907 19H15.0112ZM11.4946 13.32H11.5166L12.4867 16.2686H10.5245L11.4946 13.32Z" />
      <path d="M18.6047 19C20.6661 19 22 17.5143 22 15C22 12.5086 20.6661 11 18.6157 11H15.9369V19H18.6047ZM17.5684 12.5543H18.6047C19.685 12.5543 20.3685 13.3771 20.3685 15C20.3685 16.6343 19.685 17.4457 18.6047 17.4457H17.5684V12.5543Z" />
    </mask>
    <path
      d="M15.0112 19L12.2552 11H10.756L8 19H9.6205L10.0284 17.7543H12.9828L13.3907 19H15.0112ZM11.4946 13.32H11.5166L12.4867 16.2686H10.5245L11.4946 13.32Z"
      fill="currentColor"
    />
    <path
      d="M18.6047 19C20.6661 19 22 17.5143 22 15C22 12.5086 20.6661 11 18.6157 11H15.9369V19H18.6047ZM17.5684 12.5543H18.6047C19.685 12.5543 20.3685 13.3771 20.3685 15C20.3685 16.6343 19.685 17.4457 18.6047 17.4457H17.5684V12.5543Z"
      fill="currentColor"
    />
    <path
      d="M15.0112 19L12.2552 11H10.756L8 19H9.6205L10.0284 17.7543H12.9828L13.3907 19H15.0112ZM11.4946 13.32H11.5166L12.4867 16.2686H10.5245L11.4946 13.32Z"
      stroke="currentColor"
      strokeWidth="3"
      mask="url(#path-2-inside-1_5430_1614)"
    />
    <path
      d="M18.6047 19C20.6661 19 22 17.5143 22 15C22 12.5086 20.6661 11 18.6157 11H15.9369V19H18.6047ZM17.5684 12.5543H18.6047C19.685 12.5543 20.3685 13.3771 20.3685 15C20.3685 16.6343 19.685 17.4457 18.6047 17.4457H17.5684V12.5543Z"
      stroke="currentColor"
      strokeWidth="3"
      mask="url(#path-2-inside-1_5430_1614)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 19.25H22.25V20V25.8H23.75L23.75 21.8105L27.4697 25.5303L28 26.0606L29.0607 25L28.5303 24.4696L24.8108 20.75L28 20.75H28.75V19.25H28H23Z"
      fill="currentColor"
    />
  </svg>
);
