import { ForwardedRef, forwardRef } from 'react';

import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { useTranslation } from 'react-i18next';

import IconButton from '~/components/Button/IconButton';
import { Icons } from '~/components/Icon/constants';
import { ISortableItemProps } from '~/components/SortableList/SortableListItem';

import styles from './styles';

export interface IItem {
  id: string;
  title: string;
}

interface IItemProps<T> extends ISortableItemProps<T> {
  listeners?: SyntheticListenerMap;
  style?: {
    transform?: string;
    transition?: string;
  };
  isDragging?: boolean;
}

const Item = <T extends IItem>(
  {
    item,
    isReordering,
    isSortable,
    listId,
    children,
    isEditMode,
    listeners,
    style,
    isDragging,
    ...rest
  }: IItemProps<T>,
  ref: ForwardedRef<HTMLLIElement>,
): JSX.Element => {
  const { t } = useTranslation();
  const reorderHelpTextId = `${listId}-help-text`;

  return (
    <li key={item.id} css={[styles.item, style]} ref={ref} {...rest}>
      <div css={isDragging && styles.childrenInvisible}>{children}</div>
      {isEditMode && (
        <span {...listeners} css={isReordering && styles.childrenInvisible}>
          <IconButton
            ariaDescribedBy={reorderHelpTextId}
            ariaLabel={`${t('sortable.listItem.reorder.label')} ${item.title}`}
            buttonCss={styles.handle}
            icon={Icons.ListHandle}
            isDisabled={!isSortable}
            iconSizeInPx={32}
          />
        </span>
      )}
    </li>
  );
};

export default forwardRef(Item);
