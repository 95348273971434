import { MutableRefObject, useEffect, useRef, useState } from 'react';

import { useBreakpoints } from '~/hooks/use-breakpoints';

interface IUseNavigationProps {
  isMenuOpen: boolean;
  handleCloseMenu: () => void;
  handleToggleMenu: () => void;
  closeButtonRef?: MutableRefObject<HTMLButtonElement | null>;
}

const useNavigation = (): IUseNavigationProps => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const { is } = useBreakpoints();

  const handleCloseMenu = (): void => setIsMenuOpen(false);

  const handleToggleMenu = (): void => {
    setIsMenuOpen((prev) => !prev);
  };

  // Focus the close button when the menu is opened on MD
  useEffect(() => {
    if (isMenuOpen && is.MD) {
      closeButtonRef?.current?.focus();
    }
  }, [isMenuOpen, is, closeButtonRef]);

  return {
    closeButtonRef,
    isMenuOpen,
    handleCloseMenu,
    handleToggleMenu,
  };
};

export default useNavigation;
