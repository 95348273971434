import { ClickFeature } from '@audacy-clients/client-services/core';
import { useCachedLiveEpisodeFromSchedule } from '@audacy-clients/core/atoms/liveNow';
import { IModule } from '@audacy-clients/core/atoms/wrappers/modules';
import { useModuleContext } from '@audacy-clients/core/components/ModuleRenderer/context';
import { IPlayContext } from '@audacy-clients/core/hooks/useAudioHelpers';
import { getPlayingUntilFromStartAndDuration } from '@audacy-clients/core/utils/date';

import { INowPlaying } from '~/components/Entity/types';
import { useAudioHelpersWeb } from '~/hooks/use-audio-helpers-web';
import { getStationScheduleUrl } from '~/routes';
import { useNavClickEvent } from '~/utils/analytics';

import LiveNow from './LiveNow';

interface ILiveNowWithState {
  isStation: boolean;
  module: IModule;
}

const LiveNowWithState = ({ isStation, module }: ILiveNowWithState): JSX.Element | null => {
  const {
    viewRootObjectId: contentId,
    viewRootObjectUrl: stationUrl,
    viewRootObjectRewindable: isRewindable,
  } = useModuleContext();
  const audioHelpers = useAudioHelpersWeb();
  const episode = useCachedLiveEpisodeFromSchedule({ contentId, isStation });

  const trackNavigation = useNavClickEvent({
    contentId: episode?.id,
    feature: ClickFeature.CTA_BUTTON,
    moduleId: module.id,
  });

  const playContext: IPlayContext = {
    feature: ClickFeature.MODULE,
    moduleId: module.id,
  };

  if (!contentId || !episode) return null;

  const liveUntil: INowPlaying | undefined =
    episode?.startDateTime && episode?.duration
      ? {
          playingUntil: getPlayingUntilFromStartAndDuration(
            episode.startDateTime,
            episode.duration,
          ),
        }
      : undefined;

  const onPlayLiveItem = () => {
    // We can assume LiveNow broadcast episode's are live. Only ScheduleItem
    // broadcasts are rewindable for now.
    audioHelpers.playEpisodeLive(episode, playContext);
  };

  const scheduleHref = stationUrl ? getStationScheduleUrl(stationUrl) : '';

  return (
    <>
      <LiveNow
        episode={episode}
        isStation={isStation}
        onPlayLiveItem={onPlayLiveItem}
        onFooterClick={trackNavigation}
        liveUntil={liveUntil}
        scheduleHref={scheduleHref}
        contentId={episode.id}
        playContext={playContext}
        isRewindable={isRewindable}
      />
    </>
  );
};

export default LiveNowWithState;
