import { useContext, useEffect } from 'react';
import Background from '~/components/Player/FullscreenPlayer/Background';
import { PlayerContext } from '~/components/Player/PlayerContextProvider';
import PlayerControls from '~/components/Player/PlayerControls';
import Scrubber from '~/components/Player/Scrubber';
import BottomOptions from './BottomOptions';
import ContentOverview from './ContentOverview';
import styles from './styles';

export type IFullPlayerProps = {
  isInfoVisible: boolean;
  isQueueVisible: boolean;
  onQueueClick: () => void;
};

const FullPlayer = ({
  isInfoVisible,
  isQueueVisible,
  onQueueClick,
}: IFullPlayerProps): JSX.Element => {
  const { setIsFullscreenPlayer } = useContext(PlayerContext);

  useEffect(() => {
    setIsFullscreenPlayer(true);
    return () => setIsFullscreenPlayer(false);
  }, [setIsFullscreenPlayer]);

  return (
    <div css={styles.container}>
      <Background />
      <ContentOverview
        isInfoVisible={isInfoVisible}
        isQueueVisible={isQueueVisible}
        onQueueClick={onQueueClick}
      />

      <div css={styles.controlsContainer}>
        <Scrubber shouldDisplayTime isFullPlayer />
        <PlayerControls />
        <BottomOptions isQueueVisible={isQueueVisible} onQueueClick={onQueueClick} />
      </div>
    </div>
  );
};

export default FullPlayer;
