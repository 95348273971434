import { Colors, Gradients, Mixins, mqHover, Typography } from '~/styles';
import { ButtonBorder, baseButton, disabled, label, themeWhite } from '~/styles/button';
import { TStyleSheet } from '~/types/emotion-styles';

import { ButtonHeights } from './constants';

const styles: TStyleSheet = {
  baseButton,
  button: {
    ...Typography.buttonLarge,
    borderRadius: 100,
    color: Colors.white,
  },
  disabled,
  iconContainer: {
    marginRight: 5,
  },
  iconContainerExtended: {
    left: 20,
    position: 'absolute',
  },
  label,
  sizeDefault: {
    height: ButtonHeights.default,
    maxHeight: ButtonHeights.default,
    padding: '0 20px',
  },
  sizeExtended: {
    height: ButtonHeights.extended,
    maxHeight: ButtonHeights.extended,
    width: '100%',
  },
  sizeLarge: {
    height: ButtonHeights.large,
    maxHeight: ButtonHeights.large,
    padding: '0 32px',
  },
  sizeSmall: {
    ...Mixins.accessibleMinButtonSize(),
    ...Typography.buttonSmall,
    height: ButtonHeights.small,
    maxHeight: ButtonHeights.small,
    padding: '0 14px',
  },
  themePremium: Mixins.transitionGradientBackground(Gradients.premium, Gradients.premiumHover),
  themePrimary: Mixins.transitionMixBackgroundColor(Colors.audacyOrange),
  themePrimaryDarkNavi70: Mixins.transitionMixBackgroundColor(Colors.audacyDarkNavy70),
  themeSecondary: {
    background: 'transparent',
    border: ButtonBorder.default,
    color: Colors.white,
    ...mqHover({
      background: 'transparent',
      border: ButtonBorder.hover,
    }),
  },
  themeSecondaryPremium: {
    border: ButtonBorder.default,
    ...mqHover({
      border: ButtonBorder.hover,
    }),
  },
  themeSecondaryPremiumLabel: {
    background: Gradients.premiumSecondary,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  themeWhite,
};

export default styles;
