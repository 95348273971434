import { inlineLink } from '~/components/Link/styles';
import { Colors, Typography } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  text: {
    ...Typography.bodyLarge,
    color: Colors.white,

    '& a': {
      ...inlineLink,
      display: 'inline',
      fontSize: 'inherit',
    },

    '& p, & ul, & ol': {
      marginBottom: 29, // equals line-height
    },

    '& p:only-child, & p:last-child, & ul:only-child, & ul:last-child, & ol:only-child, & ol:last-child, & li p':
      {
        marginBottom: 0,
      },

    '& li': {
      listStyleType: 'initial',
      marginLeft: 20,
    },
  },
};

export default styles;
