import { continueListeningSelector, useWrappedRecents } from '@audacy-clients/core/atoms/recents';
import { IModuleProps } from '@audacy-clients/core/components/ModuleRenderer/types';

import ModuleInViewWrapper from '~/components/ModuleRenderer/ModuleInViewWrapper';

const ModuleContinueListening: React.FC<IModuleProps> = ({ moduleMap, moduleViewComponent }) => {
  const continueListening = useWrappedRecents(continueListeningSelector);

  if (!continueListening?.module) {
    return null;
  }

  const { module: continueListeningModule, getContent } = continueListening;

  const ModuleViewComponent = moduleViewComponent;

  return (
    <ModuleViewComponent
      getData={getContent}
      module={continueListeningModule}
      moduleMap={moduleMap}
      moduleWrapper={ModuleInViewWrapper}
    />
  );
};

export default ModuleContinueListening;
