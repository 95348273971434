import { useEffect, useState, useRef } from 'react';

import { isLoggedIn } from '@audacy-clients/core/atoms/auth';
import { useClientServices } from '@audacy-clients/core/utils/clientServices';
import FocusTrap from 'focus-trap-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { syncAdPreferences } from '~/backend';
import Navigation from '~/components/Navigation';
import { Constants } from '~/components/Navigation/constants';
import mockNavigationSections from '~/components/Navigation/mocks';
import NavigationBar from '~/components/NavigationBar';
import { Constants as NavigationBarConstants } from '~/components/NavigationBar/constants';
import Overlay from '~/components/Overlay';
import SearchBar from '~/components/SearchBar';
import useBodyScrollLock from '~/hooks/use-body-scroll-lock';
import { useBranchJourney } from '~/hooks/use-branch-journey';
import { useBreakpoints } from '~/hooks/use-breakpoints';
import useNavigation from '~/hooks/use-navigation';
import { Path } from '~/routes';
import useAuth from '~/state/auth';
import { ZIndex, BP, mq } from '~/styles';
import { TStyles } from '~/types/emotion-styles';

import { navTransition } from './animation';
import styles from './styles';

const NavigationContainer = (): JSX.Element | null => {
  // Auth
  const { logOut } = useAuth();
  const { clientServices } = useClientServices();
  const isSearchPage = !!useMatch({ path: Path.Search });

  const { t } = useTranslation();

  const loggedIn = useRecoilValue(isLoggedIn);

  const personalizationServices = clientServices?.getPersonalizationServices();

  // Below effect adapted from mobile.
  useEffect(() => {
    if (loggedIn) {
      personalizationServices.setEventListener(() => {
        console.warn(t('authState.sessionExpired'), t('authState.logInAgain'), [
          {
            onPress: logOut,
            text: t('global.ok'),
          },
        ]);
      });
    } else {
      personalizationServices.setEventListener(undefined);
    }

    return () => personalizationServices.setEventListener(undefined);
  }, [loggedIn, logOut, personalizationServices, t]);

  // update adTargetingStatus if an anonymous user logs in
  useEffect(() => {
    if (loggedIn) {
      syncAdPreferences();
    }
  }, [loggedIn]);

  const { is, lessThan, isLoading } = useBreakpoints();
  const { isMenuOpen, handleCloseMenu, handleToggleMenu, closeButtonRef } = useNavigation();

  const navigate = useNavigate();
  const location = useLocation();
  const isHome = location.pathname === Path.Home;
  const navigationBarRef = useRef<HTMLDivElement>(null);
  const navigationRef = useRef<HTMLDivElement>(null);
  const [navigationContainerStyles, setNavigationContainerStyles] = useState<TStyles>({});
  const [navigationInnerContainerStyles, setNavigationInnerContainerStyles] = useState<TStyles>({});
  const isMenuOpenOnMedium = isMenuOpen && is.MD;
  const isMenuOpenOnSmall = isMenuOpen && is.SM;

  const { branchJourneyElement } = useBranchJourney({
    onOpen: handleCloseMenu,
    onClose: handleCloseMenu,
  });

  useBodyScrollLock(isMenuOpenOnMedium || isMenuOpenOnSmall, navigationRef.current);

  /**
   * Accounts for presence of Branch Journey Top banner
   * NavigationBar and Navigation menu are both position: fixed
   * When menu is opened, check the offsetTop of NavigationBar and
   * update the top position of Navigation menu
   */
  useEffect(() => {
    if (navigationBarRef.current) {
      const navigationBarOffsetTop = navigationBarRef.current.offsetTop;
      if (navigationBarOffsetTop > 0) {
        setNavigationContainerStyles({
          top: NavigationBarConstants.barHeight + navigationBarOffsetTop,
          [mq(BP.Medium)]: {
            top: `${navigationBarOffsetTop}px`,
          },
        });

        setNavigationInnerContainerStyles({
          height: `calc(100vh - ${NavigationBarConstants.barHeight + navigationBarOffsetTop}px)`,
          [mq(BP.Medium)]: {
            height: `calc(100vh - ${Constants.navPadding[BP.Medium] + navigationBarOffsetTop}px)`,
          },
        });
      } else {
        setNavigationContainerStyles({});
        setNavigationInnerContainerStyles({});
      }
    }
  }, [isMenuOpen]);

  if (isLoading) return null;

  return (
    <>
      {branchJourneyElement}
      <div>
        <FocusTrap active={isMenuOpenOnSmall || isMenuOpenOnMedium}>
          <header role="banner">
            {lessThan.LG ? (
              <NavigationBar
                isMenuOpen={isMenuOpen}
                onClickMenu={handleToggleMenu}
                shouldShowSearch={!isSearchPage || (isSearchPage && is.MD)}
                ref={navigationBarRef}
                navigate={navigate}
                isHome={isHome}
                onClose={handleCloseMenu}
              />
            ) : (
              !isSearchPage && (
                <div css={styles.searchContainer}>
                  <SearchBar />
                </div>
              )
            )}

            <div ref={navigationRef}>
              <Navigation
                isMenuOpen={isMenuOpen}
                onClose={handleCloseMenu}
                closeButtonRef={closeButtonRef}
                sections={mockNavigationSections}
                containerCss={navigationContainerStyles}
                innerContainerCss={navigationInnerContainerStyles}
                isHome={isHome}
                navigate={navigate}
              />
              <Overlay
                isAnimated
                isVisible={isMenuOpenOnMedium}
                onClick={handleToggleMenu}
                motionTransition={navTransition}
                zIndex={ZIndex.navOverlay}
              />
            </div>
          </header>
        </FocusTrap>
      </div>
    </>
  );
};

export default NavigationContainer;
