import { forwardRef } from 'react';

import Icon from '~/components/Icon';
import { type Icons } from '~/components/Icon/constants';
import useLinkOrButton from '~/hooks/use-link-or-button';
import { type TStyles } from '~/types/emotion-styles';
import {
  type IGlobalAsAnchorProps,
  type IGlobalAsButtonProps,
  LinkType,
  type TButtonOrAnchorRef,
} from '~/types/links';

import styles, { link as linkStyles } from './styles';

interface ILinkProps {
  hasIconAfter?: boolean;
  icon?: Icons;
  isInline?: boolean;
  isTiny?: boolean;
  label?: string;
  labelCss?: TStyles;
}

export interface ILinkAnchorProps extends IGlobalAsAnchorProps, ILinkProps {}
export interface ILinkButtonProps extends IGlobalAsButtonProps, ILinkProps {}
export type TLinkProps = ILinkAnchorProps | ILinkButtonProps;

const Link = (
  {
    as = LinkType.Anchor,
    children: linkChildren,
    hasIconAfter = false,
    icon,
    isInline = false,
    isTiny = false,
    label,
    labelCss,
    ...rest
  }: TLinkProps,
  ref: TButtonOrAnchorRef,
): JSX.Element => {
  // NB: the inline variation overrides icon and tiny styles.
  const iconEl = !isInline && icon && (
    <Icon
      iconCss={hasIconAfter ? styles.iconAfter : styles.iconBefore}
      name={icon}
      sizeInPx={isTiny ? 5 : 14}
      ariaHidden
    />
  );

  const children = (
    <>
      {!hasIconAfter && iconEl}
      <span
        css={[
          styles.label,
          !isInline && isTiny && styles.tiny,
          isInline && styles.inline,
          labelCss,
        ]}
      >
        {label || linkChildren}
      </span>
      {hasIconAfter && iconEl}
    </>
  );

  return useLinkOrButton({
    props: { as, ...rest },
    children,
    styles: linkStyles,
    ref,
  });
};

export default forwardRef(Link);
