import React from 'react';

import DOMPurify from 'dompurify';
import { stripHtml } from 'string-strip-html';

import { TStyles } from '~/types/emotion-styles';

import styles from './styles';

interface IProps {
  shouldStripImages?: boolean;
  shouldStripTags?: boolean;
  shouldStripInteractiveTags?: boolean;
  text?: string;
  textCss?: TStyles;
}

const HTML: React.FC<IProps> = ({
  shouldStripImages = true,
  shouldStripTags = false,
  shouldStripInteractiveTags = false,
  text = '',
  textCss,
}) => {
  let html = text;

  if (shouldStripImages) {
    html = stripHtml(text, {
      onlyStripTags: ['img'],
    }).result;
  }
  if (shouldStripTags) {
    html = stripHtml(html).result;
  } else if (shouldStripInteractiveTags) {
    html = stripHtml(html, {
      onlyStripTags: ['a', 'button'],
    }).result;
  } else {
    html = stripHtml(html, {
      cb: ({ tag, deleteFrom, deleteTo, rangesArr }) => {
        if (tag.name === 'a') {
          const href = tag.attributes.find((attr) => attr.name === 'href');

          if (!!href && deleteFrom && deleteTo) {
            rangesArr.push([
              deleteFrom,
              deleteTo,
              ` <a href="${href.value}" target="_blank" rel="noopener noreferrer">`,
            ]);
          }
        }
      },
    }).result;
  }

  return (
    <div
      css={[styles.text, textCss]}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(html, { ADD_ATTR: ['target', 'rel'] }),
      }}
    />
  );
};

export default HTML;
