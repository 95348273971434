/**
 * @module SimpleObject
 */
export default class SimpleObject {
  /**
   * Create a new instance of SimpleObject.
   * @param {ObjectType}  type
   */
  constructor(type) {
    this.dataObjectType = type;
  }

  /**
   * @returns {ObjectType}
   */
  getObjectType() {
    return this.dataObjectType;
  }
}
