import { playerControlsState } from '@audacy-clients/core/atoms/player';
import { isPlayerButtonDisabled, isPlayerButtonVisible } from '@audacy-clients/core/utils/player';
import { useRecoilValue } from 'recoil';

import usePlayerControls from '~/components/Player/hooks/usePlayerControls';
import { IPlayerAction } from '~/components/Player/PlayerControls/types';
import { getPrimaryAction, getSecondaryAction } from '~/components/Player/PlayerControls/utils';

type TControlActions = {
  primaryControl: IPlayerAction;
  leftControl?: IPlayerAction;
  rightControl?: IPlayerAction;
  nextBtn?: IPlayerAction;
  prevBtn?: IPlayerAction;
};
const useGetControlActions = (): TControlActions => {
  const playerControls = usePlayerControls();
  const { isLiked, onPlay, onStop, onPause, onLike, onSendNext, onSkip, onNext, onPrev } =
    playerControls;

  const {
    ff15sButton,
    pauseButton,
    rw15sButton,
    skipButton,
    stopButton,
    thumbsUpButton,
    nextTrackButton,
    prevTrackButton,
  } = useRecoilValue(playerControlsState) || {};

  const canSkip = isPlayerButtonVisible(skipButton);
  const canScrub = isPlayerButtonVisible(ff15sButton) && isPlayerButtonVisible(rw15sButton);
  const canSwitchTracks =
    isPlayerButtonVisible(nextTrackButton) || isPlayerButtonVisible(prevTrackButton);
  const hasPause = isPlayerButtonVisible(pauseButton, true);
  const hasStop = isPlayerButtonVisible(stopButton, true);
  const isForwardDisabled = isPlayerButtonDisabled(ff15sButton);
  const isLikeDisabled = isPlayerButtonDisabled(thumbsUpButton);
  const isRewindDisabled = isPlayerButtonDisabled(rw15sButton);
  const isSkipDisabled = isPlayerButtonDisabled(skipButton);
  const isNextDisabled = isPlayerButtonDisabled(nextTrackButton);
  const isPrevDisabled = isPlayerButtonDisabled(prevTrackButton);

  const primaryAction = getPrimaryAction({ onPause, onPlay, onStop, hasPause, hasStop }) || {};
  const { leftControl, rightControl, prevBtn, nextBtn } = getSecondaryAction({
    canScrub,
    canSkip,
    canSwitchTracks,
    isRewindDisabled,
    isForwardDisabled,
    isLiked,
    isSkipDisabled,
    isLikeDisabled,
    isNextDisabled,
    isPrevDisabled,
    onLike,
    onSendNext,
    onSkip,
    onNext,
    onPrev,
  });

  return {
    primaryControl: primaryAction,
    leftControl,
    rightControl,
    prevBtn,
    nextBtn,
  };
};

export default useGetControlActions;
