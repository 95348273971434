import AudacyError from '@audacy-clients/client-services/src/AudacyError';

import Error from '~/components/Error/';
import PageNotFound from '~/components/Error/404';
import { Mixins } from '~/styles';

interface IErrorScreenProps {
  error?: Error | AudacyError;
}

const FullScreenError = ({ error }: IErrorScreenProps): JSX.Element => {
  const is404 = !!error && 'audacyErrorJson' in error && error?.audacyErrorJson?.code === 404;
  return <div css={Mixins.fullScreenContainer}>{is404 ? <PageNotFound /> : <Error />}</div>;
};

export default FullScreenError;
