import DataObject from './DataObject';
import { ObjectType } from '../Constants';
import Container from '../Container';
import StationSummary from './StationSummary';

const serviceBus = Container;

/** @typedef { import("./EpisodeList")            } EpisodeList */
/** @typedef { import('../Constants').PodcastSort } PodcastSort */
/** @typedef { import('../Constants').PodcastType } PodcastType */

/**
 * @module Show
 */
export default class Show extends DataObject {
  /**
   * Creates a new instance of a Show.
   * @param {ShowJson} data
   */
  constructor(data) {
    super(ObjectType.SHOW, data);

    DataObject.cleanJsonObject(this.data);
  }

  /**
   * Get the show author.
   * @returns {String}
   */
  getAuthor() {
    return this.data.author;
  }

  /**
   * Get the show description.
   * @returns {String}
   */
  getDescription() {
    return this.data.description;
  }

  /**
   * Get the list of episodes associated with the show.
   * @param {PodcastSort} [sort=PodcastSort.DATE_DESC]
   * @param {Boolean}     [bust]
   * @returns {Promise<EpisodeList>}
   */
  getEpisodeList(sort, bust) {
    return serviceBus.dataServices.getEpisodes(this.getId(), 0, sort, bust);
  }

  /**
   * Get the text description of the image associated with the entity.
   * @returns {String}
   */
  getImageAlt() {
    return this.data.images && this.data.images.alt;
  }

  /**
   * Get the square show image.
   * @returns {String}
   */
  getImageSquare() {
    return this.data.images && this.data.images.square;
  }

  /**
   * Get the meta description.
   * @returns {String}
   */
  getMetaDescription() {
    return this.data.metaDescription;
  }

  /**
   * Get the meta title.
   * @returns {String}
   */
  getMetaTitle() {
    return this.data.metaTitle;
  }

  /**
   * Get the show parent station.
   * @returns {StationSummary | undefined}
   */
  getParentStation() {
    return this.data.parentStation && new StationSummary(this.data.parentStation);
  }

  /**
   * Get the podcast type.
   * @returns {PodcastType}
   */
  getPodcastType() {
    return this.data.podcastType;
  }

  /**
   * Get the subtitle.
   * @returns {String}
   */
  getSubtitle() {
    return this.data.subtitle;
  }

  /**
   * Get the showImageOverride value.
   * @returns {Boolean}
   */
  getAllowShowImageOverride() {
    return this.data.allowShowImageOverride;
  }

  /**
   * @returns {Boolean}
   */
  isFollowable() {
    return this.data.followable;
  }
}
