export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Sound system</title>
    <path stroke="currentColor" strokeWidth="1.5" d="M7 4h18v24H7z" />
    <circle cx="16" cy="9" r="2" fill="currentColor" />
    <circle cx="16" cy="19" r="5" stroke="currentColor" strokeWidth="1.5" />
  </svg>
);
