import { container, text } from '~/components/PlayStateArea/styles';
import { BP, Colors, mq } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: {
    ...container,
    gap: 4,
    color: Colors.audacyOrange,
    marginTop: 5,
    [mq(BP.Medium)]: {
      marginTop: 6,
    },
    [mq(BP.Large)]: {
      marginTop: 10,
    },
  },
  text: {
    ...text,
    color: Colors.audacyOrange,
  },
  gapContainer: {
    alignItems: 'center',
    display: 'inline-flex',
    gap: 5,
  },
};

export default styles;
