/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useContext } from 'react';

import { CSSObject } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon';
import { FullSecondaryIconSize } from '~/components/Player/FullscreenPlayer/Components/FullPlayer/constants';
import { PlayerContext } from '~/components/Player/PlayerContextProvider';
import { fullPlayerStyles, miniPlayerStyles } from '~/components/Player/PlayerControls/styles';
import { IPlayerAction } from '~/components/Player/PlayerControls/types';
import { useBreakpoints } from '~/hooks/use-breakpoints';

export const PlayerSecondaryButton = ({
  action,
  children,
}: {
  action: IPlayerAction;
  children?: JSX.Element | boolean;
}): JSX.Element => {
  const { isFullscreenPlayer } = useContext(PlayerContext);

  const { t } = useTranslation();
  const { bk } = useBreakpoints();

  //@ts-ignore
  const styles: CSSObject = isFullscreenPlayer ? fullPlayerStyles : miniPlayerStyles;
  const secondaryIconSize = isFullscreenPlayer ? FullSecondaryIconSize[bk] : 20;

  return (
    <button
      aria-label={t(...action.accessibilityLabel)}
      css={styles.secondaryActionBtn}
      disabled={action.isDisabled}
      onClick={action.onClick}
    >
      <Icon ariaHidden name={action.icon} sizeInPx={secondaryIconSize} />
      {children}
    </button>
  );
};
