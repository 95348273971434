import { EntityType, type ListState } from '@audacy-clients/client-services/core';
import { type IPlayerMetadata } from '@audacy-clients/client-services/src/audioServices/players/types';
import { type IPlayerControlsState } from '@audacy-clients/client-services/src/audioServices/streamers/types';
import { isContentTypeAd } from '@audacy-clients/client-services/src/audioServices/streamers/utils';
import {
  ECollectionPlaybackMode,
  type EContentType,
} from '@audacy-clients/client-services/src/audioServices/types';
import type Collection from '@audacy-clients/client-services/src/dataServices/Collection';
import {
  noCacheDefaultSelector,
  noCacheDefaultSelectorFamily,
} from '@audacy-clients/core/atoms/helpers/noCacheDefaultSelector';
import {
  type IEpisode,
  type IStandaloneChapter,
  type TPartialWrappedContentObject,
} from '@audacy-clients/core/atoms/wrappers/types';
import { MIN_TIME_OFFSET_TO_ENABLE_RW_FF } from '@audacy-clients/core/constants/player';
import { type IImageUrl } from '@audacy-clients/core/types/images';
import { PlaybackFractionPositionType, PlayerState } from '@audacy-clients/core/types/player';
import clientServices from '@audacy-clients/core/utils/clientServices';
import { checkCollectionIdsForPlaying } from '@audacy-clients/core/utils/collection';
import { customFormatDuration } from '@audacy-clients/core/utils/date';
import { isRewindEpisode } from '@audacy-clients/core/utils/episode';
import { mapPlayerButtonsState, mapPlayerListButtonState } from '@audacy-clients/core/utils/player';
import { checkContentPosition } from '@audacy-clients/core/utils/playlist';
import { atom, selector, useRecoilTransaction_UNSTABLE } from 'recoil';

import { currentChapterPositionTypeState } from './chapters';
import {
  activeCollectionState,
  collectionPlaybackModeState,
  selectedPlaylistIdState,
} from './collections';
import { deepEqualSelector } from './helpers/deepEqualSelector';

export type IPlayerVolumeState = {
  isMuted: boolean;
  volume: number;
};

export type IPlaybackSpeedState = {
  currentRate: number;
  savedRate: number;
  availableRates: ReadonlyArray<number>;
};

export const Constants = {
  defaultDuration: 1,
};

export type IMetadata = {
  dataObject?: TPartialWrappedContentObject;
  episodeDataObject?: IEpisode;
  standaloneChapterDataObject?: IStandaloneChapter;
} & IPlayerMetadata;

export const playerMetadataState = atom<IMetadata | undefined>({
  default: undefined,
  key: 'PlayerMetadataState',
});

type IPlayerTimeState = {
  duration?: number;
  offset?: number;
  playbackFraction?: number;
  playbackLiveFraction?: number;
  remaining?: number;
};

export const playerTimeState = atom<IPlayerTimeState>({
  default: {},
  key: 'PlayerTimeState',
});

export type IPlayerState = {
  contentType?: EContentType;
  listState?: ListState;
  playState: PlayerState; // TODO: [CCS-2795] rename this to something clearer and use actual state machine value once we can make it typesafe
};

const playerState = atom<IPlayerState>({
  default: {
    playState: PlayerState.Idle,
  },
  key: 'PlayerState',
});

export default playerState;

export const playButtonDisabledState = atom<boolean>({
  default: false,
  key: 'PlayButtonDisabledState',
});

export const playerVolumeState = atom<IPlayerVolumeState>({
  default: {
    isMuted: false,
    volume: 1,
  },
  key: 'PlayerVolumeState',
});

export const playerSpeedState = atom<IPlaybackSpeedState>({
  default: {
    availableRates: [],
    currentRate: 1,
    savedRate: 0,
  },
  key: 'PlayerSpeedState',
});

export const playerLoadingState = atom<boolean>({
  default: false,
  key: 'PlayerLoadingState',
});

export const isAdState = selector<boolean>({
  key: 'isAdState',
  get: ({ get }) => {
    const metadata = get(playerMetadataState);
    return isContentTypeAd(metadata?.contentType);
  },
});

export type INowPlayingState = {
  artist?: string;
  dataObject?: TPartialWrappedContentObject;
  duration?: number;
  endTime?: number;
  episodeDataObject?: IEpisode;
  id?: string;
  image?: IImageUrl;
  skips?: number;
  startTime?: number;
  station?: string;
  standaloneChapterDataObject?: IStandaloneChapter;
  title?: string;
};

export const nowPlaying = deepEqualSelector<INowPlayingState | undefined>({
  get: ({ get }) => {
    const metadata = get(playerMetadataState);

    if (!metadata) {
      return undefined;
    }

    const {
      dataObject,
      artist,
      image: imageUrl,
      standaloneChapterDataObject,
      episodeDataObject,
      station,
      songOrShow,
      stop: endTime,
      start: startTime,
      skips,
      id,
    } = metadata;

    const resolvedEpisodeDataObject =
      episodeDataObject ??
      (dataObject?.type === EntityType.EPISODE ? (dataObject as IEpisode) : undefined);

    const resolvedStandaloneChapterDataObject =
      standaloneChapterDataObject ??
      (dataObject?.type === EntityType.STANDALONE_CHAPTER
        ? (dataObject as IStandaloneChapter)
        : undefined);

    const imageFallbackUrl = dataObject?.image || dataObject?.parentImage;
    const image = {
      src: imageUrl ? encodeURI(imageUrl) : undefined,
      srcFallback:
        imageFallbackUrl && imageFallbackUrl !== imageUrl ? encodeURI(imageFallbackUrl) : undefined,
    };

    const title =
      songOrShow ||
      resolvedEpisodeDataObject?.title ||
      resolvedEpisodeDataObject?.parentTitle ||
      dataObject?.title;

    return {
      artist,
      dataObject,
      endTime,
      episodeDataObject: resolvedEpisodeDataObject,
      standaloneChapterDataObject: resolvedStandaloneChapterDataObject,
      image,
      startTime,
      station,
      title,
      duration: dataObject?.duration,
      id: resolvedEpisodeDataObject?.id || dataObject?.id || id,
      skips,
    };
  },
  key: 'NowPlaying',
});

export enum EAuxControl {
  Forward15 = 'Forward15',
  NextTrack = 'NextTrack',
  PrevTrack = 'PrevTrack',
  Rewind = 'Rewind',
  Skip = 'Skip',
  Thumbs = 'Thumbs',
}

export const playerSkipsState = noCacheDefaultSelector({
  get: ({ get }) => get(playerMetadataState)?.skips,
  key: 'PlayerPlayerSkipsStateState',
});

export const playerDurationState = noCacheDefaultSelector({
  get: ({ get }) => ({
    id: get(playerMetadataState)?.dataObject?.id,
    duration: get(playerMetadataState)?.duration,
  }),
  key: 'PlayerDurationState',
});

export const playerCompanionAdIframeUrlState = noCacheDefaultSelector({
  get: ({ get }) => get(playerMetadataState)?.companionAdIframeUrl,
  key: 'PlayerCompanionAdIframeUrlState',
});

export const playerLiveFractionState = noCacheDefaultSelector({
  get: ({ get }) => get(playerTimeState).playbackLiveFraction,
  key: 'PlayerLiveFractionState',
});

export const playerContentIsLiveState = noCacheDefaultSelector<boolean>({
  get: ({ get }) => {
    const metadata = get(playerMetadataState);
    return metadata?.isLive || false;
  },
  key: 'PlayerContentIsLiveState',
});

export const playerContentIsRewindableState = noCacheDefaultSelector({
  get: ({ get }) => {
    const { episodeDataObject, isRewindable } = get(playerMetadataState) || {};
    return !!isRewindable && isRewindEpisode(episodeDataObject);
  },
  key: 'PlayerContentIsRewindableState',
});

const playerFractionPositionTypeState = noCacheDefaultSelector<PlaybackFractionPositionType>({
  get: ({ get }) => {
    const { offset = 0, duration } = get(playerTimeState);
    const metadata = get(playerMetadataState);

    if (offset < MIN_TIME_OFFSET_TO_ENABLE_RW_FF) {
      return PlaybackFractionPositionType.Start;
    }

    if (duration !== undefined && offset + MIN_TIME_OFFSET_TO_ENABLE_RW_FF >= duration) {
      return PlaybackFractionPositionType.End;
    }

    if (metadata?.isLive) {
      return PlaybackFractionPositionType.End;
    }

    return PlaybackFractionPositionType.Middle;
  },
  key: 'PlayerFractionPositionTypeState',
});

export const playerControlsState = noCacheDefaultSelector<IPlayerControlsState | undefined>({
  get: ({ get }) => {
    const { playState, contentType, listState } = get(playerState);
    const collectionPlaybackMode = get(collectionPlaybackModeState);
    const activeCollection = get(activeCollectionState);
    const currentContent = get(nowPlaying);
    const metadata = get(playerMetadataState);
    const { skips = 0 } = metadata || {};
    const isAudioPreroll = metadata?.isAudioPreroll || false;

    const fractionPositionType = get(playerFractionPositionTypeState);
    const chapterPositionType = get(currentChapterPositionTypeState);

    const isLive = get(playerContentIsLiveState);

    const { isFirst, isLast } = checkContentPosition(activeCollection, currentContent);

    const playerItem = get(nowPlaying);
    const isStandaloneChapter = playerItem?.dataObject?.type === EntityType.STANDALONE_CHAPTER;

    const buttonsState = mapPlayerListButtonState(
      mapPlayerButtonsState({
        chapterPositionType,
        playState,
        contentType,
        fractionPositionType,
        skipsRemaining: skips,
        isLive,
        isAd: isContentTypeAd(metadata?.contentType),
        collectionPlaybackMode,
        isFirst,
        isLast,
        isStandaloneChapter,
        isAudioPreroll,
      }),
      listState,
    );
    const scrubberIsUserControlled = buttonsState.scrubberIsUserControlled;
    return {
      ...buttonsState,
      scrubberIsUserControlled,
    };
  },
  key: 'PlayerControlsState',
});

export const checkPlayingById = noCacheDefaultSelectorFamily({
  get:
    ({ contentId }: { contentId?: string }) =>
    ({ get }) => {
      const { playState } = get(playerState);
      const metadata = get(playerMetadataState);

      const selectedPlaylistId = get(selectedPlaylistIdState);
      const collectionPlaybackMode = get(collectionPlaybackModeState);

      const metadataId = metadata?.dataObject?.id;
      const episodeDataId = metadata?.episodeDataObject?.id;

      const isPlaying = playState === PlayerState.Playing;
      const isPlaylist = collectionPlaybackMode === ECollectionPlaybackMode.Playlist;

      if (!isPlaying) {
        return false;
      }

      if (isPlaylist && selectedPlaylistId === contentId) {
        return true;
      }

      if (metadataId || episodeDataId) {
        return metadataId === contentId || episodeDataId === contentId;
      }
      return false;
    },
  key: 'CheckPlayingById',
});

export const checkIsCollectionPlayingById = noCacheDefaultSelectorFamily({
  get:
    (id: string) =>
    async ({ get }) => {
      const { playState } = get(playerState);
      const metadata = get(playerMetadataState);
      const content: Partial<Collection> = await clientServices
        .getDataServices()
        .getContentObject(id);

      return checkCollectionIdsForPlaying(playState, content.getItems?.(), metadata);
    },
  key: 'CheckPlayingBydId',
});

export const checkPlayerLoadingById = noCacheDefaultSelectorFamily({
  get:
    (id) =>
    ({ get }) => {
      const { playState } = get(playerState);
      const metadata = get(playerMetadataState);

      const metadataId = metadata?.dataObject?.id;
      const episodeDataId = metadata?.episodeDataObject?.id;

      if (metadataId || episodeDataId) {
        return playState === PlayerState.Loading && (metadataId === id || episodeDataId === id);
      }
      return false;
    },
  key: 'CheckPlayerLoadingById',
});

export const checkPlayedById = noCacheDefaultSelectorFamily({
  get:
    (id) =>
    ({ get }) => {
      const { playState } = get(playerState);
      const metadata = get(playerMetadataState);

      if (metadata?.dataObject) {
        return playState === PlayerState.Ended && metadata.dataObject.id === id;
      }
      return false;
    },
  key: 'CheckPlayedById',
});

export const playerFormattedHoursMinutesRemaining = deepEqualSelector({
  get: ({ get }) => {
    let formattedTime;
    const playerTime = get(playerTimeState);
    const playerNowPlaying = get(nowPlaying);
    if (
      (playerNowPlaying?.standaloneChapterDataObject || playerNowPlaying?.episodeDataObject) &&
      playerTime?.remaining
    ) {
      formattedTime = customFormatDuration(playerTime?.remaining);
    }
    return {
      duration: {
        hours: formattedTime?.duration.hours,
        minutes: formattedTime?.duration.minutes,
      },
      label: formattedTime?.label,
    };
  },
  key: 'PlayerFormattedHoursMinutesRemaining',
});

export const isInPlayer = noCacheDefaultSelectorFamily({
  get:
    (id) =>
    ({ get }) => {
      const metadata = get(playerMetadataState);

      return metadata?.dataObject?.id === id || metadata?.episodeDataObject?.id === id;
    },
  key: 'IsInPlayer',
});

export const checkPausedById = noCacheDefaultSelectorFamily({
  get:
    (id) =>
    ({ get }) => {
      const { playState } = get(playerState);
      const metadata = get(playerMetadataState);

      if (metadata?.dataObject) {
        return playState === PlayerState.Paused && metadata?.dataObject.id === id;
      }
      return false;
    },
  key: 'CheckPausedBydId',
});

export const shouldShowPlayerState = noCacheDefaultSelector({
  get: ({ get }) => {
    const hasMetadata = get(playerMetadataState);
    const isPlayerLoading = get(shouldShowPlayerLoadingState);

    return !!hasMetadata || isPlayerLoading;
  },
  key: 'ShouldShowPlayerState',
});

export const shouldShowPlayerLoadingState = noCacheDefaultSelector({
  get: ({ get }) => {
    const { playState } = get(playerState);
    const isLoading = get(playerLoadingState);

    return playState === PlayerState.Loading || isLoading;
  },
  key: 'shouldShowPlayerLoadingState',
});

export const useResetPlayerState = (): ((isLoading?: boolean) => void) => {
  return useRecoilTransaction_UNSTABLE(
    ({ set, reset }) =>
      (isLoading = false) => {
        reset(playerMetadataState);
        reset(playerState);
        reset(playerTimeState);

        set(playerLoadingState, isLoading);
      },
    [],
  );
};
