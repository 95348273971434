import { EpisodeSubType } from '@audacy-clients/client-services/core';
import { IChapter } from '@audacy-clients/core/atoms/wrappers/chapter';
import { IEpisode } from '@audacy-clients/core/atoms/wrappers/types';
import { customFormatDuration, formatFromTo, formatTime } from '@audacy-clients/core/utils/date';
import { Characters } from '@audacy-clients/core/utils/strings';
import { formatSecondsToTime } from '@audacy-clients/core/utils/time';
import { fromUnixTime } from 'date-fns';
import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon';
import { Icons } from '~/components/Icon/constants';

import styles from './styles';

interface IChapterItemProps {
  chapter: IChapter;
  episode: IEpisode;
  accessibilityLabelIndex: string;
  onClick: (chapter: IChapter) => void;
  isLiveChapter?: boolean;
  isPlaying?: boolean;
}

const ChapterItem = ({
  chapter,
  episode,
  accessibilityLabelIndex,
  onClick,
  isPlaying,
  isLiveChapter,
}: IChapterItemProps): JSX.Element => {
  const { t } = useTranslation();
  const { duration, startOffset, title } = chapter;
  const { startTime, subtype, isLive } = episode;

  const isShowEpisode = subtype === EpisodeSubType.BROADCAST_SHOW_EPISODE;
  let accessibilityLabelTime = '';
  let chapterTitle = title;
  let startOffsetSeconds = startOffset;
  let timeLabel = formatSecondsToTime(startOffsetSeconds);
  const formattedDuration = customFormatDuration(duration);
  const showLiveIndicator = !!(isLiveChapter && isLive);
  const durationLabel = showLiveIndicator ? t('global.onNow') : formattedDuration.label;

  if (isShowEpisode) {
    const startTimeSeconds = startTime / 1000;
    const fromToString = formatFromTo(startOffset * 1000, (startOffset + duration) * 1000);

    // Currently the only way to identify a placeholder chapter is by the word "Part" in the title
    const isPlaceholderTitle = title?.substring(0, 4) === 'Part';
    if (isPlaceholderTitle) {
      chapterTitle = showLiveIndicator ? title : `${title} ${Characters.bullet} ${fromToString}`;

      // In a future state, placeholder chapters will not have a `title`
    } else if (!title) {
      chapterTitle = t('chapters.chapterWithoutTitle', {
        index: chapter.index + 1,
        separator: Characters.bullet,
        fromToString,
      });
    }

    timeLabel = formatTime(fromUnixTime(startOffset), false);
    accessibilityLabelTime = `- ${t('accessibilityLabels.duration', {
      from: timeLabel,
      to: fromToString.split('-')[1],
    })}`;
    startOffsetSeconds = startOffset - startTimeSeconds;
  }

  return (
    <li css={styles.item}>
      <button
        css={styles.button}
        onClick={() => onClick(chapter)}
        aria-label={`${title} - ${accessibilityLabelIndex} ${accessibilityLabelTime}`}
      >
        <span css={[styles.pill, isPlaying && styles.pillPlaying]}>{timeLabel}</span>
        <div css={[styles.label, isPlaying && styles.labelPlaying]}>
          {chapterTitle}
          <span css={styles.duration}>{durationLabel}</span>
          {showLiveIndicator && (
            <Icon name={Icons.LiveSM} sizeInPx={16} iconCss={styles.liveIcon} />
          )}
        </div>
      </button>
    </li>
  );
};

export default ChapterItem;
