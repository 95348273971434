import { Borders, BP, Colors, Mixins, mq, Timing, ZIndex } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

import { Constants } from './constants';

const styles: TStyleSheet = {
  container: {
    alignItems: 'center',
    backgroundColor: Colors.audacyDarkNavy,
    borderBottom: Borders.separator,
    display: 'flex',
    height: Constants.barHeight,
    justifyContent: 'space-between',
    padding: '15px 20px',
    position: 'fixed',
    width: '100%',
    zIndex: ZIndex.navigationBarSmall,
    [mq(BP.Medium)]: {
      padding: `0 ${Constants.verticalSpacingMd}px`,
      zIndex: ZIndex.navigationBar,
    },
    // Obscure page content above nav bar when Branch journey banner is dismissed
    '&::before': {
      content: '""',
      backgroundColor: Colors.audacyDarkNavy,
      position: 'absolute',
      left: 0,
      top: '-100vh',
      height: '100vh',
      width: '100%',
    },
  },
  brandLink: {
    ...Mixins.transition(['transform'], Timing.navigationMenuOpen),
    ...Mixins.reduceMotion(),
  },
  brandLinkOpen: {
    transform: `translate3d(calc(50vw - ${Constants.logoWidth}px), 0, 0)`,
  },
  logo: {
    alignItems: 'center',
    display: 'flex',
    height: Constants.logoHeight,
    width: Constants.logoWidth,
  },
  navLeft: {
    display: 'flex',
    gap: 23,
  },
  navLeftSmall: {
    width: '100%',
  },
  navRight: {
    display: 'flex',
    gap: 17,
    [mq(BP.Medium)]: {
      gap: 20,
    },
  },
  navRightSearchOpen: {
    position: 'absolute',
    width: '100%',
    left: 0,
    padding: '0 20px',
    backgroundColor: Colors.audacyDarkNavy,
  },
  getAppButton: {
    alignSelf: 'center',
  },
  searchButtonMobile: {
    alignSelf: 'center',
    [mq(BP.Medium)]: {
      display: 'none',
    },
  },
};

export default styles;
