import Collection from '@audacy-clients/client-services/src/dataServices/Collection';
import { getNullSafeDate } from '@audacy-clients/core/utils/date';

import { wrapDataObject } from '.';
import { ICollection, IImageMetadata } from './types';

export const wrapCollection = (collection: Collection): ICollection => ({
  ...wrapDataObject(collection),
  author: collection.getAuthor?.(),
  durationSeconds: collection.getDuration?.(),
  image: collection.getImageSquare?.(),
  imageAlt: collection.getImageAlt?.(),
  imageHero: collection.getImageHero?.(),
  imageMetadata: collection?.getImageMetadata?.() as IImageMetadata,
  itemsIdList: collection.getItems?.(),
  contentUpdated: getNullSafeDate(collection.getUpdatedTime?.()),
  updateDate: getNullSafeDate(collection.getUpdatedDate?.()),
});
