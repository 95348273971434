import { BP, Colors, Mixins, mq, mqHover } from '~/styles';
import { TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  wrapper: {
    position: 'relative',
    height: 28,
  },
  button: {
    ...Mixins.accessibleMinButtonSize(),
    ...Mixins.transition(['color', 'background-color']),
    height: 28,
    padding: '0 8px',

    [mq(BP.Large)]: {
      border: `1px solid ${Colors.white30}`,
      borderRadius: 40,
      position: 'absolute',
      transform: 'translateX(-50%)',
    },

    ...mqHover({
      color: Colors.white60,
    }),
  },
  buttonLeft: {
    [mq(BP.Large)]: {
      transform: 'none',
    },
  },
};

export default styles;
