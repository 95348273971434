import { ListState } from '@audacy-clients/client-services/core';
import {
  IPlayerControlsState,
  PlayerButtonState,
  ScrubberBarType,
} from '@audacy-clients/client-services/src/audioServices/streamers/types';
import {
  ECollectionPlaybackMode,
  EContentType,
} from '@audacy-clients/client-services/src/audioServices/types';
import { isMobileIOSDevice } from '@audacy-clients/client-services/src/Utilities';
import { PLAYER_CONTROL_STATE_DEFAULT } from '@audacy-clients/core/constants/player';
import {
  CurrentChapterPositionType,
  PlaybackFractionPositionType,
  PlayerState,
} from '@audacy-clients/core/types/player';

const ENABLED = PlayerButtonState.VisibleEnabled;
const DISABLED = PlayerButtonState.VisibleDisabled;
const HIDDEN = PlayerButtonState.Hidden;

export const isPlayerButtonVisible = (
  buttonState: PlayerButtonState | undefined,
  isVisibleEnabledOnly = false,
): boolean => {
  if (isVisibleEnabledOnly) {
    return buttonState === PlayerButtonState.VisibleEnabled;
  }

  return (
    buttonState === PlayerButtonState.VisibleEnabled ||
    buttonState === PlayerButtonState.VisibleDisabled
  );
};

export const isPlayerButtonDisabled = (buttonState: PlayerButtonState | undefined): boolean => {
  return buttonState === PlayerButtonState.VisibleDisabled;
};

export const mapPlayerButtonsState = (params: {
  chapterPositionType?: CurrentChapterPositionType;
  contentType?: EContentType;
  fractionPositionType?: PlaybackFractionPositionType;
  isAd?: boolean;
  isLive?: boolean;
  playState?: PlayerState;
  skipsRemaining?: number;
  collectionPlaybackMode?: ECollectionPlaybackMode;
  isFirst?: boolean;
  isLast: boolean;
  isStandaloneChapter?: boolean;
  isAudioPreroll?: boolean;
}): IPlayerControlsState => {
  const {
    chapterPositionType,
    contentType,
    fractionPositionType,
    isAd = false,
    isLive = false,
    playState,
    skipsRemaining,
    collectionPlaybackMode,
    isFirst,
    isLast,
    isStandaloneChapter,
    isAudioPreroll,
  } = params;

  const isPlaying = playState === PlayerState.Playing;
  const isPaused = playState === PlayerState.Paused;
  const isEnded = playState === PlayerState.Ended;
  const isLoading = playState === PlayerState.Loading;
  const isError = playState === PlayerState.Error;

  if (contentType === undefined) {
    if (isPaused) {
      return {
        ...PLAYER_CONTROL_STATE_DEFAULT,
        playButton: ENABLED,
      };
    }
    return PLAYER_CONTROL_STATE_DEFAULT;
  }

  const rw15sButton =
    fractionPositionType === PlaybackFractionPositionType.Start ? DISABLED : ENABLED;

  const ff15sButton =
    fractionPositionType === PlaybackFractionPositionType.End ? DISABLED : ENABLED;

  const previousChapterButton = () => {
    if (isAd || chapterPositionType === CurrentChapterPositionType.StartAndEnd) {
      return HIDDEN;
    }
    if (chapterPositionType === CurrentChapterPositionType.Start) {
      return DISABLED;
    } else {
      return ENABLED;
    }
  };

  const nextChapterButton = () => {
    if (isAd || chapterPositionType === CurrentChapterPositionType.StartAndEnd) {
      return HIDDEN;
    }
    if (chapterPositionType === CurrentChapterPositionType.End || isLive) {
      return DISABLED;
    } else {
      return ENABLED;
    }
  };

  const restartShowButton =
    isAd || chapterPositionType === CurrentChapterPositionType.StartAndEnd ? HIDDEN : ENABLED;

  const prevTrackButton = isFirst
    ? PlayerButtonState.VisibleDisabled
    : PlayerButtonState.VisibleEnabled;

  const nextTrackButton = isLast
    ? PlayerButtonState.VisibleDisabled
    : PlayerButtonState.VisibleEnabled;

  if (
    collectionPlaybackMode === ECollectionPlaybackMode.Playlist ||
    collectionPlaybackMode === ECollectionPlaybackMode.Queue
  ) {
    if (playState === PlayerState.Playing) {
      return {
        ...PLAYER_CONTROL_STATE_DEFAULT,
        prevTrackButton,
        nextTrackButton,
        ff15sButton,
        rw15sButton,
        playButton: PlayerButtonState.Hidden,
        pauseButton: PlayerButtonState.VisibleEnabled,
        scrubberIsUserControlled: true,
      };
    } else if (playState === PlayerState.Paused) {
      return {
        ...PLAYER_CONTROL_STATE_DEFAULT,
        prevTrackButton,
        nextTrackButton,
        ff15sButton,
        rw15sButton,
        playButton: PlayerButtonState.VisibleEnabled,
        pauseButton: PlayerButtonState.Hidden,
        scrubberIsUserControlled: true,
      };
    } else if (playState === PlayerState.Loading) {
      return {
        ...PLAYER_CONTROL_STATE_DEFAULT,
        prevTrackButton: PlayerButtonState.VisibleDisabled,
        nextTrackButton: PlayerButtonState.VisibleDisabled,
        ff15sButton: PlayerButtonState.VisibleDisabled,
        rw15sButton: PlayerButtonState.VisibleDisabled,
        playButton: PlayerButtonState.VisibleDisabled,
        pauseButton: PlayerButtonState.Hidden,
        scrubberIsUserControlled: false,
      };
    }
  }

  switch (contentType) {
    case EContentType.Podcast:
      if (isPlaying) {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          ff15sButton:
            fractionPositionType === PlaybackFractionPositionType.End ? DISABLED : ENABLED,
          pauseButton: ENABLED,
          playButton: DISABLED,
          replayButton: HIDDEN,
          rw15sButton,
          scrubberIsUserControlled: true,
          scrubberIsDisabled: false,
        };
      } else if (isPaused) {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          ff15sButton:
            fractionPositionType === PlaybackFractionPositionType.End ? DISABLED : ENABLED,
          pauseButton: DISABLED,
          playButton: ENABLED,
          replayButton: HIDDEN,
          rw15sButton,
          scrubberIsUserControlled: true,
        };
      } else if (isLoading) {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          ff15sButton: DISABLED,
          pauseButton: ENABLED,
          playButton: DISABLED,
          replayButton: HIDDEN,
          rw15sButton: DISABLED,
          scrubberIsUserControlled: true,
          scrubberIsDisabled: true,
        };
      } else if (isEnded) {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          ff15sButton,
          pauseButton: DISABLED,
          playButton: HIDDEN,
          replayButton: ENABLED,
          rw15sButton,
          scrubberIsUserControlled: true,
        };
      } else if (isError) {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          playButton: DISABLED,
        };
      }
      break;
    case EContentType.LiveStation:
      if (isPlaying && isAudioPreroll) {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          playButton: DISABLED,
          scrubberBarType: ScrubberBarType.Live,
          stopButton: ENABLED,
          scrubberIsDisabled: true,
          shouldDisplayLiveIndicator: false,
        };
      } else if (!isPlaying && isAudioPreroll) {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          playButton: ENABLED,
          scrubberBarType: ScrubberBarType.Live,
          stopButton: DISABLED,
          scrubberIsDisabled: true,
          shouldDisplayLiveIndicator: false,
        };
      } else if (isPlaying) {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          playButton: DISABLED,
          scrubberBarType: ScrubberBarType.Live,
          stopButton: ENABLED,
          scrubberIsDisabled: true,
          shouldDisplayLiveIndicator: true,
        };
      } else if (isLoading) {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          playButton: DISABLED,
          scrubberBarType: ScrubberBarType.Live,
          stopButton: ENABLED,
          scrubberIsDisabled: true,
          shouldDisplayLiveIndicator: true,
        };
      } else {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          playButton: ENABLED,
          scrubberBarType: ScrubberBarType.Live,
          stopButton: DISABLED,
          scrubberIsDisabled: true,
          shouldDisplayLiveIndicator: true,
        };
      }
    case EContentType.ExclusiveStation: {
      const skips = skipsRemaining ?? 0;
      const isMobileIos = isMobileIOSDevice();

      if (isLoading && isMobileIos) {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          pauseButton: ENABLED,
          skipButton: HIDDEN,
          skipsRemaining: skips,
          thumbsUpButton: HIDDEN,
        };
      } else if (isPlaying && isMobileIos) {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          pauseButton: ENABLED,
          playButton: DISABLED,
          skipButton: HIDDEN,
          skipsRemaining: skips,
          thumbsUpButton: HIDDEN,
        };
      } else if (isMobileIos) {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          pauseButton: DISABLED,
          playButton: ENABLED,
          skipButton: HIDDEN,
          skipsRemaining: skips,
          thumbsUpButton: HIDDEN,
        };
      } else if (isLoading) {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          pauseButton: ENABLED,
          skipButton: DISABLED,
          skipsRemaining: skips,
          thumbsUpButton: DISABLED,
        };
      } else if (isPlaying && isAd) {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          pauseButton: ENABLED,
          playButton: DISABLED,
          skipButton: DISABLED,
          skipsRemaining: skips,
          thumbsUpButton: DISABLED,
        };
      } else if (isPlaying) {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          pauseButton: ENABLED,
          playButton: DISABLED,
          skipButton: isAd || skips <= 0 ? DISABLED : ENABLED,
          skipsRemaining: skips,
          thumbsUpButton: ENABLED,
        };
      } else {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          pauseButton: DISABLED,
          playButton: ENABLED,
          skipButton: isAd || skips <= 0 ? DISABLED : ENABLED,
          skipsRemaining: skips,
          thumbsUpButton: ENABLED,
        };
      }
    }
    case EContentType.Rewind: {
      // TODO: [CCS-2776] Should be enabled if ATL episode isLiveNowEpisode
      const backToLiveButton =
        isLive || isAd || isStandaloneChapter ? HIDDEN : ENABLED;

      const scrubberBarType = isStandaloneChapter
        ? ScrubberBarType.ElapsedTime
        : ScrubberBarType.WallClock;

      if (isPlaying || isPaused) {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          ff15sButton: isAd ? DISABLED : ff15sButton,
          pauseButton: isPaused ? HIDDEN : ENABLED,
          playButton: isPlaying ? HIDDEN : ENABLED,
          // Can't enable rw15 during ad because it just restarts the ad
          rw15sButton: isAd ? DISABLED : rw15sButton,
          scrubberBarType,
          scrubberIsUserControlled: true,
          scrubberIsDisabled: isAd,
          shouldDisplayLiveIndicator: isLive,
          backToLiveButton,
          previousChapterButton: previousChapterButton(),
          nextChapterButton: nextChapterButton(),
          restartShowButton: restartShowButton,
        };
      } else {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          ff15sButton: DISABLED,
          pauseButton: ENABLED,
          playButton: ENABLED,
          rw15sButton: DISABLED,
          scrubberBarType,
          scrubberIsUserControlled: true,
          scrubberIsDisabled: isAd,
          shouldDisplayLiveIndicator: isLive,
          backToLiveButton,
          previousChapterButton: previousChapterButton(),
          nextChapterButton: nextChapterButton(),
          restartShowButton: restartShowButton,
        };
      }
    }
    case EContentType.RewindNoReplay:
      if (isPlaying) {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          playButton: DISABLED,
          scrubberBarType: ScrubberBarType.WallClock,
          stopButton: ENABLED,
          // hide ff&rw control buttons for rewind with no replay shows
          ff15sButton: HIDDEN,
          rw15sButton: HIDDEN,
          scrubberIsDisabled: true,
          shouldDisplayLiveIndicator: true,
          previousChapterButton: previousChapterButton(),
          nextChapterButton: nextChapterButton(),
          restartShowButton: restartShowButton,
        };
      } else {
        return {
          ...PLAYER_CONTROL_STATE_DEFAULT,
          playButton: ENABLED,
          scrubberBarType: ScrubberBarType.WallClock,
          stopButton: DISABLED,
          // hide ff&rw control buttons for rewind with no replay shows
          ff15sButton: HIDDEN,
          rw15sButton: HIDDEN,
          scrubberIsDisabled: true,
          shouldDisplayLiveIndicator: true,
          previousChapterButton: previousChapterButton(),
          nextChapterButton: nextChapterButton(),
          restartShowButton: restartShowButton,
        };
      }
    default:
      return PLAYER_CONTROL_STATE_DEFAULT;
  }
  return PLAYER_CONTROL_STATE_DEFAULT;
};

export const mapPlayerListButtonState = (
  playerControlsState: IPlayerControlsState,
  listState?: ListState,
): IPlayerControlsState => {
  switch (listState) {
    case ListState.BOTH:
      return {
        ...playerControlsState,
        nextTrackButton: ENABLED,
        prevTrackButton: ENABLED,
      };
    case ListState.NONE:
      return {
        ...playerControlsState,
        nextTrackButton: DISABLED,
        prevTrackButton: DISABLED,
      };
    case ListState.ONLY_NEXT:
      return {
        ...playerControlsState,
        nextTrackButton: ENABLED,
        prevTrackButton: DISABLED,
      };
    case ListState.ONLY_PREV:
      return {
        ...playerControlsState,
        nextTrackButton: DISABLED,
        prevTrackButton: ENABLED,
      };

    default:
      return playerControlsState;
  }
};

export { CurrentChapterPositionType };
