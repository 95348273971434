import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';

import {
  CollectionSubType,
  EntityType,
  EpisodeSubType,
  ShowSubtype,
} from '@audacy-clients/client-services';
import {
  ISearchAssistantProps,
  SearchType,
} from '@audacy-clients/client-services/src/analyticServices';
import { ISearchResult, wrapSearchResultsList } from '@audacy-clients/core/atoms/wrappers/search';
import { IClientServicesPaginatedList } from '@audacy-clients/core/atoms/wrappers/types';
import { useClientServices } from '@audacy-clients/core/utils/clientServices';
import { IViewContext } from '@audacy-clients/core/utils/viewContext';

import {
  IAllSearchResults,
  ISearchRequest,
  ISearchRequestFilterParameters,
  SearchResultType,
} from './requests';

export enum SearchFilterOptions {
  ALL = 'All',
  PODCASTS = 'Podcasts',
  SHOWS = 'Shows',
  STATIONS = 'Stations',
  EPISODES = 'Episodes',
  PLAYLISTS = 'Playlists',
  TOPICS = 'Topics',
}

export const SearchFilterOptionsToQueryParamsMap: Record<
  SearchFilterOptions,
  ISearchRequestFilterParameters
> = {
  [SearchFilterOptions.ALL]: {
    entitySubtypes: [],
    entityTypes: [],
    excludeEntitySubtypes: [],
    excludeEntityTypes: [EntityType.EPISODE], // Episodes are experimental so only displayed under their own tab
  },
  [SearchFilterOptions.PODCASTS]: {
    entitySubtypes: [ShowSubtype.PODCAST],
    entityTypes: [],
    excludeEntitySubtypes: [],
    excludeEntityTypes: [EntityType.EPISODE],
  },
  [SearchFilterOptions.SHOWS]: {
    entitySubtypes: [],
    entityTypes: [EntityType.SHOW],
    excludeEntitySubtypes: [ShowSubtype.PODCAST],
    excludeEntityTypes: [EntityType.EPISODE],
  },
  [SearchFilterOptions.STATIONS]: {
    entitySubtypes: [],
    entityTypes: [EntityType.STATION],
    excludeEntitySubtypes: [],
    excludeEntityTypes: [EntityType.EPISODE],
  },
  [SearchFilterOptions.EPISODES]: {
    entitySubtypes: [EpisodeSubType.PODCAST_EPISODE],
    entityTypes: [EntityType.EPISODE],
    excludeEntitySubtypes: [ShowSubtype.PODCAST],
    excludeEntityTypes: [EntityType.SHOW, EntityType.STATION],
  },
  [SearchFilterOptions.PLAYLISTS]: {
    entitySubtypes: [CollectionSubType.PLAYLIST],
    entityTypes: [EntityType.COLLECTION],
    excludeEntitySubtypes: [],
    excludeEntityTypes: [],
  },
  [SearchFilterOptions.TOPICS]: {
    entitySubtypes: [],
    entityTypes: [EntityType.TAG, EntityType.TOPIC],
    excludeEntitySubtypes: [],
    excludeEntityTypes: [],
  },
};

export const Constants: {
  defaultRequest: ISearchRequest;
  emptyAllResults: IAllSearchResults;
  minTermLength: number;
  defaultSearchAssistantProps: ISearchAssistantProps;
} = {
  defaultRequest: {
    ...SearchFilterOptionsToQueryParamsMap[SearchFilterOptions.STATIONS],
    page: 0,
    term: '',
  },
  emptyAllResults: {
    featuredResults: [],
    results: [],
    totalResults: 0,
  },
  minTermLength: 1,
  defaultSearchAssistantProps: {
    audacyAssistantFlag: 'No',
    audacyAssistantSessionId: '',
    audacyAssistantUserId: '',
    audacyAssistantUtterance: '',
  },
};

const sortSearchResultsList = (
  startIndex: number,
  wrappedResults: IClientServicesPaginatedList<ISearchResult | null>,
): IAllSearchResults => {
  const { pageLength, get, total } = wrappedResults;
  const lastIndex = startIndex === 0 ? startIndex + pageLength - 1 : startIndex + pageLength;

  // surface each search item so that we can
  // evaluate the array and sort by isFeatured
  const tempResults: ISearchResult[] = [];

  for (let i = startIndex; i <= lastIndex; i++) {
    const searchItem = get(i);

    searchItem && tempResults.push(searchItem);
  }

  const featuredResults = tempResults.filter((item) => item.isFeatured);
  const results = tempResults.filter((item) => !item.isFeatured);

  return {
    [SearchResultType.FeaturedResults]: featuredResults,
    [SearchResultType.Results]: results,
    totalResults: total,
  };
};

interface ISearchResultsHookProps {
  getViewContext: () => IViewContext;
  searchType: SearchType;
}

export interface ISearchResultsHook {
  allResults: IAllSearchResults;
  clearAllResults: () => void;
  getSearchResults: (request: ISearchRequest) => void;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const useSearch = ({
  searchType,
  getViewContext,
}: ISearchResultsHookProps): ISearchResultsHook => {
  const requestId = useRef(0);
  const { clientServices: cs, loading: csLoading } = useClientServices();
  const [allResults, setAllResults] = useState<IAllSearchResults>(Constants.emptyAllResults);
  const [isLoading, setIsLoading] = useState(true);

  const clearAllResults = useCallback(() => {
    setAllResults(Constants.emptyAllResults);
  }, []);

  const getSearchResults = useCallback(
    (request: ISearchRequest) => {
      if (csLoading) {
        return;
      }
      if (request.term.length < Constants.minTermLength) {
        // Don't fetch if we don't have a valid term length
        return;
      }

      // If requesting first page, clear old results
      if (request.page === 0) {
        clearAllResults();
      }

      requestId.current++;
      const id = requestId.current;

      setIsLoading(true);
      let assistantProps: ISearchAssistantProps = {};
      if (searchType.includes('Audacy Assistant')) {
        if (request.searchAssistantProps) {
          assistantProps = request.searchAssistantProps;
        }
      } else {
        assistantProps.audacyAssistantFlag = 'No';
      }

      cs.getAnalyticServices().sendSearchEvent(
        getViewContext(),
        request.term,
        searchType,
        searchType === 'Onboarding',
      );

      cs.getDataServices()
        .search(
          request.term,
          request.entityTypes,
          request.entitySubtypes,
          request.excludeEntityTypes,
          request.excludeEntitySubtypes,
          request.page,
        )
        .then((newPage) => {
          if (id !== requestId.current) {
            return;
          }

          setAllResults((currentAllResults) => {
            const wrappedNewPage = wrapSearchResultsList(newPage);
            const totalResultsInState = currentAllResults.results.length;
            const nextStartIndex = totalResultsInState > 0 ? totalResultsInState - 1 : 0;
            const nextAllResults = sortSearchResultsList(nextStartIndex, wrappedNewPage);

            return {
              featuredResults: [
                ...currentAllResults.featuredResults,
                ...nextAllResults.featuredResults,
              ],
              results: [...currentAllResults.results, ...nextAllResults.results],
              totalResults: nextAllResults.totalResults,
            };
          });
        })
        .finally(() => {
          if (id === requestId.current) {
            setIsLoading(false);
          }
        });
    },
    [csLoading, searchType, cs, getViewContext, clearAllResults],
  );

  return {
    allResults,
    clearAllResults,
    getSearchResults,
    isLoading,
    setIsLoading,
  };
};
