import { useCallback, useContext, useEffect, useState } from 'react';

import { IOptionsMenu } from '~/components/OptionsMenu/types';
import usePlayerProps from '~/components/Player/hooks/usePlayerProps';
import { PlayerQueueContext } from '~/components/Player/PlayerContextProvider';
import { animationStatusName } from '~/constants/animation';
import { useBreakpoints } from '~/hooks/use-breakpoints';
import useOptionsMenu from '~/hooks/use-options-menu';

interface IUseContainerAnimation {
  isPlayerContentVisible: boolean;
  isPlayerContentAnimationComplete: boolean;
  isPlayerVisible: boolean;
  handlePlayerVisibleComplete: (name: string) => void;
  handlePlayerContentVisibleComplete: (name: string) => void;
}

export const useContainerAnimation = (): IUseContainerAnimation => {
  const [isPlayerContentVisible, setIsPlayerContentVisible] = useState(false);
  const [isPlayerContentAnimationComplete, setIsPlayerContentAnimationComplete] = useState(false);
  const [isPlayerVisible, setIsPlayerVisible] = useState(false);
  const { isOpen } = usePlayerProps();

  useEffect(() => {
    if (isOpen) {
      setIsPlayerVisible(true);
    } else {
      setIsPlayerContentVisible(false);
    }
  }, [isOpen]);

  const handlePlayerVisibleComplete = (name: string) => {
    if (name === animationStatusName.animate) {
      setIsPlayerContentVisible(true);
    }
  };

  const handlePlayerContentVisibleComplete = (name: string) => {
    if (name === animationStatusName.animate) {
      setIsPlayerContentAnimationComplete(true);
    } else if (name === animationStatusName.exit) {
      setIsPlayerVisible(false);
      setIsPlayerContentAnimationComplete(false);
    }
  };

  return {
    handlePlayerContentVisibleComplete,
    handlePlayerVisibleComplete,
    isPlayerContentVisible,
    isPlayerContentAnimationComplete,
    isPlayerVisible,
  };
};

interface IUseQueueAnimation {
  handleQueueAnimationComplete: (name: string) => void;
  handleQueueClick: () => void;
  handleQueueContainerAnimationComplete: (name: string) => void;
  isQueueTransitioned: boolean;
  isQueueVisible: boolean;
}

export const useQueueAnimation = (): IUseQueueAnimation => {
  const { greaterThan } = useBreakpoints();
  const [isQueueVisible, setIsQueueVisible] = useState(greaterThan.MD);
  const [isQueueTransitioned, setIsQueueTransitioned] = useState(false);

  useEffect(() => {
    setIsQueueTransitioned(false);
    setIsQueueVisible(greaterThan.MD);
  }, [greaterThan.MD]);

  const { isQueueHead, setIsQueueHeadPlayer } = useContext(PlayerQueueContext);
  const handleQueueClick = useCallback(() => {
    if (isQueueVisible) {
      setIsQueueTransitioned(false);
      setIsQueueHeadPlayer(false);
    } else {
      setIsQueueVisible(true);
      setIsQueueHeadPlayer(!greaterThan.MD);
    }
  }, [greaterThan.MD, isQueueVisible, setIsQueueHeadPlayer]);

  useEffect(() => {
    setIsQueueHeadPlayer(isQueueHead && !greaterThan.MD && isQueueVisible);
  }, [greaterThan.MD, isQueueHead, isQueueVisible, setIsQueueHeadPlayer]);

  // Extra logic for rendering <Queue> only after the panel is open. This improves performance.
  const handleQueueContainerAnimationComplete = (name: string) => {
    if (name === animationStatusName.animate) {
      setIsQueueTransitioned(true);
    }
  };

  const handleQueueAnimationComplete = (name: string) => {
    if (name !== animationStatusName.animate) {
      setIsQueueVisible(false);
    }
  };

  return {
    handleQueueAnimationComplete,
    handleQueueClick,
    handleQueueContainerAnimationComplete,
    isQueueTransitioned,
    isQueueVisible,
  };
};

interface IUseDelayedDisplay {
  isVisibleAfterDelay: boolean;
  setIsVisibleAfterDelay: (isVisible: boolean) => void;
}

// Note: delay should be in seconds
export const useDelayedDisplay = (trigger: boolean, delay: number): IUseDelayedDisplay => {
  const [isVisibleAfterDelay, setIsVisibleAfterDelay] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (trigger) {
      timeout = setTimeout(() => setIsVisibleAfterDelay(true), delay * 1000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [delay, trigger]);

  return {
    isVisibleAfterDelay,
    setIsVisibleAfterDelay,
  };
};

export const useGetPlayerOptionsMenu = (): IOptionsMenu | undefined => {
  const { contentId, imageUrl, parentShowUrl, playerTitle, showContentId, subtype, type, url } =
    usePlayerProps();

  return useOptionsMenu({
    contentId,
    entityType: type,
    entitySubtype: subtype,
    image: imageUrl,
    isFullPlayer: true,
    parentShowUrl,
    showContentId,
    title: playerTitle,
    url,
  });
};
