import { Colors, Easing, Timing, ZIndex } from '~/styles';
import { TCSSObject, TStyleSheet } from '~/types/emotion-styles';

import { OptionsMenuPosition } from '.';

// Can't use transition mixin for these more complicated transitions w/ different timing for visibility
const transitionDefaults = `${Timing.menuTooltip}s ${Easing.default}`;
const menuTransition = `opacity ${transitionDefaults}`;
const visibilityTransitionOnExit = `visibility ${Timing.none}s ${Easing.default} ${Timing.menuTooltip}s`;

export const menuPositionStyles = (
  position: OptionsMenuPosition,
  buttonSize: number,
): TCSSObject => {
  const containerOffset = buttonSize + 10;

  switch (position) {
    case OptionsMenuPosition.BottomLeft:
      return {
        right: 0,
        top: containerOffset,
      };
    case OptionsMenuPosition.TopLeft:
      return {
        right: 0,
        bottom: containerOffset,
      };
    case OptionsMenuPosition.TopRight:
      return {
        left: 0,
        bottom: containerOffset,
      };
    default:
      return {
        left: 0,
        top: containerOffset,
      };
  }
};

const styles: TStyleSheet = {
  menuContainer: {
    backgroundColor: Colors.white,
    borderRadius: 4,
    boxShadow: '0px -1px 0px rgba(0, 0, 0, 0.08)',
    color: Colors.audacyDarkNavy,
    minWidth: 185,
    padding: '10px 0',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    zIndex: ZIndex.optionsMenu,
  },
  menuOpen: {
    opacity: 1,
    transition: menuTransition,
    visibility: 'visible',
    zIndex: ZIndex.overlay,
  },
  menuClosed: {
    opacity: 0,
    transition: `${menuTransition}, ${visibilityTransitionOnExit}`,
    visibility: 'hidden',
  },
};

export default styles;
