import React, { createContext, useState } from 'react';

interface IPlayerContext {
  isFullscreenPlayer: boolean;
  setIsFullscreenPlayer: (value: boolean) => void;
}

export const PlayerContext = createContext<IPlayerContext>({
  isFullscreenPlayer: false,
  setIsFullscreenPlayer: () => {},
});

interface IPlayerQueueContext {
  isQueueHead: boolean;
  setIsQueueHeadPlayer: (value: boolean) => void;
}

export const PlayerQueueContext = createContext<IPlayerQueueContext>({
  isQueueHead: false,
  setIsQueueHeadPlayer: () => {},
});

const PlayerContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [isFullscreenPlayer, setIsFullscreen] = useState(false);
  const [isQueueHead, setIsQueueHead] = useState(false);
  return (
    <PlayerContext.Provider
      value={{
        isFullscreenPlayer,
        setIsFullscreenPlayer: setIsFullscreen,
      }}
    >
      <PlayerQueueContext.Provider
        value={{
          isQueueHead,
          setIsQueueHeadPlayer: setIsQueueHead,
        }}
      >
        {children}
      </PlayerQueueContext.Provider>
    </PlayerContext.Provider>
  );
};

export default PlayerContextProvider;
