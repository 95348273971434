import { BP, Colors, mq, ZIndex } from '~/styles';
import { type TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  drawerContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    overflow: 'auto',
    width: '100%',
    zIndex: ZIndex.drawer,
  },
  drawer: {
    background: Colors.white,
    borderRadius: '15px 15px 0 0',
    padding: '30px 30px 50px',
    width: '100vw',
    [mq(BP.Medium)]: {
      padding: '30px 42px 35px',
    },
  },
  portal: {
    position: 'relative',
    zIndex: ZIndex.drawer,
  },
};

export default styles;
