import React from 'react';
import BubbleQuote from '../../../assets/icons/bubble-quote';
import styles from './styles';

const TranscriptButton: React.FC<{ onClick: () => void; text: string }> = ({ onClick, text }) => (
  <button onClick={onClick} style={styles.transcriptButton}>
    <BubbleQuote style={{ marginRight: '8px' }} />
    <span style={{ fontSize: '12px', lineHeight: '16px', fontFamily: 'Maison Neue Extended', fontWeight: '700' }}>{text}</span>
  </button>
);

export default TranscriptButton;
