export default (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Favorite filled</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21.75C11.7199 21.5195 11.4116 21.27 11.0825 21.0037C7.74048 18.2993 2.25 13.8562 2.25 9.85714C2.25 6.48426 4.59581 3.75 7.875 3.75C9.56055 3.75 10.9995 4.93894 12 6.40013C13.0005 4.93894 14.4395 3.75 16.125 3.75C19.4042 3.75 21.75 6.48426 21.75 9.85714C21.75 13.8562 16.2595 18.2993 12.9175 21.0037C12.5884 21.27 12.2801 21.5195 12 21.75Z"
      fill="currentColor"
    />
  </svg>
);
