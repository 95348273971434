export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Sports</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5418 5.43043C27.5418 5.43043 28.2492 8.64383 27.8455 12.6654C24.6103 11.0043 21.964 8.35839 20.3024 5.12355C24.326 4.72117 27.5418 5.43043 27.5418 5.43043ZM18.2928 5.41452C20.2061 9.47537 23.4927 12.7617 27.5538 14.6745C26.9902 17.6052 25.7486 20.7376 23.2201 23.2662C20.6844 25.802 17.552 27.0398 14.6273 27.5966C12.7174 23.5437 9.43949 20.2624 5.38901 18.3482C5.93707 15.4163 7.1673 12.2797 9.70051 9.74637C12.2287 7.21807 15.3614 5.97725 18.2928 5.41452ZM12.6157 27.8838C10.9596 24.6622 8.3267 22.0252 5.10822 20.3639C4.73156 24.368 5.45906 27.5618 5.45906 27.5618C5.45906 27.5618 8.63135 28.2724 12.6157 27.8838ZM19.1323 12.4736L12.5005 19.154L13.8682 20.5177L20.5 13.8373L19.1323 12.4736Z"
      fill="currentColor"
    />
  </svg>
);
