import { EntityType, EntitySubtype } from '@audacy-clients/client-services/core';
import { ISearchResult } from '@audacy-clients/core/atoms/wrappers/search';
import { SearchAssistantProps } from '@audacy-clients/core/types/voiceEventsData';
import { SerializableParam } from 'recoil';

export interface ISearchRequestFilterParameters {
  entitySubtypes: EntitySubtype[];
  entityTypes: EntityType[];
  excludeEntitySubtypes: EntitySubtype[];
  excludeEntityTypes: EntityType[];
}

export interface ISearchRequest extends ISearchRequestFilterParameters {
  page: number;
  term: string;
  searchAssistantProps?: SearchAssistantProps;
}

export enum SearchResultType {
  FeaturedResults = 'featuredResults',
  Results = 'results',
}

export interface IAllSearchResults extends Record<SearchResultType, ISearchResult[]> {
  totalResults: number;
}

export type TSearchRequest = ISearchRequest & SerializableParam;
