export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Website</title>
    <path
      d="M27.25 16c0 6.213-5.037 11.25-11.25 11.25v1.5c7.042 0 12.75-5.708 12.75-12.75h-1.5ZM16 27.25C9.787 27.25 4.75 22.213 4.75 16h-1.5c0 7.042 5.708 12.75 12.75 12.75v-1.5ZM4.75 16C4.75 9.787 9.787 4.75 16 4.75v-1.5C8.958 3.25 3.25 8.958 3.25 16h1.5ZM16 4.75c6.213 0 11.25 5.037 11.25 11.25h1.5c0-7.042-5.708-12.75-12.75-12.75v1.5Z"
      fill="currentColor"
    />
    <path
      d="M20.5 16c0 3.235-.575 6.128-1.475 8.185-.45 1.029-.967 1.815-1.503 2.333-.532.514-1.045.732-1.522.732v1.5c.973 0 1.84-.454 2.565-1.154.72-.696 1.335-1.668 1.834-2.81C21.4 22.499 22 19.392 22 16h-1.5ZM16 27.25c-.477 0-.99-.218-1.522-.732-.536-.518-1.053-1.304-1.503-2.333-.9-2.057-1.475-4.95-1.475-8.185H10c0 3.392.6 6.499 1.6 8.786.5 1.142 1.115 2.114 1.835 2.81.724.7 1.592 1.154 2.565 1.154v-1.5ZM11.5 16c0-3.235.575-6.128 1.475-8.185.45-1.029.967-1.815 1.503-2.333.532-.514 1.045-.732 1.522-.732v-1.5c-.973 0-1.84.454-2.565 1.154-.72.696-1.335 1.668-1.834 2.81C10.6 9.501 10 12.608 10 16h1.5ZM16 4.75c.477 0 .99.218 1.522.732.536.518 1.053 1.304 1.503 2.333.9 2.057 1.475 4.95 1.475 8.185H22c0-3.392-.6-6.499-1.6-8.786-.5-1.142-1.115-2.114-1.835-2.81-.724-.7-1.592-1.154-2.565-1.154v1.5Z"
      fill="currentColor"
    />
    <path stroke="currentColor" strokeWidth="1.5" d="M4.923 12.481h22.154M4.923 19.866h22.154" />
  </svg>
);
