export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Sort</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m8.47 23.697.53.53.53-.53 4.834-4.833.53-.53-1.06-1.061-.531.53-3.553 3.553V7.25h-1.5v14.106l-3.553-3.553-.53-.53-1.061 1.06.53.53 4.834 4.834ZM22.47 7.47l.53-.53.53.53 4.834 4.833.53.53-1.06 1.061-.531-.53L23.75 9.81v14.106h-1.5V9.811l-3.553 3.553-.53.53-1.061-1.06.53-.531L22.47 7.47Z"
      fill="currentColor"
    />
  </svg>
);
