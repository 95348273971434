import { ECollectionPlaybackMode } from '@audacy-clients/client-services/src/audioServices/types';
import { nowPlaying } from '@audacy-clients/core/atoms/player';
import { useClientServices } from '@audacy-clients/core/utils/clientServices';

import { getItemToPlay } from '@audacy-clients/core/utils/playlist';

import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

export const useRecentlyPlayedPlaylist = (): void => {
  const currentItem = useRecoilValue(nowPlaying);
  const { clientServices, loading } = useClientServices();

  useEffect(() => {
    if (!loading) {
      const localActiveCollectionId = clientServices.getPersonalizationServices().localCollectionId;
      const localActivePlaylistItems =
        clientServices.getPersonalizationServices().localActivePlaylistItems;

      // If the currently loaded item is also in the localPlaylistItems,
      // set the playlist items in audio services

      const isCurrentItemInCollection =
        currentItem?.id && localActivePlaylistItems?.includes(currentItem.id);

      if (localActiveCollectionId && isCurrentItemInCollection) {
        const setLocalPlaylistItems = async () => {
          const items = await getItemToPlay(localActiveCollectionId);
          clientServices.getAudioServices().updateCollection(items.orderedContent);
          clientServices
            .getAudioServices()
            .setCollectionPlaybackMode(ECollectionPlaybackMode.Playlist);
        };

        setLocalPlaylistItems().catch((e) => console.error(e));
      }
    }
  }, [clientServices, loading, currentItem]);
};
