import useLinkOrButton from '~/hooks/use-link-or-button';
import { TStyles } from '~/types/emotion-styles';
import { IGlobalAsAnchorProps, IGlobalAsButtonProps, LinkType } from '~/types/links';
import { appendRightArrow } from '~/utils/strings';

import styles from './styles';

interface IFooterLinkProps {
  isSecondary?: boolean;
  label: string;
  linkCss?: TStyles;
}

export interface IFooterLinkAnchorProps extends IGlobalAsAnchorProps, IFooterLinkProps {
  useClickHandler?: boolean;
}
export interface IFooterLinkButtonProps extends IGlobalAsButtonProps, IFooterLinkProps {
  useClickHandler?: boolean;
}
export type TFooterLinkProps = IFooterLinkAnchorProps | IFooterLinkButtonProps;

const FooterLink = ({
  useClickHandler,
  isSecondary,
  label,
  linkCss,
  ...rest
}: TFooterLinkProps): JSX.Element => {
  const as = useClickHandler ? LinkType.Button : LinkType.Anchor;
  const text = isSecondary ? label : appendRightArrow(label);

  const footerLinkStyles = [styles.link, isSecondary ? styles.secondaryLink : {}, linkCss];

  return useLinkOrButton({
    props: { as, ...rest },
    children: text,
    styles: footerLinkStyles as TStyles,
  });
};

export default FooterLink;
