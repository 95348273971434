import { EntityImageShape } from '@audacy-clients/core/atoms/wrappers/modules';
import { IEpisode } from '@audacy-clients/core/atoms/wrappers/types';
import { getEpisodeTitleAndImage, isQueueableEpisode } from '@audacy-clients/core/utils/episode';
import { useTranslation } from 'react-i18next';

import IconButton from '~/components/Button/IconButton';
import EntityImage from '~/components/Entity/components/EntityImage';
import { IEntitySummaryProps } from '~/components/Entity/types';
import { Icons } from '~/components/Icon/constants';
import Link from '~/components/Link';
import OptionsMenuButton from '~/components/OptionsMenu/components/OptionsMenuButton';
import { OptionsMenuPosition } from '~/components/OptionsMenu/components/OptionsMenuContainer';
import PlayStateArea from '~/components/PlayStateArea';
import Bulleted from '~/components/PlayStateArea/components/Bulleted';
import TruncatedDescription from '~/components/TruncatedDescription';
import useOptionsMenu from '~/hooks/use-options-menu';
import { LinkType } from '~/types/links';

import styles from './styles';
import { getEyebrow } from './utils';

interface IEpisodeItemProps {
  episode: IEpisode;
  isInQueue?: boolean;
  isLoggedIn?: boolean;
  onClick?: () => void;
  onPlayStateClick?: (id?: string) => void;
  onQueueClick: () => void;
  hasOptionsMenu?: boolean;
}

const EpisodeItem = ({
  duration,
  episode,
  isInQueue,
  isLoggedIn,
  isPlayed,
  isPlaying,
  onClick,
  onPlayStateClick,
  onQueueClick,
  resumePoint,
  isScrubbing,
  hasOptionsMenu = true,
  isPlayable,
}: IEpisodeItemProps & IEntitySummaryProps): JSX.Element => {
  const optionsMenu = useOptionsMenu({
    contentId: episode.id,
    entityType: episode.type,
    entitySubtype: episode.subtype,
    image: episode.image,
    title: episode.title,
    url: episode.url,
  });
  const { t } = useTranslation();
  const { description } = episode;

  const episodePath = episode.url;
  const eyebrow = getEyebrow(episode, false);

  const [title, image] = getEpisodeTitleAndImage(episode);

  const queueIconLabel = isInQueue ? t('optionsMenu.removeFromQueue') : t('optionsMenu.addToQueue');

  const content = (
    <>
      <div css={styles.header}>
        {!!image && (
          <EntityImage
            entityImageCss={styles.image}
            alt={episode.imageAlt}
            aspectRatio={1}
            entityImageShape={EntityImageShape.Square}
            imageURIBase={image}
          />
        )}
        <div>
          {eyebrow.length > 0 && <Bulleted bulletedCss={styles.eyebrow} title={eyebrow} />}
          <h3 css={styles.title}>{title}</h3>
        </div>
      </div>

      {!!description && (
        <div css={styles.descriptionWrapper}>
          <TruncatedDescription
            displayShowMore={false}
            numberOfLines={3}
            shouldStripTags
            textCss={styles.description}
            textId={`truncated-desc-${episode.id}`}
            text={description}
          />
        </div>
      )}
    </>
  );

  return (
    <div css={styles.listItem}>
      <div css={isPlayed && !isPlaying && styles.played}>
        {episodePath ? (
          <Link as={LinkType.Anchor} css={styles.container} href={episodePath} onClick={onClick}>
            {content}
          </Link>
        ) : (
          <div css={styles.container}>{content}</div>
        )}

        <div css={styles.bottomOptions}>
          <button
            css={styles.playGroup}
            onClick={() => onPlayStateClick && onPlayStateClick(episode.id)}
            aria-label={t('playerControls.accessibilityLabels.play')}
          >
            <PlayStateArea
              duration={duration}
              isPlayed={isPlayed}
              isPlaying={isPlaying}
              isScrubbing={isScrubbing}
              resumePoint={resumePoint}
              isPlayable={isPlayable}
            />
          </button>

          <div css={styles.actionsGroup}>
            {isLoggedIn && isQueueableEpisode(episode.type, episode.subtype) && (
              <IconButton
                ariaLabel={queueIconLabel}
                buttonSizeInPx={18}
                icon={isInQueue ? Icons.QueueRemove : Icons.QueueAdd}
                onClick={onQueueClick}
                title={queueIconLabel}
              />
            )}
            {hasOptionsMenu && optionsMenu ? (
              <OptionsMenuButton
                ariaLabel={t('playerControls.accessibilityLabels.options')}
                menuPosition={OptionsMenuPosition.BottomLeft}
                menuTitle={title}
                menuImage={
                  image
                    ? {
                        imageURIBase: image,
                        alt: episode.imageAlt,
                      }
                    : undefined
                }
                optionsMenu={optionsMenu}
                iconSizeInPx={14}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EpisodeItem;
