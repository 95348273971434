interface IZIndexProps {
  [key: string]: number;
}

const zIndexValues = [-1, 0, 100, 200, 300, 400, 500, 600, 700, 800, 900];
const branchZIndexDefault = 99999;

export const ZIndex: IZIndexProps = {
  hidden: zIndexValues[0],
  transitionBackgroundAfter: zIndexValues[0],
  focusOutline: zIndexValues[1],
  carouselNav: zIndexValues[2],
  siblingOverlay: zIndexValues[2],
  gradientBase: zIndexValues[2],
  optionsMenu: zIndexValues[2],
  stickyNav: zIndexValues[2],
  selectInputLabel: zIndexValues[2],
  transitionBackground: zIndexValues[2],
  fullPlayerQueueHeader: zIndexValues[2],
  fullPlayerCloseButton: zIndexValues[2],
  editCta: zIndexValues[2],
  fullPlayerPanel: zIndexValues[3],
  gradientContent: zIndexValues[3],
  transcriptContainer: zIndexValues[3],
  stickyHeader: zIndexValues[4],
  headerWithStickyNav: zIndexValues[4] + 1, // header should not overlap any other elements except sticky header so add + 1 to ZIndex.stickyHeader value
  navigationBar: zIndexValues[5],
  miniPlayer: zIndexValues[6],
  navOverlay: zIndexValues[7],
  navigation: zIndexValues[8],
  navigationBarSmall: zIndexValues[5],
  navigationWithBranchJourney: branchZIndexDefault + 1,
  fullPlayer: zIndexValues[8],
  drawer: zIndexValues[9],
  skipToMainContent: zIndexValues[9],
  toast: zIndexValues[9],
  gridOverlay: zIndexValues[10],
  horizontalEntityCardPlayStateContainer: zIndexValues[1],
  liveIndicator: zIndexValues[4],
};
