export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    role="img"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Headset</title>
    <path
      d="M27 15.143v8.571a4.335 4.335 0 0 1-1.232 3.03A4.165 4.165 0 0 1 22.798 28h-6.034a.832.832 0 0 1-.594-.251.866.866 0 0 1 0-1.212.832.832 0 0 1 .594-.251h6.035c.668-.001 1.309-.272 1.781-.754a2.601 2.601 0 0 0 .74-1.818v-.147c-.27.098-.555.147-.84.147h-1.681a2.5 2.5 0 0 1-1.782-.754 2.601 2.601 0 0 1-.74-1.817v-4.286c.002-.682.267-1.335.74-1.817a2.5 2.5 0 0 1 1.782-.754h2.483a9.5 9.5 0 0 0-3-6.13 9.142 9.142 0 0 0-6.288-2.441 9.142 9.142 0 0 0-6.276 2.44 9.5 9.5 0 0 0-3 6.13H9.2a2.5 2.5 0 0 1 1.782.755c.473.482.738 1.135.74 1.817v4.286c-.002.682-.267 1.335-.74 1.817a2.5 2.5 0 0 1-1.782.754h-1.68a2.5 2.5 0 0 1-1.782-.754A2.6 2.6 0 0 1 5 21.143v-6c0-1.47.285-2.925.839-4.282a11.159 11.159 0 0 1 2.387-3.625 10.91 10.91 0 0 1 3.57-2.41A10.736 10.736 0 0 1 16.077 4c2.897 0 5.675 1.174 7.724 3.264A11.257 11.257 0 0 1 27 15.143Z"
      fill="currentColor"
    />
  </svg>
);
