import linkifyHtml from 'linkify-html';
import { find } from 'linkifyjs';
import isString from 'lodash/isString';

export const unescapeString = (input?: string): string | undefined =>
  isString(input) ? unescape(input) : input;

export const Characters = {
  bullet: '\u2022',
  rightAngleQuotation: '\u203A',
  spaceDouble: '  ',
  spaceSingle: ' ',
};

const decodingMap = {
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&amp;': '&#38',
  '&': '&#38',
};

export const appendCaret = (title: string): string =>
  title + Characters.spaceSingle + Characters.rightAngleQuotation;

export const containsHTML = (str: string): boolean => /<[a-z][\s\S]*>/i.test(str);

export const containsLinks = (str: string): boolean => find(str).length > 0;

export const needsHTMLRendering = (str: string): boolean => containsHTML(str) || containsLinks(str);

export const addLinksToText = (str: string): string => linkifyHtml(str);

export const convertNewlinesToBreaks = (str: string): string =>
  str.replace(/(?:\r\n|\r|\n)/g, '<br />');

export const addCommaToThousands = (number: number): string => {
  if (number < 999) {
    return number.toString();
  }
  const numberArr = number.toString().split('');
  numberArr.splice(-3, 0, ',');
  return numberArr.join('');
};

export const ellipsizeText = (str: string, numChars: 16): string => {
  return `${str.substring(0, numChars)}...`;
};

export function decodeHtmlEntities(str: string) {
  let decodedStr = str;

  for (const [entity, char] of Object.entries(decodingMap)) {
    const regex = new RegExp(entity, 'g');
    decodedStr = decodedStr.replace(regex, char);
  }

  return decodedStr;
}
