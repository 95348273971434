import formatDuration from 'date-fns/formatDuration';

export const getTimeLabel = (currentTime: number, duration: number): string => {
  const getHours = (time: number) => Math.floor(time / 3600);
  const getMinutes = (time: number) => Math.floor(time / 60) % 60;
  const getSeconds = (time: number) => time % 60;

  const legibleStart = formatDuration({
    hours: getHours(currentTime),
    minutes: getMinutes(currentTime),
    seconds: getSeconds(currentTime),
  });
  const legibleDuration = formatDuration({
    hours: getHours(duration),
    minutes: getMinutes(duration),
    seconds: getSeconds(duration),
  });

  return `${legibleStart} of ${legibleDuration}`;
};
