import { useContext } from 'react';

import { playerControlsState } from '@audacy-clients/core/atoms/player';
import { isPlayerButtonVisible } from '@audacy-clients/core/utils/player';
import { useRecoilValue } from 'recoil';

import useGetControlActions from '~/components/Player/hooks/useGetControlActions';
import usePlayerControls from '~/components/Player/hooks/usePlayerControls';
import { PlayerContext, PlayerQueueContext } from '~/components/Player/PlayerContextProvider';
import { PlayerPrimaryButton } from '~/components/Player/PlayerControls/Components/PlayerPrimaryButton';
import { PlayerSecondaryButton } from '~/components/Player/PlayerControls/Components/PlayerSecondaryButton';
import { useBreakpoints } from '~/hooks/use-breakpoints';

import { fullPlayerStyles, miniPlayerStyles } from './styles';
const PlayerControls = (): JSX.Element => {
  const { skipButton, prevTrackButton, nextTrackButton } =
    useRecoilValue(playerControlsState) || {};

  const { skipsRemaining } = usePlayerControls();
  const { greaterThan } = useBreakpoints();
  const { primaryControl, rightControl, leftControl, prevBtn, nextBtn } = useGetControlActions();
  const { isQueueHead } = useContext(PlayerQueueContext);
  const { isFullscreenPlayer } = useContext(PlayerContext);

  const canSkip = isPlayerButtonVisible(skipButton);
  const canSwitchTracks =
    isPlayerButtonVisible(nextTrackButton) || isPlayerButtonVisible(prevTrackButton);
  const styles = isFullscreenPlayer ? fullPlayerStyles : miniPlayerStyles;
  const canRenderSecondary = (greaterThan.SM || isFullscreenPlayer) && !isQueueHead;

  return (
    <div css={[styles.container, isQueueHead && styles.queueHeaderContainer]}>
      {prevBtn && canSwitchTracks && <PlayerSecondaryButton action={prevBtn} />}
      {leftControl && canRenderSecondary && <PlayerSecondaryButton action={leftControl} />}
      <PlayerPrimaryButton action={primaryControl} />
      {rightControl && canRenderSecondary && (
        <PlayerSecondaryButton action={rightControl}>
          {canSkip && isFullscreenPlayer && (
            <span css={styles.skipsRemaining}>{skipsRemaining}</span>
          )}
        </PlayerSecondaryButton>
      )}
      {nextBtn && canSwitchTracks && <PlayerSecondaryButton action={nextBtn} />}
    </div>
  );
};

export default PlayerControls;
