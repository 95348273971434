import { IEpisode } from '@audacy-clients/core/atoms/wrappers/types';
import { format } from 'date-fns';
import addDays from 'date-fns/addDays';
import startOfDay from 'date-fns/startOfDay';
import { groupBy } from 'lodash';

export const getIsEpisodeToday = (
  selectedDate: Date,
  startTime?: number,
  endTime?: number,
): boolean => {
  if (!startTime || !endTime) {
    return false;
  }

  const startOfDayTime = startOfDay(selectedDate).getTime();
  const endOfDayTime = startOfDay(addDays(selectedDate, 1)).getTime();
  const endsWithinToday = endTime > startOfDayTime && endTime <= endOfDayTime;
  const startsWithinToday = startTime > startOfDayTime && startTime < endOfDayTime;

  return startsWithinToday || endsWithinToday;
};

export interface IEpisodeOvernight {
  continuesNextDay: boolean;
  continuesPreviousDay: boolean;
}

export const getIsEpisodeOvernight = (
  selectedDate: Date,
  startTime: number,
  endTime: number,
): IEpisodeOvernight => {
  const endOfDayTime = startOfDay(addDays(selectedDate, 1)).getTime();
  const endOfNextDay = startOfDay(addDays(selectedDate, 2)).getTime();
  const endOfPreviousDay = startOfDay(selectedDate).getTime();

  return {
    continuesNextDay: endTime > endOfDayTime && endTime <= endOfNextDay,
    continuesPreviousDay:
      startTime < endOfPreviousDay && startTime <= endOfDayTime && endTime > endOfPreviousDay,
  };
};

export const formatScheduleTime = (date: Date | number): string =>
  format(date, 'h:mmaaa').replace(':00', '');

export const getScheduleTimeLabel = (
  startTime: number,
  endTime: number,
  selectedDate: Date,
): string => {
  const overnight = getIsEpisodeOvernight(selectedDate, startTime, endTime);

  const formattedStartTime = formatScheduleTime(startTime);

  return overnight.continuesPreviousDay ? '12am' : formattedStartTime;
};

export interface IScheduleGroupInformation {
  nextShowStartTime?: number;
  startTime: number;
  timeLabel: string;
}

export interface IScheduleGroup extends IScheduleGroupInformation {
  episodes: IEpisode[];
}

/**
 * Groups episodes in schedule groups by start time, returning some useful props like:
 * - start time
 * - time label
 * - start time of next show on schedule
 */
export const groupScheduleEpisodesByStartTime = (
  episodes: IEpisode[],
  selectedDate: Date,
): IScheduleGroup[] => {
  const groupedEpisodes = groupBy(episodes, (episode) => episode?.startTime);

  const scheduleGroups = [] as IScheduleGroup[];

  Object.keys(groupedEpisodes).forEach((key) => {
    // Order internally the grouped episodes to keep on-air only at last
    groupedEpisodes[key].sort((a, b) => {
      if (a.isOnAirOnly === b.isOnAirOnly) {
        return a.endTime - b.endTime;
      }

      return +a.isOnAirOnly - +b.isOnAirOnly;
    });

    const firstItem = groupedEpisodes[key][0];

    const timeLabel = getScheduleTimeLabel(firstItem?.startTime, firstItem?.endTime, selectedDate);

    scheduleGroups.push({
      episodes: groupedEpisodes[key],
      startTime: firstItem?.startTime,
      timeLabel,
    });
  });

  // Order groups based on start time
  scheduleGroups.sort((a, b) => a?.startTime - b?.startTime);

  // Gets next not on-air only show start time
  for (let i = 0; i < scheduleGroups.length; i++) {
    const scheduleGroupA = scheduleGroups[i];

    for (let j = i + 1; j < scheduleGroups.length; j++) {
      const scheduleGroupB = scheduleGroups[j];

      const someEpisodeAreNotOnAirOnly = scheduleGroupB.episodes.some(
        (episode) => !episode?.isOnAirOnly,
      );

      if (someEpisodeAreNotOnAirOnly) {
        scheduleGroupA.nextShowStartTime = scheduleGroupB?.startTime;

        break;
      }
    }
  }

  return scheduleGroups;
};

/**
 * Returns a boolean indicating if `Ends at` should be showed.
 *
 * It sticks to the following rules:
 * - Show ends earlier or later than the next show on the schedule
 * - Episode does not already have `Continues Next Day` text
 */
export const getShouldShowEndTime = (
  endTime: number,
  nextShowStartTime: number | undefined,
  overnight: IEpisodeOvernight,
): boolean => {
  return (
    nextShowStartTime !== undefined && endTime !== nextShowStartTime && !overnight.continuesNextDay
  );
};
