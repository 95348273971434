import useToast from '~/state/toast';

import ToastContainer from './ToastContainer';

const ToastWithState = (): JSX.Element | null => {
  const { amountQueued, currentToast, popToast } = useToast();

  return <ToastContainer amountQueued={amountQueued} nextToast={popToast} toast={currentToast} />;
};

export default ToastWithState;
