import { BP, Breakpoints } from '~/styles';

export const getBreakpoint = (width = 0): BP => {
  let breakpoint: BP = BP.Small;

  Object.entries(Breakpoints).forEach(([key, value]) => {
    if (width >= value) {
      breakpoint = parseInt(key, 10);
    }
  });

  return breakpoint;
};
